// libraries
import type { Incentive, StateTree } from '@makemydeal/dr-dash-types';

// selectors
import {
    getCurrentOfferType,
    getAllIncentiveTotalByOfferType,
    getManualIncentiveRebatesByOfferType
} from '../selectors/offerRedux';
import { DateTime } from 'luxon';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import * as offerReduxSelectors from '../selectors/offerRedux';

/**
 * Prepares incentives data specifically for AIO
 * @param state
 * @returns
 */
export const buildIncentivesNode = (state: StateTree) => {
    const offerType = getCurrentOfferType(state);
    const enableAppliedLenderInfoToggle = featureToggleSelectors.getFeatureToggle(state, 'enableAppliedLenderInfo');
    const creditDecisionEnabled = offerReduxSelectors.getIsUserProgramQuotes(state);

    const appliedIncentiveAmount =
        enableAppliedLenderInfoToggle && creditDecisionEnabled
            ? offerReduxSelectors.getIncentivesTotalOverrideByOfferType(state, offerType)
            : getAllIncentiveTotalByOfferType(state, offerType);
    const appliedIncentives = offerReduxSelectors.getAppliedIncentivesMappedForAIO(state);

    const incentivesTotal =
        getManualIncentiveRebatesByOfferType(state, offerType) ||
        appliedIncentiveAmount ||
        offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash(state);

    return {
        appliedIncentiveAmount: incentivesTotal,
        appliedIncentives
    };
};

/**
 * This filter will return false for invalid dates or dates older than seven days local time. Assumes dates are in M/d/yyyy format.
 * @returns
 */
export const filterByEndDate =
    (limitISO: string) =>
    (incentive: { endDate: string | null | undefined }): boolean => {
        const lowerLimit = DateTime.fromISO(limitISO);
        const endValue = incentive.endDate;

        // returning early here because DateTime silently accepts blank but
        //   reports invalid. We want to keep null or blank end dates
        if (endValue == null || endValue === '') return true;
        let end = DateTime.fromFormat(endValue, 'M/d/yyyy');

        if (!end.isValid) {
            end = DateTime.fromISO(endValue);
        }

        if (!end.isValid) {
            return false; // If still not valid, exclude this data point
        }
        // DateTime has custom operators to handle logical comparisons between dates.
        return end >= lowerLimit; // NOTE: valid if more recent than lower limit
    };

/**
 * AMD relies on M/d/yyyy format for dates, so rather than chaning it to ISO [for now], we'll format our incentives in dash to match. The filter also relies on this format.
 * @param incentive
 * @returns
 */
export const transformIncentiveDates = (incentive: Incentive): Incentive => {
    let startDate: DateTime | undefined = DateTime.fromISO(incentive.startDate);
    let endDate: DateTime | undefined = DateTime.fromISO(incentive.endDate);

    if (!startDate.isValid) startDate = undefined;
    if (!endDate.isValid) endDate = undefined;
    return {
        ...incentive,
        endDate: endDate?.toLocaleString(DateTime.DATE_SHORT, { locale: 'en-US' }) ?? '',
        startDate: startDate?.toLocaleString(DateTime.DATE_SHORT, { locale: 'en-US' }) ?? ''
    };
};
