// externals
import React, { useMemo } from 'react';

// libraries
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import { deskingSelectors, offerActionTypes, offerSelectors, paymentInputActionCreators } from '@makemydeal/dr-dash-store';

// utils
import { usePaymentCall } from '../../utils/usePaymentCall';

// consts/enums
import { GENERIC_LAST_FIELD_CHANGED_ERROR, SELLING_PRICE } from '../../constants';

// styles
import { useSelector } from 'react-redux';
import { NumericInput } from '@interstate/components/NumericInput';

const SellingPriceField = () => {
    const {
        value: sellingPrice,
        handleChange: handleSellingPriceChange,
        handleBlur
    } = usePaymentCall(offerSelectors.getSellingPrice, paymentInputActionCreators.updateOfferPrice, {
        formatValueToString: formatDollarsAndCents
    });
    const failed = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_OFFER_PRICE_IMMEDIATE));
    const failedMessage = useMemo(() => (failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined), [failed]);

    return (
        <NumericInput
            id="selling-price"
            label={SELLING_PRICE}
            name={kebabCase(SELLING_PRICE)}
            onChange={handleSellingPriceChange}
            placeholder={'26,500.00'}
            hasError={failed}
            errorMessage={failedMessage}
            value={sellingPrice}
            onBlur={handleBlur}
            data-testid={kebabCase(SELLING_PRICE)}
            allowDecimal={true}
            decimalMaxLength={2}
            decimalMinLength={2}
            inputPrefix={'$'}
            autoInsertCommas={true}
            maxValue={9999999}
        />
    );
};

export default SellingPriceField;
