// externals
import { Reducer } from 'redux';

// libraries
import type { ShopperNotificationsState } from '@makemydeal/dr-dash-types';

// consts/enums
import { COPY_ACTIVITY_SHARE_URL_SUCCESS, COPY_ACTIVITY_SHARE_URL_FAILURE } from '../actionTypes/notifyActionTypes';
import { initActionTypes } from '@makemydeal/dr-shared-store';

export const INITIAL_SHOPPER_NOTIFICATIONS_STATE: ShopperNotificationsState = {};

export type ShopperNotificationsReducer = Reducer<ShopperNotificationsState>;

export const reducer: ShopperNotificationsReducer = (
    state: ShopperNotificationsState = INITIAL_SHOPPER_NOTIFICATIONS_STATE,
    action: any
) => {
    switch (action.type) {
        case initActionTypes.INIT_PENCIL_SUCCESS: {
            const snResponse = action.payload.shopperNotifications;
            const notificationsList =
                snResponse && snResponse.ok && snResponse.response ? snResponse.response.shopperNotifications : [];
            return {
                ...state,
                notificationsList
            };
        }

        case COPY_ACTIVITY_SHARE_URL_SUCCESS: {
            const newEntry = action.payload.storedNotificationDoc;
            const notificationsList = state.notificationsList ? [...state.notificationsList, newEntry] : [newEntry];

            return {
                ...state,
                notificationsList
            };
        }
        case COPY_ACTIVITY_SHARE_URL_FAILURE: {
            return state;
        }

        default: {
            return state;
        }
    }
};
