// externals
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    RADIO_BUTTON_LIST,
    RADIO_BUTTON_LIST_LABEL,
    RATING_DISPLAY_NAME,
    RATING_INPUT,
    SELF_REPORTED_CREDIT_RATING_FIELD,
    TIER,
    RATING
} from '../../constants';
import { kebabCase } from 'lodash';
import { Box } from '@interstate/components/Box';
import { NumericInput } from '@interstate/components/NumericInput';
import { RadioButtonList } from '@interstate/components/RadioButtonList';
import { SelectInput } from '@interstate/components/SelectInput';
import { offerReduxSelectors, offerSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';

export const SelfReportedCreditRatingWithScore = () => {
    const dispatch = useDispatch();
    const options = useSelector(offerSelectors.getCreditTiersAsOptions);
    const selected = useSelector(offerSelectors.getSelectedCreditTier);
    const manualCreditScoreFromState = useSelector(offerReduxSelectors.getManualCreditScore);
    const manualCreditScoreAsString = manualCreditScoreFromState ? manualCreditScoreFromState.toString() : '';
    const getInitialDisplay = manualCreditScoreFromState ? 'Rating' : 'Tier';
    const [creditDisplayType, setCreditDisplayType] = React.useState(getInitialDisplay);
    const [tempCreditScoreInput, setTempCreditScoreInput] = React.useState('');
    const [shouldClearCreditScoreInput, setShouldClearCreditScoreInput] = React.useState(false);

    useEffect(() => {
        if (isValidCreditScore(tempCreditScoreInput) && isNewCreditScore()) {
            const creditRatingAsNum = parseInt(tempCreditScoreInput);
            dispatch(offerReduxActionCreators.updateManualCreditScore(creditRatingAsNum));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempCreditScoreInput]);

    const isValidCreditScore = (score: string): boolean => {
        const num = parseInt(score, 10);
        return !isNaN(num) && num >= 300 && num <= 900;
    };

    const isNewCreditScore = () => {
        return tempCreditScoreInput !== manualCreditScoreAsString;
    };

    const handleInputChange = (event: any) => {
        setTempCreditScoreInput(event.target.value);
        setShouldClearCreditScoreInput(false);
    };
    const handleInputFocus = () => {
        setShouldClearCreditScoreInput(true);
    };
    const handleInputBlur = () => {
        setTempCreditScoreInput(tempCreditScoreInput);
        setShouldClearCreditScoreInput(false);
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            maxWidth={395}
            data-testid="self-reported-credit-score"
            justifyContent="space-between"
        >
            {
                <RadioButtonList
                    data-testid={RADIO_BUTTON_LIST}
                    label={RADIO_BUTTON_LIST_LABEL}
                    name={RADIO_BUTTON_LIST}
                    value={creditDisplayType}
                    onChange={(e: any) => setCreditDisplayType(e.target.value)}
                    options={[
                        {
                            label: TIER,
                            value: TIER
                        },
                        {
                            label: RATING,
                            value: RATING
                        }
                    ]}
                    inline={true}
                    sx={{
                        '.MuiGrid2-direction-xs-row:nth-of-type(2)': {
                            width: '200px'
                        }
                    }}
                />
            }
            {creditDisplayType === RATING_DISPLAY_NAME ? (
                <Box>
                    <NumericInput
                        id={RATING_INPUT}
                        data-testid={RATING_INPUT}
                        label={RATING_DISPLAY_NAME}
                        name={RATING_INPUT}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        value={shouldClearCreditScoreInput ? '' : tempCreditScoreInput || manualCreditScoreAsString}
                        minValue={300}
                        maxValue={900}
                        minLength={3}
                        maxLength={3}
                    />
                </Box>
            ) : (
                <SelectInput
                    id={kebabCase(SELF_REPORTED_CREDIT_RATING_FIELD)}
                    name={kebabCase(SELF_REPORTED_CREDIT_RATING_FIELD)}
                    onChange={(e) => dispatch(offerReduxActionCreators.selectedCreditTier(e.target.value))}
                    value={selected}
                    options={options}
                    displayDeselectOption={false}
                    label={TIER}
                />
            )}
        </Box>
    );
};

export default SelfReportedCreditRatingWithScore;
