// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import type { PushToDarwinStatus, StateTree } from '@makemydeal/dr-dash-types';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

// consts
import {
    OPEN_IN_DARWIN,
    PUSH_TO_DARWIN,
    SET_PUSH_TO_DARWIN_STATUS,
    PUSH_TO_DARWIN_ERROR,
    PUSH_TO_DARWIN_FAILURE,
    PUSH_TO_DARWIN_REQUEST,
    PUSH_TO_DARWIN_SUCCESS,
    OPEN_IN_DARWIN_URL
} from '../actionTypes/pushToDarwinActionTypes';

// utils
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';

// selectors
import { offerReduxSelectors, pushToDarwinSelectors, vehicleSelectors } from '../selectors';
import { getDealXgId } from '../selectors/offerRedux';

export const pushToDarwin = () => {
    return {
        type: PUSH_TO_DARWIN
    };
};

export const setPushToDarwinStatus = (status: PushToDarwinStatus) => {
    return {
        type: SET_PUSH_TO_DARWIN_STATUS,
        payload: status
    };
};

export const pushToDarwinError = ({ statusCode, errorDetails }: { statusCode: number; errorDetails?: any }) => {
    return {
        type: PUSH_TO_DARWIN_ERROR,
        payload: {
            errorDetails,
            statusCode
        }
    };
};

export const pushToDarwinRSAA = (appPrefix: string, successSource: string) => {
    return (dispatch: any, getState: () => StateTree) => {
        const state = getState();

        const commonOrgId = offerReduxSelectors.getCommonOrgId(state);
        const stockNumber = vehicleSelectors.getVehicleStockNumber(state);
        const dealXgId = getDealXgId(state);

        const locHref = locHrefUtil.getLocHref();
        const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, 'pushToDarwin');

        const body = JSON.stringify({
            dealerID: commonOrgId,
            dealID: dealXgId,
            stockNumber
        });

        dispatch({
            [RSAA]: {
                endpoint: bffApiUrlResult.url,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body,
                types: [
                    PUSH_TO_DARWIN_REQUEST,
                    {
                        type: PUSH_TO_DARWIN_SUCCESS,
                        meta: { source: successSource }
                    },
                    PUSH_TO_DARWIN_FAILURE
                ]
            }
        });
    };
};

export const openInDarwin = () => {
    return {
        type: OPEN_IN_DARWIN
    };
};

export const openInDarwinUrl = () => {
    return {
        type: OPEN_IN_DARWIN_URL
    };
};
