// externals
import React from 'react';
import { useSelector } from 'react-redux';

// libraries
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// types
import { CompareColumnDetailProps } from '../types/compareColumnDetailProps';

// constants
import * as constants from '../../constants';

// components
import MSRP from '../../dealSummary/sections/MSRP.interstate';
import SellingPrice from '../../dealSummary/sections/SellingPrice.interstate';
import Addons from '../../dealSummary/sections/Addons.interstate';
import NetTrade from '../../dealSummary/sections/NetTrade.interstate';
import Rebates from '../../dealSummary/sections/Rebates.interstate';
import CashDown from '../../dealSummary/sections/CashDown.interstate';
import TermLength from '../../dealSummary/sections/TermLength.interstate';
import MonthlyPayment from '../../dealSummary/sections/MonthlyPayment.interstate';
import TotalAmountDue from '../../dealSummary/sections/TotalAmountDue.interstate';
import { EmptyField } from '../../dealSummary/sections/EmptyField';
import Discount from '../../dealSummary/sections/Discount.interstate';
import TotalTax from '../../dealSummary/sections/TotalTax.interstate';
import NetSellingPrice from '../../dealSummary/sections/NetSellingPrice.interstate';
import { StyledListBordered, StyledListNotBordered } from './CompareColumnDetail.style';

export const CompareFinanceColumnDetail = ({ deal, hasDifference, deltaList }: CompareColumnDetailProps) => {
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const StyledList = isABPencilEnabled ? StyledListBordered : StyledListNotBordered;

    return (
        <>
            <Grid container flexDirection="column" gap={1}>
                <Typography variant="h4">{constants.PRICE}</Typography>
                <StyledList>
                    <MSRP deal={deal} />
                    <Discount deal={deal} hasDelta={deltaList.isDiscountDelta} />
                    <SellingPrice deal={deal} hasDelta={deltaList.isSellingPriceDelta} />
                </StyledList>
            </Grid>
            <Grid container flexDirection="column" gap={1}>
                <Typography variant="h4">{constants.ADD_ONS}</Typography>
                <StyledList>
                    <Addons deal={deal} hasDelta={deltaList.isAddOnsDelta} />
                    <TotalTax deal={deal} hasDelta={deltaList.isTotalTaxDelta} />
                    <NetSellingPrice deal={deal} hasDelta={deltaList.isNetSellingPriceDelta} />
                </StyledList>
            </Grid>
            <Grid container flexDirection="column" gap={1}>
                <Typography variant="h4">{constants.FINANCE_REDUCTIONS}</Typography>
                <StyledList>
                    <NetTrade deal={deal} hasDelta={deltaList.isNetTradeDelta} />
                    <Rebates deal={deal} hasDelta={deltaList.isRebatesDelta} />
                    <CashDown deal={deal} hasDelta={deltaList.isCashDownDelta} />
                    {hasDifference && <EmptyField />}
                </StyledList>
            </Grid>
            <Grid container flexDirection="column" gap={1}>
                <Typography variant="h4">{constants.FINANCING_TERMS}</Typography>
                <StyledList>
                    {hasDifference && <EmptyField />}
                    <TermLength deal={deal} hasDelta={deltaList.isTermDelta} />
                    <MonthlyPayment deal={deal} hasDelta={deltaList.isMonthlyPaymentDelta} />
                    <TotalAmountDue deal={deal} hasDelta={deltaList.isTotalDueDelta} />
                </StyledList>
            </Grid>
        </>
    );
};
