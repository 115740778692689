import { useContext } from 'react';
import { FeesFormContext } from './FeesFormContext';

export const useFeesFormContext = () => {
    const context = useContext(FeesFormContext);

    if (!context) throw new Error('useFeesFormContext must be used within a FeesFormProvider.');

    return context;
};
