// externals
import { uniq } from 'lodash';
import { SurchargeOverrides } from '@makemydeal/dr-shared-types';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// types
import { StateAbbreviation, VppDealAttributeInput } from '../types';

// constants
import { StateAbbreviations } from '../components/editDealAttributesDrawer/dealAttributesForm/constants/stateAbbreviations';
import { vppDealAttributesInputsList } from '../components/editDealAttributesDrawer/dealAttributesForm/constants/vppDealAttributesInputsSettings';
import { alwaysVisibleDealAttributes } from '../components/editDealAttributesDrawer/dealAttributesForm/constants/alwaysVisibleDealAttributes';

const mapStateAbbreviationToFullStateName = (stateAbbreviation: StateAbbreviation) => {
    return StateAbbreviations.find((state) => state.value === stateAbbreviation)?.label;
};

const mapDealAttributeInputs = (
    allDealAttributesToShow: string[],
    requiredSurchargesAttributes: string[],
    surchargeOverrides: SurchargeOverrides
) => {
    const mappedDealAttributeInputs: (VppDealAttributeInput | undefined)[] = allDealAttributesToShow.map((requestedField) => {
        const matchingDealAttributeInput = vppDealAttributesInputsList.find(
            (attributeInput) => attributeInput.fullVppPath === requestedField
        );

        if (!matchingDealAttributeInput) {
            console.error(`Could not find matching VPP deal attribute for: ${requestedField}. Incorrect deal attributes settings`);
            return;
        }

        const dealAttributeInput = {
            ...matchingDealAttributeInput,
            required: requiredSurchargesAttributes.includes(requestedField),
            value: surchargeOverrides[matchingDealAttributeInput.name]
        };

        if (dealAttributeInput.name === 'customerState') {
            dealAttributeInput.displayValue = mapStateAbbreviationToFullStateName(dealAttributeInput.value as StateAbbreviation);
        }

        return dealAttributeInput;
    });

    return mappedDealAttributeInputs.filter((dealAttr) => !!dealAttr) as VppDealAttributeInput[];
};

export const getRequestedDealAttributeInputs = (
    surchargeOverrides: SurchargeOverrides,
    usedSurchargesAttributes: string[],
    requiredSurchargesAttributes: string[]
): VppDealAttributeInput[] => {
    const allDealAttributesToShow = uniq([...alwaysVisibleDealAttributes, ...usedSurchargesAttributes]);

    const mappedDealAttributeInputs = mapDealAttributeInputs(
        allDealAttributesToShow,
        requiredSurchargesAttributes,
        surchargeOverrides
    );

    return mappedDealAttributeInputs.sort((a: VppDealAttributeInput, b: VppDealAttributeInput): number => {
        return a.order - b.order;
    });
};

export const formatDealAttributeInformationValue = (isPrice: boolean | undefined, value: number | string): string => {
    if (isPrice) {
        return formatDollarsAndCents(value);
    }
    return (value || 'N/A') as string;
};
