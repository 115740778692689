import { Grid } from '@interstate/components/Grid';
import styled from 'styled-components';

export const StyledColumnsContainer = styled(Grid)`
    &&& div.card-media-header-content-container {
        padding-left: 0;
        padding-right: 0;
    }

    & div.offer-compare-column {
        width: 299px;
    }
`;
