import { Reducer } from 'redux';
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { VAUTO_APPRAISALS_FAILURE, VAUTO_APPRAISALS_REQUEST, VAUTO_APPRAISALS_SUCCESS } from '../actionTypes/vAutoActionTypes';
import { VAutoState } from '@makemydeal/dr-dash-types';

export const vAutoInitialState: VAutoState = {
    items: [],
    hasError: false
};

export type vAutoReducer = Reducer<VAutoState>;

export const reducer: vAutoReducer = (state: VAutoState = vAutoInitialState, action: AnyFSA) => {
    switch (action.type) {
        case VAUTO_APPRAISALS_REQUEST: {
            return { ...state, hasError: false };
        }
        case VAUTO_APPRAISALS_SUCCESS: {
            return { ...state, items: action.payload.data.items };
        }
        case VAUTO_APPRAISALS_FAILURE: {
            return { ...state, hasError: true };
        }

        default: {
            return state;
        }
    }
};
