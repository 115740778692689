import styled from 'styled-components';

export const StyledRebatesFlexView = styled.div`
    > hr {
        width: 100%;
        margin: 16px 0;
    }

    > hr:first-child {
        margin-top: 0;
    }

    > hr:last-child {
        margin-bottom: 0;
    }
`;

export const StyledRebatesTabularView = styled.table`
    table-layout: auto;
`;
