// libraries
import { FSA } from '@makemydeal/dr-platform-shared';
import type { ProtectionItem } from '@makemydeal/dr-shared-types';
import type { DealProduct, AccessoriesAdapterObject } from '@makemydeal/dr-shared-types';
import type { InternalPrevOfferPayloadData as OfferFullPayloadData } from '@makemydeal/dr-dash-types';

// consts/enums
import {
    INTERNAL_PATCH_PREV_OFFER_PAYLOAD_MENU_PRODUCTS,
    INTERNAL_UPDATE_PREV_OFFER_AFTER_INIT,
    INTERNAL_UPDATE_PREV_OFFER_FULL_PAYLOAD
} from '../actionTypes/internalPrevOfferActionTypes';

export type UpdatePrevOfferAfterInitActionPayload = {
    dealXgId: string;
};

export type UpdateInternalPrevOfferAfterInitAction = FSA<
    typeof INTERNAL_UPDATE_PREV_OFFER_AFTER_INIT,
    UpdatePrevOfferAfterInitActionPayload
>;

export const updatePrevOfferAfterInit = (dealXgId: string): UpdateInternalPrevOfferAfterInitAction => ({
    type: INTERNAL_UPDATE_PREV_OFFER_AFTER_INIT,
    payload: {
        dealXgId
    }
});

export type UpdatePrevOfferFullActionPayload = {
    data: OfferFullPayloadData;
    dealXgId: string;
    dealXgVersion: string;
};

export type UpdateInternalPrevOfferPayloadDataAction = FSA<
    typeof INTERNAL_UPDATE_PREV_OFFER_FULL_PAYLOAD,
    UpdatePrevOfferFullActionPayload
>;

export const internalUpdatePrevOfferFullPayload = (
    dealXgId: string,
    dealXgVersion: string,
    prevOfferFullPayload: OfferFullPayloadData
): UpdateInternalPrevOfferPayloadDataAction => ({
    type: INTERNAL_UPDATE_PREV_OFFER_FULL_PAYLOAD,
    payload: {
        data: prevOfferFullPayload,
        dealXgId,
        dealXgVersion
    }
});

export type PatchPrevOfferMenuProductsActionPayload = {
    menuProducts: ProtectionItem[] | DealProduct[] | undefined;
    accessories: AccessoriesAdapterObject | undefined;
};

export type PatchPrevOfferMenuProductsAction = FSA<
    typeof INTERNAL_PATCH_PREV_OFFER_PAYLOAD_MENU_PRODUCTS,
    PatchPrevOfferMenuProductsActionPayload
>;

export const internalPatchPrevOfferPayloadAftermarketItems = (
    menuProducts: ProtectionItem[] | DealProduct[] | undefined,
    accessories: AccessoriesAdapterObject | undefined
): PatchPrevOfferMenuProductsAction => ({
    type: INTERNAL_PATCH_PREV_OFFER_PAYLOAD_MENU_PRODUCTS,
    payload: {
        menuProducts,
        accessories
    }
});
