import { Store } from 'redux';
import { RealState, ScopedState } from '../types/scopeState';
import { createScopedAction, createScopedActionMeta } from '../actions/scopedActions';
import { injectMetadataOnRSAA, isRSAAAction } from '../utils/RSAAUtil';

export type ScopedStore = Store & {
    __isScoped: boolean;
    __realStore: Store<RealState>;
    /**
     * @deprecated All realState is now present on the ScopedState, so it can be accessed anytime
     */
    getRealState(): RealState;
};

export const isScopedStore = (value: ScopedStore | Store<RealState>): value is ScopedStore => {
    return '__isScoped' in value && value.__isScoped === true;
};

type ScopedStateCacheObject = {
    cachedScopedState: ScopedState;
    lastState: RealState;
};

/**
 * This class is responsible for creating a Scoped state from a Real State
 * and maintaining a cache when that scoped state is unchanged.
 * Caching is crucial because parts of the application select the root state,
 * and changing it every time would cause an infinite render loop.
 */
export class ScopedStoreManager {
    private scopedStateCache: Map<string, ScopedStateCacheObject>;
    constructor() {
        this.scopedStateCache = new Map();
    }

    private getTargetScope = (state: RealState, scopeId?: string, navigationScope?: boolean) => {
        let targetScope: string;

        if (navigationScope) {
            targetScope = state.__navigationScopeTarget;
        } else {
            targetScope = scopeId ?? state.__primaryScope;
        }

        if (!state.__scopeMap.scopes[targetScope]) {
            throw new Error(
                `Trying to access the State Scope Id: ${targetScope} that does not exist. Did you forget to create it first?`
            );
        }

        return targetScope;
    };

    private getScopedState = (state: RealState, targetScope: string): ScopedState => {
        const { __global: globalState, __scopeMap } = state;
        const scopeState = __scopeMap.scopes[targetScope].__scopeState;

        const { cachedScopedState, lastState } = this.scopedStateCache.get(targetScope) || {};

        if (cachedScopedState && lastState === state) {
            return cachedScopedState;
        }

        const newState: ScopedState = {
            __scopeId: targetScope,
            ...globalState,
            ...scopeState,
            ...state
        };

        this.scopedStateCache.set(targetScope, {
            cachedScopedState: newState,
            lastState: state
        });

        return newState;
    };

    private createScopedDispatch = (store: Store<RealState>, scopeId?: string, navigationScope?: boolean) => {
        return (action: any) => {
            if (action) {
                const targetScope = this.getTargetScope(store.getState(), scopeId, navigationScope);

                let newAction = createScopedAction(targetScope, action);

                if (isRSAAAction(newAction)) {
                    newAction = injectMetadataOnRSAA(newAction, createScopedActionMeta(targetScope));
                }

                return store.dispatch(newAction);
            }
        };
    };

    private createScopedGetState = (store: Store<RealState>, scopeId?: string, navigationScope?: boolean) => {
        return () => {
            const state = store.getState();
            const targetScope = this.getTargetScope(state, scopeId, navigationScope);
            return this.getScopedState(state, targetScope);
        };
    };

    getStore = (store: Store<RealState>, scopeId?: string, navigationScope?: boolean): ScopedStore => {
        return {
            ...store,
            dispatch: this.createScopedDispatch(store, scopeId, navigationScope),
            getState: this.createScopedGetState(store, scopeId, navigationScope),
            getRealState: store.getState,
            __isScoped: true,
            __realStore: store
        };
    };
}

export const scopedStoreManager = new ScopedStoreManager();
