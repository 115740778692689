// externals
import { useEffect, useState } from 'react';
import { NumericInput } from '@interstate/components/NumericInput';

// libraries
import { kebabCase } from '@makemydeal/dr-common-utils';
import { paymentActionCreators } from '@makemydeal/dr-offer-redux';
import { usePaymentCall } from '../../utils/usePaymentCall';
import { offerSelectors } from '@makemydeal/dr-dash-store';

// consts
import { DAYS_TO_FIRST_PAYMENT } from '../../constants';

const DaysToFirstPaymentField = () => {
    const [errorMsg, setErrorMsg] = useState('');

    const {
        value: daysToPayment,
        handleChange,
        handleBlur
    } = usePaymentCall(offerSelectors.getDaysToFirstPaymentWithFallback, paymentActionCreators.updateDaysToFirstPayment);

    useEffect(() => {
        const newDaysToFirstPaymentValue = Number(daysToPayment);

        if (!isValidCreditScore(newDaysToFirstPaymentValue)) {
            setErrorMsg('Days to first payment must be between 30 and 365');
        } else {
            setErrorMsg('');
        }
    }, [daysToPayment]);

    const isValidCreditScore = (score: number): boolean => !isNaN(score) && score >= 30 && score <= 365;

    return (
        <NumericInput
            minLength={2}
            maxLength={3}
            minValue={30}
            maxValue={365}
            hasError={Boolean(errorMsg)}
            errorMessage={errorMsg}
            onBlur={handleBlur}
            value={daysToPayment}
            onChange={handleChange}
            label={DAYS_TO_FIRST_PAYMENT}
            name={kebabCase(DAYS_TO_FIRST_PAYMENT)}
            id={kebabCase(DAYS_TO_FIRST_PAYMENT)}
            data-testid={kebabCase(DAYS_TO_FIRST_PAYMENT)}
        />
    );
};

export default DaysToFirstPaymentField;
