import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import { IVehicleProtectionActivity } from '../types/IVehicleProtectionActivity';
import { getAllRoutes } from '../utils/routes';
import VehicleProtectionActivity from './VehicleProtectionActivity';
import { getMaxAppContainerWidth } from '@makemydeal/dr-shared-ui-utils';

const Root = (props: IVehicleProtectionActivity) => {
    const { root } = getAllRoutes(props.baseRoute);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    return (
        <div
            style={{ maxWidth: getMaxAppContainerWidth(isABPencilEnabled), margin: '0 auto', padding: '0 16px' }}
            data-testid="activities-vehicle-protection-container"
            className="activities-vehicle-protection"
        >
            <Route path={root} component={VehicleProtectionActivity} />
        </div>
    );
};

Root.displayName = 'VehicleProtectionActivity';

export default Root;
