import { Button } from '@interstate/components/Button';
import { Modal } from '@interstate/components/Modal';
import { Typography } from '@interstate/components/Typography';
import { offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { useDispatch } from 'react-redux';
import { CoBuyerModalProps } from './CoBuyerModalProps';
import { CoBuyerRemoveModalFooter } from './CoBuyerRemoveModal.styles';

export const CoBuyerRemoveModal = ({ show, setShowModal }: CoBuyerModalProps) => {
    const dispatch = useDispatch();
    return (
        <Modal
            size="small"
            id="remove-cobuyer-modal"
            data-testid="remove-cobuyer-modal"
            show={show}
            header={
                <Typography variant="h3" color="base.color.blue.700">
                    Remove Co-Buyer
                </Typography>
            }
            footer={{
                footerComponent: (
                    <CoBuyerRemoveModalFooter>
                        <Button buttonStyle="tertiary" id="cancel-btn" data-testid="cancel-btn" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>

                        <Button
                            id="remove-cobuyer-btn"
                            data-testid="remove-cobuyer-btn"
                            onClick={() => {
                                dispatch(offerReduxActionCreators.clearCoBuyer());
                                setShowModal(false);
                            }}
                        >
                            Remove Co-Buyer
                        </Button>
                    </CoBuyerRemoveModalFooter>
                )
            }}
        >
            <Typography variant="body-md" tag="span">
                Are you sure you want to remove this co-buyer? Remember, you will still need to update this info in your credit
                portal.
            </Typography>
        </Modal>
    );
};
