import { Grid } from '@interstate/components/Grid';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const HeaderRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
`;

export const DealDetailsHeaderWrapper = styled.div`
    margin: 16px 0;
`;

export const DealSummaryContainer = styled(Grid)`
    div.ids-item-content {
        padding: 0;
    }

    div.ids-list-item-content {
        padding: 0;
    }

    .empty-item > div.ids-item-content {
        background-color: #efefef;
    }

    @media (min-width: ${BreakPoint.TABLET_PORTRAIT}) {
        margin-bottom: 16px;
    }
`;
