// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';
import { UPDATED_CREDIT_DECISION_ENABLED } from '../actionTypes/offerActionTypes';
import { offerReduxSelectors } from '../selectors';
import { offerActionCreators } from '../actions';

export const deskingMiddleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    const state = store.getState();
    switch (action.type) {
        case UPDATED_CREDIT_DECISION_ENABLED:
            if (action.payload) {
                // NOTE: credit decision is being enabled here
                const currentOfferType = offerReduxSelectors.getCurrentOfferType(state);
                const penciledInputs = offerReduxSelectors.getOfferDetails(state, currentOfferType);

                // NOTE: if manual incentives don't yet exist on penciled inputs, then initialize them here with the current/selectd incentives.
                if (penciledInputs.manualIncentives == null) {
                    const incentives = offerReduxSelectors.getAppliedIncentives(state);
                    const dealerCashTotal = offerReduxSelectors.getAppliedIncentivesTotalForDealerCash(state);
                    const nonDealerCashTotal = offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash(state);
                    store.dispatch(
                        offerActionCreators.updateManualIncentives({
                            manualIncentives: incentives,
                            dealerCashTotal,
                            totalRebates: nonDealerCashTotal
                        })
                    );
                }
            }
            break;
    }
};
