import { DarwinResponseData, PushToDarwinState, PushToDarwinStatus, StateTree } from '@makemydeal/dr-dash-types';
import { createSelector } from 'reselect';

export const getPushToDarwin = (state: StateTree) => state.pushToDarwin;

export const getPushToDarwinState = createSelector([getPushToDarwin], (pushToDarwin) => pushToDarwin || ({} as PushToDarwinState));

export const getDarwinResponseData = createSelector(
    [getPushToDarwinState],
    (darwinState) => darwinState.darwinResponseData || ({} as DarwinResponseData)
);

export const getDarwinRequestFailure = (state: StateTree): boolean => {
    const darwinStatus = getPushToDarwinStatus(state);
    return darwinStatus === PushToDarwinStatus.Failure;
};

export const getDarwinDealId = (state: StateTree) => {
    const darwinResponseMessageData = getPushToDarwinState(state);
    return darwinResponseMessageData.dealXgId || '';
};

export const getPushToDarwinStatus = (state: StateTree): PushToDarwinStatus => {
    return getPushToDarwinState(state).status || PushToDarwinStatus.Ready;
};

export const getPushToDarwinCurrentFlow = (state: StateTree): string | undefined => {
    return getPushToDarwinState(state).currentFlow;
};

export const getDarwinErrorDetails = (state: StateTree): string | undefined => {
    return getPushToDarwinState(state).error?.errorDetails;
};
