import { useEffect } from 'react';

// externals
import { useDispatch, useSelector } from 'react-redux';
import { SelectInputOption } from '@interstate/components/SelectInput';
import { TextInput } from '@interstate/components/TextInput';

// libraries
import {
    tradeInSelectors,
    tradeInComponentActionCreators,
    tradeInComponentSelectors,
    tradeInActionCreators
} from '@makemydeal/dr-dash-store';
import { updateVehicle } from '@makemydeal/dr-activities-tradein';

// components
import { SelectInput } from './TradeInSelectInput';

// constants
import { TRADE_IN_MODEL } from '@makemydeal/dr-dash-components';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

interface MakeFieldProps {
    isManualFormOpen: boolean;
}

const ModelField: React.FC<MakeFieldProps> = ({ isManualFormOpen }) => {
    const dispatch = useDispatch();
    const vehicleYear = useSelector(tradeInSelectors.getTradeYear);
    const vehicleMake = useSelector(tradeInSelectors.getTradeMake);
    const vehicleMakeId = useSelector(tradeInSelectors.getTradeMakeId);
    const vehicleModel = useSelector(tradeInSelectors.getTradeModel);
    const vehicleModelId = useSelector(tradeInSelectors.getTradeModelId);
    const tradeInVehicleModels = useSelector(tradeInComponentSelectors.getTradeInComponentModels);
    const tradeInVehicle = useSelector(tradeInComponentSelectors.getTradeInComponentVehicle);
    const hasMultipleVin = useSelector(tradeInSelectors.hasMultipleVin);

    const modelValue = vehicleMake && vehicleModel !== 'default' ? vehicleModel : '';
    const isModelInputDisabled =
        hasMultipleVin || (tradeInVehicleModels.options.length === 0 && !modelValue) || !vehicleMake || vehicleMake === 'default';

    useEffect(() => {
        const model = tradeInVehicleModels.options.find((option) => vehicleModel === option.name);

        if (model && vehicleModelId !== model.id) {
            dispatch(tradeInActionCreators.updateTradeInModelId(model.id));
        }
    }, [tradeInVehicleModels, vehicleModel, vehicleModelId, dispatch]);

    useEffect(() => {
        if (vehicleYear && vehicleMakeId && vehicleMake && vehicleModel && vehicleModelId) {
            dispatch(
                tradeInComponentActionCreators.fetchTradeInComponentTrims(
                    MANAGER_VIEW_APP_PREFIX,
                    vehicleYear,
                    vehicleMakeId,
                    vehicleModelId
                )
            );
        }
    }, [vehicleMakeId, vehicleModelId, vehicleYear, vehicleMake, vehicleModel, dispatch]);

    const getTradeInModels = (): SelectInputOption[] => {
        const modelsOptions =
            modelValue && !tradeInVehicleModels.options.find((model) => model.name === modelValue)
                ? [...tradeInVehicleModels.options, { name: modelValue }]
                : tradeInVehicleModels.options;

        return modelsOptions.map((model) => ({ label: model.name, value: model.name })).sort();
    };

    const onModelChange = (event: any) => {
        const model = tradeInVehicleModels.options.find((model) => model.name === event.target.value);
        dispatch(tradeInActionCreators.updateTradeInModel(model));
        dispatch(
            updateVehicle({
                ...tradeInVehicle,
                model,
                trim: { id: '', name: '', vehicleId: '' }
            })
        );
        if (model) {
            dispatch(
                tradeInComponentActionCreators.fetchTradeInComponentTrims(
                    MANAGER_VIEW_APP_PREFIX,
                    vehicleYear,
                    vehicleMakeId,
                    model.id
                )
            );
        }
    };

    return (
        <>
            {isManualFormOpen && (
                <SelectInput
                    id="trade-models-select-input"
                    data-testid="trade-models-select-input"
                    label={TRADE_IN_MODEL}
                    name={TRADE_IN_MODEL}
                    onChange={onModelChange}
                    options={getTradeInModels()}
                    value={modelValue}
                    placeholder="Select"
                    disabled={isModelInputDisabled}
                    required
                    displayDeselectOption={false}
                />
            )}
        </>
    );
};

export default ModelField;
