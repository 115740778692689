// externals
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { PencilSquareIcon } from '@interstate/components/Icons/PencilSquareIcon';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { Button } from '@interstate/components/Button';

// store
import {
    vehicleProtectionSelectors,
    vehicleProtectionActionCreators,
    offerReduxSelectors,
    navigationHooks,
    RouteValues
} from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// style
import {
    EditDealAttributesLink,
    VehicleProtectionContent,
    VehicleProtectionTitle,
    VehicleProtectionFooter,
    VehicleProtectionFooterContainer,
    LoaderWrapper
} from './VehicleProtection.style';

// components
import VehicleProtectionTable from '../vehicleProtectionTable/VehicleProtectionTable';
import EditProductDrawer from '../editProductDrawer/EditProductDrawer';
import EditDealAttributesDrawer from '../editDealAttributesDrawer/EditDealAttributesDrawer';
import ProductSummary from '../productSummary/ProductSummary';
import TableGlobalAlerts from '../vehicleProtectionTableGlobalAlerts/TableGlobalAlerts';

// types
import { DealProductWithProductCode } from '@makemydeal/dr-dash-types';
import { CASH } from '@makemydeal/dr-platform-types';

const VehicleProtection = () => {
    const [editableProductCode, setEditableProductCode] = useState<string | undefined>(undefined);

    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const products = useSelector(vehicleProtectionSelectors.getVppProducts);
    const selectedProductsCount = useSelector(vehicleProtectionSelectors.getVppSelectedProductsCount);
    const summary = useSelector(vehicleProtectionSelectors.getVppSummary);
    const isFetchingVppProducts = useSelector(vehicleProtectionSelectors.isFetchingVppProducts);
    const isFetchingVppRates = useSelector(vehicleProtectionSelectors.isFetchingVppRates);
    const productsEligibleForSelection = useSelector(vehicleProtectionSelectors.getVppProductsEligibleForSelection);
    const isFetchingFIE = isFetchingVppProducts || isFetchingVppRates;

    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isFinanceOrLease = !(currentOfferType === CASH);

    const dispatch = useDispatch();
    const theme = useInterstateTheme();

    const [isDealAttributesDrawerOpen, setDealAttributesDrawerOpen] = useState(false);

    const handleDrawerClose = useCallback(() => {
        setEditableProductCode(undefined);
    }, []);

    const onOpenDealAttributesDrawer = useCallback(() => setDealAttributesDrawerOpen(true), []);
    const onCloseDealAttributesDrawer = useCallback(() => setDealAttributesDrawerOpen(false), []);

    const handleBackButtonClick = navigationHooks.useNavigate(RouteValues.DASHBOARD);

    const onProductEdit = useCallback((product: DealProductWithProductCode) => {
        setEditableProductCode(product.productCode);
    }, []);

    const onProductApply = useCallback(
        (product: DealProductWithProductCode) => {
            if (product.selected) {
                dispatch(vehicleProtectionActionCreators.unselectVppProduct(product.productCode));
            } else {
                dispatch(vehicleProtectionActionCreators.selectVppProduct(product.productCode));
            }
        },
        [dispatch]
    );

    const onProductApplyAll = useCallback(() => {
        if (selectedProductsCount === productsEligibleForSelection.length) {
            dispatch(vehicleProtectionActionCreators.unselectAllVppProducts());
        } else {
            dispatch(vehicleProtectionActionCreators.selectAllVppProducts());
        }
    }, [dispatch, selectedProductsCount, productsEligibleForSelection.length]);

    return (
        <>
            <TableGlobalAlerts />
            <VehicleProtectionTitle>
                <span data-testid="vpp-title">Vehicle Protection Products ({selectedProductsCount})</span>
            </VehicleProtectionTitle>
            <VehicleProtectionContent>
                <div>
                    <EditDealAttributesLink
                        theme={theme}
                        onClick={onOpenDealAttributesDrawer}
                        data-testid="edit-deal-attributes-link"
                    >
                        <PencilSquareIcon /> Edit Deal Attributes
                    </EditDealAttributesLink>
                </div>
                {isFetchingFIE && !products.length ? (
                    <LoaderWrapper>
                        <LoadingIndicator size="small" />
                    </LoaderWrapper>
                ) : (
                    <>
                        <VehicleProtectionTable
                            onProductApply={onProductApply}
                            onProductApplyAll={onProductApplyAll}
                            onProductEdit={onProductEdit}
                            products={products}
                        />
                        <ProductSummary summary={summary} isFinanceOrLease={isFinanceOrLease} />
                    </>
                )}
                <EditProductDrawer productCode={editableProductCode} onClose={handleDrawerClose} />
                <EditDealAttributesDrawer show={isDealAttributesDrawerOpen} onClose={onCloseDealAttributesDrawer} />
            </VehicleProtectionContent>
            <VehicleProtectionFooterContainer>
                <VehicleProtectionFooter theme={theme} isNewAppWidthEnabled={isABPencilEnabled}>
                    <Button
                        data-testid="vehicle-protection-back-to-deal"
                        buttonStyle="primary"
                        size="medium"
                        block={false}
                        onClick={handleBackButtonClick}
                    >
                        Back to My Deal
                    </Button>
                </VehicleProtectionFooter>
            </VehicleProtectionFooterContainer>
        </>
    );
};

export default VehicleProtection;
