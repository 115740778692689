// externals
import type { Reducer } from 'redux';

// libraries
import type { BridgeUserState } from '@makemydeal/dr-dash-types';
import { bridgeUserActionTypes } from '@makemydeal/dr-shared-store';

// consts/enums
const { BRIDGE_USER_SUCCESS } = bridgeUserActionTypes;

export const USER_INITIAL_STATE: BridgeUserState = {
    id: '',
    username: '',
    familyName: '',
    givenName: ''
};

export type BridgeUserReducer = Reducer<BridgeUserState>;

export const reducer: Reducer<BridgeUserState> = (state: BridgeUserState = USER_INITIAL_STATE, action: any) => {
    switch (action.type) {
        case BRIDGE_USER_SUCCESS: {
            const userInfo = action.payload;
            return {
                id: userInfo.id,
                familyName: userInfo.family_name,
                givenName: userInfo.given_name,
                username: userInfo.username
            };
        }

        default:
            return state;
    }
};
