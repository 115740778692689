import { RSAA, RSAAAction } from 'redux-api-middleware';

export const isRSAAAction = (action: unknown): action is RSAAAction =>
    Boolean(action && typeof action === 'object' && RSAA in action);

export const injectMetadataOnRSAA = (action: RSAAAction, metadata: Record<string, any>): RSAAAction => {
    const newAction = {
        ...action
    };

    const types = action[RSAA].types;
    newAction[RSAA].types = types.map((t: any) => {
        if (typeof t === 'string') {
            return {
                type: t,
                meta: metadata
            };
        } else if (t.meta && typeof t.meta === 'function') {
            // Previous metadata defined as a function
            return {
                ...t,
                meta: (...args: any[]) => {
                    const result = t.meta(...args);
                    return {
                        ...result,
                        ...metadata
                    };
                }
            };
        } else if (t.meta && typeof t.meta === 'object') {
            // Previous metadata defined as object
            return {
                ...t,
                meta: {
                    ...t.meta,
                    ...metadata
                }
            };
        } else {
            // If Type is an object and has no metadata
            return {
                ...t,
                meta: metadata
            };
        }
    }) as typeof types;

    return newAction;
};
