import React from 'react';
import { useLocation } from 'react-router-dom';

type Callback = () => void;

export const useLocationChange = (action: Callback) => {
    const location = useLocation();
    React.useEffect(() => {
        action();
    }, [location]);
};
