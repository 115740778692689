import { kebabCase } from '@makemydeal/dr-common-utils';

import { MAX_RESIDUALIZED_MSRP_APPLIED, MAX_RESIDUALIZED_MSRP_APPLIED_LABEL_INTERSTATE } from '../../constants';
import { useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { CheckBox } from '@interstate/components/CheckBox';

const MaxResidualizedMsrpAppliedROField = () => {
    const checked = useSelector(offerReduxSelectors.getMaxResidualizedMsrpApplied);

    return (
        <CheckBox
            data-testid={kebabCase(MAX_RESIDUALIZED_MSRP_APPLIED)}
            id={kebabCase(MAX_RESIDUALIZED_MSRP_APPLIED)}
            label={MAX_RESIDUALIZED_MSRP_APPLIED_LABEL_INTERSTATE}
            name={kebabCase(MAX_RESIDUALIZED_MSRP_APPLIED)}
            sx={{ marginBottom: '6px' }}
            checked={checked}
            disabled={true}
            value="1"
        />
    );
};

export default MaxResidualizedMsrpAppliedROField;
