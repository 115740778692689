// externals
import { Reducer } from 'redux';

// libraries
import type { DealerState } from '@makemydeal/dr-dash-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export type DealerReducer = Reducer<DealerState>;

export const reducer: DealerReducer = (state: Partial<DealerState> = {}, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...action.payload.dealer
            };
        }
        default: {
            return state;
        }
    }
};
