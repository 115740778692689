// externals
import { FC, PropsWithChildren, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Box } from '@interstate/components/Box';
import { Badge } from '@interstate/components/Badge';
import { Action } from '@interstate/components/Action';
import { Typography } from '@interstate/components/Typography';
import { ArrowTopRightOnSquareIcon, ArrowsRightLeftIcon, PencilSquareIcon } from '@interstate/components/Icons';
import { configSelectors, navigationActionCreators } from '@makemydeal/dr-dash-store';
import { getNumberOfDaysStartingFromTargetDate } from '@makemydeal/dr-common-utils';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// components
import { LinkContainer } from './Style';
import { FormContext } from '../formManager/formContext';
import { FormStockInput } from '../formManager/FormStockInput';

type DrawerHeaderProps = PropsWithChildren<{
    condition: string;
    inventoryDate: string;
    year: string;
    model: string;
    make: string;
    trim: string;
    vin: string;
    stockNumber: string;
    isCustom?: boolean;
}>;

export const DrawerHeader: FC<DrawerHeaderProps> = ({
    condition,
    inventoryDate,
    year,
    model,
    make,
    trim,
    vin,
    stockNumber,
    isCustom
}) => {
    const { isStockEditable, handleClickEditStock, hideDrawer } = useContext(FormContext);
    const dispatch = useDispatch();
    const vAutoUrl = useSelector(configSelectors.getVAutoInventoryUrl);
    const isSwitchVehicleEnabled = useSelector(dealerSelectors.isSwitchVehicleEnabled);
    const age = inventoryDate && getNumberOfDaysStartingFromTargetDate(inventoryDate);
    const isSellAnyVehicleEnabled: boolean = useSelector(featureToggleSelectors.isSellAnyVehicleEnabled);
    const isCustomVehicle = isSellAnyVehicleEnabled && isCustom;

    return (
        <Box paddingBottom={4}>
            <Box display="flex" marginBottom={1.5} justifyContent="space-between">
                <Typography
                    data-testid="drawer-header-title"
                    variant="strong-md"
                >{`${condition.toUpperCase()} ${year} ${make} ${model} ${trim}`}</Typography>
                {!!age && age > 0 && (
                    <Box>
                        <Badge variant="transparent" data-testid="drawer-header-age">
                            Age: {age}d
                        </Badge>
                    </Box>
                )}
            </Box>
            <Box marginBottom={1.5} color="white">
                <Typography data-testid="drawer-header-vin" variant="body-md">
                    VIN {vin}
                </Typography>
                {!isCustomVehicle && (
                    <Typography data-testid="drawer-header-stock" variant="body-md">
                        Stock No. {stockNumber}
                    </Typography>
                )}
                {isCustomVehicle && !isStockEditable && (
                    <Box display="flex" alignItems="flex-start" gap={0.5} mt={1} pb={0.5}>
                        <Typography data-testid="drawer-header-stock" variant="body-md" sx={{ wordBreak: 'break-all' }}>
                            Stock # {stockNumber || 'N/A'}
                        </Typography>
                        <Action
                            actionIcon={{
                                icon: <PencilSquareIcon />,
                                start: true
                            }}
                            onClick={handleClickEditStock}
                            data-testid="edit-stock"
                        />
                    </Box>
                )}
                {isCustomVehicle && isStockEditable && <FormStockInput name="stockNumber" label="Stock #" />}
            </Box>
            <LinkContainer>
                {isSwitchVehicleEnabled && (
                    <Action
                        onClick={() => {
                            hideDrawer();
                            dispatch(navigationActionCreators.navigateToChangeVehicle());
                        }}
                        size="xs"
                        data-testid="btnChangeVehicle"
                        actionIcon={{
                            icon: <ArrowsRightLeftIcon />,
                            start: true
                        }}
                    >
                        Change Vehicle
                    </Action>
                )}
                {vAutoUrl && (
                    <a
                        href={`${vAutoUrl}&vin=${vin}`}
                        target="_blank"
                        data-testid="vauto-link"
                        rel="noreferrer"
                        onClick={hideDrawer}
                    >
                        <Action
                            size="xs"
                            actionIcon={{
                                icon: <ArrowTopRightOnSquareIcon />,
                                start: true
                            }}
                        >
                            vAuto
                        </Action>
                    </a>
                )}
            </LinkContainer>
        </Box>
    );
};
