import { styled } from 'styled-components';

export const StyledPaymentTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.single-card-view {
        margin: 0;
        margin-top: ${({ theme }) => theme.tokens.ComponentCardPadding};
    }

    &.multi-card-view {
        * {
            color: inherit;
        }
    }
`;
