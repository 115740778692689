import styled from 'styled-components';

export const StyledHeader = styled.div`
    & #slide-out-drawer-header {
        margin-left: 1.5rem;
        margin-top: 1rem;
    }
`;

export const StyledIncentivesHeader = styled.div`
    padding: 1rem;
`;
