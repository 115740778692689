import { EnvironmentType } from '../hostUrlHelper';
import * as offerSessionUtils from '../offerSessionUtils/offerSessionUtils';
import { importModule } from './moduleImport';

declare const __webpack_init_sharing__: (scope: 'default') => Promise<void>;
declare const __webpack_share_scopes__: { default: unknown };

let remoteUrlDefinitions: Record<string, string>;

export function setRemoteDefinitions(definitions: Record<string, string>) {
    remoteUrlDefinitions = definitions;
}

export const getRemoteUrlDefinitions = () => remoteUrlDefinitions;

const remoteModuleMap = new Map<string, unknown>();
const remoteContainerMap = new Map<string, unknown>();

export const getRemoteModuleMap = () => remoteModuleMap;
export const getRemoteContainerMap = () => remoteContainerMap;

export async function loadManifest() {
    try {
        const baseURL =
            offerSessionUtils.getEnvironmentType() === 'prod'
                ? 'https://pipelines.app.coxautoinc.com'
                : 'https://np-pipelines.app.coxautoinc.com'; // "http://localhost:4200" for full local dev experience with local crw
        const moduleFederationManifest = baseURL + '/module-federation.manifest.json';
        const manifest = await fetch(moduleFederationManifest);
        return await manifest.json();
    } catch (error) {
        /* trackInNewRelic({
            componentAction: 'ModuleFederationManifestRequest',
            locationLoadedFrom: moduleFederationManifest,
            errorContext: (error as Error).message
        }); */
        throw new Error('Failed to fetch remote module location manifest');
    }
}

export async function loadRemoteModule(remoteName: string, moduleName: string) {
    if (!remoteUrlDefinitions) {
        const definitions: Record<string, string> = await loadManifest();
        setRemoteDefinitions(definitions);
    }
    const remoteModuleKey = `${remoteName}:${moduleName}`;
    if (remoteModuleMap.has(remoteModuleKey)) {
        return remoteModuleMap.get(remoteModuleKey);
    }

    const container = remoteContainerMap.has(remoteName)
        ? remoteContainerMap.get(remoteName)
        : await loadRemoteContainer(remoteName);

    const factory = await container.get(moduleName);
    const Module = factory();

    remoteModuleMap.set(remoteModuleKey, Module);

    return Module;
}

let initialSharingScopeCreated = false;

export function setInitialSharingScopeCreated(isSet: boolean) {
    initialSharingScopeCreated = isSet;
}

export async function loadRemoteContainer(remoteName: string) {
    if (!remoteUrlDefinitions) {
        throw new Error('Call setRemoteDefinitions to allow Dynamic Federation to find the remote apps correctly.');
    }

    if (!initialSharingScopeCreated) {
        initialSharingScopeCreated = true;
        await __webpack_init_sharing__('default');
    }

    const remoteUrl = remoteUrlDefinitions[remoteName];
    const containerUrl = `${remoteUrl}${remoteUrl.endsWith('/') ? '' : '/'}remoteEntry.js`;

    const container: any = await importModule(containerUrl);
    await container.init(__webpack_share_scopes__.default);

    remoteContainerMap.set(remoteName, container);
    return container;
}
