// externals
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// styles
import { DealAttributesContainer } from './DealAttributesForm.style';

// types
import { VppDealAttributeInput, VppDealAttributeInputType } from '../../../types';

// components
import DealAttributeItem from '../dealAttributeItems/DealAttributeItem';
import LabelWithToolTipIcon from '../../form/LabelWithToolTipIcon';

type DealAttributesFormProps = {
    dealAttributeInputs: VppDealAttributeInput[];
    onChange: (dealAttribute: VppDealAttributeInput, withError?: boolean) => void;
};

const DealAttributesForm = ({ dealAttributeInputs, onChange }: DealAttributesFormProps) => {
    const theme = useInterstateTheme();

    const groups: Record<VppDealAttributeInputType, JSX.Element[]> = {
        [VppDealAttributeInputType.INFO_TOP]: [],
        [VppDealAttributeInputType.DATEPICKER]: [],
        [VppDealAttributeInputType.DROPDOWN]: [],
        [VppDealAttributeInputType.CHECKBOX]: [],
        [VppDealAttributeInputType.INFO]: []
    };
    dealAttributeInputs.forEach((dealAttributeInput) => {
        groups[dealAttributeInput.inputType].push(
            <DealAttributeItem
                key={dealAttributeInput.name}
                dealAttributeInput={dealAttributeInput}
                onChangeValue={(value, withError) =>
                    onChange(
                        {
                            ...dealAttributeInput,
                            value
                        },
                        withError
                    )
                }
            />
        );
    });

    const { info, infoTop, datepicker, dropdown, checkbox } = groups;

    const checkboxGroup = checkbox.length ? (
        <div className="attributes-section">
            <LabelWithToolTipIcon title="Extra settings" name="extra-settings" />
            {checkbox}
        </div>
    ) : null;

    return (
        <DealAttributesContainer theme={theme} className="with-overflow-y">
            Adjust attributes to affect coverages and terms, price calculation accuracy, and availability of protection products.
            {infoTop}
            {datepicker}
            {dropdown}
            {checkboxGroup}
            {info}
        </DealAttributesContainer>
    );
};

export default DealAttributesForm;
