//external
import styled from 'styled-components';

export const ProductSummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 32px;
`;

export const ProductSummaryLine = styled.div`
    display: flex;
`;
