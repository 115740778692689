import { Badge } from '@interstate/components/Badge';
import { Card, CardFooter } from '@interstate/components/Card';
import { Flag } from '@interstate/components/Flag';
import { CheckmarkSolidIcon, DocumentTextIcon, ExclamationCircleIcon, ResetTimeIcon } from '@interstate/components/Icons';
import { formatDollars, kebabCase } from '@makemydeal/dr-common-utils';
import { OfferLenderDecisionPayload } from '@makemydeal/dr-dash-store';
import { DealerDecisionStatus } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CardElement, InfoContainer, InfoDetail, LogoContainer } from './LenderDecisionCard.style';

type LenderDecisionCardProps = {
    isCardActive?: boolean;
    onCardSelected: (lenderId: string) => void;
    data: OfferLenderDecisionPayload;
    showFooter: () => void;
};

const LenderDecisionCard = ({ isCardActive = false, onCardSelected, data, showFooter }: LenderDecisionCardProps) => {
    const {
        approvalStatus,
        approvedAmount,
        approvedRate,
        decisionShown,
        buyRate,
        monthlyPayment,
        approvedTerm,
        lenderId,
        lenderName,
        lenderLogo
    } = data;
    const [imageFetchError, setImageFetchError] = useState(false);
    const enableLenderLogos = useSelector(featureToggleSelectors.isLenderLogosEnabled);
    const toggleEnableBuyRateForCreditDecision = useSelector(featureToggleSelectors.isEnableBuyRateForCreditDecision);

    const handleImageFetchError = () => {
        setImageFetchError(true);
    };

    const handleSelectedCard = () => {
        onCardSelected(lenderId);
    };

    return (
        <CardElement onClick={handleSelectedCard} data-testid="lender-decision-card">
            <Card
                sx={{
                    '.MuiCardActions-root': {
                        justifyContent: 'flex-end'
                    }
                }}
                header={
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                        {decisionShown && (
                            <Flag variant="info" data-testid="badge-customer-viewing">
                                Shared with Customer
                            </Flag>
                        )}
                        <LogoContainer>
                            <div>
                                <InfoDetail>
                                    <span data-testid={`decisionCard-lenderName-${kebabCase(lenderName)}`}>
                                        {!enableLenderLogos || imageFetchError || !lenderLogo ? (
                                            lenderName
                                        ) : (
                                            <img
                                                className="decisionCardLenderLogo"
                                                data-testid={`decisionCard-lenderLogo-${kebabCase(lenderName)}`}
                                                onError={handleImageFetchError}
                                                src={lenderLogo}
                                            />
                                        )}
                                    </span>
                                </InfoDetail>
                            </div>
                            <div>
                                {approvalStatus === DealerDecisionStatus.APPROVED && (
                                    <Badge
                                        id="badgeGreen"
                                        variant="success"
                                        startIcon={<CheckmarkSolidIcon />}
                                        className="statusContainer"
                                    >
                                        <span>{approvalStatus}</span>
                                    </Badge>
                                )}
                                {(approvalStatus === DealerDecisionStatus.CONDITIONALLY_APPROVED ||
                                    approvalStatus === DealerDecisionStatus.COUNTER_OFFER) && (
                                    <Badge
                                        id="badgeYellow"
                                        startIcon={<DocumentTextIcon />}
                                        variant="caution"
                                        className="statusContainer"
                                    >
                                        <span>Check Details</span>
                                    </Badge>
                                )}
                                {(approvalStatus === DealerDecisionStatus.PENDING ||
                                    approvalStatus === DealerDecisionStatus.APP_SUBMITTED) && (
                                    <Badge
                                        id="badgeYellow"
                                        startIcon={<ResetTimeIcon />}
                                        variant="caution"
                                        className="statusContainer"
                                    >
                                        <span>Pending</span>
                                    </Badge>
                                )}
                                {approvalStatus === DealerDecisionStatus.DECLINED && (
                                    <Badge
                                        startIcon={<ExclamationCircleIcon />}
                                        id="badgeRed"
                                        variant="error"
                                        className="statusContainer"
                                    >
                                        <span>{approvalStatus}</span>
                                    </Badge>
                                )}
                            </div>
                        </LogoContainer>
                    </div>
                }
                id="LenderDecisionCard"
                className={isCardActive ? 'selectedCardContainer' : 'cardContainer'}
                content={
                    <InfoContainer>
                        <InfoDetail>
                            <span>Amount</span>
                            <span>{formatDollars(approvedAmount)}</span>
                        </InfoDetail>
                        <InfoDetail>
                            <span>Term</span>
                            <span>{approvedTerm}mos</span>
                        </InfoDetail>
                        <InfoDetail>
                            <span>Buy Rate</span>
                            {toggleEnableBuyRateForCreditDecision ? (
                                buyRate ? (
                                    <span>{buyRate}%</span>
                                ) : (
                                    <span></span>
                                )
                            ) : (
                                <span>{approvedRate}%</span>
                            )}
                        </InfoDetail>
                        <InfoDetail>
                            <span>Payment</span>
                            <span>{monthlyPayment}</span>
                        </InfoDetail>
                    </InfoContainer>
                }
                footer={
                    <CardFooter
                        id={'card-footer-id'}
                        data-testid={'card-footer-data-testid'}
                        actions={[
                            {
                                label: 'Offer Details',
                                id: 'offer-details-link',
                                'data-testid': 'offerDetails-link',
                                onClick: () => showFooter()
                            }
                        ]}
                    />
                }
            />
        </CardElement>
    );
};

export { LenderDecisionCard };
