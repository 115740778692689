import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const DialogHeader = styled.h3``;

export const DialogParagraph = styled.p`
    margin-bottom: 24px;
`;

export const DialogFailedToSend = styled.div`
    background-color: #ff00003b;
    font-size: 13px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    margin: -12px 0px 25px 0px;

    p {
        color: #784c4c;
        margin: 0px;
    }
`;

export const ShareOptions = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 16px;
`;

export const ShareOption = styled.button`
    background-color: transparent;
    border-color: transparent;

    display: flex;
    flex-direction: column;
    align-items: center;

    outline: none;

    p {
        border-bottom: 2px solid transparent;
    }

    &:focus p,
    &:active p,
    &.selected p {
        border-bottom: 2px solid #0d65bf;
    }
`;

export const Circle = styled.div`
    width: 3rem;
    height: 3rem;
    border: 1px solid #0d65bf;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    color: #0d65bf;
    margin-bottom: 4px;
`;

export const ShareOptionLabel = styled.p`
    margin-bottom: 0;
`;

export const StyledTextArea = styled.div`
    margin-bottom: 16px;
    .form-group {
        margin-bottom: 0;
    }
`;

export const ContentContainer = styled.div`
    padding: 32px;
    @media (max-width: ${BreakPoint.PHONE}) {
        padding: 0 16px;
    }
    overflow: hidden;

    // TODO: US1152335 - I need this code due to an interstate bug. Andrew said they will fix it in the next release
    #toggle-button-group-id {
        width: 100%;
        margin: 24px 0;
    }
`;
