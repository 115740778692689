// interfaces/types
import type { CompareToLastOfferPayloadObjectResult } from '../types/comparisonUtilsTypes';

// consts/enums
import { SavedOfferPayloadItemComparison } from '../types/comparisonUtilsEnums';

export const consoleLogComparisonInfo = (comparisonResult: CompareToLastOfferPayloadObjectResult) => {
    const objectForTable: any = {};
    comparisonResult.detailedResult.forEach((item) => {
        const comparisonResultForDisplay = (comparisonResult: SavedOfferPayloadItemComparison) => {
            switch (comparisonResult) {
                case SavedOfferPayloadItemComparison.Modified:
                    return 'Modified';
                case SavedOfferPayloadItemComparison.Unmodified:
                    return '';
                case SavedOfferPayloadItemComparison.SourceOnly:
                    return 'Source';
                case SavedOfferPayloadItemComparison.TargetOnly:
                    return 'Target';
                default:
                    return `UNKNOWN:${comparisonResult}`;
            }
        };
        objectForTable[item.keyPath] = {
            comparison: comparisonResultForDisplay(item.comparisonResult),
            source: item.sourceValue,
            target: item.targetValue
        };
    });
    console.table(objectForTable);
};
