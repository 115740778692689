import {
    accessoriesSelectors,
    configSelectors,
    dealXgDetailsSelectors,
    offerReduxSelectors,
    tradeInSelectors,
    vehicleProtectionSelectors,
    vehicleSelectors
} from '.';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { StateTree } from '@makemydeal/dr-dash-types';
import { OfferType } from '@makemydeal/dr-platform-types';
import {
    ActivityName,
    ACTIVITY_NAME_ACCESSORIES,
    ACTIVITY_NAME_CREDIT_APPLICATION,
    ACTIVITY_NAME_DOC_UPLOAD,
    ACTIVITY_NAME_TEST_DRIVE,
    ACTIVITY_NAME_TRADE_IN,
    ACTIVITY_NAME_VEHICLE_PROTECTION,
    ACTIVITY_NAME_VEHICLE_RESERVATION,
    ACTIVITY_STATE_COMPLETED
} from '@makemydeal/dr-dpm-client';

const isActivityCompleted = (state: StateTree, activity: ActivityName) => {
    return dealXgDetailsSelectors.getDpmStateByActivity(state, activity) === ACTIVITY_STATE_COMPLETED;
};

export const getQrCodeLink = (state: StateTree) => {
    const _bffConfig = configSelectors.getServicesBff(state);
    const _urlConfig = configSelectors.getUrls(state);
    const dealXgId = offerReduxSelectors.getDealXgId(state);
    const prefix = _urlConfig?.sendToShopper || `${_bffConfig.protocol}://${_bffConfig.host}`;
    return `${prefix}/shopperPage.html?dealXgId=${dealXgId}&route=shopper-quote-dashboard`;
};

export const getPaymentData = (state: StateTree, offerType: OfferType) => {
    switch (offerType) {
        case 'finance': {
            return {
                monthlyPayment: offerReduxSelectors.getMonthlyPaymentRoundedByOfferType(state, offerType),
                numberOfMonths: offerReduxSelectors.getSelectedTermMonths(state),
                apr: offerReduxSelectors.getApr(state),
                msrp: vehicleSelectors.getRetailPrice(state),
                advertisedPrice: vehicleSelectors.getListedPrice(state),
                shopperAskingPrice: offerReduxSelectors.getOfferPriceByOfferType(state, offerType),
                sellingPrice: offerReduxSelectors.getOfferPriceByOfferType(state, offerType),
                adjustedCashDown: offerReduxSelectors.getDownPaymentByOfferType(state, offerType),
                manufacturerIncentives: offerReduxSelectors.getAppliedNonconditionalIncentivesAmount(state),
                specialIncentives: offerReduxSelectors.getAppliedConditionalIncentivesAmount(state),
                taxesAndFees: offerReduxSelectors.getTaxesAndFeesTotal(state, offerType),
                vehicleProtection: vehicleProtectionSelectors.getVppSummary(state).totalProductPrice,
                accessories: accessoriesSelectors.getAccessoriesTotalAmount(state),
                netTradeInEquity: tradeInSelectors.getTradeNetValue(state),
                estimatedTotalFinanced: offerReduxSelectors.getSelectedTermAmountFinanced(state),
                totalCashDown: offerReduxSelectors.getDownPaymentByOfferType(state, offerType)
            };
        }
        case 'lease': {
            return {
                monthlyPayment: offerReduxSelectors.getMonthlyPaymentRoundedByOfferType(state, offerType),
                numberOfMonths: offerReduxSelectors.getSelectedTermMonths(state),
                mileage: offerReduxSelectors.getAnnualMiles(state),
                msrp: vehicleSelectors.getRetailPrice(state),
                advertisedPrice: vehicleSelectors.getListedPrice(state),
                shopperAskingPrice: offerReduxSelectors.getOfferPriceByOfferType(state, offerType),
                capitalizedCost: offerReduxSelectors.getCapCostTotal(state),
                capCostReduction: offerReduxSelectors.getCapCostReduction(state, offerType),
                manufacturerIncentives: offerReduxSelectors.getAppliedNonconditionalIncentivesAmount(state),
                specialIncentives: offerReduxSelectors.getAppliedConditionalIncentivesAmount(state),
                netTradeInEquity: tradeInSelectors.getTradeNetValue(state),
                capitalizedFees: offerReduxSelectors.getCapitalizedFeesByOfferType(state, offerType),
                vehicleProtection: vehicleProtectionSelectors.getVppSummary(state).totalProductPrice,
                accessories: accessoriesSelectors.getAccessoriesTotalAmount(state),
                estimatedAdjustedCapCost: offerReduxSelectors.getAdjustedCapCost(state),
                dueAtLeaseSigning: offerReduxSelectors.getDueAtSigningFromDueInAdvance(state)
            };
        }
        case 'cash': {
            return {
                msrp: vehicleSelectors.getRetailPrice(state),
                advertisedPrice: vehicleSelectors.getListedPrice(state),
                shopperAskingPrice: offerReduxSelectors.getOfferPriceByOfferType(state, offerType),
                taxesAndFees: offerReduxSelectors.getTaxesAndFeesTotal(state, offerType),
                netTradeInEquity: tradeInSelectors.getTradeNetValue(state),
                vehicleProtection: vehicleProtectionSelectors.getVppSummary(state).totalProductPrice,
                accessories: accessoriesSelectors.getAccessoriesTotalAmount(state),
                estimatedCashPayment: offerReduxSelectors.getCashTotalAmountDueAfterCredits(state)
            };
        }
    }
};

export const getPrintPayload = (state: StateTree) => {
    const offerType = offerReduxSelectors.getCurrentOfferType(state);

    return {
        applicationName: 'Manager View',
        dealXgId: offerReduxSelectors.getDealXgId(state),
        dealXgVersion: offerReduxSelectors.getDealXgVersion(state),
        data: {
            vehicle: {
                condition: vehicleSelectors.getVehicleCondition(state),
                year: vehicleSelectors.getVehicleYear(state),
                make: vehicleSelectors.getVehicleMake(state),
                model: vehicleSelectors.getVehicleModel(state),
                trim: vehicleSelectors.getVehicleTrim(state),
                vin: vehicleSelectors.getVehicleVin(state),
                stockNumber: vehicleSelectors.getVehicleStockNumber(state),
                imageUrl: vehicleSelectors.getVehicleImageUrl(state)
            },
            dealer: {
                image: '', //There is no such thing that exists. Whenever this exists in the future, we can add it
                address: dealerSelectors.getDealerTextAddress(state),
                phone: dealerSelectors.getDealerPhone(state)
            },
            offerType,
            activityStatus: {
                accessories: isActivityCompleted(state, ACTIVITY_NAME_ACCESSORIES),
                creditApplication: isActivityCompleted(state, ACTIVITY_NAME_CREDIT_APPLICATION),
                uploadDocuments: isActivityCompleted(state, ACTIVITY_NAME_DOC_UPLOAD),
                testDrive: isActivityCompleted(state, ACTIVITY_NAME_TEST_DRIVE),
                tradeIn: isActivityCompleted(state, ACTIVITY_NAME_TRADE_IN),
                vehicleProtection: isActivityCompleted(state, ACTIVITY_NAME_VEHICLE_PROTECTION),
                vehicleReservation: isActivityCompleted(state, ACTIVITY_NAME_VEHICLE_RESERVATION)
            },
            paymentData: getPaymentData(state, offerType),
            showSignatureLine: dealerSelectors.isPrintOfferShowSignatureLineEnabled(state),
            qrCodeLink: getQrCodeLink(state),
            creditScore: offerReduxSelectors.getSelectedCreditTierScore(state).score.low
        }
    };
};
