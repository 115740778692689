export const getRoute = (route: string, baseRoute?: string): string => {
    baseRoute = baseRoute || '/creditDecision';
    return `${baseRoute}${route}`;
};

export const getAllRoutes = (baseRoute?: string): { [key: string]: string } => {
    return {
        root: getRoute('', baseRoute)
    };
};
