// libraries
import { axiosRestApiAdapter, restApiCaller as commonRestApiCaller } from '@makemydeal/dr-common-utils';

// utils
import { axiosInstance } from './axiosInstance';

const apiCaller = axiosRestApiAdapter.buildApiCallerForAxios(axiosInstance);
commonRestApiCaller.setupApiCaller(apiCaller);

export const restApiCaller = commonRestApiCaller;
