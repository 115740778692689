import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LEASE } from '@makemydeal/dr-platform-types';
import { offerActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';

import { Box } from '@interstate/components/Box';
import { Switch } from '@interstate/components/Switch';
import { Typography } from '@interstate/components/Typography';

import TaxTotalOverrideField from './TaxTotalOverrideField';
import TotalSummary from '../../common/TotalSummary';
import TotalTaxUpfront from './TotalTaxUpfront';

import { kebabCase } from '@makemydeal/dr-common-utils';
import { TAX_OVERRIDE } from '../constants';
import { TaxOverrideHeaderContainer } from './TaxOverride.style';
import { getTaxOvverrideDisabled } from './helpers';
import { ManualLegendMessage } from './ManuakLegendMessage';
import { DisabledOverrideTax } from './DisabledOverrideTax';

type TaxOverrideProps = {
    taxOverrideEnabled: boolean;
    taxOverrideFormEdited: boolean;
    setTaxOverrideEnabled: (taxOverride: boolean) => void;
    setTaxOverrideFormEdited: (formEdited: boolean) => void;
};

const id = kebabCase('TaxOverrideToggle');

const TaxOverride = (props: TaxOverrideProps) => {
    const { taxOverrideEnabled, taxOverrideFormEdited, setTaxOverrideEnabled, setTaxOverrideFormEdited } = props;
    const dispatch = useDispatch();
    const totalTax = useSelector(offerReduxSelectors.getTotalTax);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const selectedTerm = useSelector(offerReduxSelectors.getSelectedTerm);
    const monthlyUseTaxTotal = useSelector(offerReduxSelectors.getMonthlyUseTax);
    const capCostReductionTaxTotal = useSelector(offerReduxSelectors.getCapCostReductionTax);
    const hasManualTaxItems = useSelector(offerReduxSelectors.hasManualTaxItems);
    const hasManualLegend = taxOverrideFormEdited || hasManualTaxItems;

    // If we have a monthly use tax or cap cost reduction tax total, then disable the override
    const isTaxOverrideDisabled = getTaxOvverrideDisabled(monthlyUseTaxTotal, capCostReductionTaxTotal);
    const [originalTotalTax, setOriginalTotalTax] = useState(0);

    // Set the original total tax to be reverted to when either:
    // 1. On component mount
    // 2. The selected term changes
    // 3. The offer type changes

    useEffect(() => {
        setOriginalTotalTax(totalTax);
    }, [totalTax, selectedTerm, offerType]);

    const setTaxOverride = () => {
        const payload = taxOverrideEnabled ? undefined : originalTotalTax;
        taxOverrideEnabled && setTaxOverrideFormEdited(false);
        dispatch(offerActionCreators.updatedTotalTaxOverride(payload));
        setTaxOverrideEnabled(!taxOverrideEnabled);
    };

    return isTaxOverrideDisabled ? (
        <DisabledOverrideTax hasManualLegend={hasManualLegend} totalTax={totalTax} />
    ) : (
        <Box>
            <TaxOverrideHeaderContainer data-testid="tax-override-header">
                <Typography variant="h5" color="base.color.black">
                    Total Tax Override
                </Typography>
                <br />
            </TaxOverrideHeaderContainer>
            <Box display="flex">
                <Box marginTop="1rem" width="70%" display="flex" justifyContent="space-between" flexDirection="column">
                    <Switch
                        name={id}
                        checked={taxOverrideEnabled}
                        label={TAX_OVERRIDE}
                        labelSx={{
                            width: 'unset',
                            marginBottom: '16px'
                        }}
                        layout="vertical"
                        onClick={setTaxOverride}
                        data-testid={id}
                    />
                    {hasManualLegend && <ManualLegendMessage />}
                </Box>
                <Box display="flex" alignItems="center" justifyContent="end" width="30%">
                    {taxOverrideEnabled ? (
                        <Box display="flex" flexDirection="row">
                            {offerType === LEASE && <TotalTaxUpfront />}
                            <TaxTotalOverrideField
                                setTaxOverrideFormEdited={setTaxOverrideFormEdited}
                                taxOverrideFormEdited={taxOverrideFormEdited}
                            />
                        </Box>
                    ) : (
                        <TotalSummary totalType="Tax" totalValue={totalTax} paddingRight={2.8} />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default TaxOverride;
