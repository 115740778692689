import { Button } from '@interstate/components/Button';
import { useSlideoutDrawer } from './useSlideoutDrawer';
import { FooterActionContainer, FooterContainer, FooterInfoContainer, LoadingContainer } from './styles';
import { FC, MouseEventHandler, ReactNode } from 'react';
import { Spinner } from '@interstate/components/Spinner';

/**
 * Callback for the primary button click. This will indicate to the slide out the apply is done processing and will become enabled. If true is passed, then the slide out will close.
 */
export type OnPrimaryHandlerCallback = (
    /**
     * Close the slide out? true to close, false to keep open.
     */
    close: boolean
) => void;

export type SlideoutDrawerFooterProps = {
    /**
     * Provide a ReactNode or string text to show on the left side of the footer.
     */
    text?: ReactNode | string;
    /** Show/hide the cancel button */
    showSecondary?: boolean;

    secondaryText?: string;
    primaryText?: string;
    isLoading?: boolean;
    /**
     * onCancel will invoke BEFORE the slider is dismissed
     */
    onSecondary?: () => void;

    /**
     * onPrimary will invoke upon click before the slideout closes to give an opportunity for asynchronous work to be done before closing.
     * While this prop is optional; if provided, you must invoke the callback to complete the slideout close.
     * @param callback `(closeSlider) => void`, Required: Invoke the callback with true (to close) and false (to keep open).
     */
    onPrimary?: (callback: OnPrimaryHandlerCallback) => void;
};

export const SlideoutDrawerFooter: FC<SlideoutDrawerFooterProps> = ({
    secondaryText,
    primaryText,
    onSecondary,
    onPrimary,
    showSecondary,
    text,
    isLoading
}) => {
    const { toggle, toggleBusy, busy } = useSlideoutDrawer();
    const handleSecondaryClicked: MouseEventHandler<HTMLButtonElement> = (_e) => {
        if (onSecondary != null) onSecondary();
        toggle(false);
    };
    const handlePrimaryClicked: MouseEventHandler<HTMLButtonElement> = (_e) => {
        if (onPrimary == null) {
            // NOTE: nothing provided, assume just close
            toggle(false);
            return;
        }

        toggleBusy(true);

        // NOTE: We don't know how long it'll take to finish the OK operation; so we're making it async via callback.
        onPrimary((close) => {
            if (close) toggle(false);
            toggleBusy(false);
        });
    };
    return (
        <FooterContainer>
            {text && <FooterInfoContainer>{text}</FooterInfoContainer>}
            {isLoading && (
                <LoadingContainer data-testid="slideout-footer-loader">
                    <Spinner />
                </LoadingContainer>
            )}
            <FooterActionContainer>
                {showSecondary && (
                    <Button
                        data-testid="slideout-footer-secondary"
                        id="slideout-footer-secondary"
                        disabled={busy}
                        buttonStyle="secondary"
                        onClick={handleSecondaryClicked}
                    >
                        {secondaryText}
                    </Button>
                )}
                <Button
                    data-testid="slideout-footer-primary"
                    id="slideout-footer-primary"
                    buttonStyle={showSecondary ? 'primary' : 'secondary'}
                    onClick={handlePrimaryClicked}
                    disabled={busy}
                >
                    {primaryText}
                </Button>
            </FooterActionContainer>
        </FooterContainer>
    );
};

SlideoutDrawerFooter.defaultProps = {
    showSecondary: false,
    text: <span />, // need the el for flex styling.
    primaryText: 'Close', // by default only primary shows.
    secondaryText: 'Cancel'
};
