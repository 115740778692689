export type PushToRouteOneState = {
    timestamp: string;
    dealXgVersion: string;
    bffResult: { message: string; ok: boolean };
    wsResult: { message: string; ok: boolean };
    status: string;
};

export enum PushToRouteOneStatus {
    Ready = 'ready',
    Waiting = 'waiting',
    Success = 'success',
    Failure = 'failure'
}
