import { Action } from '@interstate/components/Action';
import { navigationActionCreators } from '@makemydeal/dr-dash-store';
import { useDispatch } from 'react-redux';
import { Typography } from '@interstate/components/Typography';
import { ChevronRightIcon } from '@interstate/components/Icons/ChevronRightIcon';

const DigitalDealJacketLink = () => {
    const dispatch = useDispatch();
    return (
        <>
            <Typography variant="h3" sx={{ marginBottom: '0px', marginTop: '10px' }}>
                Digital Deal Jacket
            </Typography>
            <Action
                data-testid="digital-deal-jacket-link"
                sx={{ textAlign: 'left' }}
                onClick={() => dispatch(navigationActionCreators.navigateToDDJ())}
            >
                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                    Manage Documents
                    <ChevronRightIcon fontSize={10} />
                </Typography>
            </Action>
        </>
    );
};

export default DigitalDealJacketLink;
