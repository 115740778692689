import { FunctionComponent, PropsWithChildren } from 'react';
import { DetailContainer } from '../trade.interstate.style';
import { Typography } from '@interstate/components/Typography';

export interface Props {
    label: string;
    'data-testid': string;
}

export const SummaryDataBox: FunctionComponent<PropsWithChildren<Props>> = ({ label, children, 'data-testid': testId }) => {
    return (
        <DetailContainer>
            <Typography
                variant="label-md"
                color="sem.color.on-surface.muted"
                sx={{ marginBottom: '8px' }}
                data-testid={`${testId}-label`}
            >
                {label}
            </Typography>
            <Typography variant="body-md" tag="span" data-testid={`${testId}-value`}>
                {children}
            </Typography>
        </DetailContainer>
    );
};
