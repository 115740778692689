import { useContext } from 'react';
import { CustomerUIContext, CustomerUIContextValue } from './CustomerUIContext';

export const useCustomerUI = (): CustomerUIContextValue => {
    const context = useContext(CustomerUIContext);

    // istanbul ignore next A test for this will just make them noisy.
    if (context == null) throw new Error('useCustomerUI must be used within a CustomerUIProvider.');
    return context;
};
