// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { initActionTypes } from '@makemydeal/dr-shared-store';

// consts/enums
import {
    ADD_CATALOG_ACCESSORIES,
    ADDED_MANUAL_ACCESSORIES,
    ADD_ACCESSORIES_SELECTIONS,
    REMOVE_CATALOG_ACCESSORIES,
    ACCESSORIES_OPEN_FORM
} from '../actionTypes/accessoriesActionTypes';
import { DASH_START_ACCESSORIES_CATALOG } from '../actionTypes/navigationActionTypes';

// actions
import { actions as offerReduxActions } from '@makemydeal/dr-offer-redux';
import {
    addAccessoriesSelections,
    checkAccessoriesCatalogExists,
    completeAccessoriesProgress,
    startAccessoriesProgress,
    useAccessoriesInitSuccess,
    useAccessoriesMenuSuccess
} from '../actions/accessoriesActionCreators';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

// utils
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { accessoriesSelectors } from '../selectors';
import { accessoriesActionCreators } from '../actions';
import { AddCatalogAccessoriesActionPayload, RemoveCatalogAccessoriesActionPayload } from '../types/types';
import { toSelectedAccessory } from '../utils/manualAccessoriesUtils';
import { cloneDeep } from 'lodash';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    const state = store.getState();

    switch (action.type) {
        case 'PROTECTION_LIST_SUCCESS': {
            const isUseAccessoriesFromInitCall = featureToggleSelectors.useAccessoriesFromInitCall(state);

            if (!isUseAccessoriesFromInitCall) {
                store.dispatch(useAccessoriesMenuSuccess(action.payload));
            }
            break;
        }
        case initActionTypes.INIT_PENCIL_SUCCESS: {
            const isUseAccessoriesFromInitCall = featureToggleSelectors.useAccessoriesFromInitCall(state);
            if (isUseAccessoriesFromInitCall) {
                store.dispatch(useAccessoriesInitSuccess(action.payload.accessories));
            }
            store.dispatch(checkAccessoriesCatalogExists());
            break;
        }
        case DASH_START_ACCESSORIES_CATALOG:
        case ACCESSORIES_OPEN_FORM: {
            // Notify DPM middelware to set InProgress the accessories activity
            store.dispatch(startAccessoriesProgress());

            break;
        }
        case ADDED_MANUAL_ACCESSORIES: {
            store.dispatch(addAccessoriesSelections(action.meta?.skipPayment));
            break;
        }
        case ADD_ACCESSORIES_SELECTIONS: {
            // Notify DPM middelware to set Completed the accessories activity
            store.dispatch(completeAccessoriesProgress());

            break;
        }
        case offerReduxActions.PAYMENT_RECEIVED: {
            if (action.meta?.originalAction.type === ADD_ACCESSORIES_SELECTIONS) {
                store.dispatch(accessoriesActionCreators.closeForm());
            }

            break;
        }
        case ADD_CATALOG_ACCESSORIES: {
            const { accessories: catalogAccessories } = action.payload as AddCatalogAccessoriesActionPayload;
            const accessories = cloneDeep(accessoriesSelectors.getAccessoriesList(state));

            catalogAccessories.map(toSelectedAccessory).forEach((newAccessory) => {
                const existingAccessoryIndex = accessories.findIndex((accessory) => accessory.code === newAccessory.code);

                if (existingAccessoryIndex >= 0) {
                    Object.assign(accessories[existingAccessoryIndex], newAccessory);
                } else {
                    accessories.push(newAccessory);
                }
            });

            store.dispatch(accessoriesActionCreators.addManualAccessories(accessories));

            break;
        }
        // This is only for remove process from catalog iframe
        case REMOVE_CATALOG_ACCESSORIES: {
            const { accessories: catalogAccessories } = action.payload as RemoveCatalogAccessoriesActionPayload;
            const accessories = cloneDeep(accessoriesSelectors.getAccessoriesList(state));

            const hasBeenChanged = catalogAccessories.reduce((hasBeenChanged, catalogAccessory) => {
                // part_number will be manualAccessoryId for manual accessories, code for non-manual accessories
                const existingAccessoryIndex = accessories.findIndex(
                    (accessory) =>
                        catalogAccessory.part_number === accessory.manualAccessoryId ||
                        catalogAccessory.part_number === accessory.code
                );

                if (existingAccessoryIndex >= 0) {
                    accessories.splice(existingAccessoryIndex, 1);

                    return true;
                }

                return hasBeenChanged;
            }, false);

            if (hasBeenChanged) {
                store.dispatch(accessoriesActionCreators.addManualAccessories(accessories));
            }

            break;
        }
    }
};
