// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { loadCDLV3Script } from '@makemydeal/dr-common-data-layer-utils';

// interfaces/types
import {
    type DashMiddleware,
    type DashStore,
    type DashNext,
    featureToggleSelectors,
    initActionTypes
} from '@makemydeal/dr-shared-store';
import { HostEnvironment, hostUrlHelper, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export const loadCdlV3ScriptMiddleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    const state = store.getState();
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            const isCDL3EnabledInMV = featureToggleSelectors.isCDL3EnabledInMV(state);
            const environment = hostUrlHelper.getHostUrlEnvironment(MANAGER_VIEW_APP_PREFIX, locHrefUtil.getLocHref());
            const env = environment === HostEnvironment.Prod ? 'prod' : 'non-prod';
            if (isCDL3EnabledInMV) {
                loadCDLV3Script(env);
            }
            break;
        }
    }
};
