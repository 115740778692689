// state
import type { StateTree } from '@makemydeal/dr-dash-types';

// types
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { FeeCategory } from '@makemydeal/dr-shared-types';

export const getFeesByCategory = (state: StateTree, categoryTitle: string): FeeCategory[] => {
    return state.payment.feesByCategory?.[categoryTitle.toLowerCase()] || [];
};

export const getFeesOverrides = (state: StateTree): paymentServicesTypes.FeeOverride[] => {
    return state.payment?.feesOverrides || [];
};
