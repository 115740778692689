export const PUSH_TO_DMS = 'PUSH_TO_DMS';
export const PUSH_TO_DMS_PLUS = 'PUSH_TO_DMS_PLUS';
export const PUSH_TO_DMS_REQUEST = 'PUSH_TO_DMS_REQUEST';
export const PUSH_TO_DMS_SUCCESS = 'PUSH_TO_DMS_SUCCESS';
export const PUSH_TO_DMS_FAILURE = 'PUSH_TO_DMS_FAILURE';
export const PUSH_TO_DMS_ERROR = 'PUSH_TO_DMS_ERROR';
export const COPY_DMS_STOCK_NUMBER = 'COPY_DMS_STOCK_NUMBER';
export const PUSH_TO_DMS_UPDATE_TIMESTAMP = 'PUSH_TO_DMS_UPDATE_TIMESTAMP';
export const PUSH_TO_DMS_WS_EVENT = 'PUSH_TO_DMS_WS_EVENT';
export const PUSH_TO_CDMS_RESET_ERROR = 'PUSH_TO_CDMS_RESET_ERROR';
