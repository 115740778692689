// interfaces/types
import { Accessory } from '@makemydeal/dr-dash-types';
import type { ColumnMappings } from './manualAccessoriesTypes';

export const buildColumnMappings = ({
    amount,
    name,
    code,
    cost,
    profit,
    residual,
    upFront,
    weOwe,
    install
}: Accessory): ColumnMappings => ({
    Name: {
        fieldName: 'name',
        value: name,
        required: true
    },
    ID: {
        fieldName: 'code',
        value: code,
        required: false
    },
    Cost: {
        fieldName: 'cost',
        value: cost,
        required: true
    },
    Sale: {
        fieldName: 'amount',
        value: amount,
        required: true
    },
    Install: {
        fieldName: 'installPrice',
        value: install,
        required: false
    },
    Residual: {
        fieldName: 'residual',
        value: residual,
        required: false
    },
    Profit: {
        fieldName: 'profit',
        value: profit,
        required: false
    },
    Upfront: {
        fieldName: 'upFront',
        value: upFront,
        required: false,
        type: 'checkbox',
        label: 'Upfront'
    },
    WeOwe: {
        fieldName: 'weOwe',
        value: weOwe,
        required: false,
        type: 'checkbox',
        label: 'We Owe'
    }
});
