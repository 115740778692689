import styled from 'styled-components';

export const PriceContainer = styled.div`
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
    font-size: ${({ theme }) => theme.tokens.BrandTypographyFontSizeBase};
    margin-top: 1.5rem;
`;

export const PriceValue = styled.div`
    flex: 1;
    text-align: right;
    font-weight: 500;
`;

export const TextInputContainer = styled.div`
    input {
        text-align: right;
    }
`;

export const ProfitContainer = styled.div`
    display: flex;
    font-size: ${({ theme }) => theme.tokens.BrandTypographyFontSizeBase};
    font-weight: 400;
    line-height ${({ theme }) => theme.tokens.BaseSizeFont20};
    letter-spacing: 0em;
    text-align: left;
`;

export const UpfrontContainer = styled.div`
    display: flex;
    font-size: ${({ theme }) => theme.tokens.BrandTypographyFontSizeBase};
    font-weight: 400;
    line-height ${({ theme }) => theme.tokens.BaseSizeFont20};
    letter-spacing: 0em;
    text-align: left;
`;

export const RadioButtonListContainer = styled.div`
    margin-top: ${({ theme }) => theme.tokens.BaseSpace40};

    .ids-input-layout-message-spacer {
        display: none;
    }

    .ids-input-layout-horizontal div:first-of-type {
        width: 100%;
    }
`;
