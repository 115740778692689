// externals
import { useCallback, useState } from 'react';

// libraries
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { PriceInput, PriceInputProps } from '@interstate/components/PriceInput';
import { isBlurEvent } from '../utils/eventUtils';

export type PriceInputInterstateProps = Omit<PriceInputProps, 'value'> & { value: number };

const PriceInputInterstate = (props: PriceInputInterstateProps) => {
    const { value: numberValue, onChange, onBlur, onFocus } = props;

    const [stringValue, setStringValue] = useState(numberValue.toFixed(2));

    const handleOnChange = useCallback(
        (e: InterstateOnChangeEvent<string>) => {
            if (isBlurEvent(e)) {
                return;
            }

            const eventStringValue = e.target.value || '';
            setStringValue(eventStringValue);

            if (numberValue !== Number(eventStringValue) && onChange) {
                onChange(e);
            }
        },
        [onChange, numberValue]
    );

    const handleOnBlur = useCallback(
        (e: InterstateOnChangeEvent<string>) => {
            const inputValue = !isNaN(Number(stringValue)) ? Number(stringValue) : 0;
            const fixedValue = inputValue.toFixed(2);
            setStringValue(fixedValue);

            if (onBlur) {
                onBlur(e);
            }
        },
        [onBlur, stringValue]
    );

    const handleOnFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (Number(stringValue) === 0) {
                setStringValue('0');
            }
            if (onFocus) {
                onFocus(e);
            }
        },
        [onFocus, stringValue]
    );

    return <PriceInput {...props} onChange={handleOnChange} onFocus={handleOnFocus} onBlur={handleOnBlur} value={stringValue} />;
};

export default PriceInputInterstate;
