import styled from 'styled-components';

import { BreakPoint } from '@makemydeal/dr-activities-common';
import { getMaxAppContainerWidth } from '@makemydeal/dr-shared-ui-utils';

export const ActionsContainer = styled.div<{ isNewAppWidthEnabled: boolean }>`
    display: flex;
    flex-direction: column-reverse;
    background-color: ${({ theme }) => theme.tokens.BaseColorWhite};
    gap: 1rem;

    width: 100%;
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;

    box-shadow: ${({ theme }) => ` 0 0 4px 0 ${theme.tokens.BaseColorGray300}`};
    padding: 16px;

    .desking-btn-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .share-container-buttons {
        display: flex;
        flex-direction: column-reverse;
        gap: 16px;
    }

    @media (min-width: ${BreakPoint.SMALL}) {
        margin-top: 10px;
        flex-direction: row;

        .desking-btn-container {
            flex: 1;
            flex-direction: row;
        }

        .share-container-buttons {
            flex-direction: row;
        }
    }
`;

// NOTE: This is a fix to show the shadow on the top side of the container only
export const CutOffShadowContainer = styled.div<{ isNewAppWidthEnabled: boolean }>`
    width: 100%;
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;
    overflow: hidden;
`;

export const FooterContainer = styled.div``;
