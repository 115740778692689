import { CustomInterstateValidationRule } from '../types/CustomInterstateValidationRule';

export const getMaxValueValidationRule = (
    maxValue: number,
    errorMessage: string,
    isMaxValueAllowed: boolean
): CustomInterstateValidationRule => {
    return (value) => {
        const numericValue = Number(value);

        if (Number.isFinite(numericValue)) {
            return {
                isValid: isMaxValueAllowed ? numericValue <= maxValue : numericValue < maxValue,
                errorMessage
            };
        }

        return { isValid: true, errorMessage };
    };
};

export const getMinValueValidationRule = (
    minValue: number,
    errorMessage: string,
    isMinValueAllowed: boolean
): CustomInterstateValidationRule => {
    return (value) => {
        const numericValue = Number(value);

        if (Number.isFinite(numericValue)) {
            return { isValid: isMinValueAllowed ? numericValue >= minValue : numericValue > minValue, errorMessage };
        }

        return { isValid: true, errorMessage };
    };
};

export const getMaxLengthValidationRule = (maxLength: number, errorMessage: string): CustomInterstateValidationRule => {
    return (value) => {
        if (value) {
            return { isValid: String(value).length <= maxLength, errorMessage };
        }

        return { isValid: true, errorMessage };
    };
};

export const getRequiredValidationRule = (errorMessage: string): CustomInterstateValidationRule => {
    return (value) => {
        return {
            isValid: value !== undefined && value !== null && value !== '',
            errorMessage
        };
    };
};
