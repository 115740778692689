// externals
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

// libraries
import { IRootProps, TRADE_IN } from '@makemydeal/dr-activities-tradein';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

// config
import { getConfigTradeFromState, getTradeInSelectors } from '../config';

// components
import { TradeInUI } from './TradeInUI';

export const mapStateToProps = (state: StateTree, ownProps: RouteComponentProps): IRootProps => {
    const selectors = getTradeInSelectors();
    const {
        checkIsSubaruGtpEligible,
        // getTradeInCurrentLocationRoute,
        // getTradeInCurrentLocationRouteForIco,
        icoTradeFlow,
        isICO,
        isShopperEnteredTrade,
        isTradeInCompleted,
        isTradeInIcoEnabled,
        getTradeDecisionPage,
        getManualTradeDecision
    } = selectors;

    const getRoute = () => {
        if (isTradeInCompleted(state)) {
            // click Edit on Trade-In card

            if (dealerSelectors.isSubaruGtpEnabled(state) && checkIsSubaruGtpEligible(state)) {
                return '/tradeSubaruEligibility';
            }

            if (isShopperEnteredTrade(state)) {
                return '/tradeInManualEntry';
            }

            if (isICO(state)) {
                return '/tradeInIco';
            }

            return '/vehicleInfo'; // default when Trade-In is completed
        }

        const icoIsOn = isTradeInIcoEnabled(state) && icoTradeFlow(state);

        if ((icoIsOn && getTradeDecisionPage(state)) || (getTradeDecisionPage(state) && getManualTradeDecision(state))) {
            return '/tradeInDecision';
        }

        if (icoIsOn) {
            // should show ICO page when Admin toggles for Decision page is disabled and ICO is enabled.
            return '/tradeInIco';
        }

        return '/tradeInSearch'; // default when Trade-In is not completed
    };

    const currentRoute = getRoute();
    // TODO: DASH - implement this later if needed
    // const { widgetSettings } = state;
    // const sponsor = (widgetSettings && widgetSettings.sponsor) || '';
    // const isAtcOrKbbSite = validator.isListingSite(sponsor);
    // const optimizelyWhichSite = isAtcOrKbbSite
    //     ? optimizelyUtils.getTestValue(optimizelyUtils.OPTIMIZELY_TEST_NAMES.TRADEIN_SINGLE_COLUMN_ATC_KBB, state)
    //     : optimizelyUtils.getTestValue(optimizelyUtils.OPTIMIZELY_TEST_NAMES.TRADEIN_SINGLE_COLUMN_DDC, state);
    const isOptimizelySingleCol = true; // optimizelyWhichSite === 'SINGLE_COL';

    const props: IRootProps = {
        baseRoute: TRADE_IN,
        config: {
            ...getConfigTradeFromState(state),
            userContext: {
                isDealer: true,
                isDesking: true
            }
        },
        selector: selectors,
        actionCreators: {},
        isOptimizelySingleCol,
        currentRoute
    };
    return props;
};

const connectWrapper = connect(mapStateToProps);
const connectedTradeInUi = connectWrapper(TradeInUI);

export const TradeInContainer = connectedTradeInUi;
