import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IDeskingActivity } from '../types/IDeskingActivity';
import { getAllRoutes } from '../utils/routes';

import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { ActivityRouteContainer } from '@makemydeal/dr-dash-components';
import InterstateDeskingActivity from './DeskingActivity.interstate';

const Root = (props: IDeskingActivity) => {
    const { root } = getAllRoutes(props.baseRoute);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    return (
        <ActivityRouteContainer
            data-testid="activities-desking-container"
            className="activities-desking"
            isNewAppWidthEnabled={isABPencilEnabled}
        >
            <Route path={root} component={InterstateDeskingActivity} />
        </ActivityRouteContainer>
    );
};

Root.displayName = 'DeskingActivity';

export default Root;
