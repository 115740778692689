// externals
import { Draft, produce } from 'immer';
import { Reducer } from 'redux';

// libraries
import type { ManualIncentivesState } from '@makemydeal/dr-dash-types';
import type { AnyFSA } from '@makemydeal/dr-platform-shared';

// consts/enums
import {
    MANUAL_INCENTIVES_EDIT_ADD,
    MANUAL_INCENTIVES_EDIT_INIT,
    MANUAL_INCENTIVES_EDIT_REMOVE,
    MANUAL_INCENTIVES_EDIT_SHOW_VALIDATION,
    MANUAL_INCENTIVES_EDIT_UPDATE_FIELD,
    OPEN_MANUAL_INCENTIVE_FORM
} from '../actionTypes/manualIncentivesActionTypes';

// utils
import { convertToNumber } from '../utils/formatUtils';

export type ManualIncentivesReducerState = Reducer<ManualIncentivesState>;
const initialState = {
    showValidation: false,
    isManualFormOpen: false
};

export const reducer: ManualIncentivesReducerState = (state = initialState, action?: AnyFSA) => {
    if (!action) {
        return state;
    }

    return produce(state, (draft: Draft<any>) => {
        switch (action.type) {
            case MANUAL_INCENTIVES_EDIT_SHOW_VALIDATION: {
                return {
                    ...state,
                    showValidation: action.payload
                };
            }

            case MANUAL_INCENTIVES_EDIT_INIT: {
                return { ...state, manualIncentivesEdit: action.payload, showValidation: false };
            }

            case MANUAL_INCENTIVES_EDIT_ADD: {
                if (state.manualIncentivesEdit == null) return { ...state, manualIncentivesEdit: [action.payload] };
                return { ...state, manualIncentivesEdit: [...state.manualIncentivesEdit, action.payload] };
            }

            case MANUAL_INCENTIVES_EDIT_REMOVE: {
                const index = action.payload;
                if (state.manualIncentivesEdit == null) return state;

                const updated = state.manualIncentivesEdit.map((row, i) => {
                    if (i !== index) return row;
                    const newThing = { ...row };
                    newThing.deleted = !newThing.deleted;

                    return newThing;
                });

                return { ...state, manualIncentivesEdit: updated };
            }

            case MANUAL_INCENTIVES_EDIT_UPDATE_FIELD: {
                const { index, field, value } = action.payload;
                if (state.manualIncentivesEdit == null) return state;

                const updated = state.manualIncentivesEdit.map((row, i) => {
                    if (i !== index) return row;
                    const original = row;
                    let textValue: string | number;
                    const updatedEntry: any = { ...row };

                    switch (field) {
                        case 'code':
                        case 'amount':
                            textValue = convertToNumber(value);
                            break;
                        default:
                            textValue = value as string;
                    }

                    updatedEntry[field] = textValue; // field value

                    if (original.originalAmount !== updatedEntry.amount) {
                        updatedEntry.manual = true;
                    }
                    return updatedEntry;
                });

                return { ...state, manualIncentivesEdit: updated };
            }

            case OPEN_MANUAL_INCENTIVE_FORM: {
                return {
                    ...state,
                    isManualFormOpen: action.payload ?? true
                };
            }

            default:
                return state;
        }
    });
};
