import { ActionsInterstate } from '@makemydeal/dr-dash-components';

// styling
import { StyledDeskingActions } from './DeskingActions.interstate.style';

const DeskingActionsInterstate = () => {
    // istanbul ignore next
    return (
        <StyledDeskingActions className="text-center" data-testid="desking-actions-container">
            <ActionsInterstate></ActionsInterstate>
        </StyledDeskingActions>
    );
};
export default DeskingActionsInterstate;
