import React, { useEffect } from 'react';
import { ResultsContainer } from './BuildVehicleSearchResults.style';
import { Typography } from '@interstate/components/Typography';
import { useDispatch, useSelector } from 'react-redux';

import { changeVehicleCreators, changeVehicleSelectors } from '../../store';
import { CatalogVehicle } from '../../types';

import NotInStockVehicle from './NotInStockVehicle';

type VehicleResultsProps = {
    vehicles: CatalogVehicle[];
    selectValues: {
        inventoryType: string;
        year: string;
        make: string;
        model: string;
        trim: string;
    };
};

const BuildVehicleSearchResults: React.FC<VehicleResultsProps> = ({ vehicles, selectValues }) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector(changeVehicleSelectors.getSelectedVehicle);

    const getResultMessage = () => {
        const totalResults = vehicles.length;
        return totalResults > 1 ? `Vehicle Results (${totalResults})` : 'Vehicle Result';
    };

    useEffect(() => {
        dispatch(changeVehicleCreators.updateCustomVehicle(undefined));
    }, [dispatch, selectedVehicle]);

    return (
        <ResultsContainer>
            <Typography variant="h5" tag="div" sx={{ marginBottom: '16px' }}>
                {getResultMessage()}
            </Typography>
            {vehicles.map((vehicle, index) => {
                return (
                    <NotInStockVehicle key={`buildVehicle-${index}`} index={index} vehicle={vehicle} selectValues={selectValues} />
                );
            })}
        </ResultsContainer>
    );
};
export default BuildVehicleSearchResults;
