import { Grid } from '@interstate/components/Grid';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const DealPulseContainer = styled(Grid)`
    padding: 8.5px !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
    border: 1px solid #b2b2b2;
    border-radius: 4px;
`;

export const DealPulseCard = styled(Grid)`
    background-color: #f4f4f4;
    padding: 8px !important;
`;

export const RangeTitle = styled.span`
    color: #6d6d6d;
`;

export const DealPulseHeader = styled.h3`
    margin-bottom: 8px;
`;

export const DealPulseWrapper = styled.div`
    padding: 16px !important;
    display: grid;
    grid-gap: 4px;
    grid-template-columns: 1fr 1fr;
    border: 1px solid #b2b2b2;

    @media (min-width: ${BreakPoint.SMALL}) {
        grid-template-columns: 1fr;
    }
    @media (min-width: ${BreakPoint.MEDIUM}) {
        grid-template-columns: 1fr 1fr;
    }
`;

export const DealPulseElement = styled.div`
    background-color: #f4f4f4;
    padding: 8px !important;
`;

export const DealPulseInfoHeaders = styled.div`
    display: flex;
    flex-direction: row;

    @media (min-width: ${BreakPoint.MEDIUM}) {
        flex-direction: column;
    }
`;
export const DealPulseInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
`;

export const DealPulseRangeContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
`;
