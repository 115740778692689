// libraries
import { validator } from '@makemydeal/dr-platform-shared';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

// interfaces/types
import type { StateTree } from '@makemydeal/dr-dash-types';

export const getVdpUrl = (state: StateTree) => {
    const { vdpSource } = state;
    return vdpSource && vdpSource.vdpUrl;
};

export const getVdpSponsor = (state: StateTree) => {
    const { vdpSource } = state;
    return vdpSource && vdpSource.sponsor;
};

/**
 * This function returns the listingCode.
 *
 * @param state
 *     The state tree
 * @param computedSponsor
 *     The sponsor retrieved from widget settings or other sources as needed
 */
export const getListingCode = (state: StateTree, computedSponsor: string | undefined): string => {
    if (computedSponsor && validator.isListingSite(computedSponsor)) {
        return String(dealerSelectors.getOwnerId(state)) || '';
    } else {
        const { vdpSource } = state;
        return (vdpSource && vdpSource.listingCode) || '';
    }
};
