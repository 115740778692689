// TODO: DASH - I'm not crazy about this pattern... I think we have other issues that are forcing us to use this...
//              and we should rather fix those other issues instead.

// This class was added for all feature packages
// to be able to register there selectors here
// this will allow circular dependencies to not be an issue
// given the fact that we can retrieve from this collection using
// a type from the feature package, but we will not have any of the cycles
// generated when importing directly from the file that exists
import { BaseSelectors } from '@makemydeal/dr-activities-common';

class FeaturePackageSelectors {
    private _selectors: { [key: string]: BaseSelectors } = {};

    addOrReplace = (key: string, selector: BaseSelectors): void => {
        this._selectors[key] = selector;
    };

    remove = (key: string): void => {
        if (this._selectors[key]) {
            delete this._selectors[key];
        }
    };

    get = <T extends BaseSelectors>(key: string): T => {
        return this.getBase(key) as T;
    };

    getBase = (key: string): BaseSelectors => {
        return this._selectors[key];
    };
}

export const featurePackageSelectors = new FeaturePackageSelectors();
