import { DynamicSurcharge } from '@makemydeal/dr-platform-types';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { DealProductAddOn, ProductAddOnDetails } from '@makemydeal/dr-shared-types';
import { DraftDealProduct } from '@makemydeal/dr-dash-types';

export const calculateAddOnsCost = (addOns: DealProductAddOn[]) => {
    return addOns
        .filter((addOn) => addOn.isAddOnEditable !== false)
        .reduce((acc: number, addOn: DealProductAddOn) => acc + addOn.addOnCost, 0);
};

export const calculateAddOnsPrice = (addOns: DealProductAddOn[]) => {
    return addOns
        .filter((addOn) => addOn.isAddOnEditable !== false)
        .reduce((acc: number, addOn: DealProductAddOn) => acc + addOn.addOnPrice, 0);
};

const mapDynamicSurchargeToAddOn = (dynamicSurcharge: DynamicSurcharge): DealProductAddOn => {
    return {
        addOnCost: dynamicSurcharge.cost,
        addOnDescription: dynamicSurcharge.description,
        addOnPrice: dynamicSurcharge.price,
        addOnSurchargeCode: dynamicSurcharge.surchargeCode,
        isAddOnEditable: dynamicSurcharge.isEditable
    };
};

export const mapDynamicSurchargesToProductAddOns = (product: DraftDealProduct, dynamicSurcharges: DynamicSurcharge[]) => {
    const selectedAddOns = dynamicSurcharges
        .filter(
            (item) =>
                product.productAddOnDetails?.productAddOns?.find((addOn) => addOn.addOnSurchargeCode === item.surchargeCode) ||
                (item.isEditable === false && item.value)
        )
        .map(mapDynamicSurchargeToAddOn);

    const unselectedAddOns = dynamicSurcharges
        .filter(
            (item) =>
                !product.productAddOnDetails?.productAddOns?.find((addOn) => addOn.addOnSurchargeCode === item.surchargeCode) &&
                item.isEditable !== false
        )
        .map(mapDynamicSurchargeToAddOn);

    return { selectedAddOns, unselectedAddOns };
};

export const mapProductAddOnsToModel = (productAddOnDetails: ProductAddOnDetails) => {
    const selectedAddOns =
        productAddOnDetails.productAddOns
            ?.filter((item) => item.isAddOnEditable)
            .map((item) => ({
                ...item,
                selected: true
            })) ?? [];

    const unselectedAddOns =
        productAddOnDetails.unselectedProductAddOns
            ?.filter((item) => item.isAddOnEditable)
            .map((item) => ({
                ...item,
                selected: false
            })) ?? [];

    const allAddOns = [...selectedAddOns, ...unselectedAddOns];

    const addOnsModel = allAddOns.map((addOn) => {
        const formattedCost = formatDollarsAndCents(addOn.addOnCost);
        const description = addOn.addOnDescription || addOn.addOnSurchargeCode;
        return {
            value: addOn.addOnSurchargeCode,
            label: `${description} (+${formattedCost})`,
            cost: formattedCost,
            selected: addOn.selected
        };
    });
    const sortedAddOnsModel = addOnsModel.sort((a, b) => a.label.localeCompare(b.label));
    return sortedAddOnsModel;
};

export const selectProductAddOns = (addOns: DealProductAddOn[], selected: string[]) => {
    const selectedAddOns = addOns.filter((addOn) => selected.includes(addOn.addOnSurchargeCode) || !addOn.isAddOnEditable);

    const unselectedAddOns = addOns.filter((addOn) => !selected.includes(addOn.addOnSurchargeCode) && addOn.isAddOnEditable);

    return { selectedAddOns, unselectedAddOns };
};

export const getIncludedAddOnNames = (productAddOnDetails: ProductAddOnDetails = {}): string[] => {
    const includedAddOns = productAddOnDetails.productAddOns?.filter((addOn) => addOn.isAddOnEditable === false) ?? [];
    return includedAddOns.map((addOn) => addOn.addOnDescription ?? addOn.addOnSurchargeCode);
};
