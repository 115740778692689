// externals
import { memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { tradeInActionCreators, tradeInCardSelectors, tradeInSelectors } from '@makemydeal/dr-dash-store';

// components
import TradeVinDecode from '../TradeVinDecode';
import { TradeDetailsAccordion } from '../TradeDetailsAccordion.interstate';
import { CheckBox, CheckBoxEventValue } from '@interstate/components/CheckBox';
import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { BodyStyleField } from '../vehicle/BodyStyleField.interstate';
import { DoorsField } from '../vehicle/DoorsField.interstate';
import { InteriorColorField } from '../vehicle/InteriorColorField.interstate';
import { EngineField } from '../vehicle/EngineField.interstate';
import { TransmissionField } from '../vehicle/TransmissionField.interstate';
import { DriveLineField } from '../vehicle/DriveLineField.interstate';
import YearsField from '../vehicle/YearsField.interstate';
import MakesField from '../vehicle/MakesField.interstate';
import ModelsField from '../vehicle/ModelsField.interstate';
import TrimsField from '../vehicle/TrimsField.interstate';
import { OdometerField } from '../vehicle/OdometerField.interstate';
import ExteriorColorField from '../vehicle/ExteriorColorField.interstate';
import VehicleConditionField from '../vehicle/VehicleConditionField.interstate';

// constants
import { LEASE, LEASE_TRADE, PURCHASE } from '../../../constants';

// hooks
import { useScrollIntoView } from '../../../utils/useScrollIntoView';

import { ManualTradeInActions } from './ManualTradeInActions';
import { ContainerGrid, ContainerGridItem } from '@makemydeal/dr-dash-components';
import { useMediaQuery } from 'react-responsive';
import { BreakPoint } from '@makemydeal/dr-activities-common';

const ManualTradeIn = memo(() => {
    const elementRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    const hasTradeIn = useSelector(tradeInSelectors.isTradeInCompleted);
    const isManualFormOpen = useSelector(tradeInCardSelectors.isManualFormOpen);
    const ownershipType = useSelector(tradeInSelectors.getTradeOwnershipType);

    const updateOwnershipType: InterstateOnChangeCallback<CheckBoxEventValue> = (
        event: InterstateOnChangeEvent<CheckBoxEventValue>
    ) => {
        /* istanbul ignore next */
        const changeOwnershipType = event.target.value?.checkboxValue === LEASE ? PURCHASE : LEASE;
        dispatch(tradeInActionCreators.updateTradeInOwnershipType(changeOwnershipType));
    };

    useScrollIntoView(elementRef, isManualFormOpen);

    const isSmallScreenSize = useMediaQuery({ query: `(max-width: ${BreakPoint.SMALL})` });

    return (
        <div ref={elementRef}>
            <ContainerGrid>
                {isManualFormOpen && (
                    <ContainerGridItem xs={12} md={8}>
                        {isManualFormOpen && <TradeVinDecode isManualFormOpen={isManualFormOpen} />}
                    </ContainerGridItem>
                )}

                <ContainerGridItem xs={12} md={4}>
                    <OdometerField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                </ContainerGridItem>

                {isManualFormOpen && (
                    <>
                        <ContainerGridItem xs={6} md={4}>
                            <YearsField isManualFormOpen={isManualFormOpen} />
                        </ContainerGridItem>
                        <ContainerGridItem xs={6} md={4}>
                            <MakesField isManualFormOpen={isManualFormOpen} />
                        </ContainerGridItem>
                        <ContainerGridItem xs={6} md={4}>
                            <ModelsField isManualFormOpen={isManualFormOpen} />
                        </ContainerGridItem>
                        <ContainerGridItem xs={6} md={4}>
                            <TrimsField isManualFormOpen={isManualFormOpen} />
                        </ContainerGridItem>
                    </>
                )}
                <ContainerGridItem xs={6} md={4}>
                    <ExteriorColorField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <VehicleConditionField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                </ContainerGridItem>
                {isManualFormOpen && (
                    <ContainerGridItem xs={12}>
                        <CheckBox
                            checked={ownershipType === LEASE ? true : false}
                            label={LEASE_TRADE}
                            onChange={updateOwnershipType}
                            value={ownershipType}
                        />
                    </ContainerGridItem>
                )}
                <ContainerGridItem xs={12}>
                    <TradeDetailsAccordion isManualFormOpen={isManualFormOpen}>
                        <ContainerGrid>
                            <ContainerGridItem xs={6} md={4}>
                                <EngineField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                            </ContainerGridItem>

                            <ContainerGridItem xs={6} md={4}>
                                <TransmissionField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                            </ContainerGridItem>
                            <ContainerGridItem xs={6} md={4}>
                                <DriveLineField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                            </ContainerGridItem>
                            <ContainerGridItem xs={6} md={4}>
                                <BodyStyleField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                            </ContainerGridItem>
                            <ContainerGridItem xs={6} md={4}>
                                <DoorsField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                            </ContainerGridItem>
                            <ContainerGridItem xs={6} md={4}>
                                <InteriorColorField isManualFormOpen={isManualFormOpen} hasTradeIn={hasTradeIn} />
                            </ContainerGridItem>
                        </ContainerGrid>
                    </TradeDetailsAccordion>
                </ContainerGridItem>
                {isManualFormOpen && <ContainerGridItem xs={12}>{isManualFormOpen && <ManualTradeInActions />}</ContainerGridItem>}
            </ContainerGrid>
        </div>
    );
});

export default ManualTradeIn;
