import type { StateTree } from '@makemydeal/dr-dash-types';

export const getCustomerCardShowing = (state: StateTree) => state.customerUI.customerCard.show;
export const getCustomerCardOptions = (state: StateTree) =>
    state.customerUI.customerCard.options ?? {
        commonConsumerId: '',
        tab: 'information',
        waitForOfferSave: false,
        readOnlyFields: false
    };

export const getCustomerSearchShowing = (state: StateTree) => state.customerUI.customerSearchShow;
