// externals
import { Store } from 'redux';

// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';

// TODO: Restore the code on the next line when we can resolve the CodeBuild issue (error TS2707 - see below)
// `export const dispatchAnalyticsAction = (_dispatch: Dispatch<AnyAction, any>, type: string, description: string): void => {`
//
// Error reported when executing libs/accelerate/dash-store/src/utils/__tests__/analyticsDispatcher.spec.ts tests:
//   error TS2707: Generic type 'Dispatch<A>' requires between 0 and 1 type arguments.
export const dispatchAnalyticsAction = (_dispatch: any, type: string, description: string): void => {
    if (type) {
        // TODO: DASH - remove after implementing analytics

        console.log('FAKING ANALYTICS DISPATCH (3)', description);
        // TODO: DASH - restore this later
        // const analyticsAction = getAnalyticsAction(type, description);
        // dispatch(analyticsAction);
    }
};

export const dispatchActionWithAnalytics = (store: Store<StateTree>, type: string, description: string) => {
    dispatchAnalyticsAction(store.dispatch, type, description);
};
