// externals
import React, { ReactNode, useDeferredValue, useEffect, useState } from 'react';

// libraries
import { FloatingButton } from '@interstate/components/FloatingButton';
import { ChevronRightIcon } from '@interstate/components/Icons';
import { ChevronLeftIcon } from '@interstate/components/Icons/ChevronLeftIcon';

// styling
import { StyledCarouselContainer, StyledItemsContainer } from './SliderCarousel.style';

const SliderCarousel = ({ children, id }: { children: ReactNode[]; id: string }) => {
    const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
    const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
    const [size, setSize] = useState(window.innerWidth);
    const deferredSize = useDeferredValue(size);

    const ref = React.useRef<HTMLDivElement>(null);

    const scrollLeft = () => {
        if (ref.current) {
            const scroller = ref.current;
            scroller.scrollLeft -= 320;
        }
    };

    const scrollRight = () => {
        if (ref.current) {
            const scroller = ref.current;
            scroller.scrollLeft += 320;
        }
    };

    const showArrow = () => {
        if (ref.current) {
            const marginError = 5;
            const showLeft = ref.current.scrollLeft > 0;
            const showRight = ref.current.scrollLeft + ref.current.offsetWidth < ref.current.scrollWidth - marginError;

            setShowLeftArrow(showLeft);
            setShowRightArrow(showRight);
        }
    };

    useEffect(() => {
        const checkSize = () => {
            setSize(window.innerWidth);
        };
        window.addEventListener('resize', checkSize);
        return () => {
            window.removeEventListener('resize', checkSize);
        };
    }, []);

    useEffect(() => {
        if (ref.current) {
            const showRightRow = ref.current.scrollWidth > ref.current.offsetWidth;
            setShowRightArrow(showRightRow);
        }
    }, [deferredSize, children]);

    return (
        <StyledCarouselContainer>
            {showLeftArrow && (
                <FloatingButton
                    icon={{
                        icon: <ChevronLeftIcon />,
                        placement: 'iconOnly'
                    }}
                    data-testid={`left-carousel-btn-${id}`}
                    style={{
                        fontSize: '20px',
                        color: '#005BA8',
                        backgroundColor: '#fff',
                        zIndex: '1000'
                    }}
                    onClick={scrollLeft}
                    placement="left"
                ></FloatingButton>
            )}
            <StyledItemsContainer data-testid={`${id}-scroller`} id={`${id}-scroller`} ref={ref} onScroll={showArrow}>
                {children}
            </StyledItemsContainer>
            {showRightArrow && (
                <FloatingButton
                    icon={{
                        icon: <ChevronRightIcon />,
                        placement: 'iconOnly'
                    }}
                    data-testid={`right-carousel-btn-${id}`}
                    style={{
                        fontSize: '20px',
                        color: '#005BA8',
                        backgroundColor: '#fff',
                        zIndex: '1000'
                    }}
                    onClick={scrollRight}
                    placement="right"
                ></FloatingButton>
            )}
        </StyledCarouselContainer>
    );
};

export default SliderCarousel;
