// externals
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';

// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { configSelectors } from '@makemydeal/dr-dash-store';

// routing
import { Routes, RoutesDispatchProps, RoutesStateProps } from './routes';

export const mapStateToProps = (state: StateTree, ownProps: RouteComponentProps): RoutesStateProps => {
    const toggles = featureToggleSelectors.getFeatureToggles(state);
    const staticAssets = configSelectors.getStaticAssets(state);
    const dealer = dealerSelectors.getDealerState(state);

    return {
        toggles,
        staticAssets,
        dealer
    };
};

export const mapDispatchToProps = (): RoutesDispatchProps => {
    return {};
};

const connectResult = connect(mapStateToProps, mapDispatchToProps);
const Routing = connectResult(Routes);

export default Routing;
