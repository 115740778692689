import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const DashboardLifecycleContainer = styled.div`
    margin-bottom: 0.5rem;
    text-align: right;

    .badge {
        font-size: 14px;
        padding: 4px 12px;
    }

    Button {
        padding: 0 0 0 0.9rem;
        font-weight: 500;
        font-size: 14px;

        &:focus {
            box-shadow: none;
        }

        @media (min-width: ${BreakPoint.SCREEN_SM_MIN}) {
            font-size: 16px;
        }
    }
`;

export const DashboardDealDate = styled.div`
    font-size: 12px;
    text-align: right;
    color: #6d727a;
    margin-bottom: 0.5rem;

    @media (min-width: ${BreakPoint.SCREEN_SM_MIN}) {
        font-size: 14px;
    }
`;
