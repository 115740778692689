// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { ProgramQuote } from '@makemydeal/dr-dash-types';
import { MANAGER_VIEW_APP_PREFIX, apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import { paymentPayloadUtils } from '@makemydeal/dr-offer-redux';
import { LEASE } from '@makemydeal/dr-platform-types';

// actions
import {
    PROGRAMS_CLEAR_ALL,
    PROGRAMS_CLEAR_INCENTIVES,
    PROGRAMS_CLEAR_SELECTED,
    PROGRAMS_INCENTIVES_REQUEST,
    PROGRAMS_INCENTIVES_REQUEST_FAILURE,
    PROGRAMS_INCENTIVES_REQUEST_SUCCESS,
    PROGRAMS_SELECT,
    REQUEST_PROGRAMS,
    REQUEST_PROGRAMS_FAILURE,
    REQUEST_PROGRAMS_SUCCESS,
    TOGGLE_INCENTIVE_FOR_PROGRAM
} from '../actionTypes/offerActionTypes';

// consts/enums
import { APPLICATION_NAME } from '../constants';

// selectors
import * as programSelectors from '../selectors/programsSelectors';
import { getSelectedProgram, getSelectedProgramIncentives } from '../selectors/programsSelectors';

// utils
import {
    getAnnualMiles,
    getCurrentOfferType,
    getSelectedIncentivesByOfferType,
    getSelectedTerm,
    getTermId,
    getTermIdForOfferType
} from '../selectors/offerRedux';
import { programPayloadUtil } from '../utils';

export const selectProgramQuote = (record: ProgramQuote) => {
    return {
        type: PROGRAMS_SELECT,
        payload: record,
        meta: {
            incentives: true
        }
    };
};

export const toggleSelectedIncentive = (incentive: any, selected: boolean) => {
    return { type: TOGGLE_INCENTIVE_FOR_PROGRAM, payload: { incentive, selected } };
};

export const clear = () => {
    return { type: PROGRAMS_CLEAR_ALL };
};

export const clearSelected = () => {
    return { type: PROGRAMS_CLEAR_SELECTED };
};

// istanbul ignore next // TODO: Merging early; will fast follow with this.
export const clearIncentives = () => {
    return { type: PROGRAMS_CLEAR_INCENTIVES };
};

export const getAllQuotes = (): any => (dispatch: any, getState: any) => {
    const state = getState();
    const currentOfferType = getCurrentOfferType(state);
    const currentSelectedProgram = getSelectedTerm(state, currentOfferType);
    const hostSelectors = { isManagerView: true } as any;

    const payload = paymentPayloadUtils.createPaymentPayload(state, hostSelectors, {
        type: REQUEST_PROGRAMS,
        meta: { excludeOverrides: true, sourceApplication: APPLICATION_NAME }
    });

    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, 'program-orchestrator');
    const endpoint = bffApiUrlResult.url;

    dispatch({
        [RSAA]: {
            endpoint,
            method: 'POST',
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json' },
            types: [
                REQUEST_PROGRAMS,
                { type: REQUEST_PROGRAMS_SUCCESS, meta: { currentOfferType, currentSelectedProgram } },
                REQUEST_PROGRAMS_FAILURE
            ]
        }
    });
};

export const applySelectedProgram =
    (program: ProgramQuote): any =>
    (dispatch: any, getState: any): any => {
        if (!program || !Object.keys(program).length) return;

        const state = getState();
        const offerType = getCurrentOfferType(state);
        const selectedTerm = getSelectedTerm(state, offerType);

        const term = program.term as number;
        const currMiles = getAnnualMiles(state);
        const currTermId = getTermId(offerType, term);
        const prevTermId = getTermIdForOfferType(state, offerType);
        const prevIncentives = getSelectedIncentivesByOfferType(state, offerType);
        const appliedIncentiveIds = getSelectedProgramIncentives(state);

        const currIncentives = appliedIncentiveIds.map((incentive: any) => ['conditional', offerType, incentive].join('_'));

        const isSameTerm = offerType == LEASE ? false : currTermId == prevTermId;
        const isSameIncentives = JSON.stringify(currIncentives) == JSON.stringify(prevIncentives);
        const isSameProgram = programPayloadUtil.isSelectedProgram(selectedTerm, program, offerType, currMiles);

        if (isSameTerm && isSameIncentives && isSameProgram) return;

        dispatch({
            type: 'APPLIED_PROGRAM',
            payload: { programData: program, incentives: currIncentives },
            meta: {
                currentOfferType: offerType,
                middleware: {
                    payment: true
                }
            }
        });

        dispatch(clearSelected());
    };

export const getIncentivesForSelectedProgramLender = (): any => (dispatch: any, getState: any) => {
    const state = getState();
    const currentOfferType = getCurrentOfferType(state);

    const payload = programSelectors.getIncentivePayload(state);
    const selectedProgram = getSelectedProgram(state);
    if (selectedProgram == null) return; // cannot continue; no selected program
    const term = selectedProgram.term; // NOTE: intentionally using program term (NOT offer term)

    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, '/incentives');
    const endpoint = bffApiUrlResult.url;

    dispatch({
        [RSAA]: {
            endpoint,
            method: 'POST',
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json' },
            types: [
                PROGRAMS_INCENTIVES_REQUEST,
                { type: PROGRAMS_INCENTIVES_REQUEST_SUCCESS, meta: { currentOfferType, term } },
                PROGRAMS_INCENTIVES_REQUEST_FAILURE
            ]
        }
    });
};
