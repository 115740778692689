/**
 * PURPOSE: Contains logic related to the vAuto.
 */

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { DashMiddleware, DashNext, DashStore } from '@makemydeal/dr-shared-store';
import { bridgeUserActionTypes } from '@makemydeal/dr-shared-store';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// consts/enums
import { VAUTO_APPRAISALS_SUCCESS } from '../actionTypes/vAutoActionTypes';

// actions
import { tradeActionCreators } from '../shopperActivities/trade';
import { getVAutoAppraisals } from '../actions/vAutoActionCreators';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    if (action.type === bridgeUserActionTypes.BRIDGE_USER_SUCCESS) {
        const platformUserId = action.payload.id;

        store.dispatch(getVAutoAppraisals(MANAGER_VIEW_APP_PREFIX, platformUserId));
    }

    if (action.type === VAUTO_APPRAISALS_SUCCESS) {
        const vAutoAppraisal = action.payload.data?.items;
        if (vAutoAppraisal?.length > 0) {
            const currentAppraisal = vAutoAppraisal[vAutoAppraisal.length - 1];
            const vAutoAcv = currentAppraisal?.value;
            if (vAutoAcv > 0) {
                store.dispatch(tradeActionCreators.acvChanged(vAutoAcv));
            }
        }
    }

    return next(action);
};
