// libraries
import { extractNumberFromString } from '@makemydeal/dr-common-utils';

export const extractNumberFromInputValue = (value?: string | number): number => {
    if (value === undefined) {
        return 0;
    }
    if (typeof value === 'number') {
        return value;
    }
    return extractNumberFromString(value);
};
