/**
 * Purpose: namespace constants defined in this file will be used to differentiate various categories of actions.  When a namespace
 *   is introduced it should include an explanation to differentiate that category.  We should try as much as possible to keep these
 *   as well-delineated categories that avoid overlap- if this requires us to change an existing namespace that's a valid option.
 */

/**
 * Internal actions that should not be used for anything other than their original purpose- see where these actions are used in
 * reducers and middleware before introducing new code that uses them.
 */
export const INTERNAL_NAMESPACE = '@@internal';
