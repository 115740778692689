import styled from 'styled-components';

export const AnchorFlexRowCentered = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
    color: ${({ theme }) => theme.tokens.BaseColorBlue700};
`;
