import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import { ICreditBureauActivity } from '../types/ICreditBureauActivity';
import { getAllRoutes } from '../utils/routes';
import CreditBureauActivity from './CreditBureauActivity';
import { getMaxAppContainerWidth } from '@makemydeal/dr-shared-ui-utils';

const Root = (props: ICreditBureauActivity) => {
    const { root } = getAllRoutes(props.baseRoute);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    return (
        <div
            style={{ maxWidth: getMaxAppContainerWidth(isABPencilEnabled), margin: '0 auto', padding: '0 16px' }}
            data-testid="activities-credit-bureau-container"
            className="activities-credit-bureau"
        >
            <Route path={root} component={CreditBureauActivity} />
        </div>
    );
};

Root.displayName = 'CreditBureauActivity';

export default Root;
