// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// selectors
import { hasLazyInitCompleted } from './bootstrapSelectors';

/**
 * This selector should only return true when all lazy API calls have been completed (if toggle enabled).
 * For example, the menu rated API calls update state that's used for offer save so we cannot enable the offer save button until
 * these calls have returned results.
 */
export const isLazyInitCompleteForSmartInit = (state: StateTree): boolean => {
    if (!featureToggleSelectors.isSmartInitOfferSaveForDashEnabled(state)) {
        return true;
    }
    return hasLazyInitCompleted(state);
};
