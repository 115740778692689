// Ext
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// redux related
import {
    deskingActionCreators,
    offerActionCreators,
    offerInfoSelectors,
    offerReduxActionCreators,
    offerReduxSelectors,
    vehicleSelectors
} from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// components
import { Modal } from '@interstate/components/Modal';
import { TextArea } from '@interstate/components/TextArea';
import { Button } from '@interstate/components/Button';
import { Alert } from '@interstate/components/Alert';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { Typography } from '@interstate/components/Typography';

// styles
import {
    TextAreaContainer,
    LoadingIndicatorContainer,
    AlertContainer,
    DefaultMessage,
    SavingMessage,
    SavedMessage,
    ShareToSalesViewFooter
} from './ShareToSalesViewDialog.style';

// constants
import { SHARE_TO_SALES_VIEW_HEADER } from '../constants';

export enum ShareToSalesViewDialogMode {
    SAVING = 'Saving',
    SAVED = 'Saved',
    DEFAULT = 'Default'
}

export type ShareToSalesViewDialogProps = {
    show: boolean;
    onHide(): void;
    onOfferSaveHide(): void;
};

export const ShareToSalesViewDialog = (props: ShareToSalesViewDialogProps) => {
    const { show, onHide, onOfferSaveHide } = props;

    const [mode, setMode] = useState(ShareToSalesViewDialogMode.DEFAULT);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');

    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const isCDL3InMVEnabled = useSelector(featureToggleSelectors.isCDL3EnabledInMV);

    const dispatch = useDispatch();

    // Reset mode to default when dialog is hidden
    React.useEffect(() => {
        if (show) {
            setMessage('');
            setMode(ShareToSalesViewDialogMode.DEFAULT);
            setError(false);
        }
    }, [show]);

    // Set mode when offer save status changes
    React.useEffect(() => {
        if (show) {
            if (offerIsBeingSaved === true) {
                return setMode(ShareToSalesViewDialogMode.SAVING);
            }

            if (offerIsBeingSaved === false && offerHasBeenSaved === true) {
                setMode(ShareToSalesViewDialogMode.SAVED);
                setError(false);
                setTimeout(onOfferSaveHide, 500);
                return;
            }

            if (offerIsBeingSaved === false && offerHasBeenSaved === false) {
                setMode(ShareToSalesViewDialogMode.DEFAULT);
                setError(true);
                return;
            }
        }
    }, [offerIsBeingSaved, offerHasBeenSaved]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleMessageChange = (event: any) => {
        setMessage(event.target.value);
    };

    const handleSendClick = () => {
        if (isCDL3InMVEnabled) {
            dispatch(deskingActionCreators.shareToSalesViewConfirmClicked());
        }

        dispatch(offerReduxActionCreators.updateMessageToSalesperson(message));
        dispatch(offerActionCreators.saveOffer());
    };

    const header = <h3 data-testid="share-to-sales-view-dialog-header">{SHARE_TO_SALES_VIEW_HEADER}</h3>;

    switch (mode) {
        case ShareToSalesViewDialogMode.SAVING:
            return (
                <Modal data-testid="share-to-sales-view-dialog-saving" show={show} header={header} onHide={onHide} size="large">
                    <SavingMessage>We're updating your changes to this offer.</SavingMessage>
                    <LoadingIndicatorContainer>
                        <LoadingIndicator size="large" data-testid="share-to-sales-view-loading-indicator" />
                    </LoadingIndicatorContainer>
                </Modal>
            );

        case ShareToSalesViewDialogMode.SAVED:
            return (
                <Modal
                    data-testid="share-to-sales-view-dialog-saved"
                    show={show}
                    header={header}
                    onHide={onHide}
                    footer={{ footerComponent: <ShareToSalesViewFooter /> }}
                    size="large"
                >
                    <SavedMessage>We have successfully shared your offer.</SavedMessage>
                </Modal>
            );

        default:
            return (
                <Modal
                    data-testid="share-to-sales-view-dialog-form"
                    show={show}
                    header={
                        <Typography variant="h3" color="sem.color.on-surface.primary">
                            {header}
                        </Typography>
                    }
                    footer={{
                        footerComponent: (
                            <ShareToSalesViewFooter>
                                <Button data-testid="share-to-sales-view-send-button" onClick={handleSendClick}>
                                    {error ? 'Retry' : 'Send'}
                                </Button>
                            </ShareToSalesViewFooter>
                        )
                    }}
                    onHide={onHide}
                    size="large"
                >
                    {error && (
                        <AlertContainer data-testid="share-to-sales-view-alert-container">
                            <Alert type="error">The changes you made to this offer failed to update. Please try again.</Alert>
                        </AlertContainer>
                    )}
                    <DefaultMessage>
                        When you send your updates, the salesperson will receive a notification in Sales View.
                    </DefaultMessage>
                    <TextAreaContainer>
                        <TextArea
                            label=""
                            data-testid="share-to-sales-view-message"
                            name="share-to-sales-view-message"
                            value={message}
                            placeholder="Include a message about this offer."
                            autoFocus={true}
                            displayLabel={false}
                            rows={4}
                            onChange={handleMessageChange}
                        />
                    </TextAreaContainer>
                </Modal>
            );
    }
};
