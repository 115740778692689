import React from 'react';
import { kebabCase } from '@makemydeal/dr-common-utils';

export const getTestId = ({ label, testId }: { label: string | React.ReactNode; testId?: string }) => {
    if (typeof label === 'string') {
        return kebabCase(label);
    }
    if (testId) {
        return kebabCase(testId);
    }
    return '';
};
