// libraries
import { BootstrapState, StateTree } from '@makemydeal/dr-dash-types';

const getBootstrap = (state: StateTree): Partial<BootstrapState> => {
    return state.bootstrap || ({} as Partial<BootstrapState>);
};

/**
 * This selector should only return true when all lazy API calls have been completed.  For example, the menu rated API calls update
 * state that's used for offer save so we cannot enable the offer save button until these calls have returned results.
 */
export const hasLazyInitCompleted = (state: StateTree): boolean => {
    const bootstrap = getBootstrap(state);
    return bootstrap.lazyInitCompleted || false;
};
