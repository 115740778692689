import { isEqual } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@interstate/components/Button';
import { Typography } from '@interstate/components/Typography';
import {
    ManualIncentiveEditEntry,
    offerActionCreators,
    offerReduxSelectors,
    offerSelectors,
    transformToEditIncentive
} from '@makemydeal/dr-dash-store';
import { manualIncentiveUtils } from '@makemydeal/dr-platform-shared';

import { ManualRebatesForm } from './ManualRebatesForm.interstate';
import {
    AddIncentiveContainer,
    EditableRowsPlusAddButtonContainer,
    FlexManualRebatesButtonContainer
} from './RebatesEdit.interstate.styles';
import { RebatesTotals } from './RebatesTotals.interstate';
import { RebatesEditProps } from './RebatesViewProps';
import { createNewManualIncentiveEntry, isIncentiveFormValid } from './utils';

const INCENTIVE_NOT_EDITED = -1;

export const RebatesEdit: FC<RebatesEditProps> = ({ setIncentivesMode }) => {
    const dispatch = useDispatch();

    const appliedIncentiveTotal = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);
    const incentives = useSelector(offerReduxSelectors.getAppliedIncentives, isEqual);
    const term = useSelector(offerSelectors.getSelectedTermMonths);

    const [incentivesToEdit, setIncentivesToEdit] = useState(transformToEditIncentive(term, incentives));
    const [haveIncentivesUpdated, setHaveIncentivesUpdated] = useState(false);
    const [isFormValid, setFormValid] = useState(false);
    const [hasMessageErrors, setHasMessageErrors] = useState(false);
    const [editedIncentiveIndex, setEditedIncentiveIndex] = useState(INCENTIVE_NOT_EDITED);

    // Current total for the edit screen (not including Dealer Cash incentives)
    const [currentEditedIncentivesTotal, setCurrentEditedIncentivesTotal] = useState(0);
    // Current (previously applied) total (not including Dealer Cash Incentives)
    const [currentAppliedIncentiveTotal, setCurrentAppliedIncentiveTotal] = useState(0);
    // Current dealer cash total
    const [dealerCashTotal, setDealerCashTotal] = useState(0);

    const getDealerCashIncentives = (incentivesToEdit: ManualIncentiveEditEntry[]) =>
        incentivesToEdit.filter(manualIncentiveUtils.isDealerCash as any);
    const getCurrentEditedIncentives = (incentivesToEdit: ManualIncentiveEditEntry[]) =>
        incentivesToEdit.filter(manualIncentiveUtils.isNotDealerCash as any);

    // Reset the error count to 0 on component load.
    useEffect(() => {
        const dealerCashIncentives = getDealerCashIncentives(incentivesToEdit);
        const currentEditedIncentives = getCurrentEditedIncentives(incentivesToEdit);

        setHasMessageErrors(false);
        setCurrentEditedIncentivesTotal(manualIncentiveUtils.getIncentiveSum(currentEditedIncentives as any));
        setDealerCashTotal(manualIncentiveUtils.getIncentiveSum(dealerCashIncentives as any));
        setCurrentAppliedIncentiveTotal(appliedIncentiveTotal);
    }, []);

    // Update the current total when incentives change
    useEffect(() => {
        if (!incentivesToEdit.length) {
            setIncentivesToEdit([createNewManualIncentiveEntry()]);
        }

        const dealerCashIncentives = getDealerCashIncentives(incentivesToEdit);
        const currentEditedIncentives = getCurrentEditedIncentives(incentivesToEdit);

        setCurrentEditedIncentivesTotal(manualIncentiveUtils.getIncentiveSum(currentEditedIncentives as any));
        setDealerCashTotal(manualIncentiveUtils.getIncentiveSum(dealerCashIncentives as any));
        setFormValid(isIncentiveFormValid(incentivesToEdit));
    }, [incentivesToEdit]);

    // When the Update Offer button is clicked, dispatch action to update offer and move to view mode, now showing the updates applied.
    useEffect(() => {
        if (haveIncentivesUpdated) {
            dispatch(
                offerActionCreators.updateManualIncentives({
                    manualIncentives: incentivesToEdit,
                    dealerCashTotal,
                    totalRebates: currentEditedIncentivesTotal
                })
            );
            setIncentivesMode('view');
        }
    }, [haveIncentivesUpdated]);

    const handleIncentivesUpdate = useCallback(() => {
        setHaveIncentivesUpdated(true);
        const newIncentives = incentivesToEdit.filter(({ deleted }) => !deleted);
        setIncentivesToEdit(newIncentives);
    }, [incentivesToEdit]);

    const handleAddIncentive = useCallback(() => {
        setHaveIncentivesUpdated(false);
        setIncentivesToEdit((incentivesToEdit) => [...incentivesToEdit, createNewManualIncentiveEntry()]);
    }, [setHaveIncentivesUpdated, setIncentivesToEdit]);

    return (
        <>
            <EditableRowsPlusAddButtonContainer>
                {/* TODO: add US1265956 typography once figma dev mode access is back */}
                <Typography variant="label-md" sx={{ marginBottom: '16px' }} color="base.color.gray.600">
                    Program number and code fields are optional.
                </Typography>
                <ManualRebatesForm
                    term={term}
                    incentivesToEdit={incentivesToEdit}
                    haveIncentivesUpdated={haveIncentivesUpdated}
                    editedIncentiveIndex={editedIncentiveIndex}
                    setEditedIncentiveIndex={setEditedIncentiveIndex}
                    setIncentivesToEdit={setIncentivesToEdit}
                    setHasMessageErrors={setHasMessageErrors}
                    setHaveIncentivesUpdated={setHaveIncentivesUpdated}
                />
                <AddIncentiveContainer>
                    <Button buttonStyle="tertiary" data-testid="add-incentives-button" onClick={handleAddIncentive} size="small">
                        + Add Incentive
                    </Button>
                </AddIncentiveContainer>
            </EditableRowsPlusAddButtonContainer>

            <RebatesTotals
                dealerCashTotal={dealerCashTotal}
                currentAppliedIncentiveTotal={currentAppliedIncentiveTotal}
                currentEditedIncentivesTotal={currentEditedIncentivesTotal}
                showEditTotals
                showRebatesTotal
            />

            <FlexManualRebatesButtonContainer id="manual-rebates-button-container">
                <Button
                    data-testid="btn-manual-rebates-cancel-edit"
                    buttonStyle="secondary"
                    onClick={() => {
                        setIncentivesMode('view');
                    }}
                    size="medium"
                >
                    Cancel
                </Button>
                <Button
                    data-testid="btn-manual-rebates-edit-update-offer"
                    buttonStyle="primary"
                    onClick={handleIncentivesUpdate}
                    disabled={editedIncentiveIndex === INCENTIVE_NOT_EDITED || hasMessageErrors || !isFormValid}
                    size="medium"
                >
                    Update Offer
                </Button>
            </FlexManualRebatesButtonContainer>
        </>
    );
};
