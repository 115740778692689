// libraries
import { getAllRoutes } from '@makemydeal/dr-activities-deal-history';
import { NavigationItemStatus } from '@makemydeal/dr-activities-shared';

// interfaces/types
import type { IFlowConfig } from '../../flowMasterInterfaces';

// components
import CreditBureauContainer from './components/CreditBureauContainer';

// consts/enums
import { APP_NAME, CREDIT_BUREAU_ROUTE_ROOT } from './constants';

export const allRoutes = getAllRoutes(CREDIT_BUREAU_ROUTE_ROOT);

// istanbul ignore next
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noopReducer = () => {};

/* this activity won't appear on the dr-activities-dashboard card list,
 * so including the bare minimum inputs to satisfy the interface
 */
export const creditBureauConfiguration: IFlowConfig<any> = {
    name: APP_NAME,
    navCard: {
        label: 'Credit Bureau',
        icon: 'vehicle',
        cardId: 'dr-sp-credit-bureau'
    },
    showCardSelector: () => false,
    isCompleteSelector: () => false,
    routingComponent: CreditBureauContainer,
    defaultRoute: allRoutes.root,
    routes: [...Object.keys(allRoutes).map((routeName) => allRoutes[routeName])],
    middleware: null,
    reducer: noopReducer, // credit bureau doesn't have any of its own state
    isLocked: (_state: unknown) => false,
    dependsOnLeadFormCompletion: () => false,
    status: () => NavigationItemStatus.DEFAULT,
    isDisabled: () => false
};
