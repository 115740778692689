import { IPencilOfferInputsDealerFee, ItemizedGovernmentFee } from '@makemydeal/dr-platform-types';
import { FeesCategoriesType } from '@makemydeal/dr-shared-types';

// Helper function to map dealer fees
export const mapDealerFees = (fee: IPencilOfferInputsDealerFee) => ({
    feeTypeId: fee.dealerFeeTypeCode,
    feeAmount: `${fee.dealerFeeAmount}`,
    reqCapIndicator: fee.dealerFeeCapped,
    feeDescription: fee.dealerFeeDesc,
    feeName: fee.dealerFeeDesc,
    category: FeesCategoriesType.DEALER,
    includeInCalcIndicator: true
});

// Helper function to map government fees
export const mapGovernmentFees = (fee: ItemizedGovernmentFee) => ({
    feeTypeId: fee.code,
    feeAmount: `${fee.amount}`,
    reqCapIndicator: fee.capped,
    feeDescription: fee.description,
    feeName: fee.description,
    category: FeesCategoriesType.GOVERNMENT,
    includeInCalcIndicator: true
});
