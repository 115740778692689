// externals
import React, { createContext, PropsWithChildren, useState } from 'react';

type DrawerContext = {
    drawerOpen: boolean;
    setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    toggleDrawer(): void;
};

// using `never` here because the default value will never be needed or used, this just satisfies the compiler
// and is a bit cleaner than creating a noop function that needs to be exempted from coverage or tested
export const NavDrawerContext = createContext<DrawerContext>(undefined as never);

export const NavDrawerProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerOpen((lastValue) => !lastValue);
    };

    return <NavDrawerContext.Provider value={{ drawerOpen, toggleDrawer, setDrawerOpen }}>{children}</NavDrawerContext.Provider>;
};
