// externals
import { Reducer } from 'redux';

// libraries
import type { Lender, LenderListState } from '@makemydeal/dr-dash-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';

// utils
import { typeGuards } from '../utils';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export type LenderListReducer = Reducer<LenderListState>;

const defaultState: LenderListState = [];

export const reducer: LenderListReducer = (state: LenderListState = defaultState, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            let newState = { ...state };
            newState = action.payload.lenderList?.lenderList || [];

            if (action.payload.offer) {
                // Some external DXG deals send selected lender information that does not exist within the lenderList response
                // In these cases we need to append them to the lenderList so the Lender drop down behaves correctly
                const dealType = action.payload.offer.type;
                const creditDecisionEnabled = action.payload.offer[dealType].creditDecisionEnabled;
                const creditDecisionLender = action.payload.offer[dealType].creditDecisionLender;
                const isValidLender = typeGuards.isCreditDecisionLender(creditDecisionLender);
                if (!isValidLender) {
                    return newState;
                }
                const isLenderFromOfferInLenderList = newState.some((lender) => lender.lenderId === creditDecisionLender.id);
                if (creditDecisionEnabled && !isLenderFromOfferInLenderList) {
                    newState.push({ lenderId: creditDecisionLender.id, lenderName: creditDecisionLender.name } as Lender);
                }
            }

            return newState;
        }
        default: {
            return state;
        }
    }
};
