import { useSelector } from 'react-redux';

import { offerActionTypes, offerReduxActionCreators, offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import {
    BASE_RESIDUAL_DOLLAR_TESTID,
    BASE_RESIDUAL_GROUP_TESTID,
    BASE_RESIDUAL_GROUPID,
    BASE_RESIDUAL_ID,
    BASE_RESIDUAL_LABEL,
    BASE_RESIDUAL_PERCENT_TESTID,
    BASE_RESIDUAL_VALUE_TESTID
} from '../../constants';
import { useFeatureToggle } from '../../utils/useFeatureToggle';
import ResidualField from './ResidualField.interstate';

export const BaseResidualField = () => {
    const enabled = useFeatureToggle('enableAppliedLenderInfo');

    const isUserProgramQuotes = useSelector(offerReduxSelectors.getIsUserProgramQuotes);

    const showDisabled = (enabled && !isUserProgramQuotes) || !enabled;

    const { baseResidualType, baseResidualAmount, baseResidualPercent } = useSelector(
        offerSelectors.getResidualInfoOverrideWithFallback
    );

    const residualDollar = useSelector(offerReduxSelectors.getBaseResidualDollar);
    const residualPercent = useSelector(offerReduxSelectors.getBaseResidualPercent);

    const dollar = residualDollar || baseResidualAmount;
    const percent = residualPercent || baseResidualPercent;

    const props = {
        showDisabled,
        residualType: baseResidualType,
        residualAmount: dollar,
        residualPercent: percent,
        updateResidualOverride: offerReduxActionCreators.updateBaseResidualOverride,
        id: BASE_RESIDUAL_ID,
        label: BASE_RESIDUAL_LABEL,
        groupId: BASE_RESIDUAL_GROUPID,
        valueTestId: BASE_RESIDUAL_VALUE_TESTID,
        groupTestId: BASE_RESIDUAL_GROUP_TESTID,
        dollarTestId: BASE_RESIDUAL_DOLLAR_TESTID,
        percentTestId: BASE_RESIDUAL_PERCENT_TESTID,
        dollarOverrideAction: offerActionTypes.UPDATED_BASE_RESIDUAL_DOLLAR_OVERRIDE,
        percentOverrideAction: offerActionTypes.UPDATED_BASE_RESIDUAL_PERCENT_OVERRIDE
    };

    return <ResidualField inputProps={props}></ResidualField>;
};

export default BaseResidualField;
