import {
    CLICKED_BEGIN_CHECKOUT,
    CLICKED_CONTINUE_IN_DESKING,
    CLICKED_CONTINUE_IN_DESKING_CONFIRM,
    CLICKED_CONTINUE_IN_UNIFI,
    CLICKED_COPY_DEAL_URL,
    CLICKED_COPY_DEAL_URL_CLICKED,
    CLICKED_PUSH_TO_DMS_CONFIRM,
    CLICKED_SEND_TO_CUSTOMER,
    CLICKED_SEND_TO_ECOMMERCE,
    SEND_TO_SALES_VIEW_CONFIRM,
    SHOW_TOTAL_TAX_RATE_SUPERSEDE_ALERT,
    HIDE_TOTAL_TAX_RATE_SUPERSEDE_ALERT
} from '../actionTypes/deskingActionTypes';

export const continueInDesking = () => ({
    type: CLICKED_CONTINUE_IN_DESKING
});

export const sendToEcommerce = () => ({
    type: CLICKED_SEND_TO_ECOMMERCE
});

export const copyDealUrl = () => ({
    type: CLICKED_COPY_DEAL_URL
});

export const copyDealUrlClicked = () => ({
    type: CLICKED_COPY_DEAL_URL_CLICKED
});

export const continueInUnifi = () => ({
    type: CLICKED_CONTINUE_IN_UNIFI
});

export const pushToDmsConfirmClicked = () => ({
    type: CLICKED_PUSH_TO_DMS_CONFIRM
});

export const sendToCustomerClicked = () => ({
    type: CLICKED_SEND_TO_CUSTOMER
});

export const beginCheckoutClicked = () => ({
    type: CLICKED_BEGIN_CHECKOUT
});

export const continueInDeskingConfirmClicked = () => ({
    type: CLICKED_CONTINUE_IN_DESKING_CONFIRM
});

export const shareToSalesViewConfirmClicked = () => ({
    type: SEND_TO_SALES_VIEW_CONFIRM
});

export const showTotalTaxRateSupersedeAlert = () => ({
    type: SHOW_TOTAL_TAX_RATE_SUPERSEDE_ALERT
});

export const hideTotalTaxRateSupersedeAlert = () => ({
    type: HIDE_TOTAL_TAX_RATE_SUPERSEDE_ALERT
});
