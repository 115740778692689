import styled from 'styled-components';

import { TextInput } from '@interstate/components/TextInput';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { FlexCol } from '../../common/Layout.style';

export const StyledTextWithToggleGroupContainer = styled.div`
    display: flex;
    align-items: end;
    gap: 5px;
    @media (max-width: ${BreakPoint.SMALL}) {
        gap: 1px;
    }
`;

export const StyledTextWithToggleGroupText = styled(FlexCol)`
    input,
    fieldset,
    div {
        width: auto;
        min-width: auto !important; // override min-width of TextInput
    }

    @media (max-width: ${BreakPoint.SMALL}) {
        div {
            width: auto;
            min-width: auto !important; // override min-width of TextInput
        }
    }
`;

export const StyledTextWithToggleGroupToggle = styled(FlexCol)`
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    button.MuiToggleButtonGroup-grouped:not(:first-of-type) {
        border: 1px solid #b2b2b2;
    }

    @media (max-width: ${BreakPoint.SMALL}) {
        #dollarToggle,
        #percentToggle {
            min-width: 10px;
        }
    }
`;

export const StyledReadonlyTextInput = styled(TextInput)`
    // input value container
    #ids-input-layout-grid-container > div:nth-child(2) {
        height: 48px;
    }
`;
