import styled from 'styled-components';
import { toRem } from '../utils/pixelUtils';

export const StyledOfferCardContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    .offer-card__details {
        flex-grow: 1;
    }

    .offer-card__message {
        // this hardocded height is to make sure message areas are aligned horizontally. Even they are empty.
        // And also give them a breathing room.
        margin-top: ${toRem(16)};
        height: ${toRem(26)};
        display: flex;
        align-items: center;
    }
`;

export const StyledOfferCardBadgesContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${toRem(8)};

    .badge-scenario-count {
        color: ${({ theme }) => theme.tokens.BaseColorBlue700};
        border-color: currentColor !important;
    }
`;
