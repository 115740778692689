// libraries
import { selectors as offerReduxSelectors } from '@makemydeal/dr-offer-redux';

// state
import type { StateTree } from '@makemydeal/dr-dash-types';

// interfaces/types
import type { TermData } from '@makemydeal/dr-dash-bff-types';

export const getCurrentPaymentTermData = (state: StateTree): TermData | null => {
    const offerType = offerReduxSelectors.getCurrentOfferType(state);
    if (!offerType) {
        return null;
    }
    const selectedTermId = offerReduxSelectors.getTermIdByOfferType(state, offerType);
    if (!selectedTermId) {
        return null;
    }
    const termsById = offerReduxSelectors.getTermsByIdForOffer(state.offer);
    if (!termsById) {
        return null;
    }
    return termsById[selectedTermId];
};

export const getCurrentPaymentTerm = (state: StateTree): number | null => {
    const paymentTermData = getCurrentPaymentTermData(state);
    const term = paymentTermData && paymentTermData.months;
    return term;
};

export const getLenderLegalName = (state: StateTree): string | null => {
    const paymentTermData = getCurrentPaymentTermData(state);
    const lenderLegalName = paymentTermData && paymentTermData.lenderLegalName;
    return lenderLegalName;
};
