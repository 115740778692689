// externals
import { Tooltip } from '@interstate/components/Tooltip';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { ExclamationTriangleIcon } from '@interstate/components/Icons';

// types
import { TableAlertInputProps } from '../../types';

const TableAlertWarning = ({ tableAlertInput }: TableAlertInputProps) => {
    const theme = useInterstateTheme();

    return (
        // The tooltip shifts to a wrong place on wide resolutions when position is one of "<position>-end" or "<position>-start"
        <Tooltip data-testid="table-alert-warning-tooltip" size="large" toolTipContent={tableAlertInput.message} position="top">
            <ExclamationTriangleIcon data-testid="table-alert-warning-icon" color={theme.tokens.BaseColorGold500} />
        </Tooltip>
    );
};

export default TableAlertWarning;
