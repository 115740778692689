// libraries
import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';
import { LEASE, paymentServicesTypes } from '@makemydeal/dr-platform-types';

// components
import { FeeItems } from './FeeItems';
import { CustomFeeItem } from './types';

export type FeeCategoryItemsProps = {
    categoryTitle: string;
    categoryType: paymentServicesTypes.FeeCategory;
    feeItems: CustomFeeItem[];
    dataTestId: string;
    offerType: string;
};

export const FeeCategoryItems: React.FC<FeeCategoryItemsProps> = ({ categoryTitle, feeItems, dataTestId, offerType }) => {
    const isLease = offerType === LEASE;
    return (
        <Box data-testid={dataTestId} marginTop={3}>
            <Typography variant="h5" sx={{ marginBottom: 1, fontSize: '1rem' }}>
                {categoryTitle}
            </Typography>
            <FeeItems feeItems={feeItems} isLease={isLease} />
        </Box>
    );
};

export default FeeCategoryItems;
