import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const CardElement = styled.div`
    width: 100%;
    margin-top: 1rem;

    .cardContainer {
        border: 2px solid rgb(221, 221, 221);
    }

    .selectedCardContainer {
        border: 2px solid #005ba8;
    }

    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        max-width: 18.75rem;
        min-width: 18.75rem;
        margin-bottom: 1rem;
    }
`;
export const LenderCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
`;
export const LogoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .statusContainer {
        display: flex;
        align-items: center;
    }

    .statusIconApproved {
        stroke: black;
        stroke-width: 2;
        transform: scale(0.6);
    }

    .statusIconConditionallyApproved {
        transform: scale(0.7);
    }

    .decisionCardLenderLogo {
        max-height: 3rem;
        max-width: 6rem;
    }

    .statusIconDeclined {
        fill: #ffcccc;
        stroke: black;
        stroke-width: 1.3;
        transform: scale(0.7);
    }
`;
export const InfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 0;

    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        flex-direction: column;
        gap: 0.2rem;
    }
`;
export const InfoDetail = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        flex-direction: row;
        justify-content: space-between;
    }
`;
export const OfferDetailsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    border-top: 2px solid rgb(221, 221, 221);

    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        justify-content: center;
    }
`;

export const DeepLinkOfferDetails = styled.div`
    margin-top: 0.7rem;
`;
