import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';
import { Alert } from '@interstate/components/Alert';
import { Button } from '@interstate/components/Button';

export const CheckoutErrorCard = ({ handleClick }: { handleClick: () => void }) => {
    return (
        <Box marginTop="0.5rem" sx={{ maxWidth: '100%' }} data-testid="checkout-error-card">
            <Typography sx={{ marginBottom: '1.5rem' }} variant="strong-lg" tag="div">
                Checkout
            </Typography>
            <Typography variant="strong-md" tag="span">
                System Error
            </Typography>
            <Alert type="error" role="alert" sx={{ maxWidth: '100%', margin: '10px 0' }}>
                <Typography variant="body-sm" tag="span">
                    We are unable to provide a checkout link due to a system error.
                </Typography>
            </Alert>
            <Typography variant="body-sm">Click "Close" and continue to relevant system to enter missing data.</Typography>
            <Box marginTop="1rem" sx={{ maxWidth: '100%', textAlign: 'end' }}>
                <Button data-testid="ca-close-button" onClick={handleClick}>
                    Close
                </Button>
            </Box>
        </Box>
    );
};
