export enum BuildPayloadPurpose {
    AppInit = 'APP_INIT',
    MenuInit = 'MENU_INIT',
    OfferSave = 'OFFER_SAVE',
    PushToDms = 'PUSH_TO_DMS',
    PushToDarwin = 'PUSH_TO_DARWIN',
    TradeIn = 'TRADE_IN',
    Unknown = 'UNKNOWN',
    ContinueInDesking = 'CONTINUE_IN_DESKING',
    DeveloperDebugging = 'DEVELOPER_DEBUGGING'
}
