// external
import styled from 'styled-components';
import { rem } from 'polished';

// libraries
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { Action } from '@interstate/components/Action';
import { CardContent } from '@interstate/components/Card';

export const VppPreviewSummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 16px;
    @media (min-width: ${BreakPoint.SMALL}) {
        margin-right: 16px;
    }
`;

export const VppPreviewSummaryRow = styled.div`
    display: flex;
    justify-content: space-between;
    width: 200px;
`;

export const ActionsContainer = styled.div`
    button:not(:last-child) {
        margin-right: ${rem(12)};
    }
`;

export const StyledAction = styled(Action)`
    margin-right: ${rem(12)};
`;

export const StyledVppCardContent = styled(CardContent)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
