// externals
import type { Reducer } from 'redux';

// libraries
import {
    reducer as tradeInReducer,
    getAllRoutes,
    TRADE_IN,
    TradeInCardDetails,
    TradeInSummary,
    ITradeInComponentState
} from '@makemydeal/dr-activities-tradein';
import { offerSelectors, tradeShopperActivity } from '@makemydeal/dr-dash-store';

// interfaces/types
import type { IFlowConfig } from '../../flowMasterInterfaces';

// utils
import { tradeInMiddleware } from './middleware';

// components
import { TradeInContainer } from './components/TradeInContainer';

export const tradeInRoutes = getAllRoutes();

export type TradeReducer = Reducer<ITradeInComponentState>;

const { getTradeInSelectors } = tradeShopperActivity;

export const tradeConfiguration: IFlowConfig<ITradeInComponentState> = {
    name: 'tradeIn',
    navCard: {
        label: 'Value My Trade-In',
        icon: 'tradeIn2',
        iconClass: 'trade-in',
        cardId: 'dr-sp-trade-in-card',
        title: 'Value Your Vehicle',
        nextStepsDescription: 'Use Kelley Blue Book to see what your current car is worth towards your new vehicle.',
        nextStepsTitle: `Don't have a vehicle to trade-in?`,
        mobileDescription: 'Get a trusted Kelley Blue Book® estimate.',
        dashboardIcon: 'carDollarSign',
        timeSaved: 26,
        detailComponent: TradeInCardDetails,
        summaryComponent: TradeInSummary
    },
    showCardSelector: (state) => getTradeInSelectors().showTradeCard(state),
    isCompleteSelector: (state) => getTradeInSelectors().isTradeInAppCardCompleted(state),
    isUnavailableSelector: (state) => getTradeInSelectors().isTradeInUnavailable(state),
    routingComponent: TradeInContainer,
    defaultRoute: TRADE_IN,

    routes: [...Object.keys(tradeInRoutes).map((routeName) => (tradeInRoutes as any)[routeName])],
    middleware: tradeInMiddleware,
    reducer: tradeInReducer,
    isLocked: () => false,
    dependsOnLeadFormCompletion: () => false,
    status: (state) => getTradeInSelectors().tradeInStatus(state),
    isDisabled: (state) => getTradeInSelectors().isTradeInDisabled(state),
    showInNextSteps: (state) => !offerSelectors.isOfferFromExternalSource(state),
    showOnDealerOffer: () => true
};
