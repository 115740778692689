import {
    MANUAL_INCENTIVES_EDIT_ADD,
    MANUAL_INCENTIVES_EDIT_INIT,
    MANUAL_INCENTIVES_EDIT_REMOVE,
    MANUAL_INCENTIVES_EDIT_SHOW_VALIDATION,
    MANUAL_INCENTIVES_EDIT_UPDATE_FIELD,
    OPEN_MANUAL_INCENTIVE_FORM
} from '../actionTypes/manualIncentivesActionTypes';
import { ManualIncentiveEditEntry } from '../types/manualIncentiveTypes';

import { UniqueScrollRequestId } from '@makemydeal/dr-dash-ui-utils';
import { FSA } from '@makemydeal/dr-platform-shared';

export const updateShowValidation = (haveManualIncentivesUpdated: boolean) => {
    return {
        type: MANUAL_INCENTIVES_EDIT_SHOW_VALIDATION,
        payload: haveManualIncentivesUpdated
    };
};

export const initManualIncentivesEdit = (initData: ManualIncentiveEditEntry[]) => {
    return { type: MANUAL_INCENTIVES_EDIT_INIT, payload: initData };
};

export const createManualIncentive = (data: ManualIncentiveEditEntry) => {
    return { type: MANUAL_INCENTIVES_EDIT_ADD, payload: data };
};

export const updateManualIncentiveField = (index: number, field: string, value: string | number | boolean) => {
    return { type: MANUAL_INCENTIVES_EDIT_UPDATE_FIELD, payload: { index, field, value } };
};

export const removeOneManualIncentive = (index: number) => {
    return { type: MANUAL_INCENTIVES_EDIT_REMOVE, payload: index };
};

export const resetManualIncentivesEdit = () => {
    return { type: MANUAL_INCENTIVES_EDIT_INIT, payload: undefined };
};

export type ToggleManualTradeFormAction = FSA<typeof OPEN_MANUAL_INCENTIVE_FORM, UniqueScrollRequestId>;

export const OpenManualIncentiveForm = (toggleStateOrUniqueRequestId?: UniqueScrollRequestId): ToggleManualTradeFormAction => {
    return {
        type: OPEN_MANUAL_INCENTIVE_FORM,
        payload: toggleStateOrUniqueRequestId
    };
};
