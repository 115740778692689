type ConditionEnum = 'new' | 'used' | 'demo' | 'certified' | 'rental' | 'consignment' | 'other';
type conditionMapperType = {
    condition: {
        map: { [keys: string]: ConditionEnum };
        default: ConditionEnum;
    };
};

const AcceptedEnums: conditionMapperType = {
    condition: {
        map: {
            new: 'new',
            NEW: 'new',
            used: 'used',
            demo: 'demo',
            certified: 'certified',
            certifiedpreowned: 'certified',
            rental: 'rental',
            consignment: 'consignment',
            other: 'other'
        },
        default: 'used'
    }
};

export const conditionMapper = (value?: string): ConditionEnum => {
    if (!value) return AcceptedEnums.condition.default;

    return AcceptedEnums.condition.map[value.toLowerCase()] || AcceptedEnums.condition.default;
};
