import styled from 'styled-components';

export const LoadingContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    left: 0;
    right: 0;
    padding: 40.5px 32px 0px 32px;

    & #ids-spinner-root {
        color: #2372b8;
    }
`;
