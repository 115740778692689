import { useContext } from 'react';

import { SelectInput } from '@interstate/components/SelectInput';
import { FormSelectInputProps } from '../types';
import { FormContext } from './formContext';

export const FormSelectInput = ({ name, label, options, errorMessage, maxHeight, placeholder }: FormSelectInputProps) => {
    const { form, changeHandler } = useContext(FormContext);

    return (
        <SelectInput
            data-testid={name}
            name={name}
            label={label}
            options={options}
            onChange={(ev) => changeHandler(name, ev.target.value as string)}
            maxHeight={maxHeight}
            placeholder={placeholder}
            value={form.values[name] as string}
            hasError={!!form.error[name]}
            errorMessage={form.error[name] ? errorMessage : undefined}
        />
    );
};
