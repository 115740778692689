// libraries
import type { SelectedOffer } from '@makemydeal/dr-shared-types';
import type { StateTree } from '@makemydeal/dr-dash-types';

export const getSelectedOffer = (state: StateTree): SelectedOffer => {
    return state.selectedOffer;
};

export const getSelectedOfferConnectionId = (state: StateTree): string | undefined => {
    const selectedOffer = getSelectedOffer(state);
    return selectedOffer?.connectionId;
};

export const getSelectedOfferVin = (state: StateTree): string | undefined => {
    const selectedOffer = getSelectedOffer(state);
    return selectedOffer?.vin;
};

export const getSelectedOfferDealXgId = (state: StateTree): string | undefined => {
    const selectedOffer = getSelectedOffer(state);
    return selectedOffer?.dealXgId;
};

export const getSelectedOfferDealXgVersion = (state: StateTree): string | undefined => {
    const selectedOffer = getSelectedOffer(state);
    return selectedOffer?.dealXgVersion;
};
