import styled from 'styled-components';

import { Card } from '@interstate/components/Card';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const DeskingCardsContainer = styled.div<{ cardsAmount: number }>`
    display: grid;
    overflow: auto;
    grid-auto-flow: column;
    grid-gap: ${({ theme }) => theme.tokens.ComponentCardPadding};
    grid-template-columns: ${({ cardsAmount }) => `repeat(${cardsAmount}, minmax(30rem, 1fr));`};
    grid-template-rows:
        [header] auto
        [payment-details] auto
        [terms] auto
        [paymentGrid] auto
        [creditBureau] auto
        [trade] auto
        [tax] auto
        [fees] auto
        [rebates] auto
        [vpp] auto
        [accessories] auto
        [comments] auto
        [actions] auto;

    padding: ${({ theme }) => `0.2rem ${theme.tokens.BaseSpace20}`};
    margin: ${({ theme }) => `0 -${theme.tokens.BaseSpace20}`};
    margin-top: ${({ theme }) => theme.tokens.ComponentCardPadding};

    @media (max-width: ${BreakPoint.PHONE}) {
        grid-template-columns: ${({ cardsAmount }) => `repeat(${cardsAmount}, minmax(26.7rem, 1fr));`};
    }
`;

export const DeskingCardHeaderWrapper = styled.div`
    grid-row-start: header;
    grid-column: 1 / -1;

    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const DeskingCardHeaderActions = styled.div`
    display: grid;

    grid-template-columns: 0.5fr 8fr;
    grid-template-rows: minmax(40px, 1fr);
    align-items: center;

    border-bottom: 1px solid ${({ theme }) => theme.tokens.SemColorBorderDefault};
    padding-bottom: 0.31rem;
`;

export const DeskingCardSelectDraftDeal = styled.div`
    margin-bottom: -0.6rem;
`;

export const DeskingCardHeaderActionsMenu = styled.div`
    justify-self: end;
    grid-column: 2 / -1;
    margin-bottom: -0.5rem;

    & * {
        margin-left: 8px;
    }
`;

export const DeskingCardLeftPanel = styled.div`
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: 2 / -1;
    grid-column-start: 1;
`;

export const DeskingCardLeftPanelContent = styled.div`
    grid-row: 2 / -1;
`;

export const DeskingCardBlocksContainer = styled.div`
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: subgrid;
    grid-row: 2 / -1;
    grid-column-start: 2;
`;

export const StyledDeskingCard = styled.div<{ isSingleCardView: boolean; isPrimaryScope: boolean; isLargeScreen: boolean }>`
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: ${({ isSingleCardView, isLargeScreen }) =>
        isSingleCardView && isLargeScreen ? '[left-side] 26rem [right-side] minmax(0, 1fr)' : 'subgrid'};
    grid-row: -1 / 1;

    grid-auto-rows: max-content;

    grid-gap: ${({ theme }) => theme.tokens.ComponentCardPadding};
    padding: ${({ theme }) => theme.tokens.ComponentCardPadding};

    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme, isSingleCardView, isPrimaryScope }) =>
        !isSingleCardView && isPrimaryScope ? 'transparent' : theme.tokens.BaseColorGray300};
    outline: ${({ theme, isSingleCardView, isPrimaryScope }) =>
        !isSingleCardView && isPrimaryScope ? `2px solid ${theme.tokens.BaseColorBlue900}` : 'unset'};
    border-radius: ${({ theme }) => theme.tokens.BaseSizeBorderRadiusSm};
    background-color: ${({ isSingleCardView, isPrimaryScope }) => (!isSingleCardView && isPrimaryScope ? '#F5FCFF' : 'inherit')};
`;

export const DeskingCardBlock = styled.div<{ rowName: string; rowNameEnd?: string }>`
    grid-row-start: ${({ rowName }) => rowName};
    grid-row-end: ${({ rowNameEnd }) => rowNameEnd};

    & > * {
        height: 100%;
    }
`;

export const StretchHeightCard = styled(Card)`
    .MuiCardHeader-action {
        margin-right: 0;
    }

    & > * {
        height: 100%;

        & > * {
            height: 100%;
        }
    }
`;

export const LoadingSection = styled.div`
    position: relative;
    height: 100%;
`;
