// components
import TableAlertWarning from './TableAlertWarning';
import TableAlertError from './TableAlertError';
import TableAlertInfo from './TableAlertInfo';

// types
import { VppProductAttributes } from '@makemydeal/dr-dash-types';
import { TableAlertInput, TableAlertInputProps, TableAlertInputType } from '../../types';

// constants
import { tableAlertMessages } from './constants/tableAlertMessages';

const inputTypeComponentMap: Record<TableAlertInputType, (props: TableAlertInputProps) => JSX.Element> = {
    [TableAlertInputType.INFO]: TableAlertInfo,
    [TableAlertInputType.WARNING]: TableAlertWarning,
    [TableAlertInputType.ERROR]: TableAlertError
};

const TableRowAlerts = ({ productAttributes = {} }: { productAttributes: VppProductAttributes }) => {
    const tableAlertInput = {} as TableAlertInput;

    if (productAttributes.newProductFromFie) {
        tableAlertInput.type = TableAlertInputType.INFO;
        tableAlertInput.message = tableAlertMessages.newProductFromFie;
    }
    if (productAttributes.selectedProductCostChanged) {
        tableAlertInput.type = TableAlertInputType.WARNING;
        tableAlertInput.message = tableAlertMessages.selectedProductCostChanged;
    }
    if (productAttributes.productDisappeared) {
        tableAlertInput.type = TableAlertInputType.WARNING;
        tableAlertInput.message = tableAlertMessages.productDisappeared;
    }
    if (productAttributes.productWithoutRates) {
        tableAlertInput.type = TableAlertInputType.ERROR;
        tableAlertInput.message = tableAlertMessages.productWithoutRates;
    }
    if (productAttributes.missingRequiredAttributes) {
        tableAlertInput.type = TableAlertInputType.WARNING;
        tableAlertInput.message = tableAlertMessages.missingRequiredAttributes;
    }
    if (productAttributes.paymentFailed) {
        tableAlertInput.type = TableAlertInputType.ERROR;
        tableAlertInput.message = tableAlertMessages.paymentFailed;
    }
    if (productAttributes.hasInapplicableRateAttribute) {
        tableAlertInput.type = TableAlertInputType.ERROR;
        tableAlertInput.message = tableAlertMessages.hasInapplicableRateAttribute;
    }
    if (productAttributes.hasInapplicablePlan) {
        tableAlertInput.type = TableAlertInputType.ERROR;
        tableAlertInput.message = tableAlertMessages.hasInapplicableRatePlan;
    }

    const AlertComponent = inputTypeComponentMap[tableAlertInput.type];

    if (!AlertComponent) {
        return null;
    }

    return <AlertComponent tableAlertInput={tableAlertInput} />;
};

export default TableRowAlerts;
