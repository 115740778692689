import styled from 'styled-components';

export const StyledProgramsIncentivesTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledGuideLineAlertsLinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    > a {
        white-space: nowrap;
    }

    & #guideline-alerts-link {
        text-decoration: underline;
    }
`;
