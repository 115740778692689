import { paymentServicesTypes, ManualIncentiveEntry } from '@makemydeal/dr-platform-types';
import { isOriginalIncentive } from '@makemydeal/dr-dash-store';
import { INCENTIVE_TYPE_VALUES } from '@makemydeal/dr-dash-types';
import { formatAsMDYYYY } from '@makemydeal/dr-common-utils';

export type IIncentiveTypeMappingInputs = {
    code?: number;
    isStandardIncentive?: boolean;
    type?: paymentServicesTypes.IncentiveType | '';
    description?: string;
    name: string;
};

export const getIncentiveDisplayType = (data: ManualIncentiveEntry): string => {
    if (data.isStandardIncentive) {
        return 'Manufacturer';
    } else if (data.type === INCENTIVE_TYPE_VALUES.dealerCash.value) {
        return INCENTIVE_TYPE_VALUES.dealerCash.label;
    } else if (isOriginalIncentive(data)) {
        return 'Included';
    } else if (data.type === INCENTIVE_TYPE_VALUES.customerCash.value) {
        return INCENTIVE_TYPE_VALUES.customerCash.label;
    }

    // Returns in case when the rebate is not known for MV but came from other system
    return 'Included';
};

export const createIncentiveSubHeaderText = (termMonths: number, apr: number, programEndDate: string) => {
    let subHeaderText = `${termMonths} mos at ${apr.toFixed(2)}% APR`;
    if (programEndDate) {
        subHeaderText += ` (expires ${formatAsMDYYYY(new Date(programEndDate))})`;
    }
    return subHeaderText;
};
