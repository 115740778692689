import { TextInput } from '@interstate/components/TextInput';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatUtils, kebabCase } from '@makemydeal/dr-common-utils';
import { offerActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { TAX_OVERRIDE_DEBOUNCE_TIMER } from '../constants';
import { TaxOverrideFieldInputContainer } from './TaxOverrideField.style';
import { TOTAL_TAX } from '@makemydeal/dr-dash-components';

type TaxTotalOverrideFieldProps = {
    setTaxOverrideFormEdited: (taxFormEdited: boolean) => void;
    taxOverrideFormEdited: boolean;
};

const TaxTotalOverrideField = (props: TaxTotalOverrideFieldProps) => {
    const dispatch = useDispatch();

    const totalTax = useSelector(offerReduxSelectors.getTotalTax);
    const formattedTotalTax = formatUtils.formatDollarsAndCents(totalTax);

    const [inputText, setInputText] = useState(formattedTotalTax);

    useEffect(() => {
        setInputText(formattedTotalTax);
    }, [totalTax]);

    const handleInputChanged = (event: any) => {
        props.setTaxOverrideFormEdited(true);
        const taxOverrideValue = event.target.value;
        if (taxOverrideValue !== inputText) {
            const numericTotalTaxOverride = parseFloat(taxOverrideValue.replace(/[$,]/g, ''));
            const MAXIMUM_ALLOWED_VALUE = 999999.99;
            const MINIMUM_ALLOWED_VALUE = 0;
            if (numericTotalTaxOverride < MINIMUM_ALLOWED_VALUE || numericTotalTaxOverride > MAXIMUM_ALLOWED_VALUE) {
                debouncedActionCaller(0);
                return;
            }
            setInputText(taxOverrideValue);
            debouncedActionCaller(numericTotalTaxOverride);
        }
    };

    /* istanbul ignore next */
    const initiateTaxOverridePaymentCall = (overrideValue: number) => {
        dispatch(offerActionCreators.updatedTotalTaxOverride(overrideValue));
        const formattedTotalTax = formatUtils.formatDollarsAndCents(overrideValue);
        setInputText(formattedTotalTax);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedActionCaller = useCallback(debounce(initiateTaxOverridePaymentCall, TAX_OVERRIDE_DEBOUNCE_TIMER), []);

    return (
        <TaxOverrideFieldInputContainer $taxOverrideFormEdited={props.taxOverrideFormEdited}>
            <TextInput
                name={kebabCase(TOTAL_TAX)}
                label={TOTAL_TAX}
                value={inputText}
                onChange={handleInputChanged}
                data-testid={kebabCase(TOTAL_TAX)}
            />
        </TaxOverrideFieldInputContainer>
    );
};
export default TaxTotalOverrideField;
