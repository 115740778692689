// libraries
import { getAllRoutes } from '@makemydeal/dr-activities-deal-history';
import { NavigationItemStatus } from '@makemydeal/dr-activities-shared';

// interfaces/types
import type { IFlowConfig } from '../../flowMasterInterfaces';

// components
import CreditDecisionContainer from './components/CreditDecisionContainer';

// consts/enums
import { APP_NAME, CREDIT_DECISION_ROUTE_ROOT } from './constants';

export const allRoutes = getAllRoutes(CREDIT_DECISION_ROUTE_ROOT);

// istanbul ignore next
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noopReducer = () => {};

/* this activity won't appear on the dr-activities-dashboard card list,
 * so including the bare minimum inputs to satisfy the interface
 */
export const creditDecisionConfiguration: IFlowConfig<any> = {
    name: APP_NAME,
    navCard: {
        label: 'Credit Decision',
        icon: 'vehicle',
        cardId: 'dr-sp-credit-Decision'
    },
    showCardSelector: () => false,
    isCompleteSelector: () => false,
    routingComponent: CreditDecisionContainer,
    defaultRoute: allRoutes.root,
    routes: [...Object.keys(allRoutes).map((routeName) => allRoutes[routeName])],
    middleware: null,
    reducer: noopReducer, // credit Decision doesn't have any of its own state
    isLocked: (_state: unknown) => false,
    dependsOnLeadFormCompletion: () => false,
    status: () => NavigationItemStatus.DEFAULT,
    isDisabled: () => false
};
