// styles
import styled from 'styled-components';

// Components
import ModalDialog from '@cx/ui/ModalDialog';

import { BreakPoint } from '@makemydeal/dr-activities-common';

export const Modal = styled(ModalDialog)`
    svg.modal-body {
        color: #005ba8;
    }
    .modal-dialog {
        margin: 0px;
    }
    .modal-content {
        height: 100vh;
        width: 100vw;
    }
    @media (min-width: ${BreakPoint.SCREEN_SM_MIN}) {
        .modal-content {
            height: inherit;
            width: inherit;
        }

        .modal-dialog {
            margin: 10px;
            padding: 120px 0 0 0;
        }
    }

    @media (min-width: ${BreakPoint.SMALL}) {
        .modal-dialog {
            margin: 30px auto;
            padding: 120px 36px 8px 36px;
        }
    }
`;

export const DealStatusHeader = styled.div`
    font-size: 20px;
    font-weight: 600;
    color: #003468;
`;

export const DateSectionHeader = styled.div`
    font-size: 14px;
    font-weight: 600;
    margin: 20px 0 10px 0;
`;
