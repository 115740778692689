import { Alert } from '@interstate/components/Alert';
import { Interstitial as LoadingIndicator } from '@interstate/components/Interstitial';
import { Typography } from '@interstate/components/Typography';
import { FlexCol } from '@makemydeal/dr-dash-components';
import { programActionCreators, programsSelectors } from '@makemydeal/dr-dash-store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IncentiveCard } from './IncentiveCard';
import { LoadingIndicatorWrapper } from './styles';

export const IncentiveSelectorForm = () => {
    const dispatch = useDispatch();
    const quote = useSelector(programsSelectors.getSelectedProgram);
    const available = useSelector(programsSelectors.getAvailableProgramIncentives);

    const errorDesc = 'No Incentives Found';
    const loadingDesc = 'Loading Incentives...';

    // only getting APPLIED non-conditionals
    const nonConditionals = useSelector(programsSelectors.getAppliedNonConditionalIncentives);

    // getting ALL available, but checking the applied ones.
    const conditionals = useSelector(programsSelectors.getAvailableConditionalIncentives);
    const hasConditionals = conditionals.length > 0;
    const hasNonConditionals = nonConditionals.length > 0;

    const isError = useSelector(programsSelectors.getIncentivesLoadingError);
    const isLoading = quote && available == null;

    useEffect(() => {
        return () => {
            dispatch(programActionCreators.clearIncentives());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isError)
        return (
            <Alert
                type="error"
                role="alert"
                title={errorDesc}
                sx={{
                    '.MuiAlertTitle-root': { margin: '0 !important', padding: '10px !important' }
                }}
            />
        );

    if (isLoading)
        return (
            <LoadingIndicatorWrapper>
                <LoadingIndicator message={loadingDesc} size="large" />
            </LoadingIndicatorWrapper>
        );

    return (
        <FlexCol gap="1rem" data-testid="incentive-container">
            {hasNonConditionals && (
                <>
                    <Typography variant="h4">Included</Typography>
                    {nonConditionals.map((nonconditional, nx) => (
                        <IncentiveCard incentive={nonconditional} key={`nonconditional-${nx}`} />
                    ))}
                </>
            )}
            {/* NOTE: this for flex styling; to gap the top of conditionals when both exist. */}
            {hasConditionals && hasNonConditionals && <div data-testid="spacer-div" />}

            {hasConditionals && (
                <>
                    <Typography variant="h4">Select Conditional Incentives</Typography>
                    {conditionals.map((conditional, cx) => (
                        <IncentiveCard conditional incentive={conditional} key={`conditional-${cx}`} />
                    ))}
                </>
            )}
        </FlexCol>
    );
};
