import { PRINT_PDF, PRINT_PDF_LOADING } from '../actionTypes/printPdfActionTypes';

export const printPdf = () => {
    return {
        type: PRINT_PDF
    };
};

export const printPdfLoading = (loading: boolean) => {
    return {
        type: PRINT_PDF_LOADING,
        payload: {
            loading
        }
    };
};
