// externals
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { PushToDarwinState, PushToDarwinStatus } from '@makemydeal/dr-dash-types';

// consts/enums
import { OPEN_IN_DARWIN_FLOW, PUSH_TO_DARWIN_FLOW, DARWIN_ERROR_MESSAGES } from '../constants';
import { OFFER_SAVE_SUCCESS, OFFER_SAVE_FAILURE, LINKS_FAILURE } from '../actionTypes/offerActionTypes';
import {
    SET_PUSH_TO_DARWIN_STATUS,
    PUSH_TO_DARWIN,
    PUSH_TO_DARWIN_ERROR,
    PUSH_TO_DARWIN_SUCCESS,
    OPEN_IN_DARWIN
} from '../actionTypes/pushToDarwinActionTypes';

export const pushToDmsInitialState: PushToDarwinState = {
    status: PushToDarwinStatus.Ready
};

export type PushToDarwinReducer = Reducer<PushToDarwinState>;

export const reducer: PushToDarwinReducer = (state: PushToDarwinState = pushToDmsInitialState, action: AnyFSA) => {
    const { payload, type } = action;
    return produce(state, (draft: Draft<PushToDarwinState>) => {
        switch (type) {
            case OPEN_IN_DARWIN:
            case PUSH_TO_DARWIN:
                draft.status = PushToDarwinStatus.Sending;
                draft.currentFlow = action.type === OPEN_IN_DARWIN ? OPEN_IN_DARWIN_FLOW : PUSH_TO_DARWIN_FLOW;

                break;

            case LINKS_FAILURE:
            case OFFER_SAVE_FAILURE:
            case PUSH_TO_DARWIN_ERROR:
                if (draft.currentFlow === OPEN_IN_DARWIN_FLOW || draft.currentFlow === PUSH_TO_DARWIN_FLOW) {
                    const darwinErrorDetails =
                        draft.currentFlow === OPEN_IN_DARWIN_FLOW
                            ? DARWIN_ERROR_MESSAGES.OPEN_IN_DARWIN_ERROR
                            : DARWIN_ERROR_MESSAGES.PUSH_TO_DARWIN_ERROR;

                    const errorDetails =
                        payload?.errorDetails === DARWIN_ERROR_MESSAGES.STOCK_NUMBER_ERROR
                            ? payload.errorDetails
                            : darwinErrorDetails;

                    draft.error = { errorDetails, statusCode: payload?.statusCode };
                    draft.status = PushToDarwinStatus.Failure;
                }

                break;

            case OFFER_SAVE_SUCCESS: {
                const offerSaveSource = action.meta?.source;
                if (offerSaveSource === PUSH_TO_DARWIN_FLOW) {
                    const { dealXgId } = payload;
                    draft.dealXgId = dealXgId;
                }
                break;
            }

            case PUSH_TO_DARWIN_SUCCESS:
                draft.darwinResponseData = { url: payload.data.URL };
                draft.status = PushToDarwinStatus.Ok;
                break;

            case SET_PUSH_TO_DARWIN_STATUS:
                draft.status = payload;
                break;

            default:
                break;
        }
    });
};
