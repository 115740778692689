import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const LenderDecisionContainer = styled.div`
    justify-content: center;
    margin-top: 0.8rem;
    width: 100%;
`;
export const DescriptionInfo = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    align-items: baseline;
    font-size: 1.1rem;

    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        flex-direction: column;
    }
`;

export const FinancingContainer = styled.div`
    display: flex;
    gap: 1.5rem;
    align-items: baseline;

    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        justify-content: space-between;
        width: 100%;
    }
`;

export const DealertrackContainer = styled.div`
    .openDealertrackIcon {
        width: 1rem;
        height: 1rem;
    }
`;
export const DeepLinkDealertrack = styled.a`
    display: flex;
    align-items: baseline;
    gap: 0.3rem;
`;

export const FinancingTitle = styled.span`
    font-size: 1.3rem;
`;

export const SendLinkContainer = styled.span`
    display: flex;
    gap: 1.5rem;

    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        justify-content: space-between;
        flex-direction: row-reverse;
        width: 100%;
    }
`;

export const SendLinkWrapper = styled.span`
    visibility: hidden;
`;

export const LayoutContainer = styled.div`
    border: 2px solid rgb(221, 221, 221);
    display: flex;
    height: 100%;

    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        flex-direction: column;
    }

    .lenderDecisionWidget {
        position: relative;
        width: 70%;
        height: auto;
        z-index: 1;
    }
`;

export const CardContainer = styled.div`
    width: 30%;
    padding: 1rem;
    border-right: 2px solid rgb(221, 221, 221);
    background-color: rgb(245, 246, 247);

    .lenderDecisionTitle {
        font-weight: 500;
    }

    @media only screen and (max-width: ${BreakPoint.MEDIUM}) {
        width: 40%;
    }

    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        flex-direction: column;
        width: 100%;
    }
`;
export const LenderInfoContainer = styled.div`
    padding: 1rem;
    width: 70%;

    @media only screen and (max-width: ${BreakPoint.MEDIUM}) {
        width: 60%;
    }

    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        width: 100%;
        height: 1000px;
    }
`;

export const CardWrapper = styled.div`
    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        display: flex;
        gap: 0.5rem;
        overflow-x: scroll;
        flex-direction: row;
    }
`;
