// externals
import React, { useState } from 'react';

// libraries
import { CreditDecision } from '@makemydeal/dr-dash-types';
import { sortingUtils } from '@makemydeal/dr-dash-ui-utils';

// components
import { LenderDecisionCard } from './LenderDecisionCard';

// styles
import { CardWrapper } from './LenderDecisionInfo.style';
import { useDispatch } from 'react-redux';

// selectors
import { creditDecisionActionCreators } from '@makemydeal/dr-dash-store';

export type LenderListProps = {
    onCardSelected: (e: string) => void;
    lenderDecisions: CreditDecision[];
    sortOrder: any[];
    showFooter: () => void;
    setSelectedDecision: (x: CreditDecision) => void;
};

export const LenderList = ({ onCardSelected, lenderDecisions, sortOrder, showFooter, setSelectedDecision }: LenderListProps) => {
    const mutatedList: CreditDecision[] = [];
    const [lenderDecisonsList, setLenderDecisionList] = useState<CreditDecision[]>(lenderDecisions);
    const dispatch = useDispatch();

    const handleCardSelected = (e: any) => {
        onCardSelected(e);
        lenderDecisonsList.forEach((lender) => {
            /* istanbul ignore else */
            if (lender.lenderId === e) {
                lender.isActive = !lender.isActive;
                setSelectedDecision(lender);
            } else {
                lender.isActive = false;
            }
            mutatedList.push(lender);
        });
        setLenderDecisionList(mutatedList);
    };

    if (sortOrder.length === 0) {
        lenderDecisions.sort((a, b) => (a.approvalStatus > b.approvalStatus ? 1 : -1));
    } else {
        lenderDecisions.sort(sortingUtils.sortByStatusFunction);
    }

    return (
        <CardWrapper data-testid="credit-decision-lender-list">
            {lenderDecisonsList.map((lenderDecision) => {
                return (
                    <LenderDecisionCard
                        isCardActive={lenderDecision.isActive || false}
                        onCardSelected={handleCardSelected}
                        data={lenderDecision}
                        showFooter={showFooter}
                    />
                );
            })}
        </CardWrapper>
    );
};
