import { createContext } from 'react';
import { FMContext, FMNames } from '../types';

export const returnZero = () => 0;
export const vehicleInfoFormKeys: FMNames[] = [
    'msrp',
    'sellingPrice',
    'invoiceAmount',
    'cost',
    'mileage',
    'wholesaleValue',
    'wholesaleBookSource',
    'stockNumber'
];
export const FormContext = createContext<FMContext>({
    form: { values: {}, initialValues: {}, touched: {}, error: {} },
    canApply: false,
    changeHandler: returnZero,
    handleClick: returnZero,
    hideDrawer: returnZero,
    isStockEditable: false,
    handleClickEditStock: returnZero
});
