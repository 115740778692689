// istanbul ignore file
import styled from 'styled-components';

export const StyledRebatesFlexView = styled.div<{ cols: number }>`
    gap: 0;
    display: grid;
    align-content: stretch;
    align-items: stretch;
    ${({ cols }) => `grid-template-columns: repeat(${cols}, minmax(0, 1fr));`}

    > hr {
        ${({ cols }) => `grid-column: span ${cols} / span ${cols};`}
        width: 100%;
    }
    > hr:last-child {
        display: none;
    }
`;

export const StyledRebatesTabularView = styled.table`
    table-layout: auto;
    table: margin: 0;
`;
export const StyledHr = styled.hr`
    bg-color: #cacfd9;
    width: 100%;
    margin: 0;
`;

export const StyledHrWithMargin = styled(StyledHr)`
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export const CustomGrid = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.25rem;
    column-gap: 1rem;
    justify-content: space-between;
    align-items: center;
`;
