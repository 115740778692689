// externals
import { PropsWithChildren } from 'react';

// libraries
import { Modal } from '@interstate/components/Modal';
import { Button } from '@interstate/components/Button';

// consts/enums
import { CANCEL_BUTTON_TEXT, CONFIRM_BUTTON_TEXT, HEADER_TEXT } from './feeResetDialogConsts';
import { Typography } from '@interstate/components/Typography';
import { Grid } from '@interstate/components/Grid';

export type FeeResetDialogDispatchProps = {
    onHide(): void;
    onCancel(): void;
    onConfirm(): void;
};

export type FeeResetDialogStateProps = {
    'data-testid': string;
};

export type FeeResetDialogProps = FeeResetDialogStateProps & FeeResetDialogDispatchProps;

export const FeeResetDialog = (props: PropsWithChildren<FeeResetDialogProps>) => {
    const { onHide, onCancel, onConfirm } = props;

    const baseDataTestId = props['data-testid'];

    const header = (
        <Typography data-testid={`${baseDataTestId}-header`} variant="h4" tag="h4" color="sem.color.on-surface.primary">
            {HEADER_TEXT}
        </Typography>
    );
    return (
        <Modal
            size="small"
            data-testid={baseDataTestId}
            show={true}
            header={header}
            footer={{
                primary: {
                    label: CONFIRM_BUTTON_TEXT,
                    action: onConfirm,
                    'data-testid': `${baseDataTestId}-close-button`,
                    buttonStyle: 'primary'
                },
                options: [
                    {
                        label: CANCEL_BUTTON_TEXT,
                        action: onCancel,
                        'data-testid': `${baseDataTestId}-cancel-button`,
                        buttonStyle: 'tertiary'
                    }
                ]
            }}
            onHide={onHide}
        >
            <Typography variant="body-md" tag="p">
                This will reset all Fees to their original value.
            </Typography>
        </Modal>
    );
};
