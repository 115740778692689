import { BreakPoint } from '@makemydeal/dr-activities-common';
import { rem } from 'polished';
import styled from 'styled-components';

export const CreditBureauSeparator = styled.div`
    border-bottom: 0.1rem solid #c8c8c8;
`;
export const InterstateCreditBureauSeparator = styled.div`
    border-bottom: ${rem(1)} solid #b2b2b2;
    margin-bottom: ${rem(16)};
`;

export const CreditBureauContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;

    @media (min-width: ${BreakPoint.SMALL}) {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
`;
