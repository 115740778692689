// externals
import { useSelector } from 'react-redux';

// libraries
import { Card } from '@interstate/components/Card';
import { dealHistorySelectors, offerSelectors, quickCompareSelectors } from '@makemydeal/dr-dash-store';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { CASH, FINANCE, IOffer, IShopper, LEASE, OfferType } from '@makemydeal/dr-platform-types';
import { Box } from '@interstate/components/Box';
import { dateUtils } from '@makemydeal/dr-common-utils';

// interfaces/types
import type { IOfferCompareColumnProps } from './types/offerCompareColumnProps';

// components
import { MakeCurrentModal } from '../makeCurrentModal/MakeCurrentModal.interstate';
import OfferCardHeader from '../offerCardHeader/OfferCardHeader.interstate';
import { OfferMessage } from '../offerMessage/OfferMessage.interstate';
import CompareColumnSummary from './CompareColumnSummary.interstate';
import { StyledOfferCompareColumn } from './OfferCompareColumn.interstate.style';
import { CompareCashColumnDetail } from './compareColumnDetails/CompareCashColumnDetail.interstate';
import { CompareFinanceColumnDetail } from './compareColumnDetails/CompareFinanceColumnDetail.interstate';
import { CompareLeaseColumnDetail } from './compareColumnDetails/CompareLeaseColumnDetail.interstate';
import { MakeCurrentButtonView } from '../makeCurrentModal/types';
import { OfferCardContent } from '../offerCardContent/OfferCardContent';
import { OfferCardStock } from '../offerCardStock/OfferCardStock';

// utils
import { determineDeltas } from '../utils/determineDeltasUtil';
import { OfferCardBadges } from '../offerCardBadges/OfferCardBadges';

const OfferCompareColumn = (props: IOfferCompareColumnProps) => {
    const {
        isCurrent,
        isDealHistory,
        isQuickCompare,
        onMakeCurrentOffer,
        isSelected,
        onDealClick,
        isDefaultOffer,
        isMostRecentDealerOffer,
        isMostRecentShopperOffer,
        deal
    } = props;

    const workingOffer = useSelector(offerSelectors.getWorkingOffer);
    const comparingOffer = useSelector(quickCompareSelectors.getQuickCompareOffer);
    const selectedOffers = useSelector(dealHistorySelectors.getSelectedDealHistory);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const deltaList = determineDeltas(deal, workingOffer, isCurrent);

    const {
        shopper,
        createdOn,
        offerType,
        terms,
        penciledInputs,
        offerSource,
        applicationName,
        vehicle,
        cmdMessageToShopper: dealerMessage,
        messageToDealer: shopperMessage
    } = deal.offer;
    const { firstName, lastName } = shopper as IShopper;

    const offerPrice = offerSelectors.getSellingPrice(deal as unknown as StateTree);
    const payment =
        offerType === CASH
            ? penciledInputs && penciledInputs.byOfferType && penciledInputs.byOfferType[CASH]
            : terms.byId[(penciledInputs as any).byOfferType[offerType as string].term]; // TODO: replace with selector US847966

    const onColumnClick = () => isDealHistory && onDealClick && onDealClick();

    const offerTypesDiffer = (offersList: IOffer[]) => {
        return offersList.some((currOffer) => currOffer.offerType !== workingOffer.offer.offerType);
    };

    const hasDifference = () => {
        if (isDealHistory) {
            return offerTypesDiffer([...selectedOffers.map((deal) => deal.offer)]);
        } else {
            return offerTypesDiffer([comparingOffer.offer]);
        }
    };

    const renderColumnDetail = () => {
        const _hasDifference = hasDifference();
        switch (offerType as OfferType) {
            case CASH:
                return <CompareCashColumnDetail deal={deal} hasDifference={_hasDifference} deltaList={deltaList} />;
            case FINANCE:
                return <CompareFinanceColumnDetail deal={deal} hasDifference={_hasDifference} deltaList={deltaList} />;
            case LEASE:
                return <CompareLeaseColumnDetail deal={deal} hasDifference={_hasDifference} deltaList={deltaList} />;
        }
    };

    const createdOnFormattedDate = createdOn && dateUtils.formatAsMMDDYYYY(new Date(createdOn));
    const createdOnFormattedTime = createdOn && dateUtils.formatAsTime(new Date(createdOn));

    return (
        <div onClick={onColumnClick} style={{ alignSelf: 'stretch' }} className="offer-compare-column-container">
            <Card
                className="offer-compare-column"
                data-testid={`${isCurrent ? 'current' : 'selected'}-offer-compare-column`}
                sx={{
                    border: isSelected ? '2px solid #005BA8' : '1px solid #B2B2B2'
                }}
                content={
                    <StyledOfferCompareColumn container gap={1} flexDirection="column">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            data-testid="offer-compare-column-offer-card-header"
                        >
                            <OfferCardHeader
                                createdOnDate={createdOnFormattedDate}
                                createdOnTime={createdOnFormattedTime}
                                offerSource={offerSource}
                                applicationName={applicationName}
                                isDefaultOffer={isDefaultOffer}
                                isMostRecentDealerOffer={isMostRecentDealerOffer}
                                isMostRecentShopperOffer={isMostRecentShopperOffer}
                                showSubHeader={false}
                            />
                            {isABPencilEnabled && !isQuickCompare && <OfferCardBadges isCurrent={isCurrent} />}
                        </Box>
                        {!isABPencilEnabled && (
                            <CompareColumnSummary
                                isCurrentOffer={isCurrent}
                                createdOn={createdOn as string}
                                offerType={offerType as OfferType}
                                lastName={lastName}
                                firstName={firstName}
                                offerSource={offerSource}
                                offerPrice={offerPrice}
                                payment={payment}
                                isDealHistory={isDealHistory}
                                isDefaultOffer={isDefaultOffer}
                                isMostRecentDealerOffer={isMostRecentDealerOffer}
                                isMostRecentShopperOffer={isMostRecentShopperOffer}
                            />
                        )}

                        {isABPencilEnabled && (
                            <Box
                                display="grid"
                                gap={1}
                                marginTop={2}
                                marginBottom={2}
                                data-testid="offer-compare-column-offer-card-content"
                            >
                                <Box
                                    {...(isQuickCompare && {
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    })}
                                >
                                    <OfferCardStock stockNumber={vehicle.stockNumber} />
                                    {isQuickCompare && <OfferCardBadges isCurrent={isCurrent} />}
                                </Box>
                                <OfferCardContent offer={deal.offer} />
                            </Box>
                        )}
                        {offerType && renderColumnDetail()}
                        {!isABPencilEnabled && (shopperMessage || dealerMessage) && (
                            <OfferMessage shopperMessage={shopperMessage} dealerMessage={dealerMessage} offerSource={offerSource} />
                        )}
                        {!isCurrent && onMakeCurrentOffer && (
                            <MakeCurrentModal
                                onMakeCurrentOffer={onMakeCurrentOffer}
                                makeCurrentButtonView={
                                    isDealHistory ? MakeCurrentButtonView.DealHistory : MakeCurrentButtonView.QuickCompare
                                }
                            />
                        )}
                    </StyledOfferCompareColumn>
                }
            />
        </div>
    );
};

export default OfferCompareColumn;
