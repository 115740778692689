// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import { SendDialogTypes, SendToCustomerDialogEmailStatus, StateTree } from '@makemydeal/dr-dash-types';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

// selectors
import { getVehicleVin } from './../selectors/vehicleSelectors';
import { getConnectionId } from './../selectors/connectionSelectors';
import { getCreditAppURL, getDocUploadURL, getTestDriveURL } from '../selectors/offerInfoSelectors';
import { getShopperEmail } from '../selectors/offerSelectors';

// consts/enums
import {
    COPY_ACTIVITY_SHARE_URL,
    COPY_ACTIVITY_SHARE_URL_REQUEST,
    COPY_ACTIVITY_SHARE_URL_SUCCESS,
    COPY_ACTIVITY_SHARE_URL_FAILURE
} from '../actionTypes/notifyActionTypes';

const getDealUrl = (state: StateTree, dialogType: SendDialogTypes) => {
    switch (dialogType) {
        case SendDialogTypes.CreditApp:
            return getCreditAppURL(state);

        case SendDialogTypes.DocUpload:
            return getDocUploadURL(state);

        case SendDialogTypes.TestDrive:
            return getTestDriveURL(state);

        default:
            return '';
    }
};

export const copyActivityShareUrl = (dialogType: string) => {
    return {
        type: COPY_ACTIVITY_SHARE_URL,
        payload: {
            dialogType
        }
    };
};

export const copyActivityShareUrlRSAA = (appPrefix: string, state: StateTree, action: any) => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, 'shopperNotifications');
    const endpoint = bffApiUrlResult.url;

    const payload = {
        activityName: action.payload.dialogType,
        connectionId: getConnectionId(state),
        dealerId: dealerSelectors.getDealerId(state),
        dealUrl: getDealUrl(state, action.payload.dialogType),
        email: getShopperEmail(state),
        notificationType: 'sharedByURL',
        vin: getVehicleVin(state),
        experience: 'cmd'
    };

    return {
        [RSAA]: {
            endpoint,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
            types: [COPY_ACTIVITY_SHARE_URL_REQUEST, COPY_ACTIVITY_SHARE_URL_SUCCESS, COPY_ACTIVITY_SHARE_URL_FAILURE]
        }
    };
};
