// consts/enums
import { SavedOfferPayloadItemComparison, SavedOfferPayloadObjectComparison } from '../types/comparisonUtilsEnums';

// interfaces/types
import type {
    CompareOfferNodesResult,
    CompareToLastOfferPayloadObjectResult,
    SavedOfferPayloadObject,
    SavedOfferPayloadObjectComparisonDifference
} from '../types/comparisonUtilsTypes';

export const compareOfferPayloadObjects = (
    sourceObject: SavedOfferPayloadObject,
    targetObject: SavedOfferPayloadObject
): CompareToLastOfferPayloadObjectResult => {
    const excludedKeyPaths = new Set<string>(['root.username', 'root.employees', 'root.purpose', 'root.offer.transferToDMS']);
    const { different, differences } = compareOfferNodesResult('root', sourceObject, targetObject, [], excludedKeyPaths);
    return {
        simpleResult: different ? SavedOfferPayloadObjectComparison.Different : SavedOfferPayloadObjectComparison.Same,
        detailedResult: differences
    };
};

export const compareOfferNodesResult = (
    keyPath: string,
    obj1: any,
    obj2: any,
    differences: SavedOfferPayloadObjectComparisonDifference[],
    excludedKeyPaths: Set<string>
): CompareOfferNodesResult => {
    const isExcludedItem = excludedKeyPaths.has(keyPath);
    if (typeof obj1 === 'string' && typeof obj2 === 'string') {
        const different = !isExcludedItem && obj1 !== obj2;
        if (different) {
            differences.push({
                keyPath,
                comparisonResult: SavedOfferPayloadItemComparison.Modified,
                sourceValue: obj1,
                targetValue: obj2
            });
        }
        return {
            different,
            differences
        };
    } else if (!isExcludedItem && `${obj1}` !== `${obj2}`) {
        differences.push({
            keyPath,
            comparisonResult: SavedOfferPayloadItemComparison.Modified,
            sourceValue: obj1,
            targetValue: obj2
        });
        return {
            different: true,
            differences
        };
    }
    const obj1Nodes = !obj1 ? [] : Object.keys(obj1);
    const obj2Nodes = !obj2 ? [] : Object.keys(obj2);
    if (obj1Nodes.length !== obj2Nodes.length) {
        differences.push({
            keyPath,
            comparisonResult: SavedOfferPayloadItemComparison.Modified,
            sourceValue: `${obj1Nodes.length} items`,
            targetValue: `${obj2Nodes.length} items`
        });
        return {
            different: true,
            differences
        };
    }
    let different = false;
    let searchForTargetDifference = false;
    obj1Nodes.forEach((key) => {
        const obj1ItemKeyPath = `${keyPath}.${key}`;
        const isObj1ItemKeyExcluded = excludedKeyPaths.has(obj1ItemKeyPath);
        if (!isObj1ItemKeyExcluded) {
            const obj2HasItemKey = Object.prototype.hasOwnProperty.call(obj2, key);
            if (!obj2HasItemKey) {
                different = !isObj1ItemKeyExcluded;
                differences.push({
                    keyPath,
                    comparisonResult: SavedOfferPayloadItemComparison.SourceOnly,
                    sourceValue: obj1[key],
                    targetValue: undefined
                });
                searchForTargetDifference = true;
            } else {
                const obj1Value = obj1[key];
                const obj2Value = obj2[key];
                if (!different) {
                    const { different: newDifferent, differences: newDifferences } = compareOfferNodesResult(
                        obj1ItemKeyPath,
                        obj1Value,
                        obj2Value,
                        differences,
                        excludedKeyPaths
                    );
                    different = newDifferent;
                    differences = newDifferences;
                }
            }
        }
    });
    if (searchForTargetDifference) {
        obj2Nodes.forEach((key) => {
            if (!Object.prototype.hasOwnProperty.call(obj1, key)) {
                different = true;
                differences.push({
                    keyPath,
                    comparisonResult: SavedOfferPayloadItemComparison.TargetOnly,
                    sourceValue: undefined,
                    targetValue: obj2[key]
                });
            }
        });
    }
    return {
        different,
        differences
    };
};
