// externals
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { dealXgDetailsSelectors, navigationActionTypes, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { ACTIVITY_NAME_INCENTIVES } from '@makemydeal/dr-dpm-client';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// components
import DealActivityCard from '../DealActivityCard.interstate';

const name = 'incentives';

const IncentivesCard = () => {
    const dispatch = useDispatch();
    const totalRebates = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);
    const completed = useSelector(offerReduxSelectors.getAppliedIncentivesForNonDealerCash).length > 0;
    const cardValue = useMemo(() => formatDollarsAndCents(totalRebates), [totalRebates]);
    const isEcommerceDeal = useSelector(dealXgDetailsSelectors.getIsEcommerceDeal);

    const isIncentivesActivityEnabledAfterBootstrap = useSelector(featureToggleSelectors.isIncentivesActivityEnabledAfterBootstrap);
    const incentivesDpmState = useSelector((state: StateTree) =>
        dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_INCENTIVES)
    );

    return (
        <DealActivityCard
            dpmState={incentivesDpmState}
            name={name}
            cardValue={cardValue}
            completed={completed}
            showCardAnchor={true}
            action={() => {
                if (isIncentivesActivityEnabledAfterBootstrap) {
                    if (isEcommerceDeal) dispatch({ type: navigationActionTypes.DASH_START_INCENTIVES_ACTIVITY });
                    else dispatch({ type: navigationActionTypes.DASH_START_PROGRAMS_ACTIVITY });
                }
            }}
        />
    );
};

export default IncentivesCard;
