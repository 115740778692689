// TODO: use centralized utils between here and activities-desking

// externals
import { formatUtils } from '@makemydeal/dr-common-utils';
import numeral from 'numeral';

export const convertToNumber = (dollars: string): number => Number(dollars.replace(/[^0-9.]/g, ''));

export const isNaNish = (text: string): boolean => /^[\D]+$/.test(text) || text.trim() === ''; // Test for only non-numerics

export const formatMileageCharge = (amount: number): string => {
    return numeral(amount).format('0,0.0[00]');
};

export const formatAprRate = (amount: number): string => {
    return numeral(amount).format('0,0.000');
};

export const formatMoneyFactor = (amount: number): string => {
    return numeral(amount).format('0,0.00000');
};

export const formatPayment = (payment: number) => {
    const formattedDollar = formatUtils.formatDollars(payment);
    const numeralFormat = numeral(formattedDollar).format('0,0.00');
    return numeralFormat;
};
