// istanbul ignore file
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    .bridge-bar-menu-overlay { display: none !important; }

    .interstate-reference {
        z-index: 1300 !important; // @TODO: TA2381111 - waiting for the fix (interstate)
    }

    #ready-to-send-dialog {

        .modal-dialog {
            margin-top: 18rem;
        }

        .modal-body {
            padding-top: 0;
            padding-bottom: 0;
        }

        .modal-footer {
            padding-top: 16px;
        }
    }

    #send-to-customer-dialog {

        .modal-dialog {
            margin-top: 18rem;
        }

        .modal-body {
            padding-top: 0;
            padding-bottom: 0;
        }

        .modal-footer {
            background-color: #f4f4f5;
            padding-top: 16px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }

    .share-to-showroom-dialog,
    .share-to-customer-dialog,
    .update-offer-dialog {

        .modal-dialog {
            margin-top: 18rem;
        }

        .modal-body {
            padding-top: 0;
            padding-bottom: 0;
        }

        .modal-footer {
            background-color: #f4f4f5;
            padding-top: 16px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }

    .header-root {
        background-color: #fff
    }

    // Override for Fee options category select
    div[role='tooltip'][id^='select-input-options-fee-category-select-input'] {
        width: auto !important;
    }


`;
