// externals
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';
import { Button } from '@interstate/components/Button';
import { Modal } from '@interstate/components/Modal';

// style
import './confirmPushToDms.css';

// constants
import {
    CANCEL_BUTTON_TEXT,
    CLOSE_BUTTON_TEXT,
    DIALOG_HEADER_INTERSTATE,
    DIALOG_HEADER_RE_PUSH_INTERSTATE,
    DIALOG_PROMPT,
    IMPORTANT_DIALOG_PROMPT,
    LOADING_TEXT_INTERSTATE,
    PUSH_ERROR_DIALOG_HEADER_INTERSTATE,
    PUSH_VIN_ERROR_DIALOG_MESSAGE
} from '../pushToDmsConstants';

// libraries
import { featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';
import {
    CDMS,
    DMS_PLUS,
    bridgeUserSelectors,
    dealXgDetailsSelectors,
    deskingActionCreators,
    offerInfoSelectors,
    offerReduxSelectors,
    pushToDmsActionCreators,
    pushToDmsSelectors,
    tradeInCardSelectors,
    tradeInSelectors
} from '@makemydeal/dr-dash-store';

// types
import { PushToDmsStatus } from '../pushToDmsTypes';
import { Grid } from '@interstate/components/Grid';
import { usePushToDmsConfirmButtonText } from '../pushToDmsHooks';

export interface IConfirmPushToDmsDialogProps {
    show: boolean;
    onSaved(): void;
    onFailed(): void;
    onCancel(): void;
    onHide(): void;
    showLoader(): void;
    closeLoader(): void;
    setIsPushToCdmsActive(flag: boolean): void;
    setIsExtendedLoader(flag: boolean): void;
}

const ConfirmPushToDmsDialog = (props: IConfirmPushToDmsDialogProps) => {
    const prefix = 'confirm-push-to-dms-dialog';
    const { show, onFailed, onSaved, onCancel, onHide, showLoader, closeLoader, setIsPushToCdmsActive, setIsExtendedLoader } =
        props;
    const [pushToDmsStatus, setPushToDmsStatus] = useState(PushToDmsStatus.Ready);

    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const cdmsRequestFailure = useSelector(pushToDmsSelectors.getCdmsRequestFailure);
    const hasCdmsResponseData = Object.keys(useSelector(pushToDmsSelectors.getCdmsResponseData)).length > 0;
    const returnedSuccessfulCdmsResponse = useSelector(pushToDmsSelectors.getReturnedSuccessfulCdmsResponse);
    const dmsIntegrationToggle = useSelector(dealerSelectors.getDMSIntegrationToggle);
    const enableEnhancedPushToDmsPlusMV = useSelector(featureToggleSelectors.enableEnhancedPushToDmsPlusMV);
    const isCDL3InMVEnabled = useSelector(featureToggleSelectors.isCDL3EnabledInMV);
    const lastPushToDmsUpdate = useSelector(dealXgDetailsSelectors.getLastPushToDmsUpdate);

    const isShouldIncludeTradeIn = useSelector(tradeInCardSelectors.getShouldIncludeTradeIn);
    const isTradeCompleted = useSelector(tradeInSelectors.isTradeInCompleted);
    const isTradeVinValid = useSelector(tradeInSelectors.isValidVin);
    const isVinValidToPushToDms = isTradeCompleted ? isTradeVinValid : true;

    const dmsPlusRePush = enableEnhancedPushToDmsPlusMV && dmsIntegrationToggle === DMS_PLUS && lastPushToDmsUpdate;
    const isEnableOptionalPbcUi = useSelector(featureToggleSelectors.enableOptionalPbcUi);

    const dispatch = useDispatch();

    const confirmBtnText = usePushToDmsConfirmButtonText();

    const handleShowChange = () => {
        if (show) {
            setPushToDmsStatus(PushToDmsStatus.Ready);
        }
    };

    const handleOfferStatusChange = () => {
        if (show) {
            if (offerIsBeingSaved === true) {
                return setPushToDmsStatus(PushToDmsStatus.Sending);
            }

            if (offerHasBeenSaved === false) {
                setTimeout(onFailed, 500);
                return;
            }

            if (offerHasBeenSaved === true && dmsIntegrationToggle === DMS_PLUS) {
                setTimeout(onSaved, 500);
                return;
            }
        }
    };

    const handlePushToDmsStatusChange = () => {
        if (show || (isEnableOptionalPbcUi && !returnedSuccessfulCdmsResponse)) {
            if (isEnableOptionalPbcUi) {
                closeLoader();
            }
            if (cdmsRequestFailure) {
                setTimeout(onFailed, 500);
                return;
            } else {
                if (hasCdmsResponseData) {
                    if (returnedSuccessfulCdmsResponse) {
                        setTimeout(onSaved, 500);
                        return;
                    }
                    setTimeout(onFailed, 500);
                    return;
                }
            }
        }
    };

    const handleConfirmClick = () => {
        if (isCDL3InMVEnabled) dispatch(deskingActionCreators.pushToDmsConfirmClicked());

        if (dmsIntegrationToggle === DMS_PLUS) dispatch(pushToDmsActionCreators.pushToDmsPlus());
        else {
            if (isEnableOptionalPbcUi) {
                onHide();
                showLoader();
                setIsPushToCdmsActive(true);
                setIsExtendedLoader(false);
            }
            dispatch(pushToDmsActionCreators.pushToDms());
        }
    };

    useEffect(handleShowChange, [show]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(handleOfferStatusChange, [offerIsBeingSaved, offerHasBeenSaved]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(handlePushToDmsStatusChange, [returnedSuccessfulCdmsResponse, hasCdmsResponseData, cdmsRequestFailure]);

    const header = (
        <Typography variant="h4" tag="h4">
            {dmsPlusRePush ? DIALOG_HEADER_RE_PUSH_INTERSTATE : DIALOG_HEADER_INTERSTATE}
        </Typography>
    );
    const footer = (
        <Grid container gap={1} justifyContent="flex-end" padding="16px 24px">
            {isShouldIncludeTradeIn ? (
                <Button
                    data-testid={`${prefix}-cancel-button`}
                    buttonStyle={isVinValidToPushToDms ? 'tertiary' : 'secondary'}
                    onClick={onCancel}
                    disabled={pushToDmsStatus === PushToDmsStatus.Sending}
                >
                    {isVinValidToPushToDms ? CANCEL_BUTTON_TEXT : CLOSE_BUTTON_TEXT}
                </Button>
            ) : (
                <Button
                    data-testid={`${prefix}-cancel-button`}
                    buttonStyle="tertiary"
                    onClick={onCancel}
                    disabled={pushToDmsStatus === PushToDmsStatus.Sending}
                >
                    {CANCEL_BUTTON_TEXT}
                </Button>
            )}
            {(!isShouldIncludeTradeIn || (isShouldIncludeTradeIn && isVinValidToPushToDms)) && (
                <Button
                    data-testid={`${prefix}-confirm-button`}
                    buttonStyle="primary"
                    onClick={handleConfirmClick}
                    disabled={pushToDmsStatus === PushToDmsStatus.Sending}
                >
                    {pushToDmsStatus === PushToDmsStatus.Sending ? LOADING_TEXT_INTERSTATE : confirmBtnText}
                </Button>
            )}
        </Grid>
    );

    const dialogDefaultBody = (
        <Typography variant="body-md" tag="p">
            {dmsPlusRePush ? (
                <>
                    <Typography variant="strong-md" tag="span">
                        Important Note:{' '}
                    </Typography>
                    <Typography variant="body-md" tag="span">
                        {IMPORTANT_DIALOG_PROMPT}
                    </Typography>
                </>
            ) : (
                DIALOG_PROMPT
            )}
        </Typography>
    );
    const dialogErrorBody = (
        <Grid paddingX={3}>
            <Alert data-testid="dms-error-modal" type="error">
                <Typography variant="h6" tag="h6">
                    {PUSH_ERROR_DIALOG_HEADER_INTERSTATE}
                </Typography>
                <Typography variant="body-sm" tag="span" data-testid="dms-error-detail-text">
                    {PUSH_VIN_ERROR_DIALOG_MESSAGE}
                </Typography>
            </Alert>
        </Grid>
    );

    return (
        <Modal
            size={isEnableOptionalPbcUi && dmsIntegrationToggle === CDMS ? 'large' : 'small'}
            data-testid={pushToDmsStatus === PushToDmsStatus.Sending ? `${prefix}-loading-container` : prefix}
            show={show}
            header={header}
            footer={{
                footerComponent: footer
            }}
            onHide={onHide}
        >
            {isShouldIncludeTradeIn ? (isVinValidToPushToDms ? dialogDefaultBody : dialogErrorBody) : dialogDefaultBody}
        </Modal>
    );
};

export default ConfirmPushToDmsDialog;
