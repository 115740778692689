import { ADDITIONAL_QUERY_PARAMS_WHITE_LIST } from '@makemydeal/dr-shared-components';
import { restApiCaller } from '@makemydeal/dr-shared-network';
import { apiConfigHelper, locHrefUtil, MANAGER_VIEW_APP_PREFIX, offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { FetchAllTogglesResultItem } from '@makemydeal/dr-feature-toggles-client';

export type FeatureToggles = {
    [name: string]: boolean;
};

const toggleListToObject = (toggles: string[], value: boolean, toggleObject?: FeatureToggles) => {
    let result = toggleObject || {};
    return toggles.reduce((result, key) => {
        if (key) {
            result[key] = value;
        }
        return result;
    }, result);
};

export const getToggleOverrides = () => {
    offerSessionUtils.parseAndWriteAdditionalToggles(window.location.href, { whitelist: ADDITIONAL_QUERY_PARAMS_WHITE_LIST });

    const { enableToggles: enableTogglesString, disableToggles: disableTogglesString } = offerSessionUtils.getAdditionalToggles();
    const enableToggles = (enableTogglesString || '').split(',');
    const disableToggles = (disableTogglesString || '').split(',');

    let result = toggleListToObject(enableToggles, true);
    return toggleListToObject(disableToggles, false, result);
};

export const getFeatureToggles = async (): Promise<FeatureToggles> => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, 'toggleData');

    const response = await restApiCaller.getResource<any>(bffApiUrlResult.url);

    return response.response.data.items.reduce((toggles: FeatureToggles, featureToggle: FetchAllTogglesResultItem) => {
        toggles[featureToggle.name] = featureToggle.enabled;

        return toggles;
    }, {});
};

export const getABPencilToggle = async () => {
    try {
        const toggleOverrides = getToggleOverrides();

        const value = toggleOverrides.enableABPencilUIModelMV;
        if (value === undefined) {
            const toggles = await getFeatureToggles();
            return toggles.enableABPencilUIModelMV || false;
        }
        return value;
    } catch (error) {
        if (window.newrelic) {
            let nrError: Error;
            if (error instanceof Error) {
                nrError = error;
            } else if (typeof error === 'string') {
                nrError = new Error(`ABPencilToggle Error: ${error}`);
            } else {
                nrError = new Error('Unknown ABPencilToggle Error');
            }
            newrelic.noticeError(nrError);
            newrelic.addPageAction('Failed to load ABPencil UIModel toggle', { error: nrError.message });
        }
    }
    return false;
};
