// externals
import { Reducer } from 'redux';

// libraries
import type { ActivityMilestoneState } from '@makemydeal/dr-dash-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';

// consts/enums
import { UPDATE_DEAL_PROGRESS_MILESTONES } from '../actionTypes/dpmActionTypes';
import { SOCKET_EVENT_RECEIVED } from '../actionTypes/socketActionTypes';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export type ActivityMilestoneReducer = Reducer<ActivityMilestoneState>;

export const reducer: ActivityMilestoneReducer = (state: Partial<ActivityMilestoneState> = {}, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...action.payload.activityMilestone
            };
        }
        case UPDATE_DEAL_PROGRESS_MILESTONES: {
            return { ...state, ...action.payload.updatedMilestoneData };
        }
        case SOCKET_EVENT_RECEIVED: {
            if (!action.payload.data?.dealProgressMilestones) {
                return state;
            }

            return {
                ...state,
                ...action.payload.data.dealProgressMilestones
            };
        }
        default: {
            return state;
        }
    }
};
