// externals
// externals
import React from 'react';

// libraries
import { Root } from '@makemydeal/dr-activities-programs';

// consts/enums
import { ROUTE_ROOT } from '../constants';

const ProgramsActivity = () => {
    return <Root baseRoute={ROUTE_ROOT} />;
};

export default ProgramsActivity;
