// externals
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// libraries
import { Button } from '@interstate/components/Button';
import { ArrowTopRightOnSquareIcon } from '@interstate/components/Icons';
import { configSelectors, navigationHooks, offerSelectors, RouteValues } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';
import { HostEnvironment, MANAGER_VIEW_APP_PREFIX, hostUrlHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';

// styles
import {
    ButtonContainer,
    CreditAndFinanceTitle,
    CreditAndFinanceWrapper,
    CreditBureauContainer,
    DeepLinkDealertrack,
    DeepLinkDealertrackContainer,
    IconAndWidgetWrapper,
    LenderDecisionContainer
} from './CreditBureau.style';

// components
import LenderDecisionInfo from './LenderDecisionInfo';

export const CreditBureau = () => {
    const toggleEnableLenderDecisionsInCreditBureau = useSelector(featureToggleSelectors.isEnableLenderDecisionsInCreditBureau);
    const toggleFsDealRefId = useSelector(featureToggleSelectors.isToggleFsDealRefIdEnabled);
    const creditProviderIsRouteOne = useSelector(dealerSelectors.isCreditProviderRouteOne);
    const isRouteOneCreditBureauHidden = useSelector(featureToggleSelectors.enableHideRouteOneCreditBureau);
    const enableCreditBureauAndDecisionPBCs: boolean = useSelector(featureToggleSelectors.enableCreditBureauAndDecisionPBCs);
    const hideCreditBureauComponent = creditProviderIsRouteOne && isRouteOneCreditBureauHidden;
    const dealRefId = useSelector(offerSelectors.getDealRefId);
    const configUrl = useSelector(configSelectors.getDealertrackUrl);
    const environment = hostUrlHelper.getHostUrlEnvironment(MANAGER_VIEW_APP_PREFIX, locHrefUtil.getLocHref());
    const env = useMemo(() => (environment === HostEnvironment.Prod ? 'prod' : 'uat'), [environment]);

    const parseUnifiDeepLinkFromConfigUrl = (configUrl: any) => {
        if (configUrl.length > 0) {
            const fsProvider = toggleFsDealRefId ? 'FD' : 'DRS';
            configUrl = `${configUrl}/credit_bureau_details/${fsProvider}/${dealRefId}/`;
        }
        return configUrl;
    };

    const openUniFiDeepLinkUrl = () => {
        const url: string = parseUnifiDeepLinkFromConfigUrl(configUrl);
        window.open(url, '_blank');
    };

    const handleDoneButtonClick = navigationHooks.useNavigate(RouteValues.DASHBOARD);

    return (
        <>
            <CreditBureauContainer data-testid="credit-bureau-card">
                <CreditAndFinanceWrapper>
                    {!enableCreditBureauAndDecisionPBCs && (
                        <CreditAndFinanceTitle data-testid="credit-and-finance-title">Credit And Finance</CreditAndFinanceTitle>
                    )}
                    <IconAndWidgetWrapper data-testid="widget-wrapper">
                        {hideCreditBureauComponent === false && (
                            <DeepLinkDealertrackContainer>
                                <DeepLinkDealertrack onClick={openUniFiDeepLinkUrl} data-testid="anchorCreditBureau">
                                    <div>
                                        <ArrowTopRightOnSquareIcon />
                                    </div>
                                    <span>Open Dealertrack</span>
                                </DeepLinkDealertrack>
                            </DeepLinkDealertrackContainer>
                        )}
                        {hideCreditBureauComponent === false && (
                            <fni-credit-bureau-widget deal-ref-id={dealRefId} env={env} applicant="A" />
                        )}
                    </IconAndWidgetWrapper>

                    {(!enableCreditBureauAndDecisionPBCs || creditProviderIsRouteOne) &&
                        toggleEnableLenderDecisionsInCreditBureau && (
                            <LenderDecisionContainer data-testid="lender-decision-container">
                                <LenderDecisionInfo data-testid="lender-decision-layout" />
                            </LenderDecisionContainer>
                        )}
                </CreditAndFinanceWrapper>
            </CreditBureauContainer>
            {!enableCreditBureauAndDecisionPBCs && toggleEnableLenderDecisionsInCreditBureau && (
                <ButtonContainer>
                    <Button data-testid="back-to-dealCentral" id="btnDefault" onClick={handleDoneButtonClick}>
                        Done
                    </Button>
                </ButtonContainer>
            )}
        </>
    );
};
