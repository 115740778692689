// externals
import numeral from 'numeral';

// libraries
import { formatUtils } from '@makemydeal/dr-common-utils';

// consts/enums
import { NOT_AVAILABLE } from '../constants';

export const convertToNumber = (dollars: string): number => Number(dollars.replace(/[^0-9.]/g, ''));

export const isNaNish = (text: string): boolean => /^[\D]+$/.test(text) || text.trim() === ''; // Test for only non-numerics

export const formatPayment = (payment: number) => {
    if (payment) {
        return `${formatUtils.formatDollars(payment)}/mo*`;
    }
    return NOT_AVAILABLE;
};

export const formatMileageCharge = (amount: number): string => {
    return numeral(amount).format('0,0.0[00]');
};

export const formatAprRate = (amount: number): string => {
    return numeral(amount).format('0,0.000');
};
export const formatMoneyFactor = (amount: number): string => {
    return numeral(amount).format('0,0.00000');
};

export const titleizePascalString = (source: string): string =>
    source.replace(/^[a-z]/, (c: string): string => c.toUpperCase()).replace(/([a-z])([A-Z])/g, '$1 $2');

export const NON_BREAKING_SPACE = '\u00a0';

export const isAlphaNumericWithSpaces = (str: string) => /^[a-zA-Z0-9 ]+$/.test(str);
