// externals
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import type { PushToDmsState } from '@makemydeal/dr-dash-types';

// consts/enums
import {
    PUSH_TO_DMS,
    PUSH_TO_DMS_SUCCESS,
    PUSH_TO_DMS_ERROR,
    PUSH_TO_DMS_PLUS,
    PUSH_TO_DMS_WS_EVENT,
    PUSH_TO_CDMS_RESET_ERROR
} from '../actionTypes/pushToDmsActionTypes';
import { COX_DMS_PLUS, PUSH_TO_DMS_FLOW } from '../constants';
import { OFFER_SAVE_SUCCESS, OFFER_SAVE_FAILURE } from '../actionTypes/offerActionTypes';

export const pushToDmsInitialState: PushToDmsState = {
    transferToDMS: '',
    responseStatusCode: 500,
    pushToDmsWsEvent: {} as PushToDmsState['pushToDmsWsEvent']
};

export type PushToDmsReducer = Reducer<PushToDmsState>;

export const reducer: PushToDmsReducer = (state: PushToDmsState = pushToDmsInitialState, action: AnyFSA) => {
    return produce(state, (draft: Draft<PushToDmsState>) => {
        switch (action?.type) {
            case PUSH_TO_DMS:
                draft = { ...pushToDmsInitialState, lastSucceedDmsDealId: draft.lastSucceedDmsDealId };
                break;

            case OFFER_SAVE_FAILURE:
            case PUSH_TO_DMS_ERROR:
                draft.error = action.payload
                    ? { errorDetails: action.payload.errorDetails, statusCode: action.payload.statusCode }
                    : { statusCode: 500 };
                break;

            case PUSH_TO_DMS_PLUS: {
                draft.transferToDMS = COX_DMS_PLUS;
                break;
            }

            case OFFER_SAVE_SUCCESS: {
                const offerSaveSource = action.meta.source;
                if (offerSaveSource === PUSH_TO_DMS_FLOW) {
                    const { dealXgId, dealXgVersion } = action.payload;
                    draft.dealXgId = dealXgId;
                    draft.dealXgVersion = dealXgVersion;
                }
                break;
            }

            case PUSH_TO_DMS_SUCCESS:
                draft.responseStatusCode = action.payload.data.status_code;
                draft.cdmsResponseData = action.payload.data.message;
                draft.cdmsStorefrontErrorMessage = action.payload.data.response?.message?.error;
                if (!draft.cdmsStorefrontErrorMessage && !action.payload.ok)
                    draft.cdmsStorefrontErrorMessage = action.payload.data.message;
                break;

            case PUSH_TO_DMS_WS_EVENT:
                draft.pushToDmsWsEvent = action.payload?.data || {};
                if (action.payload?.data?.payload?.dms_deal_id) {
                    draft.lastSucceedDmsDealId = action.payload.data.payload.dms_deal_id;
                    draft.lastSucceedDmsTime = new Date();
                }
                break;
            case PUSH_TO_CDMS_RESET_ERROR:
                draft.cdmsStorefrontErrorMessage = '';
                draft.cdmsResponseData = undefined;
                break;

            default:
                break;
        }
    });
};
