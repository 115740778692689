import { IVehicle } from '@makemydeal/dr-platform-types';
import { useMemo } from 'react';

const isValidVehicle = (vehicle: IVehicle, isCustomVehicle = false): boolean => {
    const sellingPrice = vehicle.listedPrice;
    const msrpPrice = vehicle.retailPrice;
    const mileage = vehicle.mileage;

    const isNewVehicle = vehicle.condition === 'new';
    const minMileageValue = isNewVehicle ? 0 : 1;

    const hasValidSellingPrice = typeof sellingPrice === 'number' && sellingPrice > 0;
    const hasValidMSRPPrice = typeof msrpPrice === 'number' && msrpPrice > 0;
    const hasValidMileage = typeof mileage === 'number' && mileage >= minMileageValue;

    if (isCustomVehicle) return !!hasValidSellingPrice && !!hasValidMSRPPrice && !!hasValidMileage;

    return hasValidSellingPrice && hasValidMileage;
};

export const useValidateVehicle = (vehicle: IVehicle): boolean | undefined => {
    return useMemo(() => {
        const { vin, stockNumber, chromeStyleId } = vehicle;

        if (stockNumber && vin) {
            return isValidVehicle(vehicle);
        }
        if (chromeStyleId) {
            return isValidVehicle(vehicle, true);
        }

        return undefined;
    }, [vehicle]);
};
