import { useEffect } from 'react';
import { CreditDecision } from './creditDecision/CreditDecision';
import { offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';

const CreditDecisionActivity = () => {
    useEffect(() => {
        const environment = offerSessionUtils.getEnvironmentType() === 'prod' ? '' : '-np';
        const scriptAlpha = document.createElement('script');
        scriptAlpha.type = 'module';
        scriptAlpha.id = 'fni-cd-module';
        scriptAlpha.src = `https://fni-static${environment}.dealertrack.com/apps/fni-credit-decision-widget/versions/latest/fni-credit-decision.esm.js`;
        document.body.appendChild(scriptAlpha);
        const scriptBeta = document.createElement('script');
        scriptBeta.setAttribute('nomodule', '');
        scriptBeta.id = 'fni-cd-js';
        document.body.appendChild(scriptBeta);
        scriptBeta.src = `https://fni-static${environment}.dealertrack.com/apps/fni-credit-decision-widget/versions/latest/fni-credit-decision.js`;
        const linkElement = document.createElement('link');
        linkElement.href = 'https://fonts.googleapis.com/css?family=Roboto+Flex';
        linkElement.rel = 'stylesheet';
        linkElement.type = 'text/css';
        document.body.appendChild(linkElement);
        return () => {
            const cbScripts = document.querySelectorAll('script[id*="fni-cd-"]');
            cbScripts.forEach((elem) => elem.remove());
        };
    }, []);

    return <CreditDecision />;
};

export default CreditDecisionActivity;
