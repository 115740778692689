import { DealHistory, StateTree } from '@makemydeal/dr-dash-types';
import { createSelector } from 'reselect';

import { getCurrentOfferType, getDiscount } from './offerRedux';
import {
    getAmountFinanced,
    getDownPayment,
    getRetailPriceOverrideWithFallback,
    getSelectedTermMonths,
    getSellingPrice
} from './offerSelectors';

export const selectEffectiveOfferType = createSelector(
    getCurrentOfferType,
    (_state: StateTree, deal: DealHistory | undefined) => deal,
    (stateOffetType, deal) => (deal ? getCurrentOfferType(deal as unknown as StateTree) : stateOffetType)
);

export const getEffectiveSelectedTermMonths = createSelector(
    getSelectedTermMonths,
    (_state: StateTree, deal: DealHistory | undefined) => deal,
    (stateSelectedTerm, deal) => (deal ? getSelectedTermMonths(deal as unknown as StateTree) : stateSelectedTerm)
);

export const getEffectiveDiscount = createSelector(
    (state: StateTree) => getDiscount(state),
    (_state: StateTree, deal: DealHistory | undefined) => deal,
    (offerDiscount, deal) => (deal ? getDiscount(deal as unknown as StateTree) : offerDiscount)
);

export const selectEffectiveDownPayment = createSelector(
    getDownPayment,
    (_state: StateTree, deal: DealHistory | undefined) => deal,
    (stateDownPayment, deal) => (deal ? getDownPayment(deal as unknown as StateTree) : stateDownPayment)
);

export const selectEffectiveAmountFinanced = createSelector(
    getAmountFinanced,
    (_state: StateTree, deal: DealHistory | undefined) => deal,
    (stateAmountFinanced, deal) => (deal ? getAmountFinanced(deal as unknown as StateTree) : stateAmountFinanced)
);

export const selectEffectiveRetailPrice = createSelector(
    getRetailPriceOverrideWithFallback,
    (_state: StateTree, deal: DealHistory | undefined) => deal,
    (stateRetailPrice, deal) => (deal ? getRetailPriceOverrideWithFallback(deal as unknown as StateTree) : stateRetailPrice)
);

export const getEffectiveSellingPrice = createSelector(
    getSellingPrice,
    (_state: StateTree, deal: DealHistory | undefined) => deal,
    (stateSellingPrice, deal) => (deal ? getSellingPrice(deal as unknown as StateTree) : stateSellingPrice)
);
