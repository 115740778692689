import styled from 'styled-components';

import { Typography } from '@interstate/components/Typography';
import { AccordionDetails } from '@interstate/components/AccordionGroup';
import { TableCell } from '@interstate/components/SimpleTable';

export const StyledTableCell = styled.div<{ textAlign?: string }>`
    min-width: 3.125rem;
    text-align: ${(props) => props.textAlign || 'left'};
`;

export const StyledTableCellWithoutBorder = styled(TableCell)`
    border-bottom-style: unset !important;
    border-bottom-width: 0 !important;
    border-bottom-color: unset !important;
`;

export const StyledSpan = styled.span<{ paddingLeft: number }>`
    padding-left: ${(props) => props.paddingLeft}rem;
`;

export const TypoRightAligned = styled(Typography)`
    text-align: right;
`;

export const TaxDetailsContainer = styled(AccordionDetails)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const TaxOverrideContainer = styled.div`
    margin-top: auto;
`;
