// externals
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { ACTIVITY_NAME_VEHICLE_PROTECTION } from '@makemydeal/dr-dpm-client';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { Action } from '@interstate/components/Action';
import {
    dealXgDetailsSelectors,
    vehicleProtectionSelectors,
    vehicleProtectionActionCreators,
    calculationDetailsSelectors,
    pushToDarwinActionCreators
} from '@makemydeal/dr-dash-store';
import { featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';
import DealActivityCard from '../DealActivityCard.interstate';

const name = 'vppActivityVehicleProtection';

const VppActivityVehicleProtectionCard = () => {
    const dispatch = useDispatch();

    const completed = useSelector(vehicleProtectionSelectors.getVppSelectedProducts).length > 0;
    const { totalProductPrice } = useSelector(vehicleProtectionSelectors.getVppSummary);
    const isFieEngine = useSelector(calculationDetailsSelectors.isFieCalculationEngineService);
    const isDarwinEnabled = useSelector(dealerSelectors.isDarwinEnabled);
    const enablePushToDarwin = useSelector(featureToggleSelectors.isPushToDarwinEnabled) && isDarwinEnabled;

    const cardValue = formatDollarsAndCents(totalProductPrice);
    const vehicleProtectionDpmState = useSelector((state: StateTree) =>
        dealXgDetailsSelectors.getDpmStateByActivity(state, ACTIVITY_NAME_VEHICLE_PROTECTION)
    );

    if (!isFieEngine) {
        return null;
    }

    const redirectToFullVppPage = () => {
        dispatch(vehicleProtectionActionCreators.redirectToVehicleProtectionPage());
    };

    const handleOpenInDarwin = () => {
        dispatch(pushToDarwinActionCreators.openInDarwin());
    };

    return (
        <DealActivityCard
            dpmState={vehicleProtectionDpmState}
            name={name}
            cardValue={cardValue}
            completed={completed}
            showCardAnchor={completed}
            action={redirectToFullVppPage}
            additionalAnchor={
                enablePushToDarwin && (
                    <Action
                        size="sm"
                        onClick={handleOpenInDarwin}
                        data-testid="vpp-activity-vehicle-protection-open-in-darwin-action"
                    >
                        Open in Darwin
                    </Action>
                )
            }
        />
    );
};

export default VppActivityVehicleProtectionCard;
