// externals
import { Reducer } from 'redux';

// libraries
import { initActionTypes } from '@makemydeal/dr-shared-store';

// interfaces/types
import type { LeadState } from '../types/leadReducerTypes';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export const leadInitialState: LeadState = {
    id: '',
    hash: '',
    timestamp: undefined,
    url: ''
};

export type LeadReducer = Reducer<LeadState>;

export const reducer: LeadReducer = (state: LeadState = leadInitialState, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...action.payload.offer.lead
            };
        }
        default: {
            return state;
        }
    }
};
