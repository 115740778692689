// interfaces/types
import { CatalogAccessory } from '@makemydeal/dr-dash-types';
import { AccessoryElement, LEASE, OfferType } from '@makemydeal/dr-platform-types';
import { AccessoriesAdapterObject, AccessoryItem } from '@makemydeal/dr-shared-types';

export const transformToManualEditAccessories = (accessories: any) => {
    return {
        ...accessories,
        originalAmount: accessories.originalAmount || accessories.amount,
        deleted: accessories.deleted ?? false
    };
};

export const calculateAccessoriesTotalAmount = (accessories: AccessoryElement[]) => {
    return accessories.reduce((currentTotal, accessory) => currentTotal + accessory.amount, 0);
};

const createCleanAccessoryItemFn =
    (offerType: OfferType) =>
    (accessoryItem: AccessoryItem): AccessoryItem => {
        const isLeaseDeal = offerType === LEASE;

        return {
            ...accessoryItem,
            residual: isLeaseDeal ? accessoryItem.residual : undefined,
            residualType: isLeaseDeal ? accessoryItem.residualType : undefined,
            upFront: isLeaseDeal ? accessoryItem.upFront : undefined,
            isPriceCapitalized: isLeaseDeal ? accessoryItem.isPriceCapitalized : undefined
        };
    };

export const toOfferTypeSpecificFormat = (
    accessoriesObject: AccessoriesAdapterObject,
    offerType: OfferType
): AccessoriesAdapterObject => {
    const { accessoryObjList, selectedAccessories } = accessoriesObject;

    const toOfferTypeSpecificAccessoryItem = createCleanAccessoryItemFn(offerType);

    const cleanAccessoryObjList = accessoryObjList?.map(toOfferTypeSpecificAccessoryItem);
    const cleanSelectedAccessories = selectedAccessories?.map(toOfferTypeSpecificAccessoryItem);

    return {
        ...accessoriesObject,
        accessoryObjList: cleanAccessoryObjList,
        selectedAccessories: cleanSelectedAccessories
    };
};
export const toSelectedAccessory = (catalogAccessory: CatalogAccessory): AccessoryElement => {
    return {
        amount: catalogAccessory.total_price,
        initialPrice: catalogAccessory.price,
        installPrice: catalogAccessory.install_price,
        code: catalogAccessory.part_number,
        deleted: false,
        name: catalogAccessory.name,
        originalAmount: 0,
        profit: 'frontEndProfit',
        residual: 0,
        residualType: 'Dollar',
        upFront: false,
        weOwe: false,
        cost: catalogAccessory.cost || catalogAccessory.total_price,
        isManualAccessory: false,
        providerName: 'PartSites'
    };
};
