import { isOriginalIncentive, ManualIncentiveEditEntry } from '@makemydeal/dr-dash-store';
import { INCENTIVE_TYPE_VALUES } from '@makemydeal/dr-dash-types';
import * as deskingConstants from '../../constants';

export const getIncentiveDisplayType = (data: any): string => {
    if (data.isStandardIncentive) {
        return 'Manufacturer';
    } else if (data.type === INCENTIVE_TYPE_VALUES.dealerCash.value) {
        return INCENTIVE_TYPE_VALUES.dealerCash.label;
    } else if (isOriginalIncentive(data)) {
        return 'Included';
    } else if (data.type === INCENTIVE_TYPE_VALUES.customerCash.value) {
        return INCENTIVE_TYPE_VALUES.customerCash.label;
    }

    // Returns in case when the rebate is not known for MV but came from other system
    return 'Included';
};

export const isIncentiveFormValid = (incentives: ManualIncentiveEditEntry[]): boolean =>
    incentives.every((incentive: ManualIncentiveEditEntry) => {
        return (incentive.type !== '' && incentive.name !== '') || incentive.deleted;
    });

export const getErrorMessage = (incentiveType: string | undefined): string => {
    if (incentiveType) return deskingConstants.REQUIRED;
    else return '';
};

export const createNewManualIncentiveEntry = (): ManualIncentiveEditEntry => ({
    program: '',
    code: undefined,
    manual: true,
    name: '',
    description: '',
    type: '',
    amount: 0,
    deleted: false,
    originalAmount: 0,
    isStandardIncentive: false
});
