import styled from 'styled-components';

import { BreakPoint } from '@makemydeal/dr-activities-common';
import { getMaxAppContainerWidth } from '@makemydeal/dr-shared-ui-utils';

export const StyledChangeVehicleActivity = styled.div<{ isNewAppWidthEnabled: boolean }>`
    .change-vehicle-container {
        max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
        position: relative;
        top: 40px;
        margin: auto;
        margin-bottom: 45px;
        padding: 0 1rem;
        padding-bottom: 90px;

        @media (max-width: ${BreakPoint.MEDIUM}) {
            width: 100%;
            padding: 0px 16px 90px;
        }

        .change-vehicle-footer {
            background: ${({ theme }) => theme.tokens.BaseColorWhite};
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .change-vehicle-footer-button-container {
            max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
            margin: 0 auto;
        }
    }
`;
