import { CheckBox, CheckBoxEventValue } from '@interstate/components/CheckBox';
import { TARGET_OUT_OF_POCKET } from '../../constants';
import { kebabCase } from '@makemydeal/dr-common-utils';
import { InterstateOnChangeCallback } from '@interstate/components/InterstateEvents';
import { offerReduxSelectors, offerReduxActionCreators, paymentInputActionCreators } from '@makemydeal/dr-dash-store';
import { useSelector, useDispatch } from 'react-redux';

export const IsTargetOutOfPocket = () => {
    const dispatch = useDispatch();
    const isTargetOutOfPocket = useSelector(offerReduxSelectors.getIsTargetOutOfPocket);

    const handleChange: InterstateOnChangeCallback<CheckBoxEventValue> = (ev) => {
        const checked = ev.target.value.checked;

        if (checked) {
            dispatch(offerReduxActionCreators.updateIsTargetOutOfPocket(checked, false));
            dispatch(paymentInputActionCreators.updateDownPayment(0));
        } else {
            dispatch(offerReduxActionCreators.updateIsTargetOutOfPocket(checked, true));
        }
    };

    return (
        <CheckBox
            sx={{
                '& label': { display: 'flex', alignItems: 'center' },
                '& label > span:first-of-type': { top: 0 }
            }}
            label={TARGET_OUT_OF_POCKET}
            name={kebabCase(TARGET_OUT_OF_POCKET)}
            data-testid={kebabCase(TARGET_OUT_OF_POCKET)}
            onChange={handleChange}
            checked={isTargetOutOfPocket}
            value="1"
        />
    );
};

export default IsTargetOutOfPocket;
