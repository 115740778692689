// libraries
import { Typography } from '@interstate/components/Typography';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// styles
import { AccessoriesTotal } from '../../ManualAccessories.interstate.styles';

export type AccessoriesSummaryProps = {
    totalAmount: number;
};

export const AccessoriesSummary = ({ totalAmount }: AccessoriesSummaryProps) => {
    const totalAmountFormatted = formatDollarsAndCents(totalAmount);

    return (
        <AccessoriesTotal>
            <Typography variant="body-sm" tag="span">
                Total
            </Typography>
            <Typography data-testid={'accessories-total'} variant="strong-sm" tag="span">
                {totalAmountFormatted}
            </Typography>
        </AccessoriesTotal>
    );
};
