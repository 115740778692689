import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const VehicleInfoContainer = styled.div`
    text-transform: capitalize;
    flex-direction: column;
    @media (min-width: ${BreakPoint.PHONE}) {
        display: flex;
        gap: 0.25rem;
        flex-direction: row;
    }
`;

export const LinkContainer = styled.div`
    display: flex;
    gap: 1rem;
    span {
        white-space: nowrap; // Prevent Action label break the line
    }
`;

export const ShopperInfoContainer = styled.div`
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

export const VerticalDivider = styled.div`
    height: 12px;
    margin-left: 12px;
    margin-right: 12px;
    display: inline-block;
    border-left: 1px solid #959595;
    border-radius: 2px;
    @media (max-width: ${BreakPoint.PHONE}) {
        display: none;
    }
`;

export const CustomVehicleLabel = styled.span`
    @media (max-width: ${BreakPoint.PHONE}) {
        display: block;
    }
`;
