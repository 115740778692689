import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@interstate/components/Grid';
import { NumericInput } from '@interstate/components/NumericInput';
import { kebabCase } from '@makemydeal/dr-common-utils';

import { COST, MSRP, ODOMETER, SELLING_PRICE } from '../../constants';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { changeVehicleCreators, changeVehicleSelectors } from '../../store';

export const NotInStockVehicleData: React.FC = () => {
    const [msrp, setMsrp] = useState<string>('');
    const [sellingPrice, setSellingPrice] = useState<string>('');
    const [cost, setCost] = useState<string>('');
    const [odometer, setOdometer] = useState<string>('');
    const [msrpError, setMsrpError] = useState<string>('');
    const [sellingPriceError, setSellingPriceError] = useState<string>('');
    const [odometerError, setOdometerError] = useState<string>('');
    const shouldShowCost = useSelector(changeVehicleSelectors.shouldShowCost);
    const selectedVehicle = useSelector(changeVehicleSelectors.getSelectedVehicle);
    const isCarConditionNew = selectedVehicle.condition === 'new';

    const dispatch = useDispatch();

    const createOnBlurHandler =
        ({
            name,
            setValue,
            setError
        }: {
            name?: string;
            setValue: React.Dispatch<React.SetStateAction<string>>;
            setError?: React.Dispatch<React.SetStateAction<string>>;
        }) =>
        (e: InterstateOnChangeEvent<string>) => {
            const { value } = e.target;

            // should allow 0 mileage only when car condition is new
            const isOdometer = name?.toLowerCase() === 'odometer';
            const allowZeroOdometer = isCarConditionNew && isOdometer && !!value.length;

            if (name && setError && !allowZeroOdometer) {
                setError(!Number(value) ? `${name} is required` : '');
            }

            // reset error if odometer value is filled
            else if (name && setError && isOdometer && allowZeroOdometer) {
                setError('');
            }

            setTimeout(() => setValue(name !== ODOMETER ? (value ? `${Number(value).toFixed(2)}` : '') : value), 0);
        };

    useEffect(() => {
        const id = setTimeout(() => {
            const newValue = {
                msrp: msrp ? Number(msrp) : undefined,
                sellingPrice: sellingPrice ? Number(sellingPrice) : undefined,
                cost: cost ? Number(cost) : undefined,
                odometer: odometer ? +odometer : undefined
            };
            dispatch(changeVehicleCreators.updateCustomVehicle(newValue));
        }, 250);
        return () => {
            clearTimeout(id);
        };
    }, [msrp, sellingPrice, cost, odometer, dispatch]);

    return (
        <Grid
            container
            columnSpacing="8px"
            rowGap={1}
            sx={{
                '& label': { whiteSpace: 'nowrap !important' }
            }}
        >
            <Grid xs={5.5} md={3}>
                <NumericInput
                    placeholder="0.00"
                    name={kebabCase(MSRP)}
                    onChange={(e) => setMsrp(e.target.value)}
                    onBlur={createOnBlurHandler({ name: MSRP, setValue: setMsrp, setError: setMsrpError })}
                    value={msrp}
                    label={`${MSRP} (required)`}
                    data-testid={kebabCase(MSRP)}
                    allowDecimal={true}
                    decimalMaxLength={2}
                    decimalMinLength={2}
                    inputPrefix={'$'}
                    autoInsertCommas={true}
                    errorMessage={msrpError}
                    hasError={!!msrpError}
                />
            </Grid>
            <Grid xs={6.5} md={3.25}>
                <NumericInput
                    placeholder="0.00"
                    name={kebabCase(SELLING_PRICE)}
                    onChange={(e) => setSellingPrice(e.target.value)}
                    onBlur={createOnBlurHandler({
                        name: SELLING_PRICE,
                        setValue: setSellingPrice,
                        setError: setSellingPriceError
                    })}
                    value={sellingPrice}
                    label={`${SELLING_PRICE} (required)`}
                    data-testid={kebabCase(SELLING_PRICE)}
                    allowDecimal={true}
                    decimalMaxLength={2}
                    decimalMinLength={2}
                    inputPrefix={'$'}
                    autoInsertCommas={true}
                    errorMessage={sellingPriceError}
                    hasError={!!sellingPriceError}
                />
            </Grid>
            {shouldShowCost && (
                <Grid xs={5.5} md={2.75}>
                    <NumericInput
                        placeholder="0.00"
                        name={kebabCase(COST)}
                        onChange={(e) => setCost(e.target.value)}
                        onBlur={createOnBlurHandler({ setValue: setCost })}
                        value={cost}
                        label={COST}
                        data-testid={kebabCase(COST)}
                        allowDecimal={true}
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        inputPrefix={'$'}
                        autoInsertCommas={true}
                    />
                </Grid>
            )}
            <Grid xs={6.5} md={3}>
                <NumericInput
                    placeholder="0"
                    name={kebabCase(ODOMETER)}
                    onChange={(e) => setOdometer(e.target.value ? `${+e.target.value}` : '')}
                    onBlur={createOnBlurHandler({
                        name: ODOMETER,
                        setValue: setOdometer,
                        setError: setOdometerError
                    })}
                    value={odometer}
                    label={`${ODOMETER} (required)`}
                    data-testid={kebabCase(ODOMETER)}
                    allowDecimal={false}
                    autoInsertCommas={true}
                    minValue={isCarConditionNew ? 0 : 1}
                    errorMessage={odometerError}
                    hasError={!!odometerError}
                />
            </Grid>
        </Grid>
    );
};
