// externals
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// components
import { formatSingleCardViewPaymentDetails } from '@makemydeal/dr-dash-components';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';

import InterstateDealSummary from '../dealSummary/DealSummary.interstate';

import { DeskingCardLeftPanelContent } from './DeskingCard.style';
import { StyledPaymentTitle } from './DerskingCardTitle.style';
import { Typography } from '@interstate/components/Typography';

export const DeskingCardPaymentDetailsSidePanel = () => {
    const theme = useInterstateTheme();
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const monthlyPayment = useSelector(offerSelectors.getMonthlyPayment);

    const paymentDetailLabel = useMemo(() => {
        return formatSingleCardViewPaymentDetails(currentOfferType, monthlyPayment);
    }, [currentOfferType, monthlyPayment]);

    return (
        <>
            <StyledPaymentTitle theme={theme} data-testid="desking-card-payment-details" className={'single-card-view'}>
                <Typography data-testid="desking-card-payment-details-side-panel-title" variant={'h3'}>
                    Payment Details
                </Typography>
                <Typography data-testid="desking-card-payment-details-side-panel-detail-label" variant="h3">
                    {paymentDetailLabel}
                </Typography>
            </StyledPaymentTitle>

            <DeskingCardLeftPanelContent>
                <InterstateDealSummary />
            </DeskingCardLeftPanelContent>
        </>
    );
};
