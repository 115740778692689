import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { tradeInSelectors, tradeInActionCreators } from '@makemydeal/dr-dash-store';

import LienholderFormHeader from './LienholderFormHeader.interstate';
import LienholderSummary from './LienholderSummary.interstate';
import LienholderActionGroup from './LienholderActionGroup.interstate';
import LienholderFormInputs from './LienholderFormInputs.interstate';

import { LienholderFormData } from './types';
import { checkLienholderInfoAvailability, getLienholderFormData } from './utils';
import { StyledLienholderFormContainer } from './LienholderForm.interstate.style';

const LienholderForm = ({ isEditDisabled }: { isEditDisabled: boolean }) => {
    const dispatch = useDispatch();

    const isTradeInCompleted = useSelector(tradeInSelectors.isTradeInCompleted);
    const sourceLienholderData = useSelector(tradeInSelectors.getLienholderFull);

    const [lienholderData, setLienholderData] = useState<LienholderFormData>(getLienholderFormData(sourceLienholderData));

    const [isEditMode, setEditMode] = useState(false);
    const [disableApplyButton, setDisableApplyButton] = useState(true);

    const handleCancelButton = () => {
        setLienholderData(getLienholderFormData(sourceLienholderData));
        setEditMode(false);
    };

    const handleErrors = useCallback(
        (error: boolean) => {
            const hasNoChange = isEqual(getLienholderFormData(sourceLienholderData), lienholderData);
            setDisableApplyButton(hasNoChange || error);
        },
        [lienholderData, sourceLienholderData]
    );

    useEffect(() => {
        setLienholderData(getLienholderFormData(sourceLienholderData));
    }, [sourceLienholderData]);

    const handleApplyButton = () => {
        setEditMode(false);
        dispatch(
            tradeInActionCreators.updateTradeInLienholderFull({
                lienHolder: lienholderData.lienholder,
                lienHolderAccountNumber: lienholderData.lienholderAccountNumber,
                lienHolderAddress: lienholderData.lienholderAddress,
                goodThroughDate: lienholderData.payoffDueDate && lienholderData.payoffDueDate.toDateString(),
                perDiemInterest: lienholderData.perDiem
            })
        );
    };

    const isLienholderInfoAvailable = useMemo(() => checkLienholderInfoAvailability(sourceLienholderData), [sourceLienholderData]);

    return (
        <StyledLienholderFormContainer>
            <LienholderFormHeader
                isEditMode={isEditMode}
                isEditDisabled={isEditDisabled}
                isLienholderInfoAvailable={isLienholderInfoAvailable}
                toggleEditMode={(mode: boolean) => {
                    setEditMode(mode);
                }}
            />
            {!isEditMode && isLienholderInfoAvailable && <LienholderSummary data={sourceLienholderData} />}
            {isEditMode && (
                <>
                    <LienholderFormInputs
                        isTradeInCompleted={isTradeInCompleted}
                        lienholderData={lienholderData}
                        setLienholderData={setLienholderData}
                        handleError={handleErrors}
                    />
                    <LienholderActionGroup
                        disableApplyButton={disableApplyButton}
                        handleApply={handleApplyButton}
                        handleCancel={handleCancelButton}
                    />
                </>
            )}
        </StyledLienholderFormContainer>
    );
};

export default LienholderForm;
