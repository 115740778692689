import { CommonConsumer } from '@makemydeal/dr-platform-types';

export type CustomerSideBarRequiredFields = 'firstName' | 'lastName' | 'email' | 'postalCode';
export const CUSTOMER_REQUIRED_FIELDS_FOR_PBC: CustomerSideBarRequiredFields[] = ['firstName', 'lastName', 'email', 'postalCode'];
export const COMMON_CONSUMER_REQUIRED_FIELDS: (keyof Omit<CommonConsumer, 'alternateIds'>)[] = [
    'firstName',
    'lastName',
    'primaryEmail',
    'postalCode'
];
