// externals
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import Button from '@cx/ui/Button';
import VinInput from '@cx/ui/VinInput';

// selectors
import { tradeInSelectors } from '@makemydeal/dr-dash-store';

// consts
import { VIN } from '../../constants';

// actions
import { tradeInActionCreators } from '@makemydeal/dr-dash-store';
import { RSAAAction } from 'redux-api-middleware';

import { TradeMultipleVIN, TradeVinDecodeContainer } from './trade.style';
import { FetchVinDecodeResponse } from '../../types/TradeIn';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

interface TradeVinDecodeProps {
    isManualFormOpen: boolean;
}

const TradeVinDecode: React.FC<TradeVinDecodeProps> = ({ isManualFormOpen }) => {
    const [enableDecode, setEnableDecode] = React.useState(false);
    const [isValidVIN, setIsValidVin] = useState(false);

    const dispatch = useDispatch();
    const vin = useSelector(tradeInSelectors.getVin);
    const hasMultipleVin = useSelector(tradeInSelectors.hasMultipleVin);
    const multipleVinOptions = useSelector(tradeInSelectors.multipleVinOptions);

    useEffect(() => {
        setIsValidVin(vin ? vin.length === 17 : false);
    }, [vin]);

    useEffect(() => {
        setEnableDecode(isValidVIN);
    }, [isValidVIN]);

    const onVinChange = (cxEvent: React.ChangeEvent<HTMLInputElement>) => {
        const value = cxEvent.target.value.toUpperCase();
        const isValid = value.length === 17;

        dispatch(tradeInActionCreators.updateTradeInVin(value));
        setIsValidVin(isValid);
    };

    const onDecodeClick = () => {
        dispatch(tradeInActionCreators.vinDecodeRSAA(MANAGER_VIEW_APP_PREFIX, vin) as unknown as RSAAAction<any, unknown, unknown>);
    };

    const selectMultipleOption = (optionSelected: FetchVinDecodeResponse[0]) => {
        dispatch(tradeInActionCreators.selectMultipleVinOption(optionSelected));
    };

    return (
        <>
            {isManualFormOpen && (
                <TradeVinDecodeContainer id="trade-vin-decode">
                    <VinInput
                        appendChild={
                            <Button
                                disabled={!enableDecode}
                                htmlId="vin-decode-btn"
                                buttonStyle="secondary"
                                onClick={onDecodeClick}
                                data-testid="vin-decode-btn"
                            >
                                Decode
                            </Button>
                        }
                        data-testid="vin-decode-input"
                        htmlId="vin-decode-input"
                        label={VIN}
                        name="vin-decode-input"
                        onChange={onVinChange}
                        value={vin}
                        autoFocus
                    />
                    {hasMultipleVin && multipleVinOptions.length > 0 && (
                        <TradeMultipleVIN data-testid="multiple-vin-options-container">
                            {multipleVinOptions.map((option) => (
                                <p
                                    key={option.id}
                                    data-testid={`multiple-option-${option.id}`}
                                    onClick={() => selectMultipleOption(option)}
                                >
                                    {option.title}
                                </p>
                            ))}
                        </TradeMultipleVIN>
                    )}
                </TradeVinDecodeContainer>
            )}
        </>
    );
};

export default TradeVinDecode;
