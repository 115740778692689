import React, { FC } from 'react';
import { formatDollars } from '@makemydeal/dr-common-utils';
import { ManualIncentiveEntry } from '@makemydeal/dr-platform-types';
import { getIncentiveDisplayType } from './utils';
import { MobileIncentiveItems, MobileIncentiveItem } from './ViewRows.interstate.styles';

export type RowProps = { data: ManualIncentiveEntry; index?: number };

export const MobileRow: FC<RowProps> = ({ data, index }) => {
    const { amount, name, code, program } = data;
    return (
        <>
            <MobileIncentiveItems>
                <MobileIncentiveItem>
                    <div data-testid={`program-label-${index}`}>Program #</div>
                    <div data-testid={`program-value-${index}`}>{program}</div>
                </MobileIncentiveItem>
                <MobileIncentiveItem>
                    <div data-testid={`name-label-${index}`}>Name</div>
                    <div data-testid={`name-value-${index}`}>{name}</div>
                </MobileIncentiveItem>
                <MobileIncentiveItem>
                    <div data-testid={`code-label-${index}`}>Code</div>
                    <div data-testid={`code-value-${index}`}>{code}</div>
                </MobileIncentiveItem>
                <MobileIncentiveItem>
                    <div data-testid={`type-label-${index}`}>Type</div>
                    <div data-testid={`type-value-${index}`}>{getIncentiveDisplayType(data)}</div>
                </MobileIncentiveItem>
                <MobileIncentiveItem>
                    <div data-testid={`amount-label-${index}`}>Amount</div>
                    <div data-testid={`amount-value-${index}`}>{formatDollars(amount)}</div>
                </MobileIncentiveItem>
            </MobileIncentiveItems>
            <hr />
        </>
    );
};
