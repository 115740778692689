import styled from 'styled-components';

import { SlideOut } from '@interstate/components/SlideOut';

export const StyledVehicleInfoDrawer = styled(SlideOut)`
    .ids-slideout-close-button {
        cursor: pointer;
    }

    .ids-SlideOut-content {
        padding-bottom: 0;
    }
`;

export const VehicleInfoDrawerContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
