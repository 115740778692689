import { StyledAlertsContainer } from './GuidelineAlerts.style';
import { programsSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';
import { filterUniqueMessages } from '../utils/mapUtils';

export const GuidelineAlerts = () => {
    const allProgramMessages = useSelector(programsSelectors.getAllMessages);
    const alerts = filterUniqueMessages(allProgramMessages);

    if (!alerts.length) return null;

    return (
        <StyledAlertsContainer data-testid="guideline-alerts-container">
            {alerts.map(({ lenderCode, message }, index) => (
                <div key={`alert-${index}`} data-testid="lender-guideline-item">
                    <span className="guideline-alert-lender-code">{lenderCode} </span> {message}
                </div>
            ))}
        </StyledAlertsContainer>
    );
};
