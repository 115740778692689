// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

// consts/enums
import { DOC_UPLOAD_FETCH_REQUEST, DOC_UPLOAD_FETCH_FAILURE, DOC_UPLOAD_FETCH_SUCCESS } from '../actionTypes/docUploadActionTypes';

// utils
import { getFsDealRefId } from '../selectors/offerRedux';
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';

export const fetchDocUploadRSAA = (appPrefix: string, state: StateTree) => {
    const locHref = locHrefUtil.getLocHref();
    const fsDealRefId = getFsDealRefId(state);
    const referrer = 'CMD';

    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, '/docUploadV2');
    const leadRoutingOverrideSettingsEnabled = dealerSelectors.getLeadRoutingOverrideSettingsEnabled(state);
    const mmdDealerId = leadRoutingOverrideSettingsEnabled
        ? dealerSelectors.getLeadOverrideDealerId(state)
        : dealerSelectors.getDealerId(state);

    const endpoint = `${bffApiUrlResult.url}/dealerId/${mmdDealerId}/dealRefId/${fsDealRefId}/statuses`;
    return {
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-dr-referrer': referrer
            },
            types: [DOC_UPLOAD_FETCH_REQUEST, DOC_UPLOAD_FETCH_SUCCESS, DOC_UPLOAD_FETCH_FAILURE]
        }
    };
};
