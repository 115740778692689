// interfaces/types
import { StateTree, DealLifecycle, UpdateDealLifecycleStatus } from '@makemydeal/dr-dash-types';

export const getDealLifecycle = (state: StateTree): DealLifecycle => {
    const safeDefault: DealLifecycle = {
        updateDealLifecycleStatus: UpdateDealLifecycleStatus.Ready
    };
    return state.dealLifecycle || safeDefault;
};

export const getDealLifecycleStatus = (state: StateTree): string => {
    return state.dealLifecycle?.status || '';
};

export const getUpdateDealLifecycleStatus = (state: StateTree) => {
    const dealLifecycle = getDealLifecycle(state);
    return dealLifecycle.updateDealLifecycleStatus;
};
