import { dealHistoryActionCreators, dealHistorySelectors, quickCompareActionCreators } from '@makemydeal/dr-dash-store';
import { DealHistory } from '@makemydeal/dr-dash-types';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MakeCurrentModal from '../makeCurrentModal/MakeCurrentModal.interstate';
import QuickCompareWithInterstate from '../quickCompare/QuickCompare.interstate';
import { MakeCurrentButtonView } from '../makeCurrentModal/types';
import { Tooltip } from '@interstate/components/Tooltip';
import { CheckBox } from '@interstate/components/CheckBox';

type OfferCardActionsProps = {
    deal: DealHistory;
    isDealHistory?: boolean;
};

export const DISABLED_CHECKBOX_MESSAGE = "You've reached your max comparison, uncheck one of the other cards to continue";

export const OfferCardActions = ({ deal, isDealHistory }: OfferCardActionsProps) => {
    const dispatch = useDispatch();
    const selectedDeals = useSelector(dealHistorySelectors.getSelectedDealHistory);

    const { offer } = deal;
    const { dealXgVersion, offerId } = offer;

    const updateQuickCompareOffer = useCallback(() => {
        dispatch(quickCompareActionCreators.updateQuickCompareOffer(deal as DealHistory));
    }, [dispatch, deal]);

    const makeCurrentOffer = useCallback(() => {
        dispatch(dealHistoryActionCreators.makeCurrentDeal(dealXgVersion));
    }, [dispatch, dealXgVersion]);

    const dealCheckedChange = useCallback(
        (event: any) => {
            if (event.target.value.checked) {
                dispatch(dealHistoryActionCreators.dealHistoryChecked(deal));
                dispatch(dealHistoryActionCreators.dealHistorySelectedUpdate(offer.offerId as string));
            } else {
                dispatch(dealHistoryActionCreators.dealHistoryUnchecked(offer.offerId as string));
            }
        },
        [dispatch, deal, offer.offerId]
    );

    const isCheckboxDisabled = useMemo(() => {
        const isDealSelected = selectedDeals.find((selectedDeal) => selectedDeal.offer.offerId === offer.offerId);
        return !isDealSelected && selectedDeals.length === 2;
    }, [selectedDeals, offer.offerId]);

    const renderCheckbox = () => {
        const checkboxId = 'deal-compare-checkbox-' + offerId;
        return isCheckboxDisabled ? (
            <Tooltip
                id="tooltipLink"
                toolTipContent={DISABLED_CHECKBOX_MESSAGE}
                data-testid="disabled-offer-card-tooltip-interstate"
                size="large"
                position="bottom"
            >
                <div>
                    <CheckBox
                        id={checkboxId}
                        name="checkBoxList"
                        onChange={dealCheckedChange}
                        value="1"
                        label={'Compare'}
                        disabled
                    />
                </div>
            </Tooltip>
        ) : (
            <CheckBox id={checkboxId} name="checkBoxList" onChange={dealCheckedChange} value="1" label={'Compare'} />
        );
    };

    return (
        <>
            {!isDealHistory && (
                <>
                    <QuickCompareWithInterstate onCompareClick={updateQuickCompareOffer} />
                    <MakeCurrentModal
                        onMakeCurrentOffer={makeCurrentOffer}
                        makeCurrentButtonView={MakeCurrentButtonView.DealComparison}
                    />
                </>
            )}
            {isDealHistory && (
                <div data-testid="dr-dash-offer-card-compare" className="offer-card-compare-check-box">
                    {renderCheckbox()}
                </div>
            )}
        </>
    );
};
