import React, { useCallback, useMemo } from 'react';
import { Card, NotInStockVehicleDataContainer } from './BuildVehicleSearchResults.style';
import { Typography } from '@interstate/components/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

import { changeVehicleCreators, changeVehicleSelectors } from '../../store';
import { CatalogVehicle } from '../../types';

import { NotInStockVehicleData } from './NotInStockVehicleData';
import { useVehicleTitle } from '../../utils';

type NotInStockVehicleProps = {
    vehicle: CatalogVehicle;
    selectValues: {
        inventoryType: string;
        year: string;
        make: string;
        model: string;
        trim: string;
    };
    index: number;
};

const NotInStockVehicle: React.FC<NotInStockVehicleProps> = ({ vehicle, selectValues: { inventoryType }, index }) => {
    const dispatch = useDispatch();
    const selectedVehicle = useSelector(changeVehicleSelectors.getSelectedVehicle);
    const theme = useInterstateTheme();

    const vehicleCardTitle = useVehicleTitle(vehicle);

    const selectVehicle = useCallback(() => {
        if (vehicle !== selectedVehicle) {
            dispatch(changeVehicleCreators.newVehicleSelected(vehicle));
        }
    }, [dispatch, vehicle, selectedVehicle]);

    return (
        <Card
            className={vehicle === selectedVehicle ? 'active' : ''}
            onClick={selectVehicle}
            data-testid={`buildVehicle-${index}`}
            theme={theme}
        >
            <Typography variant="h5" tag="div" sx={{ mb: 2 }}>
                {inventoryType} {vehicleCardTitle}
            </Typography>
            <div>
                <Typography variant="label-md" color="base.color.gray.600" sx={{ whiteSpace: 'nowrap', paddingRight: '5px' }}>
                    Engine Description:
                </Typography>
                {vehicle.engine}
            </div>
            <div>
                <Typography variant="label-md" color="base.color.gray.600" sx={{ whiteSpace: 'nowrap', paddingRight: '5px' }}>
                    Drivetrain Description:
                </Typography>
                {vehicle.driveLine}
            </div>
            <div>
                <Typography variant="label-md" color="base.color.gray.600" sx={{ whiteSpace: 'nowrap', paddingRight: '5px' }}>
                    Transmission:
                </Typography>
                {vehicle.transmission}
            </div>
            {vehicle === selectedVehicle && (
                <NotInStockVehicleDataContainer>
                    <NotInStockVehicleData />
                </NotInStockVehicleDataContainer>
            )}
        </Card>
    );
};

export default NotInStockVehicle;
