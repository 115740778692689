import { FinanceInterstate, LeaseInterstate } from '@makemydeal/dr-dash-components';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { FINANCE, LEASE, OfferType } from '@makemydeal/dr-platform-types';
import { useSelector } from 'react-redux';

const CreditDecisionTerms = () => {
    const currentOfferType: OfferType = useSelector(offerReduxSelectors.getCurrentOfferType);

    if (currentOfferType === FINANCE) {
        return <FinanceInterstate />;
    }

    if (currentOfferType === LEASE) {
        return <LeaseInterstate />;
    }

    return null;
};

export default CreditDecisionTerms;
