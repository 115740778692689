import { Comparison } from '@makemydeal/dr-dash-bff-types';

/**
 * Iterator used to filter a list of Comparisons to get the active one
 * @param comparison
 * @returns
 */
export const byActiveStatus = (comparison: Comparison) => comparison.status === 'active';

/**
 * Rebuilds the order array based on the comparison object and successfully retrieved alternate scenario ids
 * @param comparison
 * @param retrievedNonPrimaryScenarioIds
 * @param primaryScopeId
 * @returns
 */
export const getOrderFromRetrievedScenarios = (
    comparison: Comparison,
    retrievedNonPrimaryScenarioIds: any[],
    primaryScopeId: string
): string[] => {
    const order: string[] = [];

    // Rebuild the order array correctly with retrieved versionIds instead of generated ids
    if (comparison.scenarios) {
        for (const scenario of comparison.scenarios) {
            // don't try to hydrate a scope from a scenarioId we did not successfully retrieve
            if (!scenario.isPrimary && retrievedNonPrimaryScenarioIds.includes(scenario.id)) {
                order.push(scenario.id);
            } else {
                order.push(primaryScopeId);
            }
        }
    }

    return order;
};
