// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CREDIT_DECISION_LENDER_NAME, CREDIT_DECISION_LENDER_NAME_FIELD } from '../../constants';
import { kebabCase } from 'lodash';
import { lenderListSelectors, offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { SelectInput } from '@interstate/components/SelectInput';

const CreditDecisionLenderNameField = () => {
    const dispatch = useDispatch();
    const toggled = useSelector(offerReduxSelectors.getCreditDecisionEnabled);
    const lender = useSelector(offerReduxSelectors.getCreditDecisionLender);
    const lenderDecisions = useSelector(lenderListSelectors.getLenderList);

    const options = lenderDecisions
        .sort((a, b) => a.lenderName.localeCompare(b.lenderName))
        .map((t) => ({
            value: JSON.stringify({ id: t.lenderId, name: t.lenderName }),
            label: t.lenderName
        }));

    const selectedValue = lender ? JSON.stringify({ id: lender.id, name: lender.name }) : '';

    return (
        <SelectInput
            id={kebabCase(CREDIT_DECISION_LENDER_NAME_FIELD)}
            data-testid={kebabCase(CREDIT_DECISION_LENDER_NAME_FIELD)}
            label={CREDIT_DECISION_LENDER_NAME}
            name={CREDIT_DECISION_LENDER_NAME}
            onChange={(event) => {
                let parsedValue = undefined;

                try {
                    parsedValue = JSON.parse(event.target.value as string);
                } catch (err) {
                    // Do nothing, fallback to initial value
                }

                const action = offerReduxActionCreators.updateCreditDecisionLender(parsedValue);
                dispatch(action);
            }}
            value={selectedValue}
            disabled={!toggled || !lenderDecisions.length}
            options={options}
            displayDeselectOption={false}
        />
    );
};

export default CreditDecisionLenderNameField;
