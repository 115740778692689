import styled from 'styled-components';

export const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    min-height: 36px;
    width: 100%;

    & #interstitial-overlay-root {
        background-color: transparent;
        min-width: 32px;
        min-height: 36px;
    }
    & #interstitial-content-root {
        min-width: 32px;
        box-shadow: none;
    }
`;

export const UpdateOfferErrorFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 20px 24px 24px;
`;
