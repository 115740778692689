// externals
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import { RSAA } from 'redux-api-middleware';
import {
    TRADE_IN_YEARS_REQUEST,
    TRADE_IN_YEARS_SUCCESS,
    TRADE_IN_YEARS_FAILURE,
    TRADE_IN_MAKES_REQUEST,
    TRADE_IN_MAKES_SUCCESS,
    TRADE_IN_MAKES_FAILURE,
    TRADE_IN_MODELS_REQUEST,
    TRADE_IN_MODELS_SUCCESS,
    TRADE_IN_MODELS_FAILURE,
    TRADE_IN_TRIMS_REQUEST,
    TRADE_IN_TRIMS_SUCCESS,
    TRADE_IN_TRIMS_FAILURE,
    TRADE_IN_VEHICLES_REQUEST,
    TRADE_IN_VEHICLES_SUCCESS,
    TRADE_IN_VEHICLES_FAILURE
} from '../actionTypes/tradeInComponentActionTypes';

export const fetchTradeInComponentYears = (appPrefix: string) => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, '/trade');
    const endpoint = bffApiUrlResult.url + '/lookup/years?vehicleClass=used';

    return {
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [TRADE_IN_YEARS_REQUEST, TRADE_IN_YEARS_SUCCESS, TRADE_IN_YEARS_FAILURE]
        }
    };
};

export const fetchTradeInComponentMakes = (appPrefix: string, year: string) => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, '/trade');
    const endpoint = bffApiUrlResult.url + `/lookup/makes?year=${year}&vehicleClass=used`;

    return {
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [TRADE_IN_MAKES_REQUEST, TRADE_IN_MAKES_SUCCESS, TRADE_IN_MAKES_FAILURE]
        }
    };
};

export const fetchTradeInComponentModels = (appPrefix: string, year: string, id: string) => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, '/trade');
    const endpoint = bffApiUrlResult.url + `/lookup/models?year=${year}&makeid=${id}&vehicleClass=used`;

    return {
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [TRADE_IN_MODELS_REQUEST, TRADE_IN_MODELS_SUCCESS, TRADE_IN_MODELS_FAILURE]
        }
    };
};

export const fetchTradeInComponentTrims = (appPrefix: string, year: string, makeId: string, modelId: string) => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, '/trade');
    const endpoint = bffApiUrlResult.url + `/lookup/trims?year=${year}&makeid=${makeId}&modelid=${modelId}&vehicleClass=used`;

    return {
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [TRADE_IN_TRIMS_REQUEST, TRADE_IN_TRIMS_SUCCESS, TRADE_IN_TRIMS_FAILURE]
        }
    };
};

export const fetchTradeInComponentVehicle = (appPrefix: string, year: string, makeId: string, modelId: string, trimId: string) => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, '/trade');
    const endpoint = bffApiUrlResult.url + `/lookup/vehicles?year=${year}&makeid=${makeId}&modelid=${modelId}&trimid=${trimId}`;

    return {
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [TRADE_IN_VEHICLES_REQUEST, TRADE_IN_VEHICLES_SUCCESS, TRADE_IN_VEHICLES_FAILURE]
        }
    };
};
