/**
 * Convert string to number; Imported from: dash-components - utils/formatUtils.ts to prevent circular dependency
 * @param dollars value to convert
 * @returns number
 */
export const convertToNumber = (dollars: string | number | undefined) => {
    if (dollars === undefined) return 0;
    if (typeof dollars === 'number') return dollars;

    const v = dollars.replace(/[^0-9.]/g, '');
    return Number(v);
};
