// libraries
import { apiConfigHelper, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import type { StateTree } from '@makemydeal/dr-dash-types';

// actions
import { buildOfferSavePayloadObject } from '../actions/offerActionCreators';

// consts/enums
import { BuildPayloadPurpose } from '../types/offerPayloadUtilsEnums';
import { SavedOfferPayloadObjectComparison } from '../types/comparisonUtilsEnums';

// selectors
import { getInternalPrevOfferPayload } from '../selectors/internalPrevOfferSelectors';

// utils
import { compareOfferPayloadObjects } from '../utils/comparisonUtils';

export const showDeveloperOfferHasUnsavedChanges = (state: StateTree) => {
    const isInLocalDevMode = apiConfigHelper.isInLocalDevMode(MANAGER_VIEW_APP_PREFIX, locHrefUtil.getLocHref());
    if (!isInLocalDevMode) {
        return false;
    }
    return currentOfferHasUnsavedChanges(state);
};

export const currentOfferHasUnsavedChanges = (state: StateTree) => {
    const offerSavePayload = buildOfferSavePayloadObject(state, BuildPayloadPurpose.DeveloperDebugging);
    const lastOfferPayloadObject = getInternalPrevOfferPayload(state);
    const comparisonResult = compareOfferPayloadObjects(lastOfferPayloadObject, offerSavePayload);
    return comparisonResult.simpleResult === SavedOfferPayloadObjectComparison.Different;
};
