import styled from 'styled-components';

export const CoBuyerRemoveModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 20px 24px 24px;

    :first-child {
        margin-right: 5px;
    }
`;
