import styled from 'styled-components';

export const StyledLabel = styled.label`
    display: flex;
    align-items: end;

    margin: 1px 0 7px;
    font-size: ${({ theme }) => theme.tokens.ComponentTextInputLabelFontSizeMedium};
    font-family: ${({ theme }) => theme.tokens.ComponentTextInputFontFamily};
    font-weight: ${({ theme }) => theme.tokens.ComponentTextInputFontWeight};
    color: ${({ theme }) => theme.tokens.ComponentTextInputLabelColor};
    line-height: 115%;
`;
