// externals
import styled from 'styled-components';

export const StyledVehicleImage = styled.img`
    display: block;
    height: auto;
    border: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.tokens.BaseColorGray100};
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    overflow-clip-margin: unset;
`;
