export const getOfferDetailsHref = (
    configUrl: string,
    fsDealRefIdEnabled: boolean,
    dealRefId: string,
    lenderId: string
): string => {
    const fsProvider = fsDealRefIdEnabled ? 'FD' : 'DRS';
    const link = `${configUrl}/detail/${fsProvider}/${dealRefId}/${lenderId}/`;
    return link;
};
