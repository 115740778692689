// consts/enums
import { HostEnvironment } from '../hostUrlHelper/hostUrlHelperEnums';

export const getEnvironmentName = (env: HostEnvironment): string => {
    switch (env) {
        case HostEnvironment.Local:
            return 'Local';
        case HostEnvironment.Dev:
            return 'Dev';
        case HostEnvironment.Dev2:
            return 'Dev2';
        case HostEnvironment.QA:
            return 'QA';
        case HostEnvironment.DarkProd:
            return 'DarkProd';
        case HostEnvironment.Prod:
            return 'Prod';
        case HostEnvironment.Unknown:
            return 'Unknown';
        case HostEnvironment.None:
            return 'None';
        default:
            return 'Invalid';
    }
};
