import { StateTree, VAutoItem } from '@makemydeal/dr-dash-types';

export const getVAutoState = (state: StateTree) => {
    return state.vAuto;
};

export const getVAutoItems = (state: StateTree) => {
    const items = getVAutoState(state)?.items as VAutoItem[];
    return items || [];
};

export const getCurrentAppraisalFromVin = (state: StateTree) => {
    const availableAppraisals = [...getVAutoItems(state)];
    availableAppraisals.sort((a, b) => {
        return new Date(b.updatedOn).getTime() - new Date(a.updatedOn).getTime();
    });
    return availableAppraisals[0];
};

export const getCurrentAppraisalId = (state: StateTree) => {
    const currentAppraisal = getCurrentAppraisalFromVin(state);
    return currentAppraisal?.id;
};
