import { ProgramQuote } from '@makemydeal/dr-dash-types';
import { ActionColumnBody } from './ActionColumnBody';

/**
 * Returns a ReactNode to render the action columbn for the Programs Table. This is not a standard fragement component function.
 * You can thank ANT Design - Table Column for that. - https://ant.design/components/table#column
 * @param _text unused
 * @param value Data record associated with the cell render of this column/row
 * @param index Row index of the data record
 * @returns
 */
export const ActionColumn = (_text: any, value: ProgramQuote, index: number) => {
    return <ActionColumnBody value={value} index={index} />;
};
