import { MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { ExclamationCircleIcon } from '@interstate/components/Icons/ExclamationCircleIcon';
import { programsSelectors } from '@makemydeal/dr-dash-store';
import { filterUniqueMessages } from '../utils/mapUtils';
import { StyledGuideLineAlertsLinkContainer, StyledProgramsIncentivesTitleContainer } from './ProgramsAndIncentives.style';
import { GuidelineAlerts } from './GuidelineAlerts';
import { useSlideoutDrawer } from '@makemydeal/dr-dash-components';
import { Typography } from '@interstate/components/Typography';

export const ProgramsAndIncentivesHeader = () => {
    const allProgramMessages = useSelector(programsSelectors.getAllMessages);
    const guideLineAlerts = filterUniqueMessages(allProgramMessages);
    const guideLineAlertsCount = guideLineAlerts.length;

    const { showContent } = useSlideoutDrawer();

    const handleClickShowAlerts: MouseEventHandler<HTMLAnchorElement> = () => {
        showContent(GuidelineAlerts, {
            header: 'Lender Guideline Alerts'
        });
    };

    return (
        <StyledProgramsIncentivesTitleContainer id="programs-incentives-title-container">
            <Typography variant="h1" sx={{ margin: '2rem 0 0' }}>
                Programs and Incentives
            </Typography>
            {guideLineAlertsCount > 0 && (
                <StyledGuideLineAlertsLinkContainer className="text-primary">
                    <ExclamationCircleIcon fontSize="1.1rem" />
                    <a id="guideline-alerts-link" data-testid="guideline-alerts-link" onClick={handleClickShowAlerts}>
                        Lender Guideline Alerts
                    </a>
                    <span>({guideLineAlertsCount})</span>
                </StyledGuideLineAlertsLinkContainer>
            )}
        </StyledProgramsIncentivesTitleContainer>
    );
};
