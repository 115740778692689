// externals
import { useSelector } from 'react-redux';

// types
import type { DealHistory, StateTree } from '@makemydeal/dr-dash-types';
import { QuickCompareOffer } from '../types/quickCompareOffer';

export const createDealRootSelector = (deal?: DealHistory | { offer?: QuickCompareOffer }) => {
    return <T,>(selector: (state: StateTree) => T) => useSelector((state: any) => selector(deal || state));
};
