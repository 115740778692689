import React from 'react';

// components
import { ActionProps, Action } from '@interstate/components/Action';
import { Modal as InterstateModalDialog } from '@interstate/components/Modal';
import { Typography } from '@interstate/components/Typography';
import { Button } from '@interstate/components/Button';
import { MakeCurrentButtonView } from './types';

export interface IMakeCurrentModalProps {
    onMakeCurrentOffer: { (): void };
    makeCurrentButtonView: MakeCurrentButtonView;
}

export const MakeCurrentModal: React.FC<IMakeCurrentModalProps> = ({
    onMakeCurrentOffer,
    makeCurrentButtonView
}: IMakeCurrentModalProps) => {
    const [showModal, setShowModal] = React.useState(false);

    const buttonClickHandlerCommonLogic = (onContinue?: () => void) => {
        if (onContinue) {
            onContinue();
        }
        setShowModal(!showModal);
    };

    const handleMakeCurrentButtonClick = () => buttonClickHandlerCommonLogic();
    const handleMakeCurrentContinueClick = () => buttonClickHandlerCommonLogic(onMakeCurrentOffer);
    const handleMakeCurrentCancelClick = () => buttonClickHandlerCommonLogic();

    const makeCurrentButton = () => {
        switch (makeCurrentButtonView) {
            case MakeCurrentButtonView.DealComparison: {
                return (
                    <Action onClick={handleMakeCurrentButtonClick} data-testid={MakeCurrentButtonView.DealComparison}>
                        <Typography variant="anchor-block-md" color="sem.color.surface.button.primary.rest">
                            Make Current
                        </Typography>
                    </Action>
                );
            }
            case MakeCurrentButtonView.DealHistory: {
                return (
                    <Button
                        data-testid={MakeCurrentButtonView.DealHistory}
                        id="btn-make-current"
                        size="medium"
                        buttonStyle="primary"
                        block={false}
                        onClick={handleMakeCurrentButtonClick}
                    >
                        Make Current
                    </Button>
                );
            }
            case MakeCurrentButtonView.QuickCompare: {
                return (
                    <Button
                        data-testid={MakeCurrentButtonView.QuickCompare}
                        id="btn-make-current"
                        size="medium"
                        buttonStyle="secondary"
                        block={true}
                        onClick={handleMakeCurrentButtonClick}
                        sx={{ fontSize: '16px' }}
                    >
                        Make Current
                    </Button>
                );
            }
        }
    };

    return (
        <>
            {makeCurrentButton()}
            {showModal && (
                <InterstateModalDialog
                    className="dr-dash-make-current-modal"
                    data-testid="dr-dash-make-current-modal"
                    onHide={handleMakeCurrentCancelClick}
                    show={showModal}
                    size="small"
                    footer={{
                        primary: {
                            label: 'Continue',
                            action: handleMakeCurrentContinueClick
                        },
                        options: [
                            {
                                label: 'Cancel',
                                action: handleMakeCurrentCancelClick
                            }
                        ]
                    }}
                >
                    <div className="dr-dash-make-current-modal-row">
                        <p className="dr-dash-make-current-modal-text">
                            This will overwrite your existing working offer. Do you want to continue?
                        </p>
                    </div>
                </InterstateModalDialog>
            )}
        </>
    );
};

export default MakeCurrentModal;
