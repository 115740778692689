// externals
import { Reducer } from 'redux';

// libraries
import type { TestDriveState } from '@makemydeal/dr-dash-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';

export type TestDriveTime = 'morning' | 'afternoon' | 'evening';

export const INITIAL_TEST_DRIVE_STATE: Partial<TestDriveState> = {};

export type TestDriveReducer = Reducer<TestDriveState>;

export const reducer: TestDriveReducer = (state: Partial<TestDriveState> = INITIAL_TEST_DRIVE_STATE, action: any) => {
    switch (action.type) {
        case initActionTypes.INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...(action.payload.testDrive || {})
            };
        }
        default: {
            return state;
        }
    }
};
