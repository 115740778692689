// externals
import { ImgHTMLAttributes, useCallback, useEffect, useState } from 'react';

// assets
import VehiclePlaceholderSvg from '../../assets/blankVehicle.svg';

interface VehicleImageProps extends ImgHTMLAttributes<HTMLImageElement> {
    'data-testid'?: string;
}

export const VehicleImage = ({ src, ...rest }: VehicleImageProps) => {
    const [isImageError, setIsImageError] = useState(false);

    /**
     * There will (possibly) be the place when the vehicle list is redesigned to
     * support CADS and (possibly) use an item index for
     * VehicleListItemInterstate's key field as there may be duplicates of vin,
     * which is currently used as a key. In this case the component won't be
     * unmounted between searches, but instead its props, including the image,
     * will be updated.
     */
    useEffect(() => setIsImageError(false), [src]);

    const onImageError = useCallback(() => {
        setIsImageError(true);
    }, []);

    const imageSrc = src && !isImageError ? src : VehiclePlaceholderSvg;
    const dataTestId = rest['data-testid'] || 'vehicle-image';

    return <img {...rest} src={imageSrc} onError={onImageError} data-testid={dataTestId} />;
};
