import {
    AUTH_SCOPE_BRIDGE_SIGN_IN,
    AUTH_SCOPE_CXM_BFF_INSIGHTS_READ,
    AUTH_SCOPE_OPEN_ID,
    AuthConfigCallback,
    OKTA_CONFIG_ISSUER_NON_PROD,
    OKTA_CONFIG_ISSUER_PROD,
    OktaAuthBaseConfig
} from '@makemydeal/dr-shared-components';
import { APP_SCOPE, CLIENT_ID, NON_PROD_CLIENT_ID, OKTA_CONFIG_ID_NON_PROD, OKTA_CONFIG_ID_PROD } from './constants';

const baseConfig: OktaAuthBaseConfig = {
    id: OKTA_CONFIG_ID_NON_PROD,
    issuer: OKTA_CONFIG_ISSUER_NON_PROD,
    clientId: NON_PROD_CLIENT_ID,
    scopes: [AUTH_SCOPE_BRIDGE_SIGN_IN, AUTH_SCOPE_OPEN_ID, APP_SCOPE, AUTH_SCOPE_CXM_BFF_INSIGHTS_READ],
    system: 'CSI'
};

const productionConfig: OktaAuthBaseConfig = {
    ...baseConfig,
    id: OKTA_CONFIG_ID_PROD,
    issuer: OKTA_CONFIG_ISSUER_PROD,
    clientId: CLIENT_ID
};

export const getAuthConfig: AuthConfigCallback = (isProduction: boolean) => {
    return isProduction ? productionConfig : baseConfig;
};
