// libraries
import { getAllRoutes } from '@makemydeal/dr-activities-vehicle-protection';
import { NavigationItemStatus } from '@makemydeal/dr-activities-shared';

// interfaces/types
import type { IFlowConfig } from '../../flowMasterInterfaces';

// components
import VehicleProtectionContainer from './components/VehicleProtectionContainer';

// consts/enums
import { APP_NAME, VEHICLE_PROTECTION_ROUTE_ROOT } from './constants';

export const allRoutes = getAllRoutes(VEHICLE_PROTECTION_ROUTE_ROOT);

// istanbul ignore next
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noopReducer = () => {};

/* this activity won't appear on the dr-activities-dashboard card list,
 * so including the bare minimum inputs to satisfy the interface
 */
export const vehicleProtectionConfiguration: IFlowConfig<any> = {
    name: APP_NAME,
    navCard: {
        label: 'Vehicle Protection',
        icon: 'handHoldingCar',
        iconClass: 'menu',
        cardId: 'dr-sp-vehicle-protection',
        title: 'Vehicle Protection',
        dashboardIcon: 'handHoldingCar'
    },
    showCardSelector: () => false,
    isCompleteSelector: () => false,
    routingComponent: VehicleProtectionContainer,
    defaultRoute: allRoutes.root,
    routes: [...Object.keys(allRoutes).map((routeName) => allRoutes[routeName])],
    middleware: null,
    reducer: noopReducer,
    isLocked: (_state: unknown) => false,
    dependsOnLeadFormCompletion: () => false,
    status: () => NavigationItemStatus.DEFAULT,
    isDisabled: () => false
};
