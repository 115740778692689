// externals
import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

// components
import { OfferHistoryCard as OfferHistoryCardInterstate } from '../offerHistoryCard/OfferHistoryCard.interstate';
import OfferCardInterstate from '../offerCard/OfferCard.interstate';
import SliderCarousel from '../common/SliderCarousel/SliderCarousel';

// libraries
import { Grid } from '@interstate/components/Grid';
import { Spinner } from '@interstate/components/Spinner';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// styles
import {
    DealPulseWrapper,
    DealScenariosSeparator,
    InterstateOfferCardsContainer,
    LoadingIndicatorContainer
} from './DealComparison.style';

// selectors
import { consumerInsightsSelectors, dealHistorySelectors } from '@makemydeal/dr-dash-store';

// utils
import DealPulseInterstate from '../dealPulse/DealPulseSection.interstate';
import { adjustOfferCardHeights } from '../utils/adjustOfferCardHeights';

export const DealComparisonInterstate = () => {
    const theme = useInterstateTheme();
    const isLoading = useSelector(dealHistorySelectors.getDealHistoryIsLoading);
    const dealerOffer = useSelector(dealHistorySelectors.getLatestDealerOffer);
    const shopperOffer = useSelector(dealHistorySelectors.getLatestShopperOffer);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const dealPulseAdminToggle = useSelector(dealerSelectors.getDealPulseToggle);
    const dealPulseData = useSelector(consumerInsightsSelectors.getConsumerInsights);
    const isDealPulseHasData = dealPulseData && Object.keys(dealPulseData).length > 0;
    const showDealPulse = isABPencilEnabled && dealPulseAdminToggle && isDealPulseHasData;
    const hasMultiScenarios = useSelector(featureToggleSelectors.enableMultiScenarioPersistence);

    useLayoutEffect(() => {
        if (isABPencilEnabled) {
            adjustOfferCardHeights('#deal-comparison-offer-cards-carousel-scroller');
        }
    }, [isABPencilEnabled, isLoading, dealerOffer, shopperOffer, dealPulseData]);

    const showOfferHistory = () => {
        return (
            <Grid
                container
                data-testid="deal-comparison-container"
                alignItems="stretch"
                flexWrap="wrap"
                margin={0}
                width="100%"
                rowGap={2}
            >
                <InterstateOfferCardsContainer container columnGap={2} alignItems="flex-start" flexWrap="nowrap">
                    <SliderCarousel id="deal-comparison-offer-cards-carousel">
                        {showDealPulse && (
                            <>
                                <DealPulseWrapper>
                                    <DealPulseInterstate />
                                </DealPulseWrapper>
                                <DealScenariosSeparator id="deal-pulse-separator" theme={theme}></DealScenariosSeparator>
                            </>
                        )}
                        <OfferCardInterstate
                            deal={dealerOffer}
                            isMostRecentDealerOffer={true}
                            hasMultiScenarios={hasMultiScenarios}
                        />
                        <OfferCardInterstate
                            deal={shopperOffer}
                            isMostRecentShopperOffer={true}
                            hasMultiScenarios={hasMultiScenarios}
                        />
                    </SliderCarousel>
                </InterstateOfferCardsContainer>
                <OfferHistoryCardInterstate />
            </Grid>
        );
    };

    if (!isLoading && !dealerOffer && !shopperOffer) {
        return null;
    }
    const content = isLoading ? (
        <LoadingIndicatorContainer data-testid="dr-dash-deal-comparison-loading-indicator">
            <Spinner size={60} data-testid="offer-history-loading-interstate" />
        </LoadingIndicatorContainer>
    ) : (
        <>{showOfferHistory()}</>
    );
    return (
        <Grid container flexDirection="column" className="dr-dash-deal-comparison">
            {content}
        </Grid>
    );
};
