// types
import { IVehicle } from '@makemydeal/dr-platform-types';

// styles
import { StyledVehicleImage } from './VehicleImage.style';
import { useCallback, useEffect, useState } from 'react';

import VehiclePlaceholderSvg from '../../assets/blankVehicle.svg';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

export type VehicleImageProps = {
    vehicle: IVehicle;
};

export const VehicleImage = ({ vehicle }: VehicleImageProps) => {
    const { imageUrl, year = '', make = '', model = '' } = vehicle;

    const [isImageError, setIsImageError] = useState(false);

    const theme = useInterstateTheme();

    /**
     * There will (possibly) be the place when the vehicle list is redesigned to
     * support CADS and (possibly) use an item index for
     * VehicleListItemInterstate's key field as there may be duplicates of vin,
     * which is currently used as a key. In this case the component won't be
     * unmounted between searches, but instead its props, including the image,
     * will be updated.
     */
    useEffect(() => setIsImageError(false), [imageUrl]);

    const onImageError = useCallback(() => {
        setIsImageError(true);
    }, []);

    const imgDescription = `Photo of a ${year} ${make} ${model}`;
    const useOriginalImage = imageUrl && !isImageError;

    return (
        <StyledVehicleImage
            data-testid="change-vehicle-image"
            alt={imgDescription}
            className="img-responsive img-rounded"
            src={useOriginalImage ? imageUrl : VehiclePlaceholderSvg}
            onError={onImageError}
            theme={theme}
        />
    );
};
