// libraries
import { getAllRoutes } from '@makemydeal/dr-activities-desking';
import { NavigationItemStatus } from '@makemydeal/dr-activities-shared';

// interfaces/types
import type { IFlowConfig } from '../../flowMasterInterfaces';

// components
import DeskingContainer from './components/DeskingContainer';

// consts/enums
import { APP_NAME, DESKING_ROUTE_ROOT } from './constants';
import { deskingMiddleware } from '@makemydeal/dr-dash-store';

export const allRoutes = getAllRoutes(DESKING_ROUTE_ROOT);

// istanbul ignore next
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noopReducer = () => {};

/* this activity won't appear on the dr-activities-dashboard card list,
 * so including the bare minimum inputs to satisfy the interface
 */
export const deskingConfiguration: IFlowConfig<any> = {
    name: APP_NAME,
    navCard: {
        label: 'Desking',
        icon: 'deposit',
        cardId: 'dr-sp-desking'
    },
    showCardSelector: () => false,
    isCompleteSelector: () => false,
    routingComponent: DeskingContainer,
    defaultRoute: allRoutes.root,
    routes: [...Object.keys(allRoutes).map((routeName) => allRoutes[routeName])],
    middleware: deskingMiddleware,
    reducer: noopReducer, // desking doesn't have any of its own state
    isLocked: (_state: unknown) => false,
    dependsOnLeadFormCompletion: () => false,
    status: () => NavigationItemStatus.DEFAULT,
    isDisabled: () => false
};
