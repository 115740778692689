/**
 * PURPOSE: Actions that trigger push to dms will be processed by this middleware.
 */

// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// actions
import { offerActionCreators, pushToDmsActionCreators } from '../actions';

// consts/enums
import {
    OFFER_SAVE_SUCCESS,
    OFFER_SAVE_FAILURE,
    LINKS_SUCCESS,
    LINKS_FAILURE,
    OFFER_SAVE_NOCHANGE_SUCCESS,
    SOFT_SAVE_SUCCESS,
    SOFT_SAVE_FAILURE
} from '../actionTypes/offerActionTypes';
import { PUSH_TO_DMS, PUSH_TO_DMS_PLUS, PUSH_TO_DMS_FAILURE } from '../actionTypes/pushToDmsActionTypes';
import { PUSH_TO_DMS_FLOW, DMS_PLUS } from '../constants';
import { BuildPayloadPurpose } from '../types';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

// actions
import { buildOfferSavePayloadObject, smartOfferSave } from '../actions/offerActionCreators';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    const state = store.getState();

    switch (action.type) {
        case PUSH_TO_DMS_PLUS:
        case PUSH_TO_DMS: {
            const source = PUSH_TO_DMS_FLOW;
            if (featureToggleSelectors.isPushToDmsSmartSaveEnabled(state)) {
                const purpose = BuildPayloadPurpose.PushToDms;
                store.dispatch(smartOfferSave(state, source, purpose));
            } else {
                const offerSavePayload = buildOfferSavePayloadObject(state, BuildPayloadPurpose.PushToDms);
                store.dispatch(offerActionCreators.saveOfferRSAA(state, offerSavePayload, undefined, source));
            }
            break;
        }
        case LINKS_SUCCESS:
        case SOFT_SAVE_SUCCESS:
        case OFFER_SAVE_NOCHANGE_SUCCESS:
        case OFFER_SAVE_SUCCESS: {
            const offerSaveSource = action.meta?.source;
            if (offerSaveSource === PUSH_TO_DMS_FLOW) {
                if (dealerSelectors.getDMSIntegrationToggle(state) !== DMS_PLUS) {
                    store.dispatch(pushToDmsActionCreators.pushToDmsRSAA(MANAGER_VIEW_APP_PREFIX, state));
                }
            }
            break;
        }
        case LINKS_FAILURE:
        case SOFT_SAVE_FAILURE:
        case OFFER_SAVE_FAILURE: {
            const offerSaveFailureSource = action.meta?.source;
            if (offerSaveFailureSource === PUSH_TO_DMS_FLOW) {
                store.dispatch(pushToDmsActionCreators.dmsError(500));
            }
            break;
        }
        case PUSH_TO_DMS_FAILURE:
            store.dispatch(pushToDmsActionCreators.dmsError(500));
            break;
    }
};
