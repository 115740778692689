// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';

// interfaces/types
import { TradeInCardState } from '../reducers/tradeInCardReducer';

export const getTradeInCard = (state: StateTree): TradeInCardState => {
    return state.tradeInCard as TradeInCardState;
};

export const isManualFormOpen = (state: StateTree) => {
    return getTradeInCard(state)?.openManualForm || false;
};

export const getShouldIncludeTradeIn = (state: StateTree) => {
    return getTradeInCard(state)?.shouldIncludeTradeIn || false;
};
