import { MaskingConfig } from '@interstate/components/TextInput/Types/textInputBaseTypes';
import { SelectInputOption } from '@interstate/components/SelectInput';

export enum FMField {
    msrp = 'msrp',
    sellingPrice = 'sellingPrice',
    invoiceAmount = 'invoiceAmount',
    cost = 'cost',
    mileage = 'mileage',
    wholesaleValue = 'wholesaleValue',
    wholesaleBookSource = 'wholesaleBookSource',
    stockNumber = 'stockNumber'
}

export type FMNames = keyof typeof FMField;

export type FMGenerator<T> = {
    [key in FMNames]?: T;
};
export type FMForm = FMGenerator<string | number>;
export type FMTouchedInputs = FMGenerator<boolean>;
export type FMInputErrors = FMGenerator<string>;
export type FMErrors = { [key: string]: FMInputErrors };
export type FMProps = {
    initialValues: FMForm;
    values: FMForm;
    touched: FMTouchedInputs;
    error: FMInputErrors;
    formVersion?: number;
};
export type FMContext = {
    form: FMProps;
    canApply: boolean;
    changeHandler: (name: FMNames, value: string | number) => void;
    handleClick: (type: 'APPLY' | 'CANCEL') => void;
    hideDrawer: () => void;
    isStockEditable: boolean;
    handleClickEditStock: () => void;
};
export type FormTextInputProps = {
    name: FMNames;
    label: string;
    inputSuffix?: string;
    masking?: MaskingConfig;
    // SxProps
    sx?: Record<string, object>;
    validator?: (value: number | string) => string;
};

export type FormStockInputProps = {
    name: 'stockNumber';
    label: string;
};

export type FormSelectInputProps = {
    name: 'wholesaleBookSource';
    label: string;
    options: SelectInputOption[];
    errorMessage?: string;
    maxHeight?: string;
    placeholder?: string;
};

export type PaymentState = {
    actionForPayment: string;
    isCalculating: boolean;
    error: boolean;
};
