// externals
import { useSelector } from 'react-redux';

// utils
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// types
import { IVehicle } from '@makemydeal/dr-platform-types';
import { VehicleListItemNotInStock } from './VehicleListItemNotInStock';
import { VehicleListItemInStock } from './VehicleListItemInStock';
import { CatalogVehicleWithIndex } from '../../types';

export type VehicleListItemProps = {
    vehicle: CatalogVehicleWithIndex;
    redirectToBuildVehicleTab: (vehicle: IVehicle) => void;
};

export const VehicleListItemInterstate = ({ vehicle, redirectToBuildVehicleTab }: VehicleListItemProps) => {
    const { stockNumber } = vehicle;

    const isSellAnyVehicleEnabled = useSelector(featureToggleSelectors.isSellAnyVehicleEnabled);

    return isSellAnyVehicleEnabled && !stockNumber ? (
        <VehicleListItemNotInStock vehicle={vehicle} onBuildVehicleClick={redirectToBuildVehicleTab} />
    ) : (
        <VehicleListItemInStock vehicle={vehicle} />
    );
};
