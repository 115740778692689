// externals
import React from 'react';

// libraries
import { Root } from '@makemydeal/dr-activities-vehicle-protection';

// consts/enums
import { VEHICLE_PROTECTION_ROUTE_ROOT } from '../constants';

const VehicleProtection = () => {
    return <Root baseRoute={VEHICLE_PROTECTION_ROUTE_ROOT} />;
};

export default VehicleProtection;
