// libraries
import { SimpleTable, TableCell, TableRow } from '@interstate/components/SimpleTable';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// styles
import {
    FeeNameWrapper,
    FINANCE_MIN_WIDTH,
    FINANCE_NAME_MIN_WIDTH,
    LEASE_MIN_WIDTH,
    LEASE_NAME_MIN_WIDTH,
    StyledTableCell
} from './FeesCard.style';

// components
import { TableBody } from '../taxes/Taxes/TableBody';
import { EmptyFeeCategoryFallback } from './EmptyFeeCategoryFallback';
import { FormatUpfrontForDisplay } from './FormatUpfrontForDisplay';
import { TableHead } from './TableHead';

// consts/enums
import { DATA_DENSITY, NA } from '../common/taxesAndFeesConsts';
import { ELIMINATED_FEE_AMOUNT, FINANCE_FEETABLECOLUMNS, LEASE_FEETABLECOLUMNS } from './constants';
import { CustomFeeItem, Detail, FinanceDetail, LeaseDetail } from './types';
import { isLeaseFinanceDetail } from './utils';

export type FeeItemSummaryDetailedProps = {
    feeItems: CustomFeeItem[];
    isLease: boolean;
};

export const FeeItems: React.FC<FeeItemSummaryDetailedProps> = ({ feeItems, isLease }) => {
    const feeDetailRows: Detail[] = feeItems.map((feeItem) => {
        const feeDetailRow: Detail = {
            name: feeItem.feeName,
            code: feeItem.feeTypeId,
            amount: feeItem.feeAmount,
            category: feeItem.category,
            isManual: feeItem.isManualFee,
            isEdited: feeItem.isEdited,
            isDeleted: feeItem.isDeleted,
            includedInCalc: feeItem.includedInCalc
        };

        if (isLeaseFinanceDetail(isLease, feeDetailRow)) {
            const isFeeCapped = feeItem.capIndicator;

            feeDetailRow.upfront = isFeeCapped == null ? null : !isFeeCapped;
        }

        return feeDetailRow;
    });

    const buildCodeCell = (index: number, detail: FinanceDetail) => (
        <TableCell key={`simple-cell-fees-code-${index}`}>
            <StyledTableCell
                $isManualFee={detail.isManual}
                data-testid={`simple-table-cell-${detail.code}`}
                $minWidth={isLease ? LEASE_MIN_WIDTH : FINANCE_MIN_WIDTH}
            >
                {detail.code == null ? NA : `${detail.code}`}
            </StyledTableCell>
        </TableCell>
    );

    const buildNameCell = (index: number, detail: FinanceDetail) => (
        <TableCell key={`simple-cell-fees-name-${index}`}>
            <StyledTableCell
                data-testid={`simple-table-cell-${detail.name}`}
                $minWidth={isLease ? LEASE_NAME_MIN_WIDTH : FINANCE_NAME_MIN_WIDTH}
                $justifyContent="flex-end"
                $textAlign="left"
            >
                <FeeNameWrapper>{detail.name} </FeeNameWrapper>
            </StyledTableCell>
        </TableCell>
    );

    const buildUpfrontCell = (index: number, detail: LeaseDetail) => (
        <TableCell key={`simple-cell-fees-upfront-${index}`}>
            <StyledTableCell
                data-testid={`simple-table-cell-${detail.upfront}`}
                $minWidth={LEASE_MIN_WIDTH}
                $textAlign="center"
                $justifyContent="center"
            >
                {detail.upfront == null ? NA : <FormatUpfrontForDisplay upfront={detail.upfront} />}
            </StyledTableCell>
        </TableCell>
    );
    const buildAmountCell = (index: number, detail: FinanceDetail) => (
        <TableCell key={`simple-cell-fees-amount-${index}`}>
            <StyledTableCell
                data-testid={`simple-table-cell-${detail.amount}`}
                $minWidth={isLease ? LEASE_MIN_WIDTH : FINANCE_MIN_WIDTH}
                $textAlign="right"
                $justifyContent="flex-end"
            >
                {detail.amount == null ? NA : formatDollarsAndCents(detail.amount)}
            </StyledTableCell>
        </TableCell>
    );

    const renderFinanceRow = (detail: FinanceDetail, index: number) => (
        <>
            {buildCodeCell(index, detail)}
            {buildNameCell(index, detail)}
            {buildAmountCell(index, detail)}
        </>
    );

    const renderLeaseRow = (detail: LeaseDetail, index: number) => (
        <>
            {buildCodeCell(index, detail)}
            {buildNameCell(index, detail)}
            {buildUpfrontCell(index, detail)}
            {buildAmountCell(index, detail)}
        </>
    );

    const renderRow = (detail: Detail, index: number) => {
        if (detail.amount === `${ELIMINATED_FEE_AMOUNT}`) return null;

        return isLease ? renderLeaseRow(detail as LeaseDetail, index) : renderFinanceRow(detail, index);
    };

    const tableColumns = isLease ? LEASE_FEETABLECOLUMNS : FINANCE_FEETABLECOLUMNS;

    return feeDetailRows.length ? (
        <SimpleTable data-testid="simple-table-data-testid" dataDensity={DATA_DENSITY} background="white">
            <TableHead columns={tableColumns} />
            <TableBody data={feeDetailRows} renderRow={renderRow} />
        </SimpleTable>
    ) : (
        <EmptyFeeCategoryFallback isLease={isLease} />
    );
};

export default FeeItems;
