// externals
import { RSAA } from 'redux-api-middleware';
import { FSA, SimpleFSA } from '@makemydeal/dr-platform-shared';

// libraries
import { MANAGER_VIEW_APP_PREFIX, apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';

// consts/enums
import {
    ACCESSORIES_CLOSE_FORM,
    ACCESSORIES_OPEN_FORM,
    ADD_CATALOG_ACCESSORIES,
    ADDED_MANUAL_ACCESSORIES,
    ADD_ACCESSORIES_SELECTIONS,
    INIT_ACCESSORIES,
    INIT_MENU_ACCESSORIES,
    REMOVE_CATALOG_ACCESSORIES,
    FOCUS_ACCESSORIES_TABLE,
    CHECK_ACCESSORIES_CATALOG_EXISTS_REQUEST,
    CHECK_ACCESSORIES_CATALOG_EXISTS_SUCCESS,
    CHECK_ACCESSORIES_CATALOG_EXISTS_FAILURE,
    ACCESSORIES_ACTIVITY_IN_PROGRESS,
    ACCESSORIES_ACTIVITY_COMPLETED
} from '../actionTypes/accessoriesActionTypes';
import { DASH_START_ACCESSORIES_CATALOG } from '../actionTypes/navigationActionTypes';

// selectors
import * as vehicleSelectors from '../selectors/vehicleSelectors';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

// utils
import { AccessoriesAdapter } from '../utils/manualAccessoriesAdapter';
import * as accessoriesSelectors from '../selectors/accessoriesSelectors';
import { CatalogAccessory, ProtectionListSuccessPayload } from '@makemydeal/dr-dash-types';
import { DealAccessory } from '@makemydeal/dr-shared-types';

export type OptionalOpenFormPayload = {
    scrollIntoView: boolean;
};

export type OpenFormPayload = OptionalOpenFormPayload | undefined;

export type AccessoriesOpenFormAction = FSA<typeof ACCESSORIES_OPEN_FORM, OpenFormPayload>;
export type CloseForm = SimpleFSA<typeof ACCESSORIES_CLOSE_FORM>;

export type OpenFormOptions = {
    scrollIntoView: boolean;
};

type PaymentSkipMeta = { skipPayment?: boolean };

export const startAccessoriesProgress = () => {
    return {
        type: ACCESSORIES_ACTIVITY_IN_PROGRESS
    };
};

export const completeAccessoriesProgress = () => {
    return {
        type: ACCESSORIES_ACTIVITY_COMPLETED
    };
};

export const useAccessoriesInitSuccess = (accessories: DealAccessory[]) => ({
    type: INIT_ACCESSORIES,
    payload: {
        accessories
    }
});

export const useAccessoriesMenuSuccess = (payload: ProtectionListSuccessPayload) => ({
    type: INIT_MENU_ACCESSORIES,
    payload
});

export const openForm = (openOptions?: OpenFormOptions): AccessoriesOpenFormAction => {
    if (openOptions?.scrollIntoView) {
        return {
            type: ACCESSORIES_OPEN_FORM,
            payload: {
                scrollIntoView: true
            }
        };
    }
    return {
        type: ACCESSORIES_OPEN_FORM
    };
};

export const closeForm = (): CloseForm => {
    return {
        type: ACCESSORIES_CLOSE_FORM
    };
};

export const focusAccessoriesTable = () => {
    return {
        type: FOCUS_ACCESSORIES_TABLE
    };
};

export const addManualAccessories = (manualAccessories: any[], meta?: PaymentSkipMeta) => ({
    type: ADDED_MANUAL_ACCESSORIES,
    payload: {
        manualAccessories: {
            accessories: manualAccessories
        }
    },
    meta
});

export const addCatalogAccessories = (catalogAccessories: CatalogAccessory[]) => ({
    type: ADD_CATALOG_ACCESSORIES,
    payload: {
        accessories: catalogAccessories
    }
});

export const removeCatalogAccessories = (catalogAccessories: CatalogAccessory[]) => ({
    type: REMOVE_CATALOG_ACCESSORIES,
    payload: {
        accessories: catalogAccessories
    }
});

export const addAccessoriesSelections =
    (skipPayment?: boolean): any =>
    (dispatch: any, getState: any) => {
        const state = getState();

        const accessoriesPayload = AccessoriesAdapter.toTargetFormat(accessoriesSelectors.getAccessories(state));
        const accessoriesSelections = accessoriesPayload.accessoryObjList;

        dispatch({
            type: ADD_ACCESSORIES_SELECTIONS,
            payload: {
                accessories: accessoriesSelections
            },
            ...(!skipPayment && {
                meta: {
                    middleware: {
                        payment: true
                    }
                }
            })
        });
    };

export const redirectToAccessoriesCatalogPage = () => {
    return { type: DASH_START_ACCESSORIES_CATALOG };
};

export const checkAccessoriesCatalogExists = () => (dispatch: any, getState: any) => {
    const state = getState();

    const dealerId = dealerSelectors.getDealerId(state);
    const { year, make, model, trim, vin } = vehicleSelectors.getVehicle(state);

    const searchParams = {
        dealerId,
        year,
        make,
        model,
        trim,
        ...(vin && { vin })
    };

    const queryString = new URLSearchParams(searchParams).toString();

    const locHref = locHrefUtil.getLocHref();
    const urlBuildResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, 'accessories/catalogExistsCheck');
    const catalogCheckEndpoint = `${urlBuildResult.url}?${queryString}`;

    dispatch({
        [RSAA]: {
            endpoint: catalogCheckEndpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                CHECK_ACCESSORIES_CATALOG_EXISTS_REQUEST,
                CHECK_ACCESSORIES_CATALOG_EXISTS_SUCCESS,
                CHECK_ACCESSORIES_CATALOG_EXISTS_FAILURE
            ]
        }
    });
};
