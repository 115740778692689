import { FC } from 'react';
import { StyledSubHeaderContainer } from './SlideOutDrawerHeader.style';

export type SlideOutHeaderProps = {
    text?: string;
    subHeaderText?: string;
};

export const SlideoutDrawerHeader: FC<SlideOutHeaderProps> = ({ text, subHeaderText }) => {
    return (
        <div id="slide-out-drawer-header">
            <div>{text && <h3>{text}</h3>}</div>
            {subHeaderText && (
                <StyledSubHeaderContainer>
                    <p>{subHeaderText}</p>
                </StyledSubHeaderContainer>
            )}
        </div>
    );
};
