// externals
import { FC } from 'react';

// libraries
import { CheckBox } from '@interstate/components/CheckBox';

// interfaces/types
import { CheckboxChangeEventHandlerChange, ColumnMapping } from '../manualAccessoriesTypes';

export type ManualAccessoryWeOweFieldStateProps = {
    index: number;
    weOwe: boolean;
    weOweColumnMapping: ColumnMapping<boolean>;
    deleted: boolean;
};

export type ManualAccessoryWeOweFieldDispatchProps = {
    handleCheckboxCheck: CheckboxChangeEventHandlerChange;
};

export type ManualAccessoryWeOweFieldProps = ManualAccessoryWeOweFieldDispatchProps & ManualAccessoryWeOweFieldStateProps;

export const ManualAccessoryWeOweField: FC<ManualAccessoryWeOweFieldProps> = ({
    deleted,
    index,
    weOwe,
    weOweColumnMapping,
    handleCheckboxCheck
}) => (
    <CheckBox
        label={weOweColumnMapping.label}
        name={`${weOweColumnMapping.fieldName}-${index}`}
        data-testid={`${weOweColumnMapping.fieldName}-${index}`}
        onChange={(e) => {
            handleCheckboxCheck(e, weOweColumnMapping.fieldName);
        }}
        checked={weOwe}
        value={`${weOweColumnMapping.fieldName}-${index}`}
        disabled={deleted}
    />
);
