// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { ToggleStatsToggleItem, toggleUseRegistry } from '@makemydeal/dr-feature-toggles-client';
import { bootstrapActionTypes } from '@makemydeal/dr-shared-store';
import { DashMiddleware, DashNext, DashStore } from '@makemydeal/dr-shared-store';
import { toggleStatsActionCreators, toggleStatsActionTypes } from '@makemydeal/dr-dash-store';
import { MANAGER_VIEW_APP_PREFIX, isFeatureToggleStatsEnabled, setupFeatureToggleStats } from '@makemydeal/dr-shared-ui-utils';

export const toggleStatsMiddleware: DashMiddleware = (store: DashStore) => (next: DashNext) => async (action: AnyFSA) => {
    next(action);
    switch (action.type) {
        case bootstrapActionTypes.BOOTSTRAP_SUCCESS: {
            if (isFeatureToggleStatsEnabled(action.payload)) {
                const updateToggleStatsApiCall = (toggleStatsItems: ToggleStatsToggleItem[]) => {
                    const updateToggleStatsAction = toggleStatsActionCreators.updateFeatureToggleStatsRequest(
                        MANAGER_VIEW_APP_PREFIX,
                        toggleStatsItems
                    );
                    store.dispatch(updateToggleStatsAction);
                };
                setupFeatureToggleStats(action.payload, updateToggleStatsApiCall);
            }
            break;
        }
        case toggleStatsActionTypes.UPDATE_FEATURE_TOGGLE_STATS_SUCCESS: {
            toggleUseRegistry.onApiCallSuccess();
            break;
        }
        case toggleStatsActionTypes.UPDATE_FEATURE_TOGGLE_STATS_FAILURE: {
            const message = action.payload.message;
            const response = action.payload.response;
            toggleUseRegistry.onApiCallFailure(message, response);
            break;
        }
    }
};
