// libraries
import { Grid } from '@interstate/components/Grid';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import type { DealHistory } from '@makemydeal/dr-dash-types';

// consts
import * as constants from '../../constants';

import { taxElementsToLineItems } from '../utils/transform';

// hooks
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

// components
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';
import LineItemWithChildren from '../common/LineItemWithChildren';
import { useSelector } from 'react-redux';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { expandibleAreaStyles, expandibleButtonStyles, labelStyles, secondaryValueStyles, valueStyles } from '../utils/styles';
import DealSummaryItem from '../common/DealSummaryItem';

export type TotalTaxProps = {
    deal?: DealHistory;
    useWrapper?: boolean;
    hasDelta?: boolean;
};

const TotalTax = ({ deal, useWrapper = true, hasDelta = false }: TotalTaxProps) => {
    const useDealRootSelector = createDealRootSelector(deal);

    const totalTax = useDealRootSelector(offerReduxSelectors.getTotalTax);

    const additionalBreakdown = useDealRootSelector(offerSelectors.getAdditionalTaxBreakdown);
    const additionalItems = taxElementsToLineItems(additionalBreakdown);
    const additionalTotal = useDealRootSelector(offerSelectors.getAdditionalTaxTotal);

    const primaryBreakdown = useDealRootSelector(offerReduxSelectors.getPrimaryTaxBreakdown);
    const primaryTotal = useDealRootSelector(offerSelectors.getPrimaryTaxTotal);
    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);

    if (primaryBreakdown == null) {
        return null;
    }

    const primaryItems = taxElementsToLineItems(primaryBreakdown);

    const isPrimary = primaryTotal > 0 && primaryItems !== null;
    const isAdditional = additionalTotal > 0 && additionalItems !== null;

    return (
        <div>
            {isEnhancedDealDetailsEnabled ? (
                <DealSummaryItem
                    valueStyles={valueStyles}
                    labelStyles={labelStyles}
                    label={constants.TOTAL_TAXES}
                    value={formatDollarsAndCents(totalTax)}
                >
                    {isPrimary && (
                        <DealSummaryItem
                            valueStyles={secondaryValueStyles}
                            labelStyles={labelStyles}
                            expandibleButtonStyles={expandibleButtonStyles}
                            expandibleAreaStyles={expandibleAreaStyles}
                            label={constants.PRIMARY_TAXES}
                            value={formatDollarsAndCents(primaryTotal)}
                        >
                            {primaryItems.map(({ label: childLabel, value: childValue }) => (
                                <LineItemWithChildren
                                    containerStyles={{
                                        pr: 0
                                    }}
                                    label={childLabel}
                                    value={childValue}
                                />
                            ))}
                        </DealSummaryItem>
                    )}
                    {isAdditional && (
                        <DealSummaryItem
                            valueStyles={secondaryValueStyles}
                            labelStyles={labelStyles}
                            expandibleButtonStyles={expandibleButtonStyles}
                            expandibleAreaStyles={expandibleAreaStyles}
                            label={constants.ADDL_TAXES}
                            value={formatDollarsAndCents(additionalTotal)}
                        >
                            {additionalItems.map(({ label: childLabel, value: childValue }) => (
                                <LineItemWithChildren
                                    containerStyles={{
                                        pr: 0
                                    }}
                                    label={childLabel}
                                    value={childValue}
                                />
                            ))}
                        </DealSummaryItem>
                    )}
                </DealSummaryItem>
            ) : (
                <DealSummaryItemWrapper
                    label={constants.TOTAL_TAXES}
                    value={formatDollarsAndCents(totalTax)}
                    useWrapper={useWrapper}
                    hasDelta={hasDelta}
                >
                    {(isPrimary || isAdditional) && (
                        <Grid>
                            {isPrimary && (
                                <LineItemWithChildren
                                    testId="deal-summary-lineitem-capitalized-taxes"
                                    label={constants.PRIMARY_TAXES}
                                    value={formatDollarsAndCents(primaryTotal)}
                                    childItems={primaryItems}
                                />
                            )}
                            {isAdditional && (
                                <LineItemWithChildren
                                    label={constants.ADDL_TAXES}
                                    value={formatDollarsAndCents(additionalTotal)}
                                    childItems={additionalItems}
                                />
                            )}
                        </Grid>
                    )}
                </DealSummaryItemWrapper>
            )}
        </div>
    );
};

export default TotalTax;
