import { useOktaAuth } from '@okta/okta-react';
import { FC } from 'react';

/** Render the provided children only when okta context exists */
export const RenderWhenOktaEnabled: FC<any> = ({ children }) => {
    const oktaExists = Boolean(useOktaAuth());
    if (oktaExists) {
        return children;
    }
    return null;
};
