// libraries
import { getAllRoutes } from '@makemydeal/dr-activities-accessories-catalog';
import { NavigationItemStatus } from '@makemydeal/dr-activities-shared';

// interfaces/types
import type { IFlowConfig } from '../../flowMasterInterfaces';

// components
import AccessoriesCatalog from './components/AccessoriesCatalogContainer';

// consts/enums
import { APP_NAME, ACCESSORIES_CATALOG_ROUTE_ROOT } from './constants';

export const allRoutes = getAllRoutes(ACCESSORIES_CATALOG_ROUTE_ROOT);

// istanbul ignore next
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noopReducer = () => {};

/* this activity won't appear on the dr-activities-dashboard card list,
 * so including the bare minimum inputs to satisfy the interface
 */
export const accessoriesCatalogConfiguration: IFlowConfig<any> = {
    name: APP_NAME,
    navCard: {
        label: 'Explore Parts & Accessories',
        icon: 'accessoriesIcon',
        iconClass: 'accessories',
        cardId: 'dr-sp-parts-and-accessories-card',
        title: 'Parts & Accessories',
        dashboardIcon: 'accessoriesIcon'
    },
    showCardSelector: () => false,
    isCompleteSelector: () => false,
    routingComponent: AccessoriesCatalog,
    defaultRoute: allRoutes.root,
    routes: [...Object.keys(allRoutes).map((routeName) => allRoutes[routeName])],
    middleware: null,
    reducer: noopReducer,
    isLocked: (_state: unknown) => false,
    dependsOnLeadFormCompletion: () => false,
    status: () => NavigationItemStatus.DEFAULT,
    isDisabled: () => false
};
