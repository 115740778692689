import styled from 'styled-components';

export const ProductName = styled.div`
    display: flex;
    flex: 3;
    font-weight: 500;
`;

export const ProductNameContainer = styled.div`
    display: flex;
    padding-right: 0.5rem;
    font-size: ${({ theme }) => theme.tokens.BrandTypographyFontSizeBase};
    margin-bottom: ${({ theme }) => theme.tokens.BaseSpace40};
`;

export const IncludedAddOnsContainer = styled.div`
    font-size: ${({ theme }) => theme.tokens.BrandTypographyFontSizeBase};
    margin-bottom: ${({ theme }) => theme.tokens.BaseSpace40};
`;

export const AddOnsTitle = styled.div`
    font-weight: 500;
    line-height ${({ theme }) => theme.tokens.BaseSizeFont20};
    margin-bottom: ${({ theme }) => theme.tokens.BaseSpace40};
`;

export const AddOnsList = styled.ul`
    margin: 1.5rem;
    padding: 0;
`;

export const InfoRow = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.tokens.BaseSpace15};
    font-size: ${({ theme }) => theme.tokens.BrandTypographyFontSizeBase};
    font-weight: 400;
    line-height ${({ theme }) => theme.tokens.BaseSizeFont20};
    letter-spacing: 0em;
    text-align: left;
`;

export const InfoText = styled.span`
    margin-right: ${({ theme }) => theme.tokens.BaseSizePadding8};
    font-size: ${({ theme }) => theme.tokens.BrandTypographyFontSizeBase};
    font-weight: 500;
    line-height ${({ theme }) => theme.tokens.BaseSizeFont20};
    letter-spacing: 0em;
    text-align: left;
`;

export const ProductCost = styled.div`
    flex: 1;
    text-align: right;
    font-weight: 500;
`;
