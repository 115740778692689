// externals
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// styles
import { StyledTitle } from './TitleWithToolTipIcon.style';

// components
import IconWithToolTip from './IconWithToolTip';

type TitleWithToolTipIconProps = {
    name: string;
    title: string;
    toolTipContent?: string;
};

const TitleWithToolTipIcon = ({ toolTipContent, name, title }: TitleWithToolTipIconProps) => {
    const theme = useInterstateTheme();

    let optionalTooltip: JSX.Element | null = null;

    if (toolTipContent) {
        optionalTooltip = <IconWithToolTip name={name} toolTipContent={toolTipContent} />;
    }

    return (
        <StyledTitle theme={theme}>
            {title}
            {optionalTooltip}
        </StyledTitle>
    );
};

export default TitleWithToolTipIcon;
