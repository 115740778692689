// externals
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// libraries
import type { InitSuccessPayload } from '@makemydeal/dr-dash-bff-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';

// interfaces/types
import { InitPencilSuccessAction } from '../actions/initPencil';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export type ConnectionState = {
    id?: string | null;
};

export const INITIAL_CONNECTION_STATE: ConnectionState = {};

export type AllConnectionActions = InitPencilSuccessAction;

export const getConnectionIdFromPayload = (payload: InitSuccessPayload): string | null => {
    return payload.offer?.connectionId ?? null;
};

export type ConnectionReducer = Reducer<ConnectionState>;

export const reducer: ConnectionReducer = (state: ConnectionState = INITIAL_CONNECTION_STATE, action: AllConnectionActions) => {
    if (!action) {
        return state;
    }
    return produce(state, (draft: Draft<ConnectionState>) => {
        switch (action.type) {
            case INIT_PENCIL_SUCCESS: {
                draft.id = getConnectionIdFromPayload(action.payload!);
                return;
            }
        }
    });
};
