// external
import styled from 'styled-components';

export const VEHICLE_LIST_ITEM_WIDTH = '319px';

export const VehicleListItemsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${VEHICLE_LIST_ITEM_WIDTH}, 1fr));
    grid-gap: 16px;
    justify-content: start;
`;
