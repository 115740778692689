// externals
import { FC, useState, useEffect, PropsWithChildren } from 'react';

// libraries
import { useOktaAuth } from '@okta/okta-react';

type ReadyContentProps = PropsWithChildren<{
    loadingPage: FC;
    identified: boolean;
    renewTokens?: () => Promise<boolean>;
}>;

export const ReadyContent: FC<ReadyContentProps> = ({ children, identified, loadingPage: LoadingPage, renewTokens }) => {
    const oktaAuthContext = useOktaAuth();
    const oktaAuth = oktaAuthContext?.oktaAuth;
    const oktaDisabled = oktaAuth == null;
    const [authenticated, setAuthenticated] = useState(oktaDisabled);

    useEffect(() => {
        if (!authenticated && oktaAuth && renewTokens) {
            const handleExpiredTokens = async () => {
                const isAuthenticated = await oktaAuth.isAuthenticated({ onExpiredToken: 'none' });
                if (isAuthenticated) {
                    setAuthenticated(true);
                    return;
                }
                const result = await renewTokens();
                setAuthenticated(result);
                return;
            };
            handleExpiredTokens();
        }
    }, []);

    if (!oktaAuth) {
        if (!identified) {
            return <LoadingPage />;
        }

        return <>{children}</>;
    }

    if (!identified || !authenticated) {
        return <LoadingPage />;
    }

    return <>{children}</>;
};
