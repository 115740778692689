// libraries
import { createSelector } from 'reselect';
import type { StateTree } from '@makemydeal/dr-dash-types';

// consts/enums
import { ICO, ICO_MESSAGE, SD, SD_MESSAGE, USER, USER_MESSAGE, DEFAULT_MESSAGE } from '../constants';

// selectors
import { getTradeInCard } from './tradeInCardSelectors';

// interfaces/types
import { TradeInState } from '../reducers/tradeInReducer';
import { getSelectedTerm } from './offerRedux';

// TODO: DASH - define this type - probably switch to using an already defined type/interface for this from an activity export?
export type GetTradeInResult = {
    isCompleted: boolean | undefined;
    isSkipped: boolean | undefined;
};

export const getGlobalTradeIn = (state: StateTree): TradeInState => {
    return state.tradeIn as TradeInState;
};

export const getTradeIn = createSelector(getGlobalTradeIn, getTradeInCard, (globalTradeIn, scopedTradeIn) => ({
    ...globalTradeIn,
    ...scopedTradeIn
}));

export const getTradeNetValue = (state: StateTree): number => getTradeValue(state) - getTradeInAmountOwed(state);

export const getNetTradeInAllowanceValue = (state: StateTree): number => {
    const netTradeValue = getSelectedTerm(state).netTrade;
    return netTradeValue ?? 0;
};

export const getTradeValue = (state: StateTree): number => state.tradeIn?.value || 0;

export const getTradeInStatus = (state: StateTree): GetTradeInResult => {
    return {
        isCompleted: isTradeInCompletedFalsy(state),
        isSkipped: isTradeInSkippedFalsy(state)
    };
};

export const getTradeInAmountOwed = (state: StateTree): number => state.tradeIn?.amountOwed || 0;

export const isTradeInCompletedFalsy = (state: StateTree): boolean | undefined => state.tradeIn?.isCompleted;

export const isTradeInCompleted = (state: StateTree): boolean => isTradeInCompletedFalsy(state) || false;

export const isTradeInSkippedFalsy = (state: StateTree): boolean | undefined => state.tradeIn?.isSkipped;

export const isTradeInSkipped = (state: StateTree): boolean => isTradeInSkippedFalsy(state) || false;

export const getTradeACV = (state: StateTree) => state.tradeIn?.acv || 0;

export const getIsBuyerRegisteredOwner = (state: StateTree) => state.tradeIn?.isBuyerRegisteredOwner || false;

export const getIsSellingVehicleRegisteredInSameState = (state: StateTree) => {
    return state.tradeIn?.isSellingVehicleRegisteredInSameState || false;
};

export const getTradeMake = (state: StateTree) => {
    return getTradeIn(state).make || '';
};

export const getTradeMakeId = (state: StateTree) => {
    return getTradeIn(state).makeId || '';
};

export const getTradeModel = (state: StateTree) => {
    return getTradeIn(state).model || '';
};

export const getTradeModelId = (state: StateTree) => {
    return getTradeIn(state).modelId || '';
};

export const getTradeYear = (state: StateTree) => {
    return getTradeIn(state).year?.toString() || '';
};

export const getTradeMileage = (state: StateTree) => {
    return getTradeIn(state).mileage || 0;
};

export const getTradeAllowanceMethod = (state: StateTree) => {
    const source = getTradeIn(state).source || '';

    switch (source) {
        case ICO:
            return ICO_MESSAGE;
        case SD:
            return SD_MESSAGE;
        case USER:
            return USER_MESSAGE;
        default:
            return DEFAULT_MESSAGE;
    }
};

export const getTradeExpirationDate = (state: StateTree) => {
    return getTradeIn(state).expirationDate || '';
};

export const getTradePayoffDueDate = (state: StateTree) => {
    return getTradeIn(state).goodThroughDate || '';
};

export const getLienholder = (state: StateTree) => {
    return getTradeIn(state).lienHolder || '';
};

export const getLienholderFull = (state: StateTree) => {
    const tradeIn = getTradeIn(state);

    return {
        lienholder: tradeIn.lienHolder || '',
        lienholderAccountNumber: tradeIn.lienHolderAccountNumber || '',
        lienholderAddress: tradeIn.lienHolderAddress || {},
        perDiemInterest: tradeIn.perDiemInterest || 0,
        goodThroughDate: tradeIn.goodThroughDate || ''
    };
};

export const getPerDiem = (state: StateTree) => {
    return getTradeIn(state).perDiemInterest || 0;
};

export const getVin = (state: StateTree) => {
    return getTradeIn(state).vin?.toUpperCase() || '';
};

export const isValidVin = (state: StateTree) => {
    return getTradeIn(state).vin?.trim()?.length === 17;
};

export const getErrorDecodingVin = (state: StateTree) => getTradeIn(state).errorDecodingVin || false;

export const getBodyStyle = (state: StateTree) => {
    return getTradeIn(state).bodyStyle || '';
};

export const getCondition = (state: StateTree) => {
    return getTradeIn(state).condition || '';
};

export const getDoors = (state: StateTree) => {
    return getTradeIn(state).doors || '';
};

export const getExteriorColor = (state: StateTree) => {
    return getTradeIn(state).color || '';
};

export const getInteriorColor = (state: StateTree) => {
    return getTradeIn(state).interiorColor || '';
};

export const getEngine = (state: StateTree) => {
    return getTradeIn(state).engine || '';
};

export const getTransmission = (state: StateTree) => {
    return getTradeIn(state).transmission || '';
};

export const getDriveLine = (state: StateTree) => {
    return getTradeIn(state).driveLine || '';
};

export const getTradeOwnershipType = (state: StateTree) => {
    return getTradeIn(state).ownershipType || '';
};

export const getTradeTrim = (state: StateTree) => {
    return getTradeIn(state).trim || '';
};

export const getTradeTrimId = (state: StateTree) => {
    return getTradeIn(state).trimId || '';
};

export const getTradeTrimDecodedOptions = (state: StateTree) => {
    return getTradeIn(state).trimDecodedOptions || [];
};

export const vinHasBeenDecoded = (state: StateTree) => {
    return getTradeIn(state).vinHasBeenDecoded || false;
};

export const hasMultipleVin = (state: StateTree) => {
    return getTradeIn(state).hasMultipleVin || false;
};

export const multipleVinOptions = (state: StateTree) => {
    return getTradeIn(state).multipleVinOptions || [];
};

export const isSavingTrade = (state: StateTree) => {
    return getTradeIn(state).isTradeBeingSaved || false;
};
