// externals
import { useCallback, useEffect, useState } from 'react';
import { IParams, paramsToQueryString } from '@makemydeal/dr-common-utils';

// utils
import { useDispatch, useSelector } from 'react-redux';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import {
    accessoriesActionCreators,
    accessoriesSelectors,
    configSelectors,
    navigationActionCreators,
    offerReduxSelectors,
    offerSelectors,
    paymentTermSelectors,
    vehicleSelectors
} from '@makemydeal/dr-dash-store';
import { PartSitesIFrame } from './PartSitesCatalog.style';
import { pingPartSites, sendPartSitesInitMessage } from '../../utils';
import { CatalogAccessory } from '@makemydeal/dr-dash-types';

// components
import PartSitesAccessoriesAlert from './PartSitesAccessoriesAlert';
import { calculateApr } from '../../utils/calculateApr';

export type AccessoryAddedOrRemovedMessagePayload = {
    accessory: CatalogAccessory[];
    action: 'add_accessory' | 'remove_accessory';
    message: string;
    success: boolean;
};

const PartSitesCatalog = () => {
    const dispatch = useDispatch();
    const partSitesCatalogUrl = useSelector(configSelectors.getPartSitesCatalogUrl);
    const selectedCatalogAccessories = useSelector(accessoriesSelectors.getSelectedCatalogAccessories);
    const selectedManualAccessories = useSelector(accessoriesSelectors.getSelectedManualAccessories);
    const partSitesOrigin = new URL(partSitesCatalogUrl).origin;
    const { year, make, model, trim, vin } = useSelector(vehicleSelectors.getVehicle);
    const sellRateValue = useSelector(offerSelectors.getSellRateOverrideWithFallback);
    const sellRateType = useSelector(offerSelectors.getRateTypeOverrideFromOfferTypeOrSelectedTerm);
    const { months: term, apr } = useSelector(paymentTermSelectors.getCurrentPaymentTermData) || {};
    const dealerId = useSelector(dealerSelectors.getDealerId);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const theme = 'interstate';
    const submitButtonText = 'Save';

    const [hasChangedAccessories, setHasChangedAccessories] = useState(false);
    const [initialApr] = useState(sellRateType && sellRateValue ? calculateApr(sellRateValue, sellRateType) : undefined);

    const searchParams: IParams = {
        year: String(year),
        make: String(make),
        model: String(model),
        trim: String(trim),
        d_id: String(dealerId),
        deal_type: offerType,
        theme: theme
    };

    if (term) {
        searchParams.term = String(term);
    }

    if (initialApr) {
        searchParams.apr = String(initialApr);
    }

    if (vin) {
        searchParams.vin = vin;
    }

    const handleMessage = useCallback(
        (message: MessageEvent) => {
            if (message.origin === partSitesOrigin) {
                const { data } = message;
                const { action } = data;

                switch (action) {
                    case 'complete_added_accessories': {
                        dispatch(navigationActionCreators.navigateToDashboard());
                        setTimeout(() => dispatch(accessoriesActionCreators.focusAccessoriesTable()), 200);

                        break;
                    }
                    case 'add_accessory':
                        setHasChangedAccessories(true);
                        dispatch(accessoriesActionCreators.addCatalogAccessories(data.accessory));

                        break;
                    case 'remove_accessory': {
                        setHasChangedAccessories(true);
                        dispatch(accessoriesActionCreators.removeCatalogAccessories(data.accessory));

                        break;
                    }
                }
            }
        },
        [partSitesOrigin, dispatch, setHasChangedAccessories]
    );

    useEffect(() => {
        setHasChangedAccessories(false);
    }, [setHasChangedAccessories]);

    useEffect(() => {
        window.addEventListener('message', handleMessage, false);

        return () => {
            window.removeEventListener('message', handleMessage, false);
        };
    }, [handleMessage]);

    return (
        <>
            <PartSitesAccessoriesAlert hasChanges={hasChangedAccessories} />
            <PartSitesIFrame
                onLoad={(event) => {
                    sendPartSitesInitMessage({
                        event,
                        apr,
                        term,
                        submitButtonText,
                        selectedCatalogAccessories,
                        selectedManualAccessories
                    });

                    // NOTE: newrelic agent isn't configured to work locally
                    if ((window as any).newrelic) {
                        const nr = (window as any).newrelic;

                        pingPartSites(event, partSitesOrigin).then(
                            () =>
                                nr.addPageAction('PartSites iframe has been loaded successfully', {
                                    name: (event.target as any).src
                                }),
                            () => nr.noticeError(new Error(`PartSites iframe failed - ${(event.target as any).src}`))
                        );
                    }
                }}
                data-testid="dr-activities-accessories-iframe"
                className="dr-activities-accessories-iframe"
                title="Parts And Accessories"
                src={`${partSitesCatalogUrl}?${paramsToQueryString(searchParams)}`}
            />
        </>
    );
};

export default PartSitesCatalog;
