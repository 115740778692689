import numeral from 'numeral';
import { ProgramQuote } from '@makemydeal/dr-dash-types';
import * as offerReduxSelectors from '../selectors/offerRedux';
import { DateTime } from 'luxon';
import { IResponsePayment, LEASE, OfferType, ILenderProgram } from '@makemydeal/dr-platform-types';
import { IPaymentTerm } from '@makemydeal/dr-platform-shared';
import { formatDollars, formatDollarsAndCents, formatNumber } from '@makemydeal/dr-common-utils';

export const isSelectedProgram = (
    selectedTerm: IPaymentTerm,
    currentProgram: ProgramQuote,
    offerType: OfferType,
    annualMiles?: number
): boolean => {
    const selectedLenderProgram = selectedTerm.lenderProgram;

    const programLenderProgram = currentProgram.lenderProgram;

    if (!(selectedLenderProgram && programLenderProgram)) return false;

    const sameLenderProgram =
        programLenderProgram.branchId === selectedLenderProgram.branchId &&
        programLenderProgram.lenderId === selectedLenderProgram.lenderId &&
        programLenderProgram.programName === selectedLenderProgram.programName &&
        programLenderProgram.programType === selectedLenderProgram.programType &&
        programLenderProgram.rateProgram === selectedLenderProgram.rateProgram &&
        programLenderProgram.regionalId === selectedLenderProgram.regionalId &&
        programLenderProgram.residualProgram === selectedLenderProgram.residualProgram;
    const sameProgram = sameLenderProgram && selectedTerm.months === currentProgram.term;

    if (offerType !== LEASE) return sameProgram;

    const buyRate = parseFloat(currentProgram.buyRate ?? '0');
    const dealerAcquisitionFee = offerReduxSelectors.getDealerAcquisitionFeeFromTerm(selectedTerm) || {};
    const acqFee = dealerAcquisitionFee.dealerFeeAmount;

    return (
        sameProgram &&
        acqFee === currentProgram.acqFee &&
        annualMiles === currentProgram.annualMiles &&
        selectedTerm.buyRate === buyRate &&
        selectedTerm.securityDeposit === currentProgram.securityDeposit &&
        selectedTerm.baseResidualPercent === currentProgram.baseResidualPercent
    );
};

export const mapPaymentResponseToProgramQuote = (quote: IResponsePayment): ProgramQuote => {
    const {
        appliedIncentiveAmount,
        appliedIncentiveIds,
        baseResidualDollar,
        baseResidualPercent,
        buyRate,
        creditTierDescription,
        lenderCode,
        lenderLegalName,
        lenderProgram,
        monthlyPayment,
        profit,
        rateType,
        securityDeposit,
        sellRate,
        term,
        annualMiles
    } = quote;

    const acqFee = offerReduxSelectors.getDealerAcquisitionFeeFromTerm(quote as any)?.dealerFeeAmount;

    const formattedData = {
        acqFeeFormatted: acqFee == null ? undefined : formatDollars(acqFee),
        acqFee,
        appliedIncentiveAmount: formatDollarsAndCents(appliedIncentiveAmount),
        appliedIncentiveIds,
        backEndProfit: formatDollarsAndCents(profit?.backEndProfit),
        baseResidualDollar: formatDollarsAndCents(baseResidualDollar),
        baseResidualPercent,
        buyRate: formatRate(buyRate, rateType),
        creditTierDescription,
        frontEndProfit: formatDollarsAndCents(profit?.frontEndProfit),
        lenderCode,
        lenderLegalName,
        lenderProgram,
        monthlyPayment: formatDollarsAndCents(monthlyPayment),
        rateType,
        securityDeposit,
        securityDepositFormatted: formatDollarsAndCents(securityDeposit),
        sellRate: formatRate(sellRate, rateType),
        term,
        annualMiles,
        annualMilesFormatted: formatNumber(annualMiles),
        totalProfit: formatDollarsAndCents(profit?.totalProfit),
        profit
    };

    return formattedData;
};

export const formatRate = (rate: number, rateType: string) => {
    if (rateType === 'APR') return formatAprRate(rate);
    return formatMoneyFactor(rate);
};

export const formatAprRate = (amount: number): string => {
    return numeral(amount).format('0,0.000');
};

export const formatMoneyFactor = (amount: number): string => {
    return numeral(amount).format('0,0.00000');
};
