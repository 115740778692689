export enum VAutoAppraisalStatus {
    Completed = 'Completed',
    InProcess = 'InProcess'
}

export type VAutoState = {
    items: VAutoItem[];
    hasError: boolean;
};

export type getAppraisalsResponseData = {
    count: number;
    href: string;
    items: VAutoItem[];
    limit: number;
};

export type VAutoItem = {
    completedOn: string;
    costToMarketPercentage: number;
    createdOn: string;
    currency: string;
    dealer: VAutoDealer;
    href: string;
    id: string;
    likeMineMarketDaySupply: number;
    marketDaySupply: number;
    odometerAdjustedCostToMarketPercentage: number;
    odometerAdjustedPriceToMarketPercentage: number;
    priceToMarketPercentage: number;
    profitTime: ProfitTime;
    selectedExitStrategy: string;
    source: string;
    status: string;
    updatedOn: string;
    value: number;
    vehicle: VAutoVehicle;
};

export type VAutoDealer = {
    logicalId: string;
    name: string;
};

export type ProfitTime = {
    score: number;
    scoreBadge: string;
    scoreFactors: ScoreFactor[];
};

export type ScoreFactor = {
    code: string;
    factorType: string;
    message: string;
};

export type VAutoVehicle = {
    body: VAutoBody;
    dealerStockNumber: string;
    driveTrain: VAutoDriveTrain;
    engine: VAutoEngine;
    exterior: VAutoExterior;
    interior: VAutoExterior;
    make: string;
    model: string;
    odometer: number;
    odometerUnitOfMeasure: string;
    series: string;
    seriesDetail: string;
    transmission: VAutoTransmission;
    vin: string;
    year: number;
};

export type VAutoBody = {
    bedStyle: string;
    cabStyle: string;
    description: string;
    doorCount: number;
    roofStyle: string;
    sideStyle: string;
    type: string;
};

export type VAutoDriveTrain = {
    description: string;
    type: string;
    wheelbase: number;
    wheelCount: number;
};

export type VAutoEngine = {
    aspiration: string;
    camshaft: string;
    cylinderCount: number;
    description: string;
    displacement: number;
    fuelIntake: string;
    fuelType: string;
};

export type VAutoExterior = {
    color: string;
};

export type VAutoTransmission = {
    description: string;
    gearCount: number;
    type: string;
};
