import React from 'react';

// Components
import OfferCardDetailsInterstate from '../offerCardDetails/OfferCardDetails.interstate';

// Types
import type { IOfferCompareSummaryProps } from './types/offerCompareSummaryProps';

// Utils
import { Grid } from '@interstate/components/Grid';

const CompareColumnSummary = ({ offerType, payment, offerPrice }: IOfferCompareSummaryProps) => {
    return (
        <Grid p="16px 16px 16px 0">
            {payment && offerType && (
                <OfferCardDetailsInterstate
                    offerType={offerType}
                    monthlyPayment={payment.monthlyPayment}
                    netCashDown={payment.netCashDown}
                    tradeAllowance={payment.totalAllowance}
                    {...(offerPrice && { offerPrice })}
                    months={payment.months}
                    apr={payment.apr}
                    sellRate={payment.sellRate}
                />
            )}
        </Grid>
    );
};

export default CompareColumnSummary;
