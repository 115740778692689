// externals
import { useState, useEffect, SetStateAction, Dispatch } from 'react';

export default function useEditableProp<V>(value: V, isManuallyEdited = false): [V, Dispatch<SetStateAction<V>>, boolean] {
    const [inputProp, setInputProp] = useState(value);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        if (Number.isFinite(value) && value !== Number(inputProp)) {
            setHasChanged(true);
        } else {
            setHasChanged(false);
        }
    }, [inputProp, value]);

    const isEdited = hasChanged || isManuallyEdited;

    return [inputProp, setInputProp, isEdited];
}
