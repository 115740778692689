interface FniCreditBureauWidget extends Partial<HTMLElement> {
    'deal-ref-id': string;
    env: string;
    applicant: string;
}

namespace JSX {
    interface IntrinsicElements {
        'fni-credit-bureau-widget': FniCreditBureauWidget;
    }
}
