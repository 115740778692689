// externals
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// ui components
import { Modal } from '@interstate/components/Modal';
import { Grid } from '@interstate/components/Grid';
import { Button } from '@interstate/components/Button';
import { Typography } from '@interstate/components/Typography';

// store
import { offerActionCreators, offerInfoSelectors, pushToRouteOneActionCreators } from '@makemydeal/dr-dash-store';

// custom react component
import PushToRouteOneModal from './PushToRouteOneModal';
import { StyledPushToRouteOneFooter } from './PushToRouteOneDialog.style';

export interface IPushToRouteOneDialogProps {
    show: boolean;
    onHide(): void;
}

const PushToRouteOneDialog = (props: IPushToRouteOneDialogProps) => {
    // props
    const { show, onHide } = props;

    // hooks
    const dispatch = useDispatch();
    const [showLoadingModal, setShowLoadingModal] = useState(show);

    // constants
    const prefix = 'push-to-routone-dialog';
    const header: JSX.Element = (
        <Typography variant="h3" sx={{ paddingTop: '1rem' }} data-testid={`${prefix}-header`}>
            Push To Route One
        </Typography>
    );
    const footer = (
        <StyledPushToRouteOneFooter>
            <Grid container justifyContent="flex-end" data-testid={`${prefix}-footer`}>
                <Button data-testid={`${prefix}-cancel-button`} buttonStyle="secondary" onClick={onHide}>
                    Cancel
                </Button>
            </Grid>

            <Grid container justifyContent="flex-end" data-testid={`${prefix}-footer`}>
                <Button
                    data-testid={`${prefix}-confirm-button`}
                    buttonStyle="primary"
                    onClick={() => {
                        handleConfirmPushToRouteOne();
                        onHide();
                        setShowLoadingModal(true);
                    }}
                >
                    Confirm
                </Button>
            </Grid>
        </StyledPushToRouteOneFooter>
    );

    // dispatch
    const handleConfirmPushToRouteOne = () => {
        dispatch(pushToRouteOneActionCreators.confirmPushToRouteOne());
    };

    // istanbul ignore next
    const handleLoadingModal = () => {
        setShowLoadingModal(false);
    };

    return (
        <>
            <Modal
                size="large"
                id={prefix}
                data-testid={prefix}
                show={show}
                header={header}
                footer={{ footerComponent: footer }}
                onHide={onHide}
            >
                <Typography
                    data-testid={`${prefix}-stock-number-input`}
                    variant="body-sm"
                    tag="p"
                    color="sem.color.on-surface.primary"
                >
                    Make sure all financial and contracting activities have been completed before pushing to RouteOne
                </Typography>
            </Modal>
            {showLoadingModal && <PushToRouteOneModal show={showLoadingModal} header={header} onHide={handleLoadingModal} />}
        </>
    );
};

export default PushToRouteOneDialog;
