// hooks
import { useSelector } from 'react-redux';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealState, StateTree } from '@makemydeal/dr-dash-types';
import { dealEffectiveSelectors } from '@makemydeal/dr-dash-store';

// consts
import * as constants from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

export type TotalAmountDueProps = DealState & {
    showLargeValue?: boolean;
};

const AmountFinanced = ({ deal, showLargeValue }: TotalAmountDueProps) => {
    const financeTotalAmountDue = useSelector((state: StateTree) =>
        dealEffectiveSelectors.selectEffectiveAmountFinanced(state, deal)
    );

    return (
        <DealSummaryItem
            label={constants.TOTAL_AMOUNT_DUE_FINANCE}
            value={formatDollarsAndCents(financeTotalAmountDue)}
            showLargeValue={showLargeValue}
        />
    );
};

export default AmountFinanced;
