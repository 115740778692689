// externals
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Button } from '@interstate/components/Button';
import { tradeInActionCreators, tradeInCardActionCreators, tradeInSelectors } from '@makemydeal/dr-dash-store';

// styles
import { ButtonContainer } from '../trade.interstate.style';

import { hasEmptyField } from './utils';
import { useCallback } from 'react';

export const ManualTradeInActions = () => {
    const dispatch = useDispatch();

    const vehicleMileage = useSelector(tradeInSelectors.getTradeMileage);
    const vehicleYear = useSelector(tradeInSelectors.getTradeYear);
    const vehicleMake = useSelector(tradeInSelectors.getTradeMake);
    const vehicleModel = useSelector(tradeInSelectors.getTradeModel);
    const vehicleTrim = useSelector(tradeInSelectors.getTradeTrim);

    const areRequiredFieldsEmpty = hasEmptyField({
        vehicleMake,
        vehicleMileage,
        vehicleModel,
        vehicleTrim,
        vehicleYear
    });

    const handleCancelClick = useCallback(() => {
        dispatch(tradeInActionCreators.tradeDetailsManualEntryCancel());
        dispatch(tradeInCardActionCreators.toggleManualTradeForm());
    }, [dispatch]);

    const handleSaveClick = useCallback(() => {
        dispatch(tradeInActionCreators.tradeDetailsManualEntrySave());
        dispatch(tradeInCardActionCreators.toggleManualTradeForm());
    }, [dispatch]);

    return (
        <ButtonContainer data-testid="trade-details-manual-entry-btn">
            <Button
                id="btn-cancel-manual-trade-entry"
                data-testid="btn-cancel-manual-trade-entry"
                buttonStyle="secondary"
                onClick={handleCancelClick}
            >
                Cancel
            </Button>
            <Button
                id="btn-save-manual-trade-entry"
                data-testid="btn-save-manual-trade-entry"
                buttonStyle="primary"
                onClick={() => handleSaveClick()}
                sx={{ marginLeft: '16px' }}
                disabled={areRequiredFieldsEmpty}
            >
                Save
            </Button>
        </ButtonContainer>
    );
};
