// externals
import React, { PropsWithChildren, createContext, useContext, useState } from 'react';

export const HeaderMeasureContext = createContext<HeaderContextType>(undefined as never);

export type HeaderContextType = {
    height: number;
    setHeight: (v: number) => void;
};

export const HeaderMeasureProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [height, setHeight] = useState<number>(0);
    return <HeaderMeasureContext.Provider value={{ height, setHeight }}>{children}</HeaderMeasureContext.Provider>;
};

export function useHeaderMeasure() {
    const context = useContext(HeaderMeasureContext);
    return context;
}
