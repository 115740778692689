//external
import styled from 'styled-components';

export const StyledAlertWrapper = styled.div`
    margin-bottom: 0.5rem;
`;

export const StyledAlertContentWrapper = styled.div`
    .underline {
        text-decoration: underline;
    }
    display: flex;
    align-items: baseline;
`;

export const AlertsAnimatedContainer = styled.div<{ height: number }>`
    transition: 0.5s;
    height: ${(props) => `${props.height}px`};
    overflow: hidden;
    margin-top: ${({ theme }) => theme.tokens.BaseSpace80};
`;

export const AlertsAnimatedContent = styled.div`
    height: fit-content;
`;
