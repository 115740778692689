import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateTo } from '@makemydeal/dr-activities-common';

export const useNavigate = (path: string) => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(navigateTo(path));
    }, [dispatch, path]);
};
