import { FC, memo, useCallback } from 'react';

import { InterstateOnChangeCallback } from '@interstate/components/InterstateEvents';
import { SelectInput, SelectInputEventValue } from '@interstate/components/SelectInput';
import { TextInput } from '@interstate/components/TextInput';
import { Typography } from '@interstate/components/Typography';
import { ContainerGridItem, formatUtils } from '@makemydeal/dr-dash-components';
import { ManualIncentiveEditEntry } from '@makemydeal/dr-dash-store';
import { INCENTIVE_TYPE_VALUES } from '@makemydeal/dr-dash-types';

import * as deskingConstants from '../../constants';
import { IncentivesAmountField } from './RebatesAmountField.interstate';
import { ManualRebatesTypeDisplayContainer, TypeInputOrDisplayContainer } from './RebatesEdit.interstate.styles';
import { getErrorMessage } from './utils';

export interface RebatesTableInputProps {
    deleted: boolean;
    type: string | undefined;
    manual: boolean;
    displayType: string;
    incentiveLabel: string;
    fieldValue: number | string | undefined;
    fieldName: keyof ManualIncentiveEditEntry;
    required: boolean;
    hasCodeDuplicate: boolean;
    isFromProgramIncentives: boolean;
    incentiveIndex: number;
    haveIncentivesUpdated: boolean;
    handleInputChange: (
        textValue: string | number | undefined,
        fieldName: keyof ManualIncentiveEditEntry,
        incentiveIndex: number
    ) => void;
}

export const RebatesTableInput: FC<RebatesTableInputProps> = memo(function RebatesTableInput({
    required,
    deleted,
    type,
    displayType,
    manual,
    incentiveLabel,
    fieldValue,
    fieldName,
    hasCodeDuplicate,
    isFromProgramIncentives,
    incentiveIndex,
    haveIncentivesUpdated,
    handleInputChange
}) {
    const firstRow = incentiveIndex === 0;
    const onInputChange: InterstateOnChangeCallback<SelectInputEventValue> = useCallback(
        (event) => {
            const value = event.target.value;
            let textValue: string | number | undefined;

            switch (incentiveLabel) {
                case deskingConstants.CODE:
                    textValue = value !== '' ? formatUtils.convertToNumber(value as string) : '';
                    break;
                default:
                    textValue = value;
            }

            handleInputChange(textValue, fieldName, incentiveIndex);
        },
        [incentiveLabel, fieldName, incentiveIndex, handleInputChange]
    );

    if (incentiveLabel === deskingConstants.TYPE) {
        const selectOptions = [INCENTIVE_TYPE_VALUES.dealerCash, INCENTIVE_TYPE_VALUES.customerCash];
        const typeInputOrDisplay =
            manual && !isFromProgramIncentives ? (
                <>
                    <ContainerGridItem xs={12} md={firstRow ? 12 : 'hidden'}>
                        <Typography variant="label-md" color="base.color.gray.600" sx={{ marginBottom: '6px', display: 'inital' }}>
                            {incentiveLabel}
                        </Typography>
                    </ContainerGridItem>

                    <SelectInput
                        name={`type-${incentiveIndex}`}
                        data-testid={`type-${incentiveIndex}`}
                        onChange={onInputChange}
                        options={selectOptions}
                        size="medium"
                        displayLabel={false}
                        errorMessage={getErrorMessage(type)}
                        hasError={haveIncentivesUpdated && !type}
                        value={fieldValue as string}
                        required={required && !deleted}
                        disabled={deleted}
                        displayDeselectOption={false}
                    />
                </>
            ) : (
                <ManualRebatesTypeDisplayContainer>
                    <ContainerGridItem xs={12} md={firstRow ? 12 : 'hidden'}>
                        <div data-testid={`type-label-${incentiveIndex}`} className="type-display">
                            {incentiveLabel}
                        </div>
                    </ContainerGridItem>

                    <div className="type-value">{displayType}</div>
                </ManualRebatesTypeDisplayContainer>
            );
        return <TypeInputOrDisplayContainer>{typeInputOrDisplay}</TypeInputOrDisplayContainer>;
    } else {
        const errorDisplay =
            hasCodeDuplicate && incentiveLabel === deskingConstants.CODE ? deskingConstants.INCENTIVE_DUPLICATE_CODE : '';

        return (
            <div className={`text-input-${fieldName}`}>
                <ContainerGridItem xs={12} md={firstRow ? 12 : 'hidden'}>
                    <Typography variant="label-md" color="base.color.gray.600" sx={{ marginBottom: '6px', whiteSpace: 'nowrap' }}>
                        {incentiveLabel}
                    </Typography>
                </ContainerGridItem>

                {fieldName === 'amount' ? (
                    <IncentivesAmountField
                        fieldName={fieldName}
                        index={incentiveIndex}
                        handleInputChange={onInputChange}
                        errorDisplay={errorDisplay}
                        required={required}
                        deleted={deleted}
                        fieldValue={fieldValue as number}
                    />
                ) : (
                    <TextInput
                        displayLabel={false}
                        data-testid={`${fieldName}-${incentiveIndex}`}
                        name={`${fieldName}-${incentiveIndex}`}
                        onChange={onInputChange}
                        value={fieldValue?.toString()}
                        errorMessage={errorDisplay}
                        hasError={!!errorDisplay}
                        required={required}
                        disabled={deleted}
                        size="medium"
                    />
                )}
            </div>
        );
    }
});
