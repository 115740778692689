// TODO: use centralized component between here and activities-desking

// components
import { Grid } from '@interstate/components/Grid';

import LineItemWithChildren from '../common/LineItemWithChildren';

// constants
import * as constants from '../../constants';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// libraries
import type { DealHistory } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import DealSummaryItem from '../common/DealSummaryItem';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';
import { useSelector } from 'react-redux';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

export type MonthlyPaymentProps = {
    deal?: DealHistory;
    useWrapper?: boolean;
    hasDelta?: boolean;
};

const MonthlyPayment = ({ deal, useWrapper = true, hasDelta = false }: MonthlyPaymentProps) => {
    const useDealRootSelector = createDealRootSelector(deal);
    // monthly payment
    const monthlyPayment = useDealRootSelector(offerSelectors.getMonthlyPayment);
    // base monthly payment
    const baseMonthlyPayment = useDealRootSelector(offerReduxSelectors.getBaseMonthlyPayment);
    // depreciation
    const depreciation = useDealRootSelector(offerReduxSelectors.getTotalDepreciation);
    // rent charged
    const rentCharge = useDealRootSelector(offerReduxSelectors.getMonthlyRentCharged);
    // monthly use tax
    const monthlyUseTax = useDealRootSelector(offerReduxSelectors.getMonthlyUseTax);
    // monthly payments total
    const totalBasePayments = useDealRootSelector(offerReduxSelectors.getBaseMonthlyPaymentTotal);
    // total interest
    const totalInterest = useDealRootSelector(offerReduxSelectors.getTotalInterest);

    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);

    if (isEnhancedDealDetailsEnabled) return null;

    return (
        <DealSummaryItem label={constants.MONTHLY_PAYMENT} value={formatDollarsAndCents(monthlyPayment)} hasDelta={hasDelta}>
            <Grid>
                <LineItemWithChildren label={constants.BASE_MO_PAYMENT} value={formatDollarsAndCents(baseMonthlyPayment)} />
                <LineItemWithChildren label={constants.TOTAL_BASE_MO_PAYMENTS} value={formatDollarsAndCents(totalBasePayments)} />
                <LineItemWithChildren label={constants.DEPRECIATION} value={formatDollarsAndCents(depreciation)} />
                <LineItemWithChildren label={constants.RENT_CHARGED} value={formatDollarsAndCents(rentCharge)} />
                <LineItemWithChildren label={constants.TOTAL_INTEREST} value={formatDollarsAndCents(totalInterest)} />
                <LineItemWithChildren label={constants.MO_USE_TAX} value={formatDollarsAndCents(monthlyUseTax)} />
            </Grid>
        </DealSummaryItem>
    );
};

export default MonthlyPayment;
