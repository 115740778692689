// interfaces/types
import type { LeadFormInfoState, StateTree } from '@makemydeal/dr-dash-types';

// libraries
import { validator } from '@makemydeal/dr-platform-shared';
import { selectors } from '@makemydeal/dr-offer-redux';

// import type { StateTree, LeadFormInfoState } from '../types';

export const getLeadFormInfo = (state: StateTree): LeadFormInfoState => {
    return state.leadFormInfo || ({} as LeadFormInfoState);
};

export const isTestLead = (state: StateTree): boolean => {
    const shopperInfo = selectors.getShopperInfo(state);
    return validator.isTestLead(shopperInfo);
};

export const isOfferSubmitSuccess = (state: StateTree): boolean | undefined => true;
// TODO: DASH - shouldn't be necessary to implement this because we treat lead form as submitted 100% of time in CMD
// {
//     return getLeadFormInfo(state).offerSubmitSuccess;
// };

export const isOfferSubmitFailed = (state: StateTree): boolean | undefined => false;
// TODO: DASH - shouldn't be necessary to implement this because we treat lead form as submitted 100% of time in CMD
// {
//     return getLeadFormInfo(state).offerSubmitFailed;
// };

/**
 * Lead form is always submitted in CMD.
 */
export const isLeadFormSubmittedSuccess = (state: StateTree): boolean => true;
