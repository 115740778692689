// utils
import { cviToVehicle } from './cviToVehicle';

// types
import { IVehicle } from '@makemydeal/dr-platform-types';
import { CatalogVehicle } from '../types';

import { CommonOrgDealerVehicle } from '@makemydeal/dr-shared-ui-utils';

export const catalogToVehicle = (data: CommonOrgDealerVehicle & { marketingName?: string }): CatalogVehicle => {
    return {
        ...(cviToVehicle(data) as IVehicle),
        marketingName: data.marketingName
    };
};
