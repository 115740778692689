// libraries
import { IConfigObj, urlBuilder } from '@makemydeal/dr-common-utils';
import { IConfigTradeIn, Middleware as MiddlewareManager, Selectors, TRADE_IN } from '@makemydeal/dr-activities-tradein';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { historyInstance } from '@makemydeal/dr-shared-store';
import {
    configSelectors,
    dispatchActionWithAnalytics,
    featurePackageSelectors,
    offerReduxActionCreators
} from '@makemydeal/dr-dash-store';

// selectors
import { tradeShopperActivity } from '@makemydeal/dr-dash-store';

// consts/enums
import { APP_NAME as TRADEIN_NAME } from './constants';

// interfaces/types
import type { ServiceEndpointConfig } from '../../types/serviceEndpointTypes';

// config
import { getCurrentFlowName } from '../../flowMasterInterfaces';

// utils
import { relayExternalTrackPayload } from '../../common/services/analyticsRelay';

let tradeInSelectors: Selectors; // these won't be set until menu is configured

// allows for easier testing
export const getTradeInSelectors = (): Selectors => tradeInSelectors;

export const configureTradeIn = (config: IConfigTradeIn): MiddlewareManager => {
    // TODO: DASH - move some of this to an `init` function in the feature package (see how menu & incentives do this)
    // const { selectors, middlewareMgr } = init(APP_NAME, additionalSelectors, hostActions, config, MENU_ROUTE_ROOT);
    tradeInSelectors = new Selectors('tradeInComponent', tradeShopperActivity.additionalSelectors);
    const middlewareMgr = new MiddlewareManager(tradeInSelectors, config, TRADE_IN, dispatchActionWithAnalytics);
    featurePackageSelectors.addOrReplace(TRADEIN_NAME, tradeInSelectors);

    return middlewareMgr;
};

export const getConfigTradeFromState = (state: StateTree): IConfigTradeIn => {
    const gateway = configSelectors.getServicesGateway(state) as IConfigObj;
    const bff = configSelectors.getServicesBff(state);
    const staticImages = configSelectors.getConfigStaticImages(state) as IConfigObj;
    const kbbTradeInApiKey = configSelectors.getServicesKbb(state).tradeInApiKey || '';
    const tradeConfig: IConfigTradeIn = {
        services: {
            bff,
            gateway
        },
        staticImages,
        utils: {
            buildApiUrlWithServiceEndpoint: ({ service = 'bff', endPoint = 'init' }: ServiceEndpointConfig): string => {
                if (service !== 'bff') {
                    throw new Error('CMD buildApiUrlWithServiceEndpoint does not support services other than "bff"');
                }
                return urlBuilder.buildFromConfig(bff, endPoint);
            },
            getCurrentFlowName,
            getCurrentLocation: () => {
                const location = historyInstance.location;
                return location.pathname;
            }
        },
        // TODO: DASH - this appears to be other "host actions" - should move them under hostActions for consistency.
        offerActionCreators: {
            updatedShopper: offerReduxActionCreators.updatedShopper,
            updatedTradeIn: offerReduxActionCreators.updatedTradeIn
        },
        hostActions: {
            relayTradeInEvents: relayExternalTrackPayload
        },
        kbbTradeInApiKey,
        // NOTE: Since we are not using the old menu, we don't need to provide it to trade activity.
        renderMenuProducts: /* istanbul ignore next */ () => null
    };

    return tradeConfig;
};
