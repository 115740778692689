import { useState, FC, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ComboBox, ComboBoxOption } from '@interstate/components/ComboBox';
import { kebabCase } from '@makemydeal/dr-common-utils';
import {
    CUSTOM_MILEAGE_ERROR,
    CUSTOM_MILEAGE_LABEL,
    CUSTOM_MILEAGE_MAX_LENGTH,
    CUSTOM_MILEAGE_TESTID,
    MILES_LABEL
} from '../../constants';
import { offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { convertToNumber } from '../../utils/formatUtils';
import { buildComboOption, ComboBoxPairs } from './utils';
import { IPaymentTermMessage } from '@makemydeal/dr-platform-shared';
import { utils } from '@makemydeal/dr-offer-redux';

enum ComboActions {
    NO_ACTION,
    RESET_VALUE,
    ORIGIN_VALUE,
    SUBMIT_VALUE
}

const MATCH_MILES_INDEX = 2;
const valueMatcher = new RegExp(/^\s*(\d+)(\D+\w*|)$/);
const messageMatcher = new RegExp(
    /^(Exceeded Maximum Yearly Miles of|Ensure this value is less than or equal to)\D*(\d+)\.?$/,
    'i'
);

export const getMatchedValue = (selectedValue: string): string => {
    const matches = selectedValue?.match(valueMatcher);
    return matches?.length ? matches[1] : '';
};

export const getMessageMileage = (messages: IPaymentTermMessage[]): number => {
    let maxValue = CUSTOM_MILEAGE_MAX_LENGTH;
    if (Array.isArray(messages)) {
        messages.forEach((msg) => {
            if (typeof msg?.message !== 'string') {
                return;
            }
            const values = msg.message.match(messageMatcher);
            if (values != null && values[MATCH_MILES_INDEX] != null) {
                maxValue = parseInt(values[MATCH_MILES_INDEX], 10);
                return;
            }
        });
    }
    return maxValue;
};

const CustomMileageField: FC = () => {
    const dispatch = useDispatch();

    const mounted = useRef(false);
    const submitted = useRef(false);

    const isPaymentError = useSelector(offerReduxSelectors.getIsError);
    const isCalculatingPayment = useSelector(offerReduxSelectors.isCalculatingPayment);
    const paymentErrorMessages = useSelector(utils.getPaymentErrorMessages);
    const messagesFromCurrentTerm = useSelector(offerReduxSelectors.getMessagesFromCurrentTerm);

    const dataValue = useSelector(offerReduxSelectors.getAnnualMiles);
    const dataOptions = useSelector(offerReduxSelectors.getAnnualMilesOptions);

    const [maxValue, setMaxValue] = useState(CUSTOM_MILEAGE_MAX_LENGTH);
    const [showValue, setShowValue] = useState({ value: dataValue, action: ComboActions.NO_ACTION });

    const reducer = useCallback(
        (actionType: ComboActions): void => {
            switch (actionType) {
                case ComboActions.SUBMIT_VALUE:
                    setShowValue({ value: showValue.value, action: ComboActions.NO_ACTION });
                    dispatch(offerReduxActionCreators.selectedAnnualMiles(showValue.value));
                    break;

                case ComboActions.ORIGIN_VALUE:
                    setShowValue({ value: showValue.value, action: ComboActions.NO_ACTION });
                    break;

                case ComboActions.RESET_VALUE:
                    setShowValue({ value: dataValue, action: ComboActions.NO_ACTION });
                    break;

                default:
                    if (submitted.current) {
                        submitted.current = false;
                    }
                    break;
            }
        },
        [showValue.value, showValue.action]
    );

    const createOptions = useMemo((): ComboBoxOption[] => {
        return dataOptions?.map((item: ComboBoxPairs) => buildComboOption(item));
    }, [JSON.stringify(dataOptions)]);

    const currentOption = useCallback(
        (value: number): ComboBoxOption => buildComboOption({ id: value, name: `${value} ${MILES_LABEL}` }),
        []
    );

    const checkRange = useCallback(
        (selectedValue: number): boolean => {
            return Boolean(selectedValue && selectedValue <= maxValue);
        },
        [maxValue]
    );

    const isError = useMemo(() => {
        return !checkRange(showValue.value);
    }, [showValue.value, maxValue]);

    const comboBoxValue = useMemo(() => {
        return showValue.value != null ? [currentOption(showValue.value)] : undefined;
    }, [showValue.value, showValue.action]);

    useEffect(() => {
        if (!isCalculatingPayment) {
            const errors = isPaymentError ? paymentErrorMessages : messagesFromCurrentTerm;
            const value = getMessageMileage(errors);
            if (value) {
                setMaxValue(value);
            }
        }
    }, [isCalculatingPayment, isPaymentError, JSON.stringify(messagesFromCurrentTerm)]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }
        reducer(showValue.action);
    }, [showValue.action]);

    const handleChange = (event: any) => {
        const [selectedMileage] = event.target.value;

        if (selectedMileage == null || submitted.current) {
            return;
        }

        const strValue = getMatchedValue(selectedMileage.value);
        const numValue = convertToNumber(strValue);

        if (checkRange(numValue)) {
            if (numValue === showValue.value) {
                return setShowValue({ value: numValue, action: ComboActions.ORIGIN_VALUE });
            } else {
                submitted.current = true;
                return setShowValue({ value: numValue, action: ComboActions.SUBMIT_VALUE });
            }
        } else {
            return setShowValue({ value: numValue, action: ComboActions.ORIGIN_VALUE });
        }
    };

    const handleBlur = (event: any) => {
        // only happens when popup with + character entry clicked.
        /* istanbul ignore next */
        if (submitted.current) {
            return;
        }

        return setShowValue({ value: 0, action: ComboActions.RESET_VALUE });
    };

    return (
        <ComboBox
            id={kebabCase(CUSTOM_MILEAGE_LABEL)}
            label={CUSTOM_MILEAGE_LABEL}
            value={comboBoxValue}
            options={createOptions}
            onBlur={handleBlur}
            onChange={handleChange}
            allowNewEntry={true}
            hasError={isError}
            errorMessage={CUSTOM_MILEAGE_ERROR + maxValue}
            displayNoDataMessage={false}
            data-testid={kebabCase(CUSTOM_MILEAGE_TESTID)}
        />
    );
};

export default CustomMileageField;
