// externals
import { Reducer } from 'redux';

// libraries
import { PushToRouteOneStatus, type PushToRouteOneState } from '@makemydeal/dr-dash-types';
import {
    UPDATE_PUSH_TO_ROUTEONE,
    PUSH_TO_ROUTEONE_SUCCESS,
    PUSH_TO_ROUTEONE_FAILURE,
    CLEAR_PUSH_TO_ROUTEONE_CACHE
} from '../actionTypes/pushToRouteOneActionTypes';
import { initActionTypes } from '@makemydeal/dr-shared-store';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export const pushToRouteOneInitialState: PushToRouteOneState = {
    timestamp: '',
    dealXgVersion: '',
    bffResult: { message: '', ok: false },
    wsResult: { message: '', ok: false },
    status: PushToRouteOneStatus.Ready
};

export type PushToRouteOneReducer = Reducer<PushToRouteOneState>;

export const reducer: PushToRouteOneReducer = (state: PushToRouteOneState = pushToRouteOneInitialState, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            const { pushToRouteOne } = action.payload;
            return { ...state, ...pushToRouteOne };
        }
        case UPDATE_PUSH_TO_ROUTEONE: {
            const { timestamp, dealXgVersion, message, ok, status } = action.payload;
            return {
                ...state,
                timestamp,
                dealXgVersion,
                wsResult: { message: message, ok: ok },
                status
            };
        }
        case PUSH_TO_ROUTEONE_SUCCESS: {
            const { message, ok } = action.payload;
            return { ...state, bffResult: { message: message, ok: ok }, status: PushToRouteOneStatus.Waiting };
        }
        case PUSH_TO_ROUTEONE_FAILURE: {
            const { message, ok } = action.payload;
            return { ...state, bffResult: { message: message, ok: ok }, status: PushToRouteOneStatus.Failure };
        }
        case CLEAR_PUSH_TO_ROUTEONE_CACHE: {
            return {
                ...state,
                bffResult: { message: '', ok: false },
                wsResult: { message: '', ok: false },
                status: PushToRouteOneStatus.Ready
            };
        }
        default: {
            return state;
        }
    }
};
