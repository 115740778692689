// externals
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';

// interfaces/types
import type { Accessory } from '@makemydeal/dr-dash-types';

type AccessoryInputsValidity = { [fieldName: string]: AccessoryValidationResult };
type AccessoryValidationResult = 'valid' | 'invalid' | 'invalidSuppressed';

export const useAccessoryActions = (initialAccessories: Accessory[]) => {
    const [accessories, setAccessories] = useState(() => cloneDeep(initialAccessories));
    const [hasChanges, setHasChanges] = useState(false);
    const [accessoriesValidity, setAccessoriesValidity] = useState<AccessoryInputsValidity[]>([]);
    const [hasValidationErrors, setHasValidationErrors] = useState(false);
    const [hasVisibleValidationErrors, setHasVisibleValidationErrors] = useState(false);

    useEffect(() => {
        const availableAccessoriesValidity = accessoriesValidity.filter(
            (fieldsValidity, index) => fieldsValidity && accessories[index] && !accessories[index].deleted
        );
        const validationResults = availableAccessoriesValidity.flatMap((accFields) => Object.values(accFields));
        const hasErrors = validationResults.includes('invalid');
        const hasSuppressedErrors = validationResults.includes('invalidSuppressed');

        setHasValidationErrors(hasErrors || hasSuppressedErrors);
        setHasVisibleValidationErrors(hasErrors);
    }, [accessoriesValidity, accessories]);

    const updateAccessoryValidity = (index: number, fieldName: string, isValid: boolean, suppressAlerts: boolean) => {
        setAccessoriesValidity((previousValidityState) => {
            const updatedValidity = [...previousValidityState];
            updatedValidity[index] = updatedValidity[index] ?? {};
            updatedValidity[index][fieldName] = isValid ? 'valid' : suppressAlerts ? 'invalidSuppressed' : 'invalid';
            return updatedValidity;
        });
    };

    const updateAccessory = (index: number, fieldName: string, value: string | number | boolean) => {
        const updatedAccessories = [...accessories] as Accessory[];
        updatedAccessories[index][fieldName] = value;
        setAccessories(updatedAccessories);
        setHasChanges(true);
    };

    const addAccessory = (newAccessory: Accessory) => {
        setAccessories([...accessories, newAccessory]);
        setHasChanges(true);
    };

    return {
        accessories,
        updateAccessory,
        addAccessory,
        setAccessories,
        hasChanges,
        setHasChanges,
        updateAccessoryValidity,
        hasValidationErrors,
        hasVisibleValidationErrors
    };
};
