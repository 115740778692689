import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { programsSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';

export const FooterInformation = () => {
    const currentConditionalValue = useSelector(programsSelectors.sumConditionalAppliedIncentives);
    return (
        <span>
            Total Selected: <strong>{formatDollarsAndCents(currentConditionalValue)}</strong>
        </span>
    );
};
