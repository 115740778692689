import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import { IAccessoriesCatalogActivity } from '../types/IAccessoriesCatalogActivity';
import { getAllRoutes } from '../utils/routes';
import AccessoriesCatalogActivity from './AccessoriesCatalogActivity';
import { ActivityRouteContainer } from './AccessoriesCatalogActivity.style';

const Root = (props: IAccessoriesCatalogActivity) => {
    const { root } = getAllRoutes(props.baseRoute);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    return (
        <ActivityRouteContainer
            data-testid="activities-accessories-catalog-container"
            className="activities-accessories-catalog"
            isNewAppWidthEnabled={isABPencilEnabled}
        >
            <Route path={root} component={AccessoriesCatalogActivity} />
        </ActivityRouteContainer>
    );
};

Root.displayName = 'AccessoriesCatalogActivity';

export default Root;
