// state
import type { StateTree } from '@makemydeal/dr-dash-types';
import type { ConnectionState } from '../reducers/connectionReducer';

export const getConnectionState = (state: StateTree): ConnectionState => {
    return state.connection || ({} as ConnectionState);
};

export const getConnectionId = (state: StateTree): string => {
    const connection = getConnectionState(state);
    return connection.id || '';
};
