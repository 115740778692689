import { Grid } from '@interstate/components/Grid';
import { SlideOut } from '@interstate/components/SlideOut';
import { useContext } from 'react';
import { useLocationChange } from '../common/useLocationChange';
import DealActivity from '../dealActivity/DealActivity.interstate';
import { NavDrawerContext } from './NavDrawerContext';

const NavigationDrawerInterstate = () => {
    const { drawerOpen, setDrawerOpen, toggleDrawer } = useContext(NavDrawerContext);

    useLocationChange(() => {
        setDrawerOpen(false);
    });

    return (
        <SlideOut
            className="interstate"
            show={drawerOpen}
            onHide={toggleDrawer}
            position="left"
            panelWidth="392px"
            sx={{
                '#ids-SlideOut-content': {
                    paddingBottom: '80px'
                }
            }}
            header={
                // TODO: US1183743 - NavDrawer Typography
                <h3
                    style={{
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '120%',
                        textTransform: 'capitalize',
                        color: '#000'
                    }}
                >
                    Deal Progress
                </h3>
            }
        >
            <Grid container flexDirection="column" gap="8px">
                <DealActivity />
            </Grid>
        </SlideOut>
    );
};

export default NavigationDrawerInterstate;
