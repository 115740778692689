import { FC, PropsWithChildren, useState } from 'react';
import { ExpandableAreaContext } from './Context';

/**
 * Establish a collapsible area provider track collapsible state remotely. This should exits at the closest DOM level possible to the CollapsibleArea and CollapsibleExpandableAreaSection. If you are using multiple collapsibles in the same area, use a single provider and specify differentiating collapsibleId's to tie them together.
 */
export const ExpandableAreaProvider: FC<PropsWithChildren> = ({ children }) => {
    const [toggleByKey, setToggleByKey] = useState<Record<string, boolean>>({ main: false });

    const isExpanded = (expandableId: string = 'main') => {
        return toggleByKey[expandableId] ? true : false;
    };
    const toggle = (expandableId: string = 'main', value?: boolean) => {
        setToggleByKey((old) => {
            const updated = { ...old };
            if (value != null) {
                updated[expandableId] = value;
                return updated;
            }

            updated[expandableId] = updated[expandableId] ? false : true; // flip logic; also assumes value might be undefined.
            return updated;
        });
    };
    return (
        <ExpandableAreaContext.Provider
            value={{
                toggle,
                isExpanded
            }}
        >
            {children}
        </ExpandableAreaContext.Provider>
    );
};
