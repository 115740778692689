// hooks
import { useSelector } from 'react-redux';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealState, StateTree } from '@makemydeal/dr-dash-types';
import { dealEffectiveSelectors } from '@makemydeal/dr-dash-store';

// consts
import * as constants from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

const MSRP = ({ deal }: DealState) => {
    const retailPrice = useSelector((state: StateTree) => dealEffectiveSelectors.selectEffectiveRetailPrice(state, deal));
    const msrp = formatDollarsAndCents(retailPrice);

    return <DealSummaryItem label={constants.MSRP} value={msrp} />;
};

export default MSRP;
