import { Grid } from '@interstate/components/Grid';
import styled from 'styled-components';

export const InterstateOfferCardsContainer = styled(Grid)`
    overflow-x: hidden;

    & div.offer-card {
        margin: 0;
    }
`;

export const LoadingIndicatorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 21.05rem;
    @media (max-width: 1024px) {
        min-height: 20.45rem;
    }
`;

export const GroupContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const DealLifeCycleContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
`;

export const DealPulseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 18.8rem;

    margin: 0 !important;
`;

export const DealScenariosSeparator = styled.div`
    border-right: ${({ theme }) => `1px solid ${theme.tokens.BaseColorGray300}`};
    margin: 0 0.8rem;
`;
