import { Typography } from '@interstate/components/Typography';
import { Modal } from '@interstate/components/Modal';
import { useMediaQuery } from 'react-responsive';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export type ChangeVehicleConfirmModalProps = {
    show: boolean;
    onCancel: () => void;
    onProceed: () => void;
};

export const ChangeVehicleConfirmModal: React.FC<ChangeVehicleConfirmModalProps> = ({ show, onCancel, onProceed }) => {
    const isLargeScreenSize = useMediaQuery({ query: `(min-width: ${BreakPoint.SMALL})` });
    return (
        <Modal
            size={isLargeScreenSize ? 'large' : 'small'}
            id="change-vehicle-modal"
            data-testid="change-vehicle-modal"
            show={show}
            header={
                <Typography variant="h3" color="base.color.blue.700">
                    Confirmation
                </Typography>
            }
            footer={{
                primary: {
                    buttonStyle: 'primary',
                    action: () => onProceed(),
                    label: 'Proceed'
                },
                options: [
                    {
                        buttonStyle: 'tertiary',
                        action: () => onCancel(),
                        label: 'Cancel'
                    }
                ]
            }}
            onHide={() => onCancel()}
        >
            Entered values for finance type, term, down payment, trade-in vehicle, and value will persist. You will need to reselect
            items like vehicle protection products, incentives and accessories.
        </Modal>
    );
};
