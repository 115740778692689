// externals
import { CSSProperties } from 'react';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// styles
import { StyledLabel } from './LabelWithToolTipIcon.style';

// components
import IconWithToolTip from './IconWithToolTip';

type LabelWithToolTipIconProps = {
    name: string;
    title: string;
    toolTipContent?: string;
    style?: CSSProperties;
    dataTestId?: string;
};

const LabelWithToolTipIcon = ({ toolTipContent, name, title, style, dataTestId }: LabelWithToolTipIconProps) => {
    const theme = useInterstateTheme();

    let optionalTooltip: JSX.Element | null = null;

    if (toolTipContent) {
        optionalTooltip = <IconWithToolTip name={name} toolTipContent={toolTipContent} />;
    }

    return (
        <StyledLabel htmlFor={name} theme={theme} style={style} data-testid={dataTestId || ''}>
            {title}
            {optionalTooltip}
        </StyledLabel>
    );
};

export default LabelWithToolTipIcon;
