import { nanoid } from 'nanoid';
import { AnyAction, Dispatch } from 'redux';
import {
    SCOPES_CHANGE_DEFAULT_SCOPE,
    SCOPES_CLONE_SCOPE,
    SCOPES_SET_NAVIGATION_SCOPE_TARGET,
    SCOPES_DELETE_SCOPE,
    SCOPES_CREATE_UNINITIALIZED_SCOPES_FROM_ORDER,
    SCOPES_INITIALIZE_SCOPES
} from '../actionTypes/scopedActionTypes';
import { ThunkAction } from 'redux-thunk';
import { ScopedState } from '../types';
import { getCurrentScopeId, getInitializedScopesOrder, getPrimaryScopeId, getScopesOrder } from '../selectors/scopedStateSelectors';
import { createScopedAction } from './scopedActions';

export const setNavigationScopeTarget = (scopeIdOverride?: string) => ({
    type: SCOPES_SET_NAVIGATION_SCOPE_TARGET,
    payload: {
        scopeIdOverride
    }
});

export const changeDefaultScope = (scopeId: string) => ({
    type: SCOPES_CHANGE_DEFAULT_SCOPE,
    payload: {
        newDefaultScope: scopeId
    }
});

export const cloneScope = (scopeToClone: string, metadata: any = {}) => ({
    type: SCOPES_CLONE_SCOPE,
    payload: {
        newScopeId: nanoid(),
        scopeToClone,
        metadata
    }
});

export const deleteScope = (scopeToDelete: string, metadata: any = {}) => ({
    type: SCOPES_DELETE_SCOPE,
    payload: {
        scopeToDelete,
        metadata
    }
});

/**
 * Creates Thunk action that will dispatch SCOPES_DELETE_SCOPE for every non-primary scope.
 */
export const deleteNonPrimaryScopes = () => (dispatch: Dispatch<any, any>, getState: () => ScopedState) => {
    const state = getState();
    const scopes = getScopesOrder(state);

    const primaryScopeId = getPrimaryScopeId(state);

    scopes.forEach((scopeId) => {
        if (scopeId !== primaryScopeId) {
            dispatch(deleteScope(scopeId));
        }
    });
};

/** Sets the initialized metadata flag to true for each passed scopeId */
export const initializeScopes = (scopeIds: string[]) => ({
    type: SCOPES_INITIALIZE_SCOPES,
    payload: scopeIds
});

export const createUninitializedScopesFromOrder = (order: string[]) => ({
    type: SCOPES_CREATE_UNINITIALIZED_SCOPES_FROM_ORDER,
    payload: order
});

export type DispatchOnEveryScopeOptions = {
    includeUninitialized?: boolean;
    /**
     * Does not dispatch the action for the scope in context.
     */
    ignoreSelf?: boolean;
};

/**
 * Dispatches given action once for each Active Scope.
 */
export const dispatchOnEveryScope = (
    action: AnyAction | ThunkAction<any, any, any>,
    { ignoreSelf, includeUninitialized }: DispatchOnEveryScopeOptions = {}
) => {
    return (dispatch: Dispatch<any, any>, getState: () => ScopedState) => {
        const state = getState();
        const scopes = includeUninitialized ? getScopesOrder(state) : getInitializedScopesOrder(state);

        const currentScopeId = getCurrentScopeId(state);

        scopes.forEach((scopeId) => {
            if (ignoreSelf && scopeId === currentScopeId) {
                return;
            }
            dispatch(createScopedAction(scopeId, action));
        });
    };
};
