// types
import { SurchargeOverrides } from '@makemydeal/dr-shared-types';

// constants
import { StateAbbreviations } from '../components/editDealAttributesDrawer/dealAttributesForm/constants/stateAbbreviations';

export enum VppDealAttributeInputType {
    CHECKBOX = 'checkbox',
    DATEPICKER = 'datepicker',
    DROPDOWN = 'dropdown',
    INFO = 'info',
    INFO_TOP = 'infoTop'
}

export type VppDealAttributeName = keyof SurchargeOverrides;

export interface VppDealAttributeBasicInput {
    allowUnselectOption?: boolean;
    description?: string;
    fullVppPath: string;
    inputType: VppDealAttributeInputType;
    isPrice?: boolean;
    name: VppDealAttributeName;
    options?: VppDealAttributeSelectOption[];
    order: number;
    required?: boolean;
    title: string;
    value?: any;
    displayValue?: string | number;
}

export interface VppDealAttributeDateInput extends VppDealAttributeBasicInput {
    inputType: VppDealAttributeInputType.DATEPICKER;
    minDate?: Date;
    maxDate?: Date;
    errorMessage?: string;
}

export type VppDealAttributeInput = VppDealAttributeBasicInput | VppDealAttributeDateInput;

export type VppDealAttributeSelectOption = {
    value: string;
    label: string;
};

export type DealAttributeItemProps = {
    dealAttributeInput: VppDealAttributeInput;
    onChangeValue: (value: unknown, withError?: boolean) => void;
};

export type VppDealAttributesInputsSettings = Record<keyof SurchargeOverrides, VppDealAttributeInput>;

export type StateAbbreviation = (typeof StateAbbreviations)[number]['value'];
