import { memo } from 'react';
import { useSelector } from 'react-redux';

import { LoadingSection } from '../loadingSection';
import { paymentSelectors } from '@makemydeal/dr-dash-store';

export interface IPaymentLoadingProps {
    testId: string;
}

const PaymentLoading = ({ testId }: IPaymentLoadingProps) => {
    const isPaymentCalculating = useSelector(paymentSelectors.isCalculatingPayment);
    return <LoadingSection loading={isPaymentCalculating} testId={testId} />;
};

export default memo(PaymentLoading);
