import { FC } from 'react';
import { useSelector } from 'react-redux';

import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import ActionsInterstate from './Actions.interstate';
import { ActionsContainer, CutOffShadowContainer, FooterContainer } from './Footer.style.interstate';

export const FooterInterstate: FC = () => {
    const theme = useInterstateTheme();
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    return (
        <FooterContainer className="text-center" data-testid="footer-actions-container">
            <CutOffShadowContainer isNewAppWidthEnabled={isABPencilEnabled}>
                <ActionsContainer theme={theme} isNewAppWidthEnabled={isABPencilEnabled}>
                    <ActionsInterstate></ActionsInterstate>
                </ActionsContainer>
            </CutOffShadowContainer>
        </FooterContainer>
    );
};

export default FooterInterstate;
