// hooks
import { useSelector } from 'react-redux';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealHistory, StateTree } from '@makemydeal/dr-dash-types';
import { dealEffectiveSelectors } from '@makemydeal/dr-dash-store';

// consts
import * as constants from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

const Discount = ({
    deal,
    hasDelta = false,
    showNegativeDiscount = false
}: {
    deal?: DealHistory;
    hasDelta?: boolean;
    showNegativeDiscount?: boolean;
}) => {
    let discount = useSelector((state: StateTree) => dealEffectiveSelectors.getEffectiveDiscount(state, deal));

    let label = constants.DISCOUNT;
    if (!showNegativeDiscount && discount < 0) {
        discount = -discount;
        label = constants.MARKUP;
    }

    const value = formatDollarsAndCents(discount);

    return <DealSummaryItem label={label} value={value} hasDelta={hasDelta} />;
};

export default Discount;
