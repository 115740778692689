// externals
import { FC, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

// libraries
import { hostUrlHelper, locHrefUtil, sessionUtils, HostEnvironment } from '@makemydeal/dr-shared-ui-utils';
import { bridgeUserActionCreators } from '@makemydeal/dr-shared-store';

const solutionGroupCode = 'CMDMMD';

/**
 * Renders the actual Cox Bridge Bar DOM
 */

export type CoxBridgeBarProps = {
    appPrefix: string;
};

export const CoxBridgeBar: FC<CoxBridgeBarProps> = (props) => {
    const dispatch = useDispatch();
    const refDom = useRef<HTMLDivElement>(null);
    const environment = hostUrlHelper.getHostUrlEnvironment(props.appPrefix, locHrefUtil.getLocHref());
    const sessionId = sessionUtils.getAppSessionId(); // app session for cache busting with bridge bar
    const bridgeBarEnv = useMemo(() => (environment === HostEnvironment.Prod ? 'prod' : 'qa'), [environment]);

    useEffect(() => {
        const bbEl = refDom.current as HTMLDivElement;

        // Keeping this here as closure instance
        const handler = function () {
            (bbEl as any).bb.render({
                env: bridgeBarEnv,
                solutionGroupCode,
                sessionId
            });
        };
        // Rendering DOM and attaching event first
        bbEl.addEventListener('initialized', handler);

        // Load CBB second (when init event handler is in place)
        const script = document.createElement('script');
        script.src = 'https://assets.bridge.coxautoservices.com/bridge-bar.js';
        script.async = true;
        document.body.appendChild(script);
        dispatch(bridgeUserActionCreators.fetchBridgeUserInfo(bridgeBarEnv, sessionId));

        // Cleanup; remove the evnet handler before dispose
        return () => {
            bbEl.removeEventListener('initialized', handler);
            document.body.removeChild(script);
        };
    }, []);

    // According to CBB docs, this can be rendered with an element ID instead of the custom XML tag `<bridge-bar>`. This is easier to work with in TSX.
    return <div id="bridge-bar" data-testid="bridge-bar" ref={refDom}></div>;
};
