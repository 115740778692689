// externals
import React, { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';

// library
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { Typography } from '@interstate/components/Typography';

// styles
import { Grid } from '@interstate/components/Grid';
import { PopoverRow } from './SliderContainer.style';

export default function ProfitInfo({ className }: HTMLAttributes<HTMLDivElement>) {
    const profit = useSelector(offerReduxSelectors.getSelectedPaymentProfitDetails);
    const defaultProfit = '$0.00';
    const frontEndProfit = formatDollarsAndCents(profit?.frontEndProfit, defaultProfit);
    const backEndProfit = formatDollarsAndCents(profit?.backEndProfit, defaultProfit);
    const reserveProfit = formatDollarsAndCents(profit?.reserveProfit, defaultProfit);
    const totalProfit = formatDollarsAndCents(profit?.totalProfit, defaultProfit);

    return (
        <Grid
            className={className || ''}
            container
            flexDirection={'column'}
            sx={{
                width: '248px',
                gap: '8px',
                paddingTop: '16px'
            }}
        >
            <PopoverRow data-testid="front-end-profit-value">
                <Typography tag="span" variant="label-md" color="sem.color.on-surface.muted">
                    Front End
                </Typography>
                <Typography tag="span" variant="body-md" color="sem.color.on-surface.default">
                    {frontEndProfit}
                </Typography>
            </PopoverRow>
            <PopoverRow data-testid="back-end-profit-value">
                <Typography tag="span" variant="label-md" color="sem.color.on-surface.muted">
                    Back End
                </Typography>
                <Typography tag="span" variant="body-md" color="sem.color.on-surface.default">
                    {backEndProfit}
                </Typography>
            </PopoverRow>
            <PopoverRow data-testid="reserve-profit-value">
                <Typography tag="span" variant="label-md" color="sem.color.on-surface.muted">
                    Reserve
                </Typography>
                <Typography tag="span" variant="body-md" color="sem.color.on-surface.default">
                    {reserveProfit}
                </Typography>
            </PopoverRow>
            <PopoverRow data-testid="total-profit-value">
                <Typography tag="span" variant="label-md" color="sem.color.on-surface.muted">
                    Total
                </Typography>
                <Typography tag="span" variant="body-md" color="sem.color.on-surface.default">
                    {totalProfit}
                </Typography>
            </PopoverRow>
        </Grid>
    );
}
