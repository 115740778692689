// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import type { DashMiddleware, DashNext, DashStore } from '@makemydeal/dr-shared-store';

// consts/enums
import { VAUTO_APPRAISALS_SUCCESS } from '../actionTypes/vAutoActionTypes';

// utils
import { getCurrentAppraisalIdFromAppraisals } from '../utils/vAutoUtils';

// actions
import { getVAutoTriforceData } from '../actions/vAutoTriforceActionCreators';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    if (action.type === VAUTO_APPRAISALS_SUCCESS) {
        const appraisalId = getCurrentAppraisalIdFromAppraisals(action.payload.data);

        store.dispatch(getVAutoTriforceData(appraisalId));
    }

    return next(action);
};
