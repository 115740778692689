import { Rate, RatedProtectionProduct } from '@makemydeal/dr-platform-types';
import { DraftDealProduct } from '@makemydeal/dr-dash-types';
import { formatNumber, formatDollarsAndCents, isValidNumber } from '@makemydeal/dr-common-utils';
import { UNLIMITED_MILEAGE_LABEL } from '../constants';

export const joinDeductibleValueAndMethod = (deductibleValue?: number, deductibleMethod?: string) => {
    if (!isValidNumber(deductibleValue as number)) {
        return deductibleMethod || '';
    }

    const formattedNumericValue = formatDollarsAndCents(deductibleValue);

    return deductibleMethod ? `${formattedNumericValue} ${deductibleMethod}` : formattedNumericValue;
};

export const parseDeductible = (
    deductibleString: string
): { deductibleValue: number | undefined; deductibleApplyType: string | undefined } => {
    let deductibleValue, deductibleApplyType;

    try {
        ({ deductibleValue, deductibleApplyType } = JSON.parse(deductibleString));
    } catch (error) {}

    return { deductibleValue, deductibleApplyType };
};

export const getDeductibleValue = (deductibleValue: number | undefined, deductibleApplyType: string | undefined): string => {
    return JSON.stringify({ deductibleValue, deductibleApplyType });
};

export const hasCorrectPlan = (
    rates: Rate[],
    rateAttributes: RatedProtectionProduct['rateAttributes'],
    productProviderPlan: string | undefined
) => !rateAttributes.includes('productProviderPlan') || rates.some((rate) => rate.productProviderPlan === productProviderPlan);

export const hasCorrectTerm = (
    rates: Rate[],
    rateAttributes: RatedProtectionProduct['rateAttributes'],
    productCoverageLength: number | undefined
) =>
    !rateAttributes.includes('productCoverageLength') || rates.some((rate) => rate.productCoverageLength === productCoverageLength);

export const hasCorrectMiles = (
    rates: Rate[],
    rateAttributes: RatedProtectionProduct['rateAttributes'],
    productCoverageMiles: number | undefined
) => !rateAttributes.includes('productCoverageMiles') || rates.some((rate) => rate.productCoverageMiles === productCoverageMiles);

export const hasCorrectDeductible = (
    rates: Rate[],
    rateAttributes: RatedProtectionProduct['rateAttributes'],
    productDeductible: number | undefined,
    productDeductibleMethod: string | undefined
) =>
    !rateAttributes.includes('productDeductible') ||
    rates.some(
        (rate) =>
            joinDeductibleValueAndMethod(rate.productDeductible, rate.deductibleApplyType) ===
            joinDeductibleValueAndMethod(productDeductible, productDeductibleMethod)
    );

export const hasCorrectServiceInterval = (
    rates: Rate[],
    rateAttributes: RatedProtectionProduct['rateAttributes'],
    productServiceInterval: string | undefined
) =>
    !rateAttributes.includes('productServiceInterval') ||
    rates.some((rate) => rate.productServiceInterval === productServiceInterval);

export const isUnlimitedMileage = (mileage?: number) => !mileage || mileage <= 0 || mileage >= 999_999;

/**
 * Silly little utility to selectively check or skip checking a plan
 * while the feature toggle for plan selection could be on or off.
 */
const comparePlan = (planA: string | undefined, planB: string | undefined, isPlanSelectionEnabled: boolean) => {
    if (isPlanSelectionEnabled) {
        return planA === planB;
    }
    return true;
};

export const getTermsOptions = (rates: Rate[], productPlan: string | undefined, isPlanSelectionEnabled: boolean) => {
    const ratesOfSamePlan = isPlanSelectionEnabled ? rates.filter((rate) => rate.productProviderPlan === productPlan) : rates;
    return Array.from(new Set(ratesOfSamePlan.map((rate) => rate.productCoverageLength)), (term) => ({
        value: formatNumber(term as number),
        label: formatNumber(term as number)
    }));
};

export const getMilesOptions = (rates: Rate[], product: DraftDealProduct, isPlanSelectionEnabled: boolean) => {
    return Array.from(
        rates.reduce((acc, rate) => {
            if (
                rate.productCoverageMiles !== undefined &&
                comparePlan(rate.productProviderPlan, product.productProviderPlan, isPlanSelectionEnabled) &&
                rate.productCoverageLength === product.productCoverageLength
            ) {
                acc.add(rate.productCoverageMiles);
            }
            return acc;
        }, new Set<number>())
    ).map((mile) => ({
        value: formatNumber(mile),
        label: isUnlimitedMileage(mile) ? UNLIMITED_MILEAGE_LABEL : formatNumber(mile)
    }));
};

export const getDeductibleOptions = (rates: Rate[], product: DraftDealProduct, isPlanSelectionEnabled: boolean) => {
    type DeductibleInfo = { productDeductible?: number; deductibleApplyType?: string };

    const deductibleSet = new Set<string>();

    const deductibleOptions: DeductibleInfo[] = rates.reduce((acc, rate) => {
        const isDeductibleDefined = rate.productDeductible !== undefined || rate.deductibleApplyType !== undefined;

        const isProductCoverageLengthDefinedAndSame =
            rate.productCoverageLength !== undefined && rate.productCoverageLength === product.productCoverageLength;

        const isProductCoverageMilesDefinedAndSame =
            rate.productCoverageMiles !== undefined && rate.productCoverageMiles === product.productCoverageMiles;

        if (
            isDeductibleDefined &&
            isProductCoverageLengthDefinedAndSame &&
            isProductCoverageMilesDefinedAndSame &&
            comparePlan(rate.productProviderPlan, product.productProviderPlan, isPlanSelectionEnabled)
        ) {
            const { productDeductible, deductibleApplyType } = rate;
            const key = `${productDeductible}_${deductibleApplyType}`;

            if (!deductibleSet.has(key)) {
                deductibleSet.add(key);
                acc.push({ productDeductible, deductibleApplyType });
            }
        }
        return acc;
    }, [] as DeductibleInfo[]);

    return deductibleOptions.map(({ productDeductible, deductibleApplyType }) => {
        const formattedValue = joinDeductibleValueAndMethod(productDeductible, deductibleApplyType);

        return {
            value: getDeductibleValue(productDeductible, deductibleApplyType),
            label: formattedValue
        };
    });
};

export const getIntervalOptions = (rates: Rate[], product: DraftDealProduct, isPlanSelectionEnabled: boolean) => {
    return Array.from(
        rates.reduce((acc, rate) => {
            if (
                rate.productServiceInterval !== undefined &&
                comparePlan(rate.productProviderPlan, product.productProviderPlan, isPlanSelectionEnabled) &&
                rate.productCoverageLength === product.productCoverageLength &&
                rate.productCoverageMiles === product.productCoverageMiles &&
                rate.productDeductible === product.productDeductible
            ) {
                acc.add(rate.productServiceInterval);
            }
            return acc;
        }, new Set<string>())
    ).map((interval) => ({ value: interval, label: interval }));
};

export const findRate = (rates: Rate[], attributes: Partial<Rate>): Rate | undefined => {
    const attributeKeys = Object.keys(attributes) as (keyof Rate)[];
    return rates.find((rate) => attributeKeys.every((key) => rate[key] === attributes[key]));
};
