import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import { getAllRoutes } from '../utils/routes';
import { StyledProgramActivity } from './ProgramActivity.style';
import { ProgramsAndIncentives } from './ProgramsAndIncentives';

export type ProgramsActivityProps = {
    baseRoute: string;
};

export const Root: FC<ProgramsActivityProps> = ({ baseRoute }) => {
    const { root } = getAllRoutes(baseRoute);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    return (
        <StyledProgramActivity
            data-testid="activities-programs-container"
            className="activities-programs"
            isNewAppWidthEnabled={isABPencilEnabled}
        >
            <Route path={root} component={ProgramsAndIncentives} />
        </StyledProgramActivity>
    );
};

export default Root;
