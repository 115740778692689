import { FC } from 'react';
import { Typography } from '@interstate/components/Typography';
import { InterstateColorTokens } from '@interstate/components/InterstateThemeProvider/Types/InterstateColorTokens';

export const LoadingIndicator: FC<{
    message?: string;
    color?: InterstateColorTokens;
    size?: 'small' | 'large';
    sx?: any;
}> = ({ message, size = 'small', color, sx }) => {
    return (
        <Typography
            color={color}
            variant={size === 'small' ? 'body-md' : 'h1'}
            tag="span"
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                alignItems: 'center',
                ...(sx ?? {})
            }}
        >
            {message}
        </Typography>
    );
};
