// interfaces/types
import type { RatedProtectionProduct, Rate } from '@makemydeal/dr-platform-types';
import type { DealProduct, DealProductAddOn, SurchargeOverrides } from '@makemydeal/dr-shared-types';

export const FIE_AUTHORIZATION_TOKEN_HEADER = 'X-FIE-Authorization';

export type DraftDealProduct = Partial<DealProduct>;

export type ProductWithProductCode = { productCode: string };

export type DealProductWithProductCode = DealProduct & ProductWithProductCode;

export type DealProductSummary = {
    totalProductPrice: number;
    totalProductMarkup: number;
    totalProductCost: number;
    productMonthlyPayment: number;
};

export type RatedProduct = RatedProtectionProduct;

export type RatedProductRates = Rate;

export enum DcVppVehicleUsage {
    PERSONAL = 'Personal',
    BUSINESS = 'Business',
    AGRICULTURAL = 'Agricultural'
}

export type SurchargeProduct = {
    productCode: string;
    productCategoryCode: string;
};

export type VppSurcharge = {
    code: string;
    surchargeDescription: string | null;
    resolverLogicDescription: string | null;
    resolvingFields: string[] | null;
    resolvedValue: string | boolean | number | null;
    isDefaultValue: boolean | null;
    supplementalForProducts: SurchargeProduct[];
    optionalForProducts: SurchargeProduct[];
};

export type VehicleProtectionState = {
    products: DraftDealProduct[];
    modifiedProducts: string[];
    dealXgProducts: DraftDealProduct[];
    dealerProducts: Partial<DealerProduct>[];
    rates: RatedProduct[];
    rateToken: string;
    isFetchingProducts: boolean;
    isFetchingRates: boolean;
    isPaymentFailed: boolean;
    isRatingFailed: boolean;
    isRatingTimeout: boolean;
    isFetchRatesRetried: boolean;
    lastFailureAction: string | null;
    paymentProductCodes: string[];
    lastSuccessfulPaymentProductCodes: string[];
    /**
     * Surcharges (deal attributes) overrides derived from deal or set by deal maker
     */
    surcharges: SurchargeOverrides;
    /**
     * Requested surcharges (deal attributes) derived from VPP dealer products response
     */
    requestedSurcharges: RequestedSurcharges;
};

export type RequestedSurcharges = {
    usedSurchargesAttributes: string[];
    requiredSurchargesAttributes: string[];
};

export type PaymentProduct = {
    categoryCode: string;
    price: number;
    cost?: number;
    backEndIndicator?: boolean;
    callerItemId?: number;
};

export interface DealerProduct {
    productName: string;
    productCategoryCode: string;
    productCode: string;
    providerDealerId: string;
    contractPrefix: string;
    approved: boolean;
    rateRequirements: IRateRequirements;
    content?: IProductContent;
    dealerSettings?: IProductDealerSettings;
}

export interface IProviderProduct {
    providerProductId: string;
    providerProductName: string;
}

export interface IMarketingContent {
    id: string;
    name: string;
    size: number;
    href: string;
    fileType: string;
}

export interface IProductContent {
    contentId: string;
    contentProfileName: string;
    providerId: string;
    providerName: string;
    createdDate: string;
    modifiedDate: string;
    greetingText: string;
    providerProducts?: IProviderProduct[];
    shortDescription: string;
    longDescription: string;
    fullProductDescription: string;
    benefit: string;
    marketingContents?: IMarketingContent[];
}

export type IProductDealerSettings = {
    settingId: string;
    settingProfileName: string;
} & ProductAfterMarketSetting;

export enum IDrewVPPDealType {
    CASH = 'Cash',
    FINANCE = 'Finance',
    LEASE = 'Lease'
}

export type ProductAfterMarketSetting = {
    dealerCostMarkupMethod: null | string;
    dealerCostMarkupValue: null | number;
    dealTypes: IDrewVPPDealType[];
    deductibleMethod: string;
    deductibleValue: null | number;
    default: string;
    maxMile: null | string;
    maxRetailPrice: null | number;
    maxTerm: null | string;
    minMile: null | string;
    minRetailPrice: null | number;
    minTerm: null | string;
    name: string;
    productCode: string;
    providerId: string;
    providerName: string;
    providerProducts: IProviderProduct[];
    retailPriceMarkupMethod: null | string;
    retailPriceMarkupValue: null | string;
    vehicleConditions: InventoryType[];
};

export enum InventoryType {
    USED = 'Used',
    NEW = 'New',
    CERTIFIED_PRE_OWNED = 'CertifiedPreOwned'
}

export interface IRateRequirementField {
    field: string;
    required: boolean;
}

export interface IRateRequirements {
    hasMissingField: boolean;
    fields: IRateRequirementField[];
}

export interface FetchVppProductsPayload {
    connectionId: string;
    dealerId: number;
    surchargeOverrides: SurchargeOverrides;
}

export type VppProductAttributes = {
    editingDisabled?: boolean;
    hasInapplicableRateAttribute?: boolean;
    hasInapplicablePlan?: boolean;
    missingRequiredAttributes?: boolean;
    newProductFromFie?: boolean;
    paymentFailed?: boolean;
    productDisappeared?: boolean;
    productWithoutRates?: boolean;
    selectDisabled?: boolean;
    selectedProductCostChanged?: boolean;
    unselectDisabled?: boolean;
};

export type ProductAddOnDetailsNonOptional = {
    productAddOnSummary: {
        totalProductAddOnCost: number;
        totalProductAddOnPrice: number;
    };
    productAddOns: DealProductAddOn[];
    unselectedProductAddOns: DealProductAddOn[];
};

export type ProductPlanDetails = {
    productCode: string;
    productProviderPlan: string;
    productName: string;
};
