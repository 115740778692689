import { PRINT_PDF_LOADING } from '../actionTypes/printPdfActionTypes';
import { Reducer } from 'redux';

type PrintPdfState = {
    loading: boolean;
};

const initialState: PrintPdfState = {
    loading: false
};

export type PrintPdfReducer = Reducer<PrintPdfState>;

export const reducer: PrintPdfReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case PRINT_PDF_LOADING:
            return {
                ...state,
                loading: action.payload.loading
            };
        default:
            return state;
    }
};
