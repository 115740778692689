// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import type { RSAACallAction, RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';
import type { StateTree } from '@makemydeal/dr-dash-types';

// consts/enums
import {
    CONSUMER_INSIGHTS_FETCH_REQUEST,
    CONSUMER_INSIGHTS_FETCH_SUCCESS,
    CONSUMER_INSIGHTS_FETCH_FAILURE,
    CONSUMER_INSIGHTS_FETCH_REQUEST_V2,
    CONSUMER_INSIGHTS_FETCH_SUCCESS_V2,
    CONSUMER_INSIGHTS_FETCH_FAILURE_V2
} from '../actionTypes/consumerInsightsActionTypes';

// utils
import { apiConfigHelper, BffApiUrlResult, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { getGlobalCustomerId } from '../selectors/dealXgDetailsSelectors';
import { getOfferGlobalCustomerId, getOfferCommonConsumerId } from '../selectors/offerSelectors';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

export const fetchConsumerInsights = (state: StateTree): RSAACallAction => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult: BffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, '');
    let globalCustomerId = getGlobalCustomerId(state);
    let endpoint = `${bffApiUrlResult.url}/consumerData/${globalCustomerId}/insights`;
    const enableConsumerInsightsLambdaV2 = featureToggleSelectors.enableConsumerInsightsLambdaV2(state);
    let request: RSAACallRequest;

    if (enableConsumerInsightsLambdaV2) {
        const commonConsumerId = getOfferCommonConsumerId(state);
        globalCustomerId = getOfferGlobalCustomerId(state);

        const payload = {
            globalCustomerId,
            commonConsumerId
        };

        endpoint = `${bffApiUrlResult.url}/v2/insights`;
        request = {
            endpoint,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
            types: [CONSUMER_INSIGHTS_FETCH_REQUEST_V2, CONSUMER_INSIGHTS_FETCH_SUCCESS_V2, CONSUMER_INSIGHTS_FETCH_FAILURE_V2]
        };

        return { [RSAA]: request };
    }

    request = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        types: [CONSUMER_INSIGHTS_FETCH_REQUEST, CONSUMER_INSIGHTS_FETCH_SUCCESS, CONSUMER_INSIGHTS_FETCH_FAILURE]
    };
    return {
        [RSAA]: request
    };
};
