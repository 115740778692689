import { Alert } from '@interstate/components/Alert';
import { Box } from '@interstate/components/Box';
import { deskingSelectors, paymentSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';

export const TaxCardAlerts = () => {
    const shouldShowTotalTaxRateSupersedeAlert = useSelector(deskingSelectors.shouldShowTotalTaxRateSupersedeAlert);
    const isPaymentError = useSelector(paymentSelectors.getIsError);

    return (
        <Box marginBottom={2}>
            {shouldShowTotalTaxRateSupersedeAlert && (
                <Alert data-testid={'total-tax-rate-supersede-alert'} role="total-tax-rate-supersede-alert" type={'error'}>
                    <span data-testid="total-tax-rate-supersede-alert-message">Rates can not supersede 90%.</span>
                </Alert>
            )}
            {isPaymentError && (
                <Alert data-testid={'total-tax-rate-payment-alert'} role="total-tax-rate-payment-alert" type={'error'}>
                    <span data-testid="total-tax-rate-payment-alert-message">Service Unavailable. Please try again.</span>
                </Alert>
            )}
        </Box>
    );
};
