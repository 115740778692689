import { Action } from '@interstate/components/Action';
import { Badge } from '@interstate/components/Badge';
import { UserIcon } from '@interstate/components/Icons';
import { Typography } from '@interstate/components/Typography';
import { dealXgDetailsSelectors, offerReduxActionCreators, offerReduxSelectors, vehicleSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { CommonConsumer } from '@makemydeal/dr-platform-types';
import { useDispatch, useSelector } from 'react-redux';
import { useCustomerUI } from '../customerUI/useCustomerUI';
import VehicleInfoDrawer from '../vehicleInfoDrawer/VehicleInfoDrawer';
import { CoBuyerLink } from './CoBuyerLink';
import {
    CustomVehicleLabel,
    LinkContainer,
    ShopperInfoContainer,
    VehicleInfoContainer,
    VerticalDivider
} from './Summary.style.interstate';

const SummaryInterstate = () => {
    const dispatch = useDispatch();
    const isEcommerceDeal = useSelector(dealXgDetailsSelectors.getIsEcommerceDeal);
    const commonConsumerId = useSelector(offerReduxSelectors.getShopperCommonConsumerId);

    const shopper = useSelector(offerReduxSelectors.getShopperInfo);
    const { firstName, lastName } = shopper;
    const { year, condition, make, model, trim, stockNumber, isCustom } = useSelector(vehicleSelectors.getVehicle);

    const { showCustomerCard } = useCustomerUI();

    const customerEditedCallback = (customer: CommonConsumer) => {
        dispatch(offerReduxActionCreators.updatedShopperLocationFromConsumer(shopper, customer));
    };

    const isSellAnyVehicleEnabled: boolean = useSelector(featureToggleSelectors.isSellAnyVehicleEnabled);
    const isCustomVehicle = isSellAnyVehicleEnabled && isCustom;

    return (
        <div data-testid="summary-container">
            <ShopperInfoContainer>
                <Typography
                    variant="h3"
                    tag="div"
                    color="base.color.blue.700"
                    data-testid="shopper-name-nolink"
                    sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
                >
                    {firstName} {lastName}
                    <CoBuyerLink />
                </Typography>
                {isEcommerceDeal && (
                    <Badge variant="info" data-testid="isEcommerceDealBadge">
                        <Typography variant="body-md" tag="div">
                            Order from Ecommerce
                        </Typography>
                    </Badge>
                )}
            </ShopperInfoContainer>
            <VehicleInfoContainer data-testid="vehicle-info-interstate">
                <Typography variant="body-md" sx={{ textTransform: 'capitalize' }} tag="div">
                    {condition} {year} {make} {model} {trim}
                </Typography>
            </VehicleInfoContainer>
            <Typography variant="body-md" sx={{ textTransform: 'capitalize' }} tag="div" data-testid="stock">
                <span style={{ wordBreak: 'break-all' }}>
                    {(!!stockNumber || !isCustomVehicle) && 'Stock #'} {stockNumber}
                </span>
                {isCustomVehicle && !!stockNumber && <VerticalDivider />}
                {isCustomVehicle && <CustomVehicleLabel>Custom Vehicle</CustomVehicleLabel>}
            </Typography>
            <LinkContainer>
                <>
                    {commonConsumerId && (
                        <Action
                            onClick={() => showCustomerCard(true, { customerEditedCallback, readOnlyFields: true })}
                            size="sm"
                            sx={{
                                '.ids-action': {
                                    gap: '4px'
                                }
                            }}
                            actionIcon={{
                                icon: <UserIcon />,
                                start: true
                            }}
                            data-testid="shopper-name"
                        >
                            Buyer
                        </Action>
                    )}
                    <VehicleInfoDrawer />
                </>
            </LinkContainer>
        </div>
    );
};

export default SummaryInterstate;
