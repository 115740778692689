import styled from 'styled-components';
import { Box } from '@interstate/components/Box';

export const VehicleInfoItemContainer = styled(Box)`
    border-radius: 4px;
    background: var(--surface-subdued, ${({ theme }) => theme.tokens.BaseColorGray50});
    padding: 16px;
    gap: 4px;
    align-self: stretch;
    margin-bottom: 32px;
    .rowItem {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        gap: 4px;
        .label {
            padding-bottom: 6px;
            padding-left: 0;
        }
        .vehicleInfoValue {
            text-align: right;
        }
    }
`;
export const LinkContainer = styled.div`
    display: flex;
    gap: 1rem;
    width: fit-content;
    span {
        white-space: nowrap; // Prevent Action label break the line
        font-size: 1.28rem !important;
    }
`;

export const FooterContainer = styled(Box)`
    display: flex;
    width: 100%;
    padding: 32px 0;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.tokens.ComponentCardPadding};
`;

export const DrawerContent = styled(Box)`
    flex-grow: 1;

    body {
        background: unset;
    }
`;

export const VehicleImageContent = styled(Box)`
    border-radius: 6px;
    overflow: hidden;
    object-fit: cover;
`;

export const StyledVehicleNotes = styled(Box)`
    margin-top: 32px;
`;
