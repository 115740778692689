/* eslint-disable react/no-multi-comp */
import React, { FC } from 'react';
import { StyledRebatesFlexView, StyledRebatesTabularView } from './RebatesView.style';
import { MobileRow, TabularRow } from './ViewRows';

interface ViewProps {
    data: any[];
}

export const MobileView: FC<ViewProps> = ({ data }) => {
    return (
        <StyledRebatesFlexView cols={2}>
            {data.map((rebate, rx) => (
                <MobileRow key={rx} data={rebate} index={rx} />
            ))}
        </StyledRebatesFlexView>
    );
};

export const TabularView: FC<ViewProps> = ({ data }) => {
    return (
        <StyledRebatesTabularView className="table">
            <thead>
                <tr>
                    <th>Program&nbsp;#</th>
                    <th>Name</th>
                    <th>Code</th>
                    <th>Type</th>
                    <th className="text-right">Amount</th>
                </tr>
            </thead>
            <tbody>
                {data.map((rebate, rx) => (
                    <TabularRow key={rx} data={rebate} index={rx} />
                ))}
            </tbody>
        </StyledRebatesTabularView>
    );
};
