import { useCallback, useEffect } from 'react';
import { UseClickOutsideHook } from '../types/useClickOutsideTypes';

/**
 * This hook simplifies tracking clicks outside of a specific DOM ref. When clicked outside, callback is invoked with the mouse event arguments.
 * @param contentRef Ref to the DOM content being checked
 * @param clickOutsideCallback Callback function for when clicked outside ref DOM with optional MouseEvent args
 * @param options Optional behaviors for this hook. ignoreClick defaults to false.
 */
export const useClickOutside: UseClickOutsideHook = (contentRef, clickOutsideCallback, options) => {
    const opts = options || {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const ignoreClick = opts.ignoreClick || (() => false);

    const handleBodyClick = useCallback(
        (e: MouseEvent) => {
            if (ignoreClick()) {
                return;
            }

            // Note: this is verbose by design.
            const contentRect = contentRef.current?.getClientRects()?.[0];
            if (!contentRect) {
                return;
            }

            // if inside this content, then normal toggle
            // if outside this content, toggle off
            const x = e.clientX;
            const y = e.clientY;
            const clickedInsideContent =
                x >= contentRect.left && x <= contentRect.right && y >= contentRect.top && y <= contentRect.bottom;
            if (clickedInsideContent) {
                return;
            }

            clickOutsideCallback(e);
        },
        [contentRef, clickOutsideCallback, ignoreClick]
    );

    useEffect(() => {
        document.body.addEventListener('click', handleBodyClick);
        return () => {
            document.body.removeEventListener('click', handleBodyClick);
        };
    }, [handleBodyClick]); // basically on init
};
