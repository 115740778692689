// hooks
import { useSelector } from 'react-redux';

// selectors
import { offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

// components
import DealSummaryItem from '../common/DealSummaryItem';
import { Grid } from '@interstate/components/Grid';
import { formatDollarsAndCents, formatNumber } from '@makemydeal/dr-common-utils';
import LineItemWithChildren from '../common/LineItemWithChildren';

// constants
import * as constants from '../../constants';

// types
import { DealHistory } from '@makemydeal/dr-dash-types';

// styles
import { labelStyles, expandibleButtonStyles, expandibleAreaStyles, valueStyles, secondaryValueStyles } from '../utils/styles';

type InterstatePaymentProps = {
    deal?: DealHistory;
};

const Payment = ({ deal }: InterstatePaymentProps) => {
    const useDealRootSelector = createDealRootSelector(deal);
    const monthlyPayment = useDealRootSelector(offerSelectors.getMonthlyPayment);
    const baseMonthlyPayment = useDealRootSelector(offerReduxSelectors.getBaseMonthlyPayment);
    const depreciation = useDealRootSelector(offerReduxSelectors.getTotalDepreciation);
    const rentCharge = useDealRootSelector(offerReduxSelectors.getTotalInterest);
    const monthlyUseTax = useDealRootSelector(offerReduxSelectors.getMonthlyUseTax);
    const term = useDealRootSelector(offerSelectors.getSelectedTermMonths);
    const totalBasePayment = useDealRootSelector(offerReduxSelectors.getTotalOfBasePayments);

    return (
        <DealSummaryItem
            labelStyles={labelStyles}
            valueStyles={valueStyles}
            label={constants.PAYMENT}
            value={formatDollarsAndCents(monthlyPayment)}
        >
            <DealSummaryItem
                valueStyles={secondaryValueStyles}
                labelStyles={labelStyles}
                expandibleButtonStyles={expandibleButtonStyles}
                expandibleAreaStyles={expandibleAreaStyles}
                label={constants.TOTAL_BASE_PAYMENT}
                value={formatDollarsAndCents(totalBasePayment)}
            >
                <LineItemWithChildren
                    containerStyles={{
                        pr: 0
                    }}
                    label={constants.PAYMENT_DEPRECIATION}
                    value={formatDollarsAndCents(depreciation)}
                />
                <LineItemWithChildren
                    containerStyles={{
                        pr: 0
                    }}
                    label={constants.PAYMENT_RENT_CHARGE}
                    value={formatDollarsAndCents(rentCharge)}
                />
            </DealSummaryItem>
            <LineItemWithChildren containerStyles={{ py: 1 }} label={constants.TERM} value={formatNumber(term ?? 0)} />
            <DealSummaryItem
                valueStyles={secondaryValueStyles}
                labelStyles={labelStyles}
                expandibleButtonStyles={expandibleButtonStyles}
                expandibleAreaStyles={expandibleAreaStyles}
                label={constants.MONTHLY_PAYMENT}
                value={formatDollarsAndCents(monthlyPayment)}
            >
                <LineItemWithChildren label={constants.BASE_MO_PAYMENT} value={formatDollarsAndCents(baseMonthlyPayment)} />
                <LineItemWithChildren label={constants.MO_USE_TAX} value={formatDollarsAndCents(monthlyUseTax)} />
            </DealSummaryItem>
        </DealSummaryItem>
    );
};

export default Payment;
