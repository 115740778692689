export const REMOVE_TRADE_IN = 'REMOVE_TRADE_IN';
export const UPDATE_TRADE_IN = 'UPDATE_TRADE_IN';
export const SKIP_TRADE_IN = 'SKIP_TRADE_IN';
export const TRADE_IN_ESTIMATION_SUCCESS = 'TRADE_IN_ESTIMATION_SUCCESS';
export const TRADE_IN_ESTIMATE_DISPLAYED = 'TRADE_IN_ESTIMATE_DISPLAYED';
export const TRADE_FLOW_COMPLETED = 'TRADE_FLOW_COMPLETED';
export const ACV_CHANGED = 'ACV_CHANGED';
export const ALLOWANCE_CHANGED = 'ALLOWANCE_CHANGED';
export const PAYOFF_CHANGED = 'PAYOFF_CHANGED';
export const MANUAL_ENTRY_CANCEL_TRADE_IN = 'MANUAL_ENTRY_CANCEL_TRADE_IN';
export const MANUAL_ENTRY_SAVE_TRADE_IN = 'MANUAL_ENTRY_SAVE_TRADE_IN';
export const TRADE_IN_SAVE_OR_UPDATE = 'TRADE_IN_SAVE_OR_UPDATE';
export const TRADE_IN_UPDATE_PAYOFF_DUE_DATE = 'TRADE_IN_UPDATE_PAYOFF_DUE_DATE';
export const TRADE_IN_UPDATE_LIENHOLDER = 'TRADE_IN_UPDATE_LIENHOLDER';
export const TRADE_IN_UPDATE_LIENHOLDER_FULL = 'TRADE_IN_UPDATE_LIENHOLDER_FULL';
export const TRADE_IN_ODOMETER = 'TRADE_IN_ODOMETER';
export const TRADE_IN_UPDATE_PER_DIEM = 'TRADE_IN_UPDATE_PER_DIEM';
export const TRADE_DETAILS_MANUAL_ENTRY_CANCEL = 'TRADE_DETAILS_MANUAL_ENTRY_CANCEL';
export const TRADE_DETAILS_MANUAL_ENTRY_SAVE = 'TRADE_DETAILS_MANUAL_ENTRY_SAVE';
export const TRADE_IN_PAYMENT_INFO_UPDATE_REQUEST = 'TRADE_IN_PAYMENT_INFO_UPDATE_REQUEST';
export const TRADE_IN_PAYMENT_INFO_UPDATE = 'TRADE_IN_PAYMENT_INFO_UPDATE';
export const TRADE_IN_UPDATE_INTERIOR_COLOR = 'TRADE_IN_UPDATE_INTERIOR_COLOR';
export const TRADE_IN_UPDATE_DOORS = 'TRADE_IN_UPDATE_DOORS';
export const TRADE_IN_UPDATE_BODY_STYLE = 'TRADE_IN_UPDATE_BODY_STYLE';
export const TRADE_IN_UPDATE_ENGINE = 'TRADE_IN_UPDATE_ENGINE';
export const TRADE_IN_UPDATE_TRANSMISSION = 'TRADE_IN_UPDATE_TRANSMISSION';
export const TRADE_IN_UPDATE_DRIVE_LINE = 'TRADE_IN_UPDATE_DRIVE_LINE';
export const TRADE_IN_UPDATE_OWNERSHIP_TYPE = 'TRADE_IN_OWNERSHIP_TYPE';
export const TRADE_IN_UPDATE_IS_BUYER_REGISTERED_OWNER = 'TRADE_IN_UPDATE_IS_BUYER_REGISTERED_OWNER';
export const TRADE_IN_UPDATE_IS_SELLING_VEHICLE_REGISTERED_IN_SAME_STATE =
    'TRADE_IN_UPDATE_IS_SELLING_VEHICLE_REGISTERED_IN_SAME_STATE';

// Vin update actions
export const TRADE_IN_UPDATE_VIN = 'TRADE_IN_UPDATE_VIN';
export const TRADE_IN_UPDATE_YEAR = 'TRADE_IN_UPDATE_YEAR';
export const TRADE_IN_UPDATE_MAKE = 'TRADE_IN_UPDATE_MAKE';
export const TRADE_IN_UPDATE_MAKE_ID = 'TRADE_IN_UPDATE_MAKE_ID';
export const TRADE_IN_UPDATE_MODEL_ID = 'TRADE_IN_UPDATE_MODEL_ID';
export const TRADE_IN_UPDATE_MODEL = 'TRADE_IN_UPDATE_MODEL';
export const TRADE_IN_UPDATE_TRIM = 'TRADE_IN_UPDATE_TRIM';
export const TRADE_IN_UPDATE_CONDITION = 'TRADE_IN_UPDATE_CONDITION';
export const TRADE_IN_UPDATE_VEHICLE_COLOR = 'TRADE_IN_UPDATE_VEHICLE_COLOR';
export const TRADE_IN_VIN_REQUEST = 'TRADE_IN_VIN_REQUEST';
export const TRADE_IN_VIN_SUCCESS = 'TRADE_IN_VIN_SUCCESS';
export const TRADE_IN_VIN_FAILURE = 'TRADE_IN_VIN_FAILURE';
export const TRADE_IN_MULTIPLE_VIN_SELECTED = 'TRADE_IN_MULTIPLE_VIN_SELECTED';
export const TRADE_IN_TOGGLE_SAVING = 'TRADE_IN_TOGGLE_SAVING';

export type FetchVinDecodeResponse = {
    id: number;
    standardVehicleConfigurationID?: any;
    classification: string;
    country: Country;
    countryOfOrigin: string;
    title: string;
    year: number;
    midModelYear?: any;
    manufacturer: string;
    make: string;
    model: Model;
    trim: Trim;
    vehicleAvailability: string;
    marketingName: string;
    sparse: boolean;
    region?: any;
    invoice: Invoice;
    msrp: Invoice;
    trueBasePrice: boolean;
    destination: Invoice;
    powertrain: Powertrain;
    bodyDescription: BodyDescription;
    asBuiltMsrp: AsBuiltMsrp;
    makeId: number;
}[];

interface AsBuiltMsrp {
    amount?: any;
    unitOfMeasure: string;
}

interface BodyDescription {
    segment: string;
    vehicleType: string;
    marketingBodyStyle: string;
    primaryBodyStyle: string;
    primaryBodyStyleId: number;
    secondaryBodyStyles: any[];
    cabType?: any;
    bedLength?: any;
    boxLength?: any;
    boxStyle?: any;
    sideStyle?: any;
    roofStyle?: any;
    passengerDoors: string;
    rearWheelConfiguration?: any;
    rearWheelConfig: RearWheelConfig;
}

interface RearWheelConfig {
    type: string;
}

interface Powertrain {
    engine: Engine;
    transmission: Transmission;
    drivetrain: Drivetrain;
}

interface Drivetrain {
    description: string;
    type: string;
    wheelCount?: any;
}

interface Transmission {
    primaryDescription: string;
    extendedDescription: string;
    oemOptionCode: string;
    type: string;
    gearCount: number;
    manufacturer?: any;
}

interface Engine {
    primaryDescription: string;
    extendedDescription: string;
    oemOptionCode: string;
    manufacturer?: any;
    displacement: Invoice;
    camshaft: string;
    blockType: string;
    cylinderCount: number;
    valveCount: number;
    fuelIntake: string;
    fuelType: string;
    fuelCategory: string;
    aspiration: string;
}

interface Invoice {
    amount: string;
    unitOfMeasure: string;
}

interface Trim {
    normalizedTrimId: number;
    normalizedName: string;
    marketingName: string;
    subTrim?: any;
}

interface Model {
    normalizedModelId: number;
    normalizedName: string;
    marketingName: string;
    modelGroup: string;
    availability: string;
    modelCode: string;
    region?: any;
}

interface Country {
    code: string;
    name: string;
}
