/* eslint-disable react/no-multi-comp */
import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { ManualIncentiveEntry } from '@makemydeal/dr-platform-types';
import { getIncentiveDisplayType } from './utils';
import { StyledHrWithMargin } from './RebatesView.style';

export type RowProps = { data: ManualIncentiveEntry; index?: number };

export const HeaderCell: FC<HTMLAttributes<HTMLDivElement> & { children?: ReactNode }> = ({ children, className, ...props }) => (
    <div className={`header ${className || ''}`} {...props}>
        {children}
    </div>
);

export const MobileRow: FC<RowProps> = ({ data, index }) => {
    const { amount, name, code, program } = data;
    return (
        <>
            <HeaderCell data-testid={`program-label-${index}`}>Program #</HeaderCell>
            <div className="text-right" data-testid={`program-value-${index}`}>
                {program}
            </div>
            <HeaderCell data-testid={`name-label-${index}`}>Name</HeaderCell>
            <div className="text-right" data-testid={`name-value-${index}`}>
                {name}
            </div>
            <HeaderCell data-testid={`code-label-${index}`}>Code</HeaderCell>
            <div className="text-right" data-testid={`code-value-${index}`}>
                {code}
            </div>
            <HeaderCell data-testid={`type-label-${index}`}>Type</HeaderCell>
            <div className="text-right" data-testid={`type-value-${index}`}>
                {getIncentiveDisplayType(data)}
            </div>
            <HeaderCell data-testid={`amount-label-${index}`}>Amount</HeaderCell>
            <div className="text-right" data-testid={`amount-value-${index}`}>
                {formatDollarsAndCents(amount)}
            </div>
            <StyledHrWithMargin />
        </>
    );
};

export const TabularRow: FC<RowProps> = ({ data }) => {
    const { amount, name, code, program } = data;
    return (
        <tr>
            <td>{program}</td>
            <td>{name}</td>
            <td>{code}</td>
            <td>{getIncentiveDisplayType(data)}</td>
            <td className="text-right">{formatDollarsAndCents(amount)}</td>
        </tr>
    );
};
