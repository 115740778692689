// externals
import { useCallback } from 'react';
import { CheckBoxList, CheckBoxListEventValue } from '@interstate/components/CheckBoxList';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// styles
import { AddOnsPriceContainer, PriceValue } from './EditProductAddOns.style';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { calculateAddOnsCost, calculateAddOnsPrice, mapProductAddOnsToModel, selectProductAddOns } from '../../utils/addOnsUtils';

// types
import { ProductAddOnDetails } from '@makemydeal/dr-shared-types';
import { EditProductAddOnsProps } from '../../types';

// components
import TitleWithToolTipIcon from '../form/TitleWithToolTipIcon';

const EditProductAddOns = ({
    productAddOnDetails = {},
    onEdit
}: {
    productAddOnDetails?: ProductAddOnDetails;
    onEdit: (productProps: EditProductAddOnsProps) => void;
}) => {
    const theme = useInterstateTheme();

    const addOnsModel = mapProductAddOnsToModel(productAddOnDetails);

    const handleAddOnsChange = useCallback(
        (event: InterstateOnChangeEvent<CheckBoxListEventValue>) => {
            const { selectedAddOns, unselectedAddOns } = selectProductAddOns(
                [...(productAddOnDetails.productAddOns ?? []), ...(productAddOnDetails.unselectedProductAddOns ?? [])],
                event.target.value as CheckBoxListEventValue
            );
            const addOnsCost = calculateAddOnsCost(selectedAddOns);
            const addOnsPrice = calculateAddOnsPrice(selectedAddOns);

            onEdit({
                productAddOnDetails: {
                    productAddOnSummary: {
                        totalProductAddOnCost: addOnsCost,
                        totalProductAddOnPrice: addOnsPrice
                    },
                    productAddOns: selectedAddOns,
                    unselectedProductAddOns: unselectedAddOns
                }
            });
        },
        [onEdit, productAddOnDetails]
    );
    return (
        <>
            <AddOnsPriceContainer theme={theme}>
                <TitleWithToolTipIcon title="Available Add-Ons" name="add-ons" />
                <PriceValue>
                    <span data-testid="add-ons-amount">
                        {formatDollarsAndCents(productAddOnDetails.productAddOnSummary?.totalProductAddOnCost ?? 0)}
                    </span>
                </PriceValue>
            </AddOnsPriceContainer>
            {addOnsModel[0] && (
                <CheckBoxList
                    data-testid="add-ons-checkbox-list"
                    label=""
                    name="addOnsCheckBoxList"
                    onChange={handleAddOnsChange}
                    options={addOnsModel}
                    values={addOnsModel.filter((addOn) => addOn.selected).map((addOn) => addOn.value)}
                />
            )}
        </>
    );
};

export default EditProductAddOns;
