import { Button } from '@interstate/components/Button';
import { Typography } from '@interstate/components/Typography';
import { useSelector } from 'react-redux';
import { offerReduxSelectors, pushToRouteOneSelectors } from '@makemydeal/dr-dash-store';
import { formatAsMMDDYYYY, formatAsTime } from '@makemydeal/dr-common-utils';

type PushToRouteOneButtonProps = {
    onClick: () => void;
};

const PushToRouteOneButton = (props: PushToRouteOneButtonProps) => {
    const { onClick } = props;

    const currentVersion = useSelector(offerReduxSelectors.getDealXgVersion);
    const status = useSelector(pushToRouteOneSelectors.getPushToRouteOneStatus);

    const displayMessage = currentVersion === status.dealXgVersion && status.timestamp;
    const formatTimestamp = (timestamp: string) => {
        const routeOneTimestamp = new Date(timestamp);
        return `${formatAsMMDDYYYY(routeOneTimestamp)} at ${formatAsTime(routeOneTimestamp)}`;
    };

    return (
        <>
            <Button
                block
                sx={{ backgroundColor: 'transparent', marginTop: '16px' }} // TODO: Waiting for transparent background on secondary buttons
                className="interstate"
                buttonStyle="secondary"
                data-testid="push-to-route-one-button"
                size="medium"
                onClick={onClick}
            >
                Push to Route One
            </Button>
            {displayMessage && (
                <Typography
                    variant="body-sm"
                    color="sem.color.on-surface.muted"
                    tag="div"
                    sx={{
                        marginTop: '10px'
                    }}
                    data-testid="sent-to-route-one-label"
                >
                    Deal sent to <b>RouteOne</b> on {formatTimestamp(status.timestamp)}
                </Typography>
            )}
        </>
    );
};

export default PushToRouteOneButton;
