// external
import { Store } from 'redux';

// libraries
import { BaseMiddleware } from '@makemydeal/dr-activities-common';

// types
import { ConfigChangeVehicle, HostActions } from '../types';

// store
import { processCviVehicles } from './actionCreators';
import * as Actions from './actions';
import { Selectors } from './selectors';
import { catalogToVehicle } from '../utils';

export class MiddlewareManager extends BaseMiddleware {
    constructor(
        public selectors: Selectors,
        public hostActions: HostActions,
        public config: ConfigChangeVehicle,
        public baseRoute?: string,
        public analyticsAction?: (store: any, key: string, desc: string) => void
    ) {
        super(selectors, analyticsAction);
    }

    /**
     * any activity specific middleware code should be defined here;
     * application-specific middleware should be outside of the activity
     */
    protected middlewareFunction = (store: Store<any>, next: any, action: any): void => {
        switch (action.type) {
            case Actions.SEARCH_VEHICLE_SUCCESS:
            case Actions.FETCH_CVI_SUCCESS: {
                store.dispatch(processCviVehicles(action.payload.map(catalogToVehicle)));

                break;
            }
            default: {
                break;
            }
        }
    };
}
