import styled from 'styled-components';

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    gap: 0.5rem;
    background-color: rgb(248, 247, 252);

    // we do need this, because display flex does not take the whole space like block does.
    width: 100%;

    #slideout-footer-secondary {
        background-color: #fff;
        border-color: #2b6bdd;
        color: #2b6bdd;
        transition: all 0.25s ease-in-out;
    }
    #slideout-footer-secondary:active {
        background-color: #173a78;
        background-image: none;
        border-color: #2b6bdd;
        box-shadow: inset 0 0 0 1px #2b6bdd, inset 0 0 0 2px #fff;
        color: #fff;
    }
    #slideout-footer-primary {
        background-color: #2b6bdd;
        color: #fff;
        border-color: #2b6bdd;
        transition: all 0.25s ease-in-out;
    }
    #slideout-footer-primary.Mui-disabled {
        background-color: #e3e3e3;
        border-color: #b2b2b2;
        color: #959595;
        cursor: not-allowed;
    }

    #slideout-footer-primary:active:not(.Mui-disabled) {
        background-color: #173a78;
        background-image: none;
        border-color: #2b6bdd;
        box-shadow: inset 0 0 0 1px #2b6bdd, inset 0 0 0 2px #fff;
        color: #fff;
    }

    button:active {
        color: ;
    }
`;

export const LoadingContainer = styled.div`
    & svg {
        width: 2rem !important;
        height: 2rem !important;
    }
`;

export const FooterActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
`;

export const FooterInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
`;

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 2rem 0 2rem;
    margin-bottom: 5rem;
`;
