import styled from 'styled-components';

export const DealAttributesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.tokens.BaseSpace25};

    .flex-wrapper {
        display: flex;
    }

    .input-info {
        font-size: ${({ theme }) => theme.tokens.ComponentTextInputLabelFontSizeMedium};
    }

    .attributes-section {
        margin-top: ${({ theme }) => theme.tokens.BaseSpace50};
        padding-bottom: ${({ theme }) => theme.tokens.BaseSpace25};
        border-bottom: ${({ theme }) => `1px solid ${theme.tokens.SemColorBorderMuted};`}
`;
