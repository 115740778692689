// externals
import React from 'react';

// libraries
import { RootComponent, IRootProps } from '@makemydeal/dr-activities-tradein';

export class TradeInUI extends React.Component<IRootProps, any> {
    render() {
        return <RootComponent {...this.props} />;
    }
}
