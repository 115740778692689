import { useMemo } from 'react';
import { CatalogVehicle } from '../types';

export const useVehicleTitle = (vehicle: Pick<CatalogVehicle, 'year' | 'make' | 'model' | 'trim' | 'marketingName'>) => {
    const { year = '', make = '', model = '', trim = '', marketingName } = vehicle;
    return useMemo(() => {
        const baseName = `${year} ${make} ${model} ${trim}`;
        const actualMarketingName =
            marketingName &&
            (marketingName.toLowerCase().startsWith(trim.toLowerCase())
                ? marketingName.substring(trim.length).trim()
                : marketingName);
        return actualMarketingName ? `${baseName} - ${actualMarketingName}` : baseName;
    }, [year, make, model, trim, marketingName]);
};
