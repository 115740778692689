import { FC, useMemo } from 'react';
import { Table } from '@interstate/components/Table';
import { CASH, FINANCE, OfferType } from '@makemydeal/dr-platform-types';
import { leaseOnlyColumns, columns, cashExemptColumns } from './programColumns';
import { removeColumns } from '../../utils/mapUtils';
import { ActionColumn } from './ActionColumn';

export const ProgramsListView: FC<{ data: any; offerType: OfferType }> = ({ data, offerType }) => {
    const cols = useMemo(() => {
        columns[columns.length - 1].render = ActionColumn;
        columns[columns.length - 1].width = '150px';
        if (offerType === CASH) {
            return removeColumns(columns, cashExemptColumns);
        }
        if (offerType === FINANCE) {
            return removeColumns(columns, leaseOnlyColumns);
        }
        columns[columns.length - 1].render = ActionColumn;
        return columns;
    }, [offerType]);

    return <Table columns={cols} data={data} dataDensity="small" scrollX={1800} sortableDetails={{ defaultSortOrder: 'ascend' }} />;
};
