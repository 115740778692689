// interfaces/types
import { AccessoryElement } from '@makemydeal/dr-platform-types';
import { AccessoriesAdapterObject, AccessoryItem, DealAccessory } from '@makemydeal/dr-shared-types';
import { ProtectionObjSentList } from '@makemydeal/dr-dash-types';
import { nanoid } from 'nanoid';

export class AccessoriesAdapter {
    static toTargetFormat(accessoriesState = { elements: [] as AccessoryElement[] }): AccessoriesAdapterObject {
        const accessoryObjList: AccessoryItem[] = accessoriesState.elements?.map((element) => ({
            cardHeading: '',
            categoryCode: 'ACC',
            code: element.code,
            cost: element.cost,
            forCash: true,
            forFinance: true,
            forLease: true,
            hideRatedInfoCallSpinner: undefined,
            id: 'ACCX' + element.code,
            isFrontEnd: element.profit === 'frontEndProfit',
            mediaURL: [],
            originalAmount: element.originalAmount,
            productName: element.name,
            profit: element.profit,
            provider: { id: '', name: element.providerName || '' },
            residual: element.residual,
            residualType: element.residualType,
            selected: true,
            totalPrice: element.amount,
            upFront: element.upFront,
            weOwe: element.weOwe,
            isManualAccessory: element.isManualAccessory,
            initialPrice: element.initialPrice,
            installPrice: element.installPrice,
            ...(element.weOwe && { weOweId: 'ACCX' + element.code }),
            ...(element.code && { partNumber: element.code })
        }));

        const accessoryProductList = accessoriesState.elements?.map((element) => ({}));

        const selectedAccessories = accessoriesState.elements?.map((element) => ({
            categoryCode: 'ACC',
            code: element.code,
            cost: element.cost,
            forCash: true,
            forFinance: true,
            forLease: true,
            id: 'ACCX' + element.code,
            originalAmount: element.originalAmount,
            productName: element.name,
            profit: element.profit,
            providerName: element.providerName,
            residual: element.residual,
            residualType: element.residualType,
            selected: true,
            totalPrice: element.amount,
            upFront: element.upFront,
            weOwe: element.weOwe,
            isManualAccessory: element.isManualAccessory,
            initialPrice: element.initialPrice,
            installPrice: element.installPrice,
            isPriceCapitalized: !element.upFront,
            ...(element.weOwe && { weOweId: 'ACCX' + element.code }),
            ...(element.code && { partNumber: element.code })
        })) as AccessoryItem[];

        const menuProductSentList = accessoriesState.elements?.map((element) => ({
            id: 'ACCX' + element.code,
            selected: true // TODO: Evaluate if this is needed the first time we add an accessory
        }));

        return {
            accessoryObjList,
            accessoryProductList,
            lastValidMenuProductList: [],
            menuProductSentList,
            menuSubmitToggle: true,
            productsOfInterest: [],
            requestingRatedProducts: 0,
            selectedAccessories
        };
    }

    static toSourceFormat(targetFormat: ProtectionObjSentList[] = []) {
        return {
            isFormOpen: false,
            elements: targetFormat.map((item) => ({
                amount: item.totalPrice,
                code: item.menuItemId.replace('ACCX', ''),
                cost: item.cost,
                deleted: item.deleted,
                name: item.productName,
                originalAmount: 0,
                profit: item.profit,
                residual: item.residual,
                upFront: item.isFrontEnd,
                weOwe: item.weOwe
            }))
        };
    }

    static accessoriesToSourceFormat(targetFormat: DealAccessory[]) {
        return {
            isFormOpen: false,
            elements: targetFormat.map((item) => ({
                amount: item.totalPrice,
                code: item.id.replace('ACCX', ''),
                cost: item.cost,
                deleted: item.deleted,
                name: item.productName,
                originalAmount: 0,
                profit: item.profit,
                residual: item.residual,
                residualType: item.residualType || 'Dollar',
                upFront: item.upFront,
                weOwe: item.weOwe,
                isManualAccessory: item.isManualAccessory,
                manualAccessoryId: item.isManualAccessory ? nanoid() : undefined,
                initialPrice: item.initialPrice,
                installPrice: item.installPrice,
                providerName: item.providerName || ''
            }))
        };
    }
}
