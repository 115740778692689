// externals
import React from 'react';

// libraries
import { LenderListProps } from '@makemydeal/dr-dash-types';
import { sortingUtils } from '@makemydeal/dr-dash-ui-utils';

// components
import { LenderDecisionCard } from './LenderDecisionCard';

// styles
import { CardWrapper } from './LenderDecisionInfo.style';

export const LenderList = ({ lenderDecisions, sortOrder }: LenderListProps) => {
    if (sortOrder.length === 0) {
        lenderDecisions.sort((a, b) => (a.approvalStatus > b.approvalStatus ? 1 : -1));
    } else {
        lenderDecisions.sort(sortingUtils.sortByStatusFunction);
    }

    return (
        <CardWrapper data-testid="credit-decision-lender-list">
            {lenderDecisions.map((lenderDecision) => {
                return <LenderDecisionCard data={lenderDecision} />;
            })}
        </CardWrapper>
    );
};
