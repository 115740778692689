// libraries
import { DealerDecisionStatus, type CreditDecision } from '@makemydeal/dr-dash-types';

export const sortByStatusFunction = (a: CreditDecision, b: CreditDecision): number => {
    const getStatus = (status: string): number => {
        switch (status) {
            case DealerDecisionStatus.APPROVED:
                return 1;
            case DealerDecisionStatus.CONDITIONALLY_APPROVED:
            case DealerDecisionStatus.COUNTER_OFFER:
                return 2;
            case DealerDecisionStatus.PENDING:
                return 3;
            case DealerDecisionStatus.DECLINED:
                return 4;
            default:
                return 5;
        }
    };

    return getStatus(a.approvalStatus) - getStatus(b.approvalStatus);
};

/**
 * Simple compare algorithm that will compare objects by top-level prop.
 * @param col Name of prop to compare
 * @param direction Direction to sort (1 ascending or -1 descending)
 * @returns Sort value
 */
export const getColumnSorter =
    (col: string, direction: number) =>
    (a: any, b: any): number => {
        const valueA: any = a[col];
        const valueB: any = b[col];
        if (valueA > valueB) return 1 * direction;
        if (valueA < valueB) return -1 * direction;
        return 0;
    };
