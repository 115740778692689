// externals
import { FC } from 'react';

// libraries
import { FeeCategorySelectInput } from './FeeCategorySelectInput';
import { FeesFormItemsContainer } from './FeesForm.style';
import { FinanceAmountCell } from './FinanceAmountCell';
import { FinanceCodeCell } from './FinanceCodeCell';
import { FinanceDeleteCell } from './FinanceDeleteCell';
import { FinanceNameCell } from './FinanceNameCell';
import { LeaseUpfrontCell } from './LeaseUpfrontCell';

// types
import type { Detail } from './types';

// utils
import { DELETED_BY_ACTION_FEE_AMOUNT } from './constants';
import { getFeeCodes, isLeaseFinanceDetail } from './utils';
import { offerSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';

type FeeDetailRowProps = {
    detail: Detail;
    index: number;
    isLease?: boolean;
};

export const FeeDetailRow: FC<FeeDetailRowProps> = ({ detail, index, isLease }) => {
    const allItemizedFees = useSelector(offerSelectors.getCombinedItemizedFees);

    const appliedFeeCodes = getFeeCodes(allItemizedFees);

    if (detail.amount === DELETED_BY_ACTION_FEE_AMOUNT) return null;

    const isNewFee = !appliedFeeCodes.includes(detail.code ?? -1);

    return (
        <FeesFormItemsContainer data-testid={`edit-fee-row-${detail.category}-${index}`} key={`edit-fee-row-${index}`}>
            <FinanceDeleteCell detail={detail} index={index} />
            {isNewFee ? (
                <FeeCategorySelectInput feeCategory={detail.category} feeIndex={index} detail={detail} />
            ) : (
                <FinanceCodeCell detail={detail} index={index} />
            )}
            <FinanceNameCell detail={detail} index={index} feeCategory={detail.category} />
            <FinanceAmountCell detail={detail} index={index} />
            {isLeaseFinanceDetail(!!isLease, detail) && <LeaseUpfrontCell detail={detail} index={index} />}
        </FeesFormItemsContainer>
    );
};
