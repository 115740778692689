import { FetchVinDecodeResponse } from '../actionTypes/tradeActionTypes';

export const extractTrimFromVehicles = (vehicles: FetchVinDecodeResponse): { name: string; id: number }[] => {
    return vehicles.map((result: FetchVinDecodeResponse[0]) => ({
        name: extractTrimFromVehicleTitle(result.title.split(' ')),
        id: result.trim.normalizedTrimId
    }));
};

export const extractTrimFromVehicleTitle = (title: string[]): string => {
    return title.slice(3, title.length).join(' ');
};
