// libraries
import type { DealHistory } from '@makemydeal/dr-dash-types';
import {
    compositeSelectors,
    offerReduxSelectors,
    tradeInSelectors,
    offerSelectors,
    offerAdditionalSelectors
} from '@makemydeal/dr-dash-store';
import { FINANCE, LEASE } from '@makemydeal/dr-platform-types';

export type DeltaList = {
    isDiscountDelta: boolean;
    isSellingPriceDelta: boolean;
    isAddOnsDelta: boolean;
    isTotalTaxDelta: boolean;
    isNetSellingPriceDelta: boolean;
    isNetTradeDelta: boolean;
    isRebatesDelta: boolean;
    isCashDownDelta: boolean;
    isAdjustedCapCostDelta: boolean;
    isTotalResidualDelta: boolean;
    isTermDelta: boolean;
    isMonthlyPaymentDelta: boolean;
    isTotalDueDelta: boolean;
};

export const defaultDeltaList: DeltaList = {
    isDiscountDelta: false,
    isSellingPriceDelta: false,
    isAddOnsDelta: false,
    isTotalTaxDelta: false,
    isNetSellingPriceDelta: false,
    isNetTradeDelta: false,
    isRebatesDelta: false,
    isCashDownDelta: false,
    isAdjustedCapCostDelta: false,
    isTotalResidualDelta: false,
    isTermDelta: false,
    isMonthlyPaymentDelta: false,
    isTotalDueDelta: false
};

export const determineDeltas = (
    deal: DealHistory | undefined,
    comparingOffer: DealHistory | undefined,
    isCurrent = false
): DeltaList => {
    const deltaList = { ...defaultDeltaList };

    if (!deal || isCurrent || !comparingOffer) {
        return deltaList;
    }

    const mappedOffer = mapOfferProperties(deal);
    const mappedComparingOffer = mapOfferProperties(comparingOffer);

    if (mappedOffer && mappedComparingOffer) {
        if (mappedOffer.discount !== mappedComparingOffer.discount) {
            deltaList.isDiscountDelta = true;
        }
        if (mappedOffer.sellingPrice !== mappedComparingOffer.sellingPrice) {
            deltaList.isSellingPriceDelta = true;
        }
        if (mappedOffer.addOns !== mappedComparingOffer.addOns) {
            deltaList.isAddOnsDelta = true;
        }
        if (mappedOffer.totalTax !== mappedComparingOffer.totalTax) {
            deltaList.isTotalTaxDelta = true;
        }
        if (mappedOffer.netSellingPrice !== mappedComparingOffer.netSellingPrice) {
            deltaList.isNetSellingPriceDelta = true;
        }
        if (mappedOffer.netTrade !== mappedComparingOffer.netTrade) {
            deltaList.isNetTradeDelta = true;
        }
        if (mappedOffer.rebates !== mappedComparingOffer.rebates) {
            deltaList.isRebatesDelta = true;
        }
        if (mappedOffer.cashDown !== mappedComparingOffer.cashDown) {
            deltaList.isCashDownDelta = true;
        }
        if (mappedOffer.adjustedCapCost !== mappedComparingOffer.adjustedCapCost) {
            deltaList.isAdjustedCapCostDelta = true;
        }
        if (mappedOffer.totalResidual !== mappedComparingOffer.totalResidual) {
            deltaList.isTotalResidualDelta = true;
        }
        if (mappedOffer.term !== mappedComparingOffer.term) {
            deltaList.isTermDelta = true;
        }
        if (mappedOffer.monthlyPayment !== mappedComparingOffer.monthlyPayment) {
            deltaList.isMonthlyPaymentDelta = true;
        }
        if (mappedOffer.totalDue !== mappedComparingOffer.totalDue) {
            deltaList.isTotalDueDelta = true;
        }
    }
    return deltaList;
};

export type MappedOfferValues = {
    discount: number;
    sellingPrice: number;
    addOns: number;
    totalTax: number;
    netTrade: number;
    rebates: number;
    netSellingPrice: number;
    cashDown: number | null;
    adjustedCapCost: number | null;
    totalResidual: number | null;
    term: number | undefined;
    monthlyPayment: number | null;
    totalDue: number;
};

const mapOfferProperties = (deal: DealHistory): MappedOfferValues => {
    const state: any = deal;
    const offerType = offerReduxSelectors.getCurrentOfferType(state);
    const discount = offerReduxSelectors.getDiscount(state);
    const sellingPrice = offerSelectors.getSellingPrice(state);
    const addOns = compositeSelectors.getTotalAddons(state);
    const totalTax = offerReduxSelectors.getTotalTax(state);
    const netTrade = tradeInSelectors.getTradeNetValue(state as any);
    const rebates = offerReduxSelectors.getAllIncentiveTotal(state);
    const sharedProperties = {
        discount,
        sellingPrice,
        addOns,
        totalTax,
        netTrade,
        rebates
    };
    if (offerType === FINANCE) {
        return {
            ...sharedProperties,
            netSellingPrice: compositeSelectors.getNetSellingPrice(state),
            cashDown: offerSelectors.getDownPayment(state),
            adjustedCapCost: null,
            totalResidual: null,
            term: offerSelectors.getSelectedTermMonths(state),
            monthlyPayment: offerSelectors.getMonthlyPayment(state),
            totalDue: offerSelectors.getAmountFinanced(state)
        };
    } else if (offerType === LEASE) {
        return {
            ...sharedProperties,
            netSellingPrice: compositeSelectors.getNetSellingPrice(state),
            cashDown: offerSelectors.getDownPayment(state),
            adjustedCapCost: offerReduxSelectors.getAdjustedCapCost(state),
            totalResidual: offerReduxSelectors.getAdjustedResidualValue(state),
            term: offerSelectors.getSelectedTermMonths(state),
            monthlyPayment: offerSelectors.getMonthlyPayment(state),
            totalDue: offerReduxSelectors.getTotalAmountDue(state)
        };
    } else {
        return {
            ...sharedProperties,
            netSellingPrice: compositeSelectors.getNetSellingPrice(state),
            cashDown: null,
            adjustedCapCost: null,
            totalResidual: null,
            term: undefined,
            monthlyPayment: null,
            totalDue: offerAdditionalSelectors.getMvCashOfferRoundedUp(state)
        };
    }
};
