import { ListItem } from '@interstate/components/List';
import styled from 'styled-components';
import { ExpandableAreaSection } from '../../common/ExpandableArea/ExpandableAreaSection';

interface StyledListItemProps {
    hasDelta?: boolean;
}

export const StyledListItem = styled(ListItem)<StyledListItemProps>`
    ${({ hasDelta }) => {
        return hasDelta
            ? `
            overflow: hidden;
            border-left: 4px solid #ffc20e;
            `
            : '';
    }}

    .chevron-icon {
        stroke-width: 2px;
        margin-right: 4px;
        font-size: 12px;
        color: #2b6bdd;
    }
`;

export const StyledExpandableAreaSection = styled(ExpandableAreaSection)`
    cursor: pointer;
`;
