// components
import { Grid } from '@interstate/components/Grid';
import { NumericInput } from '@interstate/components/NumericInput';

export type VehicleDataInputProps = {
    onSellingPriceChange: (value: string) => void;
    onOdometerChange: (value: string) => void;
    odometer: string;
    minOdometerValue: number;
    sellingPrice: string;
    vin: string;
};

export const VehicleDataInputs = (props: VehicleDataInputProps) => {
    const { odometer, sellingPrice, onOdometerChange, onSellingPriceChange, minOdometerValue, vin } = props;

    return (
        <>
            <Grid
                container
                columnSpacing="8px"
                rowGap={1}
                sx={{
                    '& label': { whiteSpace: 'nowrap !important' }
                }}
            >
                <Grid xs={6}>
                    <NumericInput
                        placeholder="0.00"
                        name="Selling Price"
                        label="Selling Price"
                        value={sellingPrice}
                        onChange={(e) => onSellingPriceChange(e.target.value)}
                        data-testid={`custom-selling-price-${vin}`}
                        required={true}
                        allowDecimal={true}
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        inputPrefix={'$'}
                        autoInsertCommas={true}
                        minValue={0.01}
                        customErrorMessages={{
                            lessThanMinValue: 'The value should be more than 0.'
                        }}
                    />
                </Grid>
                <Grid xs={6}>
                    <NumericInput
                        placeholder="0"
                        name="Odometer"
                        label={'Odometer'}
                        value={odometer}
                        onChange={(e) => onOdometerChange(e.target.value)}
                        data-testid={`custom-odometer-${vin}`}
                        required={true}
                        allowDecimal={false}
                        autoInsertCommas={true}
                        minValue={minOdometerValue}
                        customErrorMessages={{
                            lessThanMinValue: 'The value should be more than 0.'
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};
