// libraries
import type { FSA } from '@makemydeal/dr-platform-shared';
import type { DealHistory } from '@makemydeal/dr-dash-types';
import { actions } from '@makemydeal/dr-offer-redux';

// consts/enums
import { UPDATE_QUICK_COMPARE_OFFER } from '../actionTypes/quickCompareActionTypes';

export type MakeCurrentOfferAction = FSA<typeof actions.MAKE_CURRENT_OFFER, DealHistory>;

export const updateQuickCompareOffer = (offer: DealHistory) => ({ type: UPDATE_QUICK_COMPARE_OFFER, payload: offer });

export const makeCurrentOffer = (offer: DealHistory) => {
    const action: MakeCurrentOfferAction = {
        type: actions.MAKE_CURRENT_OFFER,
        payload: offer
    };
    return action;
};
