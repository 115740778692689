/**
 * PURPOSE: Contains logic related to the application bootstrap process.
 */

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { payloadUtils, queryParamStore } from '@makemydeal/dr-shared-ui-utils';

// consts/enums
import * as bootstrapActionTypes from '../actionTypes/bootstrapActionTypes';
import * as initActionTypes from '../actionTypes/initActionTypes';

// interfaces/types
import type { InitContext } from '../types/initTypes';
import type { ReduxMiddleware, ReduxNext, ReduxStore } from '../types/middlewareTypes';

// utils
import { addRemainingGlobalReducers, addRemainingScopedReducers } from '../store/configureStoreCommon';

export type ReducersObject = Record<string, any>;
export type AddRemainingReducers = (reducers: ReducersObject) => void;
export type GetRemainingReducers = (initContext: InitContext) => ReducersObject;

export type GetDocumentTitle = (state: any) => string;

export type BuildBootstrapMiddlewareOptions = {
    getGlobalRemainingReducers: GetRemainingReducers;
    getScopedRemainingReducers?: GetRemainingReducers;
    getDocumentTitle: GetDocumentTitle;
};

export const buildBootstrapMiddleware = <TypeStateTree>(options: BuildBootstrapMiddlewareOptions) => {
    const bootstrapMiddleware: ReduxMiddleware<TypeStateTree> =
        (store: ReduxStore<TypeStateTree>) => (next: ReduxNext) => (action: AnyFSA) => {
            if (action.type === bootstrapActionTypes.BOOTSTRAP_SUCCESS) {
                const initContext: InitContext = {
                    featureToggles: payloadUtils.getFeatureTogglesFromInitPayload(action.payload),
                    dealer: payloadUtils.getDealerFromInitPayload(action.payload)
                };
                if (queryParamStore.isSmartOfferSavedDebuggingEnabled()) {
                    // eslint-disable-next-line no-console
                    console.log('DASH DEBUG: "Smart Offer Save" debugging is enabled');
                }

                const remainingGlobalReducers = options.getGlobalRemainingReducers(initContext);
                const remainingScopedReducers = options.getScopedRemainingReducers?.(initContext);
                addRemainingGlobalReducers(remainingGlobalReducers);
                remainingScopedReducers && addRemainingScopedReducers(remainingScopedReducers);

                store.dispatch({
                    type: initActionTypes.INIT_PENCIL_SUCCESS,
                    payload: action.payload
                });
            } else if (action.type === initActionTypes.INIT_SUCCESS) {
                const state = store.getState();
                const documentTitle = options.getDocumentTitle(state);
                if (documentTitle) {
                    document.title = documentTitle;
                }
            }

            return next(action);
        };
    return bootstrapMiddleware;
};
