import { Badge } from '@interstate/components/Badge';
import { RadioButtonList } from '@interstate/components/RadioButtonList';

import { DeskingCardSelectDraftDeal } from './DeskingCard.style';

export interface DeskingCardDealLabelProps {
    isPrimaryScope: boolean;
    scopeId: string;
    onChangePrimaryDeal: () => void;
}

export const DeskingCardDealLabel = ({ isPrimaryScope, scopeId, onChangePrimaryDeal }: DeskingCardDealLabelProps) => {
    return isPrimaryScope ? (
        <Badge variant="info" data-testid="primary-deal-label">
            <span>Primary</span>
        </Badge>
    ) : (
        <DeskingCardSelectDraftDeal>
            <RadioButtonList
                inline
                displayLabel={false}
                data-testid="draft-deal-select"
                options={[{ label: 'Select', value: scopeId }]}
                onChange={onChangePrimaryDeal}
            ></RadioButtonList>
        </DeskingCardSelectDraftDeal>
    );
};
