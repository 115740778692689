import { useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { LEASE, paymentServicesTypes } from '@makemydeal/dr-platform-types';
import ItemizedLeaseSummary from './ItemizedLeaseSummary';
import TaxItemSummary from './TaxItemSummary';

const ItemizedTaxesSummary = ({
    data,
    taxOverrideEnabled
}: {
    data: paymentServicesTypes.TaxItems;
    taxOverrideEnabled?: boolean;
}) => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);

    return (
        <>
            {offerType === LEASE && <ItemizedLeaseSummary data={data} taxOverrideEnabled={taxOverrideEnabled} />}
            {offerType !== LEASE && (
                <>
                    {data.map((taxItem, i) => (
                        <TaxItemSummary taxItem={taxItem} key={taxItem.taxName + i} taxOverrideEnabled={taxOverrideEnabled} />
                    ))}
                </>
            )}
        </>
    );
};

export default ItemizedTaxesSummary;
