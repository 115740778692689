/**
 * PURPOSE: Actions that trigger analytics will be processed by this middleware so that the business logic
 *   doesn't need to know how the analytics events will be relayed to various external tools used for this
 *   purpose (for example, Adobe Launch, Pixall etc.)
 */

// externals
import { AnyFSA } from '@makemydeal/dr-platform-shared';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    // TODO: DASH - implement
    next(action);
};
