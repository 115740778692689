// externals
import React from 'react';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { DealProductSummary } from '@makemydeal/dr-dash-types';

// styling
import { ProductSummaryWrapper, ProductSummaryLine } from './ProductSummary.style';

type ProductSummaryProps = {
    summary: DealProductSummary;
    isFinanceOrLease: boolean;
};

const ProductSummary = ({ summary, isFinanceOrLease }: ProductSummaryProps) => {
    const { totalProductCost, totalProductMarkup, totalProductPrice, productMonthlyPayment } = summary;

    return (
        <ProductSummaryWrapper data-testid="product-summary-container">
            <ProductSummaryLine>
                <span data-testid="product-summary-total-cost">Total Cost: {formatDollarsAndCents(totalProductCost)}</span>
            </ProductSummaryLine>
            <ProductSummaryLine>
                <span data-testid="product-summary-total-markup">Total Markup: {formatDollarsAndCents(totalProductMarkup)}</span>
            </ProductSummaryLine>
            <ProductSummaryLine>
                <span data-testid="product-summary-total-price">Total Price: {formatDollarsAndCents(totalProductPrice)}</span>
            </ProductSummaryLine>
            {isFinanceOrLease && (
                <ProductSummaryLine>
                    <span data-testid="product-summary-monthly-payment">
                        Monthly Payment: {formatDollarsAndCents(productMonthlyPayment)}
                    </span>
                </ProductSummaryLine>
            )}
        </ProductSummaryWrapper>
    );
};

export default ProductSummary;
