// externals
import { isPlainObject } from 'lodash';

// libraries
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';

import useEditableProp from './useEditableProp';
import { CRT_TAX, MUT_TAX } from '../../constants';

import { ETaxNames, CategorizedTaxes } from '../../types';

export const excludeUnamedTaxes = (taxes: paymentServicesTypes.TaxItems) => taxes.filter((tax) => tax.taxName);

export const hasTaxBreakdown = (taxItem: paymentServicesTypes.TaxItem): boolean => !!taxItem.taxBreakdown;

export const objectHasNumericValue = (data: Record<any, any> | undefined, options?: { includeZero?: boolean }) => {
    if (!isPlainObject(data)) {
        return false;
    }

    return Object.values(data!).some(
        (value) => typeof value === 'number' && isFinite(value) && (options?.includeZero ? true : value !== 0)
    );
};

export const categorizeTaxes = (taxItems: paymentServicesTypes.TaxItems, canOverride: boolean): CategorizedTaxes => {
    const categorizedTaxes = {} as CategorizedTaxes;
    const taxTypes = taxItems.map((item) => {
        return {
            item,
            type: item.taxName || item.class
        };
    });

    taxItems.forEach((item) => {
        let category = ETaxNames.ADDITIONAL;

        switch (item.taxName) {
            case CRT_TAX:
                category = ETaxNames.UPFRONT;
                break;

            case MUT_TAX:
                category = ETaxNames.MONTHLYUSE;
                break;

            default:
                category = canOverride ? ETaxNames.UPFRONT : ETaxNames.CAPITALIZED;
        }

        if (categorizedTaxes[category] == null) {
            categorizedTaxes[category] = [];
        }

        categorizedTaxes[category].push(item);
    });

    return categorizedTaxes;
};

export const getTaxOvverrideDisabled = (monthlyUseTaxTotal: number, capCostReductionTaxTotal: number): boolean => {
    return Boolean(monthlyUseTaxTotal || capCostReductionTaxTotal);
};

export const getTotalTaxRate = (taxBreakdown: paymentServicesTypes.TaxItemBreakdown) => {
    if (!taxBreakdown) {
        return 0;
    }

    const { stateTaxRate = 0, countyTaxRate = 0, cityTaxRate = 0, localTaxRate = 0 } = taxBreakdown;
    return stateTaxRate + countyTaxRate + cityTaxRate + localTaxRate;
};

type TaxRateKey = keyof Pick<
    paymentServicesTypes.TaxItemBreakdown,
    'localTaxRate' | 'cityTaxRate' | 'countyTaxRate' | 'stateTaxRate'
>;

export const isTaxRateKey = (key: string): key is TaxRateKey => {
    const rateKeys: TaxRateKey[] = ['localTaxRate', 'cityTaxRate', 'countyTaxRate', 'stateTaxRate'];
    return rateKeys.includes(key as TaxRateKey);
};

export const getHumanReadableTaxName = (taxName: string, mappingTable: Map<string, string>): string => {
    const lowerCaseTaxName = taxName.toLowerCase();

    if (mappingTable.has(lowerCaseTaxName)) {
        return mappingTable.get(lowerCaseTaxName) as string;
    }

    return taxName;
};

export { useEditableProp };
