export const getWeekAgoDate = (): Date => {
    const date = new Date();
    date.setDate(date.getDate() - 7);

    return date;
};

export const getNextYearDate = (): Date => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    return date;
};

export const normalizeDatePickerValue = (date?: string): string | undefined => {
    if (date === undefined) {
        return undefined;
    }

    return new Date(date).toISOString();
};
