import { useSelector } from 'react-redux';
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import TaxCategory from './TaxCategory';
import { categorizeTaxes } from './helpers';
import { CAPITALIZED_TAXES_CATEGORY, MONTHLY_USE_TAXES_CATEGORY, UPFRONT_TAXES_CATEGORY } from './../constants';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

const ItemizedLeaseSummary = ({
    data,
    taxOverrideEnabled
}: {
    data: paymentServicesTypes.TaxItems;
    taxOverrideEnabled?: boolean;
}) => {
    const isOverrideTaxAmountForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideTaxAmountForPaymentEnabled);
    const isTaxCapped = useSelector(offerReduxSelectors.isTaxCapped);
    const overrideUpfront = !isTaxCapped;

    const { capitalizedTaxes, monthlyUseTaxes, upfrontTaxes } = categorizeTaxes(data, overrideUpfront);

    return (
        <>
            <TaxCategory
                categoryTitle={CAPITALIZED_TAXES_CATEGORY}
                taxItems={capitalizedTaxes}
                dataTestId="category_capitalized_taxes"
                showUpfrontOverride={!taxOverrideEnabled && isTaxCapped}
                overrideUpfront={overrideUpfront}
                taxOverrideEnabled={taxOverrideEnabled}
            />
            <TaxCategory
                categoryTitle={UPFRONT_TAXES_CATEGORY}
                taxItems={upfrontTaxes}
                dataTestId="category_upfront_taxes"
                showUpfrontOverride={!taxOverrideEnabled && !isTaxCapped}
                overrideUpfront={overrideUpfront}
                taxOverrideEnabled={taxOverrideEnabled}
            />
            <TaxCategory
                categoryTitle={MONTHLY_USE_TAXES_CATEGORY}
                taxItems={monthlyUseTaxes}
                dataTestId="category_monthly_use_taxes"
                hideTaxNames={isOverrideTaxAmountForPaymentEnabled}
                taxOverrideEnabled={taxOverrideEnabled}
            />
        </>
    );
};

export default ItemizedLeaseSummary;
