// externals
import type { AnyAction, Reducer } from 'redux';
import { produce, Draft } from 'immer';

// types
import {
    PAYMENT_FEES_TYPES_REQUEST,
    PAYMENT_FEES_TYPES_SUCCESS,
    PAYMENT_FEES_TYPES_FAILURE
} from '../actionTypes/offerActionTypes';
import { PaymentFeesTypesState } from '../types';
import { initActionTypes } from '@makemydeal/dr-shared-store';
import { FeeCategory, FeesCategoriesType } from '@makemydeal/dr-shared-types';
import { IPencilOfferInputsDealerFee, CASH, GovernmentFees, ItemizedGovernmentFee, LEASE } from '@makemydeal/dr-platform-types';
import { mapDealerFees, mapGovernmentFees } from '../utils/paymentFeesTypesUtils';

const initialState: PaymentFeesTypesState = {
    feesByCategory: {
        dealer: [],
        government: [],
        lender: []
    },
    feesTypesLoading: false,
    feesTypesError: ''
};

export const reducer: Reducer = (state = initialState, action: AnyAction) => {
    return produce(state, (draft: Draft<PaymentFeesTypesState>) => {
        switch (action.type) {
            case PAYMENT_FEES_TYPES_REQUEST: {
                draft.feesTypesLoading = true;
                break;
            }
            case PAYMENT_FEES_TYPES_SUCCESS: {
                draft.feesTypesLoading = false;
                action.payload.feesTypes.forEach((fee: FeeCategory, index: number) => {
                    if (index === 0) {
                        draft.feesByCategory = {
                            dealer: [],
                            government: [],
                            lender: []
                        };
                    }
                    switch (fee.category) {
                        case FeesCategoriesType.DEALER: {
                            draft.feesByCategory.dealer.push(fee);
                            break;
                        }
                        case FeesCategoriesType.GOVERNMENT: {
                            draft.feesByCategory.government.push(fee);
                            break;
                        }
                        case FeesCategoriesType.LENDER: {
                            draft.feesByCategory.lender.push(fee);
                            break;
                        }
                    }
                });
                break;
            }
            case PAYMENT_FEES_TYPES_FAILURE: {
                draft.feesTypesLoading = false;
                draft.feesTypesError = action.payload.error;
                break;
            }
            case initActionTypes.INIT_SUCCESS: {
                const offer = action.payload.offer;
                const offerType = offer.type;

                let paymentTermId;
                if (offerType != CASH) {
                    paymentTermId = offerType === LEASE ? offer.selectedLeaseTerm : offer.selectedFinanceTerm;
                }

                const dealerFees = action.payload.offer.payment.dealerFees as IPencilOfferInputsDealerFee[];

                const governmentFees = ((offerType === CASH ? offer[offerType].fees : offer.terms.byId?.[paymentTermId].fees) ||
                    {}) as GovernmentFees;

                // Filter and map dealer fees
                const dealerFeesOverrides = dealerFees.filter((fee) => (fee as any).isManualFee === true).map(mapDealerFees);

                // Filter and map government fees
                const governmentFeesOverrides =
                    governmentFees.fee?.taxFee?.filter((fee) => (fee as any).isManualFee === true).map(mapGovernmentFees) || [];

                // Create new state with fees overrides
                const newState = {
                    ...state,
                    feesOverrides: [...dealerFeesOverrides, ...governmentFeesOverrides]
                };

                return newState;
            }
        }
    });
};
