// externals
import React from 'react';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealHistory, DealState } from '@makemydeal/dr-dash-types';
import { compositeSelectors } from '@makemydeal/dr-dash-store';

// consts
import * as constants from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

const NetSellingPrice = ({ deal, hasDelta = false }: { deal?: DealHistory; hasDelta?: boolean }) => {
    const useDealRootSelector = createDealRootSelector(deal);
    const netSellingPrice = useDealRootSelector(compositeSelectors.getNetSellingPrice);

    return (
        <DealSummaryItem label={constants.NET_SELLING_PRICE} value={formatDollarsAndCents(netSellingPrice)} hasDelta={hasDelta} />
    );
};

export default NetSellingPrice;
