// TODO: use centralized component between here and activities-desking
// externals
import React, { FC } from 'react';

// libraries
import type { DealState } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// components
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';

// constants
import * as constants from '../../constants';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// hooks
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

export type RebatesProps = DealState & { hasDelta?: boolean };

export const Rebates: FC<RebatesProps> = ({ deal, hasDelta = false }) => {
    const useDealRootSelector = createDealRootSelector(deal);

    const rebateTotal = useDealRootSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);

    // NOTE: intentionally only showing rebate part of incentives
    return (
        <DealSummaryItemWrapper
            label={constants.REBATES}
            value={formatDollarsAndCents(rebateTotal)}
            useWrapper
            hasDelta={hasDelta}
        />
    );
};

export default Rebates;
