import styled from 'styled-components';
import { Grid } from '@interstate/components/Grid';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const GridItem = styled(Grid)`
    border: 1px solid #b2b2b2;
    width: 120px;
    height: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;

    @media (max-width: ${BreakPoint.SMALL}) {
        &.MuiGrid2-root {
            padding: 0 1%;
        }
    }
    @media (max-width: ${BreakPoint.MEDIUM}) {
        &.MuiGrid2-root {
            padding: 0 3%;
        }
    }
`;

export const StyledGridItem = styled(GridItem)<{ isSelected?: boolean; canSelect?: boolean }>`
    background-color: ${(props) => (props.isSelected ? '#003468' : '#ffffff')};
    color: ${(props) => (props.isSelected ? 'white' : '#16171A')};
    cursor: ${(props) => (props.canSelect ? 'pointer' : 'default')};
`;

export const StyledTypography = styled.div`
    font-size: 12px;
    &.large {
        font-size: 16px;
    }
`;

export const LoadingIndicatorWrapper = styled.div`
    position: relative;

    & #interstitial-overlay-root {
        background-color: transparent;
    }
    & #interstitial-content-root {
        box-shadow: none;
    }
`;

export const ContainerGrid = styled.div`
    container-name: ContainerGrid;
    container-type: inline-size;

    @container (min-width: 0px) {
        .payment-grid-item {
            padding: 0 1%;
        }
        // this is needed to make Interstate input digits smaller on small screen
        .sell-rate-input input {
            font-size: 15px;
        }
        // this is needed to remove gaps on Interstate input on small screen
        .MuiInputBase-root {
            gap: 3px !important;
            padding: 0 0.25rem 0 0.5rem !important;
        }
    }

    @container (min-width: ${BreakPoint.PHONE}) {
        .payment-grid-item {
            padding: 0 3%;
        }
        .sell-rate-input input {
            font-size: 16px !important;
        }
        .MuiInputBase-root {
            gap: 0.5rem !important;
            padding: 0 0.5rem 0 0.75 !important;
        }
    }

    @container (min-width: ${BreakPoint.SMALL}) {
        .payment-grid-item {
            padding: 0 5%;
        }
        .sell-rate-input input {
            font-size: 16px !important;
        }
        .MuiInputBase-root {
            gap: 0.5rem !important;
            padding: 0 0.5rem 0 0.75 !important;
        }
    }
`;
