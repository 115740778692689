import styled from 'styled-components';
import { rem } from 'polished';
import { BreakPoint } from '@makemydeal/dr-activities-common';

const responsiveText = () => `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const PrimaryText = styled.div`
    ${responsiveText}
    font-size: ${rem(16)};

    @media (min-width: ${BreakPoint.SMALL}) {
        font-size: ${rem(24)};
        font-weight: 400;
    }
    @media (min-width: ${BreakPoint.MEDIUM}) {
        font-size: ${rem(28)};
        font-weight: 400;
    }
`;

export const SecondaryText = styled.div`
    ${responsiveText}
    @media (min-width: ${BreakPoint.SMALL}) {
        font-size: ${rem(16)};
    }
`;

export const TertiaryText = styled.div`
    ${responsiveText}
    @media (min-width: ${BreakPoint.SMALL}) {
        font-size: ${rem(12)};
    }
`;

export const CurrencyText = styled.span`
    ${responsiveText}
    font-weight: 500;
`;
