// hooks
import { useSelector } from 'react-redux';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealHistory, StateTree } from '@makemydeal/dr-dash-types';
import { dealEffectiveSelectors } from '@makemydeal/dr-dash-store';

import * as constants from '../../constants';
import DealSummaryItem from '../common/DealSummaryItem';

const CashDown = ({ deal, hasDelta }: { deal?: DealHistory; hasDelta?: boolean }) => {
    const downPayment = useSelector((state: StateTree) => dealEffectiveSelectors.selectEffectiveDownPayment(state, deal));

    return <DealSummaryItem label={constants.CASH_DOWN} value={formatDollarsAndCents(downPayment)} hasDelta={hasDelta} />;
};

export default CashDown;
