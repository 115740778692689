import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import { ApiUrlBuilder } from './types';

export const apiUrlBuilderFromAppPrefix = (appPrefix: string) => {
    const locHref = locHrefUtil.getLocHref();
    const defaultApiUrlBuilder: ApiUrlBuilder = {
        buildBffApiUrl(path) {
            return apiConfigHelper.buildBffApiUrl(appPrefix, locHref, path);
        }
    };
    return defaultApiUrlBuilder;
};
