import { AnyAction } from 'redux';
import { ActionWithScopedMeta, ScopedActionMeta } from '../types/scopedActionTypes';

type AnyMeta = Record<string, any>;
type WithAnyMeta = { meta?: AnyMeta };

/* eslint-disable-next-line  @typescript-eslint/ban-types */
type FunctionWithAnyMeta = Function & WithAnyMeta;

type ScopeMetaWithTargetScope = Omit<ScopedActionMeta['__scopeMeta'], 'targetScope'> &
    Required<Pick<ScopedActionMeta['__scopeMeta'], 'targetScope'>>;
type ActionMetaWithTargetScope<T> = ScopedActionMeta<T> & ScopeMetaWithTargetScope;

export const createScopedActionMeta = <T extends AnyMeta>(targetScope: string, existingMeta?: T): ActionMetaWithTargetScope<T> => {
    return {
        ...existingMeta,
        __scopeMeta: {
            targetScope,
            ...existingMeta?.__scopeMeta
        }
    } as ActionMetaWithTargetScope<T>;
};

/**
 * Injects targetScope metadata on action, causing it to only affect the given scope when dispatched.
 * It is only necessary to use the function if you intend to affect a different scope rather than the one in context
 * */
export const createScopedAction = <A extends AnyAction | FunctionWithAnyMeta>(scopeId: string, action: A) => {
    const existingMeta = 'meta' in action ? (action.meta as AnyMeta) : undefined;
    const newMetadata = createScopedActionMeta(scopeId, existingMeta);

    let newAction: A;

    if (typeof action === 'function') {
        // redux thunk actions
        newAction = ((...args: any[]) => action(...args)) as any;
        newAction.meta = newMetadata;
    } else {
        newAction = {
            ...action,
            meta: newMetadata
        };
    }

    return newAction;
};

export const getTargetScopeFromAction = (action: ActionWithScopedMeta) => {
    return action?.meta?.__scopeMeta?.targetScope;
};
