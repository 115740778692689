import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const FlexFeesButtonContainer = styled.div`
    padding-top: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 1rem;

    @media (min-width: ${BreakPoint.SMALL}) {
        flex-direction: row;
    }
`;

export const FeesFormItemsContainer = styled.div`
    display: flex;
    padding-top: 1rem;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;

    hr {
        margin: 1rem 0 0;
        border-bottom: 1px solid var(--Border-default, #b2b2b2);
    }

    .delete-fee-button {
        width: 5.5rem;
        height: 2.78rem;
    }

    .edit-fee--code-cell {
        flex-basis: 45%;
    }

    .edit-fee--name-cell {
        flex-basis: 80%;
    }

    .edit-fee--amount-cell {
        flex-basis: 50%;
    }

    label {
        white-space: nowrap !important;
    }
`;

export const ActionStyle = styled.div`
    margin: 1rem 0;

    button > span,
    .ind-action {
        font-size: 1.25rem;
        font-weight: 600;
    }
`;

export const EditFeesRowDivider = styled.hr`
    margin: 1rem 0 0;
`;
