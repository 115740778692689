import isEmpty from 'lodash.isempty';
import { ManualIncentiveEntry } from '@makemydeal/dr-platform-types';
import { ManualIncentiveEditEntry } from '../types/manualIncentiveTypes';

export const transformToManualEditIncentive = (incentive: ManualIncentiveEntry): ManualIncentiveEditEntry => {
    return {
        ...incentive,
        originalAmount: incentive.originalAmount || incentive.amount,
        deleted: incentive.deleted ?? false,
        type: incentive.type || ''
    } as ManualIncentiveEditEntry;
};
export const transformToEditIncentive = (term: any, incentives: any[]): ManualIncentiveEditEntry[] => {
    if (incentives) {
        incentives.forEach((incentive: ManualIncentiveEntry) => {
            incentive.amount = transformIncentiveAmount(term, incentive);
        });
        return incentives.map(transformToManualEditIncentive);
    }
    return [];
};

export const isOriginalIncentive = ({ name, description }: ManualIncentiveEntry): boolean =>
    !isEmpty(description) && description !== name;

export function transformIncentiveAmount(term: any, incentive: any) {
    let newIncentiveAmount = incentive?.amount;

    if (newIncentiveAmount === 0) {
        const termRangeList = incentive.termRebates?.incentiveTermRange || [];
        termRangeList.forEach((termRange: any) => {
            if (term >= termRange.startTerm && term <= termRange.endTerm) {
                newIncentiveAmount = termRange.customerCash;
            }
        });
    }

    return newIncentiveAmount;
}
