import { ReactNode } from 'react';
import { useMemo } from 'react';
import { Provider, useStore } from 'react-redux';
import { Store } from 'redux';
import { RealState } from '../types/scopeState';
import { scopedStoreManager, isScopedStore, ScopedStore } from '../store/scopedStoreManager';
import { getIsScopedStoreEnabled } from '../selectors/scopedStateSelectors';

export type ScopedStateProviderValue = {
    scopeId?: string;
    /**
     * Scope will always default to Primary if scopeId is not provided,
     * The Primary serves only as a clearer indication of what will happen
     */
    primary?: boolean;
};

export type ScopedStateProviderProps = ScopedStateProviderValue & {
    children: ReactNode;
    navigationScope?: boolean;
};

export const isScopedStoreEnabled = (store: Store) => {
    return getIsScopedStoreEnabled(store.getState());
};

export const ScopedStateProvider = ({ scopeId, primary, navigationScope, children }: ScopedStateProviderProps) => {
    const store: ScopedStore | Store<RealState> = useStore();
    const scopedStoreEnabled = isScopedStoreEnabled(store);
    const scopedStore = useMemo(() => {
        return (
            scopedStoreEnabled &&
            scopedStoreManager.getStore(
                isScopedStore(store) ? store.__realStore : store,
                primary ? undefined : scopeId,
                navigationScope
            )
        );
    }, [scopeId, primary, store, scopedStoreEnabled, navigationScope]);

    // We check '!scopedStore' so TS knows this value is not false after this check
    if (!scopedStoreEnabled || !scopedStore) return <>{children}</>;

    return <Provider store={scopedStore as Store}>{children}</Provider>;
};
