import styled from 'styled-components';

export const OfferSaveBusyIndicatorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    #ids-spinner-root {
        margin-bottom: 20px;
    }
`;
