// externals
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NumericInput } from '@interstate/components/NumericInput';
import { ToggleGroup } from '@interstate/components/ToggleGroup/ToggleGroup/ToggleGroup';

// libraries
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import { deskingSelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { ResidualValues } from '@makemydeal/dr-offer-redux';

import { RESIDUAL_DOLLAR, RESIDUAL_PERCENT, ResidualValueType } from '@makemydeal/dr-platform-types';

// consts/enums
import { DEBOUNCE_TIMER, GENERIC_LAST_FIELD_CHANGED_ERROR } from '../../constants';

// utils
import { convertToNumber, formatAprRate } from '../../utils/formatUtils';
import {
    StyledTextWithToggleGroupContainer,
    StyledTextWithToggleGroupText,
    StyledTextWithToggleGroupToggle
} from './StyledTextWithToggleGroup.styles';

type ResidualProps = {
    inputProps: {
        residualType: ResidualValueType;
        showDisabled?: boolean;
        residualAmount: number;
        residualPercent: number;
        updateResidualOverride: any;
        id: string;
        label: string;
        groupId: string;
        groupTestId: string;
        valueTestId: string;
        dollarTestId: string;
        percentTestId: string;
        dollarOverrideAction: string;
        percentOverrideAction: string;
    };
};

enum ResidualActions {
    NO_ACTION,
    OVERRIDE_WITH_PAYMENT,
    OVERRIDE_WITHOUT_PAYMENT,
    CLEAR_OVERRIDE_WITH_PAYMENT,
    CLEAR_OVERRIDE_WITHOUT_PAYMENT
}

const ResidualField = ({ inputProps }: ResidualProps) => {
    const dispatch = useDispatch();

    const months = useSelector(offerReduxSelectors.getTermLength) as number;
    const failedDollar = useSelector(deskingSelectors.wasTheLastFailure(inputProps.dollarOverrideAction));
    const failedPercent = useSelector(deskingSelectors.wasTheLastFailure(inputProps.percentOverrideAction));
    const isUserProgramQuotes = useSelector(offerReduxSelectors.getIsUserProgramQuotes);

    const isPaymentError = useSelector(offerReduxSelectors.getIsError);
    const isCalculatingPayment = useSelector(offerReduxSelectors.isCalculatingPayment);

    const mounted = useRef(false);

    const [unitType, setUnitType] = useState(inputProps.residualType);

    const isUnitTypePercentage = useMemo((): boolean => {
        return unitType === RESIDUAL_PERCENT;
    }, [unitType]);

    const getUnitTypeValue = useMemo((): number => {
        return isUnitTypePercentage ? inputProps.residualPercent : inputProps.residualAmount;
    }, [unitType, inputProps.residualAmount, inputProps.residualPercent]);

    const getPrefixByType = useCallback(
        (percent?: boolean): string => {
            if (percent == null) {
                return isUnitTypePercentage ? '%' : '$';
            }
            return percent ? '%' : '$';
        },
        [unitType]
    );

    const getPlaceholdeByType = useMemo((): string => {
        return isUnitTypePercentage ? '0.00000' : '0.00';
    }, [unitType]);

    const getFormatted = useCallback((value: number): string => {
        return isUnitTypePercentage ? formatAprRate(value) : formatDollarsAndCents(value);
    }, []);

    // NOTE: due to the debouncing, etc this function isn't getting coverage credit, so ignore...
    const actionCaller = (residualValues: ResidualValues, makePaymentCall: boolean): void => {
        // we're only going to dispatch one action when the value itself ischanged. But the type will persist alongside the value.
        dispatch(inputProps.updateResidualOverride(residualValues, makePaymentCall));
    };

    const debouncedActionCaller = useCallback(debounce(actionCaller, DEBOUNCE_TIMER), []);

    const [unitValue, setUnitValue] = useState({ value: getUnitTypeValue, action: ResidualActions.NO_ACTION });

    const reducer = useCallback(
        (actionType: ResidualActions) => {
            switch (actionType) {
                case ResidualActions.OVERRIDE_WITH_PAYMENT:
                    // override value with payment call
                    unitValue.action = ResidualActions.NO_ACTION;
                    debouncedActionCaller({ value: unitValue.value, months, unitType }, true);
                    break;

                case ResidualActions.OVERRIDE_WITHOUT_PAYMENT:
                    // override value w/o payment call
                    unitValue.action = ResidualActions.NO_ACTION;
                    actionCaller({ value: unitValue.value, months, unitType }, false);
                    break;

                case ResidualActions.CLEAR_OVERRIDE_WITH_PAYMENT:
                    // clear override with payment call
                    unitValue.action = ResidualActions.NO_ACTION;
                    if (!isUserProgramQuotes) {
                        actionCaller({ value: undefined, months, unitType }, true);
                    }
                    break;

                case ResidualActions.CLEAR_OVERRIDE_WITHOUT_PAYMENT:
                    // clear override w/o payment call
                    unitValue.action = ResidualActions.NO_ACTION;
                    if (!isUserProgramQuotes) {
                        actionCaller({ value: undefined, months, unitType }, false);
                    }
                    break;

                default:
                    break;
            }
        },
        [unitType, unitValue.value, unitValue.action]
    );

    useEffect(() => {
        if (!mounted.current) {
            return;
        }
        setUnitValue({ value: getUnitTypeValue, action: ResidualActions.NO_ACTION });
    }, [inputProps.residualAmount, inputProps.residualPercent]);

    useEffect(() => {
        if (!mounted.current) {
            return;
        }
        if (!isCalculatingPayment) {
            setUnitValue({ value: getUnitTypeValue, action: ResidualActions.NO_ACTION });
        }
    }, [isCalculatingPayment, isPaymentError]);

    useEffect(() => {
        if (!mounted.current) {
            // add default baseResidualValue override if UPQ
            if (isUserProgramQuotes) {
                setUnitValue({ value: unitValue.value, action: ResidualActions.OVERRIDE_WITHOUT_PAYMENT });
            }
            return;
        }

        setUnitValue({ value: getUnitTypeValue, action: ResidualActions.CLEAR_OVERRIDE_WITHOUT_PAYMENT });
    }, [unitType]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }
        reducer(unitValue.action);
    }, [unitValue.value, unitValue.action]);

    const handleChangeUnitType = (newType: ResidualValueType): void => {
        setUnitType(newType);
    };

    const handleTextChanged = (e: any) => {
        const strValue = e.target.value;
        const numValue = convertToNumber(strValue);
        const value = strValue.length ? numValue : 0;
        const emptyAction = isUserProgramQuotes
            ? ResidualActions.OVERRIDE_WITH_PAYMENT
            : ResidualActions.CLEAR_OVERRIDE_WITH_PAYMENT;
        const action = strValue.length ? ResidualActions.OVERRIDE_WITH_PAYMENT : emptyAction;

        if (value !== unitValue.value) {
            setUnitValue({ value, action });
        }
    };

    const failedMessage = useMemo(
        () => (failedPercent || failedDollar ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined),
        [failedPercent, failedDollar]
    );

    return (
        <StyledTextWithToggleGroupContainer>
            <StyledTextWithToggleGroupText>
                <NumericInput
                    id={inputProps.id}
                    name={kebabCase(inputProps.label)}
                    label={inputProps.label}
                    value={getFormatted(unitValue.value)}
                    disabled={inputProps.showDisabled}
                    onChange={handleTextChanged}
                    hasError={Boolean(failedMessage)}
                    placeholder={getPlaceholdeByType}
                    inputPrefix={getPrefixByType()}
                    data-testid={inputProps.valueTestId}
                    errorMessage={failedMessage}
                    allowDecimal={true}
                    decimalMaxLength={3}
                    decimalMinLength={2}
                    autoInsertCommas={true}
                />
            </StyledTextWithToggleGroupText>
            <StyledTextWithToggleGroupToggle>
                <ToggleGroup
                    size="medium"
                    id={inputProps.groupId}
                    data-testid={inputProps.groupTestId}
                    toggleButtonOptions={[
                        {
                            id: 'dollarToggle',
                            value: getPrefixByType(false),
                            children: <span>{getPrefixByType(false)}</span>,
                            selected: !isUnitTypePercentage,
                            'data-testid': inputProps.dollarTestId,
                            onClick: (e: any) => {
                                handleChangeUnitType(RESIDUAL_DOLLAR);
                            }
                        },
                        {
                            id: 'percentToggle',
                            value: getPrefixByType(true),
                            children: <span>{getPrefixByType(true)}</span>,
                            selected: isUnitTypePercentage,
                            'data-testid': inputProps.percentTestId,
                            onClick: (e: any) => {
                                handleChangeUnitType(RESIDUAL_PERCENT);
                            }
                        }
                    ]}
                    toggleWidth="60px"
                />
            </StyledTextWithToggleGroupToggle>
        </StyledTextWithToggleGroupContainer>
    );
};

export default ResidualField;
