import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const IncentiveTotalsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const IncentiveTotalsColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 16px 0 16px 0;

    @media (min-width: ${BreakPoint.SMALL}) {
        margin: 16px 16px 16px 0;
    }

    svg {
        color: ${({ theme }) => theme.linkColor};
    }
`;

export const IncentiveCurrentTotal = styled.div`
    display: flex;
    align-items: center;
`;

export const IncentiveTotalsRow = styled.div`
    display: flex;
    justify-content: space-between;
`;
