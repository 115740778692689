import { TextInput } from '@interstate/components/TextInput';
import { manualIncentivesActionCreators, manualIncentivesSelectors } from '@makemydeal/dr-dash-store';
import { ManualIncentiveEditEntry } from '@makemydeal/dr-platform-types';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { columnMappings } from './columnMappings';
import { useMediaQuery } from 'react-responsive';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const TextField: FC<{ index: number; field: keyof ManualIncentiveEditEntry }> = ({ field, index }) => {
    const dispatch = useDispatch();
    const showErrors = useSelector(manualIncentivesSelectors.getManualIncentivesShowValidation);
    const isSmallOrLarger = useMediaQuery({ query: `(min-width: ${BreakPoint.SMALL})` });
    const isEmpty = useSelector(manualIncentivesSelectors.isManualIncentiveFieldEmptyAtIndex(index, field));

    const deleted = useSelector(
        manualIncentivesSelectors.getManualIncentiveEditValueAtIndexAndField(index, 'deleted')
    ) as ManualIncentiveEditEntry['deleted'];

    const column = columnMappings.find((c) => c.fieldName === field)!;

    const fieldValue = useSelector(manualIncentivesSelectors.getManualIncentiveEditValueAtIndexAndField(index, field)) ?? '';
    const [text, setText] = useState(fieldValue.toString());

    const handleChange = (value: string) => {
        setText((_old) => value);
    };

    useEffect(() => {
        const id = setTimeout(() => {
            // istanbul ignore next #This is clearly being tested and passing.
            if (text === fieldValue.toString()) return;
            dispatch(manualIncentivesActionCreators.updateShowValidation(false));
            dispatch(manualIncentivesActionCreators.updateManualIncentiveField(index, field, text));
        }, 200);
        return () => {
            clearTimeout(id);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    return (
        <>
            {!isSmallOrLarger && field === 'code' && <span data-testid="flex-spacer" />}
            <TextInput
                displayLabel={!isSmallOrLarger} // || index === 0
                id={`${field}-${index}`}
                data-testid={`${field}-${index}`}
                label={column.label}
                className={`text-input-${field}`}
                name={`${field}-${index}`}
                onChange={(e) => handleChange(e.target.value)}
                placeholder=""
                hasError={showErrors && isEmpty && !deleted && column.required}
                errorMessage="Required"
                value={text}
                disabled={deleted}
            />
        </>
    );
};
