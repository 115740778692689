// externals
import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { Box } from '@interstate/components/Box';
import EditProductPlanDrawer from './EditProductPlanDrawer';

// components
import LabelWithToolTipIcon from '../form/LabelWithToolTipIcon';
import { ProviderPlanError } from './ProviderPlanError';

// store
import { vehicleProtectionSelectors } from '@makemydeal/dr-dash-store';

// types
import { ProductPlanDetails, StateTree } from '@makemydeal/dr-dash-types';
import { RatedProtectionProduct } from '@makemydeal/dr-platform-types';
import { EditProductPlanProps } from '../../types';

// utils
import { hasCorrectPlan } from '../../utils';

export const EditProductPlan = ({
    planDisplayText,
    productProps,
    onPlanEdit
}: {
    planDisplayText: string;
    productProps: EditProductPlanProps;
    onPlanEdit: (productCode: string, productPlan: string) => void;
}) => {
    const [open, setOpen] = useState(false);

    const ratedProduct = useSelector<StateTree, RatedProtectionProduct | undefined>((state) =>
        vehicleProtectionSelectors.getVppRatedProduct(state, productProps.productCode)
    );

    const plans = useSelector<StateTree, ProductPlanDetails[]>((state) =>
        vehicleProtectionSelectors.getPlansForProductsWithSameCategoryAndProvider(state, productProps.productCode)
    );

    const hasInapplicablePlan = useMemo(
        () => !hasCorrectPlan(ratedProduct?.rates || [], ratedProduct?.rateAttributes || [], productProps.productProviderPlan),
        [ratedProduct, productProps.productProviderPlan]
    );

    const isEditEnabled = plans.length > 1 || hasInapplicablePlan;

    return (
        <div data-testid="edit-product-plan">
            <Box display="flex" justifyContent="space-between" marginBottom="1rem" paddingTop="0.5rem">
                <LabelWithToolTipIcon
                    name="product-provider-plan"
                    title="Plan/Coverage"
                    toolTipContent="Plans can be edited if there are at least two options available."
                    style={{ marginBottom: '0', marginTop: '0' }}
                    dataTestId="edit-product-plan-label"
                />
                {isEditEnabled && (
                    <Action size="sm" onClick={() => setOpen(true)} data-testid="edit-product-plan-edit">
                        Edit
                    </Action>
                )}
            </Box>
            <Box marginBottom="1rem">
                <Typography variant="body-md" data-testid="edit-product-plan-value">
                    {planDisplayText}
                </Typography>
                {hasInapplicablePlan && <ProviderPlanError />}
            </Box>
            <EditProductPlanDrawer
                open={open}
                onClose={() => setOpen(false)}
                productProps={productProps}
                onEdit={onPlanEdit}
                plans={plans}
                hasInapplicablePlan={hasInapplicablePlan}
            />
        </div>
    );
};
