import styled from 'styled-components';
import { Grid } from '@interstate/components/Grid';

export const DialogHeader = styled.h3``;

export const DialogParagraph = styled.p`
    margin-bottom: 24px;
`;

export const LoadingContainer = styled.div`
    text-align: center;

    p {
        margin: 10px 0px;
    }
`;

export const DialogBody = styled.div`
    text-align: left;
`;

export const DialogFooter = styled.div`
    text-align: right;
`;

export const FooterGrid = styled(Grid)`
    padding: 20px 32px 32px;
`;
