export const tableGlobalAlertMessages = {
    hasProductsWithInapplicableAttributes: 'Some selected products have inapplicable product properties.',
    menuPricingDisabled: 'Product prices are hidden from shoppers due to your dealer settings.',
    missingRequiredAttributes: 'Some products have missed deal attributes.',
    paymentFailed: 'An application error occurred while trying to calculate payments.',
    productsLoading: 'Please wait, we are loading products data...',
    ratingFailed: "Some products can't be added based on selected deal attributes.",
    ratingTimeout: 'No product rates were returned due to timeout.',
    unsupportedPaymentProduct: 'Some products are causing payment calculation issues.',
    vppDisabled: 'Vehicle protection products are hidden from shoppers due to your dealer settings.'
};
