import styled from 'styled-components';

// constants
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const ToastContainerWrapper = styled.div`
    .Toastify__toast-container {
        top: 16rem;

        @media (max-width: ${BreakPoint.PHONE}) {
            top: 19rem;
        }
    }
`;
