// externals
import { ITradeIn } from '@makemydeal/dr-platform-types';
import { Reducer } from 'redux';

// libraries
import { initActionTypes } from '@makemydeal/dr-shared-store';

// consts/enums
import * as tradeActionTypes from '../actionTypes/tradeActionTypes';
import { FetchVinDecodeResponse } from '../actionTypes/tradeActionTypes';
import { extractTrimFromVehicles, extractTrimFromVehicleTitle } from '../utils/tradeVehicleTitle';

// utils
import { DealExchangeValidator } from '@makemydeal/dr-shared-ui-utils';

export type TradeInState = ITradeIn & {
    errorDecodingVin?: boolean;
    hasBeenSaved?: boolean;
    hasMultipleVin?: boolean;
    isSkipped: boolean;
    isTradeBeingSaved?: boolean;
    multipleVinOptions?: FetchVinDecodeResponse | [];
    trimDecodedOptions?: { name: string; id: string }[];
    openManualForm?: boolean;
    isBuyerRegisteredOwner?: boolean;
    isSellingVehicleRegisteredInSameState?: boolean;
    vinHasBeenDecoded: boolean;
};

export type TradeInReducer = Reducer<TradeInState>;

export const initialState: TradeInState = {
    amountOwed: 0,
    bodyStyle: '',
    color: '',
    condition: '',
    doors: '',
    driveLine: '',
    engine: '',
    expirationDate: '',
    goodThroughDate: '',
    interiorColor: '',
    isAmountAppliedToFinancing: false,
    isCompleted: false,
    isSkipped: false,
    lienHolder: '',
    make: '',
    makeId: '',
    mileage: 0,
    model: '',
    modelId: '',
    offerDate: '',
    offerId: '',
    openManualForm: false,
    owed: 0,
    ownershipType: '',
    perDiemInterest: 0,
    isBuyerRegisteredOwner: false,
    isSellingVehicleRegisteredInSameState: false,
    source: '',
    status: '',
    transmission: '',
    trim: '',
    trimId: '',
    value: 0,
    vehicleId: '',
    vehicleOptions: {},
    vin: '',
    vinHasBeenDecoded: false,
    year: 0
};

export const reducer: TradeInReducer = (state: TradeInState = initialState, action: any) => {
    switch (action.type) {
        case initActionTypes.INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...(action.payload.trade?.trade || {})
            };
        }
        case tradeActionTypes.REMOVE_TRADE_IN: {
            return initialState;
        }
        case tradeActionTypes.UPDATE_TRADE_IN: {
            const tradeIn = action.payload || {};
            return { ...state, ...tradeIn };
        }
        case tradeActionTypes.SKIP_TRADE_IN: {
            return { ...state, ...{ isSkipped: true } };
        }
        case tradeActionTypes.TRADE_FLOW_COMPLETED: {
            return { ...state, success: true, isCompleted: true };
        }
        case tradeActionTypes.ACV_CHANGED: {
            return { ...state, acv: action.payload };
        }
        case tradeActionTypes.ALLOWANCE_CHANGED: {
            return { ...state, value: action.payload };
        }
        case tradeActionTypes.PAYOFF_CHANGED: {
            return { ...state, owed: action.payload, amountOwed: action.payload };
        }
        case tradeActionTypes.TRADE_IN_ESTIMATION_SUCCESS: {
            if (state.condition) {
                const tradeValue = action.payload.find(({ name }: any) => name === state.condition)?.value || 0;
                return { ...state, value: tradeValue };
            } else {
                return state;
            }
        }
        case tradeActionTypes.TRADE_IN_UPDATE_PAYOFF_DUE_DATE: {
            return { ...state, goodThroughDate: action.payload.goodThroughDate };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_LIENHOLDER: {
            return { ...state, lienHolder: action.payload.lienHolder };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_LIENHOLDER_FULL: {
            return { ...state, ...action.payload };
        }
        case tradeActionTypes.TRADE_IN_ODOMETER: {
            return { ...state, mileage: parseInt(action.payload.odometer) };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_BODY_STYLE: {
            return { ...state, bodyStyle: action.payload.bodyStyle };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_DOORS: {
            return { ...state, doors: action.payload.doors };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_INTERIOR_COLOR: {
            return { ...state, interiorColor: action.payload.interiorColor };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_IS_BUYER_REGISTERED_OWNER: {
            return { ...state, isBuyerRegisteredOwner: action.payload.isBuyerRegisteredOwner };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_IS_SELLING_VEHICLE_REGISTERED_IN_SAME_STATE: {
            return { ...state, isSellingVehicleRegisteredInSameState: action.payload.isSellingVehicleRegisteredInSameState };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_OWNERSHIP_TYPE: {
            return { ...state, ownershipType: action.payload.ownershipType };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_PER_DIEM: {
            return { ...state, perDiemInterest: action.payload.perDiemInterest };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_VIN: {
            return {
                ...state,
                vin: action.payload.vin,
                vinHasBeenDecoded: state.vinHasBeenDecoded ? !(action.payload.vin.length === 0) : false,
                ...(action.payload.vin.length === 0 && {
                    bodyStyle: '',
                    color: '',
                    doors: '',
                    driveLine: '',
                    engine: '',
                    hasMultipleVin: false,
                    interiorColor: '',
                    make: '',
                    makeId: '',
                    mileage: 0,
                    model: '',
                    modelId: '',
                    transmission: '',
                    trim: '',
                    trimId: '',
                    vehicleId: '',
                    year: '',
                    multipleVinOptions: [],
                    trimDecodedOptions: []
                })
            };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_YEAR: {
            return {
                ...state,
                year: action.payload.year,
                make: '',
                makeId: '',
                model: '',
                modelId: '',
                trim: '',
                trimId: '',
                vehicleId: ''
            };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_MAKE: {
            return {
                ...state,
                make: action.payload.name,
                makeId: action.payload.id.toString(),
                model: '',
                modelId: '',
                trim: '',
                trimId: '',
                vehicleId: ''
            };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_MAKE_ID: {
            return {
                ...state,
                makeId: action.payload.toString()
            };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_MODEL_ID: {
            return {
                ...state,
                modelId: action.payload.toString()
            };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_MODEL: {
            return {
                ...state,
                model: action.payload.name,
                modelId: action.payload.id.toString(),
                trim: '',
                trimId: '',
                vehicleId: ''
            };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_TRIM: {
            return {
                ...state,
                trim: action.payload.trim,
                trimId: action.payload.trimId
            };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_CONDITION: {
            return {
                ...state,
                condition: action.payload.condition
            };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_ENGINE: {
            return {
                ...state,
                engine: action.payload.engine
            };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_TRANSMISSION: {
            return {
                ...state,
                transmission: action.payload.transmission
            };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_DRIVE_LINE: {
            return {
                ...state,
                driveLine: action.payload.driveLine
            };
        }
        case tradeActionTypes.TRADE_IN_UPDATE_VEHICLE_COLOR: {
            return {
                ...state,
                color: action.payload.color
            };
        }
        case tradeActionTypes.TRADE_IN_VIN_REQUEST: {
            return { ...state, errorDecodingVin: false };
        }
        case tradeActionTypes.TRADE_IN_VIN_SUCCESS: {
            if (action.payload.length === 0) {
                return {
                    ...state,
                    errorDecodingVin: true
                };
            }

            if (action.payload.length > 1) {
                return {
                    ...state,
                    hasMultipleVin: true,
                    multipleVinOptions: action.payload,
                    trimDecodedOptions: extractTrimFromVehicles(action.payload),
                    vinHasBeenDecoded: false
                };
            }

            const {
                year,
                make,
                model,
                trim,
                id: vehicleId,
                bodyDescription,
                makeId,
                powertrain,
                title
            } = (action.payload as FetchVinDecodeResponse)[0];
            return {
                ...state,
                bodyStyle: bodyDescription.primaryBodyStyle,
                doors: bodyDescription.passengerDoors,
                driveLine: DealExchangeValidator.driveLine(powertrain?.drivetrain?.description) || '',
                engine: powertrain?.engine?.primaryDescription || '',
                make,
                makeId: makeId.toString(),
                model: model.normalizedName,
                modelId: model.normalizedModelId.toString(),
                transmission: powertrain?.transmission?.primaryDescription || '',
                trim: extractTrimFromVehicleTitle(title.split(' ')),
                trimId: trim.normalizedTrimId.toString(),
                trimDecodedOptions: extractTrimFromVehicles(action.payload),
                vehicleId: vehicleId.toString(),
                vinHasBeenDecoded: true,
                year
            };
        }
        case tradeActionTypes.TRADE_IN_MULTIPLE_VIN_SELECTED: {
            const { year, make, model, trim, id: vehicleId, bodyDescription, makeId, powertrain, title } = action.payload;

            return {
                ...state,
                bodyStyle: bodyDescription.primaryBodyStyle,
                doors: bodyDescription.passengerDoors,
                driveLine: DealExchangeValidator.driveLine(powertrain?.drivetrain?.description) || '',
                engine: powertrain?.engine?.primaryDescription || '',
                make,
                makeId: makeId.toString(),
                model: model.normalizedName,
                modelId: model.normalizedModelId.toString(),
                hasMultipleVin: false,
                transmission: powertrain?.transmission?.primaryDescription || '',
                trim: extractTrimFromVehicleTitle(title.split(' ')),
                trimId: trim.normalizedTrimId.toString(),
                vehicleId: vehicleId.toString(),
                vinHasBeenDecoded: true,
                year
            };
        }
        case tradeActionTypes.MANUAL_ENTRY_SAVE_TRADE_IN: {
            return { ...state, isCompleted: true };
        }
        case tradeActionTypes.MANUAL_ENTRY_CANCEL_TRADE_IN: {
            const state = action.payload;
            return { ...state, isTradeBeingSaved: false };
        }
        case tradeActionTypes.TRADE_IN_VIN_FAILURE: {
            return { ...state, errorDecodingVin: true };
        }
        case tradeActionTypes.TRADE_IN_TOGGLE_SAVING: {
            return { ...state, isTradeBeingSaved: action.payload.isSaving };
        }
        default: {
            return state;
        }
    }
};
