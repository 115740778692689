interface FniCreditDecisionWrapper {
    dealRefId: string;
    selectedLenderId: string;
    environment: string;
    routeOnePartyId?: string;
    creditProviderIsRouteOne?: boolean;
}

interface FniCreditDecisionWidget extends Partial<HTMLElement> {
    'deal-ref-id': string;
    'lender-id': string;
    env: string;
    partner_dealer_id?: string;
    partner_id?: string;
    is_route_one?: string;
}

namespace JSX {
    interface IntrinsicElements {
        'fni-credit-decision-widget': FniCreditDecisionWidget;
    }
}
