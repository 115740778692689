// libraries
import { ActivityMilestoneState } from '@makemydeal/dr-dash-types';

// consts/enums
import { UPDATE_DEAL_PROGRESS_MILESTONES } from '../actionTypes/dpmActionTypes';

export const updateMilestones = (updatedMilestoneData: ActivityMilestoneState) => {
    return {
        type: UPDATE_DEAL_PROGRESS_MILESTONES,
        payload: { updatedMilestoneData }
    };
};
