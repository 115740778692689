import { InterstateDealSummarySections } from '@makemydeal/dr-dash-components';
import { List } from '@interstate/components/List';

const Finance = () => (
    <List data-testid="finance-deal-summary">
        <InterstateDealSummarySections.InterstateMSRP />
        <InterstateDealSummarySections.InterstateDiscount />
        <InterstateDealSummarySections.InterstateSellingPrice />
        <InterstateDealSummarySections.InterstateAddons />
        <InterstateDealSummarySections.InterstateNetSellingPrice />
        <InterstateDealSummarySections.InterstateNetTrade />
        <InterstateDealSummarySections.InterstateTotalTax />
        <InterstateDealSummarySections.InterstateRebates />
        <InterstateDealSummarySections.InterstateCashDown />
        <InterstateDealSummarySections.InterstateAmountFinanced />
    </List>
);

export default Finance;
