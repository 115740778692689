import { HostEnvironment } from '@makemydeal/dr-shared-ui-utils';
export type OktaAuthBaseConfig = {
    id: string;
    issuer: string;
    clientId: string;
    scopes: string[];
    // TODO: Seems this "system" is not used
    system: string;
};

export type OktaAuthConfig = OktaAuthBaseConfig & {
    tokenManager?: oktaTokenManager;
    disable?: boolean;
};

export type oktaTokenManager = {
    expireEarlySeconds: number;
    autoRenew: boolean;
    autoRemove: boolean;
};
export type AuthConfigCallback = (isProduction: boolean) => OktaAuthBaseConfig;

export function isProductionEnvironment(environment: HostEnvironment) {
    switch (environment) {
        case HostEnvironment.Prod:
        case HostEnvironment.DarkProd:
        case HostEnvironment.UAT:
            return true;
        default:
            return false;
    }
}
