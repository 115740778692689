export const APP_DOMAIN = 'DASH';
export const ACTIVITY_DOMAIN = 'VEHICLE_PROTECTION';

// #region activity actions
export const MENU_SELECTIONS_SUBMIT_REQUEST = 'MENU_SELECTIONS_SUBMIT_REQUEST';
export const MENU_SELECTIONS_SUBMIT_SUCCESS = 'MENU_SELECTIONS_SUBMIT_SUCCESS';
export const MENU_SELECTIONS_SUBMIT_FAILURE = 'MENU_SELECTIONS_SUBMIT_FAILURE';

export const PRODUCT_SELECTED = `${ACTIVITY_DOMAIN}/PRODUCT_SELECTED`;
export const PRODUCT_UNSELECTED = `${ACTIVITY_DOMAIN}/PRODUCT_UNSELECTED`;
export const ALL_PRODUCTS_SELECTED = `${ACTIVITY_DOMAIN}/ALL_PRODUCTS_SELECTED`;
export const ALL_PRODUCTS_UNSELECTED = `${ACTIVITY_DOMAIN}/ALL_PRODUCTS_UNSELECTED`;

export const PRODUCT_IS_EDITING = `${ACTIVITY_DOMAIN}/PRODUCT_IS_EDITING`;
export const PRODUCT_UPDATED = `${ACTIVITY_DOMAIN}/PRODUCT_UPDATED`;
export const PRODUCT_MONTHLY_PAYMENT_UPDATED = `${ACTIVITY_DOMAIN}/PRODUCT_MONTHLY_PAYMENT_UPDATED`;
export const ALL_PRODUCTS_MONTHLY_PAYMENT_RESET = `${ACTIVITY_DOMAIN}/ALL_PRODUCTS_MONTHLY_PAYMENT_RESET`;
export const PRODUCTS_REQUEST_OVERRIDDEN = `${ACTIVITY_DOMAIN}/PRODUCTS_REQUEST_OVERRIDDEN`;
export const FETCH_PRODUCTS_REQUESTED = `${ACTIVITY_DOMAIN}/FETCH_PRODUCTS_REQUESTED`;
export const FETCH_PRODUCTS_SUCCESS = `${ACTIVITY_DOMAIN}/FETCH_PRODUCTS_SUCCESS`;
export const FETCH_PRODUCTS_FAILURE = `${ACTIVITY_DOMAIN}/FETCH_PRODUCTS_FAILURE`;
export const FETCH_PRODUCTS_RETRY = `${ACTIVITY_DOMAIN}/FETCH_PRODUCTS_RETRY`;
export const PRODUCT_CHANGES_PAYMENT_CALL = `${ACTIVITY_DOMAIN}/PRODUCT_CHANGES_PAYMENT_CALL`;

export const FETCH_RATES_REQUESTED = `${ACTIVITY_DOMAIN}/FETCH_RATES_REQUESTED`;
export const FETCH_RATES_SUCCESS = `${ACTIVITY_DOMAIN}/FETCH_RATES_SUCCESS`;
export const FETCH_RATES_FAILURE = `${ACTIVITY_DOMAIN}/FETCH_RATES_FAILURE`;
export const FETCH_RATES_RETRY = `${ACTIVITY_DOMAIN}/FETCH_RATES_RETRY`;
export const VEHICLE_PROTECTION_COMPLETED = `${ACTIVITY_DOMAIN}/VEHICLE_PROTECTION_COMPLETED`;
export const VEHICLE_PROTECTION_IN_PROGRESS = `${ACTIVITY_DOMAIN}/VEHICLE_PROTECTION_IN_PROGRESS`;
// #endregion

// #region app actions
export const DASH_MENU_LIFECYCLE_INIT_START = `${APP_DOMAIN}/MENU_LIFECYCLE_INIT_START`;
export const DASH_MENU_LIFECYCLE_INIT_FINISH = `${APP_DOMAIN}/MENU_LIFECYCLE_INIT_FINISH`;
export const DASH_MENU_LIFECYCLE_UNKNOWN = `${APP_DOMAIN}/MENU_LIFECYCLE_UNKNOWN`;
export const DASH_MENU_SKIPPED = `${APP_DOMAIN}/MENU_SKIPPED`;
// #endregion
