import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const Container = styled.div`
    position: relative;
    box-sizing: border-box;
    min-height: 4rem;
    @media (max-width: ${BreakPoint.SMALL}) {
        height: auto;
    }
`;

export const Title = styled.h3`
    font-size: 1.2rem;
    font-weight: 500;
`;

export const ButtonContainer = styled.div`
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;

export const ButtonsContainerInterstate = styled.div`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 0.75rem;
`;

export const Details = styled.span`
    text-align: left;
    margin: 0.25rem 0 0 0;
    font-size: 1rem;
`;

export const Info = styled.span`
    font-weight: bold;
`;

export const DrawerFooter = styled.div`
    display: flex;
    justify-content: flex-end;
`;
