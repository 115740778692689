// externals
import { Reducer } from 'redux';

// libraries
import { DealXgDetailsState } from '@makemydeal/dr-dash-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';
import { LINKS_SUCCESS, OFFER_SAVE_NOCHANGE_SUCCESS, OFFER_SAVE_SUCCESS, SOFT_SAVE_SUCCESS } from '../actionTypes/offerActionTypes';
import { DMS_PLUS, PUSH_TO_DMS_FLOW } from '../constants';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export const dealXgDetailsInitialState: DealXgDetailsState = {
    globalCustomerId: ''
};

export type DealXgDetailsReducer = Reducer<DealXgDetailsState>;

export const reducer: DealXgDetailsReducer = (state: DealXgDetailsState = dealXgDetailsInitialState, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...action.payload.dealXgDetails
            };
        }
        case LINKS_SUCCESS:
        case SOFT_SAVE_SUCCESS:
        case OFFER_SAVE_NOCHANGE_SUCCESS:
        case OFFER_SAVE_SUCCESS: {
            const {
                meta: { source, enableEnhancedPushToDmsPlusMV, dmsIntegrationToggle }
            } = action;
            if (source === PUSH_TO_DMS_FLOW && enableEnhancedPushToDmsPlusMV && dmsIntegrationToggle === DMS_PLUS) {
                const date = new Date();
                return {
                    ...state,
                    latestPushToDmsPlus: date.toISOString()
                };
            }
            return state;
        }
        default: {
            return state;
        }
    }
};
