import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { kebabCase } from '@makemydeal/dr-common-utils';
import { formatAprRate, formatMoneyFactor } from '../../utils/formatUtils';
import { APR, BUY_RATE, DEBOUNCE_TIMER, GENERIC_LAST_FIELD_CHANGED_ERROR, MONEY_FACTOR, MONEY_FACTOR_TYPE } from '../../constants';
import { TextInput } from '@interstate/components/TextInput';
import {
    offerReduxSelectors,
    offerSelectors,
    offerReduxActionCreators,
    deskingSelectors,
    offerActionTypes
} from '@makemydeal/dr-dash-store';

const BuyRateField = () => {
    const dispatch = useDispatch();

    const isUserProgramQuotes = useSelector(offerReduxSelectors.getIsUserProgramQuotes);
    const value = useSelector(offerSelectors.getBuyRateOverrideWithFallback);
    const rateType = useSelector(offerSelectors.getRateTypeOverrideFromOfferTypeOrSelectedTerm);
    const currentTermMonths = useSelector(offerSelectors.getSelectedTermMonths);
    const failed = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_BUY_RATE_OVERRIDE));

    const failedMessage = useMemo(() => (failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined), [failed]);

    // determine formatter by rate type
    const formatter = useMemo(() => {
        if (rateType === MONEY_FACTOR_TYPE) return formatMoneyFactor;
        return formatAprRate;
    }, [rateType]);

    const rateTypeLabel = useMemo(() => {
        if (rateType === MONEY_FACTOR_TYPE) return MONEY_FACTOR;
        return APR;
    }, [rateType]);

    // grab the current, foratted value
    const formattedValue = useMemo(() => formatter(value), [value, formatter, rateType]);

    // setup state for the actual input; default with currently formatted state value
    const [inputText, setInputText] = useState(formattedValue);

    // action creator to also consider selected term months
    const actionCreator = useCallback(
        (newValue: number) => offerReduxActionCreators.updateBuyRateOverride(newValue, currentTermMonths),
        [currentTermMonths]
    );

    const handleInputChanged = (e: any) => {
        if (e.target.value !== inputText) setInputText(e.target.value);
    };
    const handleInputBlur = (e: any) => {
        const value = +inputText;
        const formatted = formatter(value);
        if (formatted !== inputText) setInputText(formatted);
    };

    // We're intentionally not using `usePaymentCall` here and manually implementing a debouncer due to the complexity of action creator memoized by selected term months.
    useEffect(() => {
        // NOTE: only dipatch if the actual parsed value is different from redux state
        if (+inputText === value) return undefined;

        const id = setTimeout(() => {
            dispatch(actionCreator(+inputText));
        }, DEBOUNCE_TIMER);
        return () => {
            clearTimeout(id);
        };
    }, [inputText]);

    // NOTE: upstream change; i.e. term changed in redux causing new override to be set
    // TODO: future integration test here.
    // istanbul ignore next
    useEffect(() => {
        if (inputText === formattedValue) return undefined;
        setInputText(formattedValue);
        return undefined;
    }, [formattedValue]);

    return (
        <TextInput
            label={`${BUY_RATE} (${rateTypeLabel})`}
            name={kebabCase(BUY_RATE)}
            id={kebabCase(BUY_RATE)}
            onChange={handleInputChanged}
            onBlur={handleInputBlur}
            errorMessage={failedMessage}
            hasError={failed}
            placeholder={formatter(0)}
            value={inputText}
            disabled={!isUserProgramQuotes}
            data-testid={kebabCase(BUY_RATE)}
        />
    );
};

export default BuyRateField;
