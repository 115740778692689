// externals
import { FC } from 'react';

// libraries
import { Button } from '@interstate/components/Button';

// styles
import { ManualAccessoriesButtonsContainer } from '../../ManualAccessories.interstate.styles';

export type ManualAccessoryControlButtonsProps = {
    handleClose: any;
    handleSave: any;
    isDisabled: boolean;
};

export const ManualAccessoryControlButtons: FC<ManualAccessoryControlButtonsProps> = ({ handleClose, handleSave, isDisabled }) => (
    <ManualAccessoriesButtonsContainer id="manual-accessories-button-container">
        <Button data-testid="btn-manual-accessories-cancel-edit" buttonStyle="secondary" onClick={handleClose}>
            Cancel
        </Button>

        <Button
            data-testid="btn-manual-accessories-edit-update-offer"
            buttonStyle="primary"
            disabled={isDisabled}
            onClick={handleSave}
        >
            Save
        </Button>
    </ManualAccessoriesButtonsContainer>
);
