import styled from 'styled-components';

export const SendToCustomerDialogFooter = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 10px;

    button {
        margin-bottom: 4px;
    }
`;
