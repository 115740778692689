// state
import type { StateTree } from '@makemydeal/dr-dash-types';

// TODO: DASH - implement this
export const hasShopperEngagedWithOffer = (state: StateTree): boolean | undefined => {
    return undefined;
};

// TODO: DASH - implement this
export const getDealExchangeId = (state: StateTree): string => {
    return '';
};

// TODO: DASH - implement this
export const getDealExchangeVersion = (state: StateTree): string => {
    return '';
};
