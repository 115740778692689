import { FC, useEffect, useRef } from 'react';

import { TriforceWidgetData } from '@makemydeal/dr-dash-types';

type ProfitTimeTriforceWidgetProps = {
    displayData: TriforceWidgetData;
    scriptUrl: string;
};

interface TriforceWidgetRef extends HTMLElement {
    displayData: TriforceWidgetData;
}

const TriforceWidget: FC<ProfitTimeTriforceWidgetProps> = ({ displayData, scriptUrl }) => {
    const triforceWidgetRef = useRef<TriforceWidgetRef>(null);

    useEffect(() => {
        const scriptTriforce = document.createElement('script');
        scriptTriforce.src = scriptUrl;
        scriptTriforce.type = 'module';
        scriptTriforce.id = 'triforce';
        document.body.appendChild(scriptTriforce);
        scriptTriforce.onload = () => {
            if (triforceWidgetRef && triforceWidgetRef.current) {
                triforceWidgetRef.current.displayData = displayData;
            }
        };
        return () => {
            const triforceScript = document.querySelector('script[id*="triforce"]');
            if (triforceScript) {
                triforceScript.remove();
            }
        };
    }, [displayData, scriptUrl]);

    return <profit-time-triforce-widget ref={triforceWidgetRef} data-testid="vauto-triforce-widget" />;
};

export default TriforceWidget;
