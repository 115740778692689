import { VERSION_CREATED_BY_TO_APP_DISPLAY_NAME } from '../constants';

const personaSuffix: any = {
    dealer: 'Sales',
    shopper: 'Customer',
    dealmaker: 'You'
};

const personaPrefix: any = {
    dealer: 'Sales',
    shopper: 'Customer',
    dealmaker: 'Your'
};

export const getHeaderLabel = (
    mostRecentDealerFlag: boolean | undefined,
    mostRecentShopperFlag: boolean | undefined,
    offerSource: string | undefined,
    applicationName: string | undefined
) => {
    const personaSuffixVerbiage = getApplicationOrSourceName(offerSource, applicationName, personaSuffix);
    const personaPrefixVerbiage = getApplicationOrSourceName(offerSource, applicationName, personaPrefix);
    const isLatest = mostRecentDealerFlag || mostRecentShopperFlag;
    return isLatest ? `Latest Offer (${personaSuffixVerbiage})` : `${personaPrefixVerbiage} Offer`;
};

const getApplicationOrSourceName = (
    offerSource: string | undefined,
    applicationName: string | undefined,
    sourceMapping: Record<string, string>
) => {
    if (applicationName) {
        const source = VERSION_CREATED_BY_TO_APP_DISPLAY_NAME[applicationName];
        if (source) {
            return source;
        }
    }

    const source = (offerSource && offerSource.toLowerCase()) || 'dealmaker';
    return sourceMapping[source];
};
