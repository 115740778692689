export const TRADE_IN_COMPONENT_YEARS_REQUEST = 'TRADE_IN_COMPONENT_YEARS_REQUEST';
export const TRADE_IN_COMPONENT_YEARS_SUCCESS = 'TRADE_IN_COMPONENT_YEARS_SUCCESS';
export const TRADE_IN_COMPONENT_YEARS_FAILURE = 'TRADE_IN_COMPONENT_YEARS_FAILURE';
export const TRADE_IN_COMPONENT_MAKES_REQUEST = 'TRADE_IN_COMPONENT_MAKES_REQUEST';
export const TRADE_IN_COMPONENT_MAKES_SUCCESS = 'TRADE_IN_COMPONENT_MAKES_SUCCESS';
export const TRADE_IN_COMPONENT_MAKES_FAILURE = 'TRADE_IN_COMPONENT_MAKES_FAILURE';
export const TRADE_IN_COMPONENT_MODELS_REQUEST = 'TRADE_IN_COMPONENT_MODELS_REQUEST';
export const TRADE_IN_COMPONENT_MODELS_SUCCESS = 'TRADE_IN_COMPONENT_MODELS_SUCCESS';
export const TRADE_IN_COMPONENT_MODELS_FAILURE = 'TRADE_IN_COMPONENT_MODELS_FAILURE';
export const TRADE_IN_YEARS_REQUEST = 'TRADE_IN_YEARS_REQUEST';
export const TRADE_IN_YEARS_SUCCESS = 'TRADE_IN_YEARS_SUCCESS';
export const TRADE_IN_YEARS_FAILURE = 'TRADE_IN_YEARS_FAILURE';
export const TRADE_IN_MAKES_REQUEST = 'TRADE_IN_MAKES_REQUEST';
export const TRADE_IN_MAKES_SUCCESS = 'TRADE_IN_MAKES_SUCCESS';
export const TRADE_IN_MAKES_FAILURE = 'TRADE_IN_MAKES_FAILURE';
export const TRADE_IN_MODELS_REQUEST = 'TRADE_IN_MODELS_REQUEST';
export const TRADE_IN_MODELS_SUCCESS = 'TRADE_IN_MODELS_SUCCESS';
export const TRADE_IN_MODELS_FAILURE = 'TRADE_IN_MODELS_FAILURE';
export const TRADE_IN_TRIMS_REQUEST = 'TRADE_IN_TRIMS_REQUEST';
export const TRADE_IN_TRIMS_SUCCESS = 'TRADE_IN_TRIMS_SUCCESS';
export const TRADE_IN_TRIMS_FAILURE = 'TRADE_IN_TRIMS_FAILURE';
export const TRADE_IN_VEHICLES_REQUEST = 'TRADE_IN_VEHICLE_REQUEST';
export const TRADE_IN_VEHICLES_SUCCESS = 'TRADE_IN_VEHICLE_SUCCESS';
export const TRADE_IN_VEHICLES_FAILURE = 'TRADE_IN_VEHICLE_FAILURE';
