// externals
import { Draft } from 'immer';

// libraries
import { OfferInfoState } from '@makemydeal/dr-dash-types';

export const storeLinksInState = (draft: Draft<OfferInfoState>, payload: any) => {
    draft.urls = {
        creditAppURL: payload.creditAppUrl,
        shopperURL: payload.shopperPageUrl,
        testDriveURL: payload.testDriveDeepLinkUrl,
        docUploadURL: payload.docUploadDeepLinkUrl
    };
};

export const updateOfferSavedSuccessState = (draft: Draft<OfferInfoState>) => {
    draft.hasBeenSaved = true;
    draft.isOfferBeingSaved = false;
};

export const updateOfferSaveFailureState = (draft: Draft<OfferInfoState>) => {
    draft.hasBeenSaved = false;
    draft.isOfferBeingSaved = false;
};
