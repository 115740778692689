// externals
import { useMemo } from 'react';
import { TextInput } from '@interstate/components/TextInput';

// components
import LabelWithToolTipIcon from '../../form/LabelWithToolTipIcon';

// utils
import { formatDealAttributeInformationValue } from '../../../utils';

// types
import { DealAttributeItemProps } from '../../../types';

const DealAttributeInformation = ({ dealAttributeInput }: DealAttributeItemProps) => {
    const { description, isPrice, name, title, value, displayValue } = dealAttributeInput;
    /**
     * Since onChange is required on the TextInput this will never work for disabled input
     */
    // istanbul ignore next
    const onChange = () => undefined;

    const inputComponentType = isPrice ? 'priceinput' : 'textinput';

    const formattedValue = useMemo(() => {
        const input = displayValue ?? value;
        return formatDealAttributeInformationValue(isPrice, input);
    }, [isPrice, value, displayValue]);

    return (
        <div>
            <LabelWithToolTipIcon name={name} title={title} toolTipContent={description} />
            <div>
                <TextInput
                    label={title}
                    displayLabel={false}
                    name={name}
                    data-testid={`${name}-deal-attribute-${inputComponentType}-id`}
                    value={formattedValue}
                    onChange={onChange}
                    disabled={true}
                />
            </div>
        </div>
    );
};

export default DealAttributeInformation;
