// TODO: SV - See what is common and keep it here and get Dash to also use this (for now it is duplicated).

// utils
import { reducerRegistry } from './reducerRegistry';

export function addRemainingGlobalReducers(reducers: any) {
    reducerRegistry.registerMultipleGlobal(reducers);
}

export function addRemainingScopedReducers(reducers: any) {
    reducerRegistry.registerMultipleScoped(reducers);
}
