// libraries
import { IActivityState, NavigationItemStatus } from '@makemydeal/dr-activities-common';
import type { LegacyActivityStates, StateTree, TestDriveActivityState } from '@makemydeal/dr-dash-types';

export const getActivities = (state: StateTree): LegacyActivityStates => {
    return (state.activityStates?.activities as LegacyActivityStates) || ({} as LegacyActivityStates);
};

/**
 * Test Drive section
 */
export const getTestDriveActivityState = (state: StateTree): TestDriveActivityState => {
    let activityState = {} as TestDriveActivityState;

    const activityStates = getActivities(state);
    if (activityStates.testDrive) {
        activityState = activityStates.testDrive as TestDriveActivityState;
    }

    return activityState;
};

export type ActivityStateType = IActivityState;

export const getActivityKey = (activityId: string) => {
    switch (activityId) {
        case 'dr-sp-test-drive-card':
            return 'testDrive';
        case 'dr-sp-incentives-card':
            return 'incentives';
        // TODO: DASH - this should probably be deprecated
        // case 'dr-sp-counterOffer':
        //     return 'counterOffer';
        default:
            return undefined;
    }
};

export interface IActivityStates {
    activities?: LegacyActivityStates;
    counterOffer?: IActivityState;
    incentives?: IActivityState;
    testDrive?: IActivityState;
}

export const getActivityState = (state: StateTree): IActivityStates => {
    return state.activityStates || ({} as IActivityStates);
};

// TODO: DASH - need to check if this can be renamed - MUI was another name for Pluggable UI and we deprecated that a while back
export const getMuiActivityById = (state: StateTree, activityId: string): ActivityStateType | undefined => {
    const key = getActivityKey(activityId);
    if (!key) {
        return undefined;
    }
    let activityStates: IActivityStates | LegacyActivityStates = getActivities(state);
    if (!activityStates[key]) {
        activityStates = getActivityState(state);
    }
    const activityState = activityStates[key];
    return activityState;
};

export const getActivityStatus = (state: StateTree, activityId: string): NavigationItemStatus => {
    const activityState = getMuiActivityById(state, activityId);
    if (!activityState) {
        return NavigationItemStatus.DEFAULT;
    }
    if (activityState.isCreditLocked) {
        return NavigationItemStatus.CREDIT_LOCK;
    } else if (activityState.isDisabledForCash) {
        return NavigationItemStatus.NOT_AVAILABLE_ONLINE;
        // } else if ((activityState as ICounterOfferActivityState).showDealerCounterOfferViewReadOnly) {
        //     return NavigationItemStatus.SENT_FROM_DEALER;
    } else {
        return (activityState.status as NavigationItemStatus) || NavigationItemStatus.DEFAULT;
    }
};

export const hideNextActivityFooter = (state: StateTree): boolean => {
    return !!(
        isTestDriveCompleted(state) ||
        isTestDriveDisabled(state) ||
        isTestDriveServiceUnavailable(state) ||
        isTestDriveCreditLocked(state) ||
        isTestDriveDisabledForCash(state)
    );
};

export const isTestDriveCompleted = (state: StateTree): boolean => {
    const testDrive = getTestDriveActivityState(state);
    return testDrive.isCompleted || false;
};

export const isTestDriveServiceUnavailable = (state: StateTree): boolean => {
    const testDrive = getTestDriveActivityState(state);
    return testDrive.isUnavailable || false;
};

export const isTestDriveDisabled = (state: StateTree): boolean => {
    const testDrive = getTestDriveActivityState(state);
    return testDrive.isDisabled || false;
};

export const isTestDriveCreditLocked = (state: StateTree): boolean => {
    const testDrive = getTestDriveActivityState(state);
    return testDrive.isCreditLocked || false;
};

export const isTestDriveDisabledForCash = (state: StateTree): boolean => {
    const testDrive = getTestDriveActivityState(state);
    return testDrive.isDisabledForCash || false;
};
