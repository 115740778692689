// externals
import { Reducer } from 'redux';

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import type { SharedInitConfigurationState } from '@makemydeal/dr-shared-types';

// consts/enums
import * as types from '../actionTypes/initActionTypes';

// utils
import * as bootstrapActionTypes from '../actionTypes/bootstrapActionTypes';

const { BOOTSTRAP_INITIAL_LOAD } = bootstrapActionTypes;

// T = SharedInitConfigurationState<U, S, A>
export type BuildConfigReducerBootstrapInitialLoadCallback<T> = (appPrefix: string, action: AnyFSA, state: T) => T;

export const buildConfigReducer = <T extends SharedInitConfigurationState<any, any, any, any>>(
    appPrefix: string,
    initialState: T,
    handleBootstrapInitialLoad: BuildConfigReducerBootstrapInitialLoadCallback<T>
) => {
    const reducer: Reducer<T> = (state: T = initialState, action: any) => {
        switch (action.type) {
            case BOOTSTRAP_INITIAL_LOAD: {
                // This action builds initial config based on the URL (no API call has been made yet)
                return handleBootstrapInitialLoad(appPrefix, action, state);
            }
            case types.BOOTSTRAP_SUCCESS: {
                // This action occurs after the BFF init API call comes back with data
                const bff = state.services?.bff || initialState.services.bff;
                const services = {
                    ...action.payload.config.services,
                    bff
                };

                return {
                    ...state,
                    ...action.payload.config,
                    services
                };
            }
            default: {
                return state;
            }
        }
    };
    return reducer;
};
