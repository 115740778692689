// externals
import { buildGTMEventMiddleware, buildUserEvent, buildReadyEvent, EventMap } from '@makemydeal/dr-common-data-layer-utils';

// libraries
import { dealerSelectors, featureToggleSelectors, selectedOfferSelectors } from '@makemydeal/dr-shared-store';

// consts/enums
import { deskingActionTypes, sendToCustomerDialogActionTypes } from '../actionTypes';
import { DATA_LAYER_NAME } from '../constants';

export const eventMap: EventMap = {
    GTM_DATA_READY: (action: any, currentState: any) => {
        const eventData = {
            common: {
                context: {
                    dealershipId: dealerSelectors.getDealerId(currentState),
                    dealershipName: dealerSelectors.getDealerName(currentState)
                }
            },
            deal: {
                dealXgId: selectedOfferSelectors.getSelectedOfferDealXgId(currentState),
                dealXgVersion: selectedOfferSelectors.getSelectedOfferDealXgVersion(currentState)
            }
        };
        return buildReadyEvent(eventData);
    },
    [deskingActionTypes.CLICKED_CONTINUE_IN_DESKING]: buildUserEvent({
        eventAction: 'Clicked',
        eventElement: 'Continue in Desking Button',
        eventLocation: 'Desking Dashboard > Bottom of Page',
        eventResult: 'Opened confirmation modal for continuing in desking'
    }),
    [deskingActionTypes.CLICKED_SEND_TO_ECOMMERCE]: buildUserEvent({
        eventAction: 'Clicked',
        eventElement: 'Send to Ecommerce Button',
        eventLocation: 'Desking Dashboard > Bottom of Page',
        eventResult: 'Opened confirmation modal for sending deal to ecommerce'
    }),
    [deskingActionTypes.CLICKED_COPY_DEAL_URL]: buildUserEvent({
        eventAction: 'Clicked',
        eventElement: 'Copy URL Button',
        eventLocation: 'Desking Dashboard > Send to Customer Dialog > Share URL > Copy Button',
        eventResult: 'Sent email to customer'
    }),
    [deskingActionTypes.CLICKED_CONTINUE_IN_UNIFI]: buildUserEvent({
        eventAction: 'Clicked',
        eventElement: 'CMD Deeplink to UniFI',
        eventLocation: 'Desking Dashboard > Finish Deal Card > Link to DT/Unifi after Deal update',
        eventResult: 'Opened Confirmation modal for continuing in Unifi'
    })
};

export const googleAnalyticsEventMiddleware = buildGTMEventMiddleware({
    eventMap,
    dataLayerName: DATA_LAYER_NAME,
    middlewareEnabledSelector: featureToggleSelectors.isCDL3EnabledInMV
});
