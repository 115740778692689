// libraries
import { type Exclusions, type Incentive, type ProgramsState } from '@makemydeal/dr-dash-types';
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { IResponsePayment } from '@makemydeal/dr-platform-types';
import { DateTime } from 'luxon';
import { Reducer } from 'redux';
import {
    PROGRAMS_CLEAR_ALL,
    PROGRAMS_CLEAR_INCENTIVES,
    PROGRAMS_CLEAR_SELECTED,
    PROGRAMS_INCENTIVES_REQUEST_FAILURE,
    PROGRAMS_INCENTIVES_REQUEST_SUCCESS,
    PROGRAMS_SELECT,
    REQUEST_PROGRAMS,
    REQUEST_PROGRAMS_FAILURE,
    REQUEST_PROGRAMS_SUCCESS,
    TOGGLE_INCENTIVE_FOR_PROGRAM
} from '../actionTypes/offerActionTypes';
import { getIncentivesWithDeterminedAmountsByTerm } from '../selectors/programsSelectors';
import { filterByEndDate, transformIncentiveDates } from '../utils/incentivesUtil';

export type ProgramsReducer = Reducer<ProgramsState>;

export const initialState: ProgramsState = {
    incentiveExclusionsById: {}
};

/**
 * Used to mod the existing selection when toggling them on/off in the UX
 */
const updateSelectedIncentives = (
    state: ProgramsState,
    { incentive, selected }: { incentive: Incentive; selected: boolean }
): number[] => {
    const list = state.selectedIncentives ?? []; // useful ref
    const indexOfExisting = list.indexOf(incentive.incentiveId); // index if it exists

    const shouldAdd = indexOfExisting < 0 && selected; // needs to be added to the list
    const shouldRemove = indexOfExisting >= 0 && !selected; // needs to be removed

    if (shouldAdd) list.push(incentive.incentiveId);
    else if (shouldRemove) list.splice(indexOfExisting, 1);

    return list;
};

/**
 * Determine the current excluded incentives based on provided available and selected incentives
 */
const updateExclusions = (available: Incentive[], selectedIds: number[]): Exclusions => {
    if (!selectedIds.length) return {};

    const selected = available.filter((i) => selectedIds.indexOf(i.incentiveId) >= 0);
    const exclusions: Exclusions = {};
    selected.forEach((incentive) => {
        exclusions[incentive.incentiveId.toString()] = incentive.exclusions;
    });
    return exclusions;
};

export const reducer: ProgramsReducer = (state = initialState, action: AnyFSA) => {
    switch (action.type) {
        case PROGRAMS_CLEAR_ALL: {
            return initialState;
        }

        case PROGRAMS_CLEAR_SELECTED: {
            return { ...state, selectedIncentives: undefined, selectedProgram: undefined };
        }

        case PROGRAMS_CLEAR_INCENTIVES: {
            return { ...state, available: undefined };
        }

        case PROGRAMS_SELECT: {
            return {
                ...state,
                selectedProgram: action.payload,
                selectedIncentives: action.payload.appliedIncentiveIds
            };
        }

        case TOGGLE_INCENTIVE_FOR_PROGRAM: {
            // new selected
            const selectedIncentives = updateSelectedIncentives(state, action.payload);
            // current all
            const conditional = state.available?.conditional ?? [];
            const nonConditional = state.available?.nonConditional ?? [];
            const all = [...conditional, ...nonConditional];
            // new exclusions; based on updated selected; (selected changes, chaninging exclusions)
            const incentiveExclusionsById = updateExclusions(all, selectedIncentives);

            return { ...state, selectedIncentives, incentiveExclusionsById };
        }

        case REQUEST_PROGRAMS: {
            return { ...state };
        }

        case REQUEST_PROGRAMS_SUCCESS: {
            const { currentOfferType, currentSelectedProgram } = action.meta;
            const payments = action.payload[currentOfferType]?.payments as IResponsePayment[];
            return { ...state, programs: payments, selectedProgram: currentSelectedProgram };
        }

        case REQUEST_PROGRAMS_FAILURE: {
            return { ...state, programsError: action.payload };
        }

        case PROGRAMS_INCENTIVES_REQUEST_SUCCESS: {
            const offerType = action.meta.currentOfferType;
            const term = action.meta.term;
            const incentives = action.payload?.incentives?.[offerType] ?? {};

            const lowerLimit = DateTime.now().startOf('day').minus({ days: 7 }); // not using UTC: intentional. In browsers, this will be local time; start of day
            const filterCriteria = filterByEndDate(lowerLimit.toISO()!);
            const nonConditional = getIncentivesWithDeterminedAmountsByTerm(incentives.nonConditional ?? [], term)
                .map(transformIncentiveDates)
                .filter(filterCriteria);
            const conditional = getIncentivesWithDeterminedAmountsByTerm(incentives.conditional ?? [], term)
                .map(transformIncentiveDates)
                .filter(filterCriteria);

            // new available
            const all = [...conditional, ...nonConditional];
            // current selected
            const selected = state.selectedIncentives ?? [];
            // new exclusions based on updated available; (possible here: selected source data changes and changes exclusions)
            const incentiveExclusionsById = updateExclusions(all, selected);

            return {
                ...state,
                available: {
                    conditional,
                    nonConditional
                },
                incentiveExclusionsById
            };
        }

        case PROGRAMS_INCENTIVES_REQUEST_FAILURE: {
            return { ...state, incentivesError: action.payload };
        }

        default: {
            return state;
        }
    }
};
