/**
 * PURPOSE: Listens for specific actions and determines when a route change should be performed.
 */

// externals
import { push } from 'connected-react-router';

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { NavigationActions } from '@makemydeal/dr-activities-common';
import {
    DashNext,
    featureToggleSelectors,
    RealState,
    scopedActionsCreators,
    scopedStateSelectors
} from '@makemydeal/dr-shared-store';
import {
    navigationActionTypes,
    tradeInCardActionCreators,
    accessoriesActionCreators,
    manualIncentivesActionCreators,
    RouteValues,
    DashStoreMiddleware,
    DashStoreMiddlewareApi
} from '@makemydeal/dr-dash-store';

// config
import { creditBureauConfiguration } from '../activities/credit-bureau';
import { creditDecisionConfiguration } from '../activities/credit-decision';
import { allRoutes } from '../activities/programs';
import { vehicleProtectionConfiguration } from '../activities/vehicle-protection';

// utils
import { buildUniqueScrollRequestId } from '@makemydeal/dr-dash-ui-utils';
import { accessoriesCatalogConfiguration } from '../activities/accessories-catalog';

function dispatchNavigation(store: DashStoreMiddlewareApi, routeIntent: string) {
    // Track which scope triggered the navigation
    const state = store.getState();
    if (featureToggleSelectors.isABPencilStateMVEnabled(state)) {
        const isDashboard = routeIntent === RouteValues.DASHBOARD;
        const primaryScopeId = scopedStateSelectors.getPrimaryScopeId(state as RealState);
        const scopeId = isDashboard ? primaryScopeId : undefined;

        store.dispatch(scopedActionsCreators.setNavigationScopeTarget(scopeId));
    }
    // Dispatch route change to connected-react-router
    store.dispatch(push(routeIntent));
}

function processAction(action: AnyFSA, store: DashStoreMiddlewareApi) {
    switch (action.type) {
        // TODO: DASH - This needs to be analyzed further... I believe the original intention of this middleware was to navigate
        //              to specific routes instead of a generate route like this.
        case NavigationActions.NAVIGATE_TO: {
            const routeIntent = action.payload;
            // TODO: DASH - restore as needed
            // const state = store.getState();
            // const deskingUser = isDeskingUser(state);
            // const headerPosition = getHeaderPosition(state);
            // const deskingUser = true;
            // if (deskingUser) {
            dispatchNavigation(store, routeIntent);
            // }
            break;
        }
        case navigationActionTypes.DASH_START_PROGRAMS_ACTIVITY: {
            dispatchNavigation(store, allRoutes.root);
            break;
        }
        case navigationActionTypes.DASH_START_TRADE_ACTIVITY: {
            const navOpenedFromTradeIn = buildUniqueScrollRequestId(); // NOTE: Needed to handle case where form is already open but user navigates away and then back to trade
            store.dispatch(tradeInCardActionCreators.toggleManualTradeForm(navOpenedFromTradeIn));
            break;
        }
        case navigationActionTypes.DASH_START_INCENTIVES_ACTIVITY: {
            const navOpenedFromIncentives = buildUniqueScrollRequestId();
            store.dispatch(manualIncentivesActionCreators.OpenManualIncentiveForm(navOpenedFromIncentives));
            break;
        }
        case navigationActionTypes.DASH_START_CREDIT_BUREAU: {
            dispatchNavigation(store, creditBureauConfiguration.defaultRoute);
            break;
        }
        case navigationActionTypes.DASH_START_CREDIT_DECISION: {
            dispatchNavigation(store, creditDecisionConfiguration.defaultRoute);
            break;
        }
        case navigationActionTypes.DASH_START_VEHICLE_PROTECTION: {
            dispatchNavigation(store, vehicleProtectionConfiguration.defaultRoute);
            break;
        }
        case navigationActionTypes.DASH_START_ACCESSORIES_CATALOG: {
            dispatchNavigation(store, accessoriesCatalogConfiguration.defaultRoute);
            break;
        }
        case navigationActionTypes.DASH_START_ACCESSORIES_ACTIVITY: {
            store.dispatch(
                accessoriesActionCreators.openForm({
                    scrollIntoView: true
                })
            );
            break;
        }

        default: {
            break;
        }
    }
}

export const navigationMiddleware: DashStoreMiddleware = (store) => (next: DashNext) => (action: AnyFSA) => {
    next(action);

    if (action && action.type) {
        processAction(action, store);
    }
};
