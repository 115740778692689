// externals
import { Fragment, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';
import { IVehicle } from '@makemydeal/dr-platform-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// local components
import { VehicleListItemInterstate } from '../vehicleListItem/VehicleListItem.interstate';
import { VehicleListItemsContainer } from './ChangeVehicleSearchResult.style';

// types
import { CatalogVehicle } from '../../types';

export type ChangeVehicleSearchResultProps = {
    searchResults: CatalogVehicle[];
    isSearchError: boolean;
    redirectToBuildVehicleTab: (vehicle: IVehicle) => void;
};

export const NO_RESULT_ALERT_MESSAGE =
    'No results were returned on that VIN or stock. You may conduct another search or Build the Vehicle above.';

export const NOT_IN_STOCK_ALERT_MESSAGE =
    "We've found a Match to this VIN number, however this Vehicle is not in your Dealership's Inventory. Proceed accordingly.";

const ChangeVehicleSearchResult: React.FC<ChangeVehicleSearchResultProps> = ({
    searchResults,
    isSearchError,
    redirectToBuildVehicleTab
}) => {
    const isSellAnyVehicleEnabled = useSelector(featureToggleSelectors.isSellAnyVehicleEnabled);
    const hasSearchResults = searchResults.length > 0;

    const alertMessage = useMemo(() => {
        if (isSellAnyVehicleEnabled) {
            if (searchResults.length === 0) return NO_RESULT_ALERT_MESSAGE;
            if (searchResults.some((r) => !r.stockNumber)) return NOT_IN_STOCK_ALERT_MESSAGE;
        }
        return undefined;
    }, [isSellAnyVehicleEnabled, searchResults]);

    const getResultMessage = () => {
        const totalResults = searchResults.length;
        return totalResults > 1 ? `Search Results (${totalResults})` : 'Search Result';
    };

    return (
        <Fragment>
            {alertMessage && (
                <Alert data-testid="search-results-alert" type="info" role="" displayCloseButton sx={{ margin: '16px 0 8px 0' }}>
                    <Typography variant="body-sm" tag="span">
                        {alertMessage}
                    </Typography>
                </Alert>
            )}
            {hasSearchResults && !isSearchError && (
                <div data-testid="search-results-container" className="search-results-container">
                    <Typography
                        variant={isSellAnyVehicleEnabled ? 'h5' : 'h3'}
                        className="search-result-title"
                        data-testid="search-result-title"
                        sx={{ margin: '25px 0 24px 0' }}
                    >
                        {getResultMessage()}
                    </Typography>
                    <VehicleListItemsContainer>
                        {searchResults.map((vehicle, cardIndex) => (
                            <VehicleListItemInterstate
                                vehicle={{ ...vehicle, cardIndex: cardIndex }}
                                key={vehicle.styleId}
                                redirectToBuildVehicleTab={redirectToBuildVehicleTab}
                            ></VehicleListItemInterstate>
                        ))}
                    </VehicleListItemsContainer>
                </div>
            )}
        </Fragment>
    );
};

export default ChangeVehicleSearchResult;
