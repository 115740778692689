// libraries
import { getToggleOverrides } from '@makemydeal/dr-feature-toggles-client';
import { DEALER_TOGGLE_OVERRIDE_HEADER, TOGGLE_OVERRIDE_HEADER } from '@makemydeal/dr-platform-shared';

// utils
import { getAdditionalToggles } from '../offerSessionUtils/offerSessionUtils';
import * as apiConfigConsts from '../consts/apiConfigConsts';

export const getToggleHeaderFromQueryParamsOrSession = () => {
    const sessionAdditionalProps = getAdditionalToggles();

    const enableToggles = getQueryParam('enableToggles') || sessionAdditionalProps[apiConfigConsts.ENABLE_TOGGLES];
    const disableToggles = getQueryParam('disableToggles') || sessionAdditionalProps[apiConfigConsts.DISABLE_TOGGLES];

    const enableDealerToggles =
        getQueryParam('enableDealerToggles') || sessionAdditionalProps[apiConfigConsts.ENABLE_DEALER_TOGGLES];
    const disableDealerToggles =
        getQueryParam('disableDealerToggles') || sessionAdditionalProps[apiConfigConsts.DISABLE_DEALER_TOGGLES];

    const dealerToggleOverrides = getToggleOverrides({
        enableToggles: enableDealerToggles as string,
        disableToggles: disableDealerToggles as string
    });
    const toggleOverrides = getToggleOverrides({
        enableToggles: enableToggles as string,
        disableToggles: disableToggles as string
    });

    return {
        [TOGGLE_OVERRIDE_HEADER]: JSON.stringify(toggleOverrides),
        [DEALER_TOGGLE_OVERRIDE_HEADER]: JSON.stringify(dealerToggleOverrides)
    };
};

// istanbul ignore next
export const getHrefFromDocument = (): string => {
    return (document && document.location && document.location.href) || '';
};

export const getQueryParam = (param: string): string | string[] | undefined => {
    const locHref = getHrefFromDocument();
    const locHrefQS = locHref.split('?')[1];
    const params = new URLSearchParams(`?${locHrefQS}`);
    const obj = Object.fromEntries(params);
    const result = obj && obj[param];
    return result;
};
