// externals
import { Reducer } from 'redux';

// consts/enums
import { DOC_UPLOAD_FETCH_REQUEST, DOC_UPLOAD_FETCH_FAILURE, DOC_UPLOAD_FETCH_SUCCESS } from '../actionTypes/docUploadActionTypes';

// state
import type { DocUploadState } from '@makemydeal/dr-dash-bff-types';

export const docUploadInitialState: DocUploadState = {
    data: {
        documents: []
    },
    message: '',
    draasHTTPError: {
        error: '',
        message: '',
        errors: {}
    },
    meta: {
        traceId: '',
        appVersion: ''
    },
    isLoading: false
};

export type DocUploadReducer = Reducer<DocUploadState>;

export const reducer: DocUploadReducer = (state: DocUploadState = docUploadInitialState, action: any) => {
    switch (action.type) {
        case DOC_UPLOAD_FETCH_REQUEST: {
            return {
                ...state,
                isLoading: true,
                data: {
                    documents: []
                },
                message: '',
                draasHTTPError: {
                    error: '',
                    message: '',
                    errors: {}
                }
            };
        }
        case DOC_UPLOAD_FETCH_FAILURE: {
            return {
                ...action.payload.response,
                data: {
                    documents: []
                },
                isLoading: false
            };
        }
        case DOC_UPLOAD_FETCH_SUCCESS: {
            return {
                ...action.payload,
                isLoading: false,
                message: '',
                draasHTTPError: {
                    error: '',
                    message: '',
                    errors: {}
                }
            };
        }
        default: {
            return state;
        }
    }
};
