// externals
import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { RadioButtonEventValue, RadioButtonList } from '@interstate/components/RadioButtonList';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// utils
import { formatDollarsAndCents, formatMonthlyPayment } from '@makemydeal/dr-common-utils';

// types / constants
import { DraftDealProduct } from '@makemydeal/dr-dash-types';
import { EditProductPricesProps } from '../../types';
import { PROFIT_LOCATION_OPTIONS, UPFRONT_OPTIONS } from '../../constants';
import { CASH, LEASE } from '@makemydeal/dr-platform-types';

// store
import { offerSelectors } from '@makemydeal/dr-dash-store';

// styles
import { DrawerSeparator } from './EditProductDrawer.style';
import {
    PriceContainer,
    PriceValue,
    TextInputContainer,
    ProfitContainer,
    UpfrontContainer,
    RadioButtonListContainer
} from './EditProductPrices.style';

// components
import TextInputForPrice from './TextInputForPrice';
import TitleWithToolTipIcon from '../form/TitleWithToolTipIcon';
import LabelWithToolTipIcon from '../form/LabelWithToolTipIcon';

const EditProductPrices = ({
    productProps,
    priceInputErrorMessage,
    onEdit,
    offerType
}: {
    productProps: EditProductPricesProps;
    priceInputErrorMessage?: string;
    onEdit: (productProps: EditProductPricesProps) => void;
    offerType: string;
}) => {
    const termMonths = useSelector(offerSelectors.getSelectedTermMonths);
    const theme = useInterstateTheme();

    const monthlyPayment = useMemo(() => {
        if (isNil(termMonths) || isNil(productProps.productPrice)) {
            return undefined;
        }
        return productProps.productPrice / termMonths;
    }, [productProps, termMonths]);

    const dollarMarkup = useMemo(() => (productProps.productMarkup?.markupDollarAmount as number) || 0, [productProps]);

    const handleProfitLocationChange = useCallback(
        (e: InterstateOnChangeEvent<RadioButtonEventValue>) => {
            const profitLocation = e.target.value as DraftDealProduct['profitLocation'];
            onEdit({ ...productProps, profitLocation });
        },
        [onEdit, productProps]
    );

    const handleUpfrontChange = useCallback(
        (e: InterstateOnChangeEvent<RadioButtonEventValue>) => {
            const isProductPriceCapitalized = e.target.value === 'Capitalized';
            onEdit({ ...productProps, isProductPriceCapitalized });
        },
        [onEdit, productProps]
    );

    const handleMarkupChange = useCallback(
        (markupValue: number) => {
            const productEditPayload: EditProductPricesProps = {
                productMarkup: {
                    markupMethod: 'Dollar',
                    markupDollarAmount: markupValue
                }
            };

            if (typeof productProps.productCost === 'number') {
                productEditPayload.productPrice = Number((markupValue + productProps.productCost).toFixed(2));
            }

            onEdit(productEditPayload);
        },
        [onEdit, productProps]
    );

    const handleProductPriceChange = useCallback(
        (priceValue: number) => {
            const productEditPayload: EditProductPricesProps = {
                productPrice: priceValue
            };

            if (typeof productProps.productCost === 'number') {
                productEditPayload.productMarkup = {
                    markupMethod: 'Dollar',
                    markupDollarAmount: Number((priceValue - productProps.productCost).toFixed(2))
                };
            }

            onEdit(productEditPayload);
        },
        [onEdit, productProps]
    );

    return (
        <>
            <PriceContainer theme={theme}>
                <TitleWithToolTipIcon
                    title="Product Cost"
                    name="product-cost"
                    toolTipContent="The sum of the original costs of this Product itself and all selected Add-Ons for it."
                />
                <PriceValue data-testid={'product-cost'}>{formatDollarsAndCents(productProps.productCost)}</PriceValue>
            </PriceContainer>
            <DrawerSeparator theme={theme} />
            <TextInputContainer>
                <TextInputForPrice
                    label="Markup/Markdown"
                    dataTestId={'markup-markdown'}
                    name="markupDollarAmount"
                    onChange={handleMarkupChange}
                    value={dollarMarkup}
                />
            </TextInputContainer>
            <DrawerSeparator theme={theme} />
            <TextInputContainer>
                <LabelWithToolTipIcon
                    name="selling-price"
                    title="Selling Price"
                    toolTipContent="The total price of this Product with Add-Ons together that already includes a Markup or Markdown."
                />
                <TextInputForPrice
                    dataTestId={'selling-price'}
                    name="productPrice"
                    onChange={handleProductPriceChange}
                    value={productProps.productPrice}
                    error={priceInputErrorMessage}
                />
            </TextInputContainer>
            <DrawerSeparator theme={theme} />
            <ProfitContainer theme={theme}>
                <TitleWithToolTipIcon
                    title="Profit"
                    name="profit"
                    toolTipContent="The defined side that will be considered for this Product while calculating Profits (for protection products it's usually the Back end unless otherwise provided)."
                />
            </ProfitContainer>
            <RadioButtonListContainer theme={theme}>
                <RadioButtonList
                    data-testid={'profit-location'}
                    label=""
                    displayLabel={false}
                    layout="horizontal"
                    inline
                    name="profitLocation"
                    onChange={handleProfitLocationChange}
                    options={PROFIT_LOCATION_OPTIONS}
                    value={productProps.profitLocation ?? 'Back'}
                />
            </RadioButtonListContainer>
            {offerType === LEASE && (
                <>
                    <DrawerSeparator theme={theme} />
                    <UpfrontContainer theme={theme}>
                        <TitleWithToolTipIcon
                            title="Upfront/Capitalized"
                            name="upfront-capitalized"
                            toolTipContent="Select how the protection product price will affect the overall calculations of the deal."
                        />
                    </UpfrontContainer>
                    <RadioButtonListContainer theme={theme}>
                        <RadioButtonList
                            data-testid={'upfront-capitalized'}
                            label=""
                            displayLabel={false}
                            layout="horizontal"
                            inline
                            name="upfrontCapitalized"
                            onChange={handleUpfrontChange}
                            options={UPFRONT_OPTIONS}
                            value={
                                productProps.isProductPriceCapitalized === undefined || productProps.isProductPriceCapitalized
                                    ? 'Capitalized'
                                    : 'Upfront'
                            }
                        />
                    </RadioButtonListContainer>
                </>
            )}
            {offerType !== CASH && (
                <>
                    <DrawerSeparator theme={theme} />
                    <PriceContainer theme={theme}>
                        <TitleWithToolTipIcon
                            title="Payment"
                            name="monthly-payment"
                            toolTipContent="The reference monthly payment for this Product before taxes."
                        />
                        <PriceValue data-testid={'monthly-payment'}>{formatMonthlyPayment(monthlyPayment)}/Mo</PriceValue>
                    </PriceContainer>
                </>
            )}
        </>
    );
};

export default EditProductPrices;
