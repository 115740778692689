import styled from 'styled-components';

import { DatePicker } from '@interstate/components/DatePicker';

export const StyledLienholderActionGroup = styled.div`
    margin-top: 1rem;
`;

export const StyledDatePicker = styled(DatePicker)`
    min-width: 10rem !important;
`;

export const StyledLienholderFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .LienholderForm-accountNumber label {
        white-space: nowrap;
    }
`;
