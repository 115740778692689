import styled from 'styled-components';

export interface StyledBodyContainerProps {
    height: number | undefined;
}

export const InterstateStyledBodyContainer = styled.div<StyledBodyContainerProps>`
    margin-top: ${({ height }) => `${height}px`};
    background-color: #fff;
`;
