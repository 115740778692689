// libraries
import { getAllRoutes } from '@makemydeal/dr-activities-deal-history';
import { NavigationItemStatus } from '@makemydeal/dr-activities-shared';

// interfaces/types
import type { IFlowConfig } from '../../flowMasterInterfaces';

// components
import ChangeVehicleContainer from './components/ChangeVehicleContainer';

// consts/enums
import { APP_NAME, CHANGE_VEHICLE_ROUTE_ROOT } from './constants';

import { changeVehicleMiddleware } from './middleware';
import { changeVehicleReducer } from './config';

export const allRoutes = getAllRoutes(CHANGE_VEHICLE_ROUTE_ROOT);

/* this activity won't appear on the dr-activities-dashboard card list,
 * so including the bare minimum inputs to satisfy the interface
 */
export const changeVehicleConfiguration: IFlowConfig<any> = {
    name: APP_NAME,
    navCard: {
        label: 'Change Vehicle',
        icon: 'vehicle',
        cardId: 'dr-sp-change-vehicle'
    },
    showCardSelector: () => false,
    isCompleteSelector: () => false,
    routingComponent: ChangeVehicleContainer,
    defaultRoute: allRoutes.root,
    routes: [...Object.values(allRoutes)],
    middleware: changeVehicleMiddleware,
    reducer: changeVehicleReducer,
    isLocked: (_state: unknown) => false,
    dependsOnLeadFormCompletion: () => false,
    status: () => NavigationItemStatus.DEFAULT,
    isDisabled: () => false
};
