import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { Typography } from '@interstate/components/Typography';

export const LenderNameContainer = styled.div`
    margin: 8px 0;

    @media (min-width: ${BreakPoint.SMALL}) {
        margin: 8px 0 4px 0;
    }
`;

export const ManualRebatesCardContent = styled(Typography)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
`;
