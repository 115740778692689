import * as luxon from 'luxon';

export const isProgramNotExpired = (quote: any): boolean => {
    // We want to include quotes that do not have an end date;
    const endDate = quote?.lenderProgram?.programEndDate;

    if (!endDate) {
        return true;
    }

    const today = luxon.DateTime.now();
    const expiredDate = luxon.DateTime.fromISO(endDate);
    // We want to exclude quotes that are older than 7 days, giving leeway if the expiration is up to midnight the current day
    const isExpired = expiredDate.diff(today, 'days').days <= -8;
    return !isExpired;
};
