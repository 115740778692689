/**
 * This file provides the dash app with the additional selectors needed by the trade activity
 */

// libraries
import type { IAdditionalSelectors } from '@makemydeal/dr-activities-tradein';
import { isPaymentInvalid } from '@makemydeal/dr-platform-shared';
import type { IStateTreeBase } from '@makemydeal/dr-activities-common';
import { onFormSubmit, onOpenLeadFormModal } from '@makemydeal/dr-activities-lead-form';
import { formatUtils } from '@makemydeal/dr-common-utils';
import { OPTIMIZELY_TEST_NAMES } from '@makemydeal/dr-browser-utils';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';

// utils
import { shortDateString } from '../../utils/dateFormatters';

// selectors
import { routingSelectors } from '../../selectors';

// consts/enums
import { BuildPayloadPurpose } from '../../types/offerPayloadUtilsEnums';

import {
    activitiesStatusSelectors,
    connectionSelectors,
    creditAppSelectors,
    leadFormInfoSelectors,
    offerInfoSelectors,
    offerReduxSelectors,
    paymentSelectors,
    widgetSettingsSelectors,
    vehicleSelectors
} from '../../selectors';

import * as offerPayloadUtil from '../../utils/offerPayloadUtil';

// utils
import { milesToStringShort } from '../../utils/uiHelpersUtil';

export const additionalSelectors: IAdditionalSelectors = {
    activitiesStatusSelectors: {
        hideNextActivityFooter: activitiesStatusSelectors.hideNextActivityFooter,
        isTestDriveServiceUnavailable: activitiesStatusSelectors.isTestDriveServiceUnavailable
    },
    dateFormatters: {
        shortDateString
    },
    formatUtils: {
        formatDollars: formatUtils.formatDollars
    },
    leadForm: {
        // TODO: DASH - implement this (address inconsistency in naming - see incentives activity: using isLeadFormSubmitted)
        onOpenLeadFormModal,
        onFormSubmit,
        isLeadFormSubmittedSuccess: (state: IStateTreeBase) => leadFormInfoSelectors.isLeadFormSubmittedSuccess(state as StateTree),
        renderLeadForm: () => null
    },
    dealerSelectors: {
        getDealerTradeInValuationProvider: (state: IStateTreeBase) =>
            dealerSelectors.getDealerTradeInValuationProvider(state as StateTree) || '',
        getDealerPhone: dealerSelectors.getDealerPhone,
        getDealerState: dealerSelectors.getDealerState,
        getDealerName: dealerSelectors.getDealerName,
        isDealerTradeInLeadFormForListingSiteEnabled: dealerSelectors.isDealerTradeInLeadFormForListingSiteEnabled,
        isDealerTradeInLeadFormForWebsiteEnabled: dealerSelectors.isDealerTradeInLeadFormForWebsiteEnabled,
        isSubaruGtpEnabled: dealerSelectors.isSubaruGtpEnabled,
        isVppEnabled: dealerSelectors.isVppEnabled,
        microSiteEnabled: () => false,
        isTradeValuationKbb: dealerSelectors.isTradeValuationKbb,
        testDriveAtHomeOnTradeIn: dealerSelectors.testDriveAtHomeOnTradeIn,
        hasNoTradeOwnership: dealerSelectors.hasNoTradeOwnership,
        getCreditProvider: dealerSelectors.getCreditProvider,
        getTradeInHideExcellentConditionWebsite: dealerSelectors.getTradeInHideExcellentConditionWebsite,
        getTradeInHideExcellentConditionListingSite: dealerSelectors.getTradeInHideExcellentConditionListingSite
    },

    configSelectors: {
        isP202RedesignEnabled: (state: IStateTreeBase) => true,
        isActivityEnabled: (state: IStateTreeBase) =>
            featureToggleSelectors.isTradeActivityEnabledAfterBootstrap(state as StateTree),
        useWaitForEstimateResultLogic: (state: IStateTreeBase) => true,
        isTradeInImprovedWorkflowEnabled: (state: IStateTreeBase) => false,
        isSubaruGTPImprovedWorkflowEnabled: (state: IStateTreeBase) => false
    },

    connectionSelectors: {
        getConnectionId: (state: IStateTreeBase) => connectionSelectors.getConnectionId(state as StateTree)
    },

    offerSelectors: {
        getHasBeenSent: (state: IStateTreeBase) => offerInfoSelectors.getHasBeenSent(state as StateTree) as boolean,
        getCurrentOfferType: (state: IStateTreeBase) => offerReduxSelectors.getCurrentOfferType(state as StateTree),
        getMonthlyPaymentRoundedByOfferType: (state: IStateTreeBase, offerType: any) =>
            offerReduxSelectors.getMonthlyPaymentRoundedByOfferType(state as StateTree, offerType),
        getShopperInfo: (state: IStateTreeBase) => offerReduxSelectors.getShopperInfo(state as StateTree),
        getShopperZip: (state: IStateTreeBase) => offerReduxSelectors.getShopperZip(state as StateTree),
        isMonthlyPaymentInvalid: (state: IStateTreeBase) => offerReduxSelectors.isMonthlyPaymentInvalid(state as StateTree),
        getOfferCreationDateString: (state: IStateTreeBase) => offerReduxSelectors.getOfferCreationDateString(state as StateTree),
        getIsOfferBeingSubmitted: (state: IStateTreeBase) =>
            offerInfoSelectors.getIsOfferBeingSubmitted(state as StateTree) as boolean,
        getVehicleDetails: (state: IStateTreeBase) => offerReduxSelectors.getVehicleDetails(state as StateTree),
        isPaymentInvalid,
        createGatewayOfferPayload: (state: IStateTreeBase, offerUpdateType?: string) =>
            offerPayloadUtil.createGatewayOfferPayload(state as StateTree, offerUpdateType, BuildPayloadPurpose.TradeIn),
        createOfferPayload: (state: IStateTreeBase) => offerPayloadUtil.createOfferPayload(state as StateTree),
        isOfferFromDealer: (state: any) => state,
        getOfferDealerSource: (state: IStateTreeBase) => offerReduxSelectors.getOfferDealerSource(state as StateTree)
    },
    creditSelectors: {
        isCreditAppCompleted: (state: IStateTreeBase) => creditAppSelectors.isCreditAppCompleted(state as StateTree)
    },
    // NOTE: Since we are not using the old menu, we don't need to provide it to trade activity.
    menu: {
        getMenuRoutes: /* istanbul ignore next */ () => null,
        menuSelectors: /* istanbul ignore next */ () => null
    },
    paymentSelectors: {
        isCalculatingPayment: (state: IStateTreeBase) => paymentSelectors.isCalculatingPayment(state as StateTree)
    },
    vehicleSelectors: {
        getVehicleImageUrl: (state: IStateTreeBase) => vehicleSelectors.getVehicleImageUrl(state as StateTree),
        getVehicleVin: (state: IStateTreeBase) => vehicleSelectors.getVehicleVin(state as StateTree)
    },
    leadFormInfoSelectors: {
        isOfferSubmitSuccess: (state) => leadFormInfoSelectors.isOfferSubmitSuccess(state) || false,
        isOfferSubmitFailed: (state) => leadFormInfoSelectors.isOfferSubmitFailed(state) || false
    },
    optimizelySelectors: {
        isVariationActive: (state: StateTree, optimizelyTest: any, optimizelyVariation: string) => {
            return optimizelyTest.name === 'vehicleProtectionInPrimaryFlow';
        },
        getOptimizelyTests: () => ({
            vehicleProtectionInPrimaryFlow: {
                name: 'vehicleProtectionInPrimaryFlow',
                primaryFlow: 'some-fake-value-for-now'
            }
        }),
        activateTests: () => null,
        getTestValue: () => null,
        getTestNames: () => OPTIMIZELY_TEST_NAMES,
        track: () => null
    },
    navigationCardSelectors: {
        getNextCardDetails: () => null
    },
    routeSelectors: {
        getCurrentLocation: (state: StateTree) => routingSelectors.getRouterPathName(state),
        isUserRedirectedToManualEntry: (state: any) => state
    },
    uiHelpersUtil: {
        milesToStringShort
    },
    widgetSelectors: {
        getSponsor: (state: StateTree) => widgetSettingsSelectors.getSponsor(state as StateTree),
        isDealerUser: (state: StateTree) => widgetSettingsSelectors.isDealerUser(state as StateTree)
    },
    modalSelectors: {
        isTradeInVehicleInfoModalVisible: () => false // TODO: How to port this from SP?
    },
    editPaymentActivitiesSelectors: {
        messageBox: (actionType: string, state: any, hasMessageBoxBeenClosed: (state: any) => boolean) => ({
            message: actionType
        })
    },
    tradeInSelectors: {
        getTradeInSource: (state: any) => state // TODO: Add selector from SP
    }
};
