import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { StateTree } from '@makemydeal/dr-dash-types';

export type ShowWithSelectorProps = {
    /**
     * State selector for determine if content should be shown or not.
     * Return value of the selector will be casted to boolean using the Boolean() function.
     */
    selector: (state: StateTree) => unknown;
    /**
     * Children render when selector returns true. Will not be rendered if active prop is used
     */
    children?: ReactNode;

    /**
     * Render content when selector returns true, same as children. Take precedence over children prop
     */
    active?: ReactNode;

    /**
     * Render content when selector returns false
     */
    inactive?: ReactNode;
};

export const ShowWithSelector: FC<ShowWithSelectorProps> = ({ selector, children, active, inactive }) => {
    const enabled = Boolean(useSelector(selector));
    const childNode = active ?? children;

    return (
        <>
            {enabled && <>{childNode}</>}
            {!enabled && <>{inactive}</>}
        </>
    );
};
