// externals
import React from 'react';

// libraries
import { Root } from '@makemydeal/dr-activities-accessories-catalog';

// consts/enums
import { ACCESSORIES_CATALOG_ROUTE_ROOT } from '../constants';

const AccessoriesCatalog = () => {
    return <Root baseRoute={ACCESSORIES_CATALOG_ROUTE_ROOT} />;
};

export default AccessoriesCatalog;
