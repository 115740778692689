// externals
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { TextInput } from '@interstate/components/TextInput';
import { Button } from '@interstate/components/Button';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { MagnifyingGlassIcon } from '@interstate/components/Icons';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { ToggleGroup, ToggleButtonOptions } from '@interstate/components/ToggleGroup';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// local components
import BuildVehicle, { InitBuildVehicleValueType } from '../buildVehicle/BuildVehicle';
import ChangeVehicleSearchResult from './ChangeVehicleSearchResult';

// types
import { IVehicle } from '@makemydeal/dr-platform-types';

// styles
import {
    InlineSearchButtonContainer,
    ChangeVehicleTabsContainer,
    SearchAndSelectVehicleContainerWrapper,
    SearchAndSelectVehicleContainer
} from './ChangeVehicle.interstate.style';
import { SearchBarInfoIcon, SearchBarLabelContainer, SearchBarTooltip, SearchBarLabel } from './SearchAndSelectVehicle.style';

// store
import { changeVehicleCreators, changeVehicleSelectors } from '../../store';

enum TABS {
    searchVehicle,
    buildVehicle
}

type TabIndex = TABS.searchVehicle | TABS.buildVehicle;

const tabsData = [
    {
        title: <div data-testid="search-vehicle-tab">Search Vehicle</div>,
        value: TABS.searchVehicle
    },
    {
        title: <div data-testid="build-vehicle-tab">Build Vehicle</div>,
        value: TABS.buildVehicle
    }
];

const SearchAndSelectVehicle = ({ coOrgId }: { coOrgId?: string }) => {
    const theme = useInterstateTheme();
    const dispatch = useDispatch();
    const searchBarLabel = 'VIN/Stock#';
    const searchBarTooltipMessage = 'Enter full VIN or its last 8 characters, or Stock #';
    const searchBarPlaceholder = 'Enter full VIN or its last 8 characters, or Stock #';
    const { getServicesBff, getCommonOrgId } = changeVehicleSelectors.additionalSelectors;

    const [searchText, setSearchText] = useState('');
    const searchQuery = useSelector(changeVehicleSelectors.getSearchQuery);
    const searchResults = useSelector(changeVehicleSelectors.getSearchResults);
    const bffEndpoint = useSelector(getServicesBff);
    const isSearchError = useSelector(changeVehicleSelectors.getShowSearchError);
    const orgId = useSelector(getCommonOrgId);
    const [activeTabIndex, setActiveTabIndex] = useState<TabIndex>(TABS.searchVehicle);
    const isSearchVehicleTabActive = activeTabIndex === TABS.searchVehicle;
    const isSellAnyVehicleEnabled: boolean = useSelector(featureToggleSelectors.isSellAnyVehicleEnabled);
    const [buildVehicleValue, setBuildVehicleValue] = useState<InitBuildVehicleValueType>({
        vin: undefined,
        year: '',
        make: '',
        model: '',
        trim: ''
    });

    const commonOrgId = coOrgId ?? orgId;

    const tabs: ToggleButtonOptions[] = tabsData.map(({ title, value }) => {
        const children = <div>{title}</div>;
        return {
            value,
            children,
            selected: activeTabIndex === value
        };
    });

    const handleTextSearchChange = (event: InterstateOnChangeEvent<any>) => {
        setSearchText(event.target.value.toUpperCase());
    };

    const handleInterstateSearchClick = () => {
        dispatch(changeVehicleCreators.searchBtnClicked());
        const creator = isSellAnyVehicleEnabled
            ? changeVehicleCreators.searchVehicles
            : changeVehicleCreators.fetchCviVehiclesResults;
        dispatch(creator(searchText, commonOrgId, bffEndpoint));
    };

    const redirectToBuildVehicleTab = (vehicle: IVehicle) => {
        const vin = searchQuery;
        const { make = '', model = '', trim = '', year = '' } = vehicle;
        setBuildVehicleValue({ vin, make, model, trim, year: `${year}` });
        setActiveTabIndex(TABS.buildVehicle);
    };

    const error = isSearchError ? 'Vehicle not found' : '';

    return (
        <SearchAndSelectVehicleContainerWrapper>
            <SearchAndSelectVehicleContainer className="search-select-vehicle-container">
                {isSellAnyVehicleEnabled && (
                    <ChangeVehicleTabsContainer>
                        <ToggleGroup
                            onChange={(_e, value: TabIndex) => {
                                if (activeTabIndex !== value) {
                                    setActiveTabIndex(value);
                                    setBuildVehicleValue({
                                        vin: undefined,
                                        year: '',
                                        make: '',
                                        model: '',
                                        trim: ''
                                    });
                                    dispatch(changeVehicleCreators.changeVehicleTabChanged());
                                }
                            }}
                            toggleButtonOptions={tabs}
                            toggleWidth="50%"
                            size="medium"
                            id="change-vehicle-tabs"
                            data-testid="change-vehicle-tabs"
                        />
                    </ChangeVehicleTabsContainer>
                )}

                {isSearchVehicleTabActive || !isSellAnyVehicleEnabled ? (
                    <>
                        <div id="search-bar-container">
                            <SearchBarLabelContainer data-testid="change-vehicle-search-label-container" theme={theme}>
                                <SearchBarLabel data-testid="change-vehicle-search-label">{searchBarLabel}</SearchBarLabel>
                                <SearchBarTooltip
                                    data-testid="change-vehicle-search-label-tooltip"
                                    toolTipContent={searchBarTooltipMessage}
                                    position="right"
                                    size="large"
                                >
                                    <SearchBarInfoIcon data-testid="change-vehicle-search-label-tooltip-icon" theme={theme} />
                                </SearchBarTooltip>
                            </SearchBarLabelContainer>
                            <TextInput
                                id="search-bar-input"
                                data-testid="change-vehicle-search-input"
                                name="textInputAppend"
                                placeholder={searchBarPlaceholder}
                                onChange={handleTextSearchChange}
                                errorMessage={error}
                                hasError={!!error}
                                value={searchText}
                                maxLength={17}
                                inputSuffix={
                                    <InlineSearchButtonContainer theme={theme}>
                                        <Button
                                            id="change-vehicle-search-btn-inline"
                                            buttonStyle="primary"
                                            icon={<MagnifyingGlassIcon />}
                                            onClick={handleInterstateSearchClick}
                                            data-testid="change-vehicle-search-btn-inline"
                                        />
                                    </InlineSearchButtonContainer>
                                }
                            />
                        </div>

                        {searchResults && (
                            <ChangeVehicleSearchResult
                                searchResults={searchResults}
                                isSearchError={isSearchError}
                                redirectToBuildVehicleTab={redirectToBuildVehicleTab}
                            />
                        )}
                    </>
                ) : (
                    <div id="build-vehicle-content">
                        <BuildVehicle initValue={buildVehicleValue} />
                    </div>
                )}
            </SearchAndSelectVehicleContainer>
        </SearchAndSelectVehicleContainerWrapper>
    );
};

export default SearchAndSelectVehicle;
