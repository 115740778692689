// externals
import React, { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';

// libraries
import type { FeatureTogglesAndUrlOnly } from '@makemydeal/dr-shared-store';
import { deskingSelectors } from '@makemydeal/dr-dash-store';

export type ShowWithFeatureToggleProps = {
    /**
     * Feature toggle name
     */
    name: keyof FeatureTogglesAndUrlOnly | (keyof FeatureTogglesAndUrlOnly)[];
    /**
     * Children render when feature toggle ENABLED
     */
    children?: ReactNode;

    /**
     * Render content when feature toggle is enabled
     */
    active?: ReactNode;

    /**
     * Render content when feature toggle is disabled
     */
    inactive?: ReactNode;
};

export const ShowWithFeatureToggle: FC<ShowWithFeatureToggleProps> = ({ name, children, active, inactive }) => {
    const features: (keyof FeatureTogglesAndUrlOnly)[] = [];

    if (Array.isArray(name)) features.push(...name);
    else features.push(name);

    // Use useSelector outside of any conditional or loop
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const featureToggleStates = features.map((featureName) => useSelector(deskingSelectors.featureEnabled(featureName)));

    // Determine if any features are enabled
    const enabled = featureToggleStates.some((featureName) => featureName);

    if (children == null && inactive == null && active == null)
        throw new Error('Cannot render this component without one of: [children, active, or inactive] props.');
    if (active != null || inactive != null) {
        return <>{enabled ? active : inactive}</>;
    }
    return <>{enabled && <>{children}</>}</>;
};
