export enum FeesCategoriesType {
    DEALER = 'Dealer',
    GOVERNMENT = 'Government',
    LENDER = 'Lender'
}

export type FeeCategory = {
    active: boolean;
    category: FeesCategoriesType;
    feeDescription: string;
    id: number;
    includeInCalc: boolean;
};

export type FeeCategories = {
    dealer: FeeCategory[];
    government: FeeCategory[];
    lender: FeeCategory[];
};
