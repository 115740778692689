import React from 'react';
import { useSelector } from 'react-redux';
import { TruncateText } from '@interstate/components/TruncateText';
import { ChatBubbleLeftIcon } from '@interstate/components/Icons/ChatBubbleLeftIcon';
import { Typography } from '@interstate/components/Typography';
import { Grid } from '@interstate/components/Grid';
import { Box } from '@interstate/components/Box';
import { Tooltip } from '@interstate/components/Tooltip';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { TruncatedMessageContainer, OfferCommentContainer, StyledTruncateText } from './OfferMessage.style.interstate';

export interface IOfferMessageProps {
    shopperMessage?: string;
    dealerMessage?: string;
    offerSource?: string;
}

export const OfferMessage: React.FC<IOfferMessageProps> = ({ shopperMessage, dealerMessage, offerSource }: IOfferMessageProps) => {
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    const isOfferSourceDealer = offerSource?.toLowerCase() === 'dealmaker';
    const getMessage = () => (isOfferSourceDealer ? dealerMessage : shopperMessage);
    const messageLength = getMessage()?.length || 0;
    const shouldTruncate = messageLength > 38;

    return isABPencilEnabled ? (
        <Grid data-testid="offer-message" display={'flex'} gap={1} wrap={'nowrap'} alignItems={'center'}>
            <Box flexShrink={0} display={'inline-flex'}>
                <ChatBubbleLeftIcon data-testid="chat-icon" />
            </Box>

            <Tooltip position="bottom" size="lg" toolTipContent={`${getMessage() || '--'}`}>
                <StyledTruncateText>
                    <Typography variant="body-xxs" tag="span" data-testid="offer-message-text">
                        {getMessage() || '--'}
                    </Typography>
                </StyledTruncateText>
            </Tooltip>
        </Grid>
    ) : (
        <OfferCommentContainer className="comment-container" data-testid="offer-message">
            {getMessage() ? (
                <>
                    <ChatBubbleLeftIcon data-testid="chat-icon" className="chat-icon" />
                    <TruncatedMessageContainer>
                        <Typography variant="body-xs" tag="span">
                            <TruncateText
                                text={`"${getMessage()}`}
                                displayTruncatedText={!shouldTruncate}
                                displayToolTip={shouldTruncate}
                                sx={{
                                    '#truncate-text-interstate-truncate-text:after': {
                                        content: '"\\0022"'
                                    }
                                }}
                            />
                        </Typography>
                    </TruncatedMessageContainer>
                </>
            ) : (
                '--'
            )}
        </OfferCommentContainer>
    );
};
