/**
 * Checks whether at least one of required fields is empty
 * @param fields Trade In form fields
 * @returns
 */
export function hasEmptyField({
    vehicleMake,
    vehicleMileage,
    vehicleModel,
    vehicleTrim,
    vehicleYear
}: Record<'vehicleMileage' | 'vehicleYear' | 'vehicleMake' | 'vehicleModel' | 'vehicleTrim', string | number>) {
    return !vehicleMake || !vehicleMileage || !vehicleModel || !vehicleTrim || !vehicleYear;
}
