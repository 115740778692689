import { Action } from '@interstate/components/Action';
import { Typography } from '@interstate/components/Typography';

export interface ActionButtonProps {
    label: string;
    handleClick: () => void;
    dataTestId: string;
    disabled: boolean;
}

export const ActionButton = ({ label, handleClick, dataTestId, disabled }: ActionButtonProps) => (
    <Action onClick={handleClick} data-testid={dataTestId} disabled={disabled}>
        <Typography
            variant="anchor-block-sm"
            tag={disabled ? 'div' : 'a'}
            color={disabled ? 'base.color.gray.400' : 'base.color.blue.700'}
        >
            {label}
        </Typography>
    </Action>
);
