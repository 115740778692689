/* istanbul ignore file */

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

// actions
import { clearIncentives, getIncentivesForSelectedProgramLender } from '../actions/programActionCreators';

export const programsMiddleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);

    switch (action.type) {
        case 'APPLIED_PROGRAM': {
            store.dispatch(clearIncentives());
            break;
        }
    }

    if (action.meta?.incentives) {
        store.dispatch(getIncentivesForSelectedProgramLender());
    }
};
