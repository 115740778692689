// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { MANAGER_VIEW_APP_PREFIX, apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';

// consts/enums
import { VAUTO_TRIFORCE_FAILURE, VAUTO_TRIFORCE_REQUEST, VAUTO_TRIFORCE_SUCCESS } from '../actionTypes/vAutoTriforceActionTypes';

// selectors
import { getCommonOrgId } from '../selectors/offerRedux';
import { getBridgeId } from '../selectors/bridgeUserSelectors';

export const getVAutoTriforceData = (appraisalId: string) => (dispatch: any, getState: any) => {
    const state = getState();
    const commonOrgId = getCommonOrgId(state);
    const platformUserId = getBridgeId(state);

    if (!appraisalId || !commonOrgId || !platformUserId) return;

    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, '/vAutoTriforceWidgetData');
    const endpoint = `${bffApiUrlResult.url}/${appraisalId}`;

    dispatch({
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                PlatformUserId: platformUserId,
                CommonOrgId: commonOrgId
            },
            types: [VAUTO_TRIFORCE_REQUEST, VAUTO_TRIFORCE_SUCCESS, VAUTO_TRIFORCE_FAILURE]
        }
    });
};
