export type TableAlertInputProps = {
    tableAlertInput: TableAlertInput;
};

export type TableAlertInput = {
    type: TableAlertInputType;
    message: string;
};

export type TableAlertAction = {
    text: string;
    handler: () => void;
};

export enum TableAlertInputType {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error'
}

export type TableGlobalAlertInputProps = {
    message: string;
    name: string;
    action?: TableAlertAction;
};

export enum TableGlobalAlertNames {
    HAS_PRODUCTS_WITH_INAPPLICABLE_ATTRIBUTES = 'has-products-with-inapplicable-attributes',
    MENU_PRICING_DISABLED = 'menu-pricing-disabled',
    MISSING_ATTRIBUTES = 'missing-attributes',
    PAYMENT_FAILED = 'payment-failed',
    RATES_LOADING = 'rates-loading',
    RATING_FAILED = 'rating-failed',
    RATING_TIMEOUT = 'rating-timeout',
    UNSUPPORTED_PRODUCT = 'unsupported-product',
    VPP_DISABLED = 'vpp-disabled'
}
