// libraries
import type { ShopperNotificationsState, StateTree } from '@makemydeal/dr-dash-types';

export const getShopperNotifications = (state: StateTree) => {
    return state.shopperNotifications || ({} as ShopperNotificationsState);
};

export const getShopperNotificationsByActivity = (state: StateTree, activityType: string) => {
    const shopperNotifications = getShopperNotifications(state);
    return shopperNotifications.notificationsList
        ? shopperNotifications.notificationsList.filter((notification) => notification.activity === activityType)
        : [];
};
