// externals
import styled from 'styled-components';

export const TradeVinContainer = styled('div')`
    display: flex;
    flex-grow: 1;
`;

export const TradeVinDecodeContainer = styled.div`
    position: relative;
`;

export const TradeMultipleVIN = styled.div`
    position: absolute;
    width: 100%;
    padding: 8px 0;
    border-radius: 0.25rem;
    border: 1px solid #cacfd9;
    box-shadow: 0 6px 12px rgba(22, 23, 26, 0.1);
    background-color: #fff;
    z-index: 6;
    top: 100%;

    > p {
        text-align: left;
        color: #6d727a;
        cursor: pointer;
        padding: 4px 20px;
        margin: 0;

        &:hover {
            background-color: #2b6bdd;
            color: #fff;
        }
    }
`;

export const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AccordionExpandButton = styled.div<{ $hidden?: boolean }>`
    display: ${(props) => (props.$hidden ? 'none' : 'flex')};
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: flex-start;
    > div {
        flex: 1 calc(33.33% - 1rem);
    }
`;

export {};
