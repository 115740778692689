// external
import styled from 'styled-components';

import {
    VehicleListImageContainer,
    VehicleListInfoContainer,
    VehicleListItemContent
} from '../vehicleListItem/VehicleListItem.interstate.style';

const TWO_CARDS_LAYOUT_WIDTH = '654px';
const THREE_CARDS_LAYOUT_WIDTH = '990px';

export const ChangeVehicleHeaderContainerWrapper = styled.div`
    width: 100%;
    container-name: changeVehicleHeaderContainerWrapper;
    container-type: inline-size;
`;

export const ChangeVehicleHeaderContainer = styled.div`
    margin: 0 auto;
    width: 100%;

    @container changeVehicleHeaderContainerWrapper (min-width: ${TWO_CARDS_LAYOUT_WIDTH}) {
        max-width: ${TWO_CARDS_LAYOUT_WIDTH};
    }

    @container changeVehicleHeaderContainerWrapper (min-width: ${THREE_CARDS_LAYOUT_WIDTH}) {
        max-width: ${THREE_CARDS_LAYOUT_WIDTH};
    }
`;

export const SearchAndSelectVehicleContainerWrapper = styled.div`
    width: 100%;
    container-name: selectVehicleContainerWrapper;
    container-type: inline-size;
`;

export const SearchAndSelectVehicleContainer = styled.div`
    margin: 0 auto;
    width: 100%;

    @container selectVehicleContainerWrapper (min-width: ${TWO_CARDS_LAYOUT_WIDTH}) {
        max-width: ${TWO_CARDS_LAYOUT_WIDTH};

        ${VehicleListImageContainer} {
            flex-basis: 100%;
            height: 191px;
            &.reduced-height {
                height: 115px;
                min-height: 115px;
            }
        }

        ${VehicleListInfoContainer} {
            margin-bottom: 16px;
        }

        ${VehicleListItemContent} {
            flex-wrap: wrap;
        }

        .search-result-title {
            margin: 25px 0 32px 0;
        }
    }

    @container selectVehicleContainerWrapper (min-width: ${THREE_CARDS_LAYOUT_WIDTH}) {
        max-width: ${THREE_CARDS_LAYOUT_WIDTH};
    }
`;

export const InlineSearchButtonContainer = styled.div`
    button {
        margin-right: -12px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    #change-vehicle-search-btn-inline svg {
        color: ${({ theme }) => theme.tokens.BaseColorWhite};
    }
`;

export const ChangeVehicleTabsContainer = styled.div`
    #change-vehicle-tabs {
        width: 100%;
        margin-bottom: 16px;
    }
`;
