import { useDispatch, useSelector } from 'react-redux';

// selectors
import { tradeInActionCreators, tradeInSelectors } from '@makemydeal/dr-dash-store';

// libraries
import { kebabCase } from '@makemydeal/dr-common-utils';

// constants
import { DOORS, ZERO_WIDTH_SPACE } from '../../../constants';

// components
import { SelectInput } from '@interstate/components/SelectInput';
import { Typography } from '@interstate/components/Typography';

// styling
import { DetailContainer } from '../trade.interstate.style';

interface DoorsFieldProps {
    hasTradeIn: boolean;
    isManualFormOpen: boolean;
}

export const DoorsField: React.FC<DoorsFieldProps> = ({ hasTradeIn, isManualFormOpen }) => {
    const dispatch = useDispatch();
    const doors = useSelector(tradeInSelectors.getDoors);
    const options = [
        { value: '2', label: '2' },
        { value: '4', label: '4' },
        { value: 'other', label: 'Other' }
    ];

    const onChange = (event: any) => {
        dispatch(tradeInActionCreators.updateTradeInDoors(event.target.value));
    };

    return (
        <>
            {isManualFormOpen ? (
                <SelectInput
                    data-testid="trade-in-doors-select"
                    label={DOORS}
                    name={kebabCase(DOORS)}
                    onChange={onChange}
                    value={doors}
                    options={options}
                    placeholder="Select"
                    displayDeselectOption={false}
                />
            ) : (
                <>
                    {hasTradeIn && (
                        <DetailContainer>
                            <Typography variant="label-md" color="sem.color.on-surface.muted" sx={{ marginBottom: '8px' }}>
                                {DOORS}
                            </Typography>
                            <Typography variant="body-md" tag="span" data-testid="trade-doors-value-label">
                                {doors || ZERO_WIDTH_SPACE}
                            </Typography>
                        </DetailContainer>
                    )}
                </>
            )}
        </>
    );
};
