import {
    PAYMENT_DETAILS_ACCORDION_TOGGLE,
    FEES_ACCORDION_TOGGLE,
    TAXES_ACCORDION_TOGGLE,
    LIENHOLDER_ACCORDION_TOGGLE
} from '../actionTypes/deskingGlobalActionTypes';

export const togglePaymentDetailsAccordion = () => {
    return {
        type: PAYMENT_DETAILS_ACCORDION_TOGGLE
    };
};

export const toggleTaxesAccordion = () => {
    return {
        type: TAXES_ACCORDION_TOGGLE
    };
};

export const toggleFeesAccordion = () => {
    return {
        type: FEES_ACCORDION_TOGGLE
    };
};

export const toggleLienholderAccordion = () => {
    return {
        type: LIENHOLDER_ACCORDION_TOGGLE
    };
};
