import React from 'react';

// libraries
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import type { OfferType } from '@makemydeal/dr-platform-types';
import { Typography } from '@interstate/components/Typography';
import { Grid } from '@interstate/components/Grid';
import { buildPaymentSummary } from './utils/buildPaymentSummary';

export interface OfferCardDetailsABProps {
    offerType: OfferType;
    monthlyPayment?: number;
    netCashDown?: number;
    tradeAllowance?: number;
    offerPrice?: number;
    months?: number;
    apr?: number;
    vehicleTitle?: string;
    annualMiles?: number;
}

export const OfferCardDetailsAB: React.FC<OfferCardDetailsABProps> = ({
    offerType,
    monthlyPayment,
    netCashDown,
    tradeAllowance = 0,
    offerPrice,
    months,
    apr,
    vehicleTitle,
    annualMiles = 0
}) => {
    const renderPaymentRow = (label: string, value: string) => {
        const dataTestId = kebabCase(label);

        return (
            <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'} data-testid={`${dataTestId}-payment-row`}>
                <Typography variant="label-md" color="sem.color.on-surface.muted">
                    {label}
                </Typography>
                <Typography variant="body-md" color="sem.color.on-surface.default">
                    {value}
                </Typography>
            </Grid>
        );
    };

    return (
        <>
            <Grid display={'grid'} gap={1}>
                {vehicleTitle && (
                    <Typography variant="body-md" data-testid="offer-card-details-vehicle-title">
                        {vehicleTitle}
                    </Typography>
                )}
                <Typography variant="strong-md">
                    {buildPaymentSummary(offerType, months, apr, monthlyPayment, offerPrice, annualMiles)}
                </Typography>
            </Grid>
            <Grid marginTop={2} display={'grid'} gap={1} alignContent={'start'}>
                {renderPaymentRow('Selling Price', formatDollarsAndCents(offerPrice))}
                {offerType !== 'cash' && renderPaymentRow('Cash Down', formatDollarsAndCents(netCashDown))}
                {tradeAllowance > 0 && renderPaymentRow('Trade Allowance', formatDollarsAndCents(tradeAllowance))}
            </Grid>
        </>
    );
};
