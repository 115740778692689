export const RouteValues = {
    CHANGE_VEHICLE: '/changeVehicle',
    DASHBOARD: '/dashboard',
    DEAL_HISTORY: '/dealHistory',
    DEFAULT: '/',
    CREDIT_BUREAU: '/creditBureau',
    CREDIT_DECISION: '/creditDecision',
    PROGRAMS: '/programs',
    DDJ: '/manage-documents'
};
