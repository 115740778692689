// libraries
import { DcVppVehicleUsage, VppDealType, VppFuelType, VppInventoryType } from '@makemydeal/dr-platform-types';

export type DealProductAddOn = {
    addOnCost: number;
    addOnDescription?: string;
    addOnPrice: number;
    addOnSurchargeCode: string;
    isAddOnEditable?: boolean;
};

export type ProductAddOnsSummary = {
    totalProductAddOnCost?: number;
    totalProductAddOnPrice?: number;
};

export type ProductAddOnDetails = {
    productAddOnSummary?: ProductAddOnsSummary;
    productAddOns?: DealProductAddOn[];
    unselectedProductAddOns?: DealProductAddOn[];
};

export type ProductMarkup = {
    markupMethod?: 'Percent' | 'Dollar';
    markupDollarAmount?: number;
    markupPercentage?: number;
};

export type DealProduct = {
    productName?: string;
    productPrice: number;
    productCategoryCode: string;
    profitLocation?: string;
    productCode?: string;
    productCost?: number;
    productAddOnDetails?: ProductAddOnDetails;
    providerCode?: string;
    productInitialCost?: number;
    productProviderName?: string;
    productDeductible?: number;
    productDeductibleMethod?: string;
    productCoverage?: string;
    productCoverageEndDate?: string;
    productCoverageLength?: number;
    productCoverageMiles?: number;
    productServiceInterval?: string;
    productMaxCoverage?: number;
    productMonthlyPayment?: number;
    isProductTaxable?: boolean;
    productMarkup?: ProductMarkup;
    productProviderPlan?: string;
    isWeOwe?: boolean;
    weOweItemId?: number;
    minPriceAllowedByLender?: number;
    maxPriceAllowedByLender?: number;
    priceReducerToLenderMax?: number;
    productTaxRate?: number;
    productTaxAmount?: number;
    isProductTaxCapitalized?: boolean;
    isProductPriceCapitalized?: boolean;
    productResidualValue?: number;
    productResidualType?: string;
    selected?: boolean;
    selectedPackage?: any;
    selectedMileage?: number;
    selectedOriginalLevelType?: string;
};

export enum VppDrivetrainType {
    ALL_WHEEL_DRIVE = 'AllWheelDrive',
    FOUR_WHEEL_DRIVE = 'FourWheelDrive',
    FRONT_WHEEL_DRIVE = 'FrontWheelDrive',
    REAR_WHEEL_DRIVE = 'RearWheelDrive'
}

export type SurchargeOverrides = {
    amountFinanced?: number;
    annualMiles?: number;
    apr?: number;
    color?: string;
    contractDate?: string;
    customerState?: string;
    dealType?: VppDealType;
    drivetrainType?: VppDrivetrainType;
    dualRearWheel?: boolean;
    fuelType?: VppFuelType;
    includeLiftKitSuspension?: boolean;
    includeNavigationSystem?: boolean;
    includeSealsAndGasketsMaintenance?: boolean;
    includeSnowPlow?: boolean;
    includeTurboOrSupercharger?: boolean;
    includeWarranty?: boolean;
    inServiceDate?: string;
    inventoryType?: VppInventoryType;
    monthlyPayment?: number;
    odometer?: number;
    overOneTon?: boolean;
    oversizedTires?: boolean;
    purchaseDate?: string;
    retailPrice?: number;
    sellingPrice?: number;
    term?: number;
    usage?: DcVppVehicleUsage;
    vin?: string;
    year?: number;
};
