import { Employee } from '@makemydeal/dr-shared-types';
import { StateTree, SALES_MANAGER } from '@makemydeal/dr-dash-types';
import { getBridgeId, getBridgeFirstName, getBridgeLastName } from '../selectors/bridgeUserSelectors';

export const buildEmployeeDetails = (state: StateTree): Employee[] => {
    const employeeDetails = {
        employeeRole: SALES_MANAGER,
        bridgeId: getBridgeId(state)
    };

    return [employeeDetails];
};
