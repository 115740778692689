// externals
import { InformationCircleIcon } from '@interstate/components/Icons/InformationCircleIcon';
import { Tooltip } from '@interstate/components/Tooltip';
import { useInterstateToken } from '@interstate/components/InterstateThemeProvider';

type IconWithToolTipProps = {
    name: string;
    toolTipContent: string;
};

const IconWithToolTip = ({ toolTipContent, name }: IconWithToolTipProps) => {
    const getToken = useInterstateToken();

    return (
        <div style={{ marginLeft: getToken('base.space.15') as string }}>
            <Tooltip data-testid={`${name}-tooltip-id`} size="large" toolTipContent={toolTipContent} position="right">
                <InformationCircleIcon
                    data-testid={`${name}-info-icon-id`}
                    fontSize={getToken('base.type.size.md') as string}
                    color={getToken('base.color.blue.500')}
                />
            </Tooltip>
        </div>
    );
};

export default IconWithToolTip;
