import { ITradeIn } from '@makemydeal/dr-platform-types';

export const LIENHOLDER_FORM_SHOW_MORE = 'Show more';
export const LIENHOLDER_FORM_SHOW_LESS = 'Show less';

export const LIENHOLDER_FORM_SUMMARY_ADDRESS = 'Address';

export const LIENHOLDER_FORM_ACTION_BUTTON_CANCEL = 'Cancel';
export const LIENHOLDER_FORM_ACTION_BUTTON_APPLY = 'Apply';

export const LIENHOLDER_FORM_HEADER_TITLE = 'Lienholder Information';
export const LIENHOLDER_FORM_HEADER_EDIT_LABEL = 'Edit';
export const LIENHOLDER_FORM_HEADER_ADD_LABEL = '+ Add Lienholder Information';

export const StateCodes = [
    'AL' /* Alabama */,
    'AK' /* Alaska */,
    'AZ' /* Arizona */,
    'AR' /* Arkansas */,
    'CA' /* California */,
    'CO' /* Colorado */,
    'CT' /* Connecticut */,
    'DE' /* Delaware */,
    'DC' /* District of Columbia */,
    'FL' /* Florida */,
    'GA' /* Georgia */,
    'HI' /* Hawaii */,
    'ID' /* Idaho */,
    'IL' /* Illinois */,
    'IN' /* Indiana */,
    'IA' /* Iowa */,
    'KS' /* Kansas */,
    'KY' /* Kentucky */,
    'LA' /* Louisiana */,
    'ME' /* Maine */,
    'MD' /* Maryland */,
    'MA' /* Massachusetts */,
    'MI' /* Michigan */,
    'MN' /* Minnesota */,
    'MS' /* Mississippi */,
    'MO' /* Missouri */,
    'MT' /* Montana */,
    'NE' /* Nebraska */,
    'NV' /* Nevada */,
    'NH' /* New Hampshire */,
    'NJ' /* New Jersey */,
    'NM' /* New Mexico */,
    'NY' /* New York */,
    'NC' /* North Carolina */,
    'ND' /* North Dakota */,
    'OH' /* Ohio */,
    'OK' /* Oklahoma */,
    'OR' /* Oregon */,
    'PA' /* Pennsylvania */,
    'RI' /* Rhode Island */,
    'SC' /* South Carolina */,
    'SD' /* South Dakota */,
    'TN' /* Tennessee */,
    'TX' /* Texas */,
    'UT' /* Utah */,
    'VT' /* Vermont */,
    'VA' /* Virginia */,
    'WA' /* Washington */,
    'WV' /* West Virginia */,
    'WI' /* Wisconsin */,
    'WY' /* Wyoming */
];

export enum LienholderFormActionGroupControlIds {
    ActionButtonsGroup = 'lienholder-form-action-buttons-group',
    ActionButtonCancel = 'lienholder-form-btn-action-cancel',
    ActionButtonApply = 'lienholder-form-btn-action-apply'
}

export enum LienholderFormHeaderControlIds {
    Header = 'lienholder-form-header',
    ActionButtonEdit = 'lienholder-form-header-btn-action-edit',
    ActionButtonAdd = 'lienholder-form-header-btn-action-add'
}

export enum LienholderSummaryControlIds {
    Summary = 'lienholder-form-summary',
    ActionButtonShowMoreOrLess = 'lienholder-form-summary-btn-action-show-more-less',
    Lienholder = 'lienholder-form-summary-lienholder',
    AccountNumber = 'lienholder-form-summary-account-number',
    PayoffDueDate = 'lienholder-form-summary-payoff-due-date',
    PerDiem = 'lienholder-form-summary-per-diem',
    Address = 'lienholder-form-summary-address'
}

export const LienholderAddressKeys: (keyof ITradeIn['lienHolderAddress'])[] = [
    'address',
    'city',
    'postalCode',
    'stateProvince'
] as (keyof ITradeIn['lienHolderAddress'])[];
