import { useContext } from 'react';
import { ExpandableAreaContext, ExpandableAreaContextType } from './Context';

/**
 * Returns collapsible area utility functions.
 */
export function useExpandableArea(): ExpandableAreaContextType {
    const context = useContext(ExpandableAreaContext);
    if (context == null) throw new Error('useExpandableArea must be within a ExpandableAreaProvider.');
    return context;
}
