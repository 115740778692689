// libraries
import { ToggleStatsToggleItem, toggleUseRegistry } from '@makemydeal/dr-feature-toggles-client';

// interfaces/types
import {
    PayloadWithFeatureToggles,
    PayloadWithMetaCurrentDate,
    PayloadWithMetaFeatureToggleStats
} from '@makemydeal/dr-shared-bff-types';

// utils
import { getFeatureTogglesFromInitPayload } from '../payloadUtils/payloadUtils';

export const getFeatureTogglesStatsFromInitPayload = (initPayload: PayloadWithMetaFeatureToggleStats | undefined) =>
    initPayload?.meta?.featureToggles?.featureTogglesLastUsed || [];

export const getToggleStatsCurrentDate = (initPayload: PayloadWithMetaCurrentDate | undefined) => initPayload?.meta?.currentDate;

export const isFeatureToggleStatsEnabled = (initPayload: PayloadWithMetaCurrentDate | undefined) =>
    !!getToggleStatsCurrentDate(initPayload);

export type SetupFeatureToggleStatsInitPayload =
    | PayloadWithMetaCurrentDate & PayloadWithFeatureToggles & PayloadWithMetaFeatureToggleStats;

export const setupFeatureToggleStats = (
    initPayload: SetupFeatureToggleStatsInitPayload | undefined,
    apiCallback: (toggleStatsItems: ToggleStatsToggleItem[]) => void
) => {
    toggleUseRegistry.registerApiCallback(apiCallback);
    const bffInitCallDate = getToggleStatsCurrentDate(initPayload);
    toggleUseRegistry.enableStatsCollection(bffInitCallDate);
    const featureToggles = getFeatureTogglesFromInitPayload(initPayload);
    Object.keys(featureToggles).forEach((name) => {
        toggleUseRegistry.addKnownToggle(name);
    });
    const toggleStats = getFeatureTogglesStatsFromInitPayload(initPayload);
    toggleStats.forEach((toggleStatsItem) => {
        toggleUseRegistry.storeLastUsedForToggle(toggleStatsItem.name, toggleStatsItem.lastUsed);
    });
};
