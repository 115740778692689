/*
 * Purpose: To maintain state that represents the "active document." Everything in this slice of the state tree will
 *   be used to determine whether a save is needed because there's updated information.
 */

// externals
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import type { InternalPrevOfferPayloadState } from '@makemydeal/dr-dash-types';

// consts/enums
import {
    INTERNAL_PATCH_PREV_OFFER_PAYLOAD_MENU_PRODUCTS,
    INTERNAL_UPDATE_PREV_OFFER_FULL_PAYLOAD
} from '../actionTypes/internalPrevOfferActionTypes';

// interfaces/types
import type {
    PatchPrevOfferMenuProductsAction,
    UpdateInternalPrevOfferPayloadDataAction
} from '../actions/internalPrevOfferActionCreators';

export type InternalPrevOfferReducer = Reducer<InternalPrevOfferPayloadState>;

export const reducer: InternalPrevOfferReducer = (state = {} as InternalPrevOfferPayloadState, action: AnyFSA) => {
    if (!action) {
        return state;
    }

    return produce(state, (draft: Draft<InternalPrevOfferPayloadState>) => {
        switch (action.type) {
            case INTERNAL_UPDATE_PREV_OFFER_FULL_PAYLOAD: {
                const actionTyped = action as UpdateInternalPrevOfferPayloadDataAction;
                draft.data = actionTyped.payload?.data;
                draft.dealXgId = actionTyped.payload?.dealXgId;
                draft.dealXgVersion = actionTyped.payload?.dealXgVersion;
                return;
            }
            case INTERNAL_PATCH_PREV_OFFER_PAYLOAD_MENU_PRODUCTS: {
                const actionTyped = action as PatchPrevOfferMenuProductsAction;
                const hasMenuProducts = !!actionTyped.payload?.menuProducts;
                const hasAccessories = !!actionTyped.payload?.accessories;
                if (hasMenuProducts || hasAccessories) {
                    if (!draft.data) {
                        draft.data = {};
                    }
                }
                if (hasMenuProducts) {
                    if (!draft.data.menu) {
                        draft.data.menu = {};
                    }
                    draft.data.menu.menuProducts = actionTyped.payload!.menuProducts;
                }
                if (hasAccessories) {
                    if (!draft.data.accessories) {
                        draft.data.accessories = {};
                    }
                    draft.data.accessories = actionTyped.payload!.accessories;
                }
                return;
            }
        }
    });
};
