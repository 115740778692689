import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CardContent, CardHeader, Card as InterstateCard } from '@interstate/components/Card';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { LoadingSectionParent } from '@makemydeal/dr-dash-components';
import { Typography } from '@interstate/components/Typography';
import { TextArea } from '@interstate/components/TextArea';

export const OfferCommentsInterstate = ({
    title = 'Offer Notes',
    description = 'Notes will appear in the offer history and will be visible on Accelerate and Sales View',
    textRows = 5
}: {
    textRows?: number;
    title?: string;
    description?: string;
}) => {
    const [comment, setComment] = useState('');
    const dispatch = useDispatch();
    const isEnableDealerCommentsEnabled = useSelector(featureToggleSelectors.isEnableDealerCommentsEnabled);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const handleCommentsChanged = (e: any) => {
        setComment(e.target.value);
        dispatch(offerReduxActionCreators.updatedShopperMessage(e.target.value));
        if (isEnableDealerCommentsEnabled) {
            dispatch(offerReduxActionCreators.updatedDealerMessage(e.target.value));
        }
    };

    return (
        <LoadingSectionParent>
            <InterstateCard
                data-testid="offer-notes-container"
                header={
                    <>
                        <CardHeader title={title} data-testid="offer-comments-title" />
                        <Typography
                            variant="body-md"
                            tag="p"
                            sx={{
                                // istanbul ignore next
                                marginBottom: isABPencilEnabled ? '1.3rem' : 0
                            }}
                        >
                            {description}
                        </Typography>
                    </>
                }
                content={
                    <CardContent>
                        <TextArea
                            label=""
                            name="comment"
                            displayLabel={false}
                            value={comment}
                            placeholder="Add comments"
                            rows={textRows}
                            onBlur={handleCommentsChanged}
                            data-testid="offer-comments"
                        />
                    </CardContent>
                }
            />
        </LoadingSectionParent>
    );
};

export default OfferCommentsInterstate;
