// externals
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { accessoriesSelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { Table, TableColumn } from '@interstate/components/Table';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { Typography } from '@interstate/components/Typography';

// components
import { AccessoriesSummary } from './interstate/AccessoriesSummary.interstate';

// types
import { AccessoryItem } from '@makemydeal/dr-shared-types';
import { LEASE } from '@makemydeal/dr-platform-types';

// consts/enums
import * as deskingConstants from '../../../constants';

// styles
import {
    StyledAccessoriesTable,
    AccessoriesTotalRow,
    ACCESSORY_NAME_CLASSNAME,
    MANUAL_LEGEND_CLASSNAME,
    MANUAL_ROW_CLASSNAME,
    TABLE_ROW_CLASSNAME
} from '../ManualAccessories.interstate.styles';

const leaseDealSpecificFields = ['Residual', 'Upfront'];

const tableLayoutSettings = {
    [LEASE]: {
        columns: {
            name: '16.5%',
            id: '13.2%',
            upFront: '8.6%',
            profit: '7.6%',
            weOwe: '9.4%'
        },
        scroll: 850
    },
    other: {
        columns: {
            name: '20.6%',
            id: '16.2%',
            upFront: undefined,
            profit: '10.6%',
            weOwe: '11.6%'
        },
        scroll: 690
    }
};

const buildAccessoriesTableColumns = (offerType: any) => {
    const columnWidths = offerType === LEASE ? tableLayoutSettings[LEASE].columns : tableLayoutSettings.other.columns;

    const renderBooleanValue = (keyValue: string) => (keyValue ? 'Yes' : 'No');

    let columns: TableColumn[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            className: 'column-name',
            width: columnWidths.name,
            ellipsis: { showTitle: true },
            fixed: 'left',
            render: (keyValue: string) => <div className={ACCESSORY_NAME_CLASSNAME}>{keyValue}</div>
        },
        {
            title: 'ID',
            className: 'column-code',
            columnSortable: false,
            dataIndex: 'code',
            width: columnWidths.id,
            ellipsis: { showTitle: true }
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            className: 'column-cost',
            columnSortable: false,
            ellipsis: { showTitle: true },
            render: (keyValue: string) => formatDollarsAndCents(keyValue)
        },
        {
            title: 'Sale',
            dataIndex: 'amount',
            ellipsis: { showTitle: true },
            className: 'column-amount',
            columnSortable: false,
            render: (keyValue: string) => formatDollarsAndCents(keyValue)
        },
        {
            title: 'Install',
            dataIndex: 'installPrice',
            ellipsis: { showTitle: true },
            className: 'column-install',
            columnSortable: false,
            render: (keyValue: number) => formatDollarsAndCents(keyValue || 0)
        },
        {
            title: 'Residual',
            dataIndex: 'residual',
            ellipsis: { showTitle: true },
            className: 'column-residual',
            columnSortable: false,
            render: (keyValue: string) => formatDollarsAndCents(keyValue)
        },
        {
            title: 'Profit',
            dataIndex: 'profit',
            className: 'column-profit',
            columnSortable: false,
            width: columnWidths.profit,
            render: (keyValue: string) => {
                if (keyValue === 'frontEndProfit') {
                    return deskingConstants.FRONT;
                }

                return deskingConstants.BACK;
            }
        },
        {
            title: 'Upfront',
            dataIndex: 'upFront',
            className: 'column-upFront',
            columnSortable: false,
            width: columnWidths.upFront,
            render: renderBooleanValue
        },
        {
            title: 'We-Owe',
            dataIndex: 'weOwe',
            className: 'column-weOwe',
            columnSortable: false,
            width: columnWidths.weOwe,
            render: renderBooleanValue
        }
    ];

    // @TODO: we can change it to 'hidden' property if Interstate starts supporting it
    if (offerType !== LEASE) {
        columns = columns.filter((column: any) => {
            return !leaseDealSpecificFields.includes(column.title);
        });
    }

    return columns;
};

const AccessoriesTable = () => {
    const theme = useInterstateTheme();
    const accessories = useSelector(accessoriesSelectors.getAccessoriesList);
    const shouldShowLegend = useSelector(accessoriesSelectors.hasManualAccessories);
    const totalAccessoriesAmount = useSelector(accessoriesSelectors.getAccessoriesTotalAmount);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);

    const scrollMaxWidth = offerType === LEASE ? tableLayoutSettings[LEASE].scroll : tableLayoutSettings.other.scroll;

    const columns = useMemo(() => buildAccessoriesTableColumns(offerType), [offerType]);

    const isManualLegend = shouldShowLegend ? (
        <Typography variant="body-sm" color="base.color.gray.700" className={MANUAL_LEGEND_CLASSNAME}>
            Manually Edited
        </Typography>
    ) : (
        <div />
    );

    return (
        <StyledAccessoriesTable theme={theme}>
            <Table
                scrollX={scrollMaxWidth}
                dataDensity="small"
                data={accessories}
                columns={columns}
                defaultPageSize={5}
                enablePagination={true}
                rowClassName={(record: AccessoryItem) =>
                    record.isManualAccessory ? `${TABLE_ROW_CLASSNAME} ${MANUAL_ROW_CLASSNAME}` : TABLE_ROW_CLASSNAME
                }
                sortableColumns={true}
                className="accessories-table"
            />
            <AccessoriesTotalRow>
                <AccessoriesSummary totalAmount={totalAccessoriesAmount} />
                {isManualLegend}
            </AccessoriesTotalRow>
        </StyledAccessoriesTable>
    );
};

export default AccessoriesTable;
