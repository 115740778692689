// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';

// selectors
import * as tradeSelectors from './../selectors/trade';
import * as connectionSelectors from '../selectors/connectionSelectors';
import * as vehicleSelectors from '../selectors/vehicleSelectors';

export const createTradePayload = (state: StateTree) => {
    const tradeData = tradeSelectors.getTradeIn(state);
    const connectionId = connectionSelectors.getConnectionId(state);
    const { vin } = vehicleSelectors.getVehicle(state);

    return {
        tradeData,
        vin,
        connectionId
    };
};
