// externals
import { useSelector } from 'react-redux';

// libraries
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { FeatureTogglesAndUrlOnly } from '@makemydeal/dr-shared-store';

export type UseFeatureToggleResult = (name: keyof FeatureTogglesAndUrlOnly) => boolean;

/**
 * Simple hook to return the current value of the feature toggle
 * @param name
 * @returns
 */
export const useFeatureToggle: UseFeatureToggleResult = (name) => {
    const enabled = useSelector(featureToggleSelectors.featureEnabledSelectorForHook(name));
    return enabled;
};
