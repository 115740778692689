import React from 'react';
import { Route } from 'react-router-dom';
import DigitalDealJacket from './DigitalDealJacket';
import { DDJRouteContainer } from './DDJRouteContainer.style';
import { ROUTE_ROOT } from '../constants';

const DDJContainer = () => {
    return (
        <DDJRouteContainer data-testid="ddj-container" className="ddj">
            <Route path={ROUTE_ROOT} component={DigitalDealJacket} />
        </DDJRouteContainer>
    );
};

export default DDJContainer;
