import type { CustomerUIState } from '@makemydeal/dr-dash-types';
import { Reducer } from 'redux';
import { SHOW_CUSTOMER_CARD, SHOW_CUSTOMER_SEARCH } from '../actionTypes/customerUIActionTypes';

export type CustomerUIReducer = Reducer<CustomerUIState>;

export const initialState: CustomerUIState = {
    customerSearchShow: false,
    customerCard: {
        show: false
    }
};

export const reducer: CustomerUIReducer = (state: any = initialState, action: any) => {
    switch (action.type) {
        case SHOW_CUSTOMER_SEARCH: {
            return { ...state, customerSearchShow: action.payload };
        }
        case SHOW_CUSTOMER_CARD: {
            return { ...state, customerCard: { ...action.payload } };
        }
        default: {
            return state;
        }
    }
};
