import { default as InterstateMonthlyPayment } from './sections/MonthlyPayment.interstate';
import { default as InterstateAddons } from './sections/Addons.interstate';
import { default as InterstateTotalResidual } from './sections/TotalResidual.interstate';
import { default as InterstateTotalTax } from './sections/TotalTax.interstate';
import { default as InterstateLeaseTotalTax } from './sections/LeaseTotalTax.interstate';
import { default as InterstateMSRP } from './sections/MSRP.interstate';
import { default as InterstateDiscount } from './sections/Discount.interstate';
import { default as InterstateSellingPrice } from './sections/SellingPrice.interstate';
import { default as InterstateGrossCapCost } from './sections/GrossCapCost.interstate';
import { default as InterstateAdjCapCost } from './sections/AdjCapCost.interstate';
import { default as InterstateCashDown } from './sections/CashDown.interstate';
import { default as InterstateNetSellingPrice } from './sections/NetSellingPrice.interstate';
import { default as InterstateTermLength } from './sections/TermLength.interstate';
import { default as InterstateRebates } from './sections/Rebates.interstate';
import { default as InterstateNetTrade } from './sections/NetTrade.interstate';
import { default as InterstateTotalAmountDue } from './sections/TotalAmountDue.interstate';
import { default as InterstateAmountFinanced } from './sections/AmountFinanced.interstate';
import { default as InterstateTotalDueAtSigning } from './sections/TotalDueAtSigning.interstate';
import { default as InterstateAddsToCapCost } from './sections/AddsToCapCost.interstate';
import { default as InterstateCapCostReduction } from './sections/CapCostReduction.interstate';
import { default as InterstatePayment } from './sections/Payment.interstate';
import { default as InterstateAdjustedResidual } from './sections/AdjustedResidual.interstate';
import { default as InterstateDueAtSigningPaidBy } from './sections/DueAtSigningPaidBy.interstate';
import { default as InterstateDueAtSigning } from './sections/DueAtSigning.interstate';

export const InterstateDealSummarySections = {
    InterstateAddons,
    InterstateAddsToCapCost,
    InterstateAdjCapCost,
    InterstateCapCostReduction,
    InterstateCashDown,
    InterstateDiscount,
    InterstateGrossCapCost,
    InterstateMonthlyPayment,
    InterstateMSRP,
    InterstateNetSellingPrice,
    InterstateNetTrade,
    InterstateRebates,
    InterstateSellingPrice,
    InterstateTermLength,
    InterstateTotalAmountDue,
    InterstateAmountFinanced,
    InterstateTotalDueAtSigning,
    InterstateTotalResidual,
    InterstateTotalTax,
    InterstateLeaseTotalTax,
    InterstatePayment,
    InterstateAdjustedResidual,
    InterstateDueAtSigning,
    InterstateDueAtSigningPaidBy
};
export * from './utils/transform';
