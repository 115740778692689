// externals
import { CheckBox, CheckBoxEventValue } from '@interstate/components/CheckBox';
import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';

// types
import { DealAttributeItemProps } from '../../../types';

// components
import IconWithToolTip from '../../form/IconWithToolTip';

const DealAttributeCheckbox = ({ dealAttributeInput, onChangeValue }: DealAttributeItemProps) => {
    const onChange: InterstateOnChangeCallback<CheckBoxEventValue> = (event: InterstateOnChangeEvent<CheckBoxEventValue>) => {
        /* istanbul ignore next */
        const value = Boolean(event.target.value?.checked);
        onChangeValue(value);
    };

    let optionalTooltip: JSX.Element | null = null;

    if (dealAttributeInput.description) {
        optionalTooltip = <IconWithToolTip name={dealAttributeInput.name} toolTipContent={dealAttributeInput.description} />;
    }

    return (
        <div className="flex-wrapper">
            <CheckBox
                checked={Boolean(dealAttributeInput.value)}
                onChange={onChange}
                label={dealAttributeInput.title}
                data-testid={`${dealAttributeInput.name}-deal-attribute-checkbox-id`}
                value={dealAttributeInput.name}
                name={dealAttributeInput.name}
                id={dealAttributeInput.name}
            />
            {optionalTooltip}
        </div>
    );
};

export default DealAttributeCheckbox;
