// externals
import type { Reducer } from 'redux';
import produce, { type Draft } from 'immer';

import { bootstrapActionTypes } from '@makemydeal/dr-shared-store';
import type { BaggageEntry, Comparison } from '@makemydeal/dr-dash-bff-types';
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { byActiveStatus } from '../utils/multiScenarioUtils';

type ComparisonState = {
    comparisonList: Comparison[];
    activeComparison: Comparison | undefined;
};

export const COMPARISON_INITIAL_STATE: ComparisonState = {
    comparisonList: [],
    activeComparison: undefined
};

export type ComparisonReducer = Reducer<ComparisonState>;

export const reducer: Reducer<ComparisonState> = (state: ComparisonState = COMPARISON_INITIAL_STATE, action: AnyFSA) =>
    produce(state, (draft: Draft<ComparisonState>) => {
        switch (action.type) {
            case bootstrapActionTypes.BOOTSTRAP_SUCCESS: {
                if (Array.isArray(action.payload.baggage)) {
                    const comparisonBaggage = action.payload.baggage.find(
                        ({ name }: BaggageEntry) => name === 'deal360.comparison'
                    );
                    if (comparisonBaggage) {
                        const comparisonList = comparisonBaggage.value.content;
                        draft.comparisonList = comparisonList;

                        const latestDxgVersion = action.payload.offer.dealExchangeVersionId;
                        const activeComparison = comparisonList.find(byActiveStatus);
                        if (activeComparison?.dealXgVersionId === latestDxgVersion) {
                            draft.activeComparison = activeComparison;
                        }
                    }
                }
                break;
            }
        }
    });
