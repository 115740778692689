// externals
import { Reducer } from 'redux';

// types
import { IOffer } from '@makemydeal/dr-platform-types';

// actions
import { UPDATE_QUICK_COMPARE_OFFER } from '../actionTypes/quickCompareActionTypes';

export type QuickCompareReducer = Reducer<IOffer>;

export const reducer: QuickCompareReducer = (state: IOffer = {} as unknown as IOffer, action: any) => {
    switch (action.type) {
        case UPDATE_QUICK_COMPARE_OFFER: {
            return {
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
};
