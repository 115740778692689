/**
 * This action indicates the form submit has been attempted by the user. This is used to display validation errors.
 */
export const MANUAL_INCENTIVES_EDIT_SHOW_VALIDATION = 'MANUAL_INCENTIVES_EDIT_SHOW_VALIDATION';
/**
 * This action initializes the manual incentives form with the provided data.
 */
export const MANUAL_INCENTIVES_EDIT_INIT = 'MANUAL_INCENTIVES_EDIT_INIT';
export const MANUAL_INCENTIVES_EDIT_ADD = 'MANUAL_INCENTIVES_EDIT_ADD';
export const MANUAL_INCENTIVES_EDIT_REMOVE = 'MANUAL_INCENTIVES_EDIT_REMOVE';
export const MANUAL_INCENTIVES_EDIT_UPDATE_FIELD = 'MANUAL_INCENTIVES_EDIT_UPDATE_FIELD';
export const OPEN_MANUAL_INCENTIVE_FORM = 'OPEN_MANUAL_INCENTIVE_FORM';
