// externals
import React, { FC, useEffect } from 'react';
import { SignoutOptions } from '@okta/okta-auth-js';

export const Logout: FC<{ handleLogout: (opts?: SignoutOptions) => Promise<void> }> = ({ handleLogout }) => {
    useEffect(() => {
        console.log('Logout useEffect handleLogout() before');
        handleLogout();
        console.log('  Logout useEffect handleLogout() after');
    }, []);
    return <>Logging out...</>;
};
