// types
import { DatePickerEventValue } from '@interstate/components/DatePicker/Types/datePickerTypes';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents/Types/InterstateInputEventTypes';

export const extractDateValueFromDatepickerEvent = (
    event: InterstateOnChangeEvent<DatePickerEventValue>
): { stringValue: string | undefined; selectedDate: Date | null } => {
    const stringValue = event.target.value?.dateValue;
    const selectedDate = stringValue ? new Date(stringValue) : null;

    return { stringValue, selectedDate };
};
