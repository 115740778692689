import { locHrefUtil, apiConfigHelper, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { RSAA } from 'redux-api-middleware';
import { SCENARIOS_FETCH_FAILURE, SCENARIOS_FETCH_REQUEST, SCENARIOS_FETCH_SUCCESS } from '../actionTypes/multiScenarioActionTypes';
import { MAKE_CURRENT_COMPLETED } from '../actionTypes/offerActionTypes';
import {
    initActionTypes,
    featureToggleSelectors,
    scopedActions,
    scopedActionsCreators,
    scopedStateSelectors
} from '@makemydeal/dr-shared-store';

import * as offerReduxSelectors from '../selectors/offerRedux';
import * as multiScenarioSelectors from '../selectors/multiScenarioSelectors';
import { getOrderFromRetrievedScenarios } from '../utils/multiScenarioUtils';

export const fetchScenarios = (dealXgId: string, scenarioIds: string[]) => {
    const scenarioIdQuery = scenarioIds.join(',');
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(
        MANAGER_VIEW_APP_PREFIX,
        locHref,
        `scenarios/${dealXgId}?scenarioIds=${scenarioIdQuery}`
    );
    const endpoint = bffApiUrlResult.url;
    return {
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [SCENARIOS_FETCH_REQUEST, SCENARIOS_FETCH_SUCCESS, SCENARIOS_FETCH_FAILURE]
        }
    };
};

/**
 * Thunk action creator that dispatches actions for loading additional scenarios
 */
export const handleFetchMultipleScenarios =
    () =>
    (dispatch: any, getState: any): void => {
        const state = getState();
        if (featureToggleSelectors.enableMultiScenarioPersistence(state)) {
            const activeComparison = multiScenarioSelectors.getActiveComparison(state);
            // for some reason optional chaining didn't like this without the initial truthy check
            if (activeComparison && activeComparison.scenarios.length > 1) {
                const dealXgId = offerReduxSelectors.getDealXgId(state);
                const nonPrimaryScenarioIds = activeComparison.scenarios.filter(({ isPrimary }) => !isPrimary).map(({ id }) => id);
                dispatch(fetchScenarios(dealXgId, nonPrimaryScenarioIds));
            }
        }
    };

/**
 * Accepts full alternate scenarios retrieved from DXG and adds them to the scope state, then initializes the scopes
 * @param nonPrimaryScenarios
 * @returns
 */
export const addAlternateScenariosToState =
    (nonPrimaryScenarios: any[]) =>
    (dispatch: any, getState: any): void => {
        const state = getState();
        const activeComparison = multiScenarioSelectors.getActiveComparison(state);
        if (activeComparison && nonPrimaryScenarios.length) {
            const retrievedNonPrimaryScenarioIds = nonPrimaryScenarios.map(({ offer }: any) => offer.dealExchangeVersionId);
            const primaryScopeId = scopedStateSelectors.getPrimaryScopeId(state);
            const order = getOrderFromRetrievedScenarios(activeComparison, retrievedNonPrimaryScenarioIds, primaryScopeId);

            // Must have the scope state partially populated BEFORE fully hydrating with the INIT_PENCIL_SUCCESS dispatch
            dispatch(scopedActionsCreators.createUninitializedScopesFromOrder(order));

            // Hydrate each scenario
            for (const scenario of nonPrimaryScenarios) {
                const scenarioId = scenario.offer.dealExchangeVersionId;
                const newAction = scopedActions.createScopedAction(scenarioId, {
                    type: initActionTypes.INIT_PENCIL_SUCCESS,
                    payload: scenario
                });
                dispatch(newAction);
            }

            dispatch(scopedActionsCreators.initializeScopes(retrievedNonPrimaryScenarioIds));
        }
    };

/**
 * Thunk action creator that dispatches actions for fetching additional scenarios for "make current"
 */
export const handleFetchMakeCurrentMultipleScenarios = () => (dispatch: any, getState: any) => {
    const state = getState();

    if (featureToggleSelectors.enableMultiScenarioPersistence(state)) {
        const dealXgVersion = offerReduxSelectors.getDealXgVersion(state);
        const compObj = multiScenarioSelectors.getComparisonByDealVersion(dealXgVersion, state);

        if (compObj && compObj.scenarios?.length > 1) {
            const dealXgId = offerReduxSelectors.getDealXgId(state);
            const nonPrimaryScenarioIds = compObj.scenarios.filter(({ isPrimary }) => !isPrimary).map(({ id }) => id);

            dispatch(fetchScenarios(dealXgId, nonPrimaryScenarioIds));
        } else {
            dispatch({ type: MAKE_CURRENT_COMPLETED });
            dispatch(addMakeCurrentAlternateScenariosToState([]));
        }
    }
};

/**
 * Add all alternate scenarios retrieved from DXG to the scope state for "make current", then initializes the scopes.
 */
export const addMakeCurrentAlternateScenariosToState =
    (nonPrimaryScenarios: any[]) =>
    (dispatch: any, getState: any): void => {
        const state = getState();
        const dealXgVersion = offerReduxSelectors.getDealXgVersion(state);
        const comparison = multiScenarioSelectors.getComparisonByDealVersion(dealXgVersion, state);

        if (comparison && nonPrimaryScenarios) {
            const nonPrimaryScenarioIds = nonPrimaryScenarios.map(({ offer }: any) => offer?.dealExchangeVersionId);
            const primaryScopeId = scopedStateSelectors.getPrimaryScopeId(state);
            const order = getOrderFromRetrievedScenarios(comparison, nonPrimaryScenarioIds, primaryScopeId);

            // Must have the scope state partially populated BEFORE fully hydrating with the INIT_PENCIL_SUCCESS dispatch
            dispatch(scopedActionsCreators.createUninitializedScopesFromOrder(order));

            // Add each non-primary scenario to state
            for (const scenario of nonPrimaryScenarios) {
                const scenarioId = scenario.offer?.dealExchangeVersionId;
                const newAction = scopedActions.createScopedAction(scenarioId, {
                    type: initActionTypes.INIT_PENCIL_SUCCESS,
                    payload: scenario
                });
                dispatch(newAction);
            }

            dispatch(scopedActionsCreators.initializeScopes(nonPrimaryScenarioIds));
        }
    };
