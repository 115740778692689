const adjustDealPulseComponents = (cardSlider: Element, maxHeaderHeight: number, maxBodyHeight: number): void => {
    const dealPulseContainer = cardSlider.querySelector('#deal-pulse-container') as HTMLElement;
    const dealPulseHeader = cardSlider.querySelector('#deal-pulse-header') as HTMLElement;
    const dealPulseSeparator = cardSlider.querySelector('#deal-pulse-separator') as HTMLElement;

    if (dealPulseContainer) {
        dealPulseContainer.setAttribute('style', `min-height: ${maxBodyHeight}px`);
    }

    if (dealPulseHeader) {
        dealPulseHeader.setAttribute('style', `min-height: ${maxHeaderHeight}px`);
    }

    if (dealPulseSeparator) {
        dealPulseSeparator.setAttribute('style', `min-height: ${maxBodyHeight}px`);
    }
};

const setHeights = (offerCards: NodeListOf<Element>, maxHeaderHeight: number, maxBodyHeight: number): void => {
    offerCards.forEach((offerCard) => {
        const header = offerCard.querySelector('.offer-card-header') as HTMLElement;
        const body = offerCard.querySelector('.offer-card-container') as HTMLElement;

        if (header) {
            header.setAttribute('style', `min-height: ${maxHeaderHeight}px`);
        }

        if (body) {
            body.setAttribute('style', `min-height: ${maxBodyHeight}px`);
        }
    });
};

const getMaxHeights = (offerCards: NodeListOf<Element>): { maxHeaderHeight: number; maxBodyHeight: number } => {
    let maxHeaderHeight = 0;
    let maxBodyHeight = 0;

    offerCards.forEach((offerCard) => {
        const header = offerCard.querySelector('.offer-card-header');
        const body = offerCard.querySelector('.offer-card-container');

        maxHeaderHeight = Math.max(maxHeaderHeight, header?.getBoundingClientRect().height || 0);
        maxBodyHeight = Math.max(maxBodyHeight, body?.getBoundingClientRect().height || 0);
    });

    return { maxHeaderHeight, maxBodyHeight };
};

export const adjustOfferCardHeights = (cardSliderSelector: string): void => {
    const cardSlider = document.querySelector(cardSliderSelector);
    if (!cardSlider) return;

    const offerCards = cardSlider.querySelectorAll('.offer-card');
    if (!offerCards.length) return;

    const { maxHeaderHeight, maxBodyHeight } = getMaxHeights(offerCards);

    setHeights(offerCards, maxHeaderHeight, maxBodyHeight);

    adjustDealPulseComponents(cardSlider, maxHeaderHeight, maxBodyHeight);
};
