// components
import { Typography } from '@interstate/components/Typography';
import { Modal } from '@interstate/components/Modal';
import { Spinner as LoadingIndicator } from '@interstate/components/Spinner';

import { LoadingContainer } from './PushToDmsLoaderDialog.style';

// constants
import { DIALOG_HEADER_INTERSTATE, SHOW_WAITING_LOADER_TEXT, CLOSE_BUTTON_TEXT } from '../pushToDmsConstants';
import { useEffect, useState } from 'react';
import { Button } from '@interstate/components/Button';
import { Grid } from '@interstate/components/Grid';
import { configSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';

export interface IPushToDmsLoaderDialogProps {
    show: boolean;
    isExtendedLoader: boolean;
    handlePushToDmsLoaderClose(): void;
}

const PushToDmsLoaderDialog = (props: IPushToDmsLoaderDialogProps) => {
    const { show, isExtendedLoader, handlePushToDmsLoaderClose } = props;
    const timeout = useSelector(configSelectors.getPushToDmsLoaderTimeout);
    const timeoutInMilliSeconds = parseInt(timeout) * 1000;

    const [startExtendedLoader, setStartExtendedLoader] = useState(false);

    const prefix = 'push-to-dms-loader-dialog';

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (show && isExtendedLoader) {
            timer = setTimeout(() => {
                setStartExtendedLoader(true);
            }, timeoutInMilliSeconds);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [isExtendedLoader, show]);

    useEffect(() => {
        let crossIcon: HTMLElement | null;
        if (startExtendedLoader) {
            crossIcon = document.getElementById('push-to-dms-loader-dialog-close-icon');
            if (crossIcon) crossIcon.addEventListener('click', handlePushToDmsLoaderClose);
        }

        return () => {
            crossIcon?.removeEventListener('click', handlePushToDmsLoaderClose);
        };
    }, [startExtendedLoader, handlePushToDmsLoaderClose]);

    const header = (
        <Typography variant="h3" color="sem.color.on-surface.default">
            {DIALOG_HEADER_INTERSTATE}
        </Typography>
    );

    const footer = (
        <Grid
            container
            data-testid={`${prefix}-footer-container`}
            gap={1}
            justifyContent="flex-end"
            padding="8px 8px"
            marginRight="8px"
        >
            {startExtendedLoader && (
                <Button
                    data-testid={`${prefix}-cancel-button`}
                    buttonStyle={'tertiary'}
                    onClick={handlePushToDmsLoaderClose}
                    sx={{
                        marginRight: '14px'
                    }}
                >
                    {CLOSE_BUTTON_TEXT}
                </Button>
            )}
        </Grid>
    );

    const EXTENDED_LOADER_MESSAGE = (
        <>
            Your request is taking longer <br /> than expected. Thank you for <br /> your patience.
        </>
    );

    return (
        <Modal
            size="large"
            show={show}
            header={header}
            footer={{
                footerComponent: startExtendedLoader ? footer : null
            }}
            customIdentifiers={{ closeButton: { id: `${prefix}-close-icon`, dataTestId: `${prefix}-close-icon` } }}
            sx={{
                '.MuiDialog-paper': {
                    paddingBottom: '24px'
                },
                '#push-to-dms-loader-dialog-close-icon': {
                    display: startExtendedLoader ? '' : 'none',
                    cursor: 'pointer'
                }
            }}
        >
            <LoadingContainer data-testid={`${prefix}-spinner`}>
                <LoadingIndicator />
            </LoadingContainer>
            <Typography
                variant="body-md"
                tag="p"
                color="sem.color.on-surface.subdued"
                sx={{
                    paddingBottom: '45.5px',
                    textAlign: 'center',
                    paddingTop: '96.5px'
                }}
            >
                {startExtendedLoader ? EXTENDED_LOADER_MESSAGE : SHOW_WAITING_LOADER_TEXT}
            </Typography>
        </Modal>
    );
};

export default PushToDmsLoaderDialog;
