// externals
import { FC } from 'react';
import { createNumberMask } from 'text-mask-addons';

// libraries
import { MaskingConfig } from '@interstate/components/TextInput/Types/textInputBaseTypes';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { capitalize } from '@makemydeal/dr-common-utils';
import { DrivetrainTypeEnum } from '@makemydeal/dr-dash-store';

// styles
import { VehicleInfoItemContainer } from './Style';

// components
import { IRowProps, ItemRow } from './ItemRow';
import { FormTextInput } from '../formManager/FormTextInput';
import { LocationItemRow } from './LocationItemRow';

export type VehicleInfoProps = {
    driveLine: string;
    engine: string;
    exteriorColor: string;
    fuel: string;
    interiorColor: string;
    location: {
        isOnLot: boolean;
        gps?: string;
        lot?: string;
    };
    transmission: string;
};

const drivelineToDisplayTextMap: Record<DrivetrainTypeEnum, string> = {
    AllWheelDrive: 'All Wheel Drive',
    FourWheelDrive: 'Four Wheel Drive',
    FrontWheelDrive: 'Front Wheel Drive',
    RearWheelDrive: 'Rear Wheel Drive'
};

export const VehicleInfo: FC<VehicleInfoProps> = ({
    driveLine,
    engine,
    exteriorColor,
    fuel,
    interiorColor,
    location,
    transmission
}) => {
    const theme = useInterstateTheme();

    const getDetails: IRowProps[] = [
        { label: 'Exterior Color', value: capitalize(exteriorColor) },
        { label: 'Interior Color', value: capitalize(interiorColor) },
        { label: 'Fuel', value: capitalize(fuel) },
        { label: 'Engine', value: capitalize(engine) },
        { label: 'Transmission', value: capitalize(transmission) },
        { label: 'Driveline', value: drivelineToDisplayTextMap[driveLine as DrivetrainTypeEnum] || driveLine }
    ];

    const mileageMaskDetails = {
        prefix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ',',
        decimalSymbol: '.',
        requireDecimal: false,
        allowLeadingZeroes: false
    };

    const masking: MaskingConfig = {
        guide: true,
        mask: createNumberMask(mileageMaskDetails),
        unmask: [',']
    };

    return (
        <VehicleInfoItemContainer theme={theme}>
            {getDetails.map((row: IRowProps) => {
                return <ItemRow key={row.label} label={row.label} value={row.value} />;
            })}
            <LocationItemRow label="Location" location={location} />
            <FormTextInput
                name="mileage"
                label="Odometer"
                inputSuffix={'mi'}
                masking={masking}
                sx={{ '.MuiFormLabel-root': { fontSize: '16px' } }}
            />
        </VehicleInfoItemContainer>
    );
};
