import { useDispatch, useSelector } from 'react-redux';

import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { CASH } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors, pushToDarwinActionCreators } from '@makemydeal/dr-dash-store';
import { CardHeader } from '@interstate/components/Card';
import { VehicleProtectionCardProps } from './VehicleProtectionCardProps';
import { VehicleProtectionPreviewTable } from './VehicleProtectionPreviewTable';
import { Action } from '@interstate/components/Action';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import {
    ActionsContainer,
    StyledAction,
    StyledVppCardContent,
    VppPreviewSummaryContainer,
    VppPreviewSummaryRow
} from './VehicleProtectionCardUI.interstate.style';
import { StretchHeightCard } from '../../deskingActivity/DeskingCard.style';

export const VehicleProtectionCardUIInterstate = ({
    actionLabel,
    hideContent,
    monthlyPayment,
    onActionClick,
    totalPrice
}: VehicleProtectionCardProps) => {
    const dispatch = useDispatch();
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const isFinanceOrLease = !(currentOfferType === CASH);
    const isDarwinEnabled = useSelector(dealerSelectors.isDarwinEnabled);
    const enablePushToDarwin = useSelector(featureToggleSelectors.isPushToDarwinEnabled) && isDarwinEnabled;

    const content = (
        <StyledVppCardContent>
            <VehicleProtectionPreviewTable />

            <VppPreviewSummaryContainer>
                <VppPreviewSummaryRow>
                    <Typography variant="body-sm">Total</Typography>
                    <Typography tag="span" variant="strong-sm" data-testid="vpp-card-total-cost">
                        {totalPrice}
                    </Typography>
                </VppPreviewSummaryRow>
                {isFinanceOrLease && (
                    <VppPreviewSummaryRow>
                        <Typography variant="body-sm">Monthly Payment</Typography>
                        <Typography tag="span" variant="strong-sm" data-testid="vpp-card-monthly-payment">
                            {monthlyPayment}
                        </Typography>
                    </VppPreviewSummaryRow>
                )}
            </VppPreviewSummaryContainer>
        </StyledVppCardContent>
    );

    return (
        <StretchHeightCard
            data-testid="vpp-preview"
            header={
                <Grid container justifyContent={'space-between'}>
                    <CardHeader title="Vehicle Protection" />
                    <ActionsContainer>
                        {enablePushToDarwin && (
                            <StyledAction
                                data-testid="vpp-open-in-darwin"
                                onClick={() => dispatch(pushToDarwinActionCreators.openInDarwin())}
                            >
                                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                                    Open in Darwin
                                </Typography>
                            </StyledAction>
                        )}
                        <Action onClick={onActionClick} data-testid="vpp-preview-edit-link">
                            <Typography variant="anchor-block-sm" color="base.color.blue.700">
                                {actionLabel}
                            </Typography>
                        </Action>
                    </ActionsContainer>
                </Grid>
            }
            content={hideContent ? null : content}
        />
    );
};
