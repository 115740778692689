// components
import { Button } from '@interstate/components/Button';

export type ActionButtonProps = {
    status: 'ready' | 'sending' | 'success' | 'failure';
    prefix: string;
    successAction: string;
    readyPrefix: string;
    onAction: () => void;
    actionText: string;
    loadingText: string;
    disabled?: boolean;
};

const getRole = (status: string, readyPrefix: string) => {
    switch (status) {
        case 'ready':
            return readyPrefix;
        case 'sending':
            return 'sending-button';
        case 'success':
            return 'success-button';
        default:
            return 'failure-button';
    }
};

const ActionButtonInterstate = (props: ActionButtonProps) => {
    const { status, prefix, onAction, actionText, loadingText, readyPrefix, disabled: disabledProp } = props;

    const disabled = status === 'sending' || status === 'success' || status === 'failure' || disabledProp;

    return (
        <Button
            data-testid={`${prefix}-${getRole(status, readyPrefix)}`}
            onClick={onAction}
            buttonStyle="primary"
            disabled={disabled}
        >
            {status === 'sending' || status === 'success' ? loadingText : actionText}
        </Button>
    );
};

export default ActionButtonInterstate;
