// externals
import { nanoid } from 'nanoid';
// libraries
import type { StateTree, ProgramQuote } from '@makemydeal/dr-dash-types';
import { LenderGuideLineItem } from '../types/guidelineAlertsTypes';
import type { ProgramsState, Incentive, ProgramIncentives, Exclusions } from '@makemydeal/dr-dash-types';
import { IResponsePayment } from '@makemydeal/dr-platform-types';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { utils as offReduxUtils } from '@makemydeal/dr-offer-redux';

// utils
import { mapPaymentResponseToProgramQuote } from '../utils/programUtils';

// selectors
import * as offerRedux from './offerRedux';
import * as vehicleSelectors from './vehicleSelectors';

export const getPrograms = (state: any): ProgramsState => state?.programs || {};

export const getSelectedProgram = (state: StateTree): ProgramQuote | undefined => getPrograms(state).selectedProgram;

export const getAvailableProgramIncentives = (state: StateTree): ProgramIncentives | undefined => getPrograms(state).available;

/**
 * This utility is used to determine the incentive amounts for a given term and combine them with the main incentive for rendering.
 */
export const getIncentivesWithDeterminedAmountsByTerm = (incentives: Incentive[], term: number) => {
    const amounts = incentives.map((i) => offReduxUtils.incentives.transformTermIncentive(term, i));
    return incentives.map((incentive, index) => ({ ...incentive, cash: amounts[index].amount } as Incentive));
};

export const getAvailableConditionalIncentives = (state: StateTree): Incentive[] =>
    getAvailableProgramIncentives(state)?.conditional ?? [];

/**
 * Return a list of non-conditionals associated to the currently selected quote & incentives (implicitly, selected quote non-conditionals will be in the selected list)
 * @param state
 * @returns
 */
export const getAppliedNonConditionalIncentives = (state: StateTree): Incentive[] => {
    const available = getAvailableProgramIncentives(state);
    const selected = getPrograms(state).selectedIncentives ?? [];
    return available?.nonConditional.filter(({ incentiveId }) => selected.indexOf(incentiveId) >= 0) ?? [];
};

/**
 * Return a list of conditional incentives currently selected.
 * @param state
 * @returns
 */
export const getAppliedConditionalIncentives = (state: StateTree): Incentive[] => {
    const available = getAvailableProgramIncentives(state);
    const selected = getPrograms(state).selectedIncentives || [];
    return available?.conditional.filter(({ incentiveId }) => selected.indexOf(incentiveId) >= 0) ?? [];
};

export const sumAllAppliedIncentives = (state: StateTree): number => {
    const incentives = [...getAppliedNonConditionalIncentives(state), ...getAppliedConditionalIncentives(state)];
    return incentives.map((i) => i.cash).reduce((prev, cur) => prev + cur, 0);
};

export const sumConditionalAppliedIncentives = (state: StateTree): number => {
    const incentives = [...getAppliedConditionalIncentives(state)];
    return incentives.map((i) => i.cash).reduce((prev, cur) => prev + cur, 0);
};

export const getActivePrograms = (state: StateTree): IResponsePayment[] => getPrograms(state).programs ?? [];

export const getAllMessages = (state: StateTree): LenderGuideLineItem[] => {
    const programs = getActivePrograms(state);

    const alerts = programs.reduce((acc: LenderGuideLineItem[], program) => {
        if (Array.isArray(program.messages) && program.messages.length > 0) {
            const combinedMessage = program.messages.map((msgObj: any) => msgObj.message).join(', ');
            acc.push({
                message: combinedMessage,
                lenderCode: program.lenderCode as string
            });
        }
        return acc;
    }, []);

    return alerts;
};

export const getLenderIdFromSelected = (state: StateTree): number | undefined => getSelectedProgram(state)?.lenderProgram?.lenderId;

export const getIncentivesLoadingError = (state: StateTree): boolean => {
    return getPrograms(state).incentivesError ? true : false;
};

export const getProgramsLoadingError = (state: StateTree): boolean => {
    return getPrograms(state).programsError ? true : false;
};

export const getProgramsLoadingStatus = (state: StateTree): boolean => {
    return getProgramsLoadingError(state) || getPrograms(state).programs != null ? false : true;
};

export const getSelectedProgramIncentives = (state: StateTree): number[] => {
    return getSelectedProgram(state)?.appliedIncentiveIds || [];
};

export const getSelectedIncentives = (state: StateTree): number[] => {
    return state!.programs?.selectedIncentives || [];
};

export const getSelectedProgramTerm = (state: StateTree): number | undefined => {
    return getSelectedProgram(state)?.term;
};

/**
 * Check if incentive has been selected in the current state.
 * @param incentiveId Incentive ID provided by PS
 * @returns A selector that evaluates if this incentive is selected.
 */
export const isIncentiveSelectedSelector = (incentiveId: number) => (state: StateTree) => {
    const selectedIncentives = getSelectedIncentives(state);
    return selectedIncentives.indexOf(incentiveId) >= 0;
};

export const getincentiveExclusionsById = (state: StateTree): Exclusions => getPrograms(state).incentiveExclusionsById || [];

export const isIncentiveExcluded = (incentiveId: number) => (state: StateTree) => {
    const exclusions = getincentiveExclusionsById(state);

    if (!Object.keys(exclusions).length) return false;

    return Object.values(exclusions).some((exlusionsById: number[]) => exlusionsById.includes(incentiveId));
};

export const getProgramFromCurrentOffer = (state: StateTree) => {
    const offerType = offerRedux.getCurrentOfferType(state);

    const term = offerRedux.getSelectedTermByOfferType(state, offerType);
    const appliedIncentiveIds = offerRedux
        .getAppliedIncentives(state)
        .map((i) => i.code)
        .filter((i) => i != null);
    const temp: any = {
        acqFee: offerRedux.getDealerAcquisitionFeeFromTerm(term)?.dealerFeeAmount,
        appliedIncentiveAmount: offerRedux.getAppliedIncentivesTotalForNonDealerCash(state),
        appliedIncentiveIds: appliedIncentiveIds,
        baseResidualDollar: offerRedux.getBaseResidualDollar(state),
        baseResidualPercent: offerRedux.getBaseResidualPercent(state),
        buyRate: offerRedux.getBuyRateOverride(state, true),
        creditTierDescription: term?.creditTierDescription,
        lenderCode: term?.lenderCode,
        lenderLegalName: term?.lenderLegalName,
        lenderProgram: term?.lenderProgram,
        monthlyPayment: term?.monthlyPayment,
        rateType: offerRedux.getRateType(state),
        securityDeposit: offerRedux.getSecurityDepositOverride(state, true),
        sellRate: offerRedux.getSellRateOverride(state, true),
        term: term?.months,
        annualMiles: offerRedux.getAnnualMiles(state),
        profit: term?.profit
    };

    const quote: ProgramQuote = mapPaymentResponseToProgramQuote(temp);
    return quote;
};

export const getIncentivePayload = (state: StateTree) => {
    const selectedProgram = getSelectedProgram(state);
    const { chromeStyleId } = vehicleSelectors.getVehicle(state);

    return {
        vehicle: {
            chromeStyleId, // vehicle selectors
            vin: vehicleSelectors.getVehicleVin(state),
            // enhancedAlgCode: selectedProgram?.psRawData?.enhancedAlgCode, // not needed
            // oemCode: { // not needed
            //     oemYear: year,
            //     oemMake: make,
            //     oemModel: model
            // },
            condition: vehicleSelectors.getVehicleCondition(state) // vehicle selectors
            // engineCode: engine, // not needed
            // trim,// not needed
            // packageCode: '101A',// not needed
            // bodyType: bodyStyle, // not needed
            // fuel // not needed
        },
        zipCode: offerRedux.getShopperInfo(state)?.zip ?? dealerSelectors.getDealerZip(state), // shopper zipcode, or dealer zip if shopper zip does not exist, but in DC we are SUPPOSED to have a shopper zip
        dealerId: dealerSelectors.getDealerId(state), // the usual dealerId like 1609 - dealerSelectors should be able to provide it
        partnerDealerId: dealerSelectors.getDealerId(state), // same as `dealerId` above
        partnerId: 'MMD', // partnerId in our case is MMD
        // useAliasSettings: true, // i do not know if we need to send this
        lenders: selectedProgram?.lenderProgram != null ? [selectedProgram.lenderProgram.lenderId] : [], // this is key - we need to use the lenderCode from the current program
        // includeDealerCash: true, // not needed
        // includePercentOffIncentives: selectedProgram?.psRawData?.includePercentOffIncentives, // no idea - start with not passing it, confirm with PO?
        traceId: nanoid() // let's generate one with nanoid or uuid - whichever DC has
    };
};
