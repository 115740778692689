// externals

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { compositeSelectors } from '@makemydeal/dr-dash-store';
import type { DealHistory } from '@makemydeal/dr-dash-types';

// consts
import { CAPITALIZED_TAXES } from '../../constants';

// hooks
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

// components
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';

export type TotalTaxProps = {
    deal?: DealHistory;
    useWrapper?: boolean;
    hasDelta?: boolean;
};

const LeaseTotalTax = ({ deal, hasDelta = false }: TotalTaxProps) => {
    const useDealRootSelector = createDealRootSelector(deal);
    const totalTax = useDealRootSelector(compositeSelectors.getCapitalizedTax);

    return <DealSummaryItemWrapper hasDelta={hasDelta} label={CAPITALIZED_TAXES} value={formatDollarsAndCents(totalTax)} />;
};

export default LeaseTotalTax;
