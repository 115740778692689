// libraries
import { TableBody as InterstateTableBody, TableRow } from '@interstate/components/SimpleTable';

type TableBodyProps<T> = {
    data: T[];
    renderRow: (row: T, rowIndex: number) => React.ReactNode;
};

export const TableBody = <T,>({ data, renderRow }: TableBodyProps<T>) => (
    <InterstateTableBody>
        {data.map((row, index) => (
            <TableRow key={`row-${index}`}>{renderRow(row, index)}</TableRow>
        ))}
    </InterstateTableBody>
);

export default TableBody;
