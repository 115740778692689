/**
 * This file exports selectors statically for other libs to consume without worrying about shopper activity configuration
 * Under the hood this is all that the menu activity does with its `init` process
 *
 * It's also safe to keep separate instances of the class because it's just a plain class
 */

import { Selectors } from '@makemydeal/dr-activities-tradein';
import { additionalSelectors } from './additionalSelectors';
import { TRADE_IN_COMPONENT } from './constants';

const tradeInSelectors = new Selectors(TRADE_IN_COMPONENT, additionalSelectors);

export const { getTradeInForOffer } = tradeInSelectors;

export const getTradeInSelectors = (): Selectors => tradeInSelectors;
