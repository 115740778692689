import React, { FC } from 'react';
import { StyledRebatesFlexView } from './RebatesLayoutViews.interstate.styles';
import { MobileRow } from './ViewRows.interstate';

interface ViewProps {
    data: any[];
}

export const MobileView: FC<ViewProps> = ({ data }) => {
    return (
        <StyledRebatesFlexView>
            <hr />
            {data.map((rebate, rx) => (
                <MobileRow key={rx} data={rebate} index={rx} />
            ))}
        </StyledRebatesFlexView>
    );
};
