// libraries
import { clientLogger } from '@makemydeal/dr-shared-ui-utils';

// TODO: DASH - define this type later- this is essentially "any" object without using the "any" keyword ;-)
export type ExternalTrackPayload = Record<string, unknown>;

export const relayExternalTrackPayload = (payload: ExternalTrackPayload) => {
    clientLogger.clientLogMessage(`relaying external track anaytics payload: ${JSON.stringify(payload)}`);
    // TODO: DASH - implement this when analytics work is done
    // if (_source) {
    //     try {
    //         _source.postMessage(encodeExternalTrackMessage(payload), _origin);
    //     } catch {
    //         queuePayload(payload);
    //     }
    // } else {
    //     queuePayload(payload);
    //     log('UNABLE TO SEND EXTERNAL TRACK ANALYTICS BECAUSE _source NOT SET - queuing payload');
    //     alwaysLog('SP unable to postMessage because _source undefined');
    // }
};
