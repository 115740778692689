export const getRoute = (route: string, baseRoute?: string): string => {
    return `${baseRoute || '/programs'}${route}`;
};

export const getAllRoutes = (baseRoute?: string): { [key: string]: string } => {
    // TODO: Add routes here
    return {
        root: getRoute('', baseRoute)
    };
};
