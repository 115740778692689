import styled from 'styled-components';
import { Card } from '@interstate/components/Card';

export const StyledTermsCard = styled(Card)`
    &.border-bottom-none {
        border-bottom: none;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
`;
