// local components
import { ChangeVehicleFooterInterstate } from '../changeVehicleFooter/ChangeVehicleFooter.interstate';
import { ChangeVehicleHeaderInterstate } from '../changeVehicleHeader/ChangeVehicleHeader.interstate';

// store
import SearchAndSelectVehicle from './SearchAndSelectVehicle';

export type ChangeVehicleInterstateProps = {
    applyMessage?: string;
    enableConfirmModal?: boolean;
    coOrgId?: string;
};

const ChangeVehicleInterstate = ({ applyMessage, enableConfirmModal, coOrgId }: ChangeVehicleInterstateProps) => {
    return (
        <div className="change-vehicle-container">
            <ChangeVehicleHeaderInterstate enableConfirmModal={enableConfirmModal} />
            <SearchAndSelectVehicle coOrgId={coOrgId} />
            <ChangeVehicleFooterInterstate applyMessage={applyMessage} enableConfirmModal={enableConfirmModal} />
        </div>
    );
};

export default ChangeVehicleInterstate;
