// libraries
import type { InitConfigurationStaticAssetsState } from '@makemydeal/dr-dash-types';

export const getThemeModule = (staticAssets: InitConfigurationStaticAssetsState | undefined) => {
    const url = staticAssets?.legacyThemeJson;
    if (!url) {
        return Promise.reject('legacyThemesJson has not been provided in config');
    }

    return fetch(url).then((response) => {
        if (!response.ok) {
            return Promise.reject('A non-200 response was received when pulling json theme file');
        }
        return response.json();
    });
};
