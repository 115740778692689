// consts/enums
import { DASH_DEBUG, DASH_DEBUG_SOS } from '../consts/apiConfigConsts';
import { getSessionValue } from '../offerSessionUtils/offerSessionUtils';

export type QueryParamStoreCachedItem = {
    rawValue: string | null | undefined;
    values: Set<string>;
};

export class QueryParamStore {
    public isSmartOfferSavedDebuggingEnabled = () => this.hasDebugQueryParamValue(DASH_DEBUG_SOS);

    private cachedSessionValues: Record<string, QueryParamStoreCachedItem> = {};
    private hasCachedSessionValue = (key: string): boolean => {
        const item = this.cachedSessionValues[key];
        return !!item;
    };
    private cacheQueryParamValue = (key: string, rawValue: string | null | undefined) => {
        this.cachedSessionValues[key] = {
            rawValue,
            values: rawValue ? new Set(rawValue.split(',')) : new Set([])
        };
    };
    private hasDebugQueryParamValue = (value: string) => {
        const key = DASH_DEBUG;
        if (!this.hasCachedSessionValue(key)) {
            this.cacheQueryParamValue(key, getSessionValue(key));
        }
        return this.cachedSessionValues[key].values.has(value);
    };
}

export const queryParamStore = new QueryParamStore();
