// externals
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BreakPoint, logNewRelicPageAction } from '@makemydeal/dr-activities-common';

// libraries
import { dealerSelectors, featureToggleSelectors, scopedStateSelectors, selectedOfferSelectors } from '@makemydeal/dr-shared-store';
import {
    deskingActionCreators,
    offerInfoSelectors,
    configSelectors,
    dealXgDetailsSelectors,
    appSelectors,
    offerActionCreators,
    printPdfActionCreator,
    offerReduxSelectors,
    vehicleSelectors,
    tradeInCardSelectors
} from '@makemydeal/dr-dash-store';

// components
import { Button } from '@interstate/components/Button';
import { Menu, MenuReferenceType } from '@interstate/components/Menu';
import { Snackbar } from '@interstate/components/Snackbar';

// styling
import { ChevronDownIcon } from '@interstate/components/Icons/ChevronDownIcon';
import { useMediaQuery } from 'react-responsive';
import { useCustomerUI } from '../customerUI/useCustomerUI';
import { ShareToSalesViewDialog } from '../ShareToSalesView/ShareToSalesViewDialog.interstate';
import { UpdateOfferDialogInterstate } from '../UpdateOfferDialog/UpdateOfferDialog.interstate';
import ReadyToSendDialogInterstate from '../readyToSendDialog/ReadyToSendDialog.interstate';
import { ShareToCustomerDialogInterstate } from '../ShareToCustomerDialog/ShareToCustomerDialog.interstate';
import { Action, DEAL_REVIEW_AND_SUBMISSION_DIALOG_HEADER, DEAL_REVIEW_AND_SUBMISSION_DIALOG_PROMPT } from '../constants';
import { PrintPdfLoadingModal } from '../printPdfLoadingModal/PrintPdfLoadingModal';

const actionCreatorMap = {
    [Action.CONTINUE_IN_DESKING]: deskingActionCreators.continueInDesking,
    [Action.SEND_TO_ECOMMERCE]: deskingActionCreators.sendToEcommerce
} as const;

const readyToSendDialogContentMap = {
    [Action.CONTINUE_IN_DESKING]: {
        dialogHeader: 'Ready to Continue in Desking',
        dialogPrompt: 'Clicking "Yes" will update the offer and open Connect Desking. Are you sure you want to continue?'
    },
    [Action.SEND_TO_ECOMMERCE]: {
        dialogHeader: 'Ready to Send to Ecommerce?',
        dialogPrompt: 'Clicking "Yes" will send you to Ecommerce. Are you sure you want to continue?'
    }
} as const;

enum ShareActionTypes {
    SHARE_TO_SHOW_ROOM = 'ShareToShowroom',
    GET_DEAL_LINK = 'GetDealLink',
    SHARE_TO_CUSTOMER = 'ShareToCustomer',
    UPDATE = 'UPDATE',
    PRINT = 'PRINT'
}

const ActionsInterstate = () => {
    const [showReadyToSendDialog, setShowReadyToSendDialog] = useState(false);
    const [showReadyToSendDialogSave, setShowReadyToSendDialogSave] = useState(false);
    const [successNotification, setSuccessNotification] = useState(false);
    const [currentAction, setCurrentAction] = useState<Action>(Action.CONTINUE_IN_DESKING);
    const actionsEnabled = useSelector(appSelectors.isLazyInitCompleteForSmartInit);
    const dealExchangeDealId = useSelector(selectedOfferSelectors.getSelectedOfferDealXgId);
    const initDealExchangeDealVersion = useSelector(selectedOfferSelectors.getSelectedOfferDealXgVersion);
    const latestDealExchangeVersion = useSelector(offerInfoSelectors.getDealXgVersion);
    const copyToDeskingURL = useSelector(configSelectors.getCopyToDeskingURL);
    const isEcommerceDeal = useSelector(dealXgDetailsSelectors.getIsEcommerceDeal);
    const redirectionLink = useSelector(dealXgDetailsSelectors.getRedirectionLink);
    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const [showShareToSalesViewDialog, setShowShareToSalesViewDialog] = useState(false);
    const [showShareToCustomerDialog, setShowShareToCustomerDialog] = useState(false);
    const [showGetDealLinkDialog, setShowGetDealLinkDialog] = useState(false);
    const [showUpdateOffer, setShowUpdateOffer] = useState(false);
    const [showShareDropdown, setShareDropdown] = useState<null | HTMLElement>(null);
    const openShareDropdown = Boolean(showShareDropdown);
    const crmIntegrationToggle = useSelector(dealerSelectors.getCrmIntegrationToggle);
    const { showCustomerCard } = useCustomerUI();
    const largerThanSM = useMediaQuery({ query: `(min-width: ${BreakPoint.SMALL})` });
    const isContinueToDeskingDisabled = useSelector(dealerSelectors.isContinueToDeskingDisabled);
    const isLatestPushToDmsPlusEnabled = useSelector(dealXgDetailsSelectors.getLatestPushToDmsPlus);
    const isCDL3InMVEnabled = useSelector(featureToggleSelectors.isCDL3EnabledInMV);
    const isEnableEcommerceDealWithILDP = useSelector(featureToggleSelectors.enableEcommerceDealWithILDP);
    const sessionId = useSelector(offerReduxSelectors.getSessionId);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);
    const dealXgVersion = useSelector(offerReduxSelectors.getDealXgVersion);
    const dealerId = useSelector(dealerSelectors.getDealerId);
    const vin = useSelector(vehicleSelectors.getVehicleVin);
    const creditDecisionEnabled = useSelector(offerReduxSelectors.getCreditDecisionEnabled);
    const includesTradeIn = useSelector(tradeInCardSelectors.getShouldIncludeTradeIn);
    const scopesOrder = useSelector(scopedStateSelectors.getScopesOrder);

    // istanbul ignore next # this is invoked WAY down the pipeline and out of scope here.
    const urlSelector = (state: any) => {
        const url = offerInfoSelectors.getShopperURL(state);
        return `\n${url}`;
    };
    const dispatch = useDispatch();

    const dispatchAction = useCallback(
        (action: Action) => {
            const actionCreator = actionCreatorMap[action];
            if (actionCreator) {
                dispatch(actionCreator());
            }
        },
        [dispatch]
    );

    const handleActionClick = useCallback(
        (action: Action) => {
            setCurrentAction(action);
            setShowReadyToSendDialog(true);
            dispatchAction(action);
        },
        [setCurrentAction, setShowReadyToSendDialog, dispatchAction]
    );

    // NOTE: Ignoring coverage on the next lines because these are dependent on already asserted code in sub-components tests
    // We ARE asserting coverage on actionable behaviors like: (handleSendToCustomerClick, handleContinueInDeskingClick)

    // istanbul ignore next
    const closeReadyToSendDialog = useCallback(() => {
        setShowReadyToSendDialog(false);
    }, [setShowReadyToSendDialog]);

    const handleContinueToDeskingSaved = useCallback(() => {
        closeReadyToSendDialog();
        window.open(copyToDeskingURL + dealExchangeDealId, '_blank');
    }, [closeReadyToSendDialog, copyToDeskingURL, dealExchangeDealId]);

    const handleSendToEcommerceSaved = useCallback(() => {
        closeReadyToSendDialog();
        const dealExchangeVersion = latestDealExchangeVersion || initDealExchangeDealVersion;
        window.location.assign(`${redirectionLink}/${dealExchangeDealId}/version/${dealExchangeVersion}`);
    }, [closeReadyToSendDialog, latestDealExchangeVersion, initDealExchangeDealVersion, redirectionLink, dealExchangeDealId]);

    const handleReadyToSendSaved = useCallback(() => {
        switch (currentAction) {
            case Action.CONTINUE_IN_DESKING:
                return handleContinueToDeskingSaved();
            case Action.SEND_TO_ECOMMERCE:
                return handleSendToEcommerceSaved();
        }
    }, [currentAction, handleContinueToDeskingSaved, handleSendToEcommerceSaved]);

    const readyToSendDialogHeader =
        currentAction === Action.SEND_TO_ECOMMERCE && isEnableEcommerceDealWithILDP
            ? DEAL_REVIEW_AND_SUBMISSION_DIALOG_HEADER
            : readyToSendDialogContentMap[currentAction].dialogHeader;
    const readyToSendDialogPrompt =
        currentAction === Action.SEND_TO_ECOMMERCE && isEnableEcommerceDealWithILDP
            ? DEAL_REVIEW_AND_SUBMISSION_DIALOG_PROMPT
            : readyToSendDialogContentMap[currentAction].dialogPrompt;

    // istanbul ignore next
    const handleShareButtonClick = useCallback(
        (e: any) => {
            setShareDropdown(e.currentTarget);
        },
        [setShareDropdown]
    );

    // istanbul ignore next
    const handleShareButtonClose = useCallback(() => setShareDropdown(null), [setShareDropdown]);

    const handleSaveRequest = useCallback(
        (actionType: ShareActionTypes) => {
            switch (actionType) {
                case ShareActionTypes.SHARE_TO_CUSTOMER:
                    dispatch(offerActionCreators.saveOffer());
                    handleShareButtonClose();
                    if (crmIntegrationToggle) {
                        showCustomerCard(true, { tab: 'inbox', messageContentSelector: urlSelector, waitForOfferSave: true });
                    } else {
                        setShowShareToCustomerDialog(true);
                    }
                    break;
                case ShareActionTypes.GET_DEAL_LINK:
                    dispatch(offerActionCreators.saveOffer());
                    setShowGetDealLinkDialog(true);
                    handleShareButtonClose();
                    break;
                case ShareActionTypes.SHARE_TO_SHOW_ROOM:
                    setShowShareToSalesViewDialog(true);
                    handleShareButtonClose();
                    break;
                case ShareActionTypes.UPDATE:
                    dispatch(offerActionCreators.saveOffer());
                    setShowUpdateOffer(true);
                    break;
                case ShareActionTypes.PRINT:
                    dispatch(printPdfActionCreator.printPdf());
                    handleShareButtonClose();
                    break;
            }
        },
        [dispatch, crmIntegrationToggle, handleShareButtonClose, showCustomerCard]
    );

    // NOTE: Setup menu options; Sales view will always show. Toggling behavior of share to customer and get deal link is
    // based on OCC toggle and CRM Integration settings.
    const options = useMemo(
        () => [
            {
                label: 'Share to Customer',
                value: ShareActionTypes.SHARE_TO_CUSTOMER,
                id: 'share-to-customer-option',
                onSelect: () => {
                    handleSaveRequest(ShareActionTypes.SHARE_TO_CUSTOMER);
                    if (isCDL3InMVEnabled) dispatch(deskingActionCreators.sendToCustomerClicked());
                }
            },
            {
                label: 'Share to Sales View',
                value: ShareActionTypes.SHARE_TO_SHOW_ROOM,
                id: 'share-to-showroom-option',
                onSelect: () => {
                    handleSaveRequest(ShareActionTypes.SHARE_TO_SHOW_ROOM);
                }
            },
            {
                label: 'Get Deal Link',
                value: ShareActionTypes.GET_DEAL_LINK,
                id: 'get-deal-link-option',
                divider: true,
                onSelect: () => {
                    handleSaveRequest(ShareActionTypes.GET_DEAL_LINK);
                }
            },
            {
                label: 'Print',
                value: ShareActionTypes.PRINT,
                id: 'print-option',
                onSelect: () => {
                    handleSaveRequest(ShareActionTypes.PRINT);
                }
            }
        ],
        [handleSaveRequest]
    );

    const logNRAction = useCallback(
        (name: string) => {
            logNewRelicPageAction(name, {
                sessionId,
                dealXgId,
                dealXgVersion,
                dealerId,
                vin,
                creditDecisionEnabled,
                includesTradeIn,
                dealsCount: scopesOrder.length
            });
        },
        [creditDecisionEnabled, dealXgId, dealXgVersion, dealerId, includesTradeIn, scopesOrder.length, sessionId, vin]
    );

    const confirmAndSaveRequest = useCallback(() => {
        if (isLatestPushToDmsPlusEnabled) {
            setShowReadyToSendDialogSave(true);
        } else {
            handleSaveRequest(ShareActionTypes.UPDATE);
        }
        logNRAction('MV:Multiscenario - Update deal');
    }, [isLatestPushToDmsPlusEnabled, setShowReadyToSendDialogSave, handleSaveRequest, logNRAction]);

    const handleSave = useCallback(() => {
        setShowReadyToSendDialogSave(false);
    }, [setShowReadyToSendDialogSave]);

    useEffect(() => {
        // istanbul ignore next
        if (!showUpdateOffer) return;

        // istanbul ignore next
        if (offerIsBeingSaved === false && offerHasBeenSaved === true) {
            setSuccessNotification(true);
            setShowUpdateOffer(false);
            return;
        }
    }, [offerIsBeingSaved, offerHasBeenSaved]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleContinueInDeskingClick = useCallback(() => handleActionClick(Action.CONTINUE_IN_DESKING), [handleActionClick]);
    const handleSendToEcommerceClick = useCallback(() => handleActionClick(Action.SEND_TO_ECOMMERCE), [handleActionClick]);

    const setShowReadyToSendDialogSaveOnNoOrHide = useCallback(
        () => setShowReadyToSendDialogSave(false),
        [setShowReadyToSendDialogSave]
    );

    // NOTE: next 3 callbacks have been extracted as a part of perf optimization and aren't covered by tests
    // istanbul ignore next
    const snackbarOnCloseCallback = useCallback(() => setSuccessNotification(false), [setSuccessNotification]);
    // istanbul ignore next
    const updateOfferDialogOnHideCallback = useCallback(() => setShowUpdateOffer(false), [setShowUpdateOffer]);
    // istanbul ignore next
    const shareToCustomeDialogOnHide = useCallback(() => setShowGetDealLinkDialog(false), [setShowGetDealLinkDialog]);
    const shareToCustomerDialogOnHide = useCallback(() => setShowShareToCustomerDialog(false), [setShowShareToCustomerDialog]);
    const shareToSalesViewOnHideCallback = useCallback(() => setShowShareToSalesViewDialog(false), [setShowShareToSalesViewDialog]);
    const shareToSalesViewOnOfferSaveHide = useCallback(() => {
        setSuccessNotification(true);
        setShowShareToSalesViewDialog(false);
    }, [setSuccessNotification, setShowShareToSalesViewDialog]);

    // istanbul ignore next
    return (
        <>
            <div className="desking-btn-container">
                {!isContinueToDeskingDisabled && (
                    <Button
                        data-testid="action-copy-to-desking"
                        className="continue-desking-button"
                        size="medium"
                        buttonStyle="tertiary"
                        disabled={!actionsEnabled}
                        sx={{ fontWeight: 400 }}
                        onClick={handleContinueInDeskingClick}
                    >
                        Continue in Desking
                    </Button>
                )}
                {isEcommerceDeal && (
                    <Button
                        data-testid="action-send-to-ecommerce"
                        size="medium"
                        disabled={!actionsEnabled}
                        buttonStyle="tertiary"
                        onClick={handleSendToEcommerceClick}
                    >
                        {isEnableEcommerceDealWithILDP ? 'Deal Review & Submission' : 'Send to Ecommerce'}
                    </Button>
                )}
            </div>
            <div className="share-container-buttons">
                <Button
                    endIcon={<ChevronDownIcon />}
                    buttonStyle="secondary"
                    disabled={!actionsEnabled}
                    className="share-dropdown-button"
                    data-testid="share-dropdown"
                    onClick={handleShareButtonClick}
                >
                    Share
                </Button>
                <Menu
                    referenceProps={useMemo(
                        () => ({
                            open: openShareDropdown,
                            anchorEl: showShareDropdown as MenuReferenceType,
                            onClose: handleShareButtonClose
                        }),
                        [openShareDropdown, showShareDropdown, handleShareButtonClose]
                    )}
                    position={'top-end'}
                    menuOptions={useMemo(
                        () =>
                            options.map((item) => ({
                                ...item
                            })),
                        [options]
                    )}
                    width={largerThanSM ? '185px' : '100%'}
                />
                <Button data-testid="update-offer-button" size="medium" disabled={!actionsEnabled} onClick={confirmAndSaveRequest}>
                    Update
                </Button>
            </div>

            <ShareToSalesViewDialog
                show={showShareToSalesViewDialog}
                onHide={shareToSalesViewOnHideCallback}
                onOfferSaveHide={shareToSalesViewOnOfferSaveHide}
            />
            <ShareToCustomerDialogInterstate
                prefix="share-to-customer"
                show={showShareToCustomerDialog}
                onHide={shareToCustomerDialogOnHide}
                header="Share To Customer"
                dialogPrompt="Choose how you would like to send this offer to the shopper"
            />
            <ShareToCustomerDialogInterstate
                prefix="get-deal-link"
                show={showGetDealLinkDialog}
                onHide={shareToCustomeDialogOnHide}
                header="Get Deal Link"
            />
            <UpdateOfferDialogInterstate show={showUpdateOffer} onHide={updateOfferDialogOnHideCallback} />

            <PrintPdfLoadingModal />
            <ReadyToSendDialogInterstate
                data-testid="ready-to-send-dialog"
                dialogHeader={readyToSendDialogHeader}
                dialogPrompt={readyToSendDialogPrompt}
                show={showReadyToSendDialog}
                onHide={closeReadyToSendDialog}
                onNo={closeReadyToSendDialog}
                onSaved={handleReadyToSendSaved}
                promptSx={{ padding: '0px' }}
                action={currentAction}
            />
            <Snackbar
                data-testid="update-offer-dialog-success"
                show={successNotification}
                message="We've updated your changes to this offer."
                position="bottom-center"
                onClose={snackbarOnCloseCallback}
                type="success"
            />
            {!showReadyToSendDialog && (
                <ReadyToSendDialogInterstate
                    data-testid="ready-to-send-save-dialog"
                    dialogHeader={'Warning'}
                    dialogPromptHeader={'Important Note:'}
                    dialogPrompt={`Pushing again will overwrite the existing deal in your DMS.`}
                    show={showReadyToSendDialogSave}
                    onHide={setShowReadyToSendDialogSaveOnNoOrHide}
                    onNo={setShowReadyToSendDialogSaveOnNoOrHide}
                    onSaved={handleSave}
                    promptSx={{
                        paddingLeft: '0',
                        b: {
                            paddingRight: '5px'
                        }
                    }}
                />
            )}
        </>
    );
};
export default ActionsInterstate;
