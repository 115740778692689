import { FormContext } from '../formManager/formContext';
import { TextInput } from '@interstate/components/TextInput';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormStockInputProps } from '../types';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';

const NOT_ALLOWED_CHARACTERS = /[^a-zA-Z0-9]/g;
const ERROR_MSG = "The Stock # you've entered is invalid. Please review your entry and try again.";
const MAX_LENGTH = 17;

const formatValue = (value: string) => value.replace(NOT_ALLOWED_CHARACTERS, '').toUpperCase();

export const FormStockInput = ({ name, label }: FormStockInputProps) => {
    const { changeHandler, form } = useContext(FormContext);
    const [inputValue, setInputValue] = useState((form.values[name] as string) || '');
    const formattedValue = useMemo(() => formatValue(inputValue), [inputValue]);

    useEffect(() => {
        if (inputValue === formattedValue) return;
        setInputValue(formattedValue);
    }, [inputValue, formattedValue]);

    const handleChange = (event: InterstateOnChangeEvent<string>) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        const newFormattedValue = formatValue(newValue);
        changeHandler(name, newFormattedValue);

        if (newFormattedValue.length > MAX_LENGTH) {
            form.error[name] = ERROR_MSG;
        } else {
            delete form.error[name];
        }
    };

    return (
        <TextInput
            value={inputValue}
            data-testid={'vehicle-input-' + name}
            label={label}
            maxLength={MAX_LENGTH}
            id={name}
            key={name}
            name={name}
            customErrorMessages={{ exceedsMaxLength: ERROR_MSG }}
            hasError={Boolean(form.error[name])}
            errorMessage={form.error[name]}
            onChange={handleChange}
            sx={{ mt: 1, pb: 0.5 }}
        />
    );
};
