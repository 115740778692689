import { PushToRouteOneStatus, type PushToRouteOneState, type StateTree } from '@makemydeal/dr-dash-types';

export const getPushToRouteOneStatus = (state: StateTree): PushToRouteOneState => {
    return (
        state.pushToRouteOne ||
        ({
            timestamp: '',
            dealXgVersion: '',
            bffResult: { message: '', ok: false },
            wsResult: { message: '', ok: false },
            status: PushToRouteOneStatus.Ready
        } as PushToRouteOneState)
    );
};
