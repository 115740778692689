import styled from 'styled-components';

import { BreakPoint } from '@makemydeal/dr-activities-common';

export const DealSummary = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DealSummaryContainer = styled.div`
    flex-direction: column;
    @media (min-width: ${BreakPoint.SMALL}) {
        gap: 1.5rem;
        display: flex;
        flex-direction: row;
    }
`;
export const DealData = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    @media (min-width: ${BreakPoint.SMALL}) {
        flex-direction: column;
        gap: 0;
        justify-content: end;
        align-items: end;
    }
`;
export const DealSummaryHeader = styled.div`
    margin-left: auto;
`;
export const DealSummaryLabel = styled.div`
    white-space: nowrap;
`;
export const DataContainer = styled.div`
    display: flex;
`;
