// externals
import styled, { css } from 'styled-components';

// libraries
import { Typography } from '@interstate/components/Typography';

export const TypographyRightAligned = styled(Typography)`
    text-align: right;
`;

export const TypographyNoTextTransform = styled(Typography)`
    text-transform: none !important;
`;

export const FeeCardContainer = styled.div`
    height: 100%;

    & .MuiAccordionDetails-root {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }
`;

export const StyledTableCell = styled.div<{
    $minWidth: number;
    $textAlign?: string;
    $paddingRight?: number;
    $justifyContent?: string;
    $isManualFee?: boolean;
}>`
    display: flex;
    justify-content: ${(props) => props.$justifyContent || 'flex-start'};
    min-width: ${(props) => props.$minWidth}rem;
    padding-right: ${(props) => props.$paddingRight || 0}rem;
    text-align: ${(props) => props.$textAlign || 'left'};
    position: relative;

    ${(props) =>
        props.$isManualFee &&
        css`
            &:before {
                content: '';
                position: absolute;
                display: block;
                left: -16px;
                top: -10px;
                height: 40px;
                width: 4px;
                background-color: #ebb000;
            }
        `}
`;

export const FeeNameWrapper = styled.div`
    width: 55%;
`;

export const TypoRightAligned = styled(Typography)`
    text-align: right;
`;

export const FeeCategoryContainer = styled.div`
    padding-bottom: 40px;
`;

export const FeeDeeDetailsContainer = styled.div`
    height: 100%;
    gap: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const LEASE_MIN_WIDTH = 2.125;
export const LEASE_NAME_MIN_WIDTH = 12;
export const FINANCE_MIN_WIDTH = 3.125;
export const FINANCE_NAME_MIN_WIDTH = 14;
