// types / constants
import { Options } from '@interstate/components/RadioButtonList';
import { Rate } from '@makemydeal/dr-platform-types';
import { DealerProduct, DraftDealProduct, ProductPlanDetails, RatedProductRates } from '@makemydeal/dr-dash-types';
import { NON_RATEABLE_PRODUCT } from '../constants';

// utils
import { roundToTwoDecimal } from '@makemydeal/dr-common-utils';
import { calculateAddOnsCost, calculateAddOnsPrice, mapDynamicSurchargesToProductAddOns } from './addOnsUtils';
import { EditProductPlanProps } from '../types';

export const getPlanDefaultRate = (rates: Rate[], planName: string): Rate => {
    const planRates = rates.filter((rate) => rate.productProviderPlan === planName);

    return planRates.find((rate) => rate.recommendedRateForPlan) || planRates[0];
};

export const compareProductPlansByKey =
    (key: keyof ProductPlanDetails) =>
    (planA: ProductPlanDetails, planB: ProductPlanDetails): number => {
        if (planA[key] < planB[key]) {
            return -1;
        }
        return planA[key] > planB[key] ? 1 : 0;
    };

export const sortProductPlans = (planA: ProductPlanDetails, planB: ProductPlanDetails): number =>
    compareProductPlansByKey('productProviderPlan')(planA, planB) || compareProductPlansByKey('productName')(planA, planB);

const isRepeatedPlanName = (plans: ProductPlanDetails[], planName: string, productCode: string): boolean => {
    return plans.some((plan) => plan.productProviderPlan === planName && productCode !== plan.productCode);
};

export const getPlanOptionsFromPlans = (plans: ProductPlanDetails[]): Options[] => {
    return plans.sort(sortProductPlans).map((planDetails: ProductPlanDetails): Options => {
        const { productProviderPlan, productName, productCode } = planDetails;

        const label = isRepeatedPlanName(plans, productProviderPlan, productCode)
            ? `${productProviderPlan} [${productName}]`
            : productProviderPlan;

        return {
            label,
            value: getProductPlanKey(planDetails)
        };
    });
};

export const getProductPlanKey = (productPlanProps: EditProductPlanProps): string => {
    const { productProviderPlan, productCode } = productPlanProps;

    return `${productProviderPlan}_${productCode}`;
};

export const findByPlanKey = (selectedPlanKey: string) => (plan: ProductPlanDetails) => getProductPlanKey(plan) === selectedPlanKey;

const getMinOrMaxOrUndefined = (
    num1: number | null | undefined,
    num2: number | null | undefined,
    getMax: boolean
): number | undefined => {
    if (num1 == null || num2 == null) {
        return num1 ?? num2 ?? undefined;
    }
    return getMax ? Math.max(num1, num2) : Math.min(num1, num2);
};

export const updateDealProductWithRates = (
    selectedRate: RatedProductRates,
    product: DraftDealProduct,
    { dealerSettings }: Partial<DealerProduct> = {}
): DraftDealProduct => {
    const {
        dynamicSurcharges = [],
        productCoverageLength,
        productCoverageMiles,
        productDeductible,
        deductibleApplyType: productDeductibleMethod,
        minPrice,
        maxPrice,
        productCost: rateInitialCost,
        productPrice: productInitialPrice,
        productProviderPlan
    } = selectedRate;

    let productInitialCost = rateInitialCost;
    if (selectedRate.responseId === NON_RATEABLE_PRODUCT && typeof productInitialCost !== 'number') {
        productInitialCost = productInitialPrice;
    }

    const minPriceAllowedByLender = getMinOrMaxOrUndefined(minPrice, dealerSettings?.minRetailPrice, true);
    const maxPriceAllowedByLender = getMinOrMaxOrUndefined(maxPrice, dealerSettings?.maxRetailPrice, false);

    const { selectedAddOns, unselectedAddOns } = mapDynamicSurchargesToProductAddOns(product, dynamicSurcharges);
    const addOnsCost = calculateAddOnsCost(selectedAddOns);
    const addOnsPrice = calculateAddOnsPrice(selectedAddOns);
    const productCost = productInitialCost + addOnsCost;
    const initialProductMarkupDollarAmount = selectedRate.productPrice + addOnsPrice - productCost;
    const productMarkupDollarAmount = product.productMarkup
        ? product.productMarkup.markupDollarAmount
        : initialProductMarkupDollarAmount;
    const isProductPriceACorrectNumber = typeof product.productPrice === 'number' && !Number.isNaN(product.productPrice);
    const productPrice = isProductPriceACorrectNumber ? product.productPrice : productInitialPrice;
    const productPriceBasedOnCost = productCost + (productMarkupDollarAmount ?? 0);
    const isProductCostACorrectNumber = typeof productCost === 'number' && !Number.isNaN(productCost);
    const editedProduct = {
        ...product,
        productInitialCost,
        productPrice: isProductCostACorrectNumber ? roundToTwoDecimal(productPriceBasedOnCost) : productPrice,
        productCoverageLength,
        productCoverage: productProviderPlan,
        productProviderPlan: productProviderPlan,
        productDeductible,
        productDeductibleMethod,
        productCoverageMiles,
        minPriceAllowedByLender,
        maxPriceAllowedByLender,
        productAddOnDetails: {
            productAddOns: selectedAddOns,
            unselectedProductAddOns: unselectedAddOns,
            productAddOnSummary: {
                totalProductAddOnPrice: addOnsPrice,
                totalProductAddOnCost: addOnsCost
            }
        },
        ...(selectedRate.productServiceInterval && {
            productServiceInterval: selectedRate.productServiceInterval
        })
    };

    // NOTE: to handle non-rated product case (which has only product price)
    if (isProductCostACorrectNumber) {
        editedProduct.productCost = roundToTwoDecimal(productCost);
    }

    // NOTE: to handle non-rated product case (which has only product price)
    if (typeof productMarkupDollarAmount === 'number' && !Number.isNaN(productMarkupDollarAmount)) {
        editedProduct.productMarkup = {
            markupDollarAmount: roundToTwoDecimal(productMarkupDollarAmount),
            markupMethod: 'Dollar'
        };
    }

    return editedProduct;
};

export const getPriceInputErrorMessage = (product: DraftDealProduct = {}): string | undefined => {
    const minPriceError =
        product.minPriceAllowedByLender !== undefined &&
        product.productPrice !== undefined &&
        product.productPrice < product.minPriceAllowedByLender
            ? `Selling price can't be less than $${product.minPriceAllowedByLender}.`
            : undefined;

    const negativePriceError =
        product.productPrice !== undefined && product.productPrice < 0 ? `Selling price can't be negative.` : undefined;

    const maxPriceError =
        product.maxPriceAllowedByLender !== undefined &&
        product.productPrice !== undefined &&
        product.productPrice > product.maxPriceAllowedByLender
            ? `Selling price can't be more than $${product.maxPriceAllowedByLender}.`
            : undefined;

    return negativePriceError || minPriceError || maxPriceError;
};
