import { useSelector } from 'react-redux';

import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import ChangeVehicleInterstate from './changeVehicle/ChangeVehicle.interstate';
import { StyledChangeVehicleActivity } from './ChangeVehicleActivity.style';

const ChangeVehicleActivity = () => {
    const theme = useInterstateTheme();
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    return (
        <StyledChangeVehicleActivity theme={theme} isNewAppWidthEnabled={isABPencilEnabled}>
            <ChangeVehicleInterstate />
        </StyledChangeVehicleActivity>
    );
};

export default ChangeVehicleActivity;
