// libraries
import type { BridgeUserState, StateTree } from '@makemydeal/dr-dash-types';

export const getBridgeUserState = (state: StateTree): Partial<BridgeUserState> => {
    return state.bridgeUser || {};
};

export const getBridgeId = (state: StateTree): string => {
    // bridgePlatformId or solutionPrincipalId
    const bridgeUserState = getBridgeUserState(state);
    return bridgeUserState.id || '';
};

export const getBridgeUsername = (state: StateTree): string => {
    const bridgeUserState = getBridgeUserState(state);
    return bridgeUserState.username || '';
};

export const getBridgeFirstName = (state: StateTree): string => {
    // bridgePlatformId or solutionPrincipalId
    const bridgeUserState = getBridgeUserState(state);
    return bridgeUserState.givenName || '';
};

export const getBridgeLastName = (state: StateTree): string => {
    // bridgePlatformId or solutionPrincipalId
    const bridgeUserState = getBridgeUserState(state);
    return bridgeUserState.familyName || '';
};

/**
 * Returns the users given name and family name if they exist.
 */
export const getFullName = (state: StateTree): string => {
    const bridgeUserState = getBridgeUserState(state);
    let name = '';
    if (bridgeUserState.givenName != null) name += bridgeUserState.givenName;
    if (bridgeUserState.familyName != null) {
        if (name.length) name += ' ';
        name += bridgeUserState.familyName;
    }
    return name;
};
