import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Table, TableColumn } from '@interstate/components/Table';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { offerReduxSelectors, offerSelectors, transformIncentiveAmount } from '@makemydeal/dr-dash-store';

import { MobileView as InterstateMobileView } from './RebatesLayoutViews.interstate';
import { RebatesTotals } from './RebatesTotals.interstate';
import { getIncentiveDisplayType } from './utils';
import { ContainerGrid, ContainerGridItem } from '@makemydeal/dr-dash-components';

export const RebatesView: FC<any> = () => {
    const incentives = useSelector(offerReduxSelectors.getAppliedIncentives);
    const dealerCashTotal = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForDealerCash);
    const currentIncentivesTotal = useSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);
    const term = useSelector(offerSelectors.getSelectedTermMonths);

    if (!incentives?.length) return <span data-testid="manual-rebates-no-data">No rebates added.</span>;

    const displayFriendlyIncentivesData = incentives.map((incentive) => {
        return {
            program: incentive.program,
            name: incentive.name,
            code: incentive.code,
            type: getIncentiveDisplayType(incentive),
            amount: formatDollarsAndCents(transformIncentiveAmount(term, incentive))
        };
    });

    const columns: TableColumn[] = [
        {
            title: 'Program #',
            dataIndex: 'program'
        },
        {
            title: 'Incentive Name',
            dataIndex: 'name'
        },
        {
            title: 'Code',
            dataIndex: 'code'
        },
        {
            title: 'Type',
            dataIndex: 'type'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            align: 'right'
        }
    ];

    return (
        <ContainerGrid $mdBreakpoint={900}>
            <ContainerGridItem xs={12} md="hidden">
                <InterstateMobileView data={incentives} />
            </ContainerGridItem>
            <ContainerGridItem xs="hidden" md={12}>
                <Table
                    data-testid="incentives-table"
                    dataDensity="small"
                    columns={columns}
                    data={displayFriendlyIncentivesData}
                    tableLayout="auto"
                    scrollX={640}
                />
            </ContainerGridItem>

            <ContainerGridItem xs={12}>
                <RebatesTotals
                    currentAppliedIncentiveTotal={currentIncentivesTotal}
                    showEditTotals={true}
                    dealerCashTotal={dealerCashTotal}
                    currentEditedIncentivesTotal={currentIncentivesTotal}
                    showRebatesTotal={false}
                />
            </ContainerGridItem>
        </ContainerGrid>
    );
};
