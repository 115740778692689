import { Grid } from '@interstate/components/Grid';
import styled from 'styled-components';

export const StyledColumnsContainer = styled(Grid)`
    .offer-compare-column-container {
        margin: 0;
    }

    & div.offer-compare-column {
        width: 432px;
    }
`;
