// externals
import React, { useMemo, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// selectors
import { offerReduxSelectors, configSelectors } from '@makemydeal/dr-dash-store';
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import './DigitalDealJacket.scss';
import { DIGITAL_DEAL_JACKET_PARTNER_ID, DIGITAL_DEAL_JACKET_SOURCE, SCRIPT_FAILURE } from '../constants';
import { HostEnvironment, hostUrlHelper, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';
import { logNewRelic, NrSpaApiEnum } from '@makemydeal/dr-activities-common';

const DigitalDealJacket = () => {
    //states
    const [error, setError] = useState(false);
    const iframeRef = useRef(null);

    const dealxgId = useSelector(offerReduxSelectors.getDealXgId);
    const dealerId = useSelector(dealerSelectors.getDealerId);
    const redirectBaseUrl = `https://${window.location.hostname}`;
    const sourceUrl = useSelector(configSelectors.getDDJSourceUrl);
    const environment = hostUrlHelper.getHostUrlEnvironment(MANAGER_VIEW_APP_PREFIX, locHrefUtil.getLocHref());

    const ddjEnv = useMemo(() => {
        const environments = {
            [HostEnvironment.Dev]: 'dev',
            [HostEnvironment.Dev2]: 'dev',
            [HostEnvironment.Local]: 'dev',
            [HostEnvironment.QA]: 'uat',
            [HostEnvironment.DarkProd]: 'prod',
            [HostEnvironment.UAT]: 'uat',
            [HostEnvironment.Prod]: 'prod',
            [HostEnvironment.None]: '',
            [HostEnvironment.Unknown]: ''
        };
        return environments[environment];
    }, [environment]);

    // listen to message from iframe when script loads fail
    React.useEffect(() => {
        const handleError = (event: { data: string }) => {
            if (event.data === SCRIPT_FAILURE) {
                setError(true);

                // log an error message into New Relic
                logNewRelic('DDJ_Script_Load_Failure', 'Failure while loading Digital Deal Jacket script', NrSpaApiEnum.ERROR);
            }
        };

        window.addEventListener('message', handleError);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('message', handleError);
        };
    }, []);

    // load iframe with source
    useEffect(() => {
        if (iframeRef.current) {
            const iframe: HTMLIFrameElement = iframeRef.current;
            // istanbul ignore next
            const iframeDocument: Document | undefined = iframe.contentDocument || iframe.contentWindow?.document;

            // Clear existing content inside iframe
            if (iframeDocument) {
                iframeDocument.open();
                iframeDocument.write(`
                    <html>
                        <head></head>
                        <body>
                            <ddj-app
                                external-id='${dealxgId}'
                                source='${DIGITAL_DEAL_JACKET_SOURCE}'
                                env='${ddjEnv}'
                                redirect-url='${redirectBaseUrl}'
                                partner-id='${DIGITAL_DEAL_JACKET_PARTNER_ID}'
                                partner-dealer-id='${dealerId}'
                            >
                            </ddj-app>
                        </body>
                    </html>
                `);
                iframeDocument.close();

                // Inject the script dynamically
                const script = iframeDocument.createElement('script');
                script.type = 'text/javascript';
                script.id = 'ddj-pbc-url';
                script.src = sourceUrl;

                // Handling script failure
                script.onerror = function () {
                    // istanbul ignore next
                    for (const [, parent] of Object.entries(window?.parent)) {
                        parent?.postMessage?.(SCRIPT_FAILURE, '*');
                    }
                };

                // Append the script to the iframe
                iframeDocument.head.appendChild(script);
            }
        }
    }, [sourceUrl, dealxgId, ddjEnv, redirectBaseUrl, dealerId]);

    return (
        <div className="ddj-wrapper">
            {error && (
                <Alert type="caution" sx={{ marginTop: '40px' }} data-testid="ddj-alert">
                    <Typography variant="h6" sx={{ marginBottom: '2px' }}>
                        An Error has Occurred
                    </Typography>
                    <Typography variant="body-sm" sx={{ marginBottom: '2px' }}>
                        Sorry, the system is currently unable to process your request.
                    </Typography>
                    <Typography variant="body-sm">Please try again later.</Typography>
                </Alert>
            )}
            {!error && <iframe title="digital-deal-jacket-pbc" ref={iframeRef}></iframe>}
        </div>
    );
};

export default DigitalDealJacket;
