import { useSelector } from 'react-redux';

import { Button } from '@interstate/components/Button';
import { Grid } from '@interstate/components/Grid';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// styling
import { LenderDecisionFooterButtonContainer, LenderDecisionFooterContainer } from './CreditDecision.style';

type LenderDecisionFooterProps = {
    applySelectedCreditDecisionAndNavigate: () => void;
    hideFooter: () => void;
};
const LenderDecisionFooter = ({ applySelectedCreditDecisionAndNavigate, hideFooter }: LenderDecisionFooterProps) => {
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    return (
        <LenderDecisionFooterContainer>
            <LenderDecisionFooterButtonContainer
                data-testid="lender-decision-footer-container"
                isNewAppWidthEnabled={isABPencilEnabled}
            >
                <Grid
                    container
                    justifyContent="flex-end"
                    gap={1}
                    paddingX={4}
                    paddingY={2}
                    bgcolor="#FFF"
                    borderTop="1px solid #B2B2B2"
                >
                    <Button
                        size="medium"
                        id="btnLenderDecisionFooter"
                        data-testid="btn-lender-decision-footer-close"
                        buttonStyle="tertiary"
                        onClick={hideFooter}
                        className="btn-lender-decision-footer-close btn-link"
                    >
                        Cancel
                    </Button>
                    <Button
                        size="medium"
                        id="btnLenderDecisionFooter"
                        data-testid="btn-lender-decision-footer-select-credit-decision"
                        buttonStyle="primary"
                        onClick={applySelectedCreditDecisionAndNavigate}
                        className="btn-lender-decision-footer-select-credit-decision btn-link"
                    >
                        Select Credit Decision
                    </Button>
                </Grid>
            </LenderDecisionFooterButtonContainer>
        </LenderDecisionFooterContainer>
    );
};

export default LenderDecisionFooter;
