import axios from 'axios';
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import { DealLifecycle } from '@makemydeal/dr-dash-bff-types';
import { getCurrentAxiosRequestInterceptor } from '@makemydeal/dr-shared-network';

import {
    UPDATE_DEAL_LIFE_CYCLE_FAILURE,
    UPDATE_DEAL_LIFE_CYCLE_READY,
    UPDATE_DEAL_LIFE_CYCLE_REQUEST,
    UPDATE_DEAL_LIFE_CYCLE_SUCCESS
} from '../actionTypes/dealLifeCycleActionTypes';
import { selectedOfferSelectors } from '@makemydeal/dr-shared-store';

export const updateDealLifeCycleData = (appPrefix: string, dealLifeCycleData: any) => async (dispatch: any, getState: any) => {
    const locHref = locHrefUtil.getLocHref();
    const dealXgId = selectedOfferSelectors.getSelectedOfferDealXgId(getState());
    const axiosRequestInterceptor = getCurrentAxiosRequestInterceptor();
    const endpoint = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, `/deal/${dealXgId}/dealLifeCycle`).url;

    const { headers } = await axiosRequestInterceptor({
        url: endpoint
    });

    dispatch(updateDealLifeCycleRequest());

    try {
        const response = await axios.post(endpoint, dealLifeCycleData, {
            headers: {
                Authorization: headers.Authorization
            }
        });

        dispatch(updateDealLifeCycleSuccess(response.data));
    } catch (err) {
        //  eslint-disable-next-line no-console
        console.error(`Error calling dealLifeCycle dash bff endpoint: ${err}`);
        dispatch(updateDealLifeCycleFailure());
    }
};

export const updateDealLifeCycleReady = () => ({ type: UPDATE_DEAL_LIFE_CYCLE_READY });
export const updateDealLifeCycleRequest = () => ({ type: UPDATE_DEAL_LIFE_CYCLE_REQUEST });
export const updateDealLifeCycleSuccess = (payload: DealLifecycle) => ({ type: UPDATE_DEAL_LIFE_CYCLE_SUCCESS, payload });
export const updateDealLifeCycleFailure = () => ({ type: UPDATE_DEAL_LIFE_CYCLE_FAILURE });
