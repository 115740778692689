// libraries
import { NavigationItemStatus } from '@makemydeal/dr-activities-shared';

// interfaces/types
import type { IFlowConfig } from '../../flowMasterInterfaces';

// components
import DDJContainer from './components/DDJContainer';

// consts/enums
import { APP_NAME, ROUTE_ROOT } from './constants';

export const ddjRoute = {
    root: ROUTE_ROOT
};

// istanbul ignore next
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noopReducer = () => {};

/* this activity won't appear on the dr-activities-dashboard card list,
 * so including the bare minimum inputs to satisfy the interface
 */
export const ddjConfiguration: IFlowConfig<any> = {
    name: APP_NAME,
    navCard: {
        label: 'Digital Deal Jacket',
        icon: 'ddj',
        cardId: 'dr-sp-ddj'
    },
    showCardSelector: () => false,
    isCompleteSelector: () => false,
    routingComponent: DDJContainer,
    defaultRoute: ddjRoute.root,
    routes: [...Object.values(ddjRoute)],
    middleware: null,
    reducer: noopReducer, // DDJ doesn't have any of its own state
    isLocked: (_state: unknown) => false,
    dependsOnLeadFormCompletion: () => false,
    status: () => NavigationItemStatus.DEFAULT,
    isDisabled: () => false
};
