import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Action } from '@interstate/components/Action';
import { Typography } from '@interstate/components/Typography';
import { FlexGridCol, FlexRow } from '@makemydeal/dr-dash-components';
import { deskingGlobalActionCreators, deskingGlobalSelectors } from '@makemydeal/dr-dash-store';

import { LIENHOLDER_FORM_SHOW_LESS, LIENHOLDER_FORM_SHOW_MORE, LienholderSummaryControlIds } from './constants';
import { SummaryDataBox } from './SummaryDataBox';
import { LienholderStateData } from './types';
import { getSummaryData } from './utils';

export interface LienholderSummaryProps {
    minVisibleRows?: number;
    data: LienholderStateData;
}

const getSummaryValue = (value: string[] | string) => {
    if (Array.isArray(value)) {
        return value.map((item, i) => (
            <>
                {item}
                <br />
            </>
        ));
    }
    return value;
};

export const LienholderSummary = ({ data: lienholderInfo, minVisibleRows = 3 }: LienholderSummaryProps) => {
    const dispatch = useDispatch();

    const summaryData = useMemo(() => getSummaryData(lienholderInfo), [lienholderInfo]);

    const renderData = Object.entries(summaryData)
        .filter(([, { value }]) => !!value)
        .map(([label, { value, testId }]) => (
            <SummaryDataBox key={label} label={label} data-testid={testId}>
                {getSummaryValue(value)}
            </SummaryDataBox>
        ));

    const isShorten = renderData.length > minVisibleRows;

    const onShowMoreChange = () => {
        dispatch(deskingGlobalActionCreators.toggleLienholderAccordion());
    };

    const isAccordionOpen = useSelector(deskingGlobalSelectors.getLienholderAccordionExpanded);

    return (
        <>
            <FlexGridCol cols={2} smCols={3} alignItems="flex-start" data-testid={LienholderSummaryControlIds.Summary}>
                {isShorten ? (isAccordionOpen ? renderData : renderData.slice(0, minVisibleRows)) : renderData}
            </FlexGridCol>
            {isShorten && (
                <FlexRow>
                    <Action onClick={onShowMoreChange} data-testid={LienholderSummaryControlIds.ActionButtonShowMoreOrLess}>
                        <Typography variant="anchor-block-sm" color="base.color.blue.700">
                            {isAccordionOpen ? LIENHOLDER_FORM_SHOW_LESS : LIENHOLDER_FORM_SHOW_MORE}
                        </Typography>
                    </Action>
                </FlexRow>
            )}
        </>
    );
};

export default LienholderSummary;
