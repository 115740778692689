// interfaces/types
import type { SavedOfferPayloadObject } from '../types/comparisonUtilsTypes';

export class SavedOfferPayloadRegistry {
    private queuedPayloadIndex = 0;
    private queuedPayloads: Record<number, any> = {};
    // TODO: Drop this because it is no longer really used for anything
    private resetAllowed = true;
    queueOfferPayloadObject = (offerPayloadObject: SavedOfferPayloadObject): number => {
        this.queuedPayloadIndex++;
        this.queuedPayloads[this.queuedPayloadIndex] = offerPayloadObject;
        return this.queuedPayloadIndex;
    };
    dequeueOfferPayloadObject = (queuedPayloadIndex: number): SavedOfferPayloadObject => {
        const payload = this.queuedPayloads[queuedPayloadIndex];
        delete this.queuedPayloads[queuedPayloadIndex];
        return payload;
    };
    // TODO: Drop all of the functions below because they are no longer really used for anything
    isResetAllowed = (): boolean => this.resetAllowed;
    allowReset = () => {
        this.resetAllowed = true;
    };
    disallowReset = () => {
        this.resetAllowed = false;
    };
    resetState = () => {
        if (!this.resetAllowed) {
            throw new Error('resetState was called twice without allowReset between those calls');
        }
        this.disallowReset();
    };
}

export const savedOfferPayloadRegistry = new SavedOfferPayloadRegistry();
