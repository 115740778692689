import { actions } from '@makemydeal/dr-offer-redux';

const buildMeta = (analyticsName: string) => ({
    middleware: {
        payment: true,
        analytics: {
            eventName: analyticsName
        }
    }
});

/* NOTE: These overrides are intended to be used in place of the immediate/debounced actions found directly in offer-redux.
 * New, not previously used fields taking input will be added directly to dr-offer-redux.
 */

export const updateDownPayment = (downPayment: number) => ({
    type: actions.UPDATED_DOWN_PAYMENT_IMMEDIATE,
    payload: downPayment,
    meta: buildMeta('DR_DOWN_PAYMENT_CHANGED')
});

export const updateOfferPrice = (offerPrice: number) => ({
    type: actions.UPDATED_OFFER_PRICE_IMMEDIATE,
    payload: offerPrice,
    meta: buildMeta('DR_OFFER_PRICE_CHANGED')
});
