// externals
import { useInterstateToken } from '@interstate/components/InterstateThemeProvider';
import { Typography } from '@interstate/components/Typography';
import { Grid } from '@interstate/components/Grid';
import { ExclamationCircleIcon } from '@interstate/components/Icons';

export const ProviderPlanError = () => {
    const getToken = useInterstateToken();
    const redErrorColor = getToken('base.color.red.600');

    return (
        <Grid container spacing={0.5}>
            <Grid xs={'auto'} marginTop={'0.2rem'}>
                <ExclamationCircleIcon fontSize={'1rem'} data-testid="edit-product-plan-error-icon" color={redErrorColor} />
            </Grid>
            <Grid xs={11}>
                <Typography variant="body-sm" color="base.color.red.600" data-testid="edit-product-plan-error">
                    The plan/coverage is not applicable anymore, please replace it with another valid value.
                </Typography>
            </Grid>
        </Grid>
    );
};
