import styled from 'styled-components';
import { BreakPoint, IconFactory } from '@makemydeal/dr-activities-common';

export const StyledIconFactory = styled(IconFactory)`
    svg {
        border-radius: 8px;
    }
`;

export const StyledHeaderVehicleImage = styled.img`
    display: none;

    @media (min-width: ${BreakPoint.PHONE}) {
        display: block;
        width: 8.4rem;
        height: 6.3rem;
        margin-right: 1rem;
    }
`;
