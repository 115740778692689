// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';
import type { Document } from '@makemydeal/dr-dash-bff-types';

export const getDocuments = (state: StateTree): Document[] => {
    return state.docUpload?.data?.documents || [];
};
export const getTotalDocCount = (state: StateTree): number => {
    return getDocuments(state).length;
};
export const isDocUploadCompleted = (state: StateTree): boolean => {
    return (
        getDocuments(state).length !== 0 && getDocuments(state).every((document: Document) => document.uploadStatus === 'Complete')
    );
};
export const getDashboardStatusText = (state: StateTree): string => {
    const docCount = getTotalDocCount(state);
    return `${docCount} document${docCount !== 1 ? 's' : ''} uploaded`;
};
