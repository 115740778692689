// libraries
import { StateTree, TestDriveState } from '@makemydeal/dr-dash-types';

// utils
import { dateUtils } from '@makemydeal/dr-common-utils';

export const getTestDrive = (state: StateTree) => {
    return state.testDrive || ({} as TestDriveState);
};

export const isTestDriveComplete = (state: StateTree) => {
    const testDrive = getTestDrive(state);
    return !!testDrive.day;
};

export const getDDPTestDriveStatusText = (state: StateTree) => {
    const testDrive = getTestDrive(state);
    const message = testDrive.day
        ? `Scheduled for ${testDrive.time} of ${dateUtils.formatAsMMDDYYYY(new Date(testDrive.day))}`
        : '';
    return message;
};
