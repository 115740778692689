import { Grid } from '@interstate/components/Grid';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { MAX_ACTIVITY_CONTAINER_WIDTH_NEW, MAX_ACTIVITY_CONTAINER_WIDTH_OLD, toRem } from '@makemydeal/dr-dash-components';
import styled from 'styled-components';

export const InterstateDealActivityContainer = styled(Grid)<{ $isABPencilEnabled?: boolean }>`
    max-width: ${toRem(352)};
    && {
        padding: 40px 16px;
    }

    @media (max-width: ${(props) =>
            props.$isABPencilEnabled ? MAX_ACTIVITY_CONTAINER_WIDTH_NEW : MAX_ACTIVITY_CONTAINER_WIDTH_OLD}) {
        && {
            display: none;
        }
    }
`;

export const DealWrapper = styled(Grid)`
    @media (min-width: ${BreakPoint.TABLET_PORTRAIT}) {
        max-width: 320px;
        margin-bottom: 16px;
    }
`;

export const DealDashboardContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const HeaderRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0 0 0;
`;

export const OfferCommentsWrapper = styled.div`
    display: block;
    margin: 16px 0 0 0;
`;
