// libraries
import type { CreditAppState, CreditDecision, StateTree } from '@makemydeal/dr-dash-types';

export const getCreditApp = (state: StateTree): CreditAppState => {
    return state.creditApp ?? state;
};
export const getCreditAppReferenceId = (state: StateTree): string => {
    return getCreditApp(state).creditAppReferenceId;
};
export const getAccelerateCreditAppId = (state: StateTree): string | undefined => {
    return getCreditApp(state).accelerateCreditAppId;
};
export const isCreditAppCompleted = (state: StateTree): boolean => {
    const isSubmitted = getCreditApp(state).status === 'Submitted';
    return !!isSubmitted;
};
export const getCreditAppSubmittedOn = (state: StateTree): number => {
    const creditAppSubmittedData = getCreditApp(state).submittedOn || 0;
    return creditAppSubmittedData;
};
export const getCreditAppCardLabel = (state: StateTree): string => 'Credit App Complete';
