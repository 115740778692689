import { createContext } from 'react';

export type ExpandableAreaContextType = {
    /**
     * Toggle expandable area by key.
     * @param expandableId Key to identify an individual area.
     * @param value Optional value to directly update state. If undefined, will toggle value
     * @returns
     */
    toggle: (expandableId?: string, value?: boolean) => void;
    /**
     * Returns True if area specified by key is expanded.
     * @param expandableId Identifier key (any key you want)
     * @returns True if expanded; false if collapsed.`
     */
    isExpanded: (expandableId?: string) => boolean;
};

export const ExpandableAreaContext = createContext<ExpandableAreaContextType | null>(null);
