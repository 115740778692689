export enum PushToDarwinStatus {
    Ready = 'ready',
    Sending = 'sending',
    Failure = 'failure',
    Ok = 'ok'
}

export type PushToDarwinState = {
    error?: PushToDarwinError;
    dealXgId?: string;
    darwinResponseData?: DarwinResponseData;
    status: PushToDarwinStatus;
    currentFlow?: string;
};

export type PushToDarwinError = {
    errorDetails?: string;
    statusCode: number;
};

export type DarwinResponseData = {
    url: string;
};
