import { ICreditDecisionLender } from '@makemydeal/dr-platform-types';

export const isCreditDecisionLender = (lender: ICreditDecisionLender | Record<PropertyKey, never> | undefined) => {
    const name = lender?.name;
    const id = lender?.id;
    if (name && id) {
        return true;
    }
    return false;
};
