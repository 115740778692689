// externals
import { useSelector } from 'react-redux';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealHistory, StateTree } from '@makemydeal/dr-dash-types';
import { dealEffectiveSelectors } from '@makemydeal/dr-dash-store';

// consts
import * as constants from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

const SellingPrice = ({ deal, hasDelta = false }: { deal?: DealHistory; hasDelta?: boolean }) => {
    const offerPrice = useSelector((state: StateTree) => dealEffectiveSelectors.getEffectiveSellingPrice(state, deal));
    const sellingPrice = formatDollarsAndCents(offerPrice);

    return <DealSummaryItem label={constants.SELLING_PRICE} value={sellingPrice} hasDelta={hasDelta} />;
};

export default SellingPrice;
