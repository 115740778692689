// libraries
import {
    accessoriesSelectors,
    compositeSelectors,
    offerReduxSelectors,
    offerSelectors,
    vehicleProtectionSelectors,
    vehicleProtectionUtils
} from '@makemydeal/dr-dash-store';
import type { DealState } from '@makemydeal/dr-dash-types';

// components
import { Grid } from '@interstate/components/Grid';
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';
import LineItemWithChildren from '../common/LineItemWithChildren';

// constants
import * as constants from '../../constants';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// hooks
import { useMemo } from 'react';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';
import { LineItem } from '../../types/LineItemTypes';
import { convertAccessoryToLineItem, convertGovernmentFeeToLineItem, convertVppProductToLineItem } from '../utils/transform';

export type TotalDueAtSigningProps = DealState & {
    useWrapper?: boolean;
    showLargeValue?: boolean;
    hasDelta?: boolean;
};

const TotalDueAtSigning = ({ deal, useWrapper = true, showLargeValue, hasDelta = false }: TotalDueAtSigningProps) => {
    const useDealRootSelector = createDealRootSelector(deal);

    const totalAmountDue = useDealRootSelector(offerReduxSelectors.getDueAtSigningFromDueInAdvance);
    const monthlyPayment = useDealRootSelector(offerSelectors.getMonthlyPayment);

    const upfrontTaxes = useDealRootSelector(offerReduxSelectors.getUpfrontTaxTotal);

    const secDeposit = useDealRootSelector(offerSelectors.getSecurityDepositOverrideWithFallback);

    const acqUpfront = useDealRootSelector((state) => offerSelectors.getAcqFeeUpFrontOverrideWithFallback(state));
    const acqFeeOverride = useDealRootSelector((state) => offerSelectors.getAcqFeeOverrideWithFallback(state));
    const acqFee = acqUpfront ? acqFeeOverride : 0;

    const upfrontGovFeesTotal = useDealRootSelector(offerReduxSelectors.getGovernmentUpfrontFeeTotal);

    const upfrontGovFees = useDealRootSelector(compositeSelectors.getGovernmentFeesUpFront);
    const govFeeChildren: LineItem[] = upfrontGovFees.map(convertGovernmentFeeToLineItem);

    const upfrontDealerFees = useDealRootSelector((state) => compositeSelectors.getDealerFeesTotal(state, false));

    const capCostReductionTax = useDealRootSelector(offerReduxSelectors.getCapCostReductionTax);
    const advanceFlatTax = useDealRootSelector(compositeSelectors.getUpfrontAdvanceFlatTax);

    const rebateTotal = useDealRootSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);

    const upfrontProtectionProductsTotal = useDealRootSelector(vehicleProtectionSelectors.getUpfrontVppTotal);
    const vppProducts = useDealRootSelector(vehicleProtectionSelectors.getVppProducts);
    const upfrontProtectionProductsItems: LineItem[] = useMemo(
        () =>
            vppProducts
                .filter(vehicleProtectionUtils.isVppProductSelected)
                .filter(vehicleProtectionUtils.isVppUpfrontProduct)
                .map(convertVppProductToLineItem),
        [vppProducts]
    );
    const accessoriesTotal = useDealRootSelector(accessoriesSelectors.getUpfrontAccessoriesTotal);
    const itemizedAccessories = useDealRootSelector(accessoriesSelectors.getUpfrontAccessoriesList).map(convertAccessoryToLineItem);

    return (
        <DealSummaryItemWrapper
            label={constants.TOTAL_DUE_AT_SIGNING}
            value={formatDollarsAndCents(totalAmountDue)}
            useWrapper={useWrapper}
            showLargeValue={showLargeValue}
            hasDelta={hasDelta}
        >
            <Grid>
                <LineItemWithChildren label={constants.MO_PAYMENT} value={formatDollarsAndCents(monthlyPayment)} />
                <LineItemWithChildren
                    label={constants.GOV_FEES}
                    value={formatDollarsAndCents(upfrontGovFeesTotal)}
                    childItems={govFeeChildren}
                />
                <LineItemWithChildren label={constants.DOC_FEES} value={formatDollarsAndCents(upfrontDealerFees)} />
                <LineItemWithChildren label={constants.ACQUISITION_FEE} value={formatDollarsAndCents(acqFee)} />
                <LineItemWithChildren
                    label={constants.ACCESSORIES}
                    value={formatDollarsAndCents(accessoriesTotal)}
                    childItems={itemizedAccessories}
                />
                <LineItemWithChildren
                    label={constants.PROTECTION}
                    value={formatDollarsAndCents(upfrontProtectionProductsTotal)}
                    childItems={upfrontProtectionProductsItems}
                />
                <LineItemWithChildren label={constants.SECURITY_DEPOSIT} value={formatDollarsAndCents(secDeposit)} />
                {/* From product: Only show CCRT if it has a value; most dealers don't pay CCRT */}
                <LineItemWithChildren label={constants.UPFRONT_TAXES} value={formatDollarsAndCents(upfrontTaxes)} />
                <LineItemWithChildren label={constants.CAP_COST_REDUCTION_TAX} value={formatDollarsAndCents(capCostReductionTax)} />
                <LineItemWithChildren label={constants.ADVANCE_FLAT_TAX} value={formatDollarsAndCents(advanceFlatTax)} />
                <LineItemWithChildren label={constants.REBATES} value={formatDollarsAndCents(rebateTotal)} />
            </Grid>
        </DealSummaryItemWrapper>
    );
};

export default TotalDueAtSigning;
