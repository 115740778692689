// libraries
import { RollbackState, StateTree } from '@makemydeal/dr-dash-types';
import type { FSA, SimpleFSA } from '@makemydeal/dr-platform-shared';

// consts/enums
import {
    PREPARE_SCOPE_STATE_COPY,
    PREPARE_STATE_COPY,
    ROLLBACK_APP_STATE_FOR_SWITCH_VEHICLE,
    ROLLBACK_SCOPE_STATE
} from '../actionTypes/rollbackActionTypes';

export type PrepareStateCopyAction = FSA<typeof PREPARE_STATE_COPY, StateTree>;
export type PrepareScopeStateCopyAction = SimpleFSA<typeof PREPARE_SCOPE_STATE_COPY>;
export type RollbackStateAction = Required<FSA<typeof ROLLBACK_APP_STATE_FOR_SWITCH_VEHICLE, RollbackState>>;
export type RollbackScopeStatePayload = { persistData: string[] };
export type RollbackScopeStateAction = Required<FSA<typeof ROLLBACK_SCOPE_STATE, RollbackScopeStatePayload>>;
export type AllRollbackActions = PrepareStateCopyAction | RollbackStateAction;

export const prepareStateCopy = (appState: StateTree): PrepareStateCopyAction => {
    return {
        type: PREPARE_STATE_COPY,
        payload: appState
    };
};

export const prepareScopeStateCopy = (): PrepareScopeStateCopyAction => {
    return {
        type: PREPARE_SCOPE_STATE_COPY
    };
};

export const rollbackAppStateForSwitchVehicle = (rollbackState: RollbackState): RollbackStateAction => {
    return {
        type: ROLLBACK_APP_STATE_FOR_SWITCH_VEHICLE,
        payload: rollbackState
    };
};

export const rollbackScopeStateForSwitchVehicle = (): RollbackScopeStateAction => {
    return {
        type: ROLLBACK_SCOPE_STATE,
        payload: {
            persistData: ['changeVehicle']
        }
    };
};
