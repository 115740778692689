// components
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';
import { Alert } from '@interstate/components/Alert';
import { Grid } from '@interstate/components/Grid';
import { DEALER_SUPPROT_REQUEST_FORM } from '../constants';

export const ErrorModalDialogContent = () => {
    // istanbul ignore next
    const openSupportTicketUrl = () => {
        window.open(DEALER_SUPPROT_REQUEST_FORM);
    };

    return (
        <>
            <Alert type="error" sx={{ margin: '24px 0' }}>
                <Typography variant="body-sm" color="sem.color.on-surface.default" tag="span">
                    The changes you made to this offer failed to update. Please try again.
                </Typography>
            </Alert>
            <Typography variant="body-sm" color="sem.color.on-surface.default" tag="p">
                We ran into a problem updating this offer so the information wasn't shared. Please try again.
            </Typography>
            <Grid sx={{ margin: '24px 0' }}>
                <Typography variant="strong-md" color="sem.color.on-surface.default" tag="span">
                    If the problem persists, please contact support:{' '}
                </Typography>
                <Typography variant="body-sm" color="sem.color.on-surface.default" tag="span">
                    1-888-895-2994 or create a
                </Typography>
                <Action onClick={openSupportTicketUrl} data-testid="support-ticket" sx={{ marginLeft: '4px' }}>
                    <Typography variant="anchor-inline-md" color="sem.color.surface.button.primary.rest">
                        support ticket
                    </Typography>
                </Action>
            </Grid>
        </>
    );
};
