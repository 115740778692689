import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const CreditBureauContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 1rem;
`;

export const LenderDecisionContainer = styled.div`
    background-color: white;
    justify-content: center;
    margin-top: 1.25rem;
    width: 100%;
`;

export const CreditAndFinanceWrapper = styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
`;

export const CreditAndFinanceContainer = styled.div`
    display: flex;
    gap: 1.5rem;
    align-items: baseline;

    @media only screen and (max-width: ${BreakPoint.SMALL}) {
        justify-content: space-between;
        width: 100%;
    }
`;

export const IconAndWidgetWrapper = styled.div`
    margin-bottom: 1rem;
    position: relative;
    font-size: 1.1rem;
`;

export const DeepLinkDealertrackContainer = styled.div`
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
`;

export const CreditAndFinanceTitle = styled.h1`
    text-align: initial !important;
    font-size: 28px !important;
    line-height: 115%;
    letter-spacing: -0.28px;
    font-weight: 700;
    margin: 0 !important;
    padding: 1rem 0.6rem 0 0.6rem;
`;

export const DeepLinkDealertrack = styled.a`
    display: flex;
    align-items: baseline;
    gap: 0.3rem;
    margin-top: 1rem;

    .openDealertrackIcon {
        width: 1rem;
        height: 1rem;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-right: 2.8rem;
`;
