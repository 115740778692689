import { AccessoryElement } from '@makemydeal/dr-platform-types';

type SendPartSitesInitMessage = {
    event: React.SyntheticEvent<HTMLIFrameElement, Event>;
    term: number | undefined;
    apr: number | undefined;
    submitButtonText: string | undefined;
    selectedCatalogAccessories: AccessoryElement[];
    selectedManualAccessories: AccessoryElement[];
};

const IFRAME_PING_TIMEOUT = 2.5 * 1000;

export const sendPartSitesInitMessage = ({
    event,
    term,
    apr,
    submitButtonText,
    selectedCatalogAccessories,
    selectedManualAccessories
}: SendPartSitesInitMessage) => {
    const iframeWindow = event.currentTarget.contentWindow;

    if (iframeWindow) {
        const selectedCatalogAccessoryCodes = selectedCatalogAccessories.map((accessory) => accessory.code);

        // App is allowing accessory.code to be empty and non-unique. This can be a problem during removal process.
        // So for manual accessories, use manualAccessoryId as part_number temprorarily for removal process from catalog iframe.
        const selectedManualAccessoriesPayload = selectedManualAccessories.map((accessory) => ({
            part_number: accessory.manualAccessoryId,
            name: accessory.name,
            price: accessory.initialPrice,
            install_price: accessory.installPrice,
            total_price: accessory.amount
        }));

        const message = {
            action: 'init',
            selectedAccessories: selectedCatalogAccessoryCodes,
            selectedManualAccessories: selectedManualAccessoriesPayload,
            term,
            apr,
            submitButtonText
        };

        iframeWindow.postMessage(message, '*');
    }
};
export const pingPartSites = async (
    event: React.SyntheticEvent<HTMLIFrameElement, Event>,
    partSitesOrigin: string
): Promise<void> => {
    const iframeWindow = event.currentTarget?.contentWindow;

    if (iframeWindow) {
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line prefer-const
            let timeoutId: NodeJS.Timeout;

            const handleMessage = (message: MessageEvent) => {
                if (message.origin === partSitesOrigin) {
                    // NOTE: any message means iframe is working as expected
                    clearTimeout(timeoutId);
                    window.removeEventListener('message', handleMessage);

                    resolve();
                }
            };

            window.addEventListener('message', handleMessage);

            timeoutId = setTimeout(() => {
                window.removeEventListener('message', handleMessage);

                reject('timeout');
            }, IFRAME_PING_TIMEOUT);

            iframeWindow.postMessage(
                {
                    action: 'get_accessories'
                },
                '*'
            );
        });
    }

    return Promise.reject('no content window found');
};
