import styled from 'styled-components';

export const TruncatedMessageContainer = styled.span`
    margin-left: 0.5rem;
`;

export const OfferCommentContainer = styled.div`
    display: flex;
    align-items: center;

    .chat-icon {
        width: 12px;
        height: 12px;
    }
`;

export const StyledTruncateText = styled.div`
    span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }
`;
