import styled from 'styled-components';
import { Box } from '@interstate/components/Box';

export const DealLifeCycleFooterContainer = styled(Box)`
    display: flex;
    width: 100%;
    border-top: 1px solid rgb(149, 149, 149);
    background-color: rgba(244, 244, 244, 0.96);
    padding: 8px 16px;
    justify-content: flex-end;
    position: absolute;
    bottom: 0px;
`;
