export enum SavedOfferPayloadObjectComparison {
    Same,
    Different
}

export enum SavedOfferPayloadItemComparison {
    Unmodified,
    Modified,
    SourceOnly,
    TargetOnly
}
