// externals
import { Tooltip } from '@interstate/components/Tooltip';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { ExclamationCircleIcon } from '@interstate/components/Icons';

// types
import { TableAlertInputProps } from '../../types';

const TableAlertError = ({ tableAlertInput }: TableAlertInputProps) => {
    const theme = useInterstateTheme();

    return (
        // The tooltip shifts to a wrong place on wide resolutions when position is one of "<position>-end" or "<position>-start"
        <Tooltip data-testid="table-alert-error-tooltip" size="large" toolTipContent={tableAlertInput.message} position="top">
            <ExclamationCircleIcon data-testid="table-alert-error-icon" color={theme.tokens.BaseColorRed500} />
        </Tooltip>
    );
};

export default TableAlertError;
