export const PROFIT_LOCATION_OPTIONS = [
    {
        label: 'Front End',
        value: 'Front'
    },
    {
        label: 'Back End',
        value: 'Back'
    }
];

export const UPFRONT_OPTIONS = [
    {
        label: 'Upfront',
        value: 'Upfront'
    },
    {
        label: 'Capitalized',
        value: 'Capitalized'
    }
];

export const NON_RATEABLE_PRODUCT = 'non-rateable';

export const UNLIMITED_MILEAGE_LABEL = 'Unlimited';

export const FETCHING_RATE_FIELD_MESSAGE = '...';
