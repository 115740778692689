// components
import DealAttributeCheckbox from './DealAttributeCheckbox';
import DealAttributeDatepicker from './DealAttributeDatepicker';
import DealAttributeDropdown from './DealAttributeDropdown';
import DealAttributeInformation from './DealAttributeInformation';

// types
import { DealAttributeItemProps, VppDealAttributeInputType } from '../../../types';

const inputTypeComponentMap: Record<VppDealAttributeInputType, (props: DealAttributeItemProps) => JSX.Element> = {
    [VppDealAttributeInputType.CHECKBOX]: DealAttributeCheckbox,
    [VppDealAttributeInputType.DATEPICKER]: DealAttributeDatepicker,
    [VppDealAttributeInputType.DROPDOWN]: DealAttributeDropdown,
    [VppDealAttributeInputType.INFO_TOP]: DealAttributeInformation,
    [VppDealAttributeInputType.INFO]: DealAttributeInformation
};

const DealAttributeItem = ({ dealAttributeInput, onChangeValue }: DealAttributeItemProps) => {
    const ItemComponent = inputTypeComponentMap[dealAttributeInput.inputType];

    return <ItemComponent dealAttributeInput={dealAttributeInput} onChangeValue={onChangeValue} />;
};

export default DealAttributeItem;
