export enum HostEnvironment {
    None,
    Unknown,
    Local,
    Dev,
    Dev2,
    QA,
    UAT,
    DarkProd,
    Prod
}
