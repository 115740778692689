import { useDispatch, useSelector } from 'react-redux';

import { CheckBox } from '@interstate/components/CheckBox';
import { Box } from '@interstate/components/Box';

import { kebabCase } from '@makemydeal/dr-common-utils';

import { offerActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';

const UPFRONT = 'UPFRONT';

const TotalTaxUpfront = () => {
    const dispatch = useDispatch();
    const taxOverrideUpfront = useSelector(offerReduxSelectors.getTaxOverrideUpfront);

    const onChange = (event: any) => {
        dispatch(offerActionCreators.updatedTotalTaxUpfrontOverride(!taxOverrideUpfront));
    };
    return (
        <Box display="flex" alignItems="center" justifyContent="center" margin="0 1rem" paddingTop="2rem">
            <CheckBox
                data-testid={kebabCase(UPFRONT)}
                id={kebabCase(UPFRONT)}
                label={'Upfront'}
                name={kebabCase(UPFRONT)}
                onChange={onChange}
                checked={taxOverrideUpfront}
                value="1"
            />
        </Box>
    );
};

export default TotalTaxUpfront;
