import { useEffect } from 'react';

/**
 * Scrolls an element into view based on one of two conditions:
 * 1. formOpenOrUniqueScrollRequestId is switched from false to true.
 * 2. formOpenOrUniqueScrollRequestId has a new string value that differs from previous value (for example, a timestamp string).
 * @param ref object to scroll into view
 * @param formOpenOrUniqueScrollRequestId true/false OR some unique identifier that will trigger scroll into view when changed
 */
export const useScrollIntoView = (ref: React.RefObject<HTMLDivElement>, formOpenOrUniqueScrollRequestId: boolean | string) => {
    useEffect(() => {
        if (formOpenOrUniqueScrollRequestId && ref?.current?.scrollIntoView) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [formOpenOrUniqueScrollRequestId]);
};
