/**
 * PURPOSE: Dealer App host logic for determining whether offer redux should revert a payment for the specified action and offer
 *   type.
 */

// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { OfferType } from '@makemydeal/dr-platform-types';
import type { StateTree } from '@makemydeal/dr-dash-types';

export const shouldRevertPaymentOnFailure = (state: StateTree, action: AnyFSA, offerType: OfferType): boolean => false;
