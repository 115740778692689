import { FC } from 'react';
import { Label } from './Label';
import { ColumnMapping } from './columnMappings';

export const LabelRow: FC<{ columns: ColumnMapping[]; index: number }> = ({ columns, index }) => (
    <>
        {columns.map(({ label, required, fieldName }, lx) => (
            <Label
                label={label}
                required={required}
                key={`label-${index}-${fieldName}`}
                data-testid={`label-${index}-${fieldName}`}
            />
        ))}
    </>
);
