import React from 'react';

const FniCreditDecisionWidget: React.FC<FniCreditDecisionWrapper> = ({
    dealRefId: dealRefId,
    selectedLenderId: selectedLenderId,
    environment: environment,
    routeOnePartyId: routeOnePartyId,
    creditProviderIsRouteOne: creditProviderIsRouteOne
}) => {
    return (
        <fni-credit-decision-widget
            id="cd-widget-app"
            deal-ref-id={dealRefId}
            lender-id={selectedLenderId}
            env={environment}
            partner_dealer_id={routeOnePartyId}
            partner_id={'MMD'}
            is_route_one={creditProviderIsRouteOne ? 'true' : 'false'}
            data-testid="fni-credit-decision-widget"
        />
    );
};

export default FniCreditDecisionWidget;
