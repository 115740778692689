import { FormContext } from '../formManager/formContext';
import { TextInput } from '@interstate/components/TextInput';
import { useContext, useState } from 'react';
import { odometerValidator } from './helper';
import { FormTextInputProps } from '../types';
import { isBlurEvent } from '../../utils/eventUtils';

export const FormTextInput = ({ name, label, inputSuffix, masking, sx }: FormTextInputProps) => {
    const context = useContext(FormContext);
    const refresh = useState(0)[1];

    return (
        <TextInput
            value={context.form.values[name] as string}
            data-testid={'vehicle-input-' + name}
            label={label}
            id={name}
            key={name}
            name={name}
            inputSuffix={inputSuffix}
            hasError={Boolean(context.form.error[name])}
            errorMessage={context.form.error[name]}
            onChange={(ev) => {
                if (isBlurEvent(ev)) {
                    return;
                }
                context.changeHandler(name, ev.target.value as string);
                refresh(Date.now());
            }}
            onBlur={() => {
                context.changeHandler(name, odometerValidator(context.form.values[name] as string));
                refresh(Date.now());
            }}
            masking={masking}
            sx={sx}
        />
    );
};
