import { KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as offerActionCreators } from '@makemydeal/dr-offer-redux';
import { offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';
import { PriceInput } from '@interstate/components/PriceInput';
import { Spinner as LoadingIndicator } from '@interstate/components/Spinner';
import { PaymentGridRow, PaymentGridCellItem, FINANCE } from '@makemydeal/dr-platform-types';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

import { LoadingIndicatorWrapper, StyledGridItem, StyledTypography } from './styled.components';
import { roundValue } from './utils';
import { ENTER_KEY_CODE, getNewTarget } from '../common/utils';

const maxValue = 9999999;

const GridRow = ({ data }: { data: PaymentGridRow }) => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const dispatch = useDispatch();
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);
    const [downPayment, setDownPayment] = useState('');

    useEffect(() => {
        const roundedStringValue = data.val !== null && Number.isFinite(data.val) ? `${Math.round(data.val)}` : '';
        setDownPayment(roundedStringValue);
    }, [data]);

    const handleChange = (e: InterstateOnChangeEvent<TextInputEventValue>) => setDownPayment(e.target.value);

    const handleBlur = (e: InterstateOnChangeEvent<TextInputEventValue>) => {
        const value = roundValue(e.target.value);
        if (data.val === value) {
            setTimeout(() => setDownPayment(`${value ?? ''}`));
            return;
        }

        logNewRelicPageAction('MV:PaymentGrid - Change cash down', {
            mvOfferType: offerType,
            mvFieldEdited: 'mv:payment:grid:cash-down',
            mvDealXgId: dealXgId
        });

        dispatch(offerReduxActionCreators.updatePaymentGridCashdown(offerType, data.key, value));
    };

    const handleSelect = (item: PaymentGridCellItem) => () => {
        if (item.isEmpty) return;

        logNewRelicPageAction('MV:PaymentGrid - Change selected term', {
            mvOfferType: offerType,
            mvFieldEdited: 'mv:payment:grid:selected-term',
            mvDealXgId: dealXgId
        });

        dispatch(offerReduxActionCreators.updateDownPaymentImmediate(data.val));
        dispatch(offerReduxActionCreators.selectedTerm(item.id, { payment: false }));
        dispatch(offerActionCreators.paymentGridFetchVppProducts());
    };

    const handleKeyDownEvent = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === ENTER_KEY_CODE) {
            const newTarget = getNewTarget(event);
            handleBlur(newTarget);
        }
    };

    return (
        <>
            <StyledGridItem xs={3} className={'payment-grid-item'} maxHeight="70px">
                <PriceInput
                    autoInsertCommas
                    allowNegative={false}
                    id={'grid-cashdown-input'}
                    data-testid={'grid-cashdown-input'}
                    maxValue={maxValue}
                    allowDecimal={true}
                    decimalMaxLength={2}
                    name={'cashdown-input'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDownEvent}
                    value={downPayment}
                />
            </StyledGridItem>
            {data &&
                data.items.map((item: PaymentGridCellItem, index) => (
                    <StyledGridItem
                        xs={3}
                        className={'payment-grid-item'}
                        key={index}
                        maxHeight="70px"
                        alignItems={item.isLoading ? 'center' : 'start'}
                        paddingLeft="1%"
                        data-testid={`grid-item-row-${item.id}`}
                        onClick={handleSelect(item)}
                        isSelected={item.isSelected && !item.isLoading}
                        canSelect={!item.isEmpty && !item.isLoading && !item.isSelected}
                    >
                        {item.isLoading && (
                            <LoadingIndicatorWrapper>
                                <LoadingIndicator data-testid="loading-spinner-terms-col" />
                            </LoadingIndicatorWrapper>
                        )}
                        {!item.isLoading && !item.isEmpty && (
                            <>
                                {offerType !== FINANCE && (
                                    <StyledTypography data-testid="monthly-payment-lender-code">{item.lenderCode}</StyledTypography>
                                )}
                                <StyledTypography className="large" data-testid="monthly-payment-title">
                                    {formatDollarsAndCents(item.monthlyPayment)}
                                </StyledTypography>
                            </>
                        )}
                    </StyledGridItem>
                ))}
        </>
    );
};

export default GridRow;
