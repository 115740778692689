// externals
import { Reducer } from 'redux';

// libraries
import { FeatureToggles } from '@makemydeal/dr-platform-shared';
import { ISharedNavFlowConfig as SharedNavFlowConfig } from '@makemydeal/dr-activities-shared';
import type { StateTree } from '@makemydeal/dr-dash-types';
import type { ServiceStatus } from '@makemydeal/dr-shared-store';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface INavFlowConfig extends SharedNavFlowConfig<StateTree, FeatureToggles, ServiceStatus> {}

export interface IFlowConfig<T> extends INavFlowConfig {
    // TODO: DASH - fix this

    routingComponent: any;
    // TODO: DASH - fix this

    middleware: any;
    reducer: Reducer<T>;
    // TODO: DASH - fix this
    // eslint-disable-next-line @typescript-eslint/ban-types
    extensions?: object;
}

// NOTE: This is no longer exported directly- use getFlowConfigs() instead
// TODO: DASH - fix this

const flowConfigs: IFlowConfig<any>[] = [];

// TODO: DASH - fix this

export const getFlowConfigs = (): IFlowConfig<any>[] => flowConfigs;
/*
export interface IFlowConfigsIndex {
    
    [configName: string]: IFlowConfig<any>;
}

let flowConfigIndex: IFlowConfigsIndex | null = null;

export const clearFlowConfigIndexCache = () => {
    flowConfigIndex = null;
};

export const getFlowConfigsIndex = (): IFlowConfigsIndex => {
    if (!flowConfigIndex) {
        flowConfigIndex = {};
        getFlowConfigs().forEach((flowConfig) => {
            (flowConfigIndex as IFlowConfigsIndex)[flowConfig.name] = flowConfig;
        });
    }
    return flowConfigIndex;
};

export const getNavigationFlows = (): INavFlowConfig[] => {
    return getFlowConfigs().map((config) => {
        const navConfig = { ...config };
        delete navConfig.middleware;
        // TODO: DASH - fix this
        
        delete (navConfig as any).reducer;
        delete navConfig.routingComponent;
        return navConfig;
    });
};


export const registerFlow = (config: IFlowConfig<any>) => {
    if (enableDebugging()) {
        // eslint-disable-next-line no-console
        console.log(`Registered flowConfig for "${config?.name}"`);
    }
    getFlowConfigs().push(config);
    clearFlowConfigIndexCache();
};
*/

export const getCurrentFlowName = () => {
    // TODO: DASH - it appears that this is needed for Trade-In to report this information for analytics, so this will have to be
    //              completed when analytics for Dash is needed.
    return '("getCurrentFlowName" NOT IMPLEMENTED)';
    // const currentFlow = getFlowConfigs().find((flow) => flow.routes.indexOf(history.location.pathname) >= 0);
    // if (!currentFlow) return DASHBOARD_NAME.toUpperCase();
    // return currentFlow.name.toUpperCase();
};
