export const CLICKED_CONTINUE_IN_DESKING = 'CLICKED_CONTINUE_IN_DESKING';
export const CLICKED_CONTINUE_IN_UNIFI = 'CLICKED_CONTINUE_IN_UNIFI';
export const CLICKED_COPY_DEAL_URL = 'CLICKED_COPY_DEAL_URL';
export const CLICKED_COPY_DEAL_URL_CLICKED = 'CLICKED_COPY_DEAL_URL_CLICKED';
export const CLICKED_SEND_TO_ECOMMERCE = 'CLICKED_SEND_TO_ECOMMERCE';
export const CLICKED_PUSH_TO_DMS_CONFIRM = 'CLICKED_PUSH_TO_DMS_CONFIRM';
export const CLICKED_BEGIN_CHECKOUT = 'CLICKED_BEGIN_CHECKOUT';
export const CLICKED_CONTINUE_IN_DESKING_CONFIRM = 'CLICKED_CONTINUE_IN_DESKING_CONFIRM';
export const SEND_TO_SALES_VIEW_CONFIRM = 'SEND_TO_SALES_VIEW_CONFIRM';
export const CLICKED_SEND_TO_CUSTOMER = 'CLICKED_SEND_TO_CUSTOMER';
export const CONTINUE_IN_DESKING = 'CONTINUE_IN_DESKING';
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';
export const PAYMENT_RECEIVED = 'PAYMENT_RECEIVED';
export const PAYMENT_REQUESTED = 'PAYMENT_REQUESTED';
export const SHOW_TOTAL_TAX_RATE_SUPERSEDE_ALERT = 'SHOW_TOTAL_TAX_RATE_SUPERSEDE_ALERT';
export const HIDE_TOTAL_TAX_RATE_SUPERSEDE_ALERT = 'HIDE_TOTAL_TAX_RATE_SUPERSEDE_ALERT';
