// external
import { ReactNode, useEffect, useState } from 'react';

// interstate ui components
import { Modal } from '@interstate/components/Modal';
import { Spinner } from '@interstate/components/Spinner';
import { Typography } from '@interstate/components/Typography';
import { Alert } from '@interstate/components/Alert';
import { Grid } from '@interstate/components/Grid';
import { Button } from '@interstate/components/Button';
import { AlertSeverity } from '@interstate/components/Alert/Types/alertTypes';
import { StyledPushToRouteOneFooter } from './PushToRouteOneDialog.style';

export interface IModalLoadingType {
    show: boolean;
    onHide(): void;
    header: JSX.Element;
    success?: boolean;
    slow?: boolean;
    error?: boolean;
    saveOfferError?: boolean;
}

export const ModalLoading = (props: IModalLoadingType): JSX.Element => {
    // props
    const { show, onHide, header, slow, success, error, saveOfferError } = props;

    // states
    const [prefix, setPrefix] = useState('');
    const [message, setMessage] = useState('');
    const [footer, setFooter] = useState<ReactNode>(undefined);
    const [infoType, setInfoType] = useState<AlertSeverity>('caution');
    const [sx, setSx] = useState({ '#ids-modal-close-button': { display: 'content' } });
    const [clickHide, setClickHide] = useState(false);

    // constants
    const showCloseButton = { '#ids-modal-close-button': { display: 'content' } };
    const hideCloseButton = { '#ids-modal-close-button': { display: 'none' } };
    const modalSize = 'large';
    const cancelFooter = (
        <Grid container justifyContent="flex-end" data-testid={`${prefix}-footer`}>
            <Button data-testid={`${prefix}-cancel-button`} buttonStyle="secondary" onClick={onHide}>
                Cancel
            </Button>
        </Grid>
    );
    const closeFooter = (
        <Grid container justifyContent="flex-end" data-testid={`${prefix}-footer`}>
            <Button data-testid={`${prefix}-close-button`} buttonStyle="primary" onClick={onHide}>
                Close
            </Button>
        </Grid>
    );

    // istanbul ignore next
    const noOutOfBoundsClicking = () => {
        return clickHide ? () => {} : onHide;
    };

    useEffect(() => {
        if (slow) {
            setInfoType('caution');
            setPrefix('push-to-route-one-slow-modal');
            setMessage('Your request is taking longer than expected. You can wait or cancel and try again later.');
            setFooter(cancelFooter);
            setSx(showCloseButton);
            setClickHide(false);
        } else if (success) {
            setInfoType('success');
            setPrefix('push-to-route-one-success-modal');
            setMessage('Push to DMS was successful.');
            setFooter(closeFooter);
            setSx(showCloseButton);
            setClickHide(false);
        } else if (saveOfferError) {
            setInfoType('error');
            setPrefix('push-to-route-one-offer-error-modal');
            setMessage('Push To Route One Failed.');
            setFooter(cancelFooter);
            setSx(showCloseButton);
            setClickHide(false);
        } else if (error) {
            setInfoType('error');
            setPrefix('push-to-route-one-error-modal');
            setMessage('Push To Route One Failed.');
            setFooter(cancelFooter);
            setSx(showCloseButton);
            setClickHide(false);
        } else {
            setInfoType('info');
            setPrefix('push-to-route-one-modal');
            setMessage('This will take a moment.');
            setSx(hideCloseButton);
            setClickHide(true);
        }
    }, [success, slow, error, saveOfferError, prefix]);

    // loads success state, OR error state, OR loading state based on props and interal states it updates inside useEffect()
    return (
        <Modal
            data-testid={prefix}
            size={modalSize}
            show={show}
            header={header}
            onHide={noOutOfBoundsClicking()}
            sx={sx}
            footer={{
                footerComponent: <StyledPushToRouteOneFooter>{footer}</StyledPushToRouteOneFooter>
            }}
        >
            {/* success state */}
            {success && <Alert title={message} type={infoType} disableSuccessFade={true} />}

            {/* error state */}
            {error && (
                <>
                    <Alert title={message} type={infoType} disableSuccessFade={true}>
                        {saveOfferError ? (
                            <span>This deal encountered a problem and could not be saved. Please try again shortly</span>
                        ) : (
                            <span>This deal could not be pushed to the RouteOne. Please try again.</span>
                        )}
                    </Alert>
                    <Typography variant="h5" sx={{ paddingTop: '1rem' }}>
                        Please contact support:
                    </Typography>
                    <Typography variant="body-md">Call 1-888-895-2994 or create a support ticket</Typography>
                </>
            )}

            {/* initial loading state OR "taking longer than expected" loading state*/}
            {!success && !error && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: '10rem',
                        minHeight: '10rem'
                    }}
                >
                    <Spinner />
                    <Typography variant="body-md" sx={{ paddingTop: '1rem' }}>
                        {message}
                    </Typography>
                </div>
            )}
        </Modal>
    );
};

export default ModalLoading;
