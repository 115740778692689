// externals
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

// selectors
import { scopedStateSelectors } from '@makemydeal/dr-shared-store';

// components
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { DeskingCardBlock, StyledDeskingCard, DeskingCardBlocksContainer, DeskingCardLeftPanel } from './DeskingCard.style';
import { DeskingCardPaymentDetailsSidePanel } from './DeskingCardPaymentDetailsSidePanel';
import { DeskingCardPaymentDetailsAccordion } from './DeskingCardPaymentDetailsAccordion';
import { DeskingCardHeader } from './DeskingCardHeader';
import { DeskingCardContent } from './DeskingCardContent';

export interface DeskingCardProps {
    scopeId: string;
}

export const DeskingCard = memo(({ scopeId }: DeskingCardProps) => {
    const theme = useInterstateTheme();
    const scopesOrder = useSelector(scopedStateSelectors.getInitializedScopesOrder);
    const primaryScopeId = useSelector(scopedStateSelectors.getPrimaryScopeId);

    const isLargeScreen = useMediaQuery({ query: `(min-width: ${theme.tokens.BaseScreenLg})` });

    const isPrimaryScope = primaryScopeId === scopeId;
    const isSingleCardView = scopesOrder.length === 1;
    const isPaymentDetailsInSidePanel = isSingleCardView && isLargeScreen;

    return (
        <StyledDeskingCard
            data-testid={`deal-content-card-${scopeId}`}
            isSingleCardView={isSingleCardView}
            isLargeScreen={isLargeScreen}
            isPrimaryScope={isPrimaryScope}
            theme={theme}
        >
            <DeskingCardHeader scopeId={scopeId} />

            {isPaymentDetailsInSidePanel && (
                <DeskingCardLeftPanel>
                    <DeskingCardPaymentDetailsSidePanel />
                </DeskingCardLeftPanel>
            )}

            <DeskingCardBlocksContainer>
                {!isPaymentDetailsInSidePanel && (
                    <DeskingCardBlock rowName="payment-details">
                        <DeskingCardPaymentDetailsAccordion />
                    </DeskingCardBlock>
                )}

                <DeskingCardContent scopeId={scopeId} />
            </DeskingCardBlocksContainer>
        </StyledDeskingCard>
    );
});
