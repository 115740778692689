import { RefObject, useCallback, useEffect, useMemo } from 'react';
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
export const ResizeObserver = window.ResizeObserver || Polyfill;

export const defaultResizeObserverOptions: ResizeObserverOptions = {
    box: 'border-box'
};

/**
 * Unlike useMeasure: here, we provide our own ref to use to the hook and manage state for everything related to the observer here.
 * @param refToObserve Ref DOM to monitor for changes
 * @param onResizedCallback Callback function to invoke when DOM size changes.
 * @param options Optional behaviors for the ResizeObserver itself
 */
export function useResizeObserver(
    refToObserve: RefObject<any>,
    onResizedCallback: (entry: ResizeObserverEntry) => void,
    options?: ResizeObserverOptions
) {
    const changeObserved: ResizeObserverCallback = useCallback(
        // coverage isn't properly tracking this despite the unit test asserting invocation of the callback.
        // istanbul ignore next
        (entries) => {
            // NOTE: as noted in https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
            // This is a way to avoid a benign error we see during our integration tests when the observations can't invoke during a single animation frame.
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                entries.forEach((entry) => {
                    if (refToObserve.current === entry.target) onResizedCallback(entry);
                });
            });
        },
        [onResizedCallback, refToObserve]
    );

    const resizeObserver: ResizeObserver = useMemo(() => new ResizeObserver(changeObserved), [changeObserved]);

    useEffect(() => {
        if (!refToObserve.current) return undefined;
        const ref = refToObserve.current;
        resizeObserver.observe(ref, options || defaultResizeObserverOptions);

        // ignoring this coverage; we're invoking it during the test but not getting credit.
        // istanbul ignore next
        return () => {
            resizeObserver.unobserve(ref);
        };
    }, [resizeObserver, refToObserve, options]); // anytime details ref or resize observer instances changes. (really targeting detailsRef)
}
