import { FC, HTMLAttributes, memo } from 'react';
import { LoadingSectionParent, PaymentLoading } from '@makemydeal/dr-dash-components';
import { ManualRebatesCard as ManualRebatesCardInterstate } from './ManualRebatesCard.interstate';

export const RebatesIndex: FC<HTMLAttributes<HTMLDivElement>> = memo(function RebatesIndex(props) {
    return (
        <LoadingSectionParent {...props}>
            <PaymentLoading testId="terms-loading-indicator" />
            <ManualRebatesCardInterstate />
        </LoadingSectionParent>
    );
});
