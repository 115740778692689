// externals
import { useSelector } from 'react-redux';

// selectors
import { ScopedStateProvider, scopedStateSelectors } from '@makemydeal/dr-shared-store';

// components
import { Typography } from '@interstate/components/Typography';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { OfficialCreditDecisionToggle } from './OfficialCreditDecisionToggle';
import { DeskingCard } from './DeskingCard';

import { HeaderRowWrapper, OfferCommentsWrapper } from '../DeskingActivity.styles';
import { DeskingCardsContainer } from './DeskingCard.style';
import OfferCommentsInterstate from '../OfferComments.interstate';

export const DeskingActivityDealMultiple = () => {
    const scopesOrder = useSelector(scopedStateSelectors.getInitializedScopesOrder);

    const theme = useInterstateTheme();

    const isSingleCardView = scopesOrder.length === 1;

    return (
        <>
            <HeaderRowWrapper>
                <Typography variant="h3">Current Working Offer</Typography>
                <OfficialCreditDecisionToggle disabled={!isSingleCardView} />
            </HeaderRowWrapper>
            <DeskingCardsContainer cardsAmount={scopesOrder.length} theme={theme}>
                {scopesOrder.map((scopeId) => (
                    <ScopedStateProvider scopeId={scopeId} key={scopeId}>
                        <DeskingCard scopeId={scopeId} />
                    </ScopedStateProvider>
                ))}
            </DeskingCardsContainer>

            <OfferCommentsWrapper>
                <OfferCommentsInterstate textRows={1} />
            </OfferCommentsWrapper>
        </>
    );
};
