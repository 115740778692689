import { useContext, useState, useEffect } from 'react';

import { PriceInput } from '@interstate/components/PriceInput';
import { DomEventType, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';

import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

import { FormTextInputProps } from '../types';
import { FormContext } from './formContext';
import { convertToNumber } from '../../utils/formatUtils';
import { isBlurEvent } from '../../utils/eventUtils';

export const FormPriceInput = ({ name, label, validator }: FormTextInputProps) => {
    const { form, changeHandler } = useContext(FormContext);

    const initialValue = form.values[name] as number;
    const [value, setValue] = useState(initialValue);
    const [textValue, setTextValue] = useState(formatDollarsAndCents(initialValue));

    const onBlurHandler = (event: InterstateOnChangeEvent<TextInputEventValue>) => {
        setTextValue(formatDollarsAndCents(event.target.value));
        if (validator) {
            changeHandler(name, validator(event.target.value));
        }
    };

    const onChangeHandler = (event: InterstateOnChangeEvent<TextInputEventValue>) => {
        if (isBlurEvent(event)) {
            return;
        }
        setTextValue(event.target.value);
    };

    useEffect(() => {
        const newValue = convertToNumber(textValue);
        setValue(newValue);
        if (newValue !== value) {
            changeHandler(name, newValue);
        }
    }, [changeHandler, name, textValue, value]);

    useEffect(() => {
        const numberInitialValue = typeof initialValue === 'number' ? initialValue : convertToNumber(initialValue || '');
        if (numberInitialValue === value) return;
        setValue(numberInitialValue);
        setTextValue(formatDollarsAndCents(initialValue));
    }, [initialValue, value]);

    return (
        <PriceInput
            autoInsertCommas
            allowNegative={false}
            value={textValue}
            data-testid={'vehicle-input-' + name}
            label={label}
            id={name}
            key={name}
            name={name}
            hasError={Boolean(form.error[name])}
            errorMessage={form.error[name]}
            maxValue={9999999}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            allowDecimal={true}
            decimalMaxLength={2}
            decimalMinLength={2}
        />
    );
};
