// libraries
import { StateTree } from '@makemydeal/dr-dash-types';
import { BootstrapLoadingState, BootstrapResponseMeta, SharedBootstrapState } from '@makemydeal/dr-shared-types';

const getSharedBootstrap = (state: StateTree): Partial<SharedBootstrapState> => {
    return state.sharedBootstrap || ({} as Partial<SharedBootstrapState>);
};

export const getBootstrapLoadingState = (state: StateTree): BootstrapLoadingState => {
    const sharedBootstrap = getSharedBootstrap(state);
    return sharedBootstrap.loadingState || BootstrapLoadingState.Loading;
};

export const getBootstrapMeta = (state: StateTree): BootstrapResponseMeta | null | undefined => {
    const sharedBootstrap = getSharedBootstrap(state);
    return sharedBootstrap.meta;
};

export const getAppRunningTimeInSeconds = (state: StateTree): number => {
    const sharedBootstrap = getSharedBootstrap(state);
    const now = new Date();
    const timeInMilliseconds = sharedBootstrap.appStartTime ? now.getTime() - sharedBootstrap.appStartTime.getTime() : 0;
    return timeInMilliseconds / 1000;
};

export const getBootstrapErrorMessage = (state: StateTree): string => {
    const sharedBootstrap = getSharedBootstrap(state);
    return sharedBootstrap.loadingErrorMessage || '';
};

export const wasBootstrapSuccessful = (state: StateTree) =>
    getSharedBootstrap(state).loadingState === BootstrapLoadingState.LoadedSuccessfully;

export const getBootstrapSessionId = (state: StateTree): string | null => {
    const sharedBootstrap = getSharedBootstrap(state);
    return sharedBootstrap.sessionId || null;
};
