// externals
import { isEmpty } from 'lodash';

// libraries
import { CalculationDetails, ProductCalculationEngineService } from '@makemydeal/dr-dash-bff-types';
import type { CalculationDetailsState, StateTree } from '@makemydeal/dr-dash-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

// selectors
import * as offerReduxSelectors from './offerRedux';
import { CASH, FINANCE, LEASE } from '@makemydeal/dr-platform-types';

export const getCalculationDetailsState = (state: StateTree): CalculationDetailsState => {
    return state.calculationDetails || ({} as any);
};

export const getCalculationDetails = (state: StateTree): CalculationDetails | undefined => {
    const calculationDetails = getCalculationDetailsState(state);

    if (isEmpty(calculationDetails)) {
        return undefined;
    }

    return calculationDetails;
};

export const createCalculationDetailsForOfferSave = (state: StateTree): CalculationDetails | undefined => {
    const rootCalcDetails = getCalculationDetails(state);
    const offerType = offerReduxSelectors.getCurrentOfferType(state);
    const cashApplyMethod =
        rootCalcDetails?.payment?.calculationSettings?.cashApplyMethod || offerReduxSelectors.getCashApplyMethod(state);
    const currentCalculationEndpoint = offerReduxSelectors.getCurrentCalculationEndpoint(state);
    const shouldIncludeCashApplyMethod = offerType === LEASE && cashApplyMethod != null;
    if (rootCalcDetails) {
        const payment = rootCalcDetails.payment;
        const calculationEngine = rootCalcDetails.payment?.calculationEngine;
        const calculationSettings = rootCalcDetails.payment?.calculationSettings;

        const updatedCalcDetails = {
            ...rootCalcDetails,
            payment: {
                ...payment,
                calculationEngine: {
                    ...calculationEngine,
                    endpoint: currentCalculationEndpoint
                },
                calculationSettings: {
                    ...calculationSettings,
                    ...(shouldIncludeCashApplyMethod && { cashApplyMethod })
                }
            }
        };

        if (cashApplyMethod && (offerType === FINANCE || offerType === CASH)) {
            delete updatedCalcDetails.payment.calculationSettings.cashApplyMethod;
        }
        return updatedCalcDetails;
    }
    return {
        payment: {
            calculationEngine: {
                endpoint: currentCalculationEndpoint
            },
            calculationSettings: {
                ...(shouldIncludeCashApplyMethod && { cashApplyMethod })
            }
        }
    };
};

export const getPartnerCode = (state: StateTree): string | undefined => {
    return getCalculationDetailsState(state).payment?.calculationSourceIds?.partnerCode;
};

export const getPartnerDealerId = (state: StateTree): string | number | undefined => {
    return getCalculationDetailsState(state).payment?.calculationSourceIds?.partnerDealerId;
};

export const getProductsCalculationEngineService = (state: StateTree): ProductCalculationEngineService | undefined => {
    return getCalculationDetailsState(state).products?.calculationEngine?.service;
};

export const isFieCalculationEngineService = (state: StateTree): boolean => {
    const productsService = getProductsCalculationEngineService(state);

    return productsService === ProductCalculationEngineService.COX_FIE || productsService === undefined;
};
