// externals
import { TruncateText } from '@interstate/components/TruncateText';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// styles
import { InfoRow, InfoText } from './EditProductInformation.style';

export const ShowProductPlan = ({ planDisplayText }: { planDisplayText: string }) => {
    const theme = useInterstateTheme();

    return (
        <InfoRow theme={theme} data-testid="edit-product-plan">
            <InfoText theme={theme} data-testid="edit-product-plan-label">
                Plan/Coverage:{' '}
            </InfoText>
            <TruncateText
                displayToolTip={true}
                tooltipPosition="bottom"
                lines={0}
                data-testid="edit-product-plan-value"
                text={planDisplayText}
            />
        </InfoRow>
    );
};
