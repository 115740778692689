import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Typography } from '@interstate/components/Typography';
import { SelectInputEventValue, SelectInputOption } from '@interstate/components/SelectInput';
import { tradeInComponentSelectors, tradeInActionCreators, tradeInSelectors } from '@makemydeal/dr-dash-store';
import { updateVehicle } from '@makemydeal/dr-activities-tradein';
import { capitalize } from 'lodash';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';

// components
import { SelectInput } from './TradeInSelectInput';

// consts
import { TRADE_IN_EXTERIOR_COLOR } from '@makemydeal/dr-dash-components';
import { EXTERIOR_COLORS, ZERO_WIDTH_SPACE } from '../../../constants';

// styling
import { DetailContainer } from '../trade.interstate.style';

interface ExteriorColorFieldProps {
    hasTradeIn: boolean;
    isManualFormOpen: boolean;
}

export const ExteriorColorField: React.FC<ExteriorColorFieldProps> = ({ hasTradeIn, isManualFormOpen }) => {
    const dispatch = useDispatch();
    const vehicleExteriorColor = capitalize(useSelector(tradeInSelectors.getExteriorColor));
    const tradeInVehicle = useSelector(tradeInComponentSelectors.getTradeInComponentVehicle);

    const getVehicleExteriorColorOptions = useMemo(
        (): SelectInputOption[] => EXTERIOR_COLORS.map((color) => ({ label: color, value: color })).sort(),
        []
    );

    const onExteriorChange = (event: InterstateOnChangeEvent<SelectInputEventValue>) => {
        const color = event.target.value as string;
        dispatch(tradeInActionCreators.updateTradeInExteriorColor({ exteriorColor: color }));
        dispatch(
            updateVehicle({
                ...tradeInVehicle,
                color
            })
        );
    };

    return (
        <>
            {isManualFormOpen ? (
                <SelectInput
                    data-testid="trade-exterior-color-select-input"
                    label={TRADE_IN_EXTERIOR_COLOR}
                    name={TRADE_IN_EXTERIOR_COLOR}
                    onChange={onExteriorChange}
                    options={getVehicleExteriorColorOptions}
                    value={vehicleExteriorColor}
                    placeholder="Select"
                    displayDeselectOption={true}
                />
            ) : (
                <>
                    {hasTradeIn && (
                        <DetailContainer>
                            <Typography variant="label-md" color="sem.color.on-surface.muted" sx={{ marginBottom: '8px' }}>
                                {TRADE_IN_EXTERIOR_COLOR}
                            </Typography>
                            <Typography variant="body-md" tag="span" data-testid="trade-exterior-color-value-label">
                                {vehicleExteriorColor || ZERO_WIDTH_SPACE}
                            </Typography>
                        </DetailContainer>
                    )}
                </>
            )}
        </>
    );
};

export default ExteriorColorField;
