// externals
import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { SelectInput, SelectInputEventValue } from '@interstate/components/SelectInput';

// components
import LabelWithToolTipIcon from '../../form/LabelWithToolTipIcon';

// constants
import { REQUIRED_ATTRIBUTE_MESSAGE } from '../dealAttributesForm/constants/errorMessages';

// types
import { DealAttributeItemProps } from '../../../types';

const DealAttributeDropdown = ({ dealAttributeInput, onChangeValue }: DealAttributeItemProps) => {
    const { allowUnselectOption, description, name, options, title, value, required: isRequired } = dealAttributeInput;
    const hasIsRequiredMessage = isRequired && !value;

    const onChange: InterstateOnChangeCallback<SelectInputEventValue> = (event: InterstateOnChangeEvent<SelectInputEventValue>) => {
        const value = event.target.value;
        onChangeValue(value);
    };

    const requiredMessage = hasIsRequiredMessage ? <span className="input-info">{REQUIRED_ATTRIBUTE_MESSAGE}</span> : null;

    return (
        <div>
            <LabelWithToolTipIcon name={name} title={title} toolTipContent={description} />
            <div>
                <SelectInput
                    id={name}
                    name={name}
                    data-testid={`${name}-deal-attribute-dropdown-id`}
                    onChange={onChange}
                    value={value as string}
                    placeholder="select"
                    displayLabel={false}
                    errorMessage={REQUIRED_ATTRIBUTE_MESSAGE}
                    options={options || []}
                    displayDeselectOption={allowUnselectOption && !isRequired}
                />
            </div>
            {requiredMessage}
        </div>
    );
};

export default DealAttributeDropdown;
