// libraries
import type { StateTree, TradeInComponent } from '@makemydeal/dr-dash-types';

export const getTradeInComponent = (state: StateTree): TradeInComponent => {
    return state.tradeInComponent as TradeInComponent;
};

export const getTradeInComponentVin = (state: StateTree) => {
    return getTradeInComponent(state)?.vehicle.vin || '';
};

export const getTradeInComponentYear = (state: StateTree) => {
    return getTradeInComponent(state)?.vehicle.year || '';
};

export const getTradeInComponentYears = (state: StateTree) => {
    return getTradeInComponent(state)?.lookups?.years || { isLoading: false, options: [] };
};

export const getTradeInComponentMake = (state: StateTree) => {
    return getTradeInComponent(state)?.vehicle.make.name || '';
};

export const getTradeInComponentMakes = (state: StateTree) => {
    return getTradeInComponent(state)?.lookups?.makes || { isLoading: false, options: [] };
};

export const getTradeInComponentModel = (state: StateTree) => {
    return getTradeInComponent(state)?.vehicle.model.name || '';
};

export const getTradeInComponentModels = (state: StateTree) => {
    return getTradeInComponent(state)?.lookups?.models || { isLoading: false, options: [] };
};

export const getTradeInComponentTrim = (state: StateTree) => {
    return getTradeInComponent(state)?.vehicle.trim.name || '';
};

export const getTradeInComponentTrims = (state: StateTree) => {
    return getTradeInComponent(state)?.lookups?.trims || { isLoading: false, options: [] };
};

export const getTradeInComponentExteriorColor = (state: StateTree) => {
    return getTradeInComponent(state)?.vehicle.color || '';
};

export const getTradeInComponentCondition = (state: StateTree) => {
    return getTradeInComponent(state)?.vehicle.condition || '';
};

export const getTradeInComponentVehicle = (state: StateTree) => {
    return getTradeInComponent(state)?.vehicle || {};
};

export const getTradeInComponentVehicleMileage = (state: StateTree) => {
    return getTradeInComponent(state)?.vehicle.mileage || 0;
};
