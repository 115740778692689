import { useHeaderMeasure } from '@makemydeal/dr-dash-components';
import { FC, HTMLAttributes, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InterstateStyledBodyContainer, StyledBodyContainerProps } from './routes.interstate.style';

export const BodyContainer: FC<HTMLAttributes<HTMLDivElement | StyledBodyContainerProps>> = ({ children }) => {
    const { height } = useHeaderMeasure();
    const location = useLocation();
    useEffect(() => {
        // NOTE: upon navigating to a new location; scroll to the top. Moved here from `Card`.
        window.scroll({
            top: 0,
            left: 0
        });
    }, [location]);

    return (
        <InterstateStyledBodyContainer data-testid="dash-modal-blurlayer" height={height}>
            {children}
        </InterstateStyledBodyContainer>
    );
};
