import { SlideOut } from '@interstate/components/SlideOut';
import { bridgeUserSelectors } from '@makemydeal/dr-dash-store';
import { loadRemoteModule, offerSessionUtils } from '@makemydeal/dr-shared-ui-utils';
import { useOktaAuth } from '@okta/okta-react';
import { ComponentType, FC, LazyExoticComponent, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { CustomerDetailsProps, CustomerSidebarProps } from './CustomerSidebarProps';
import { OfferSaveBusyIndicator } from './OfferSaveBusyIndicator';
import { CUSTOMER_REQUIRED_FIELDS_FOR_PBC } from './types/CustomerSideBarRequiredFields';
import { LoadingIndicator } from '../common/LoadingIndicator';

const CustomerSidebarModule: LazyExoticComponent<ComponentType<CustomerSidebarProps>> = lazy(() =>
    loadRemoteModule('customer', './CustomerSidebarModule')
);

export const CustomerDetails: FC<CustomerDetailsProps> = ({
    commonConsumerId,
    show,
    setShow,
    onCustomerChanged,
    messageContentSelector,
    waitForOfferSave,
    tab,
    enableReadOnlyEditFields
}) => {
    const defaultMessageContent = useSelector(messageContentSelector ?? (() => ''));
    const username = useSelector(bridgeUserSelectors.getBridgeUsername);
    const fullName = useSelector(bridgeUserSelectors.getFullName);
    const nameShown = fullName.length ? fullName : username;

    const environment = offerSessionUtils.getEnvironmentType();
    const oktaContext = useOktaAuth();
    const handleCloseSlider = () => setShow(false);

    const getJwtToken = () => oktaContext?.oktaAuth.getAccessToken() || '';

    return (
        <SlideOut
            show={show}
            header={''}
            data-testid="customer-details-drawer"
            position="right"
            panelWidth="auto"
            sx={{
                '.MuiPaper-root': {
                    width: { xs: 1, md: 584 }
                },
                '.interstate-close-sliding-drawer-button ': {
                    margin: '1rem'
                },
                '.interstate-sliding-drawer-dialog-title-container': {
                    'padding-top': 0,
                    'padding-bottom': 0
                },
                '.MuiDialogContent-root': {
                    'padding-bottom': '1rem'
                },
                'div>.MuiDialogContent-root>div:first-child': {
                    // more specific; this is a hack to make the customer card fill the drawer
                    height: '100%'
                },
                '#sliding-drawer-footer-actions': {
                    display: 'none'
                },
                '#customer-form-work-phone-extension-root>label:first-child': {
                    padding: 0
                }
            }}
            onHide={handleCloseSlider}
        >
            {/* When waiting for offer save, we'll watch selectors monitoring offer save to wait for URLS. */}
            {waitForOfferSave ? (
                <OfferSaveBusyIndicator>
                    <Suspense fallback={<LoadingIndicator message="Loading..." />}>
                        <CustomerSidebarModule
                            commonConsumerID={commonConsumerId}
                            environment={environment}
                            getAuthOverride={getJwtToken}
                            doneCallback={handleCloseSlider}
                            occConfig={{ username: nameShown, messageContent: defaultMessageContent, defaultTab: 'text' } as any}
                            editCapability="enabled"
                            onCustomerEdited={onCustomerChanged}
                            defaultTab={tab}
                            enableReadOnlyEditFields={enableReadOnlyEditFields}
                            requiredFields={CUSTOMER_REQUIRED_FIELDS_FOR_PBC}
                        />
                    </Suspense>
                </OfferSaveBusyIndicator>
            ) : (
                <Suspense fallback={<LoadingIndicator message="Loading..." />}>
                    <CustomerSidebarModule
                        commonConsumerID={commonConsumerId}
                        environment={environment}
                        getAuthOverride={getJwtToken}
                        doneCallback={handleCloseSlider}
                        onCustomerEdited={onCustomerChanged}
                        occConfig={{ username: nameShown, messageContent: defaultMessageContent, defaultTab: 'text' } as any}
                        editCapability="enabled"
                        defaultTab={tab}
                        enableReadOnlyEditFields={enableReadOnlyEditFields}
                        requiredFields={CUSTOMER_REQUIRED_FIELDS_FOR_PBC}
                    />
                </Suspense>
            )}
        </SlideOut>
    );
};
