// externals
import { Reducer } from 'redux';

// libraries
import type { CreditAppState } from '@makemydeal/dr-dash-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export const creditAppInitialState: CreditAppState = {
    creditAppReferenceId: '',
    accelerateCreditAppId: '',
    coApplicantEmail: '',
    status: '',
    submittedOn: 0
};

export type CreditAppReducer = Reducer<CreditAppState>;

export const reducer: CreditAppReducer = (state: CreditAppState = creditAppInitialState, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...action.payload.offer.creditApp,
                status: action.payload.creditAppInfo?.response?.body?.status || '',
                submittedOn: action.payload.creditAppInfo?.response?.body?.submittedOn || 0
            };
        }
        default: {
            return state;
        }
    }
};
