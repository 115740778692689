// libraries
import { offerActionTypes, offerReduxActionCreators, offerReduxSelectors, offerSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';

// consts/enums
import {
    ADJUSTED_RESIDUAL_DOLLAR_TESTID,
    ADJUSTED_RESIDUAL_GROUP_TESTID,
    ADJUSTED_RESIDUAL_GROUPID,
    ADJUSTED_RESIDUAL_ID,
    ADJUSTED_RESIDUAL_LABEL,
    ADJUSTED_RESIDUAL_PERCENT_TESTID,
    ADJUSTED_RESIDUAL_VALUE_TESTID
} from '../../constants';

// utils
import { isEqual } from 'lodash';
import ResidualField from './ResidualField.interstate';

const AdjustedResidualField = () => {
    const { adjustedResidualType, adjustedResidualAmount, adjustedResidualPercent } = useSelector(
        offerSelectors.getResidualInfoOverrideWithFallback,
        isEqual
    );

    const residualValue = useSelector(offerReduxSelectors.getAdjustedResidualValue);
    const residualPercent = useSelector(offerReduxSelectors.getAdjustedResidualPercent);

    const dollar = residualValue || adjustedResidualAmount;
    const percent = residualPercent || adjustedResidualPercent;

    const props = {
        residualType: adjustedResidualType,
        residualAmount: dollar,
        residualPercent: percent,
        updateResidualOverride: offerReduxActionCreators.updateAdjustedResidualOverride,
        id: ADJUSTED_RESIDUAL_ID,
        label: ADJUSTED_RESIDUAL_LABEL,
        groupId: ADJUSTED_RESIDUAL_GROUPID,
        groupTestId: ADJUSTED_RESIDUAL_GROUP_TESTID,
        valueTestId: ADJUSTED_RESIDUAL_VALUE_TESTID,
        dollarTestId: ADJUSTED_RESIDUAL_DOLLAR_TESTID,
        percentTestId: ADJUSTED_RESIDUAL_PERCENT_TESTID,
        dollarOverrideAction: offerActionTypes.UPDATED_ADJUSTED_RESIDUAL_DOLLAR_OVERRIDE,
        percentOverrideAction: offerActionTypes.UPDATED_ADJUSTED_RESIDUAL_PERCENT_OVERRIDE
    };

    return <ResidualField inputProps={props}></ResidualField>;
};

export default AdjustedResidualField;
