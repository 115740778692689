import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';

//styles
import { InfoContainer, LogoContainer, UpdateDealBannerContainer, UpdateDealBannerWrapper } from './UpdatedDealBanner.style';

//libraries
import { offerInfoSelectors } from '@makemydeal/dr-dash-store';

//components
import IconWarning from '@cx/ui/Icons/IconWarning';
import { UPDATED_DEAL_BANNER_LABELS } from '../constants';

const UpdatedDealBanner = () => {
    const [showBanner, setShowBanner] = useState(false);
    const [lastPersona, setLastPersona] = useState('');
    const [lastMilestones, setLastMilestones] = useState([] as string[]);
    const socketEventNotification = useSelector(offerInfoSelectors.getSocketEventNotification);
    const updatedMilestones = useSelector(offerInfoSelectors.getSocketEventUpdatedMilestones);
    const isPaymentUpdate = useSelector(offerInfoSelectors.getSocketEventIsPaymentUpdate);
    const persona = useSelector(offerInfoSelectors.getSocketEventAppName);

    const milestoneLabels = updatedMilestones.reduce((prev, current) => {
        const label = UPDATED_DEAL_BANNER_LABELS[current];
        if (label) prev.push(label);
        return prev;
    }, [] as string[]);

    useEffect(() => {
        if (socketEventNotification) {
            setLastPersona(persona);
            setLastMilestones((prev) => uniq(prev.concat(milestoneLabels)));
            setShowBanner(true);
        }
    }, [socketEventNotification]);

    const handleBanner = () => {
        setShowBanner(false);
        setLastPersona('');
        setLastMilestones([]);
    };

    return (
        <>
            {showBanner && (
                <UpdateDealBannerWrapper>
                    <UpdateDealBannerContainer>
                        <LogoContainer>
                            <IconWarning className="iconDealUpdateBanner" />
                        </LogoContainer>
                        <InfoContainer>
                            <div data-testid="banner-title-container">
                                <b>Updates from {lastPersona}</b>
                            </div>
                            <div data-testid="banner-info-container">
                                {lastMilestones.length > 0 ? (
                                    <span>
                                        This offer has updates to:{' '}
                                        {lastMilestones.map((label, index) => (
                                            <Fragment key={index}>
                                                {index !== 0 && (lastMilestones.length - 1 === index ? ' and ' : ', ')}
                                                <b>{label}</b>
                                            </Fragment>
                                        ))}
                                        .{isPaymentUpdate ? " See what's changed via Quick Compare." : ''}
                                    </span>
                                ) : (
                                    <span>{'This offer has updates.'}</span>
                                )}
                            </div>
                            <div>
                                <a onClick={handleBanner} data-testid="hide-banner">
                                    Okay, Got It
                                </a>
                            </div>
                        </InfoContainer>
                    </UpdateDealBannerContainer>
                </UpdateDealBannerWrapper>
            )}
        </>
    );
};

export default UpdatedDealBanner;
