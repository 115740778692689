import React from 'react';
import { useSelector } from 'react-redux';
import { printPdfSelectors } from '@makemydeal/dr-dash-store';
import { Snackbar } from '@interstate/components/Snackbar';

export const PrintPdfLoadingModal = () => {
    const isLoading = useSelector(printPdfSelectors.isLoading);

    return (
        <Snackbar
            data-testid="print-pdf-loading-message"
            position="bottom-center"
            show={isLoading}
            message="We're currently printing your offer details"
            autoHide={false}
        />
    );
};
