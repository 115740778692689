// types
import { AdditionalSelectors, ConfigChangeVehicle, HostActions } from '../types';

// store
import * as actionCreators from './actionCreators';
import { MiddlewareManager } from './middlewareManager';
import { Selectors } from './selectors';

export const changeVehicleCreators = actionCreators;
export let changeVehicleSelectors: Selectors;
export let middlewareMgr: MiddlewareManager;
export let changeVehicleConfig: ConfigChangeVehicle;

export const init = (
    appName: string,
    additionalSelectors: AdditionalSelectors,
    hostActions: HostActions,
    config: ConfigChangeVehicle,
    baseRoute: string
): {
    selectors: Selectors;
    middlewareMgr: MiddlewareManager;
} => {
    changeVehicleConfig = config;
    changeVehicleSelectors = new Selectors(appName, additionalSelectors);
    middlewareMgr = new MiddlewareManager(changeVehicleSelectors, hostActions, config, baseRoute);

    return {
        selectors: changeVehicleSelectors,
        middlewareMgr
    };
};
