// externals
import { memo } from 'react';
import { useSelector } from 'react-redux';

// selectors
import { featureToggleSelectors, scopedStateSelectors } from '@makemydeal/dr-shared-store';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// components
import { Card } from '@interstate/components/Card';
import { GridTable, Terms, CreditBureau, PaymentLoading } from '@makemydeal/dr-dash-components';
import { StateTree } from '@makemydeal/dr-dash-types';

import InterstateAccessoriesCard from '../accessories/AccessoriesCard.interstate';
import { FeesCard } from '../fees/FeesCard';
import { RebatesIndex } from '../rebates/index';
import TaxCard from '../taxes/Taxes/TaxCard';
import InterstateTradeFormCard from '../tradeForm/TradeFormCard.interstate';
import { VehicleProtectionPreview } from '../vehicleProtection/VehicleProtectionPreview';
import { DeskingCardBlock, LoadingSection } from './DeskingCard.style';

const TermsCardSx = { height: '100%' };

export const DeskingCardContent = memo(({ scopeId }: { scopeId: string }) => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const usePaymentGridMV = useSelector(featureToggleSelectors.usePaymentGridMV);
    const paymentGridEnabledToggle = useSelector((state: StateTree) =>
        offerReduxSelectors.getPaymentGridToggle(state, currentOfferType)
    );
    const primaryScopeId = useSelector(scopedStateSelectors.getPrimaryScopeId);

    const isPaymentGridEnabled = usePaymentGridMV && paymentGridEnabledToggle;
    const isPrimaryScope = primaryScopeId === scopeId;
    return (
        <>
            <DeskingCardBlock rowName="terms" rowNameEnd="trade">
                <LoadingSection>
                    <Card sx={TermsCardSx}>
                        <PaymentLoading testId="terms-loading-indicator" />
                    </Card>
                </LoadingSection>
            </DeskingCardBlock>

            <DeskingCardBlock rowName="terms">
                <Terms />
            </DeskingCardBlock>
            <DeskingCardBlock rowName="paymentGrid">{isPaymentGridEnabled && <GridTable />}</DeskingCardBlock>

            <DeskingCardBlock rowName="creditBureau">
                <CreditBureau />
            </DeskingCardBlock>

            <DeskingCardBlock rowName="trade">
                <InterstateTradeFormCard isEditDisabled={!isPrimaryScope} />
            </DeskingCardBlock>

            <DeskingCardBlock rowName="tax">
                <TaxCard />
            </DeskingCardBlock>

            <DeskingCardBlock rowName="fees">
                <FeesCard />
            </DeskingCardBlock>

            <DeskingCardBlock rowName="rebates">
                <RebatesIndex />
            </DeskingCardBlock>

            <DeskingCardBlock rowName="vpp">
                <VehicleProtectionPreview />
            </DeskingCardBlock>

            <DeskingCardBlock rowName="accessories">
                <InterstateAccessoriesCard />
            </DeskingCardBlock>
        </>
    );
});
