import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const ManualRebatesFormContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 0.5rem;
    align-items: start;

    .label {
        display: none;
    }

    .text-input-amount input {
        text-align: right;
    }

    .text-input-name {
        grid-column-end: 5;
        grid-column-start: 3;
    }

    .hidden-label {
        margin: 0;
        line-height: auto;
        opacity: 0;
    }

    hr {
        grid-column: span 4;
        bg-color: #cacfd9;
        width: 100%;
        margin: 1rem 0;
    }

    @media (min-width: ${BreakPoint.SMALL}) {
        grid-template-columns: auto 1fr auto auto auto auto;
        .label {
            display: inline;
        }

        hr {
            grid-column: span 6;
        }

        .text-input-name {
            grid-column-end: auto;
            grid-column-start: auto;
        }

        .hidden-label {
            display: none;
        }
    }
`;

export const MobileHr = styled.hr`
    display: block;
    width: 100%;
    border-top: 1px solid #cacfd9;
    @media (min-width: ${BreakPoint.SMALL}) {
        display: none;
    }
`;
