/**
 * PURPOSE: Specialized utilities to extract objects from API call response paylods.
 */

// libraries
import type { Dealer } from '@makemydeal/dr-shared-types';
import type { FeatureToggles } from '@makemydeal/dr-platform-shared';
import type { PayloadWithConfig, PayloadWithDealer, PayloadWithFeatureToggles } from '@makemydeal/dr-shared-bff-types';

export const getFeatureTogglesFromInitPayload = (initPayload: PayloadWithFeatureToggles | undefined): Partial<FeatureToggles> =>
    initPayload?.featureToggles || {};

export const getConfigFromInitPayload = <T>(initPayload: PayloadWithConfig<T> | undefined): Partial<T> => initPayload?.config || {};

export const getDealerFromInitPayload = (initPayload: PayloadWithDealer | undefined): Partial<Dealer> =>
    (initPayload?.dealer as Dealer) || {};
