type YearsResponseType = { year: number };
type MakesResponseType = { make: string };
type ModelsResponseType = { model: string };
type TrimsResponseType = { trim: string };

export const mapYears = (data: YearsResponseType[]) => {
    return data.sort((a, b) => b.year - a.year).map((x) => x.year + '');
};

export const mapMakes = (data: MakesResponseType[]) => {
    return data.map((x) => x.make).sort();
};

export const mapModels = (data: ModelsResponseType[]) => {
    return data.map((x) => x.model).sort();
};

export const mapTrims = (data: TrimsResponseType[]) => {
    return data.map((x) => x.trim).sort();
};
