// externals
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// components
import TableGlobalAlertWarning from './TableGlobalAlertWarning';
import TableGlobalAlertError from './TableGlobalAlertError';
import TableGlobalAlertInfo from './TableGlobalAlertInfo';

// store
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { vehicleProtectionActionCreators, vehicleProtectionSelectors } from '@makemydeal/dr-dash-store';

//styles
import { AlertsAnimatedContainer, AlertsAnimatedContent } from './TableGlobalAlerts.style';

// types
import { TableGlobalAlertNames } from '../../types';
import { tableGlobalAlertMessages } from './constants/tableGlobalAlertMessages';

const TableGlobalAlerts = () => {
    const dispatch = useDispatch();

    const isRatingFailed = useSelector(vehicleProtectionSelectors.isVppRatingFailed);
    const isRatingTimeout = useSelector(vehicleProtectionSelectors.isVppRatingTimeout);
    const isPaymentFailed = useSelector(vehicleProtectionSelectors.isPaymentFailed);
    const isFetchingRates = useSelector(vehicleProtectionSelectors.isFetchingVppRates);
    const isFetchingProducts = useSelector(vehicleProtectionSelectors.isFetchingVppProducts);
    const hasProductsWithInapplicableRateAttribute = useSelector(
        vehicleProtectionSelectors.hasSelectedProductsWithInapplicableRateAttributes
    );
    const missingRequiredAttributes = useSelector(vehicleProtectionSelectors.hasMissingRequiredAttributes);
    const failedPaymentProductCodes = useSelector(vehicleProtectionSelectors.getFailedPaymentProductCodes);
    const products = useSelector(vehicleProtectionSelectors.getVppProducts);

    const isVppEnabled = useSelector(dealerSelectors.isVppEnabledForShopper);
    const isMenuPricingEnabled = useSelector(dealerSelectors.isMenuPricingEnabled);

    const theme = useInterstateTheme();

    const isFetchingFIE = isFetchingProducts || isFetchingRates;

    const retryRatesHandler = useCallback(() => {
        dispatch(vehicleProtectionActionCreators.retryFetchVehicleProtectionRates());
    }, [dispatch]);

    const alertComponents: JSX.Element[] = useMemo(() => {
        const components: JSX.Element[] = [];

        if (isRatingFailed) {
            components.push(
                <TableGlobalAlertError
                    key={TableGlobalAlertNames.RATING_FAILED}
                    message={tableGlobalAlertMessages.ratingFailed}
                    name={TableGlobalAlertNames.RATING_FAILED}
                />
            );
        }

        if (isRatingTimeout) {
            components.push(
                <TableGlobalAlertError
                    key={TableGlobalAlertNames.RATING_TIMEOUT}
                    message={tableGlobalAlertMessages.ratingTimeout}
                    name={TableGlobalAlertNames.RATING_TIMEOUT}
                    action={{ text: 'Try again.', handler: retryRatesHandler }}
                />
            );
        }

        if (hasProductsWithInapplicableRateAttribute) {
            components.push(
                <TableGlobalAlertError
                    key={TableGlobalAlertNames.HAS_PRODUCTS_WITH_INAPPLICABLE_ATTRIBUTES}
                    message={tableGlobalAlertMessages.hasProductsWithInapplicableAttributes}
                    name={TableGlobalAlertNames.HAS_PRODUCTS_WITH_INAPPLICABLE_ATTRIBUTES}
                />
            );
        }

        if (isPaymentFailed) {
            if (failedPaymentProductCodes && failedPaymentProductCodes.length) {
                components.push(
                    <TableGlobalAlertError
                        key={TableGlobalAlertNames.UNSUPPORTED_PRODUCT}
                        message={tableGlobalAlertMessages.unsupportedPaymentProduct}
                        name={TableGlobalAlertNames.UNSUPPORTED_PRODUCT}
                    />
                );
            } else {
                components.push(
                    <TableGlobalAlertError
                        key={TableGlobalAlertNames.PAYMENT_FAILED}
                        message={tableGlobalAlertMessages.paymentFailed}
                        name={TableGlobalAlertNames.PAYMENT_FAILED}
                    />
                );
            }
        }

        if (!isVppEnabled) {
            components.push(
                <TableGlobalAlertWarning
                    key={TableGlobalAlertNames.VPP_DISABLED}
                    message={tableGlobalAlertMessages.vppDisabled}
                    name={TableGlobalAlertNames.VPP_DISABLED}
                />
            );
        } else if (!isMenuPricingEnabled) {
            components.push(
                <TableGlobalAlertWarning
                    key={TableGlobalAlertNames.MENU_PRICING_DISABLED}
                    message={tableGlobalAlertMessages.menuPricingDisabled}
                    name={TableGlobalAlertNames.MENU_PRICING_DISABLED}
                />
            );
        }

        if (missingRequiredAttributes) {
            components.push(
                <TableGlobalAlertWarning
                    key={TableGlobalAlertNames.MISSING_ATTRIBUTES}
                    message={tableGlobalAlertMessages.missingRequiredAttributes}
                    name={TableGlobalAlertNames.MISSING_ATTRIBUTES}
                />
            );
        }

        if (isFetchingFIE && products.length) {
            components.push(
                <TableGlobalAlertInfo
                    key={TableGlobalAlertNames.RATES_LOADING}
                    message={tableGlobalAlertMessages.productsLoading}
                    name={TableGlobalAlertNames.RATES_LOADING}
                />
            );
        }

        return components;
    }, [
        failedPaymentProductCodes,
        isFetchingFIE,
        isMenuPricingEnabled,
        isPaymentFailed,
        isRatingFailed,
        isVppEnabled,
        missingRequiredAttributes,
        products.length,
        hasProductsWithInapplicableRateAttribute,
        isRatingTimeout,
        retryRatesHandler
    ]);

    const contentRef = useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = useState(0);

    useEffect(() => {
        if (contentRef.current) {
            const clientRect = contentRef.current.getBoundingClientRect();
            setContentHeight(clientRect.height);
        }
    }, [alertComponents]);

    if (!alertComponents.length) {
        return null;
    }

    return (
        <AlertsAnimatedContainer height={contentHeight} theme={theme}>
            <AlertsAnimatedContent ref={contentRef}>{alertComponents}</AlertsAnimatedContent>
        </AlertsAnimatedContainer>
    );
};

export default TableGlobalAlerts;
