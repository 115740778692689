import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { formatNumber, kebabCase } from '@makemydeal/dr-common-utils';
import { tradeInActionCreators, tradeInComponentSelectors, tradeInSelectors } from '@makemydeal/dr-dash-store';
import { updateVehicle } from '@makemydeal/dr-activities-tradein';

// components
import { TextInputEventValue } from '@interstate/components/TextInput';
import { Typography } from '@interstate/components/Typography';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { NumericInput } from '@interstate/components/NumericInput';

// consts
import { ODOMETER } from '../../../constants';

// styling
import { DetailContainer } from '../trade.interstate.style';

interface OdometerFieldProps {
    hasTradeIn: boolean;
    isManualFormOpen: boolean;
}

export const OdometerField: React.FC<OdometerFieldProps> = ({ hasTradeIn, isManualFormOpen }) => {
    const dispatch = useDispatch();
    const tradeInVehicle = useSelector(tradeInComponentSelectors.getTradeInComponentVehicle);
    const vehicleMileage = useSelector(tradeInSelectors.getTradeMileage);
    const [mileage, setMileage] = useState(String(vehicleMileage));

    useEffect(() => {
        if (isManualFormOpen) {
            setMileage(vehicleMileage ? String(vehicleMileage) : '0');
        }
    }, [isManualFormOpen, vehicleMileage]);

    const onOdometerChange = useCallback((event: InterstateOnChangeEvent<TextInputEventValue>) => {
        const value = event.target.value;
        setMileage(Number(value).toString());
    }, []);

    const onOdometerBlur = useCallback(() => {
        const odometer = document.getElementById('odometer') as HTMLInputElement;
        odometer.value = formatNumber(parseInt(odometer.value));

        dispatch(tradeInActionCreators.updateTradeInOdometer(mileage as any));
        dispatch(
            updateVehicle({
                ...tradeInVehicle,
                mileage: Number(mileage)
            })
        );
    }, [dispatch, mileage, tradeInVehicle]);

    return (
        <>
            {isManualFormOpen ? (
                <NumericInput
                    label={ODOMETER}
                    id="odometer"
                    data-testid="odometer"
                    name={kebabCase(ODOMETER)}
                    onChange={onOdometerChange}
                    onBlur={onOdometerBlur}
                    placeholder="0"
                    value={mileage}
                    required
                />
            ) : (
                <>
                    {hasTradeIn && (
                        <DetailContainer>
                            <Typography variant="label-md" color="sem.color.on-surface.muted" sx={{ marginBottom: '8px' }}>
                                {ODOMETER}
                            </Typography>
                            <Typography variant="body-md" tag="span" data-testid="trade-odometer-value-label">
                                {formatNumber(vehicleMileage)}
                            </Typography>
                        </DetailContainer>
                    )}
                </>
            )}
        </>
    );
};
