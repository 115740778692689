// externals
import React from 'react';
import { useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// libraries
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { SimpleTable } from '@interstate/components/SimpleTable';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';

// types
import { TaxDetail } from '../types';

// components
import TableHead from './TableHead';
import TableBody from './TableBody';
import TaxItemRateField from './TaxItemRateField';
import { DefaultFallback } from '../../common/taxesAndFeesComponents';
import TaxItemAmountField from './TaxItemAmountField';

// styles
import { StyledSpan, StyledTableCell, StyledTableCellWithoutBorder } from './TaxCard.style';

// consts/enums
import { DATA_DENSITY } from '../../common/taxesAndFeesConsts';
import {
    COUNTY_TAX_NAME,
    CITY_TAX_NAME,
    LOCAL_TAX_NAME,
    STATE_TAX_NAME,
    TAXTABLECOLUMNS,
    TAX_ITEM_KEYS,
    PRIMARY_CLASS,
    ADDITIONAL_CLASS,
    MUT_TAX
} from '../constants';

const TaxItemSummaryDetailed: React.FC<{
    taxItem: paymentServicesTypes.TaxItem;
    handleChange: (breakdownType: 'amount' | 'rate') => (e: InterstateOnChangeEvent<TextInputEventValue>, keyName: string) => void;
    isTotalTaxRateOverThreshold?: boolean;
}> = ({ taxItem, handleChange, isTotalTaxRateOverThreshold }) => {
    const isOverrideTaxRateForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideTaxRateForPaymentEnabled);
    const isOverrideTaxAmountForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideTaxAmountForPaymentEnabled);
    const isOverrideAdditonalTaxesForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideAdditonalTaxesForPaymentEnabled);
    const hasManualTotalTax = useSelector(offerReduxSelectors.hasManualTotalTax);

    const { stateTaxRate, stateTax, countyTaxRate, countyTax, cityTaxRate, cityTax, localTaxRate, localTax } =
        taxItem.taxBreakdown || /* istanbul ignore next */ {};

    const {
        stateTaxRate: isEditedStateTaxRate = false,
        stateTax: isEditedStateTax = false,
        countyTaxRate: isEditedCountyTaxRate = false,
        countyTax: isEditedCountyTax = false,
        cityTaxRate: isEditedCityTaxRate = false,
        cityTax: isEditedCityTax = false,
        localTaxRate: isEditedLocalTaxRate = false,
        localTax: isEditedLocalTax = false,
        taxAmount: isEditedTaxTotalAmount = false
    } = taxItem.manualChanges || {};

    const isSomeTaxAmountEdited = isEditedStateTax || isEditedCountyTax || isEditedCityTax || isEditedLocalTax;
    const isTaxRateEditable = !isSomeTaxAmountEdited && !isEditedTaxTotalAmount;

    const taxDetails: {
        name: string;
        rate: typeof stateTaxRate;
        amount: typeof stateTax;
        key: string;
        amountKey: string;
        isEditedRate: boolean;
        isEditedAmount: boolean;
        isRateEditable: boolean;
        isAmountEditable: boolean;
        hasError?: boolean;
    }[] = [
        {
            name: STATE_TAX_NAME,
            rate: stateTaxRate,
            amount: stateTax,
            key: TAX_ITEM_KEYS.STATE_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.STATE_TAX,
            isEditedRate: isEditedStateTaxRate,
            isEditedAmount: isEditedStateTax,
            isRateEditable: isTaxRateEditable,
            isAmountEditable: !isEditedTaxTotalAmount
        },
        {
            name: COUNTY_TAX_NAME,
            rate: countyTaxRate,
            amount: countyTax,
            key: TAX_ITEM_KEYS.COUNTY_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.COUNTY_TAX,
            isEditedRate: isEditedCountyTaxRate,
            isEditedAmount: isEditedCountyTax,
            isRateEditable: isTaxRateEditable,
            isAmountEditable: !isEditedTaxTotalAmount
        },
        {
            name: CITY_TAX_NAME,
            rate: cityTaxRate,
            amount: cityTax,
            key: TAX_ITEM_KEYS.CITY_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.CITY_TAX,
            isEditedRate: isEditedCityTaxRate,
            isEditedAmount: isEditedCityTax,
            isRateEditable: isTaxRateEditable,
            isAmountEditable: !isEditedTaxTotalAmount
        },
        {
            name: LOCAL_TAX_NAME,
            rate: localTaxRate,
            amount: localTax,
            key: TAX_ITEM_KEYS.LOCAL_TAX_RATE,
            amountKey: TAX_ITEM_KEYS.LOCAL_TAX,
            isEditedRate: isEditedLocalTaxRate,
            isEditedAmount: isEditedLocalTax,
            isRateEditable: isTaxRateEditable,
            isAmountEditable: !isEditedTaxTotalAmount
        }
    ];

    const editableTaxClasses = [PRIMARY_CLASS];

    if (isOverrideAdditonalTaxesForPaymentEnabled) {
        editableTaxClasses.push(ADDITIONAL_CLASS);
    }

    const isEditableTaxClass = editableTaxClasses.includes(taxItem.class || '');

    const showEditableTaxRate = isOverrideTaxRateForPaymentEnabled && isEditableTaxClass && taxItem.taxBasisAmount !== 0;
    const showEditableTaxAmount = isOverrideTaxAmountForPaymentEnabled && isEditableTaxClass && taxItem.taxName !== MUT_TAX;

    if (isOverrideTaxAmountForPaymentEnabled) {
        taxDetails.forEach((taxDetail) => {
            taxDetail.hasError = isTotalTaxRateOverThreshold;
        });
    }

    const renderRow = (detail: TaxDetail, index: number) => (
        <React.Fragment key={`detailed-cell-${index}-${detail.name}-${detail.amount}`}>
            <StyledTableCellWithoutBorder>
                <StyledTableCell textAlign="left" data-testid={`detailed-table-cell-${detail.name}`}>
                    {hasManualTotalTax ? (
                        <StyledSpan paddingLeft={1}>
                            <DefaultFallback />
                        </StyledSpan>
                    ) : (
                        `${detail.name}`
                    )}
                </StyledTableCell>
            </StyledTableCellWithoutBorder>
            <StyledTableCellWithoutBorder key={`detail-cell-${index}-${detail.rate}-${detail.key}-${taxItem.taxName}`}>
                {showEditableTaxRate && detail.isRateEditable ? (
                    <StyledTableCell data-testid={`detailed-table-cell-${detail.rate}-${detail.key}`} textAlign="center">
                        {hasManualTotalTax ? (
                            <DefaultFallback />
                        ) : (
                            <TaxItemRateField data={detail} handleBlur={handleChange('rate')} />
                        )}
                    </StyledTableCell>
                ) : (
                    <StyledTableCell textAlign="center" data-testid={`detailed-table-cell-${detail.rate}`}>
                        {hasManualTotalTax && <DefaultFallback />}
                        {!hasManualTotalTax && (detail.rate == undefined ? <DefaultFallback /> : `${detail.rate}%`)}
                    </StyledTableCell>
                )}
            </StyledTableCellWithoutBorder>
            <StyledTableCellWithoutBorder key={`detail-cell-amount-${index}-${detail.key}-${taxItem.taxName}`}>
                {showEditableTaxAmount && detail.isAmountEditable ? (
                    <StyledTableCell data-testid={`detailed-table-cell-${detail.amount}-${detail.key}`} textAlign="right">
                        {hasManualTotalTax ? (
                            <DefaultFallback />
                        ) : (
                            <TaxItemAmountField data={detail} handleBlur={handleChange('amount')} />
                        )}
                    </StyledTableCell>
                ) : (
                    <StyledTableCell textAlign="right" data-testid={`detailed-table-cell-${detail.amount}`}>
                        {hasManualTotalTax && <DefaultFallback />}
                        {!hasManualTotalTax &&
                            (detail.rate == undefined ? <DefaultFallback /> : formatDollarsAndCents(detail.amount))}
                    </StyledTableCell>
                )}
            </StyledTableCellWithoutBorder>
        </React.Fragment>
    );

    return (
        <SimpleTable data-testid="expanded-table-data-testid" dataDensity={DATA_DENSITY} background="white">
            <TableHead columns={TAXTABLECOLUMNS} />
            <TableBody data={taxDetails} renderRow={renderRow} />
        </SimpleTable>
    );
};

export default TaxItemSummaryDetailed;
