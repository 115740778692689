// externals
import { Tooltip } from '@interstate/components/Tooltip';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { InformationCircleIcon } from '@interstate/components/Icons';

// types
import { TableAlertInputProps } from '../../types';

const TableAlertInfo = ({ tableAlertInput }: TableAlertInputProps) => {
    const theme = useInterstateTheme();

    return (
        // The tooltip shifts to a wrong place on wide resolutions when position is one of "<position>-end" or "<position>-start"
        <Tooltip data-testid="table-alert-info-tooltip" size="large" toolTipContent={tableAlertInput.message} position="top">
            <InformationCircleIcon data-testid="table-alert-info-icon" color={theme.tokens.BaseColorBlue500} />
        </Tooltip>
    );
};

export default TableAlertInfo;
