// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { buildFromConfig, IConfigObj } from '@makemydeal/dr-common-utils';
import { RSAACallAction, RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';

// consts/enums
import {
    UPDATE_VEHICLE_INFO,
    FETCH_CVI_VEHICLE_FAILURE,
    FETCH_CVI_VEHICLE_REQUEST,
    FETCH_CVI_VEHICLE_SUCCESS
} from '../actionTypes/vehicleActionTypes';

export type UpdateVehicleInfoPayload = {
    cost: number;
    retailPrice: number;
    listedPrice?: number;
    invoice: number;
    mileage: number;
};

export const updateVehicleInfo = (payload: UpdateVehicleInfoPayload) => {
    return {
        type: UPDATE_VEHICLE_INFO,
        payload,
        meta: {
            middleware: {
                payment: true
            }
        }
    };
};

export const fetchCviVehiclesResults = (query: string, orgId: string, bffEndpointConfig: IConfigObj): RSAACallAction => {
    const proxyUrl = buildFromConfig(bffEndpointConfig, 'commonOrg');
    const endpoint = `${proxyUrl}/${orgId}/vehicles?vehicleIdentifier=${query}`;

    const request: RSAACallRequest = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        types: [FETCH_CVI_VEHICLE_REQUEST, FETCH_CVI_VEHICLE_SUCCESS, FETCH_CVI_VEHICLE_FAILURE]
    };
    return {
        [RSAA]: request
    } as RSAACallAction;
};
