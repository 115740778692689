// libraries
import type { StateTree, UserRole } from '@makemydeal/dr-dash-types';

// consts/enums
import { DEALER_ROLE, REGULAR, SHOPPER_QUOTE_DASHBOARD, VIN_SOURCE } from '../constants';

// selectors
import * as offerReduxSelectors from './offerRedux';

// TODO: DASH - will widgetSettings ever exist on the dr-dash state?
export const getWidgetSettings = (state: StateTree) => {
    return (state as any).widgetSettings || {};
};

export const getSponsor = (state: StateTree): string => {
    const { widgetSettings } = state;
    return (widgetSettings && widgetSettings.sponsor) || '';
};

export const getDsRole = (state: StateTree): string => {
    const widgetSettings = getWidgetSettings(state);
    return widgetSettings.dsRole;
};

export const getDsSource = (state: StateTree): string => {
    const widgetSettings = getWidgetSettings(state);
    return widgetSettings.dsSource || '';
};

export const isDealerUser = (state: StateTree): boolean => {
    const role = getDsRole(state);
    const source = getDsSource(state);
    return role?.toLowerCase() === DEALER_ROLE && source.toUpperCase() === VIN_SOURCE;
};

export const getUserRole = (state: StateTree): UserRole | undefined => {
    return state.widgetSettings?.userRole;
};

export const isShopperQuoteDashboard = (state: StateTree): boolean => {
    const route = getRoute(state).toLowerCase();
    return route === SHOPPER_QUOTE_DASHBOARD || (route === REGULAR && offerReduxSelectors.isOfferFromVinSource(state));
};

export const getRoute = (state: StateTree): string => {
    return state.widgetSettings?.route || '';
};

export const getSourceRoute = (state: StateTree) => {
    const route = getRoute(state);
    return isShopperQuoteDashboard(state) ? SHOPPER_QUOTE_DASHBOARD : route;
};
