export const removeLoadingIndicatorPanel = (): boolean => {
    // loadingIndicatorPanel class is coming from index.html used in open-deal and dash apps.
    const matchingElts = document.querySelectorAll('.loadingIndicatorPanel');
    if (matchingElts.length >= 1) {
        const matchingElt = matchingElts[0];
        const parentNode = matchingElt?.parentNode;
        if (parentNode) {
            parentNode.removeChild(matchingElt);
            return true;
        }
    }
    return false;
};

export const removeAllLoadingIndicatorPanels = (): number => {
    let counter = 0;
    let busy = true;
    while (busy) {
        busy = removeLoadingIndicatorPanel();
        if (busy) {
            counter++;
        }
    }
    return counter;
};
