import { UniqueScrollRequestId } from './scrollIntoViewTypes';

/**
 * Creates a unique ID that will trigger a new scroll into view action when used with
 * the useScrollIntoView hook.
 *
 * @param prefix something to uniquely namespace the scroll into view request.
 * @returns a unique ID to use with useScrollIntoView hook.
 */
export const buildUniqueScrollRequestId = (prefix = 'nav'): UniqueScrollRequestId => {
    return `${prefix}${Date.now()}`;
};
