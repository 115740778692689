// TODO: remove this file when centralized components between here and activities-desking are used
import React, { FC, ReactNode } from 'react';

// components
import DealSummaryItem from './DealSummaryItem';

export interface IDealSummaryItemWrapperProps {
    children?: ReactNode;
    label: string;
    useWrapper?: boolean;
    value: string;
    showLargeValue?: boolean;
    isExpanded?: boolean;
    hasDelta?: boolean;
}

export const DealSummaryItemWrapper: FC<IDealSummaryItemWrapperProps> = ({
    children,
    label,
    useWrapper = true,
    value,
    showLargeValue,
    isExpanded,
    hasDelta = false
}) => {
    if (useWrapper) {
        return (
            <DealSummaryItem
                label={label}
                value={value}
                showLargeValue={showLargeValue}
                isExpanded={isExpanded}
                hasDelta={hasDelta}
            >
                {children}
            </DealSummaryItem>
        );
    } else {
        return <>{children}</>;
    }
};
