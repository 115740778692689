import styled from 'styled-components';

/* istanbul ignore next */
export const TaxOverrideFieldInputContainer = styled.div<any>`
    width: 8rem;

    & .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-adornedStart.MuiInputBase-adornedEnd:before {
        content: '';
        display: ${({ $taxOverrideFormEdited }) => ($taxOverrideFormEdited ? 'block' : 'none')};
        height: 2.65rem;
        width: 0.35rem;
        background: #ebb000;
        position: absolute;
        left: 0.05rem;
    }
`;
