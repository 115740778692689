// externals
import { PropsWithChildren } from 'react';

// libraries
import { Modal } from '@interstate/components/Modal';

// consts/enums
import { Typography } from '@interstate/components/Typography';

export type TaxResetDialogDispatchProps = {
    onHide(): void;
    onCancel(): void;
    onConfirm(): void;
};

export type TaxResetDialogStateProps = {
    'data-testid': string;
};

export type TaxResetDialogProps = TaxResetDialogStateProps & TaxResetDialogDispatchProps;

export const TaxResetDialog = (props: PropsWithChildren<TaxResetDialogProps>) => {
    const { onHide, onCancel, onConfirm } = props;

    const baseDataTestId = props['data-testid'];

    const header = (
        <Typography data-testid={`${baseDataTestId}-header`} variant="h4" tag="h4" color="sem.color.on-surface.primary">
            Override Total Tax?
        </Typography>
    );
    return (
        <Modal
            size="small"
            data-testid={baseDataTestId}
            show={true}
            header={header}
            footer={{
                primary: {
                    label: 'Override',
                    'data-testid': `${baseDataTestId}-close-button`,
                    buttonStyle: 'primary',
                    action: onConfirm
                },
                options: [
                    {
                        label: 'Cancel',
                        'data-testid': `${baseDataTestId}-cancel-button`,
                        buttonStyle: 'tertiary',
                        action: onCancel
                    }
                ]
            }}
            onHide={onHide}
        >
            <Typography variant="body-md" tag="p">
                This will reset all Tax Rates(%) to their original value.
            </Typography>
        </Modal>
    );
};
