// externals
import { FC } from 'react';

// libraries
import { Button } from '@interstate/components/Button';
import { TrashIcon } from '@interstate/components/Icons/TrashIcon';
import { Tooltip } from '@interstate/components/Tooltip';
import { ArrowUturnLeftIcon as IconHistory } from '@interstate/components/Icons/ArrowUturnLeftIcon';

import { FeeDeleteButtonContainer } from './FinanceDeleteCell.style';

// types
import { CellFinanceDetailsProps } from './types';
import { useFeesFormContext } from './useFeesFormContext';

export const FinanceDeleteCell: FC<CellFinanceDetailsProps> = ({ detail, index }) => {
    const { handleDelete } = useFeesFormContext();
    const deleted = detail.isDeleted;
    const handleFeesOverrideItemDeleteOrUndo = () => {
        handleDelete({ index, category: detail.category, code: detail.code ?? 0, isDelete: !deleted });
    };

    return (
        <FeeDeleteButtonContainer>
            <Button
                className="delete-fee-button"
                buttonStyle="secondary"
                id={`edit-fee--delete-cell-${detail.code}`}
                data-testid={`edit-fee--delete-cell-${detail.code}`}
                onClick={handleFeesOverrideItemDeleteOrUndo}
            >
                {deleted && (
                    <Tooltip
                        data-testid="tooltip-deleted"
                        toolTipContent="Item was deleted. You can still undo this before updating the Offer."
                        position="bottom"
                        size="small"
                    >
                        <IconHistory />
                    </Tooltip>
                )}
                {!deleted && <TrashIcon data-testid={`manual-rebate-delete-${index}`} className="manual-rebate-delete-icon" />}
            </Button>
        </FeeDeleteButtonContainer>
    );
};
