import { FC } from 'react';
import { DefaultFallback, UpfrontTrue } from '../common/taxesAndFeesComponents';

type FormatUpfrontForDisplayProps = {
    upfront?: boolean;
};

export const FormatUpfrontForDisplay: FC<FormatUpfrontForDisplayProps> = ({ upfront }) => {
    return upfront ? <UpfrontTrue /> : <DefaultFallback />;
};
