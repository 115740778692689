import { useContext } from 'react';
import { SlideoutDrawerContextValue } from './types';
import { SlideoutDrawerContext } from './Context';

/**
 * Use this hook to manage the detail drawer state. Use `showContent(<Component/>)` to show the panel with content.
 * See: https://ghe.coxautoinc.com/DigitalRetailing/dr-dash/wiki/Using-the-Shared-Slideout-Drawer for more information on SlideoutDrawer.
 */
export const useSlideoutDrawer = (): SlideoutDrawerContextValue => {
    const context = useContext(SlideoutDrawerContext);
    if (context == null) throw new Error('Cannot useSlideoutDrawer without SlideoutDrawerProvider.');
    return context;
};
