// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';

// consts/enums
import { VAUTO_APPRAISALS_REQUEST, VAUTO_APPRAISALS_SUCCESS, VAUTO_APPRAISALS_FAILURE } from '../actionTypes/vAutoActionTypes';

// selectors
import { getCommonOrgId } from '../selectors/offerRedux';
import { getVin } from '../selectors/trade';

export const getVAutoAppraisals = (appPrefix: string, platformUserId: string) => (dispatch: any, getState: any) => {
    const state = getState();
    const commonOrgId = getCommonOrgId(state);
    const vin = getVin(state);

    if (!vin || !commonOrgId) {
        return;
    }

    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, '/vAutoAppraisals');
    const endpoint = `${bffApiUrlResult.url}?vin=${vin}`;

    dispatch({
        [RSAA]: {
            endpoint,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                PlatformUserId: platformUserId,
                CommonOrgId: commonOrgId
            },
            types: [VAUTO_APPRAISALS_REQUEST, VAUTO_APPRAISALS_SUCCESS, VAUTO_APPRAISALS_FAILURE]
        }
    });
};
