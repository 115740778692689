import { FC, MouseEventHandler } from 'react';
import { ProgramQuote } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors, programActionCreators, programPayloadUtil } from '@makemydeal/dr-dash-store';
import { AuxButton } from '@interstate/components/Button';
import {
    FlexGridCol,
    OnPrimaryHandlerCallback,
    SlideoutDrawerFooter,
    useSlideoutDrawer,
    StyledIncentivesHeader
} from '@makemydeal/dr-dash-components';
import { IncentiveSelectorForm, FooterInformation } from '../IncentiveSelector';
import { useDispatch, useSelector } from 'react-redux';
import { CASH, OfferType } from '@makemydeal/dr-platform-types';
import { IPaymentTerm } from '@makemydeal/dr-platform-shared';

export type ActionColumnBodyProps = { value: ProgramQuote; index: number };

export const ActionColumnBody: FC<ActionColumnBodyProps> = ({ value, index }) => {
    const { showContent } = useSlideoutDrawer();
    const dispatch = useDispatch();

    const selectedTerm: IPaymentTerm = useSelector(offerReduxSelectors.getSelectedTerm);
    const offerType: OfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const annualMiles = useSelector(offerReduxSelectors.getAnnualMiles);
    const isApplied = programPayloadUtil.isSelectedProgram(selectedTerm, value, offerType, annualMiles) || offerType === CASH;

    // istanbul ignore next
    const headerText = `${value.lenderLegalName || 'Cash'} Incentives`; // cannot test for Cash until the story for Add Incentives CTA is complete

    // istanbul ignore next // these are going to be challenging: but staging this for next story when we wire up apply.
    const handleSlideoutApply = (callback: OnPrimaryHandlerCallback) => {
        dispatch(programActionCreators.applySelectedProgram(value));
        callback(true);
    };

    // istanbul ignore next // these are going to be challenging: but staging this for next story when we wire up apply.
    const handleColumnApplyClicked: MouseEventHandler<HTMLButtonElement> = () => {
        if (!isApplied) dispatch(programActionCreators.applySelectedProgram(value));
    };

    const handleCloseOrCancel = () => {
        dispatch(programActionCreators.clearSelected()); // deselect program quote on close
    };

    const handleColumnViewClicked: MouseEventHandler<HTMLButtonElement> = () => {
        dispatch(programActionCreators.selectProgramQuote(value));

        showContent(IncentiveSelectorForm, {
            onClose: handleCloseOrCancel, // top-right close button
            header: <StyledIncentivesHeader>{headerText}</StyledIncentivesHeader>,
            footer: (
                <SlideoutDrawerFooter
                    showSecondary
                    primaryText="Apply"
                    text={<FooterInformation />}
                    onPrimary={handleSlideoutApply} // apply
                    onSecondary={handleCloseOrCancel} // cancel
                />
            )
        });
    };

    return (
        <FlexGridCol cols={2} gap=".5rem">
            <AuxButton data-testid={`apply-button-${index}`} onClick={handleColumnApplyClicked}>
                {isApplied ? 'APPLIED' : 'APPLY'}
            </AuxButton>
            <AuxButton data-testid={`view-button-${index}`} onClick={handleColumnViewClicked}>
                VIEW
            </AuxButton>
        </FlexGridCol>
    );
};
