import { useState } from 'react';
import { useResizeObserver } from '@makemydeal/dr-dash-components';
import { isEveryElementOnSameRow } from './components';

export const useCheckElementsOnSameRow = (ref: React.RefObject<HTMLDivElement>, selector: string) => {
    const [isOnSameRow, setIsOnSameRow] = useState(true);

    useResizeObserver(ref, () => {
        setIsOnSameRow(isEveryElementOnSameRow(ref.current, selector));
    });

    return { isOnSameRow };
};
