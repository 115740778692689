// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// consts/enums
import { COPY_ACTIVITY_SHARE_URL } from '../actionTypes/notifyActionTypes';

// actions
import { copyActivityShareUrlRSAA } from '../actions/notifyActionCreators';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    const state = store.getState();

    switch (action.type) {
        case COPY_ACTIVITY_SHARE_URL:
            store.dispatch(copyActivityShareUrlRSAA(MANAGER_VIEW_APP_PREFIX, state, action));
            break;
        default:
            break;
    }
};
