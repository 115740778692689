// libraries
import type { StateTree } from '@makemydeal/dr-dash-types';

const getRouter = (state: StateTree) => {
    return state.router;
};

export const getRouterPathName = (state: StateTree) => {
    return getRouter(state).location.pathname;
};

export const isDashboardCurrentRoute = (state: StateTree) => {
    return getRouterPathName(state) === '/dashboard';
};
