import { Comparison } from '@makemydeal/dr-dash-bff-types';
import { StateTree } from '@makemydeal/dr-dash-types';

export const getComparisonList = (state: StateTree): Comparison[] => {
    return state.comparison.comparisonList;
};

/**
 *  Will return the active comparison. Will only be populated if the comparison matches the latest DXG version
 * */
export const getActiveComparison = (state: StateTree): Comparison | undefined => {
    return state.comparison.activeComparison;
};

export const getComparisonByDealVersion = (dealVersion: string, state: StateTree): Comparison | undefined => {
    const list = getComparisonList(state) || [];
    const comparison = list.find((item: any) => {
        return item?.dealXgVersionId === dealVersion;
    });

    return comparison;
};
