import { useSelector } from 'react-redux';

import { CASH, LEASE, FINANCE } from '@makemydeal/dr-platform-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

import Cash from './CashDealSummary.interstate';
import Finance from './FinanceDealSummary.interstate';
import Lease from './LeaseDealSummary.interstate';
import { DealSummaryContainer } from './DealSummary.interstate.style';

const DealSummary = () => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);

    const getComponent = () => {
        switch (currentOfferType) {
            case CASH:
                return <Cash />;
            case FINANCE:
                return <Finance />;
            case LEASE:
                return <Lease />;
        }
    };

    return (
        <DealSummaryContainer data-testid="deal-summary-container" xs={3.9} sx={{ padding: 0 }} width="100%">
            {getComponent()}
        </DealSummaryContainer>
    );
};

export default DealSummary;
