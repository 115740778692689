import { creditAppSelectors, creditDecisionSelectors, navigationActionCreators } from '@makemydeal/dr-dash-store';
import { Typography } from '@interstate/components/Typography';
import { Badge } from '@interstate/components/Badge';
import { CheckmarkSolidIcon, DocumentTextIcon, ExclamationCircleIcon } from '@interstate/components/Icons';
import styled from 'styled-components';
import { DealerDecisionStatus } from '@makemydeal/dr-dash-types';
import { useSelector } from 'react-redux';
import { formatPayment } from '../../utils/formatUtils';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0.15rem 0.15rem 0.15rem 0;
`;

export const Label = styled.span`
    font-size: 0.5rem;
    font-color: ;
`;

export const LenderRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`;
export const CreditAppCardContent = () => {
    const selectedDecision = useSelector(creditDecisionSelectors.getSelectedCreditDecision);
    const completed = useSelector(creditAppSelectors.isCreditAppCompleted);
    const cardValue = useSelector(creditAppSelectors.getCreditAppCardLabel);
    const lenderList = useSelector(creditDecisionSelectors.getLenderCreditDecisions);
    const hasDecisionAvailable = lenderList.length > 0;
    const completedLabel = hasDecisionAvailable ? 'Credit Decision(s) Available' : cardValue;
    const isSelectedDecisionAvailable = Object.keys(selectedDecision).length > 0;

    return (
        <>
            {isSelectedDecisionAvailable ? (
                <div data-testid="credit-decision-data">
                    <InterstateThemeProvider
                        themeName="Interstate"
                        applicationName="Manager View"
                        scopeName="Manager View"
                        themeRegistries={[interstateThemeRegistry]}
                    >
                        <LenderRow>
                            <Typography variant="h5">{selectedDecision.lenderName}</Typography>
                            <div>
                                {selectedDecision.approvalStatus === DealerDecisionStatus.APPROVED && (
                                    <Badge
                                        id="badgeGreen"
                                        variant="success"
                                        startIcon={<CheckmarkSolidIcon />}
                                        className="statusContainer"
                                    >
                                        <span>{selectedDecision.approvalStatus}</span>
                                    </Badge>
                                )}
                                {(selectedDecision.approvalStatus === DealerDecisionStatus.CONDITIONALLY_APPROVED ||
                                    selectedDecision.approvalStatus === DealerDecisionStatus.COUNTER_OFFER) && (
                                    <Badge
                                        id="badgeYellow"
                                        startIcon={<DocumentTextIcon />}
                                        variant="caution"
                                        className="statusContainer"
                                    >
                                        <span>Check Details</span>
                                    </Badge>
                                )}
                                {selectedDecision.approvalStatus === DealerDecisionStatus.DECLINED && (
                                    <Badge
                                        startIcon={<ExclamationCircleIcon />}
                                        id="badgeRed"
                                        variant="error"
                                        className="statusContainer"
                                    >
                                        <span>{selectedDecision.approvalStatus}</span>
                                    </Badge>
                                )}
                            </div>
                        </LenderRow>
                    </InterstateThemeProvider>
                    <Row>
                        <Typography
                            tag="span"
                            variant="body-sm"
                            color="sem.color.on-surface.muted"
                            data-testid="select-decision-payment-label"
                        >
                            Payment
                        </Typography>

                        <Typography variant="strong-sm">${selectedDecision.monthlyPayment}</Typography>
                    </Row>

                    <Row>
                        <Typography
                            tag="span"
                            variant="body-sm"
                            color="sem.color.on-surface.muted"
                            data-testid="select-decision-payment-label"
                        >
                            Amount
                        </Typography>
                        <Typography variant="strong-sm">${formatPayment(selectedDecision.approvedAmount)}</Typography>
                    </Row>

                    <Row>
                        <Typography
                            tag="span"
                            variant="body-sm"
                            color="sem.color.on-surface.muted"
                            data-testid="select-decision-payment-label"
                        >
                            Buy Rate
                        </Typography>
                        <Typography variant="strong-sm">{selectedDecision.approvedRate}%</Typography>
                    </Row>

                    <Row>
                        <Typography
                            tag="span"
                            variant="body-sm"
                            color="sem.color.on-surface.muted"
                            data-testid="select-decision-payment-label"
                        >
                            Term
                        </Typography>
                        <Typography variant="strong-sm">{selectedDecision.approvedTerm} months</Typography>
                    </Row>

                    <span data-testid={`credit-decision-selected-label`}>Lender Decision Selected</span>
                </div>
            ) : (
                <></>
            )}
            {completed && !isSelectedDecisionAvailable && (
                <span data-testid={`credit-decision-completed-label`}>{completedLabel}</span>
            )}
        </>
    );
};
