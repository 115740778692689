/**
 * TaxItemSummarySimple is being used only for indicating tax overrides with US1519869.
 * Component is covered with the tests in TaxCard.spec.ts. And new test is added to cover US1519869.
 * Adding istanbul ignore to not to cover unused functionality of the component.
 * The component and the tests will be refactored/simplified after US1519869 accepted.
 */

// istanbul ignore file

// externals
import React from 'react';
import { useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// libraries
import { paymentServicesTypes } from '@makemydeal/dr-platform-types';
import { SimpleTable } from '@interstate/components/SimpleTable';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// components
import TableHead from './TableHead';
import TableBody from './TableBody';

// styles
import { StyledSpan, StyledTableCell, StyledTableCellWithoutBorder } from './TaxCard.style';

// consts/enums
import { DATA_DENSITY } from '../../common/taxesAndFeesConsts';
import { STATE_TAX_NAME, TAX_ITEM_KEYS, TAXTABLECOLUMNS } from '../constants';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import TaxItemRateField from './TaxItemRateField';
import { DefaultFallback } from '../../common/taxesAndFeesComponents';
import TaxItemAmountField from './TaxItemAmountField';

type Detail = {
    name: string;
    rate: number | undefined;
    key: string | undefined;
    amount: number | undefined;
    isRateEditable?: boolean;
    isAmountEditable?: boolean;
};

const TaxItemSummarySimple: React.FC<{
    taxItem: paymentServicesTypes.TaxItem;
    handleChange: (breakdownType: 'amount' | 'rate') => (e: InterstateOnChangeEvent<TextInputEventValue>, keyName: string) => void;
}> = ({ taxItem, handleChange }) => {
    const { taxRate, taxAmount, isManualTax } = taxItem;
    const keyRoot = 'simple-cell';
    const styledRoot = 'simple-table-cell';
    const isOverrideTaxRateForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideTaxRateForPaymentEnabled);
    const isOverrideTaxAmountForPaymentEnabled = useSelector(featureToggleSelectors.isOverrideTaxAmountForPaymentEnabled);
    const isTotalTaxAmountEdited = taxItem.manualChanges?.taxAmount;

    const simpleDetails = [
        {
            name: STATE_TAX_NAME,
            rate: taxRate,
            amount: taxAmount,
            key: TAX_ITEM_KEYS.STATE_TAX_RATE,
            isEditedRate: isManualTax,
            isRateEditable: !isTotalTaxAmountEdited,
            isAmountEditable: !isTotalTaxAmountEdited
        }
    ];

    const hasManualTotalTax = useSelector(offerReduxSelectors.hasManualTotalTax);
    const isRateEditable = isOverrideTaxRateForPaymentEnabled && taxItem.class === 'primary' && taxItem.taxBasisAmount !== 0;
    const isAmountEditable = isOverrideTaxAmountForPaymentEnabled && taxItem.class === 'primary' && taxItem.taxBasisAmount !== 0;

    const renderRow = (detail: Detail, index: number) => (
        <React.Fragment key={`${keyRoot}-${index}-${detail.name}-${detail.amount}`}>
            <StyledTableCellWithoutBorder>
                <StyledTableCell textAlign="left" data-testid={`${styledRoot}-${detail.name}`}>
                    {hasManualTotalTax ? (
                        <StyledSpan paddingLeft={1}>
                            <DefaultFallback />
                        </StyledSpan>
                    ) : (
                        `${detail.name}`
                    )}
                </StyledTableCell>
            </StyledTableCellWithoutBorder>
            <StyledTableCellWithoutBorder key={`${keyRoot}-${index}-${taxItem.taxName}-rate}`}>
                {isRateEditable && detail.isRateEditable ? (
                    <StyledTableCell data-testid={`${styledRoot}-${detail.rate}`} textAlign="center">
                        {hasManualTotalTax ? (
                            <DefaultFallback />
                        ) : (
                            <TaxItemRateField data={detail} handleBlur={handleChange('rate')} />
                        )}
                    </StyledTableCell>
                ) : (
                    <StyledTableCell textAlign="center" data-testid={`${styledRoot}-${detail.rate}`}>
                        {hasManualTotalTax && <DefaultFallback />}
                        {!hasManualTotalTax &&
                            (detail.rate == undefined || !detail.isRateEditable ? <DefaultFallback /> : `${detail.rate}%`)}
                    </StyledTableCell>
                )}
            </StyledTableCellWithoutBorder>
            <StyledTableCellWithoutBorder key={`${keyRoot}-${index}-${taxItem.taxName}-amount`}>
                {isAmountEditable && detail.isAmountEditable ? (
                    <StyledTableCell data-testid={`detailed-table-cell-${detail.amount}-${detail.key}`} textAlign="right">
                        {hasManualTotalTax ? (
                            <DefaultFallback />
                        ) : (
                            <TaxItemAmountField data={detail} handleBlur={handleChange('amount')} />
                        )}
                    </StyledTableCell>
                ) : (
                    <StyledTableCell textAlign="right" data-testid={`${styledRoot}-${detail.amount}`}>
                        {hasManualTotalTax && <DefaultFallback />}
                        {!hasManualTotalTax &&
                            (detail.amount == undefined || !detail.isAmountEditable ? (
                                <DefaultFallback />
                            ) : (
                                formatDollarsAndCents(detail.amount)
                            ))}
                    </StyledTableCell>
                )}
            </StyledTableCellWithoutBorder>
        </React.Fragment>
    );

    return (
        <SimpleTable data-testid="simple-table-data-testid" dataDensity={DATA_DENSITY} background="white">
            <TableHead columns={TAXTABLECOLUMNS} />
            <TableBody data={simpleDetails} renderRow={renderRow} />
        </SimpleTable>
    );
};

export default TaxItemSummarySimple;
