// externals
import { Reducer } from 'redux';

import { initActionTypes } from '@makemydeal/dr-shared-store';

// consts/enums
import * as tradeInCardActionTypes from '../actionTypes/tradeInCardActionTypes';

export type TradeInCardState = {
    openManualForm: boolean;
    shouldIncludeTradeIn: boolean;
};

export const initialState: TradeInCardState = {
    openManualForm: false,
    shouldIncludeTradeIn: true
};

export type TradeInCardReducer = Reducer<TradeInCardState>;

export const reducer: TradeInCardReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case initActionTypes.INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                shouldIncludeTradeIn: action.payload.trade?.trade?.shouldIncludeTradeIn ?? true
            };
        }
        case tradeInCardActionTypes.TRADE_IN_TOGGLE_MANUAL_FORM: {
            return {
                ...state,
                openManualForm: action.payload ?? !state.openManualForm
            };
        }
        case tradeInCardActionTypes.INCLUDE_TRADE_CHANGED: {
            return { ...state, shouldIncludeTradeIn: action.payload };
        }
        default: {
            return state;
        }
    }
};
