// externals
import { useDispatch, useSelector } from 'react-redux';
import { SelectInputOption } from '@interstate/components/SelectInput';

// libraries
import {
    tradeInSelectors,
    tradeInComponentActionCreators,
    tradeInComponentSelectors,
    tradeInActionCreators
} from '@makemydeal/dr-dash-store';
import { updateVehicle } from '@makemydeal/dr-activities-tradein';

// components
import { SelectInput } from './TradeInSelectInput';

// constants
import { TRADE_IN_TRIM } from '@makemydeal/dr-dash-components';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { useMemo } from 'react';

interface TrimFieldProps {
    isManualFormOpen: boolean;
}

const TrimsField = ({ isManualFormOpen }: TrimFieldProps) => {
    const dispatch = useDispatch();
    const vehicleYear = useSelector(tradeInSelectors.getTradeYear);
    const vehicleMakeId = useSelector(tradeInSelectors.getTradeMakeId);
    const vehicleModelId = useSelector(tradeInSelectors.getTradeModelId);
    const vehicleTrim = useSelector(tradeInSelectors.getTradeTrim);
    const vehicleTrimId = useSelector(tradeInSelectors.getTradeTrimId);
    const tradeInVehicleTrims = useSelector(tradeInComponentSelectors.getTradeInComponentTrims);
    const tradeInVehicle = useSelector(tradeInComponentSelectors.getTradeInComponentVehicle);
    const vehicleModel = useSelector(tradeInSelectors.getTradeModel);
    const trimDecodedOptions = useSelector(tradeInSelectors.getTradeTrimDecodedOptions);
    const vinHasBeenDecoded = useSelector(tradeInSelectors.vinHasBeenDecoded);

    const isTrimInputDisabled = tradeInVehicleTrims?.options.length === 0 || !vehicleModel;

    const optionsSource = vinHasBeenDecoded ? trimDecodedOptions : tradeInVehicleTrims.options;

    const tradeInTrims = useMemo((): SelectInputOption[] => {
        const options = optionsSource.map((trim) => ({ label: trim.name, value: trim.name }));

        const selectedTrim = { label: vehicleTrim, value: vehicleTrim };

        const selectedTrimAlreadyExists = options.some(
            (option) => option.label === selectedTrim.label && option.value === selectedTrim.value
        );

        if (!selectedTrimAlreadyExists && vehicleTrim && vehicleTrim.length !== 0 && vehicleTrimId && vehicleTrimId.length !== 0) {
            options.push(selectedTrim);
        }

        return options.sort();
    }, [optionsSource, vehicleTrim, vehicleTrimId]);

    const onTrimChange = (event: any) => {
        const trim = optionsSource.find((trim) => trim.name === event.target.value);
        updateTrimSelector(trim);
    };

    const updateTrimSelector = (trim: { name: string; id: string }) => {
        dispatch(tradeInActionCreators.updateTradeInTrim(trim));
        dispatch(
            updateVehicle({
                ...tradeInVehicle,
                trim
            })
        );
        if (trim && !vinHasBeenDecoded) {
            dispatch(
                tradeInComponentActionCreators.fetchTradeInComponentVehicle(
                    MANAGER_VIEW_APP_PREFIX,
                    vehicleYear,
                    vehicleMakeId,
                    vehicleModelId,
                    trim.id
                )
            );
        }
    };

    return (
        <>
            {isManualFormOpen && (
                <SelectInput
                    data-testid={`${vinHasBeenDecoded ? `trade-trim-decoded-select-input` : `trim-input`}`}
                    id={vinHasBeenDecoded ? `trade-trim-decoded-select-input` : undefined}
                    label={TRADE_IN_TRIM}
                    name={TRADE_IN_TRIM}
                    onChange={onTrimChange}
                    options={tradeInTrims}
                    value={vehicleTrim}
                    placeholder="Select"
                    disabled={isTrimInputDisabled && !vinHasBeenDecoded}
                    required
                    displayDeselectOption={false}
                />
            )}
        </>
    );
};

export default TrimsField;
