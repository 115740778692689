// externals
import { getReasonPhrase } from 'http-status-codes';

// components
import { Alert } from '@interstate/components/Alert';
import { Action } from '@interstate/components/Action';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { PUSH_CDMS_ERROR_MESSAGE, PUSH_ERROR_DIALOG_HEADER_INTERSTATE, PUSH_ERROR_DIALOG_HEADER } from '../pushToDmsConstants';
import { DEALER_SUPPROT_REQUEST_FORM } from '../../../constants';
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary } from '@interstate/components/AccordionGroup';

// selectors
import { CDMS, pushToDmsSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
export interface IPushToDmsErrorProps {
    statusCode: number;
    errorDetails: string | undefined;
}

export const PushToDmsError = (props: IPushToDmsErrorProps) => {
    const { statusCode, errorDetails } = props;
    const pushToDmsWsEvent = useSelector(pushToDmsSelectors.getPushToDmsWsEvent);
    const enableOptionalPbcUi = useSelector(featureToggleSelectors.enableOptionalPbcUi);
    const cdmsStorefrontErrorMessage = useSelector(pushToDmsSelectors.getCdmsStorefrontErrorMessage);
    const dmsIntegrationToggle = useSelector(dealerSelectors.getDMSIntegrationToggle);
    const isCdmsPbcIntegration = enableOptionalPbcUi && dmsIntegrationToggle === CDMS;

    // istanbul ignore next
    const openSupportTicketUrl = () => {
        window.open(DEALER_SUPPROT_REQUEST_FORM);
    };

    const dmsErrorText = isCdmsPbcIntegration
        ? PUSH_CDMS_ERROR_MESSAGE
        : `Error Code: ${statusCode} - ${getReasonPhrase(statusCode)}`;

    const cdmsErrorMessage = cdmsStorefrontErrorMessage ? cdmsStorefrontErrorMessage : pushToDmsWsEvent?.payload?.message;

    return (
        <Grid container flexDirection="column" gap={2}>
            <Alert data-testid="dms-error-modal" type="error">
                <Typography variant="h6" tag="h6">
                    {isCdmsPbcIntegration ? PUSH_ERROR_DIALOG_HEADER : PUSH_ERROR_DIALOG_HEADER_INTERSTATE}
                </Typography>
                <Typography variant="body-sm" tag="div" data-testid="dms-error-code-text">
                    {dmsErrorText}
                </Typography>
                {errorDetails && !isCdmsPbcIntegration && (
                    <Typography
                        variant="body-sm"
                        tag="div"
                        data-testid="dms-error-detail-text"
                    >{`Details: ${errorDetails}`}</Typography>
                )}
            </Alert>
            <Grid>
                <Typography variant={isCdmsPbcIntegration ? 'strong-md' : 'h6'} tag="h6">
                    Please contact support:
                </Typography>
                <Typography variant={isCdmsPbcIntegration ? 'body-md' : 'body-sm'} tag="span">
                    1-888-895-2994 or create a
                </Typography>
                <Action onClick={openSupportTicketUrl} data-testid="support-ticket" sx={{ marginLeft: '4px' }}>
                    <Typography variant="anchor-inline-md" color="sem.color.surface.button.primary.rest">
                        {isCdmsPbcIntegration ? 'Support Ticket' : 'support ticket'}
                    </Typography>
                </Action>
            </Grid>
            {isCdmsPbcIntegration && cdmsErrorMessage && (
                <Grid>
                    <AccordionGroup>
                        <Accordion sx={{ borderBottomStyle: 'none' }}>
                            <AccordionSummary data-testid="dms-error-accordian-summary" chevronPosition="left">
                                <span>View error code(s)</span>
                            </AccordionSummary>
                            <AccordionDetails
                                data-testid="dms-error-accordian-text"
                                sx={{
                                    maxHeight: '8rem',
                                    overflow: 'auto',
                                    paddingLeft: '26px',
                                    paddingRight: '18px',
                                    textAlign: 'justify'
                                }}
                            >
                                <Typography variant="body-md" tag="span">
                                    {cdmsErrorMessage}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                </Grid>
            )}
        </Grid>
    );
};
