import { nanoid } from 'nanoid';

let sessionId: string | undefined;

export const getAppSessionId = (): string => {
    if (!sessionId) {
        sessionId = nanoid();
    }
    return sessionId;
};

export const clearAppSessionId = () => {
    sessionId = undefined;
};
