// externals
import { Reducer } from 'redux';

// libraries
import { CalculationDetailsState } from '@makemydeal/dr-dash-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';
import { UPDATE_IS_TARGET_OUT_OF_POCKET } from '../actionTypes/calculationDetailsActionTypes';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export type CalculationDetailsReducer = Reducer<CalculationDetailsState>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reducer: CalculationDetailsReducer = (state: CalculationDetailsState = {}, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...action.payload.calculationDetails
            };
        }
        case UPDATE_IS_TARGET_OUT_OF_POCKET: {
            const isTargetOutOfPocket = action.payload || false;
            const cashApplyMethod = isTargetOutOfPocket ? 'AddCashOffsetToPrice' : '';
            return {
                ...state,
                payment: {
                    ...state.payment,
                    calculationSettings: {
                        ...state.payment?.calculationSettings,
                        cashApplyMethod
                    }
                }
            };
        }
        default: {
            return state;
        }
    }
};
