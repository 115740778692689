// external
import type { Reducer } from 'redux';
import produce, { Draft } from 'immer';

// libraries
import { initActionTypes } from '@makemydeal/dr-shared-store';

// utils
import {
    getRequestedSurcharges,
    getModifiedProducts,
    mapToDealerProducts,
    mapToInitialDealXgProducts,
    mapToPaymentProductCodes,
    selectAllEligibleProducts,
    syncVppProducts,
    unselectAllEligibleProducts,
    updateProductsWithRates,
    sortProducts
} from '../utils/vehicleProtectionUtils';

// types
import { MAX_FETCH_RATES_POLLING_COUNT, RATE_REQUEST_STATUSES } from '../types/vehicleProtectionTypes';
import { PAYMENT_FAILURE, PAYMENT_RECEIVED, PAYMENT_REQUESTED } from '../actionTypes/deskingActionTypes';
import type { DraftDealProduct, VehicleProtectionState } from '@makemydeal/dr-dash-types';
import {
    ALL_PRODUCTS_MONTHLY_PAYMENT_RESET,
    ALL_PRODUCTS_SELECTED,
    ALL_PRODUCTS_UNSELECTED,
    FETCH_PRODUCTS_FAILURE,
    FETCH_PRODUCTS_REQUESTED,
    FETCH_PRODUCTS_RETRY,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_RATES_FAILURE,
    FETCH_RATES_REQUESTED,
    FETCH_RATES_RETRY,
    FETCH_RATES_SUCCESS,
    PRODUCT_CHANGES_PAYMENT_CALL,
    PRODUCT_MONTHLY_PAYMENT_UPDATED,
    PRODUCT_SELECTED,
    PRODUCT_UNSELECTED,
    PRODUCT_UPDATED,
    PRODUCTS_REQUEST_OVERRIDDEN
} from '../actionTypes/vehicleProtectionActionTypes';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export const initialState: VehicleProtectionState = {
    products: [],
    dealXgProducts: [],
    dealerProducts: [],
    modifiedProducts: [],
    rates: [],
    rateToken: '',
    surcharges: {},
    requestedSurcharges: {
        usedSurchargesAttributes: [],
        requiredSurchargesAttributes: []
    },
    isFetchingProducts: false,
    isFetchingRates: false,
    lastFailureAction: null,
    isPaymentFailed: false,
    isRatingFailed: false,
    isRatingTimeout: false,
    isFetchRatesRetried: false,
    paymentProductCodes: [],
    lastSuccessfulPaymentProductCodes: []
};

export type VehicleProtectionReducer = Reducer<VehicleProtectionState>;

export const reducer: VehicleProtectionReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }

    return produce(state, (draft: Draft<VehicleProtectionState>) => {
        switch (action.type) {
            case INIT_PENCIL_SUCCESS: {
                const { dealVehicleProtection } = action.payload;
                if (dealVehicleProtection?.products) {
                    const { products: dealProducts }: { products: DraftDealProduct[] } = dealVehicleProtection;

                    draft.products = sortProducts(dealProducts);
                    draft.dealXgProducts = mapToInitialDealXgProducts(dealProducts);
                    draft.modifiedProducts = getModifiedProducts(draft, dealProducts);
                    draft.lastSuccessfulPaymentProductCodes = mapToPaymentProductCodes(dealProducts);
                    draft.isFetchingRates = true;
                }
                if (dealVehicleProtection?.surchargesOverrides) {
                    draft.surcharges = dealVehicleProtection.surchargesOverrides;
                }

                break;
            }
            case PRODUCT_SELECTED: {
                const { productCode } = action.payload;

                const selectedProduct = draft.products.find(
                    (product) => product.productCode && product.productCode === productCode
                );

                if (selectedProduct) {
                    selectedProduct.selected = true;
                }

                draft.modifiedProducts = getModifiedProducts(draft, [{ productCode }]);

                break;
            }
            case PRODUCT_UNSELECTED: {
                const { productCode } = action.payload;
                const selectedProduct = draft.products.find(
                    (product) => product.productCode && product.productCode === productCode
                );

                if (selectedProduct) {
                    selectedProduct.selected = false;
                }

                break;
            }
            case ALL_PRODUCTS_SELECTED: {
                draft.products = selectAllEligibleProducts(draft);
                draft.modifiedProducts = getModifiedProducts(draft, draft.products);

                break;
            }
            case ALL_PRODUCTS_UNSELECTED: {
                draft.products = unselectAllEligibleProducts(draft);

                break;
            }
            case PRODUCT_UPDATED: {
                const { productCode } = action.payload;
                const productToUpdate = draft.products.find(
                    (product) => product.productCode && product.productCode === productCode
                );

                if (productToUpdate) {
                    Object.assign(productToUpdate, action.payload);
                    draft.modifiedProducts = getModifiedProducts(draft, [{ productCode }]);
                }

                draft.lastSuccessfulPaymentProductCodes = draft.lastSuccessfulPaymentProductCodes.filter(
                    (code) => code !== productCode
                );
                break;
            }
            case PRODUCTS_REQUEST_OVERRIDDEN: {
                draft.surcharges = action.payload;

                break;
            }
            case FETCH_PRODUCTS_REQUESTED: {
                draft.isFetchingProducts = true;
                break;
            }
            case FETCH_PRODUCTS_SUCCESS: {
                const { products: dealerProducts, surcharges, rateToken } = action.payload;

                draft.isFetchingProducts = false;
                const syncedProducts = syncVppProducts(draft, dealerProducts);
                draft.products = sortProducts(syncedProducts);
                draft.requestedSurcharges = getRequestedSurcharges(surcharges);
                draft.dealerProducts = mapToDealerProducts(dealerProducts);
                draft.rateToken = rateToken;

                break;
            }
            case FETCH_PRODUCTS_FAILURE: {
                draft.isFetchingProducts = false;
                draft.isFetchingRates = false;

                break;
            }
            case FETCH_PRODUCTS_RETRY: {
                draft.isFetchRatesRetried = false;

                break;
            }
            case FETCH_RATES_REQUESTED: {
                draft.isFetchingRates = true;
                draft.isRatingFailed = false;
                draft.isRatingTimeout = false;

                break;
            }
            case FETCH_RATES_SUCCESS: {
                const { status, products, errors = [] } = action.payload;
                const { retryNumber, showRecommendedOnVppRates } = action.meta;

                if (status === RATE_REQUEST_STATUSES.FINISHED) {
                    draft.isFetchingRates = false;
                    draft.isRatingFailed = errors.length > 0;
                    draft.rates = products;

                    draft.products = updateProductsWithRates(draft, { showRecommendedOnVppRates });
                } else if (retryNumber > MAX_FETCH_RATES_POLLING_COUNT) {
                    draft.isFetchingRates = false;
                    draft.isRatingFailed = true;
                    draft.isRatingTimeout = true;
                }

                break;
            }
            case FETCH_RATES_FAILURE: {
                draft.isFetchingRates = false;
                draft.isRatingFailed = true;

                break;
            }
            case FETCH_RATES_RETRY: {
                draft.isFetchRatesRetried = true;

                break;
            }
            case PAYMENT_REQUESTED: {
                draft.lastFailureAction = null;
                draft.isPaymentFailed = false;
                draft.paymentProductCodes = mapToPaymentProductCodes(draft.products);

                break;
            }
            case PAYMENT_RECEIVED: {
                if (!action.meta?.originalAction) break;
                if (!action.meta.offerType) break;
                if (
                    action.payload[action.meta.offerType]?.success !== true &&
                    action.meta.originalAction.type === PRODUCT_CHANGES_PAYMENT_CALL
                ) {
                    draft.lastFailureAction = action.meta.originalAction.type;
                    draft.isPaymentFailed = true;
                }

                const requestProductCodes = action.meta.originalAction.meta?.middleware?.productCodes;
                if (requestProductCodes) {
                    draft.lastSuccessfulPaymentProductCodes = requestProductCodes;
                } else {
                    draft.lastSuccessfulPaymentProductCodes = draft.paymentProductCodes;
                }

                break;
            }
            case PAYMENT_FAILURE: {
                if (!action.meta?.originalAction) break;
                if (action.meta.originalAction.type === PRODUCT_CHANGES_PAYMENT_CALL) {
                    draft.lastFailureAction = action.meta.originalAction.type;
                    draft.isPaymentFailed = true;
                }

                break;
            }
            case PRODUCT_MONTHLY_PAYMENT_UPDATED: {
                const { productCode, productMonthlyPayment } = action.payload;

                const productToUpdate = draft.products.find(
                    (product) => product.productCode && product.productCode === productCode
                );

                if (productToUpdate && productMonthlyPayment) {
                    productToUpdate.productMonthlyPayment = productMonthlyPayment;
                    // NOTE: monthly price exists only when product price is capitalized
                    productToUpdate.isProductPriceCapitalized = true;
                }

                break;
            }
            case ALL_PRODUCTS_MONTHLY_PAYMENT_RESET: {
                draft.products.forEach((product) => delete product.productMonthlyPayment);

                break;
            }
        }
    });
};
