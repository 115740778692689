// interfaces/types
import type { ProfitOptions } from './manualAccessoriesTypes';

// consts/enums
import * as deskingConstants from '../../../constants';

export const profitOptions: ProfitOptions = [
    {
        label: deskingConstants.BACK,
        value: 'backEndProfit'
    },
    {
        label: deskingConstants.FRONT,
        value: 'frontEndProfit'
    }
];
