import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// constants
const startsFromZeroRegExp = /^-?0$/;
const dotChar = '.';
const minusChar = '-';
const zeroChar = '0';
const allowedDigitsAfterDecimalPoint = 2;

export const getFormattedPrice = (value: number | undefined, isInputFocused: boolean) => {
    const unformatted = value ?? '';

    return isInputFocused ? unformatted.toString() : formatDollarsAndCents(unformatted);
};

export const getCleanNumericString = (inputString: string): string => {
    let outputString = '';

    const trimmedString = inputString.trim();
    const dotIndex = trimmedString.indexOf(dotChar);
    const isDecimal = dotIndex !== -1;

    trimmedString.split('').forEach((char, index) => {
        if (char === minusChar) {
            if (index === 0) {
                outputString += char;
            }
        }
        if (char === dotChar) {
            if (dotIndex === index) {
                outputString += char;
            }
        }
        if (char === zeroChar && startsFromZeroRegExp.test(outputString)) {
            return;
        }
        if (/\d/.test(char)) {
            outputString += char;
        }
    });

    if (isDecimal) {
        return outputString.slice(0, dotIndex + allowedDigitsAfterDecimalPoint + 1);
    }

    return outputString;
};
