// libraries
import { FSA } from '@makemydeal/dr-platform-shared';
import { UniqueScrollRequestId } from '@makemydeal/dr-dash-ui-utils';

// consts/enums
import { INCLUDE_TRADE_CHANGED, TRADE_IN_TOGGLE_MANUAL_FORM } from '../actionTypes/tradeInCardActionTypes';
import { TradeAction } from '../types';

export type ToggleManualTradeFormAction = FSA<typeof TRADE_IN_TOGGLE_MANUAL_FORM, UniqueScrollRequestId>;

export const toggleManualTradeForm = (toggleStateOrUniqueRequestId?: UniqueScrollRequestId): ToggleManualTradeFormAction => ({
    type: TRADE_IN_TOGGLE_MANUAL_FORM,
    payload: toggleStateOrUniqueRequestId
});

export const includeTradeChanged = (payload: boolean, makePaymentCall = true) => {
    const action: TradeAction = {
        type: INCLUDE_TRADE_CHANGED,
        payload
    };
    if (makePaymentCall) {
        action.meta = {
            middleware: {
                payment: true
            }
        };
    }
    return action;
};
