export const getRoute = (route: string, baseRoute?: string): string => {
    baseRoute = baseRoute || '/dealHistory';
    return `${baseRoute}${route}`;
};

export const getAllRoutes = (baseRoute?: string): { [key: string]: string } => {
    // TODO: Add routes here
    return {
        root: getRoute('', baseRoute)
    };
};
