// externals
import { Route } from 'react-router-dom';

// components
import ChangeVehicleActivity from './ChangeVehicleActivity';

// utils
import { getAllRoutes } from '../utils/routes';

// types
import { ChangeVehicleActivityProps } from '../types';

const Root = (props: ChangeVehicleActivityProps) => {
    const { root } = getAllRoutes(props.baseRoute);

    return (
        <div data-testid="activities-change-vehicle-container" className="activities-change-vehicle">
            <Route path={root} component={ChangeVehicleActivity} />
        </div>
    );
};

Root.displayName = 'ChangeVehicleActivity';

export default Root;
