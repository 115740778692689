/**
 * PURPOSE: Actions that trigger push to darwin will be processed by this middleware.
 */

// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// actions
import { offerActionCreators, pushToDarwinActionCreators } from '../actions';

// consts/enums
import { OFFER_SAVE_SUCCESS, LINKS_SUCCESS, OFFER_SAVE_NOCHANGE_SUCCESS, SOFT_SAVE_SUCCESS } from '../actionTypes/offerActionTypes';
import {
    OPEN_IN_DARWIN,
    OPEN_IN_DARWIN_URL,
    PUSH_TO_DARWIN,
    PUSH_TO_DARWIN_FAILURE,
    PUSH_TO_DARWIN_SUCCESS
} from '../actionTypes/pushToDarwinActionTypes';
import { OPEN_IN_DARWIN_FLOW, PUSH_TO_DARWIN_FLOW, DARWIN_ERROR_MESSAGES } from '../constants';
import { BuildPayloadPurpose } from '../types';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

// actions
import { buildOfferSavePayloadObject } from '../actions/offerActionCreators';
import { pushToDarwinSelectors, vehicleSelectors } from '../selectors';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    const state = store.getState();

    switch (action.type) {
        case OPEN_IN_DARWIN:
        case PUSH_TO_DARWIN: {
            const source = action.type === OPEN_IN_DARWIN ? OPEN_IN_DARWIN_FLOW : PUSH_TO_DARWIN_FLOW;

            const offerSavePayload = buildOfferSavePayloadObject(state, BuildPayloadPurpose.PushToDarwin);
            store.dispatch(offerActionCreators.saveOfferRSAA(state, offerSavePayload, undefined, source));
            break;
        }
        case LINKS_SUCCESS:
        case SOFT_SAVE_SUCCESS:
        case OFFER_SAVE_NOCHANGE_SUCCESS:
        case OFFER_SAVE_SUCCESS: {
            const offerSaveSource = action.meta?.source;
            if (offerSaveSource === PUSH_TO_DARWIN_FLOW || offerSaveSource === OPEN_IN_DARWIN_FLOW) {
                const vehicleStockNumber = vehicleSelectors.getVehicleStockNumber(state);
                store.dispatch(
                    vehicleStockNumber
                        ? pushToDarwinActionCreators.pushToDarwinRSAA(MANAGER_VIEW_APP_PREFIX, offerSaveSource)
                        : pushToDarwinActionCreators.pushToDarwinError({
                              statusCode: 400,
                              errorDetails: DARWIN_ERROR_MESSAGES.STOCK_NUMBER_ERROR
                          })
                );
            }
            break;
        }
        case PUSH_TO_DARWIN_SUCCESS: {
            const offerSaveSource = action.meta?.source;
            if (offerSaveSource === OPEN_IN_DARWIN_FLOW) {
                store.dispatch(pushToDarwinActionCreators.openInDarwinUrl());
            }
            break;
        }
        case OPEN_IN_DARWIN_URL: {
            const url = pushToDarwinSelectors.getDarwinResponseData(state).url;
            if (url && url !== '') {
                window.open(url, '_blank');
            }
            break;
        }

        case PUSH_TO_DARWIN_FAILURE:
            store.dispatch(
                pushToDarwinActionCreators.pushToDarwinError({
                    statusCode: action.payload?.statusCode,
                    errorDetails: action.payload?.message
                })
            );
            break;
    }
};
