import { CustomerCardOptions } from '@makemydeal/dr-dash-types';
import { CommonConsumer } from '@makemydeal/dr-platform-types';
import { createContext } from 'react';

export type CustomerCardMessageContentSelector = (state: any) => string;

export type CustomerSearchCallback = (consumer: CommonConsumer) => void;

export type CustomerCardShowOptions = CustomerCardOptions & {
    /**
     * A late-binding selector that can determine the message content. (Default: undefined) Takes priority over messageContent
     * @param state
     * @returns Message content to use in OCC Inbox
     */
    messageContentSelector?: CustomerCardMessageContentSelector;
    /**
     * Callback for when the customer is edited
     */
    customerEditedCallback?: (consumer: CommonConsumer) => void;
    readOnlyFields?: boolean;
};
// /**
//  * This selector provides a way to late-bind the message content for the OCC Inbox.
//  */
// export type CustomerCardOptionsRequiredSelector = Omit<CustomerCardShowOptions, 'messageContentSelector'> & {
//     messageContentSelector?: (state: any) => string;
// };

export type CustomerUIContextValue = {
    /**
     * Toggles the slideout current state when no argument provided. Or forces state when argument is provided.
     * @param show True shows the slideout
     * @returns
     */
    showCustomerCard: (show: boolean, options?: CustomerCardShowOptions) => void;

    /**
     * Toggles the customer search slide out from customer-ui
     * @param show (True to show), false to hide; undefined to toggle
     */
    showSearch: (show: boolean, callback?: CustomerSearchCallback) => void;
};

export const CustomerUIContext = createContext<CustomerUIContextValue | undefined>(undefined);
