// libraries
import type { OfferInfoState, StateTree } from '@makemydeal/dr-dash-types';
import { PERSONA_APPNAME_RELATION, VERSION_CREATED_BY_TO_APP_DISPLAY_NAME } from '../constants';

const getOfferInfoState = (state: StateTree): OfferInfoState => {
    return state.offerInfo ?? {};
};

export const getOfferId = (state: StateTree) => {
    const offer = getOfferInfoState(state);
    return offer.offerId;
};

export const getDealXgVersion = (state: StateTree) => {
    const offer = getOfferInfoState(state);
    return offer.dealXgVersion;
};

export const getHasBeenSent = (state: StateTree) => {
    const offerInfoState = getOfferInfoState(state);
    return offerInfoState.hasBeenSent;
};

export const getIsOfferBeingSubmitted = (state: StateTree) => {
    const offerInfoState = getOfferInfoState(state);
    return offerInfoState.isOfferBeingSubmitted;
};

export const getHasBeenSaved = (state: StateTree) => {
    const offerInfoState = getOfferInfoState(state);
    return offerInfoState.hasBeenSaved;
};

export const getIsOfferBeingSaved = (state: StateTree) => {
    const offerInfoState = getOfferInfoState(state);
    return offerInfoState.isOfferBeingSaved;
};

export const getIsMakingCurrent = (state: StateTree) => {
    const offerInfoState = getOfferInfoState(state);
    return offerInfoState.isMakingCurrent;
};

export const isTestDriveReadyBeforeOffer = (state: StateTree) => {
    const offerInfoState = getOfferInfoState(state);
    return offerInfoState.isTestDriveReadyBeforeOffer;
};

export const hasLinks = (state: StateTree) => !!getShopperURL(state);

export const getCreditAppURL = (state: StateTree) => {
    const offerInfoState = getOfferInfoState(state);
    return offerInfoState.urls?.creditAppURL ?? '';
};

export const getShopperURL = (state: StateTree) => {
    const offerInfoState = getOfferInfoState(state);
    return offerInfoState.urls?.shopperURL ?? '';
};

export const getTestDriveURL = (state: StateTree) => {
    const offerInfoState = getOfferInfoState(state);
    return offerInfoState.urls?.testDriveURL ?? '';
};

export const getDocUploadURL = (state: StateTree) => {
    const offerInfoState = getOfferInfoState(state);
    return offerInfoState.urls?.docUploadURL ?? '';
};

export const getSocketEventNotification = (state: StateTree) => {
    const offerInfoState = getOfferInfoState(state);
    return offerInfoState.socketEventNotification;
};

export const getSocketEventUpdatedMilestones = (state: StateTree) => {
    return getSocketEventNotification(state)?.updatedMilestones ?? [];
};

export const getSocketEventIsPaymentUpdate = (state: StateTree) => {
    const paymentActivities = ['tradeIn', 'vehicleProtectionProducts', 'incentives', 'accessories'];
    const milestoneList = getSocketEventUpdatedMilestones(state);
    return milestoneList.filter((item) => paymentActivities.includes(item)).length > 0;
};

export const getSocketEventAppName = (state: StateTree) => {
    const { changedBy, dealStructure } = getSocketEventNotification(state) || {};
    const createdByAppName = dealStructure?.versionCreatedBy?.applicationName;
    if (createdByAppName) {
        const appName = VERSION_CREATED_BY_TO_APP_DISPLAY_NAME[createdByAppName];
        if (appName) {
            return appName;
        }
    }
    const persona = changedBy?.persona ?? '';
    return PERSONA_APPNAME_RELATION[persona.toLocaleLowerCase()] ?? '';
};

export const getSocketEventDealVersion = (state: StateTree) => {
    return getSocketEventNotification(state)?.message.version ?? '';
};

export const getShowInvalidCoBuyerModal = (state: StateTree) => getOfferInfoState(state).showInvalidCoBuyerModal ?? false;
