// externals
import type { Reducer } from 'redux';

// libraries
import { NavigationActions } from '@makemydeal/dr-activities-common';

// utils
import { catalogToVehicle } from '../utils';

// types
import { ChangeVehicleState } from '../types';
import { IVehicle } from '@makemydeal/dr-platform-types';

// actions
import {
    CHANGE_VEHICLE_CANCEL,
    CLEAR_CATALOG_VEHICLES,
    FETCH_CATALOG_MAKES_FAILURE,
    FETCH_CATALOG_MAKES_REQUEST,
    FETCH_CATALOG_MAKES_SUCCESS,
    FETCH_CATALOG_MODELS_FAILURE,
    FETCH_CATALOG_MODELS_REQUEST,
    FETCH_CATALOG_MODELS_SUCCESS,
    FETCH_CATALOG_TRIMS_FAILURE,
    FETCH_CATALOG_TRIMS_REQUEST,
    FETCH_CATALOG_TRIMS_SUCCESS,
    FETCH_CATALOG_VEHICLES_FAILURE,
    FETCH_CATALOG_VEHICLES_REQUEST,
    FETCH_CATALOG_VEHICLES_SUCCESS,
    FETCH_CATALOG_YEARS_FAILURE,
    FETCH_CATALOG_YEARS_REQUEST,
    FETCH_CATALOG_YEARS_SUCCESS,
    FETCH_CVI_FAILURE,
    FETCH_CVI_REQUEST,
    FETCH_CVI_SUCCESS,
    PROCESS_CVI_VEHICLES,
    NEW_VEHICLE_SELECTED,
    PROCESS_SWITCH_VEHICLE,
    SEARCH_VEHICLES_CLICKED,
    SEARCH_VEHICLE_FAILURE,
    SEARCH_VEHICLE_REQUEST,
    SEARCH_VEHICLE_SUCCESS,
    UPDATE_CUSTOM_VEHICLE,
    VEHICLE_CHANGE_FAILURE,
    VEHICLE_CHANGE_SUCCESS,
    CHANGE_VEHICLE_TAB_CHANGED,
    UPDATE_VEHICLE_CUSTOM_DATA,
    SET_SEARCH_QUERY,
    SEARCH_STOCK_NUMBER_REQUEST,
    SEARCH_STOCK_NUMBER_SUCCESS,
    SEARCH_STOCK_NUMBER_FAILURE
} from './actions';
import { mapMakes, mapModels, mapTrims, mapYears } from '../utils/catalog';

export const changeVehicleInitialState: ChangeVehicleState = {
    query: '',
    isFetching: false,
    cviVehicles: undefined,
    searchQuery: undefined,
    isSearchError: false,
    showSearchError: false,
    showProcessingError: false,
    catalogYears: [],
    catalogMakes: [],
    catalogModels: [],
    catalogTrims: [],
    catalogVehicles: [],
    showCatalogError: false,
    showBuildVehicleError: false,
    vehicleCustomData: {},
    isStockNumberAvailable: undefined
};

export type ChangeVehicleReducer = Reducer<ChangeVehicleState>;

export const reducer: ChangeVehicleReducer = (state = changeVehicleInitialState, action: any) => {
    switch (action.type) {
        case NavigationActions.NAVIGATE_TO:
        case CHANGE_VEHICLE_CANCEL: {
            return changeVehicleInitialState;
        }
        case UPDATE_CUSTOM_VEHICLE: {
            return {
                ...state,
                customVehicle: action.payload
            };
        }
        case UPDATE_VEHICLE_CUSTOM_DATA: {
            const currentCustomData = state.vehicleCustomData || {};

            return {
                ...state,
                vehicleCustomData: {
                    ...currentCustomData,
                    [action.payload.vin]: action.payload.vehicleCustomData
                }
            };
        }
        case FETCH_CVI_REQUEST:
        case SEARCH_VEHICLE_REQUEST: {
            return {
                ...state,
                isFetching: true,
                cviVehicles: undefined,
                searchQuery: undefined,
                selectedVehicle: {},
                vehicleCustomData: {},
                showSearchError: false,
                showProcessingError: false
            };
        }
        case FETCH_CVI_SUCCESS:
        case SEARCH_VEHICLE_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                showSearchError: action.payload.length === 0 ? true : false
            };
        }
        case PROCESS_CVI_VEHICLES: {
            return {
                ...state,
                cviVehicles: action.payload
            };
        }
        case FETCH_CVI_FAILURE:
        case SEARCH_VEHICLE_FAILURE: {
            return {
                ...state,
                isFetching: false,
                showSearchError: true
            };
        }
        case SEARCH_STOCK_NUMBER_REQUEST: {
            return {
                ...state,
                isFetching: true,
                isStockNumberAvailable: undefined
            };
        }
        case SEARCH_STOCK_NUMBER_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                isStockNumberAvailable: action.payload.length === 0
            };
        }
        case SEARCH_STOCK_NUMBER_FAILURE: {
            return {
                ...state,
                isFetching: false,
                isSearchError: true,
                isStockNumberAvailable: false
            };
        }
        case FETCH_CATALOG_YEARS_REQUEST: {
            return {
                ...state,
                isFetching: true,
                showCatalogError: false,
                catalogYears: []
            };
        }
        case FETCH_CATALOG_YEARS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                showCatalogError: false,
                catalogYears: mapYears(action.payload)
            };
        }
        case FETCH_CATALOG_YEARS_FAILURE: {
            return {
                ...state,
                isFetching: false,
                showCatalogError: true
            };
        }
        case FETCH_CATALOG_MAKES_REQUEST: {
            return {
                ...state,
                isFetching: true,
                showCatalogError: false,
                catalogMakes: []
            };
        }
        case FETCH_CATALOG_MAKES_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                showCatalogError: false,
                catalogMakes: mapMakes(action.payload)
            };
        }
        case FETCH_CATALOG_MAKES_FAILURE: {
            return {
                ...state,
                isFetching: false,
                showCatalogError: true
            };
        }
        case FETCH_CATALOG_MODELS_REQUEST: {
            return {
                ...state,
                isFetching: true,
                showCatalogError: false,
                catalogModels: []
            };
        }
        case FETCH_CATALOG_MODELS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                showCatalogError: false,
                catalogModels: mapModels(action.payload)
            };
        }
        case FETCH_CATALOG_MODELS_FAILURE: {
            return {
                ...state,
                isFetching: false,
                showCatalogError: true
            };
        }
        case FETCH_CATALOG_TRIMS_REQUEST: {
            return {
                ...state,
                isFetching: true,
                showCatalogError: false,
                catalogTrims: []
            };
        }
        case FETCH_CATALOG_TRIMS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                showCatalogError: false,
                catalogTrims: mapTrims(action.payload)
            };
        }
        case FETCH_CATALOG_TRIMS_FAILURE: {
            return {
                ...state,
                isFetching: false,
                showCatalogError: true
            };
        }
        case FETCH_CATALOG_VEHICLES_REQUEST: {
            return {
                ...state,
                isFetching: true,
                showProcessingError: false,
                showCatalogError: false,
                catalogVehicles: [],
                showBuildVehicleError: false
            };
        }
        case FETCH_CATALOG_VEHICLES_SUCCESS: {
            const catalogVehicles = action.payload.map(catalogToVehicle).filter(({ chromeStyleId }: IVehicle) => chromeStyleId);

            return {
                ...state,
                isFetching: false,
                showCatalogError: false,
                catalogVehicles,
                showBuildVehicleError: !catalogVehicles.length
            };
        }
        case FETCH_CATALOG_VEHICLES_FAILURE: {
            return {
                ...state,
                isFetching: false,
                showCatalogError: true
            };
        }
        case CLEAR_CATALOG_VEHICLES: {
            return {
                ...state,
                isFetching: false,
                selectedVehicle: {},
                showProcessingError: false,
                showCatalogError: false,
                catalogVehicles: [],
                showBuildVehicleError: false
            };
        }
        case PROCESS_SWITCH_VEHICLE: {
            return {
                ...state,
                isChangeProcessing: true,
                showProcessingError: false
            };
        }
        case NEW_VEHICLE_SELECTED: {
            return {
                ...state,
                selectedVehicle: action.payload.vehicle
            };
        }
        case VEHICLE_CHANGE_SUCCESS: {
            return {
                ...changeVehicleInitialState,
                isChangeProcessing: false
            };
        }
        case VEHICLE_CHANGE_FAILURE: {
            return {
                ...state,
                isChangeProcessing: false,
                showProcessingError: true
            };
        }
        case SEARCH_VEHICLES_CLICKED: {
            return {
                ...state,
                selectedVehicle: {}
            };
        }
        case CHANGE_VEHICLE_TAB_CHANGED: {
            return {
                ...state,
                selectedVehicle: {},
                showCatalogError: false,
                showBuildVehicleError: false,
                showProcessingError: false
            };
        }
        case SET_SEARCH_QUERY: {
            return {
                ...state,
                searchQuery: action.payload.searchQuery
            };
        }
        default: {
            return state;
        }
    }
};
