import styled from 'styled-components';
import { BreakPoint } from '@makemydeal/dr-activities-common';

export const StyledDeskingActions = styled.div`
    display: flex;
    flex-direction: column-reverse;
    .desking-btn-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    .share-container-buttons {
        display: flex;
        flex-direction: column-reverse;
        gap: 16px;
        margin-bottom: 16px;
    }

    @media (min-width: ${BreakPoint.SMALL}) {
        margin-top: 16px;
        margin-bottom: 32px;
        flex-direction: row;

        .desking-btn-container {
            flex: 1;
            flex-direction: row;
        }

        .share-container-buttons {
            flex-direction: row;
        }
    }
`;
