// externals
import { Reducer } from 'redux';

// libraries
import { DealLifecycle } from '@makemydeal/dr-dash-bff-types';
import { DealLifecycle as DealLifecycleState, UpdateDealLifecycleStatus } from '@makemydeal/dr-dash-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';

// consts/enums
import {
    UPDATE_DEAL_LIFE_CYCLE_FAILURE,
    UPDATE_DEAL_LIFE_CYCLE_READY,
    UPDATE_DEAL_LIFE_CYCLE_REQUEST,
    UPDATE_DEAL_LIFE_CYCLE_SUCCESS
} from '../actionTypes/dealLifeCycleActionTypes';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export type DealLifecycleReducer = Reducer<DealLifecycle>;

export const UPDATE_DEAL_LIFECYCLE_INITIAL_STATE: DealLifecycleState = {
    updateDealLifecycleStatus: UpdateDealLifecycleStatus.Ready
};

export const reducer: DealLifecycleReducer = (state: Partial<DealLifecycle> = UPDATE_DEAL_LIFECYCLE_INITIAL_STATE, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...(action.payload.dealLifecycle || {})
            };
        }

        case UPDATE_DEAL_LIFE_CYCLE_SUCCESS: {
            return {
                ...state,
                ...action.payload,
                updateDealLifecycleStatus: UpdateDealLifecycleStatus.Success
            };
        }

        case UPDATE_DEAL_LIFE_CYCLE_REQUEST: {
            return {
                ...state,
                updateDealLifecycleStatus: UpdateDealLifecycleStatus.Sending
            };
        }

        case UPDATE_DEAL_LIFE_CYCLE_FAILURE: {
            return {
                ...state,
                updateDealLifecycleStatus: UpdateDealLifecycleStatus.Failure
            };
        }

        case UPDATE_DEAL_LIFE_CYCLE_READY: {
            return {
                ...state,
                updateDealLifecycleStatus: UpdateDealLifecycleStatus.Ready
            };
        }

        default: {
            return state;
        }
    }
};
