import { StateTree } from '@makemydeal/dr-dash-types';
import { dealerSelectors } from '@makemydeal/dr-shared-store';

/**
 * Add any necessary custom attributes needed to help segment Browser metrics in New Relic queries
 * @param state The state object as populated after the application has retrieved initial data & configuration
 */
export const setCustomAttributesFromState = (state: StateTree): void => {
    if (window.newrelic) {
        const mmdDealerId = dealerSelectors.getDealerId(state);
        window.newrelic.setCustomAttribute('customattr.coxauto:mmdDealerId', `${mmdDealerId}`);
    }
};
