import type { StateTree } from '@makemydeal/dr-dash-types';
import { ManualIncentiveEditEntry } from '@makemydeal/dr-platform-types';

export const getRootState = (state: StateTree) => state.manualIncentives ?? {};

export const getCurrentEditedRebateIncentivesTotal = (state: StateTree): number => {
    return getCurrentEditedNonDeletedRebateIncentives(state).reduce((acc, entry) => {
        acc += entry.amount;
        return acc;
    }, 0);
};

export const getCurrentEditedDealerCashIncentivesTotal = (state: StateTree): number => {
    return getCurrentEditedNonDeletedDealerIncentives(state).reduce((acc, entry) => {
        acc += entry.amount;
        return acc;
    }, 0);
};

export const getCurrentEditedNonDeletedIncentives = (state: StateTree) =>
    getManualIncentiveEditList(state).filter((e) => !e.deleted);

export const getCurrentEditedNonDeletedRebateIncentives = (state: StateTree) =>
    getCurrentEditedNonDeletedIncentives(state).filter((e) => e.type !== 'DealerCash');

export const getCurrentEditedNonDeletedDealerIncentives = (state: StateTree) =>
    getCurrentEditedNonDeletedIncentives(state).filter((e) => e.type === 'DealerCash');

export const getManualIncentivesShowValidation = (state: StateTree): boolean => Boolean(getRootState(state).showValidation);

export const getManualIncentiveEditList = (state: StateTree) => getRootState(state).manualIncentivesEdit ?? [];

export const getManualIncentiveEditEntryAtIndex = (index: number) => (state: StateTree) => {
    const list = getManualIncentiveEditList(state);
    if (list.length === 0) throw new Error('No manual incentive edits exist');
    return list[index];
};
export const getManualIncentiveEditValueAtIndexAndField =
    (index: number, field: keyof ManualIncentiveEditEntry) => (state: StateTree) => {
        const list = getManualIncentiveEditList(state);
        if (list.length === 0) throw new Error('No manual incentive edits exist');
        const row = list[index];
        return row[field];
    };

export const getManualIncentiveEditIsFromProgram = (index: number) => (state: StateTree) => {
    const { name, description } = getManualIncentiveEditEntryAtIndex(index)(state);
    return description && description !== name;
};

const isValueEmptyOrNull = (value: any) => value == null || (typeof value === 'string' && value.trim() === '');

/**
 * Check if field at index is empty. Returns true if empty.
 * @param index
 * @param field
 * @returns
 */
export const isManualIncentiveFieldEmptyAtIndex = (index: number, field: keyof ManualIncentiveEditEntry) => (state: StateTree) => {
    const value = getManualIncentiveEditValueAtIndexAndField(index, field)(state);
    return isValueEmptyOrNull(value);
};

/**
 * Counts how many fields are empty among the required ones in the manual incentives edit list. More than zero is invalid.
 * @param required list of required fields by name.
 * @returns
 */
export const getManualIncentivesTotalRequired = (required: string[]) => (state: StateTree) => {
    const list = getCurrentEditedNonDeletedIncentives(state);
    const errors = list.reduce((acc, entry, index) => {
        required.forEach((field) => {
            if (isValueEmptyOrNull((entry as any)[field])) {
                acc++;
            }
        });
        return acc;
    }, 0);
    return errors;
};

export const isManualFormOpen = (state: StateTree) => {
    return state.manualIncentives.isManualFormOpen;
};
