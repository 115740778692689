// utils
import { DealExchangeValidator } from '@makemydeal/dr-shared-ui-utils';
import { conditionMapper } from './conditionMapper';

// types
import { vehicleUtils } from '@makemydeal/dr-platform-shared';
import { CommonOrgDealerVehicle } from '@makemydeal/dr-shared-ui-utils';

export const cviToVehicle = (data: CommonOrgDealerVehicle) => {
    const condition = conditionMapper(data.condition);

    return {
        bodyStyle: data.bodyStyle,
        cityFuelEconomy: data.cityFuelEconomy,
        condition,
        cost: data.wholesalePrice,
        destinationCharge: data.destinationCharge,
        driveLine: DealExchangeValidator.driveLine(data.driveLine),
        engine: data.engine,
        engineSize: data.engineSize,
        exteriorColor: data.exteriorColor || data.exteriorColorFull,
        exteriorColorFull: data.exteriorColorFull,
        fuel: DealExchangeValidator.fuel(data.fuel)[0],
        highwayFuelEconomy: data.highwayFuelEconomy,
        imageUrl: data.imageUrl,
        initialRetailPrice: data.listedPrice,
        interiorColor: data.interiorColor || data.interiorColorFull,
        interiorColorFull: data.interiorColorFull,
        interiorMaterial: Array.isArray(data.interiorMaterial) ? data.interiorMaterial.join(', ') : data.interiorMaterial,
        inventoryDate: data.inventoryDate,
        invoiceAmount: data.invoicePrice,
        isCustom: !data.vin,
        listedPrice: data.listedPrice,
        location: data.location,
        make: data.make,
        mileage: data.odometer,
        model: data.model,
        notes: data.sellerComments,
        // options is an empty array because in acceleron it's also mapped as an empty array this should change if we change it in acceleron
        options: [],
        retailPrice: data.retailPrice,
        stockNumber: data.stockNumber,
        chromeStyleId: vehicleUtils.parseChromeStyleId(data.chromeId),
        transmission: data.transmissionFull?.primaryDescription || data.transmission,
        trim: data.trim,
        type: condition,
        // vehicleId: we overwrite this field because it's a uuid from previous vehicle
        vehicleId: '',
        vin: data.vin,
        year: data.year
    };
};
