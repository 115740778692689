import styled from 'styled-components';

export const StyledPushToRouteOneFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 20px 32px 32px;
    :first-child {
        margin-right: 5px;
    }
`;
