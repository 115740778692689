// externals
import { Box } from '@interstate/components/Box';
import { Typography } from '@interstate/components/Typography';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// types
import { EditProductTaxableProps } from '../../types';

// utils
import { ShowWithFeatureToggle } from '@makemydeal/dr-dash-components';

// styles
import { InfoRow, InfoText } from './EditProductInformation.style';

const EditProductTaxable = ({ productProps }: { productProps: EditProductTaxableProps }) => {
    const theme = useInterstateTheme();

    const taxableText = productProps.isProductTaxable ? 'Yes' : 'No';

    return (
        <>
            <ShowWithFeatureToggle
                name="enablePlanSelectionForVPP"
                active={
                    <>
                        <Box marginBottom="1rem">
                            <Typography variant="label-md" color="base.color.gray.600" data-testid={'product-taxable'}>
                                Taxable
                            </Typography>
                        </Box>
                        <Box marginBottom="1rem">
                            <Typography variant="body-md" data-testid={'product-taxable-value'}>
                                {taxableText}
                            </Typography>
                        </Box>
                    </>
                }
                inactive={
                    <Box marginBottom="1rem">
                        <InfoRow data-testid={'product-taxable'} theme={theme}>
                            <InfoText theme={theme}>Taxable: </InfoText>
                            <span data-testid={'product-taxable-value'}>{taxableText}</span>
                        </InfoRow>
                    </Box>
                }
            />
        </>
    );
};

export default EditProductTaxable;
