import styled from 'styled-components';

export const ResultsContainer = styled.div`
    margin-top: 16px;
`;

export const Card = styled.div`
    border: ${({ theme }) => `1px solid ${theme.tokens.BaseColorGray300}`};
    padding: 16px;
    background-color: white;
    cursor: pointer;
    margin-top: -1px;
    &.active {
        background-color: ${({ theme }) => `${theme.tokens.BaseColorBlue50}`};
        border-left-width: 4px;
        border-left-color: ${({ theme }) => `${theme.tokens.BaseColorBlue900}`};
        border-radius: 4px 0px 0px 0px;
    }
`;

export const NotInStockVehicleDataContainer = styled.div`
    padding-top: 16px;
`;
