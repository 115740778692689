import React, { useCallback, useEffect, useRef } from 'react';

export type useDebounceResult = [work: (args?: any[]) => void, cancel: () => void];

/**
 *  This function is a custom hook to debounce functions in React components.
 *  It can only be used at the top level of a React functional component.
 **/
export const useDebounce = (fnToDebounce: any, durationInMs = 2000, deps: Array<any> = []): useDebounceResult => {
    // return
    const callback = useCallback(fnToDebounce, [fnToDebounce, ...deps]);
    const timeoutRef = useRef<any>();

    // mimics the fnToDebounce signature
    const work = (...args: any[]) => {
        clearTimeout(timeoutRef.current); // clear every time
        timeoutRef.current = setTimeout(() => {
            callback(...args); // invoke fnToDebounce w/ args
        }, durationInMs);
    };

    const cancel = () => {
        clearTimeout(timeoutRef.current);
    };

    useEffect(() => {
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, []);

    return [work, cancel]; // exposing both
};

export default useDebounce;
