// TODO: SV - See what is common and keep it here and get Dash to also use this (for now it is duplicated).

// externals
import { Middleware, applyMiddleware, createStore, compose } from 'redux';
import { connectRouter } from 'connected-react-router';

// utils
import historyInstance from './historyInstance';
import { reducerRegistry } from './reducerRegistry';
import { getWindowObj } from '../utils/windowUtil';
// import { getModule } from '../utils/moduleUtil';
import { scopeMiddleware } from '../middleware/middlewareScopeWrapper';

// selectors
// import * as featureTogglSelectors from '../selectors/featureToggleSelectors';
// import { dealerSelectors } from '@makemydeal/dr-shared-store';

// TODO: DASH - fix this
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPreloadedState {}

// { router: connectRouter(history), ...reducers }

export function configureStore(
    preloadedState: IPreloadedState,
    initialMiddleware: Middleware<any, any, any>[],
    initialGlobalReducers: any,
    initialScopedReducers?: any,
    isScoped = false
) {
    reducerRegistry.reset();
    reducerRegistry.registerGlobal('router', connectRouter(historyInstance));
    reducerRegistry.registerMultipleGlobal(initialGlobalReducers);
    initialScopedReducers && reducerRegistry.registerMultipleScoped(initialScopedReducers);

    if (isScoped) {
        reducerRegistry.setScoped();
    }

    const allReducers = reducerRegistry.getReducers();

    const windowObj = getWindowObj();

    const reduxDevTools = typeof windowObj === 'object' && (windowObj as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

    const middlewares = isScoped ? initialMiddleware.map((m) => scopeMiddleware(m)) : initialMiddleware;

    const composeEnhancers = reduxDevTools
        ? (windowObj as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'Dash', trace: true, traceLimit: 100 })
        : compose;
    const store = createStore(allReducers, preloadedState, composeEnhancers(applyMiddleware(...middlewares)));
    reducerRegistry.setChangeListener((reducers) => {
        store.replaceReducer(reducers);
    });

    return store;
}
