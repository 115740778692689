// externals
import React from 'react';
import { useSelector } from 'react-redux';

// libraries
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { Box } from '@interstate/components/Box';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { offerInfoSelectors } from '@makemydeal/dr-dash-store';
import type { IOffer, IPencilOfferInputs } from '@makemydeal/dr-platform-types';

// components
import { OfferMessage } from '../offerMessage/OfferMessage.interstate';
import { OfferCardDetailsInterstate } from '../offerCardDetails/OfferCardDetails.interstate';
import { StyledOfferCardContent } from './OfferCardContent.style';
import { OfferCardDetailsAB } from '../offerCardDetails/OfferCardDetailsAB';
import { OfferCardBadges } from '../offerCardBadges/OfferCardBadges';

type OfferCardContentProps = {
    offer: IOffer;
};

export const OfferCardContent = ({ offer }: OfferCardContentProps) => {
    const theme = useInterstateTheme();
    const socketEventDealVersion = useSelector(offerInfoSelectors.getSocketEventDealVersion);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    const { offerType, offerSource, penciledInputs, messageToDealer, cmdMessageToShopper: dealerMessage } = offer;

    const { condition, make, model, year, trim } = offer.vehicle;
    const vehicleTitle = `${condition} ${year} ${make} ${model} ${trim}`;

    const { term, offerPrice, annualMiles } = (offerType && penciledInputs?.byOfferType?.[offerType]) ?? ({} as IPencilOfferInputs);
    const payment = term && offer.terms.byId[term];
    const { monthlyPayment, netCashDown, totalAllowance, months, apr, sellRate } = payment || {};

    const isAccepted = offer.accepted;
    const isUpdated = !isAccepted && offer.dealXgVersion === socketEventDealVersion;

    const offerCardContent = () => {
        return (
            <Grid gap={1} container flexDirection="column" minHeight="131px">
                <OfferCardBadges isAccepted={isAccepted} isUpdated={isUpdated} />

                {offerType && (
                    <OfferCardDetailsInterstate
                        offerType={offerType}
                        monthlyPayment={monthlyPayment}
                        netCashDown={netCashDown}
                        tradeAllowance={totalAllowance}
                        offerPrice={offerPrice}
                        months={months}
                        apr={apr}
                        sellRate={sellRate}
                    />
                )}

                <Box paddingTop={1}>
                    <OfferMessage shopperMessage={messageToDealer} dealerMessage={dealerMessage} offerSource={offerSource} />
                </Box>
            </Grid>
        );
    };

    const offerCardAbPencilContent = () => {
        return (
            <StyledOfferCardContent theme={theme}>
                <div className="offer-card__details">
                    {offerType && (
                        <OfferCardDetailsAB
                            offerType={offerType}
                            monthlyPayment={monthlyPayment}
                            netCashDown={netCashDown}
                            tradeAllowance={totalAllowance}
                            offerPrice={offerPrice}
                            months={months}
                            apr={apr}
                            vehicleTitle={vehicleTitle}
                            annualMiles={annualMiles}
                        />
                    )}
                </div>

                <div className="offer-card__message">
                    <OfferMessage shopperMessage={messageToDealer} dealerMessage={dealerMessage} offerSource={offerSource} />
                </div>
            </StyledOfferCardContent>
        );
    };

    return isABPencilEnabled ? offerCardAbPencilContent() : offerCardContent();
};
