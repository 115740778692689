// externals
import { Reducer } from 'redux';

// libraries
import type { ConsumerInsightsState } from '@makemydeal/dr-dash-types';

// consts/enums
import { CONSUMER_INSIGHTS_FETCH_SUCCESS, CONSUMER_INSIGHTS_FETCH_SUCCESS_V2 } from '../actionTypes/consumerInsightsActionTypes';

export type ConsumerInsightsReducer = Reducer<ConsumerInsightsState>;

export const reducer: ConsumerInsightsReducer = (state: ConsumerInsightsState = {}, action: any) => {
    switch (action.type) {
        case CONSUMER_INSIGHTS_FETCH_SUCCESS: {
            return action.payload.data.insights;
        }
        case CONSUMER_INSIGHTS_FETCH_SUCCESS_V2: {
            if (action.payload?.data?.consumerInsights) {
                const {
                    preferredDownPaymentHigh,
                    preferredDownPaymentLow,
                    preferredMonthlyPaymentHigh,
                    preferredMonthlyPaymentLow,
                    preferredOfferPriceHigh,
                    preferredOfferPriceLow
                } = action.payload.data.consumerInsights;
                return {
                    preferredDownHigh: preferredDownPaymentHigh,
                    preferredDownLow: preferredDownPaymentLow,
                    preferredMonthlyHigh: preferredMonthlyPaymentHigh,
                    preferredMonthlyLow: preferredMonthlyPaymentLow,
                    preferredPriceHigh: preferredOfferPriceHigh,
                    preferredPriceLow: preferredOfferPriceLow
                };
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
};
