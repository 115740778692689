import { SetStateAction, useEffect, useMemo, Dispatch, useState } from 'react';

import { ContainerGrid, ContainerGridItem } from '@makemydeal/dr-dash-components';
import { kebabCase } from '@makemydeal/dr-common-utils';

import { DatePickerEventValue } from '@interstate/components/DatePicker';
import { SelectInput } from '@interstate/components/SelectInput';
import { TextInput, TextInputEventValue, TextInputValue } from '@interstate/components/TextInput';
import { InterstateOnChangeCallback, InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { NumericInput } from '@interstate/components/NumericInput';
import { PostalCodeInput } from '@interstate/components/PostalCodeInput';

import {
    LIEN_HOLDER,
    LIEN_HOLDER_ACCOUNT_NUMBER,
    LIEN_HOLDER_ADDRESS_BANK,
    LIEN_HOLDER_ADDRESS_CITY,
    LIEN_HOLDER_ADDRESS_STATE,
    LIEN_HOLDER_ADDRESS_ZIP,
    PAYOFF_DUE_DATE,
    PER_DIEM
} from '../../../constants';
import { LienholderFormData } from './types';
import {
    extractValueFromDatePickerEvent,
    getStateCodesSelectInputValues,
    getRequiredErrorMessage,
    useHandlePerDiemChange
} from './utils';
import { StateCodes, LienholderAddressKeys } from './constants';
import { StyledDatePicker } from './LienholderForm.interstate.style';

export interface LienholderFormInputsProps {
    isTradeInCompleted: boolean;
    lienholderData: LienholderFormData;
    setLienholderData: Dispatch<SetStateAction<LienholderFormData>>;
    handleError: (state: boolean) => void;
}

const LienholderFormInputs = ({
    isTradeInCompleted,
    lienholderData,
    setLienholderData,
    handleError
}: LienholderFormInputsProps) => {
    const stateSelectInputValues = useMemo(getStateCodesSelectInputValues, StateCodes);

    const { lienholder, lienholderAccountNumber, lienholderAddress, payoffDueDate, perDiem } = lienholderData;

    const [lienholderErrors, setLienholderErrors] = useState<Record<string, boolean>>({});

    const onChange =
        <T extends TextInputEventValue | DatePickerEventValue>(
            field: keyof typeof lienholderData,
            valueExtractor?: (e: InterstateOnChangeEvent<T>) => number | Date | null
        ): InterstateOnChangeCallback<T> =>
        (e: InterstateOnChangeEvent<T>) => {
            const value = valueExtractor && valueExtractor(e);
            setLienholderData({
                ...lienholderData,
                [field]: valueExtractor ? value : e.target.value
            });
        };

    const onLienholderAddressChange = (field: keyof typeof lienholderAddress) => (e: InterstateOnChangeEvent<TextInputValue>) => {
        setLienholderData({
            ...lienholderData,
            lienholderAddress: {
                ...lienholderData.lienholderAddress,
                [field]: e.target.value
            }
        });
    };
    const { value: perDiemValue, handleChange: perDiemChange, handleBlur: perDiemBlur } = useHandlePerDiemChange(perDiem, onChange);

    const onPayoffDueDateChange = onChange<DatePickerEventValue>('payoffDueDate', (e) => extractValueFromDatePickerEvent(e));

    useEffect(() => {
        const hasErrors = Object.keys(lienholderErrors).length !== 0;
        handleError(hasErrors);
    }, [lienholderErrors, handleError]);

    useEffect(() => {
        const newErrors: Record<string, boolean> = {
            ...(!lienholderAccountNumber && { lienholderAccountNumber: true })
        };

        for (const key of LienholderAddressKeys) {
            if (!lienholderAddress[key]) {
                newErrors[key] = true;
            }
        }

        const hasAllErrors = Object.keys(newErrors).length === 5;

        if (hasAllErrors) {
            setLienholderErrors({});
        } else {
            setLienholderErrors(newErrors);
        }
    }, [lienholderAccountNumber, lienholderAddress]);

    const minPayoffDueDate = new Date();
    minPayoffDueDate.setDate(minPayoffDueDate.getDate() + 1);

    return (
        <>
            <ContainerGrid>
                <ContainerGridItem xs={6} md={4}>
                    <TextInput
                        data-testid="lienholder"
                        label={LIEN_HOLDER}
                        name={kebabCase(LIEN_HOLDER)}
                        onChange={onChange('lienholder')}
                        value={lienholder}
                        maxLength={50}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <TextInput
                        data-testid="lienholder-account-number"
                        className="LienholderForm-accountNumber"
                        label={LIEN_HOLDER_ACCOUNT_NUMBER}
                        name={kebabCase(LIEN_HOLDER_ACCOUNT_NUMBER)}
                        onChange={onChange('lienholderAccountNumber')}
                        value={lienholderAccountNumber}
                        hasError={'lienholderAccountNumber' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'lienholderAccountNumber')}
                        maxLength={20}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <StyledDatePicker
                        id="payoff-due-date-input"
                        data-testid="payoff-due-date-input"
                        label={PAYOFF_DUE_DATE}
                        value={payoffDueDate as Date}
                        onChange={onPayoffDueDateChange}
                        minDate={minPayoffDueDate}
                        errorMessage=""
                        hasError={false}
                        sx={{ minWidth: '10rem' }}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <NumericInput
                        inputPrefix={'$'}
                        autoInsertCommas
                        allowDecimal
                        decimalMaxLength={2}
                        decimalMinLength={2}
                        data-testid="per-diem"
                        label={PER_DIEM}
                        name={kebabCase(PER_DIEM)}
                        onChange={perDiemChange}
                        onBlur={perDiemBlur}
                        placeholder="0.00"
                        value={perDiemValue}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
            </ContainerGrid>
            <ContainerGrid>
                <ContainerGridItem xs={6} md={4}>
                    <TextInput
                        data-testid="lienholder-address-bank"
                        label={LIEN_HOLDER_ADDRESS_BANK}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_BANK)}
                        onChange={onLienholderAddressChange('address')}
                        value={lienholderAddress.address}
                        hasError={'address' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'address')}
                        maxLength={60}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={4}>
                    <TextInput
                        data-testid="lienholder-address-city"
                        label={LIEN_HOLDER_ADDRESS_CITY}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_CITY)}
                        onChange={onLienholderAddressChange('city')}
                        value={lienholderAddress.city}
                        hasError={'city' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'city')}
                        maxLength={30}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={2}>
                    <SelectInput
                        data-testid="lienholder-address-state"
                        label={LIEN_HOLDER_ADDRESS_STATE}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_STATE)}
                        options={stateSelectInputValues}
                        onChange={onLienholderAddressChange('stateProvince')}
                        value={lienholderAddress.stateProvince}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'stateProvince')}
                        hasError={lienholderErrors.stateProvince || false}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={2}>
                    <PostalCodeInput
                        data-testid="lienholder-address-zip"
                        label={LIEN_HOLDER_ADDRESS_ZIP}
                        country={'US'}
                        name={kebabCase(LIEN_HOLDER_ADDRESS_ZIP)}
                        onChange={onLienholderAddressChange('postalCode')}
                        value={lienholderAddress.postalCode}
                        hasError={'postalCode' in lienholderErrors}
                        errorMessage={getRequiredErrorMessage(lienholderErrors, 'postalCode')}
                        disabled={!isTradeInCompleted}
                    />
                </ContainerGridItem>
            </ContainerGrid>
        </>
    );
};

export default LienholderFormInputs;
