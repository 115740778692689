/* eslint-disable react/no-multi-comp */
// TODO: use centralized component between here and activities-desking
import { ComponentProps, FC, ReactNode } from 'react';

// components
import { ChevronRightIcon } from '@interstate/components/Icons/ChevronRightIcon';

// constants
import { ZERO_DOLLARS } from '../../constants';

// libraries
import { kebabCase } from '@makemydeal/dr-common-utils';
import { ExpandableArea, ExpandableAreaProvider, useExpandableArea } from '../../common/ExpandableArea';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { StyledExpandableAreaSection, StyledListItem } from './DealSummaryItem.style';
import { useSelector } from 'react-redux';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

export interface IDealSummaryItemProps {
    label: string;
    value: string;
    children?: ReactNode;
    expandibleButtonStyles?: ComponentProps<typeof Grid>['sx'];
    expandibleAreaStyles?: ComponentProps<typeof Grid>['sx'];
    labelStyles?: ComponentProps<typeof Typography>['sx'];
    valueStyles?: ComponentProps<typeof Typography>['sx'];
    /**
     * @deprecated Unused prop
     */
    showLargeValue?: boolean;
    isEmpty?: boolean;
    /**
     * @deprecated Unused prop
     */
    isExpanded?: boolean;
    hasDelta?: boolean;
}

type LineItemRowProps = {
    styles?: ComponentProps<typeof Grid>['sx'];
    children: ReactNode;
};

const LineItemRow = ({ children, styles }: LineItemRowProps) => (
    <Grid
        container
        sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingY: '12px',
            pr: '16px',
            ...styles
        }}
    >
        {children}
    </Grid>
);

export const ChevronIcon: FC<{ expandableId: string }> = ({ expandableId }) => {
    const { isExpanded } = useExpandableArea();
    const expanded = isExpanded(expandableId);

    return (
        <ChevronRightIcon
            style={{
                transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)'
            }}
            className="chevron-icon"
            data-testid={expandableId}
        />
    );
};

export const DealSummaryItem = ({
    label,
    value,
    children,
    isEmpty = false,
    hasDelta = false,
    expandibleButtonStyles,
    expandibleAreaStyles,
    labelStyles,
    valueStyles
}: IDealSummaryItemProps) => {
    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);

    const dealSummaryItemPrefix = 'deal-summary-item';
    const kebabLabel = kebabCase(label);
    const containerTestId = `${dealSummaryItemPrefix}-${kebabLabel}`;
    const labelId = `${dealSummaryItemPrefix}-label-${kebabLabel}`;
    const valueId = `${dealSummaryItemPrefix}-value-${kebabLabel}`;
    const className = isEmpty ? 'empty-item' : '';
    const expandableId = 'deal-summary-item-accordion';
    const isValueZero = isEnhancedDealDetailsEnabled || value !== ZERO_DOLLARS;

    if (isValueZero && children) {
        return (
            <StyledListItem className={`${className} ${hasDelta ? 'has-delta' : ''}`} hasDelta={hasDelta}>
                <ExpandableAreaProvider>
                    <StyledExpandableAreaSection expandableId={expandableId}>
                        <LineItemRow
                            styles={{
                                pl: '8px',
                                ...expandibleButtonStyles
                            }}
                        >
                            <ChevronIcon expandableId={expandableId} />
                            <Typography
                                tag="span"
                                sx={{
                                    flexGrow: 1,
                                    ...labelStyles
                                }}
                                variant="body-sm"
                                color="sem.color.on-surface.muted"
                                data-testid={containerTestId}
                            >
                                {label}
                            </Typography>
                            <Typography
                                tag="span"
                                variant="body-lg"
                                color="sem.color.on-surface.default"
                                data-testid={valueId}
                                sx={valueStyles}
                            >
                                {value}
                            </Typography>
                        </LineItemRow>
                    </StyledExpandableAreaSection>
                    <ExpandableArea data-testid={expandableId} expandableId={expandableId}>
                        <Grid
                            sx={{
                                pb: 2,
                                pr: 2,
                                pl: '26px',
                                ...expandibleAreaStyles
                            }}
                        >
                            {children}
                        </Grid>
                    </ExpandableArea>
                </ExpandableAreaProvider>
            </StyledListItem>
        );
    }

    return (
        <StyledListItem data-testid={containerTestId} hasDelta={hasDelta} className={`${className} ${hasDelta ? 'has-delta' : ''}`}>
            <LineItemRow styles={{ pl: '26px', ...expandibleButtonStyles }}>
                <Typography
                    tag="span"
                    variant="body-sm"
                    color="sem.color.on-surface.muted"
                    data-testid={labelId}
                    sx={{
                        flexGrow: 1,
                        ...labelStyles
                    }}
                >
                    {label}
                </Typography>
                <Typography
                    sx={valueStyles}
                    tag="span"
                    variant="body-lg"
                    color="sem.color.on-surface.default"
                    data-testid={valueId}
                >
                    {value}
                </Typography>
            </LineItemRow>
        </StyledListItem>
    );
};

export default DealSummaryItem;
