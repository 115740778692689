import { navigateTo } from '@makemydeal/dr-activities-common';
import { RouteValues } from '../routeConstants';

export const navigateToDashboard = () => navigateTo(RouteValues.DASHBOARD);

export const navigateToChangeVehicle = () => navigateTo(RouteValues.CHANGE_VEHICLE);

export const navigateToCreditBureau = () => navigateTo(RouteValues.CREDIT_BUREAU);

export const navigateToPrograms = () => navigateTo(RouteValues.PROGRAMS);

export const navigateToCreditDecision = () => navigateTo(RouteValues.CREDIT_DECISION);

export const navigateToDDJ = () => navigateTo(RouteValues.DDJ);
