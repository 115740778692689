/* istanbul ignore file */

/**
 * PURPOSE: This file is intended as a central place to handle client-side logging.  In future we may switch this over to another
 * method instead of using console directly.  Having all of these "console.xyz" calls in one place will allow for an easy switch.
 * Also, we can define a specific format for messages and define the contract for what needs to be included in a standard
 * client-side log message.
 */

/* eslint-disable no-console */
export const clientLogMessage = (message: string) => {
    console.log(message);
};

export const clientWarnMessage = (message: string) => {
    console.warn(message);
};

export const clientErrorMessage = (message: string) => {
    console.error(message);
};
