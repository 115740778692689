// istanbul ignore file

export type SendToCustomerDialogState = {
    sendEmailStatus: SendToCustomerDialogEmailStatus;
};

export enum SendDialogTypes {
    CreditApp = 'creditApp',
    Offer = 'offer',
    TestDrive = 'testDrive',
    DocUpload = 'docUpload'
}

export enum SendToCustomerDialogEmailStatus {
    Ready = 'ready',
    Sending = 'sending',
    Success = 'success',
    Failure = 'failure'
}

export enum ReadyToSendDialogStatus {
    Ready = 'ready',
    Sending = 'sending',
    Success = 'success',
    Failure = 'failure'
}
