import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@interstate/components/Typography';
import { FlexCol, FlexRow } from '@makemydeal/dr-dash-components';
import {
    ManualIncentiveEditEntry,
    manualIncentivesActionCreators,
    manualIncentivesSelectors,
    offerReduxSelectors,
    transformToManualEditIncentive
} from '@makemydeal/dr-dash-store';

import { FieldRow } from './FieldRow';
import { ManualRebatesFormContainer } from './RebatesEdit.style';

export const createNewManualIncentiveEntry = (): ManualIncentiveEditEntry => ({
    program: '',
    code: undefined,
    manual: true,
    name: '',
    description: '',
    type: 'CustomerCash',
    amount: 0,
    deleted: false,
    originalAmount: 0,
    isStandardIncentive: false
});

export const RebatesEdit = () => {
    const dispatch = useDispatch();
    const incentives = useSelector(offerReduxSelectors.getAppliedIncentives, isEqual);
    const incentivesToEdit = useSelector(manualIncentivesSelectors.getManualIncentiveEditList);

    // Reset the error count to 0 on component load.
    useEffect(() => {
        dispatch(manualIncentivesActionCreators.updateShowValidation(false));
        const init = incentives.map(transformToManualEditIncentive);
        if (init.length === 0) init.push(createNewManualIncentiveEntry());
        dispatch(manualIncentivesActionCreators.initManualIncentivesEdit(init));
        return () => {
            dispatch(manualIncentivesActionCreators.resetManualIncentivesEdit());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FlexCol gap="1rem">
            <FlexCol gap=".25rem">
                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                    Edit Incentives
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 400, fontSize: '.8rem', color: 'gray' }}>
                    Program number and code fields are optional.
                </Typography>
            </FlexCol>
            <ManualRebatesFormContainer id="manual-rebates-form-container">
                {incentivesToEdit.map((_incentive, index) => (
                    <FieldRow key={`field-row-${index}`} index={index} />
                ))}
            </ManualRebatesFormContainer>

            <FlexRow alignItems="center" justifyContent="end">
                <Typography variant="body-md">
                    <a
                        id="btn-manual-rebates-add-rebate"
                        onClick={() => {
                            dispatch(manualIncentivesActionCreators.updateShowValidation(false));
                            dispatch(manualIncentivesActionCreators.createManualIncentive(createNewManualIncentiveEntry()));
                        }}
                    >
                        + Add Incentive
                    </a>
                </Typography>
            </FlexRow>
        </FlexCol>
    );
};
