import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { OfferType } from '@makemydeal/dr-platform-types';

export const buildPaymentSummary = (
    offerType?: OfferType,
    months?: number,
    apr?: number,
    monthlyPayment?: number,
    offerPrice?: number,
    annualMiles?: number
) => {
    let summary = '';

    // offer type indicator
    if (offerType === 'finance') {
        summary += 'F';
    } else if (offerType === 'lease') {
        summary += 'L';
    } else if (offerType === 'cash') {
        summary += 'C';
    }

    // term
    if (offerType === 'finance' || offerType === 'lease') {
        summary += `${months}`;
    }

    // payment
    if (offerType === 'finance' || offerType === 'lease') {
        summary += ` - ${formatDollarsAndCents(monthlyPayment)}/mo`;
    } else if (offerType === 'cash') {
        summary += ` - ${formatDollarsAndCents(offerPrice)}`;
    }

    // APR finance only
    if (offerType === 'finance') {
        summary += ` @ ${apr}% APR`;
    }

    // annual milage lease only
    if (offerType === 'lease' && annualMiles) {
        summary += ` @ ${(annualMiles / 1000).toFixed(0)}k mi/yr`;
    }

    return summary;
};
