// external
import { Reducer } from 'redux';

// libraries
import type { RollbackState, StateTree } from '@makemydeal/dr-dash-types';
import { rollbackActionCreators, rollbackActionTypes } from '@makemydeal/dr-shared-store';

const { PREPARE_STATE_COPY } = rollbackActionTypes;

const initialState: RollbackState = {
    original: {} as StateTree
};

export type RollbackReducer = Reducer<RollbackState>;

export const reducer: Reducer<RollbackState> = (
    state: RollbackState = initialState,
    action: rollbackActionCreators.AllRollbackActions
) => {
    switch (action.type) {
        case PREPARE_STATE_COPY:
            return {
                original: JSON.parse(JSON.stringify(action.payload))
            };
        default:
            return state;
    }
};
