import { useEffect, useRef } from 'react';

export function useInterval(callback: () => void, delay: number | null, isIntervalActive: boolean): void {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current!();
        }

        if (delay !== null && isIntervalActive) {
            const id = setTimeout(tick, delay);
            return () => clearInterval(id);
        }
        return undefined;
    }, [delay, isIntervalActive]);
}
