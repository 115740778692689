import styled from 'styled-components';

import { getMaxAppContainerWidth } from '@makemydeal/dr-shared-ui-utils';

export const CreditBureauContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
`;

export const LenderDecisionContainer = styled.div`
    background-color: white;
    justify-content: center;
    margin-top: 1.25rem;
    width: 100%;
`;

export const CreditAndFinanceWrapper = styled.div`
    width: 100%;
    background-color: white;
    display: block;
    margin-top: 2rem;
`;

export const CreditAndFinanceTitle = styled.h1`
    text-align: initial;
    font-size: 28px;
    line-height: 115%;
    letter-spacing: -0.28px;
    font-weight: 700;
    margin: 0rem;
`;

export const TitleSeparator = styled.div`
    border-bottom: 0.0625rem solid #b2b2b2;
    margin-bottom: 1rem;
`;

export const CreditAndFinanceTitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CreditApplicant = styled.h2`
    text-align: initial;
    line-height: 115%;
    letter-spacing: -0.28px;
    font-weight: 700;
    margin: 0rem;
`;

export const LenderDecisionFooterButtonContainer = styled.div<{ isNewAppWidthEnabled: boolean }>`
    max-width: ${({ isNewAppWidthEnabled }) => getMaxAppContainerWidth(isNewAppWidthEnabled)};
    margin: 0 auto;
`;

export const LenderDecisionFooterContainer = styled.div`
    background: #ffffff;
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
`;
