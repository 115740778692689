// externals
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';
import { Box } from '@interstate/components/Box';
import { SelectInput, SelectInputEventValue } from '@interstate/components/SelectInput';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';

// components
import LabelWithToolTipIcon from '../form/LabelWithToolTipIcon';

// store
import { vehicleProtectionSelectors } from '@makemydeal/dr-dash-store';

// types
import { StateTree } from '@makemydeal/dr-dash-types';
import { EditProductInformationProps } from '../../types';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// styles
import {
    InfoRow,
    InfoText,
    ProductCost,
    ProductName,
    ProductNameContainer,
    IncludedAddOnsContainer,
    AddOnsTitle,
    AddOnsList
} from './EditProductInformation.style';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

const EditProductInformation = ({
    productProps,
    onProviderChange
}: {
    productProps: EditProductInformationProps;
    onProviderChange: (provider: string) => void;
}) => {
    const theme = useInterstateTheme();

    const providers = useSelector<StateTree, string[]>((state) => {
        if (productProps.productCategoryCode) {
            return vehicleProtectionSelectors.getVppCategoryProviders(state, productProps.productCategoryCode);
        }

        return [];
    });
    const isPlanSelectionForVppEnabled = useSelector(featureToggleSelectors.isPlanSelectionForVppEnabled);

    const includedAddOnsList = productProps.includedAddOnNames.map((item, index) => (
        <li key={index} data-testid={`included-add-on-${index}`}>
            {item}
        </li>
    ));

    const handleProviderChange = useCallback(
        (event: InterstateOnChangeEvent<SelectInputEventValue>) => {
            const providerName = event.target.value;

            onProviderChange(providerName as string);
        },
        [onProviderChange]
    );

    return (
        <div>
            <ProductNameContainer theme={theme}>
                <ProductName data-testid={'product-name'}>{productProps.productName ?? '--'}</ProductName>
                <ProductCost data-testid={'product-initial-cost'}>
                    {formatDollarsAndCents(productProps.productInitialCost)}
                </ProductCost>
            </ProductNameContainer>
            {includedAddOnsList[0] && (
                <IncludedAddOnsContainer data-testid="included-add-ons" theme={theme}>
                    <AddOnsTitle theme={theme}>Included Add-Ons:</AddOnsTitle>
                    <AddOnsList>{includedAddOnsList}</AddOnsList>
                </IncludedAddOnsContainer>
            )}
            {providers.length && isPlanSelectionForVppEnabled ? (
                <Box marginBottom="1rem">
                    <LabelWithToolTipIcon
                        name="productProviderName"
                        title="Provider"
                        toolTipContent="Once you change the Provider, we'll pre-select its most profitable Plan."
                    />
                    <SelectInput
                        data-testid="edit-product-provider"
                        displayLabel={false}
                        name="productProviderName"
                        onChange={handleProviderChange}
                        placeholder={productProps.productProviderName}
                        value={productProps.productProviderName}
                        // TODO: use providerCode as value
                        options={providers.map((providerName) => ({ value: providerName, label: providerName }))}
                        displayDeselectOption={false}
                    />
                </Box>
            ) : (
                <InfoRow data-testid={'product-provider'} theme={theme}>
                    <InfoText theme={theme}>Provider: </InfoText>
                    {productProps.productProviderName ?? '--'}
                </InfoRow>
            )}
        </div>
    );
};

export default EditProductInformation;
