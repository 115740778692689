import styled from 'styled-components';

export const StyledCarouselContainer = styled.div`
    width: 100%;
    position: relative;
`;

export const StyledLeftArrow = styled.div`
    position: absolute;
    top: calc(50% - 20px);
    left: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #b2b2b2;
    border-radius: 50%;
    z-index: 2;
    cursor: pointer;
`;
export const StyledRightArrow = styled.div`
    position: absolute;
    top: calc(50% - 20px);
    right: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px #b2b2b2;
    border-radius: 50%;
    cursor: pointer;
`;

export const StyledItemsContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    column-gap: 16px;

    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }

    & > :first-child {
        margin-left: 16px;
    }

    & > :last-child {
        margin-right: 16px;
    }
`;
