// externals
import type { Reducer } from 'redux';

// libraries
import { BootstrapLoadingState, SharedBootstrapState } from '@makemydeal/dr-shared-types';

// consts/enums
import * as bootstrapActionTypes from '../actionTypes/bootstrapActionTypes';
import { APPLICATION_LOAD_ERROR } from '../actionTypes/applicationLoadErrorActionTypes';
import { INIT_PENCIL_SUCCESS } from '../actionTypes/initActionTypes';

// interfaces/types
import type {
    AllSharedInitAppActions,
    BootstrapAppLoadErrorAction,
    BootstrapFailureAction
} from '../actions/bootstrapSharedActionCreators';

const { BOOTSTRAP_REQUEST, BOOTSTRAP_FAILURE } = bootstrapActionTypes;

export const BOOTSTRAP_INITIAL_STATE: SharedBootstrapState = {
    appStartTime: null,
    loadingState: BootstrapLoadingState.Loading,
    sessionId: null
};

export const reducer: Reducer<SharedBootstrapState> = (
    state: SharedBootstrapState = BOOTSTRAP_INITIAL_STATE,
    action: AllSharedInitAppActions
) => {
    switch (action.type) {
        case APPLICATION_LOAD_ERROR: {
            const actionTyped = action as BootstrapAppLoadErrorAction;
            return {
                ...state,
                loadingState: BootstrapLoadingState.LoadFailed,
                loadingErrorMessage: actionTyped.payload?.message || '',
                meta: null
            };
        }
        case BOOTSTRAP_REQUEST: {
            return { ...state, appStartTime: new Date(), meta: null, lazyInitCompleted: false };
        }
        case BOOTSTRAP_FAILURE: {
            const actionTyped = action as BootstrapFailureAction;
            const responsePayload = action.payload as any;
            const responseErrorMessage = responsePayload?.response?.message;

            return {
                ...state,
                loadingState: BootstrapLoadingState.LoadFailed,
                meta: {
                    ...actionTyped.meta,
                    responseErrorMessage
                }
            };
        }
        case INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                loadingState: BootstrapLoadingState.LoadedSuccessfully,
                meta: null
            };
        }
        default: {
            return state;
        }
    }
};
