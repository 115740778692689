// externals
import { useState, useEffect, JSX } from 'react';
import { useSelector } from 'react-redux';

// selector
import { offerInfoSelectors, pushToRouteOneSelectors } from '@makemydeal/dr-dash-store';

// ui components
import ModalLoading from './ModalLoading';

// internal library
import { useInterval } from './useInterval';
import { PushToRouteOneStatus } from '@makemydeal/dr-dash-types';

export interface IPushToRouteOneModalProps {
    show: boolean;
    onHide(): void;
    header: JSX.Element;
}

const PushToRouteOneModal = (props: IPushToRouteOneModalProps) => {
    // props
    const { show, onHide, header } = props;

    // selector
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const routeOne = useSelector(pushToRouteOneSelectors.getPushToRouteOneStatus);
    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);

    // states
    const [slow, setSlow] = useState(false);
    const [error, setError] = useState(false);
    const [waiting, setWaiting] = useState(show);
    const [success, setSuccess] = useState(false);
    const [isTimerActive, setIsTimerActive] = useState(true);
    const [saveOfferError, setSaveOfferError] = useState(false);

    useEffect(() => {
        if (offerIsBeingSaved === false && offerHasBeenSaved === false) {
            setSaveOfferError(true);
        }
    }, [offerIsBeingSaved, offerHasBeenSaved]);

    // setting states for waiting and error
    useEffect(() => {
        // initial loading state
        if (routeOne.status === PushToRouteOneStatus.Waiting) {
            setWaiting(true);
            setIsTimerActive(true);
            setError(false);
            // error state
        } else if (routeOne.status === PushToRouteOneStatus.Failure) {
            setWaiting(false);
            setIsTimerActive(false);
            setError(true);
            // success state
        } else if (routeOne.status === PushToRouteOneStatus.Success) {
            setWaiting(false);
            setIsTimerActive(false);
            setError(false);
            setSuccess(true);
        }
    }, [routeOne]);

    useInterval(
        () => {
            setSlow(true);
        },
        8000,
        isTimerActive
    );

    return (
        <>
            {waiting && <ModalLoading show={show} header={header} onHide={onHide} slow={slow} />}
            {success && <ModalLoading show={show} header={header} onHide={onHide} success={success} />}
            {error && <ModalLoading show={show} header={header} onHide={onHide} error={error} saveOfferError={saveOfferError} />}
        </>
    );
};

export default PushToRouteOneModal;
