import { rem } from 'polished';
import styled from 'styled-components';
import { Alert } from '@interstate/components/Alert';
import Button from '@cx/ui/Button';

export const LoaderWrapper = styled.div`
    display: flex;
    min-height: 110px;
    position: relative;
    justify-content: center;

    & #interstitial-overlay-root {
        background-color: transparent;
        min-width: ${rem(32)};
        min-height: ${rem(36)};
    }

    & #interstitial-content-root {
        min-width: 32px;
        max-width: 340px;
        width: unset;
        box-shadow: none;
    }
`;

export const StyledAlert = styled(Alert)`
    margin: ${rem(16)} 0 ${rem(8)} 0;
`;

export const StyledButton = styled(Button)`
    margin-right: ${rem(12)};
`;

export const StyledPushToDarwinFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 20px 32px 32px;
`;
