import { AnyFSA, FeatureToggles } from '@makemydeal/dr-platform-shared';
import { Middleware, MiddlewareAPI } from 'redux';

import { bootstrapActionTypes } from '@makemydeal/dr-shared-store';
import { configSelectors } from '@makemydeal/dr-dash-store';
import { StateTree } from '@makemydeal/dr-dash-types';
import { payloadUtils } from '@makemydeal/dr-shared-ui-utils';

import { ConfigChangeVehicle, MiddlewareManager } from '@makemydeal/dr-activities-change-vehicle';

// config
import { configureChangeVehicle } from './config';

// keep a cached version of the middleware once we're able to build it.
// exported to support testing
export let cachedChangeVehicleMiddlewareMgr: MiddlewareManager | undefined;

// needed for testing
export const changeVehicleMiddlewareClearCache = () => (cachedChangeVehicleMiddlewareMgr = undefined);

export const changeVehicleMiddleware: Middleware<unknown, StateTree> =
    (store: MiddlewareAPI) => (next: any) => (action: AnyFSA) => {
        switch (action.type) {
            case bootstrapActionTypes.BOOTSTRAP_SUCCESS: {
                // get what we can from state.  This typically will be the bff endpoint only since its placed as part of initial state
                const state = store.getState();
                const bff = configSelectors.getServicesBff(state);
                const featureToggles: FeatureToggles = payloadUtils.getFeatureTogglesFromInitPayload(action.payload);

                const initConfig: ConfigChangeVehicle = {
                    services: {
                        bff
                    },
                    featureToggles
                };

                cachedChangeVehicleMiddlewareMgr = configureChangeVehicle(initConfig);

                break;
            }
        }

        if (cachedChangeVehicleMiddlewareMgr) {
            cachedChangeVehicleMiddlewareMgr.middleware(store)(next)(action);
        } else {
            next(action);
        }
    };
