export const APP_NAME = 'desking';

export const CUSTOMER_DETAILS = 'Customer Details';
export const DEBOUNCE_TIMER = 2000;

// this is purposefully reusing /dashboard
export const DESKING_ROUTE_ROOT = '/dashboard';

export const DRAWER_DURATION = 400;
export const HEADER_HEIGHT = 96;
export const NOT_AVAILABLE = 'N/A';
export const NOT_AVAILABLE_DASH = '--';
export const PROFIT_DETAILS = 'Profit Details';
