import { AcceptedEnumsType, ConditionEnum, DrivetrainTypeEnum, FuelTypesEnum } from './types/vehicleTypes';

export * from './types/vehicleTypes';

const AcceptedEnums: AcceptedEnumsType = {
    condition: {
        map: {
            new: 'New',
            used: 'Used',
            demo: 'Demo',
            certifiedpreowned: 'CertifiedPreOwned',
            certified: 'CertifiedPreOwned',
            rental: 'Rental',
            consignment: 'Consignment',
            other: 'Other'
        },
        default: 'Used'
    },
    drivetrainType: {
        match: [
            {
                value: 'AllWheelDrive',
                test: (value) => /all.?wheel(.?drive)?/i.test(value) || /awd/i.test(value)
            },
            {
                value: 'FourWheelDrive',
                test: (value) => /(four|4).?wheel(.?drive)?/i.test(value) || /(4wd|4.4)/i.test(value)
            },
            {
                value: 'FrontWheelDrive',
                test: (value) => /front.?wheel(.?drive)?/i.test(value) || /(fwd|2.4)/i.test(value)
            },
            {
                value: 'RearWheelDrive',
                test: (value) => /rear.?wheel(.?drive)?/i.test(value) || /(rwd|4.2)/i.test(value)
            }
        ]
    },
    fuelTypes: {
        match: [
            { value: 'Gasoline', test: (value) => /gasoline/i.test(value) },
            { value: 'Diesel', test: (value) => /diesel/i.test(value) },
            { value: 'Electric', test: (value) => /electr/i.test(value) },
            { value: 'NaturalGas', test: (value) => /natural.?gas/i.test(value) || /cng/i.test(value) },
            { value: 'Hydrogen', test: (value) => /hydro/i.test(value) },
            { value: 'Other', test: (value) => /other/i.test(value) }
        ]
    }
};

export const DealExchangeValidator = {
    condition: (value?: string): ConditionEnum => {
        if (!value) return AcceptedEnums.condition.default;

        return AcceptedEnums.condition.map[value.toLowerCase()] || AcceptedEnums.condition.default;
    },
    fuel: (value?: string): FuelTypesEnum[] => {
        const result: FuelTypesEnum[] = [];
        if (typeof value !== 'string' || value === '') {
            return result;
        }

        AcceptedEnums.fuelTypes.match.forEach((T) => {
            if (T.test(value)) {
                result.push(T.value);
            }
        });

        return result;
    },
    driveLine: (value?: string): DrivetrainTypeEnum | undefined => {
        if (typeof value !== 'string' || value === '') {
            return undefined;
        }
        const { match } = AcceptedEnums.drivetrainType;
        for (let i = 0; i < match.length; i++) {
            if (match[i].test(value)) {
                return match[i].value;
            }
        }

        return undefined;
    }
};
