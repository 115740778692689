// libraries
import { FSA } from '@makemydeal/dr-platform-shared';
import type { StateTree } from '@makemydeal/dr-dash-types';
import { getDiffPropsFromObjects } from '@makemydeal/dr-common-utils';

import { SOCKET_EVENT_RECEIVED, SOCKET_EVENT_IGNORED } from '../actionTypes/socketActionTypes';
import { PERSONA_WHITE_LIST } from '../constants';
import { PUSH_TO_DMS_WS_EVENT } from '../actionTypes/pushToDmsActionTypes';

import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { updatePushToRouteOne } from './pushToRouteOneActionCreators';

export type EventReceived = FSA<string, any>;

export const dispatchEvent = (state: StateTree, event: any): EventReceived => {
    if (event.topic === 'pushToFI') {
        const { data } = event;

        if (event.data.eventTime && event.data.eventKeyData) {
            return updatePushToRouteOne({
                timestamp: data.eventTime,
                dealXgVersion: data.eventKeyData.dealXgDealVersion,
                message: data.payload.message,
                ok: data.eventName === 'Contracting:SavedSuccessfully'
            });
        }
    }

    const persona = event.data?.changedBy?.persona;

    const enableOptionalPbcUi = featureToggleSelectors.enableOptionalPbcUi(state);

    if (enableOptionalPbcUi && event.topic === 'mv_pushToDms' && !event.message?.includes('subscribe')) {
        if (event.data?._type === 'pushToDmsEvent') {
            return { type: PUSH_TO_DMS_WS_EVENT, payload: event };
        }
    }

    const personaValidated = PERSONA_WHITE_LIST.includes(persona?.toLowerCase());

    if (personaValidated) {
        const activityMilestones = state.activityMilestones;
        event.updatedMilestones = getDiffPropsFromObjects(event.data.dealProgressMilestones, activityMilestones);
        return { type: SOCKET_EVENT_RECEIVED, payload: event };
    }
    return { type: SOCKET_EVENT_IGNORED, payload: event };
};
