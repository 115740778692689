import React, { FC } from 'react';
import { DeleteField } from './DeleteField';
import { LabelRow } from './LabelRow';
import { MobileHr } from './RebatesEdit.style';
import { TextField } from './TextField';
import { TypeField } from './TypeField';
import { columnMappings } from './columnMappings';
import { AmountField } from './AmountField';

export const FieldRow: FC<{
    index: number;
}> = ({ index }) => {
    return (
        <>
            {index === 0 && <LabelRow columns={columnMappings} index={index} />}

            {columnMappings.map(({ fieldName }, columnIndex) => (
                <React.Fragment key={`field-${columnIndex}`}>
                    {fieldName === 'action' && <DeleteField index={index} />}
                    {fieldName === 'amount' && <AmountField index={index} />}
                    {fieldName !== 'action' && fieldName !== 'type' && fieldName !== 'amount' && (
                        <TextField index={index} field={fieldName} />
                    )}
                    {fieldName === 'type' && <TypeField index={index} field={fieldName} />}
                </React.Fragment>
            ))}

            <MobileHr />
        </>
    );
};
