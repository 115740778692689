export enum PushToDmsStatus {
    Ready = 'ready',
    Sending = 'sending',
    Failure = 'failure',
    Ok = 'ok'
}

export type PushToDmsState = {
    cdmsResponseData?: CdmsResponseData;
    error?: PushToDmsError;
    transferToDMS?: string;
    responseStatusCode: number;
    dealXgId?: string;
    dealXgVersion?: string;
    pushToDmsWsEvent?: PushToDmsWsEvent;
    lastSucceedDmsDealId?: string;
    lastSucceedDmsTime?: Date;
    cdmsStorefrontErrorMessage?: string;
};

type PushToDmsEventKeyData = {
    dealXgDealId: string;
    dealXgDealVersion: string;
    sourcePartnerDealerId: string;
    sourcePartnerId: string;
};

export type PushToDmsWsEvent = {
    _type: 'pushToDmsEvent';
    eventId?: string;
    eventIdentityId?: string;
    eventKeyData: PushToDmsEventKeyData;
    eventTime: { $date: string };
    eventName?: string;
    eventSource?: string;
    eventTransactionId?: string;
    eventType?: string;
    eventVersion: string;
    payload?: PushToDmsWsEventPayload;
};

type PushToDmsWsEventPayload = {
    dms_deal_id?: string;
    dms_type?: string;
    message?: string;
};

export type PushToDmsError = {
    errorDetails?: string;
    statusCode: number;
};

export type CdmsResponseData = {
    message?: CdmsResponseMessageData;
    status?: string;
    action?: string;
    version?: string;
    dataEntryURL?: string;
    error?: string;
    internalUse?: {
        allInfoReceived?: boolean;
        processed?: boolean;
        putInQueue?: boolean;
    };
    deal?: string;
};

export type CdmsResponseMessageData = {
    message?: string;
    buyerId?: string;
    cobuyerId?: string;
    deal?: string;
    dmsDealId?: string;
    version?: string;
};
