// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { initActionTypes } from '@makemydeal/dr-shared-store';

// actions
import { fetchConsumerInsights } from '../actions/consumerInsightsActionCreators';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

const { INIT_SUCCESS } = initActionTypes;

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    const state = store.getState();

    switch (action.type) {
        case INIT_SUCCESS: {
            store.dispatch(fetchConsumerInsights(state));
            break;
        }
        default:
            break;
    }
};
