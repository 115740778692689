// components
import { EditedTaxFieldInputContainer } from './EditedTaxField.style';

// actions
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { TaxTotalAmountFieldProps } from '../types';
import { useEditableProp } from './helpers';
import { PriceInput } from '@interstate/components/PriceInput';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';

const InputLabelStyle = {
    '.ids-input-component-label': {
        justifyContent: 'end !important'
    }
};

const MAX_VAL = 999999.99;

export default function TaxItemMonthlyTaxField<Props extends TaxTotalAmountFieldProps>({ data, handleBlur }: Props) {
    const mut = useSelector(offerReduxSelectors.getMonthlyUseTax);
    const initialMonthlyTax = data.monthlyTax || mut;

    const formattedMonthlyTax = formatDollarsAndCents(initialMonthlyTax);
    const [monthlyTax, setMonthlyTax, isEdited] = useEditableProp(formattedMonthlyTax, data.manualChanges?.taxAmount);

    const handleBlurWrapper = (e: InterstateOnChangeEvent<TextInputEventValue>) => {
        if (!monthlyTax && !initialMonthlyTax) {
            setMonthlyTax('0');
            return;
        }
        handleBlur(e);
    };
    return (
        <EditedTaxFieldInputContainer $isEdited={isEdited}>
            <PriceInput
                allowDecimal={true}
                allowNegative={false}
                autoInsertCommas={true}
                data-testid="monthly-tax-item-input"
                decimalMaxLength={2}
                id="monthly-tax-item-input"
                label="Monthly Tax"
                maxValue={MAX_VAL}
                name="monthly-tax-item-summary"
                onBlur={handleBlurWrapper}
                onChange={(e) => setMonthlyTax(e.target.value)}
                sx={InputLabelStyle}
                textAlign="right"
                value={monthlyTax}
            />
        </EditedTaxFieldInputContainer>
    );
}
