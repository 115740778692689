// consts/enums
import * as apiConstants from '../consts/apiConfigConsts';

// interfaces/types
import { EnvironmentType } from '../hostUrlHelper/apiConfigHelperTypes';

const { DEAL_EXCHANGE_ID, COMMON_CONSUMER_ID_PARAM, COMMON_ORG_ID_PARAM } = apiConstants;

export const SELECTED_OFFER_QS_PROPS = [DEAL_EXCHANGE_ID, COMMON_ORG_ID_PARAM, COMMON_CONSUMER_ID_PARAM];

export function getSessionValue(key: string) {
    return window.sessionStorage.getItem(`cmd_oid_${key.toLowerCase().trim()}`);
}

export function setSessionValue(key: string, value: string) {
    return window.sessionStorage.setItem(`cmd_oid_${key.toLowerCase().trim()}`, value);
}

export function deleteSessionValue(key: string) {
    return window.sessionStorage.removeItem(`cmd_oid_${key.toLowerCase().trim()}`);
}

export type OfferIdentifiers = {
    dealXgId?: string;
    dealXgVersion?: string;
};

export function getAdditionalToggles(): { [key: string]: string | null } {
    const keys = getSessionValue('unique')?.split(',') || [];
    const values: { [key: string]: string | null } = {};
    if (keys.length) {
        keys.forEach((k) => (values[k] = getSessionValue(k)));
    }
    return values;
}

export function getOfferIdentifiers(): OfferIdentifiers {
    const result: any = {};
    SELECTED_OFFER_QS_PROPS.forEach((prop) => {
        // reload the value
        const value = getSessionValue(prop);
        if (value != null) {
            result[prop] = value as string;
        }
    });

    return result as OfferIdentifiers;
}

export function getBffEnvOverride(bffEnvParamName: string) {
    return getSessionValue(bffEnvParamName.trim().toLowerCase());
}

export function getDashCssOverride() {
    return getSessionValue(apiConstants.DASH_CSS);
}

export function hasDashCssOverrideValue(value: string) {
    const dashCssOverride = getDashCssOverride();
    if (!dashCssOverride) {
        return false;
    }
    const dashCssValues = dashCssOverride.split(',');
    const matchingItems = dashCssValues.filter((queryParamValue) => queryParamValue === value);
    return matchingItems.length > 0;
}

export function parseAndWriteOfferIdentifiers(href: string): void {
    const url = new URL(href);

    SELECTED_OFFER_QS_PROPS.forEach((prop) => {
        const value = url.searchParams.get(prop);
        if (value) {
            deleteSessionValue(prop);
            setSessionValue(prop, value as string);
        }
    });
}

export function parseAndWriteAdditionalToggles(href: string, options: { whitelist?: string[] } = {}) {
    const url = new URL(href);
    const { whitelist } = options;

    // NOTE: setup a list of regex checks for keys we want to ignore (known keys)
    const ignores = SELECTED_OFFER_QS_PROPS.map((k) => new RegExp(`^${k}$`, 'i'));

    const check = (key: string): string | undefined => {
        // keeping this within a sync function
        for (let ix = 0; ix < ignores.length; ix++) {
            if (ignores[ix].test(key)) return undefined; // whole word, case insensitive check
        }

        // if the key isn't whitelisted,return false
        if (whitelist == null || !whitelist.length) return undefined;

        // keeping this within a sync function
        for (let jx = 0; jx < whitelist.length; jx++) {
            if (new RegExp(`^${whitelist[jx]}$`, 'i').test(key)) return whitelist[jx]; // return the original whitelisted key (case-sensitive)
        }

        return undefined;
    };

    const unique: string[] = [];
    url.searchParams.forEach((value, key) => {
        const checkedKey = check(key);
        if (checkedKey != null) {
            unique.push(checkedKey);
            setSessionValue(checkedKey, value);
        }
    });

    if (unique.length) setSessionValue('unique', unique.join(','));
}

export function getCommonConsumerId(): string {
    return getSessionValue(COMMON_CONSUMER_ID_PARAM) || '';
}

export function setCommonConsumerId(value: string): void {
    setSessionValue(COMMON_CONSUMER_ID_PARAM, value);
}

export function setCommonOrgId(value: string): void {
    setSessionValue(COMMON_ORG_ID_PARAM, value);
}

export function getCommonOrgId(): string {
    return getSessionValue(COMMON_ORG_ID_PARAM) || '';
}

export function getDealXgId(): string {
    return getSessionValue(DEAL_EXCHANGE_ID) || '';
}

export function identifyEnvironment(environmentName: string): string {
    return environmentName.toLowerCase().includes('prod') ? 'prod' : 'nonprod';
}

export function setEnvironmentType(environmentName: string): void {
    setSessionValue(apiConstants.ENVIRONMENT_TYPE, environmentName);
}

export function getEnvironmentType(): EnvironmentType {
    const environmentType = (getSessionValue(apiConstants.ENVIRONMENT_TYPE) as EnvironmentType) || 'nonprod';
    return environmentType;
}

export function getTarget(): string {
    return getSessionValue(apiConstants.TARGET) || '';
}
