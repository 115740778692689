import React, { PropsWithChildren, useContext } from 'react';
import mitt, { Emitter } from 'mitt';

const caiEventBus = mitt();
(window as { [key: string]: any })['caiEventBus'] = caiEventBus;

export interface MittContextType {
    emitter: Emitter<Record<any, unknown>>;
}

export type DrCheckOutLauncherProps = PropsWithChildren<object>;
const MittContext = React.createContext<MittContextType>({ emitter: caiEventBus });

export const MittProvider: React.FC<DrCheckOutLauncherProps> = ({ children }) => (
    <MittContext.Provider value={{ emitter: caiEventBus }}>{children}</MittContext.Provider>
);

export const useMitt = (): MittContextType => useContext(MittContext);
