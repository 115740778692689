export const tableAlertMessages = {
    hasInapplicableRateAttribute: 'The condition for this product is expired, please edit the product to fix it.',
    hasInapplicableRatePlan: 'The plan/coverage for this product is expired, please edit the product to change it.',
    missingRequiredAttributes:
        'There are some missed deal attributes for the product. Please review to ensure all fields are correct.',
    newProductFromFie: 'Newly added product that can be sold.',
    paymentFailed: "A payment can't be calculated for this product.",
    productDisappeared: 'The product is no longer available.',
    productWithoutRates: "The product can't be added based on selected conditions.",
    selectedProductCostChanged: 'The product cost has been changed by a provider or based on selected attributes.'
};
