// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import type { RSAACallAction, RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';

// consts
import { BRIDGE_USER_FAILURE, BRIDGE_USER_REQUEST, BRIDGE_USER_SUCCESS } from '../actionTypes/bridgeUserActionTypes';

export const fetchBridgeUserInfo = (environment: 'prod' | 'qa', sessionId: string): RSAACallAction => {
    const endpoint = `https://signin.coxautoinc.com/bridgebar/user-details/mine?solutionId=CMDMMD&bridgeEnv=${environment}&sessionId=${sessionId}&partition=${
        environment === 'prod' ? 'PROD' : 'NON_PROD'
    }`;
    const request: RSAACallRequest & {
        credentials: 'include' | 'omit' | 'same-origin';
    } = {
        endpoint,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        types: [BRIDGE_USER_REQUEST, BRIDGE_USER_SUCCESS, BRIDGE_USER_FAILURE]
    };

    return {
        [RSAA]: request
    } as RSAACallAction;
};
