import { FlexCol } from '@makemydeal/dr-dash-components';
import { IncentivesCard } from './IncentivesCard';
import { Programs } from './Programs';
import { ProgramsAndIncentivesHeader } from './ProgramsAndIncentivesHeader';

export const ProgramsAndIncentives = () => {
    return (
        <FlexCol gap="1rem">
            <ProgramsAndIncentivesHeader />
            <IncentivesCard />
            <Programs />
        </FlexCol>
    );
};
