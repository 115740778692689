import { Reducer } from 'redux';
import { createRootScopedReducer } from '../reducers/rootScopeReducer';
import { appReducersWithRollback } from './configureStoreUtils';

export type ReducerRegistryReducer = any;
export type ReducerRegistryReducers = { [name: string]: ReducerRegistryReducer };
export type ReducerRegistryChangeListener = { (reducers: Reducer<any, any>): void };

export class ReducerRegistry {
    private _emitChange: ReducerRegistryChangeListener | null;
    private _globalReducers: ReducerRegistryReducers;
    private _scopedReducers: ReducerRegistryReducers;
    private _useScopedMode: boolean = false;

    constructor() {
        this._emitChange = null;
        this._globalReducers = {};
        this._scopedReducers = {};
    }

    setScoped = () => {
        this._useScopedMode = true;
    };

    reset() {
        this._emitChange = null;
        this._globalReducers = {};
        this._scopedReducers = {};
        this._useScopedMode = false;
    }

    getGlobalReducers() {
        return { ...this._globalReducers };
    }

    getScopedReducers() {
        return { ...this._scopedReducers };
    }

    getReducers() {
        if (this._useScopedMode) {
            return createRootScopedReducer(this.getGlobalReducers(), this.getScopedReducers());
        }
        return appReducersWithRollback({
            ...this.getGlobalReducers(),
            ...this.getScopedReducers()
        });
    }

    registerGlobal(name: string, reducer: ReducerRegistryReducer) {
        this.registerGlobalWithoutEmit(name, reducer);
        this.notifyListeners();
    }

    registerScoped(name: string, reducer: ReducerRegistryReducer) {
        this.registerScopedWithoutEmit(name, reducer);
        this.notifyListeners();
    }

    registerMultipleGlobal(reducers: { [name: string]: ReducerRegistryReducer }) {
        this.registerMultiple(reducers, true);
    }
    registerMultipleScoped(reducers: { [name: string]: ReducerRegistryReducer }) {
        this.registerMultiple(reducers, false);
    }

    setChangeListener(listener: ReducerRegistryChangeListener) {
        this._emitChange = listener;
    }

    private registerGlobalWithoutEmit = (name: string, reducer: ReducerRegistryReducer) => {
        this._globalReducers = { ...this._globalReducers, [name]: reducer };
    };

    private registerScopedWithoutEmit = (name: string, reducer: ReducerRegistryReducer) => {
        this._scopedReducers = { ...this._scopedReducers, [name]: reducer };
    };

    private notifyListeners() {
        if (this._emitChange) {
            this._emitChange(this.getReducers());
        }
    }

    private registerMultiple(reducers: { [name: string]: ReducerRegistryReducer }, global: boolean) {
        const registerWithoutEmit = global ? this.registerGlobalWithoutEmit : this.registerScopedWithoutEmit;
        if (reducers) {
            let itemsProcessed = 0;
            Object.keys(reducers).forEach((name: string) => {
                itemsProcessed++;
                registerWithoutEmit(name, reducers[name]);
            });
            if (itemsProcessed > 0) {
                this.notifyListeners();
            }
        }
    }
}

export const reducerRegistry = new ReducerRegistry();
