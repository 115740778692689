import ToastContainer from '@cx/ui/Toast';
import React from 'react';
import { ToastContainerWrapper } from './NotificationContainer.style';

// to display a notification just import { toast } from "@cx/ui/Toast" and use its methods: info, error, warning....
// for more details, visit CX components documentation.

export const NotificationContainer = () => {
    return (
        <ToastContainerWrapper>
            <ToastContainer />
        </ToastContainerWrapper>
    );
};
