// externals
import { useSelector } from 'react-redux';

// selectors
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { DeskingActivityDealSingle } from './DeskingActivityDealSingle';
import { DeskingActivityDealMultiple } from './DeskingActivityDealMultiple';

export const DeskingActivityDeal = () => {
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    if (isABPencilEnabled) {
        return <DeskingActivityDealMultiple />;
    }

    return <DeskingActivityDealSingle />;
};
