import { Dispatch, FC, memo, SetStateAction, useCallback, useRef } from 'react';

import { ManualIncentiveEditEntry } from '@makemydeal/dr-dash-store';

import { ManualRebatesFormContainer } from './RebatesEdit.interstate.styles';
import { RebatesTableRow } from './RebatesTableRow.interstate';

export interface ManualRebatesForm {
    incentivesToEdit: ManualIncentiveEditEntry[];
    term: number | undefined;
    haveIncentivesUpdated: boolean;
    editedIncentiveIndex: number;
    setEditedIncentiveIndex: (value: number) => void;
    setHaveIncentivesUpdated: (value: boolean) => void;
    setHasMessageErrors: (value: boolean) => void;
    setIncentivesToEdit: Dispatch<SetStateAction<ManualIncentiveEditEntry[]>>;
}

export const ManualRebatesForm = memo(function ManualRebatesForm({
    incentivesToEdit,
    term,
    haveIncentivesUpdated,
    editedIncentiveIndex,
    setHaveIncentivesUpdated,
    setEditedIncentiveIndex,
    setHasMessageErrors,
    setIncentivesToEdit
}: ManualRebatesForm) {
    const codeCellErrorIndexesRef = useRef<Set<number>>(new Set());
    const codeCellErrorIndexes = codeCellErrorIndexesRef.current;

    const onInputChange = useCallback(
        (textValue: string | number | undefined, fieldName: keyof ManualIncentiveEditEntry, incentiveIndex: number) => {
            setEditedIncentiveIndex(incentiveIndex);

            setHasMessageErrors(false);
            setHaveIncentivesUpdated(false);
            setIncentivesToEdit((incentivesToEdit) =>
                incentivesToEdit.map((currentIncentive, currentIndex) => {
                    if (incentiveIndex !== currentIndex) {
                        return currentIncentive;
                    }

                    return {
                        ...currentIncentive,
                        [fieldName]: textValue,
                        manual: currentIncentive.manual || currentIncentive.originalAmount !== textValue
                    };
                })
            );
        },
        [setEditedIncentiveIndex, setHaveIncentivesUpdated, setIncentivesToEdit, setHasMessageErrors]
    );

    const handleIncentiveDeleteOrUndo = useCallback(
        (index: number) => {
            setEditedIncentiveIndex(index);
            setHasMessageErrors(false);
            setHaveIncentivesUpdated(false);
            setIncentivesToEdit((incentivesToEdit) =>
                incentivesToEdit.map((incentive, currentIndex) => {
                    if (currentIndex === index) {
                        return { ...incentive, deleted: !incentive.deleted };
                    }
                    return incentive;
                })
            );
        },
        [setEditedIncentiveIndex, setHaveIncentivesUpdated, setIncentivesToEdit, setHasMessageErrors]
    );

    const getIsUniqueCode = useCallback(
        (currentIndex: number, currentCode: number | undefined) =>
            !incentivesToEdit.find(
                (incentive, index) =>
                    index !== currentIndex && incentive.code && incentive.code == currentCode && !incentive.deleted
            ),
        [incentivesToEdit]
    );

    return (
        <>
            <ManualRebatesFormContainer>
                {incentivesToEdit.map((incentive, index: number) => (
                    <RebatesTableRow
                        key={`incentive-row-${index}`}
                        term={term}
                        incentive={incentive}
                        incentiveIndex={index}
                        isEditing={index === editedIncentiveIndex}
                        haveIncentivesUpdated={haveIncentivesUpdated}
                        codeCellErrorIndexes={codeCellErrorIndexes}
                        updateHasMessageErrors={setHasMessageErrors}
                        handleIncentiveDeleteOrUndo={handleIncentiveDeleteOrUndo}
                        isUniqueCode={getIsUniqueCode(index, incentive.code)}
                        handleInputChange={onInputChange}
                    />
                ))}
            </ManualRebatesFormContainer>
        </>
    );
});
