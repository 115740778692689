const locale = 'en-US';

const localeDateString = (date: Date, options: Intl.DateTimeFormatOptions): string => {
    return date.toLocaleDateString(locale, options);
};

export const shortDateString = (date: Date): string => {
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' } as Intl.DateTimeFormatOptions;
    return localeDateString(date, options);
};
