// externals
import React from 'react';

// libraries
import { Root } from '@makemydeal/dr-activities-change-vehicle';

// consts/enums
import { CHANGE_VEHICLE_ROUTE_ROOT } from '../constants';

const ChangeVehicleActivity = () => {
    return <Root baseRoute={CHANGE_VEHICLE_ROUTE_ROOT} />;
};

export default ChangeVehicleActivity;
