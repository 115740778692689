// externals
import { ShowWithFeatureToggle } from '@makemydeal/dr-dash-components';

// types
import { EditProductPlanProps } from '../../types';

// components
import { EditProductPlan } from './EditProductPlan';
import { ShowProductPlan } from './ShowProductPlan';

const EditOrShowProductPlan = ({
    productProps,
    onEdit
}: {
    productProps: EditProductPlanProps;
    onEdit: (productCode: string, productPlan: string) => void;
}) => {
    const planDisplayText = productProps.productProviderPlan ?? '--';

    return (
        <ShowWithFeatureToggle
            name="enablePlanSelectionForVPP"
            active={<EditProductPlan planDisplayText={planDisplayText} productProps={productProps} onPlanEdit={onEdit} />}
            inactive={<ShowProductPlan planDisplayText={planDisplayText} />}
        />
    );
};

export default EditOrShowProductPlan;
