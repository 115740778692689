// consts/enums
import { NAME } from '../common/taxesAndFeesConsts';

// interfaces/types
import { SimpleTableHeadColumn } from './Taxes/TableHead';

export const ADDITIONAL_CLASS = 'additional';
export const PRIMARY_CLASS = 'primary';
export const ADVANCED_TAX = 'advanced';
export const CAPITALIZED_TAXES_CATEGORY = 'Capitalized Taxes';
export const CITY_TAX_NAME = 'City';
export const COUNTY_TAX_NAME = 'County';
export const CRT_TAX = 'crt';
export const FLAT_TAX = 'flat';
export const LOCAL_TAX_NAME = 'Local';
export const MONTHLY_USE_TAXES_CATEGORY = 'Monthly Use Taxes';
export const MUT_TAX = 'mut';
export const STATE_TAX_NAME = 'State';
export const TAX_OVERRIDE = 'Tax Override';
export const TAX_OVERRIDE_DEBOUNCE_TIMER = 1200;
export const RATE = 'Rate';
export const TAX_AMOUNT = 'Tax Amount';
export enum TAX_ITEM_KEYS {
    STATE_TAX_RATE = 'stateTaxRate',
    STATE_TAX = 'stateTax',
    COUNTY_TAX_RATE = 'countyTaxRate',
    COUNTY_TAX = 'countyTax',
    CITY_TAX_RATE = 'cityTaxRate',
    CITY_TAX = 'cityTax',
    LOCAL_TAX_RATE = 'localTaxRate',
    LOCAL_TAX = 'localTax'
}

export const TAXTABLECOLUMNS: SimpleTableHeadColumn[] = [
    { heading: NAME, options: { align: 'left' } },
    { heading: RATE, options: { align: 'center' } },
    { heading: TAX_AMOUNT, options: { align: 'right' } }
];

export const AZ_SINGLE_ITEM = 'az_single_item';
export const SALES_USE_TAX_CO = 'sales_use_tax_co';
export const CT_TRADEIN_FEE = 'ct_tradein_fee';
export const FL_DOC_STAMP = 'fl_doc_stamp';
export const FL_DISCRETIONARY = 'fl_discretionary';
export const GA_AD_VALOREM = 'ga_ad_valorem';
export const SALES_USE_TAX_IL = 'sales_use_tax_il';
export const MN_EXCISE_TAX = 'mn_excise_tax';
export const NJ_SUPP = 'nj_supp';
export const OR_CAT = 'or_cat';
export const OR_PRIVILEGE = 'or_privilege';
export const TN_BUSINESS = 'tn_business';
export const TN_LOCAL_OPTIONS = 'tn_local_options';
export const TN_SINGLE_ARTICLE = 'tn_single_article';
export const TX_VIT = 'tx_vit';
export const VA_BUSINESS_LICENSE = 'va_business_license';
export const WA_ESC = 'wa_esc';
export const WA_RTA = 'wa_rta';

export const taxNameMappingTable = new Map([
    [CRT_TAX.toLowerCase(), 'Cap Cost Reduction'],
    [AZ_SINGLE_ITEM.toLowerCase(), 'Single Item Tax'],
    [SALES_USE_TAX_CO.toLowerCase(), 'Sales Use Tax'],
    [CT_TRADEIN_FEE.toLowerCase(), 'Trade-In Fee'],
    [FL_DOC_STAMP.toLowerCase(), 'Documentary Stamp Tax'],
    [FL_DISCRETIONARY.toLowerCase(), 'Discretionary County Tax'],
    [GA_AD_VALOREM.toLowerCase(), 'Title Ad Valorem Tax'],
    [SALES_USE_TAX_IL.toLowerCase(), 'Sales Use Tax'],
    [MN_EXCISE_TAX.toLowerCase(), 'Excise Tax'],
    [NJ_SUPP.toLowerCase(), 'Supplemental Titling Fee'],
    [OR_CAT.toLowerCase(), 'Corporate Activity Tax'],
    [OR_PRIVILEGE.toLowerCase(), 'Privilege Tax'],
    [TN_BUSINESS.toLowerCase(), 'Business Tax'],
    [TN_LOCAL_OPTIONS.toLowerCase(), 'Local Options Tax'],
    [TN_SINGLE_ARTICLE.toLowerCase(), 'Single Article Tax'],
    [TX_VIT.toLowerCase(), 'Vehicle Inventory Tax'],
    [VA_BUSINESS_LICENSE.toLowerCase(), 'Business License Tax'],
    [WA_ESC.toLowerCase(), 'Warranty Tax'],
    [WA_RTA.toLowerCase(), 'Regional Transit Authority Tax']
]);
export const UPFRONT_TAXES_CATEGORY = 'Upfront Taxes';
export const UPFRONT = 'UPFRONT';

export const TAXRATE_TOTAL_THRESHOLD = 90;

export const TAX_AMOUNT_TOTAL_THRESHOLD = 999999.99;
