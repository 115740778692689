import { Reducer } from 'redux';
import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { VAUTO_TRIFORCE_SUCCESS, VAUTO_TRIFORCE_FAILURE, VAUTO_TRIFORCE_REQUEST } from '../actionTypes/vAutoTriforceActionTypes';
import { VAutoTriforceState } from '@makemydeal/dr-dash-types';

export const vAutoTriforceInitialState: VAutoTriforceState = {
    data: null,
    hasError: false
};

export type vAutoTriforceReducer = Reducer<VAutoTriforceState>;

export const reducer: vAutoTriforceReducer = (state: VAutoTriforceState = vAutoTriforceInitialState, action: AnyFSA) => {
    switch (action.type) {
        case VAUTO_TRIFORCE_REQUEST: {
            return { ...state, hasError: false };
        }
        case VAUTO_TRIFORCE_SUCCESS: {
            const { data, ok } = action.payload;
            if (!ok) {
                return { ...state, hasError: true };
            }

            return { ...state, data };
        }
        case VAUTO_TRIFORCE_FAILURE: {
            return { ...state, hasError: true };
        }

        default: {
            return state;
        }
    }
};
