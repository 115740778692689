// externals
import React from 'react';

// libraries
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealState } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

// consts
import { ADJUSTED_CAP_COST } from '../../constants';

// components
import DealSummaryItem from '../common/DealSummaryItem';

// hooks
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

const AdjCapCost = ({ deal }: DealState) => {
    const useDealRootSelector = createDealRootSelector(deal);
    const adjustedCapCost = useDealRootSelector(offerReduxSelectors.getAdjustedCapCost);
    const formatted = formatDollarsAndCents(adjustedCapCost);

    return <DealSummaryItem label={ADJUSTED_CAP_COST} value={formatted} />;
};

export default AdjCapCost;
