import styled from 'styled-components';
import type { IOverlayProps } from './LoadingSectionTypes';

/**
 * Enables the LoadingSection component to render properly as its direct child
 */
export const LoadingSectionParent = styled.div`
    position: relative;

    #terms-card {
        height: auto;
    }
`;

export const Overlay = styled.div<IOverlayProps>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    opacity: 0.7;
    display: ${({ loading }) => (loading ? 'block' : 'none')};
    z-index: 10;
`;

export const OverlayContent = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
