import { toRem } from '../../utils/pixelUtils';

const expandibleAreaStyles = {
    pr: 0,
    pb: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
};

const expandibleButtonStyles = {
    py: 1,
    px: 0
};

const labelStyles = {
    color: '#005BA8'
};

const mainValueStyles = {
    fontWeight: '600'
};

const secondaryValueStyles = {
    fontWeight: '600',
    fontSize: '14px'
};

export { expandibleAreaStyles, expandibleButtonStyles, labelStyles, mainValueStyles as valueStyles, secondaryValueStyles };
