// externals
import { Reducer } from 'redux';

// libraries
import type { CreditDecisionState } from '@makemydeal/dr-dash-types';
import { initActionTypes } from '@makemydeal/dr-shared-store';
import { APPLY_CREDIT_DECISION } from '../actionTypes/creditDecisionActionTypes';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

export type CreditDecisionReducer = Reducer<CreditDecisionState>;

const defaultState = {
    decisionsList: [],
    sourcePartnerId: '',
    selectedDecision: {}
};

export const reducer: CreditDecisionReducer = (state: CreditDecisionState = defaultState, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            let newState = { ...state };
            newState = {
                decisionsList: action.payload.creditDecision.body,
                sourcePartnerId: action.payload.sourcePartnerId || '',
                selectedDecision: {}
            };
            return newState;
        }
        case APPLY_CREDIT_DECISION: {
            return {
                ...state,
                selectedDecision: action.payload
            };
        }

        default: {
            return state;
        }
    }
};
