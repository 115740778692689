import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@interstate/components/Typography';
import { offerReduxActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { CommonConsumer } from '@makemydeal/dr-platform-types';

import { useCustomerUI } from '../customerUI/useCustomerUI';
import { CoBuyerLink } from './CoBuyerLink';
import { HeaderBuyerContainer, HeaderContentRow } from './Header.style.interstate';

export const HeaderRowInterstate: FC = () => {
    const dispatch = useDispatch();
    const { showCustomerCard } = useCustomerUI();

    const shopper = useSelector(offerReduxSelectors.getShopperInfo);
    const commonConsumerId = useSelector(offerReduxSelectors.getShopperCommonConsumerId);

    const customerEditedCallback = (customer: CommonConsumer) => {
        dispatch(offerReduxActionCreators.updatedShopperLocationFromConsumer(shopper, customer));
    };

    return (
        <HeaderContentRow data-testid="header-row-content">
            <HeaderBuyerContainer>
                <Typography
                    variant="h3"
                    tag={commonConsumerId ? 'a' : 'div'}
                    color="base.color.blue.700"
                    data-testid="shopper-name-link"
                    sx={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}
                >
                    <div
                        className="shopper-name"
                        data-testid="shopper-name"
                        onClick={() => commonConsumerId && showCustomerCard(true, { customerEditedCallback, readOnlyFields: true })}
                    >
                        {shopper.firstName} {shopper.lastName}
                    </div>
                </Typography>
                <CoBuyerLink />
            </HeaderBuyerContainer>
        </HeaderContentRow>
    );
};
