export const ACCESSORIES_OPEN_FORM = 'ACCESSORIES_OPEN_FORM';
export const ACCESSORIES_CLOSE_FORM = 'ACCESSORIES_CLOSE_FORM';
export const ADDED_MANUAL_ACCESSORIES = 'ADDED_MANUAL_ACCESSORIES';
export const ADD_ACCESSORIES_SELECTIONS = 'ADD_ACCESSORIES_SELECTIONS';
export const ADD_CATALOG_ACCESSORIES = 'ADD_CATALOG_ACCESSORIES';
export const FOCUS_ACCESSORIES_TABLE = 'FOCUS_ACCESSORIES_TABLE';
export const REMOVE_CATALOG_ACCESSORIES = 'REMOVE_CATALOG_ACCESSORIES';
export const UPDATED_OFFER_MENU_PRODUCTS = 'UPDATED_OFFER_MENU_PRODUCTS';
export const INIT_ACCESSORIES = 'INIT_ACCESSORIES';
export const INIT_MENU_ACCESSORIES = 'INIT_MENU_ACCESSORIES';
export const ACCESSORIES_ACTIVITY_IN_PROGRESS = 'ACCESSORIES_ACTIVITY_IN_PROGRESS';
export const ACCESSORIES_ACTIVITY_COMPLETED = 'ACCESSORIES_ACTIVITY_COMPLETED';

export const CHECK_ACCESSORIES_CATALOG_EXISTS_REQUEST = 'CHECK_ACCESSORIES_CATALOG_EXISTS_REQUEST';
export const CHECK_ACCESSORIES_CATALOG_EXISTS_SUCCESS = 'CHECK_ACCESSORIES_CATALOG_EXISTS_SUCCESS';
export const CHECK_ACCESSORIES_CATALOG_EXISTS_FAILURE = 'CHECK_ACCESSORIES_CATALOG_EXISTS_FAILURE';
