export type CreditDecision = {
    lenderName: string;
    lenderLogo: string;
    approvedAmount: number;
    buyRate: number;
    approvedTerm: number;
    approvedRate: number;
    approvalStatus: string;
    lenderId: string;
    financeMethod: string;
    monthlyPayment: number;
    decisionShown?: boolean;
    isActive?: boolean;
};

export type CreditDecisionState = {
    decisionsList: CreditDecision[];
    sourcePartnerId: string;
    selectedDecision: CreditDecision | object;
};

export type LenderListProps = {
    lenderDecisions: CreditDecision[];
    sortOrder: any[];
    showFooter?: () => void;
};

export enum DealerDecisionStatus {
    APP_SUBMITTED = 'App Submitted',
    APPROVED = 'Approved',
    CONDITIONALLY_APPROVED = 'ConditionallyApproved',
    COUNTER_OFFER = 'CounterOffer',
    PENDING = 'Pending',
    DECLINED = 'Declined',
    NOT_SUBMITTED = 'AppNotSubmitted'
}
