// externals
import { ITradeIn } from '@makemydeal/dr-platform-types';
import { Reducer } from 'redux';

// libraries
import { initActionTypes } from '@makemydeal/dr-shared-store';

// consts/enums
import * as tradeActionTypes from '../actionTypes/tradeActionTypes';
import * as tradeInCardActionTypes from '../actionTypes/tradeInCardActionTypes';

export type RollbackTradeInState = ITradeIn & {
    errorDecodingVin?: boolean;
    hasBeenSaved?: boolean;
    hasMultipleVin?: boolean;
    isSkipped: boolean;
    isTradeBeingSaved?: boolean;
    multipleVinOptions?: tradeActionTypes.FetchVinDecodeResponse | [];
    trimDecodedOptions?: { name: string; id: string }[];
    openManualForm?: boolean;
    shouldIncludeTradeIn: boolean;
    vinHasBeenDecoded: boolean;
};

export type RollbackTradeInReducer = Reducer<RollbackTradeInState>;

export const initialRollbackState: RollbackTradeInState = {
    amountOwed: 0,
    bodyStyle: '',
    color: '',
    condition: '',
    doors: '',
    driveLine: '',
    engine: '',
    expirationDate: '',
    goodThroughDate: '',
    interiorColor: '',
    isAmountAppliedToFinancing: false,
    isCompleted: false,
    isSkipped: false,
    lienHolder: '',
    make: '',
    makeId: '',
    mileage: 0,
    model: '',
    modelId: '',
    offerDate: '',
    offerId: '',
    openManualForm: false,
    owed: 0,
    ownershipType: '',
    perDiemInterest: 0,
    shouldIncludeTradeIn: true,
    source: '',
    status: '',
    transmission: '',
    trim: '',
    trimId: '',
    value: 0,
    vehicleId: '',
    vehicleOptions: {},
    vin: '',
    vinHasBeenDecoded: false,
    year: 0
};

export const reducer: RollbackTradeInReducer = (state: RollbackTradeInState = initialRollbackState, action: any) => {
    switch (action.type) {
        case initActionTypes.INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...(action.payload.trade?.trade || {})
            };
        }
        case tradeActionTypes.MANUAL_ENTRY_SAVE_TRADE_IN: {
            const state = action.payload;
            return { ...state, isCompleted: true };
        }
        case tradeInCardActionTypes.TRADE_IN_TOGGLE_MANUAL_FORM: {
            return {
                ...state,
                openManualForm: action.payload ?? !state.openManualForm
            };
        }
        default: {
            return state;
        }
    }
};
