// TODO: SV - See what is common and keep it here and get Dash to also use this (for now it is duplicated).

// externals
import { Middleware, createStore, applyMiddleware, compose } from 'redux';
import { connectRouter } from 'connected-react-router';

// utils
import historyInstance from './historyInstance';
import { reducerRegistry } from './reducerRegistry';
import * as configureStoreCommon from './configureStoreCommon';
import { scopeMiddleware } from '../middleware/middlewareScopeWrapper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPreloadedState {}

export function configureStore(
    preloadedState: IPreloadedState,
    initialMiddleware: Middleware<any, any, any>[],
    initialGlobalReducers: any,
    initialScopedReducers?: any,
    isScoped = false
) {
    reducerRegistry.reset();
    reducerRegistry.registerGlobal('router', connectRouter(historyInstance));
    reducerRegistry.registerMultipleGlobal(initialGlobalReducers);

    if (initialScopedReducers) {
        reducerRegistry.registerMultipleScoped(initialScopedReducers);
    }

    if (isScoped) {
        reducerRegistry.setScoped();
    }

    const allReducers = reducerRegistry.getReducers();
    const middlewares = isScoped ? initialMiddleware.map((m) => scopeMiddleware(m)) : initialMiddleware;
    const store = createStore(allReducers, preloadedState, compose(applyMiddleware(...middlewares)));

    reducerRegistry.setChangeListener((reducers) => {
        store.replaceReducer(reducers);
    });

    return store;
}
