export const StateAbbreviations = [
    {
        label: 'Alabama',
        value: 'AL'
    },
    {
        label: 'Alaska',
        value: 'AK'
    },
    {
        label: 'Arizona',
        value: 'AZ'
    },
    {
        label: 'Arkanzas',
        value: 'AR'
    },
    {
        label: 'California',
        value: 'CA'
    },
    {
        label: 'Colorado',
        value: 'CO'
    },
    {
        label: 'Connecticut',
        value: 'CT'
    },
    {
        label: 'Delaware',
        value: 'DE'
    },
    {
        label: 'District of Columbia',
        value: 'DC'
    },
    {
        label: 'Florida',
        value: 'FL'
    },
    {
        label: 'Georgia',
        value: 'GA'
    },
    {
        label: 'Hawaii',
        value: 'Hi'
    },
    {
        label: 'Idaho',
        value: 'ID'
    },
    {
        label: 'Illinois',
        value: 'IL'
    },
    {
        label: 'Indiana',
        value: 'IN'
    },
    {
        label: 'Iowa',
        value: 'IA'
    },
    {
        label: 'Kansas',
        value: 'KS'
    },
    {
        label: 'Kentucky',
        value: 'KY'
    },
    {
        label: 'Louisiana',
        value: 'LA'
    },
    {
        label: 'Maine',
        value: 'ME'
    },
    {
        label: 'Maryland',
        value: 'MD'
    },
    {
        label: 'Massachusetts',
        value: 'MA'
    },
    {
        label: 'Michigan',
        value: 'MI'
    },
    {
        label: 'Minnesota',
        value: 'MN'
    },
    {
        label: 'Mississippi',
        value: 'MS'
    },
    {
        label: 'Missouri',
        value: 'MO'
    },
    {
        label: 'Montana',
        value: 'MT'
    },
    {
        label: 'Nebraska',
        value: 'NE'
    },
    {
        label: 'Nevada',
        value: 'NV'
    },
    {
        label: 'New Hampshire',
        value: 'NH'
    },
    {
        label: 'New Jersey',
        value: 'NJ'
    },
    {
        label: 'New Mexico',
        value: 'NM'
    },
    {
        label: 'New York',
        value: 'NY'
    },
    {
        label: 'North Carolina',
        value: 'NC'
    },
    {
        label: 'North Dakota',
        value: 'ND'
    },
    {
        label: 'Ohio',
        value: 'OH'
    },
    {
        label: 'Oklahoma',
        value: 'OK'
    },
    {
        label: 'Oregon',
        value: 'OR'
    },
    {
        label: 'Pennsylvania',
        value: 'PA'
    },
    {
        label: 'Rhode Island',
        value: 'RI'
    },
    {
        label: 'South Carolina',
        value: 'SC'
    },
    {
        label: 'South Dakota',
        value: 'SD'
    },
    {
        label: 'Tennessee',
        value: 'TN'
    },
    {
        label: 'Texas',
        value: 'TX'
    },
    {
        label: 'Utah',
        value: 'UT'
    },
    {
        label: 'Vermont',
        value: 'VT'
    },
    {
        label: 'Virginia',
        value: 'VA'
    },
    {
        label: 'Washington',
        value: 'WA'
    },
    {
        label: 'West Virginia',
        value: 'WV'
    },
    {
        label: 'Wisconsin',
        value: 'WI'
    },
    {
        label: 'Wyoming',
        value: 'WY'
    },
    {
        label: 'American Samoa',
        value: 'AS'
    },
    {
        label: 'Guam',
        value: 'GU'
    },
    {
        label: 'Northern Mariana Islands',
        value: 'MP'
    },
    {
        label: 'Puerto Rico',
        value: 'PR'
    },
    {
        label: 'Virgin Islands',
        value: 'VI'
    },
    {
        label: 'U.S. Minor Outlying Islands',
        value: 'UM'
    },
    {
        label: 'Federated States of Micronesia',
        value: 'FM'
    },
    {
        label: 'Marshall Islands',
        value: 'MI'
    },
    {
        label: 'Palau',
        value: 'PW'
    },
    {
        label: 'Armed Forces Americas (except Canada)',
        value: 'AA'
    },
    {
        label: 'Armed Forces - Europe, Canada, Middle East, Africa',
        value: 'AE'
    },
    {
        label: 'Armed Forces Pacific',
        value: 'AP'
    },
    {
        label: 'Canal Zone',
        value: 'CZ'
    },
    {
        label: 'Philippine Islands',
        value: 'PI'
    },
    {
        label: 'Trust Territory of the Pacific Islands',
        value: 'TT'
    },
    {
        label: 'Commonwealth of the Northern Mariana Islands',
        value: 'CM'
    }
] as const;
