// externals
import { createSelector, createSelectorCreator, weakMapMemoize } from 'reselect';

// libraries
import type { CreditDecision, CreditDecisionState, StateTree } from '@makemydeal/dr-dash-types';
import { OfferType, FINANCE, LEASE } from '@makemydeal/dr-platform-types';

const createSelectorWeakMap = createSelectorCreator({
    memoize: weakMapMemoize,
    argsMemoize: weakMapMemoize
});

const EMPTY_DECISIONS_LIST: CreditDecision[] = [];

export const getLenderCreditDecisions = createSelector(
    (state: StateTree) => state.creditDecision,
    (creditDecision: CreditDecisionState | undefined): CreditDecision[] => {
        return creditDecision?.decisionsList || EMPTY_DECISIONS_LIST;
    }
);

export const getSourcePartnerId = createSelector(
    (state: StateTree) => state.creditDecision,
    (creditDecision: CreditDecisionState | undefined): string => {
        return creditDecision?.sourcePartnerId || '';
    }
);

export const getLenderCreditDecisionsByOfferType = createSelectorWeakMap(
    [
        (state: StateTree): CreditDecision[] | undefined => state.creditDecision?.decisionsList,
        (_state: StateTree, offerType: OfferType) => offerType
    ],
    (decisionsList: CreditDecision[] | undefined, offerType: OfferType): CreditDecision[] => {
        if (!decisionsList?.filter) {
            return EMPTY_DECISIONS_LIST;
        }

        if (offerType === FINANCE) {
            return decisionsList.filter((d: CreditDecision) => d.financeMethod === 'Finance' || d.financeMethod === 'Retail');
        }

        if (offerType === LEASE) {
            return decisionsList.filter((d: CreditDecision) => d.financeMethod === 'Lease');
        }

        return EMPTY_DECISIONS_LIST;
    }
);

export const getSelectedCreditDecision = createSelector(
    (state: StateTree): CreditDecision => state.creditDecision,
    (creditDecision: any): CreditDecision => creditDecision?.selectedDecision || {}
);
