import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const BannerWrapper = styled.div`
    width: auto;
`;

export const ModalAttachpointContainer = styled.div`
    .dr-modal-parent {
        top: 100px;
        bottom: 100px;
        max-width: 779px;
        margin: 0 auto;
    }
    #customer-card-container {
        box-sizing: border-box;
        button {
            background-color: #0d65bf;
            color: white;
            border-radius: 4px;
            transition: background-color 300ms ease-in-out;
            border: transparent 1px solid;
        }
        button:hover {
            background-color: white;
            color: #0d65bf;
            border: #0d65bf 1px solid;
        }
        button a {
            color: white;
            transition: color 300ms ease-in-out;
        }
        button a:hover {
            color: #0d65bf;
        }
        #customer-information-contact-info > span:nth-child(3) > div > div > p {
            text-align: left;
        }
    }
`;

export const FooterPlaceholder = styled.div`
    height: 13rem;

    @media (min-width: ${BreakPoint.SMALL}) {
        height: 5.867rem;
    }
`;

export const StickyFooter = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1001;
`;
