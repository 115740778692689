import styled from 'styled-components';

export const StyledTitle = styled.div`
    display: flex;
    align-items: end;

    font-size: ${({ theme }) => theme.tokens.BrandTypographyFontSizeBase};
    font-weight: ${({ theme }) => theme.tokens.BaseTypeWeightMedium};
    line-height ${({ theme }) => theme.tokens.BaseSizeFont20};
`;
