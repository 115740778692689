// externals
import type { AnyAction, Reducer } from 'redux';
import { produce, Draft } from 'immer';

// libraries
import { initActionTypes } from '@makemydeal/dr-shared-store';
import { VdpSourceState } from '@makemydeal/dr-dash-bff-types';

const initialState = {
    vdpUrl: '',
    sponsor: ''
} as VdpSourceState;

export type Source = {
    listingCode?: string;
    vdpUrl?: string;
};

export const reducer: Reducer<VdpSourceState> = (state: VdpSourceState = initialState, action: AnyAction) => {
    return produce(state, (draft: Draft<VdpSourceState>) => {
        switch (action?.type) {
            case initActionTypes.INIT_SUCCESS: {
                draft.listingCode = action.payload.offer.source.listingCode;
                draft.vdpUrl = action.payload.offer.source.vdpUrl;
                draft.sponsor = action.payload.offer.source.sponsor;
                break;
            }
        }
    });
};
