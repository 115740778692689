import { useMemo, useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { offerSelectors, vehicleSelectors } from '@makemydeal/dr-dash-store';
import { paymentSelectors, actions, selectors, actionCreators } from '@makemydeal/dr-offer-redux';

import { vehicleInfoFormKeys } from './formContext';
import { isObjectEqual, validateForm } from './helper';
import { FMErrors, FMField, FMNames, FMProps, PaymentState } from '../types';
import { GENERIC_LAST_FIELD_CHANGED_ERROR, STOCK_NUMBER_DEFAULT_ERROR_MSG, STOCK_NUMBER_USED_ERROR_MSG } from '../../constants';
import { changeVehicleCreators, changeVehicleSelectors } from '@makemydeal/dr-activities-change-vehicle';

const { UPDATED_VEHICLE_INFO_DETAILS, UPDATED_OFFER_PRICE_IMMEDIATE, UPDATED_RETAIL_PRICE_OVERRIDE } = actions;

function useForm(hideDrawer: () => void) {
    const dispatch = useDispatch();
    const [canApply, setCanApply] = useState(false);
    const [, setVersion] = useState(0);
    const [formVersion, setFormVersion] = useState(0);
    const errorContext = useRef<FMErrors>({});
    const [isStockEditable, setIsStockEditable] = useState(false);
    const { getServicesBff, getCommonOrgId } = changeVehicleSelectors.additionalSelectors;
    const bffEndpoint = useSelector(getServicesBff);
    const orgId = useSelector(getCommonOrgId);
    const isStockNumberAvailable = useSelector(changeVehicleSelectors.getIsStockNumberAvailable);
    const isSearchError = useSelector(changeVehicleSelectors.getIsSearchError);
    const isFetching = useSelector(changeVehicleSelectors.getIsFetching);

    const { actionForPayment, isCalculating, error: paymentError } = useSelector(paymentSelectors.getPaymentState) as PaymentState;
    const {
        cost = 0,
        invoiceAmount = 0,
        mileage,
        wholesaleValue = 0,
        wholesaleBookSource,
        stockNumber
    } = useSelector(vehicleSelectors.getVehicle);
    const sellingPrice = useSelector(offerSelectors.getSellingPrice);
    const msrp = useSelector(offerSelectors.getRetailPriceOverrideWithFallback);
    const offerType = useSelector(selectors.getCurrentOfferType);

    const formManager = useMemo<FMProps>(() => {
        if (!errorContext.current[offerType]) {
            errorContext.current[offerType] = {};
        }
        return {
            touched: {},
            error: paymentError ? errorContext.current[offerType] : {},
            values: {
                msrp,
                sellingPrice,
                invoiceAmount,
                cost,
                mileage: String(mileage),
                wholesaleValue,
                wholesaleBookSource,
                stockNumber
            },
            initialValues: {
                msrp,
                sellingPrice,
                invoiceAmount,
                cost,
                mileage: String(mileage),
                wholesaleValue,
                wholesaleBookSource,
                stockNumber
            },
            formVersion
        };
    }, [
        offerType,
        paymentError,
        msrp,
        sellingPrice,
        invoiceAmount,
        cost,
        mileage,
        wholesaleValue,
        wholesaleBookSource,
        stockNumber,
        formVersion
    ]);

    const resetStateAndHideDrawer = useCallback(() => {
        errorContext.current[offerType] = {};
        formManager.error = {};
        setCanApply(false);
        hideDrawer();
    }, [formManager, hideDrawer, offerType]);

    const updateAndReset = useCallback(() => {
        setIsStockEditable(false);
        const { payload, withPayment } = validateForm(formManager.values, formManager.touched);
        dispatch(actionCreators.updateVehicleInfoDetails(payload, withPayment));
        if (!withPayment) {
            resetStateAndHideDrawer();
        }
    }, [dispatch, formManager.touched, formManager.values, resetStateAndHideDrawer]);

    const checkBookSourceErrors = (formManager: FMProps): boolean => {
        const { wholesaleBookSource, wholesaleValue: rawWholesaleValue } = formManager.values;
        const wholesaleValue = Number(rawWholesaleValue);
        const { error } = formManager;

        delete error.wholesaleValue;
        delete error.wholesaleBookSource;

        if (!wholesaleBookSource && !wholesaleValue) {
            return false;
        }

        error.wholesaleValue = !wholesaleValue ? 'Required' : undefined;
        error.wholesaleBookSource = !wholesaleBookSource ? 'Required' : undefined;

        return !!(error.wholesaleBookSource || error.wholesaleValue);
    };

    const changeHandler = (name: FMNames, value: string | number) => {
        formManager.touched[name] = value !== formManager.initialValues[name];
        formManager.values[name] = value;

        const hasChanged = !isObjectEqual(formManager.values, formManager.initialValues);
        const hasSourceErrors = checkBookSourceErrors(formManager);

        setCanApply(hasChanged && !hasSourceErrors);
        setVersion(Date.now());
    };

    const handleClick = (type: 'APPLY' | 'CANCEL') => {
        setCanApply(false);
        if (type === 'CANCEL') {
            setIsStockEditable(false);
            hideDrawer();
            setFormVersion(Date.now());
            return;
        }

        if (formManager.error[FMField.stockNumber]) {
            setCanApply(true);
            return;
        }

        vehicleInfoFormKeys.forEach((key) => {
            if (key !== FMField.stockNumber && formManager.touched[key]) {
                errorContext.current[offerType][key] = GENERIC_LAST_FIELD_CHANGED_ERROR;
            }
        });

        if (formManager.touched.stockNumber && formManager.values.stockNumber) {
            dispatch(changeVehicleCreators.searchVehicles(formManager.values.stockNumber.toString(), orgId, bffEndpoint, true));
        } else {
            updateAndReset();
        }
    };

    const handleClickEditStock = () => {
        setIsStockEditable(true);
    };

    const setStockNumberError = (error: string) => {
        errorContext.current[offerType][FMField.stockNumber] = error;
        setCanApply(true);
    };

    useEffect(() => {
        if (typeof isStockNumberAvailable === 'undefined' || isFetching) return;

        if (isSearchError) {
            setStockNumberError(STOCK_NUMBER_DEFAULT_ERROR_MSG);
            return;
        }

        if (!isStockNumberAvailable) {
            setStockNumberError(STOCK_NUMBER_USED_ERROR_MSG);
            return;
        }

        updateAndReset();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStockNumberAvailable]);

    useEffect(() => {
        const isStockNumberAvailableError = isStockNumberAvailable === false;
        formManager.error = paymentError || isSearchError || isStockNumberAvailableError ? errorContext.current[offerType] : {};
        setVersion(Date.now());
    }, [formManager, offerType, paymentError, isSearchError, isStockNumberAvailable]);

    useEffect(() => {
        if (
            actionForPayment === UPDATED_VEHICLE_INFO_DETAILS ||
            actionForPayment === UPDATED_OFFER_PRICE_IMMEDIATE ||
            actionForPayment === UPDATED_RETAIL_PRICE_OVERRIDE
        ) {
            setVersion(Date.now());
            if (paymentError) {
                switch (actionForPayment) {
                    case UPDATED_OFFER_PRICE_IMMEDIATE:
                        errorContext.current[offerType] = {
                            ...errorContext.current[offerType],
                            sellingPrice: GENERIC_LAST_FIELD_CHANGED_ERROR
                        };
                        formManager.error = errorContext.current[offerType];
                        break;
                    case UPDATED_RETAIL_PRICE_OVERRIDE:
                        errorContext.current[offerType] = {
                            ...errorContext.current[offerType],
                            msrp: GENERIC_LAST_FIELD_CHANGED_ERROR
                        };
                        formManager.error = errorContext.current[offerType];
                        break;
                    default:
                        formManager.error = { ...errorContext.current[offerType] };
                        break;
                }
            } else if (!isCalculating) {
                resetStateAndHideDrawer();
            }
        }
    }, [actionForPayment, paymentError, formManager, hideDrawer, isCalculating, offerType, resetStateAndHideDrawer]);

    return {
        form: formManager,
        canApply,
        changeHandler,
        handleClick,
        hideDrawer,
        isStockEditable,
        handleClickEditStock
    };
}

export default useForm;
