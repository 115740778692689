import styled from 'styled-components';

export const StyledGuidelineAlertsContainer = styled.div`
    & div.MuiDialogContent-root {
        display: 'flex';
        flex-direction: 'column';

        & div div {
            white-space: pre;
        }
    }
`;

export const StyledLenderGuidelineCloseButtonContainer = styled.div`
    margin-top: auto;

    button {
        background-color: #fff;
        border-color: #2b6bdd;
        color: #2b6bdd;
        transition: all 0.25s ease-in-out;

        &:hover {
            background-color: #2b6bdd;
            border-color: #2b6bdd;
            color: #fff;
        }
    }
`;

export const StyledAlertsContainer = styled.div`
    overflow: auto;
    & span.guideline-alert-lender-code {
        font-weight: 500;
        width: 2.5rem;
        display: inline-block;
    }
`;
