// externals
import React from 'react';

// libraries
import { Root } from '@makemydeal/dr-activities-deal-history';

// consts/enums
import { DEAL_HISTORY_ROUTE_ROOT } from '../constants';

const DealHistoryActivity = () => {
    return <Root baseRoute={DEAL_HISTORY_ROUTE_ROOT} />;
};

export default DealHistoryActivity;
