// components
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';
import { Action } from '@interstate/components/Action';

// styling
import { StyledAlertWrapper, StyledAlertContentWrapper } from './TableGlobalAlerts.style';

// types
import { TableGlobalAlertInputProps } from '../../types';

const TableGlobalAlertError = ({ name, message, action }: TableGlobalAlertInputProps) => {
    return (
        <StyledAlertWrapper data-testid={`${name}-error-alert`}>
            <Alert role="banner" type="error">
                <StyledAlertContentWrapper>
                    <Typography variant="label-md">{message}</Typography>
                    {action && (
                        <>
                            &nbsp;
                            <Action onClick={action.handler}>
                                <span className="underline">{action.text}</span>
                            </Action>
                        </>
                    )}
                </StyledAlertContentWrapper>
            </Alert>
        </StyledAlertWrapper>
    );
};

export default TableGlobalAlertError;
