import { DealProduct, DealProductAddOn } from '@makemydeal/dr-shared-types';
import {
    VehicleProtectionState,
    DealProductSummary,
    DealerProduct,
    DraftDealProduct,
    RatedProductRates,
    VppSurcharge,
    ProductWithProductCode,
    ProductAddOnDetailsNonOptional
} from '@makemydeal/dr-dash-types';
import {
    OfferTypeEnum,
    VppDealType,
    OfferType,
    AccelerateVehicleCondition,
    VppInventoryType,
    Rate,
    DynamicSurcharge,
    RatedProtectionProduct
} from '@makemydeal/dr-platform-types';
import _ from 'lodash';

type CalculateSummaryInput = {
    products: DraftDealProduct[];
    term?: number;
};

export function calculateVehicleProtectionSummary({ products, term }: CalculateSummaryInput): DealProductSummary {
    const initialSummary = {
        totalProductPrice: 0,
        totalProductMarkup: 0,
        totalProductCost: 0,
        productMonthlyPayment: 0
    };

    const selectedProducts = products.filter((product) => product.selected);

    const summary = selectedProducts.reduce((summary, product) => {
        const productPrice = product.productPrice || 0;
        const productMarkup = product.productMarkup?.markupDollarAmount || 0;
        const productCost = product.productCost || 0;

        summary.totalProductPrice += productPrice;
        summary.totalProductMarkup += productMarkup;
        summary.totalProductCost += productCost;

        return summary;
    }, initialSummary);

    if (term) {
        summary.productMonthlyPayment = summary.totalProductPrice / term;
    }

    return summary;
}

const mapToProductCodes = (products: ProductWithProductCode[]): string[] => {
    return products.map((product) => product.productCode);
};

export const getModifiedProducts = ({ modifiedProducts }: VehicleProtectionState, newModifiedProducts: DraftDealProduct[]) => {
    const productCodesToAdd = mapToProductCodes(newModifiedProducts.filter(isProductWithCode));
    const uniqueProductCodes = _.uniq([...modifiedProducts, ...productCodesToAdd]);

    return uniqueProductCodes;
};

export const getRequestedSurcharges = (surcharges: VppSurcharge[] = []) => {
    const usedSurchargesAttributesSet = new Set<string>();
    const requiredSurchargesAttributesSet = new Set<string>();

    surcharges.forEach(({ resolvingFields, supplementalForProducts, optionalForProducts }) => {
        if (resolvingFields?.length) {
            const isUsed = optionalForProducts?.length || supplementalForProducts?.length;
            const isRequired = supplementalForProducts?.length;

            if (isRequired) {
                resolvingFields.forEach((resolvingField) => requiredSurchargesAttributesSet.add(resolvingField));
            }

            if (isUsed) {
                resolvingFields.forEach((resolvingField) => usedSurchargesAttributesSet.add(resolvingField));
            }
        }
    });

    return {
        usedSurchargesAttributes: Array.from(usedSurchargesAttributesSet),
        requiredSurchargesAttributes: Array.from(requiredSurchargesAttributesSet)
    };
};

const vehicleConditionToFieInventoryTypeMappers: { [key in AccelerateVehicleCondition]: VppInventoryType } = {
    [AccelerateVehicleCondition.NEW]: VppInventoryType.NEW,
    [AccelerateVehicleCondition.USED]: VppInventoryType.USED,
    [AccelerateVehicleCondition.CERTIFIED]: VppInventoryType.CPO
};

const offerTypeToFieDealTypeMapper: { [key in OfferTypeEnum]: VppDealType } = {
    [OfferTypeEnum.CASH]: VppDealType.CASH,
    [OfferTypeEnum.FINANCE]: VppDealType.FINANCE,
    [OfferTypeEnum.LEASE]: VppDealType.LEASE
};

export const mapAmdToVppVehicleCondition = (vehicleCondition: AccelerateVehicleCondition): VppInventoryType => {
    return vehicleConditionToFieInventoryTypeMappers[vehicleCondition?.toLowerCase() as AccelerateVehicleCondition];
};

export const mapOfferTypeToVppDealType = (offerType: OfferType): VppDealType => {
    return offerTypeToFieDealTypeMapper[offerType?.toLowerCase() as OfferType];
};

const toDraftProduct = (dealerProduct: DealerProduct): DraftDealProduct => {
    const { dealerSettings } = dealerProduct;

    return {
        productName: dealerProduct.productName,
        productCategoryCode: dealerProduct.productCategoryCode,
        productCode: dealerProduct.productCode,
        selected: false,
        productProviderName: dealerSettings?.providerName
    };
};

const applyRates = (product: DraftDealProduct, rates: RatedProductRates): DraftDealProduct => {
    const productAddOnDetails = mapDynamicSurchargesToProductAddOnDetails(rates.dynamicSurcharges);

    const ratedProduct: DraftDealProduct = {
        ...product,
        productPrice: rates.productPrice,
        productCost: rates.productCost,
        productInitialCost: rates.productCost,
        productDeductible: rates.productDeductible,
        productDeductibleMethod: rates.deductibleApplyType,
        productCoverage: rates.productProviderPlan,
        productCoverageLength: rates.productCoverageLength,
        productCoverageMiles: rates.productCoverageMiles,
        productProviderPlan: rates.productProviderPlan,
        minPriceAllowedByLender: rates.minPrice,
        maxPriceAllowedByLender: rates.maxPrice,
        productAddOnDetails
    };
    const markupDollarAmount = rates.productPrice - rates.productCost;

    if (typeof markupDollarAmount === 'number' && !Number.isNaN(markupDollarAmount)) {
        ratedProduct.productMarkup = {
            markupMethod: 'Dollar',
            markupDollarAmount: markupDollarAmount
        };
    }

    if (rates.productServiceInterval) {
        ratedProduct.productServiceInterval = rates.productServiceInterval;
    }

    return ratedProduct;
};

export const syncVppProducts = (
    { products: existingProducts, modifiedProducts }: VehicleProtectionState,
    newDealerProducts: DealerProduct[]
): DraftDealProduct[] => {
    const existingModifiedProducts = existingProducts.filter(
        ({ productCode }) => productCode && modifiedProducts.includes(productCode)
    );
    const mergedProducts: DraftDealProduct[] = [...existingModifiedProducts];

    newDealerProducts.forEach((newDealerProduct) => {
        const isProductModified = modifiedProducts.includes(newDealerProduct.productCode);

        if (!isProductModified) {
            mergedProducts.push(toDraftProduct(newDealerProduct));
        }
    });

    return mergedProducts;
};

export const updateProductsWithRates = (
    { products, rates, modifiedProducts }: VehicleProtectionState,
    { showRecommendedOnVppRates }: { showRecommendedOnVppRates: boolean }
): DraftDealProduct[] => {
    const productsToUpdate: DraftDealProduct[] = _.cloneDeep(products);

    for (const product of productsToUpdate) {
        const isProductModified = product.productCode && modifiedProducts.includes(product.productCode);

        if (!isProductModified) {
            const productRates = rates.find((rate) => rate.productCode === product.productCode);

            if (productRates && productRates.rates.length > 0) {
                if (showRecommendedOnVppRates) {
                    const recommendedRate = productRates.rates.find((rate) => rate.recommended);

                    if (recommendedRate) {
                        Object.assign(product, applyRates(product, recommendedRate));
                    }
                } else {
                    const [defaultRates] = productRates.rates;
                    Object.assign(product, applyRates(product, defaultRates));
                }
            }
        }
    }

    return productsToUpdate;
};

export const isDealProduct = (product: DraftDealProduct): product is DealProduct =>
    product.productCategoryCode !== undefined && product.productPrice !== undefined;

export const isProductWithCode = (product: DraftDealProduct): product is ProductWithProductCode =>
    product.productCode !== undefined;

export const mapToInitialDealXgProducts = (products: DraftDealProduct[]): DraftDealProduct[] => {
    return products.map((product) => ({
        productCode: product.productCode,
        productCost: product.productCost
    }));
};

export const mapToPaymentProductCodes = (products: DraftDealProduct[]): string[] => {
    return products.filter((product) => product.selected).map((product) => product.productCode!);
};

export const mapToDealerProducts = (products: DealerProduct[]): Partial<DealerProduct>[] => {
    return products.map((product) => ({
        productCode: product.productCode,
        rateRequirements: product.rateRequirements,
        dealerSettings: product.dealerSettings
    }));
};

export const selectAllEligibleProducts = ({ products, dealerProducts, rates }: VehicleProtectionState): DraftDealProduct[] => {
    products.forEach((product) => {
        const dealerProduct = dealerProducts.find((dealerProduct) => dealerProduct.productCode === product.productCode);
        const hasMissingAttributes = dealerProduct?.rateRequirements?.hasMissingField;
        const hasRates = rates.find(
            (ratedProduct) => ratedProduct.productCode === product.productCode && ratedProduct.rates?.length
        );

        if (!hasMissingAttributes && (!dealerProduct || hasRates)) {
            product.selected = true;
        }
    });
    return products;
};

export const unselectAllEligibleProducts = ({ products, dealerProducts }: VehicleProtectionState): DraftDealProduct[] => {
    products.forEach((product) => {
        const hasMissingAttributes = dealerProducts.find((dealerProduct) => dealerProduct.productCode === product.productCode)
            ?.rateRequirements?.hasMissingField;
        if (!hasMissingAttributes) {
            product.selected = false;
        }
    });
    return products;
};

/**
 * Compares Dealxg product with vpp rate and returns true if it's linked with each other by rate attributes
 * (coverage length, coverage miles, product deductible and product service interval).
 * In case rate doesn't have attributes - it matches the product as well
 */
export const doesRateMatchProduct = (
    rate: Rate,
    product: DraftDealProduct,
    rateAttributes: RatedProtectionProduct['rateAttributes'],
    isPlanSelectionEnabled: boolean
) => {
    return rateAttributes.every((rateAttribute): boolean => {
        let isMatchingRate = false;

        switch (rateAttribute) {
            case 'productProviderPlan':
                isMatchingRate = isPlanSelectionEnabled ? rate.productProviderPlan === product.productProviderPlan : true;

                break;
            case 'productCoverageLength':
                isMatchingRate = rate.productCoverageLength === product.productCoverageLength;

                break;
            case 'productCoverageMiles':
                isMatchingRate = rate.productCoverageMiles === product.productCoverageMiles;

                break;
            case 'productDeductible':
                isMatchingRate =
                    rate.productDeductible === product.productDeductible &&
                    rate.deductibleApplyType === product.productDeductibleMethod;

                break;
            case 'productServiceInterval':
                isMatchingRate = rate.productServiceInterval === product.productServiceInterval;

                break;
            default:
                isMatchingRate = true;

                break;
        }

        return isMatchingRate;
    });
};

export const getSelectedRate = (
    { rates = [], rateAttributes = [] }: Partial<Pick<RatedProtectionProduct, 'rates' | 'rateAttributes'>> = {},
    product: DraftDealProduct,
    isPlanSelectionEnabled: boolean = false
): Rate | undefined => {
    let matchingRate;

    rates.forEach((rate) => {
        if (doesRateMatchProduct(rate, product, rateAttributes, isPlanSelectionEnabled)) {
            matchingRate = rate;
        }
    });

    return matchingRate;
};

const mapDynamicSurchargeToAddOn = (dynamicSurcharge: DynamicSurcharge): DealProductAddOn => {
    return {
        addOnCost: dynamicSurcharge.cost,
        addOnDescription: dynamicSurcharge.description,
        addOnPrice: dynamicSurcharge.price,
        addOnSurchargeCode: dynamicSurcharge.surchargeCode,
        isAddOnEditable: dynamicSurcharge.isEditable
    };
};

export const mapDynamicSurchargesToProductAddOnDetails = (
    dynamicSurcharges: DynamicSurcharge[] = []
): ProductAddOnDetailsNonOptional => {
    const selectedAddOns = dynamicSurcharges.filter((item) => item.value).map(mapDynamicSurchargeToAddOn);

    const unselectedAddOns = dynamicSurcharges.filter((item) => !item.value).map(mapDynamicSurchargeToAddOn);

    const addOnsCost = selectedAddOns
        .filter((addOn) => addOn.isAddOnEditable !== false)
        .reduce((acc: number, addOn: DealProductAddOn) => acc + addOn.addOnCost, 0);
    const addOnsPrice = selectedAddOns
        .filter((addOn) => addOn.isAddOnEditable !== false)
        .reduce((acc: number, addOn: DealProductAddOn) => acc + addOn.addOnPrice, 0);

    return {
        productAddOns: selectedAddOns,
        unselectedProductAddOns: unselectedAddOns,
        productAddOnSummary: {
            totalProductAddOnPrice: addOnsPrice,
            totalProductAddOnCost: addOnsCost
        }
    };
};

export const sortProducts = (products: DraftDealProduct[]): DraftDealProduct[] => {
    return _.clone(products).sort((a, b) => {
        const isSelectedA = a.selected ? 0 : 1;
        const isSelectedB = b.selected ? 0 : 1;
        const categoryCodeA = a.productCategoryCode ?? '';
        const categoryCodeB = b.productCategoryCode ?? '';

        return isSelectedA - isSelectedB || categoryCodeA.localeCompare(categoryCodeB);
    });
};

export const getPlansFromProductsRates = (rates: Rate[] | undefined = []): string[] => {
    return Array.from(
        new Set(
            rates.map((rate) => rate.productProviderPlan).filter((value: unknown): value is string => typeof value === 'string')
        )
    );
};

export const isVppProductSelected = (product: DraftDealProduct): boolean => {
    return product.selected === true;
};

export const isVppCapProduct = (product: DraftDealProduct): boolean => {
    return product.isProductPriceCapitalized ?? true;
};

export const isVppUpfrontProduct = (product: DraftDealProduct): boolean => {
    return product.isProductPriceCapitalized === false;
};
